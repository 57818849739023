import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
  Button,
  Col,
  FormCheck,
  ListGroup,
  Modal,
  Row,
  Tab
} from 'react-bootstrap';
import DFormText from '../../components/DFormText/DFormText';
import api from '../../../services/api';

import ModalLoading from '../../components/ModalLoading/ModalLoading';
import { ToastContainer, toast } from 'react-toastify';
import { createLog } from '../../../services/logService';

const UserForm = forwardRef((props, ref) => {
  const { onClose } = props;
  const [modalBox, setModalBox] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loadingUserForm, setLoadingUserForm] = useState(false);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(true);

  const [dashboardAccess, setDashboardAccess] = useState(true);

  const [recordUser, setRecordUser] = useState(true);
  const [recordHealthPlan, setRecordHealthPlan] = useState(true);
  const [recordDoctor, setRecordDoctor] = useState(true);
  const [recordHospital, setRecordHospital] = useState(true);
  const [recordPacient, setRecordPacient] = useState(true);
  const [recordSupplier, setRecordSupplier] = useState(true);
  const [recordProcedureGroup, setRecordProcedureGroup] = useState(true);
  const [recordProcedureType, setRecordProcedureType] = useState(true);
  const [recordProcedure, setRecordProcedure] = useState(true);

  const [scheduleRead, setScheduleRead] = useState(true);

  const [preScheduleCreate, setPreScheduleCreate] = useState(true);
  const [preScheduleUpdate, setPreScheduleUpdate] = useState(true);
  const [preScheduleRead, setPreScheduleRead] = useState(true);
  const [preScheduleDelete, setPreScheduleDelete] = useState(true);

  const [scaleRead, setScaleRead] = useState(true);
  const [scaleUpdate, setScaleUpdate] = useState(true);

  const [availabilityRead, setAvailabilityRead] = useState(true);
  const [availabilityUpdate, setAvailabilityUpdate] = useState(true);

  const [clinicMedicalRecord, setClinicMedicalRecord] = useState(true);

  const [protocolCreate, setProtocolCreate] = useState(true);
  const [protocolUpdate, setProtocolUpdate] = useState(true);
  const [protocolRead, setProtocolRead] = useState(true);
  const [protocolDelete, setProtocolDelete] = useState(true);

  const [reportCreate, setReportCreate] = useState(true);
  const [reportUpdate, setReportUpdate] = useState(true);
  const [reportRead, setReportRead] = useState(true);

  const [operationalInvoice, setOperationalInvoice] = useState(false);
  const [operationalReceipt, setOperationalReceipt] = useState(false);
  const [operationalCommission, setOperationalCommission] = useState(false);

  const [settingsParms, setSettingsParms] = useState(true);
  const [settingsCancelReason, setSettingsCancelReason] = useState(true);

  const [userId, setUserId] = useState("");

  const [invalidName, setInvalidName] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidUsernameText, setInvalidUsernameText] = useState("Informe o login do usuário");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailText, setInvalidEmailText] = useState("Informe o e-mail");
  const [invalidPassword, setInvalidPassword] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal(mode, userInfo = {}) {
      setModalBox(true);
      setIsUpdate(false);

      resetFields();

      if (mode === "update") {
        setIsUpdate(true);

        setUserId(userInfo._id);
        setName(userInfo.name);
        setUsername(userInfo.username);
        setEmail(userInfo.email);
        setActive(userInfo.status);

        if (userInfo.roles && userInfo.roles !== "") {
          const roles = userInfo.roles;

          let verifyAccess = roles.find(x => x.feature === "dashboard" && x.function === "access");
          setDashboardAccess(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "user");
          setRecordUser(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "health_plan");
          setRecordHealthPlan(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "doctor");
          setRecordDoctor(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "hospital");
          setRecordHospital(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "pacient");
          setRecordPacient(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "supplier");
          setRecordSupplier(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "procedure_group");
          setRecordProcedureGroup(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "procedure_type");
          setRecordProcedureType(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "record" && x.function === "procedure");
          setRecordProcedure(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "schedule" && x.function === "read");
          setScheduleRead(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "preschedule" && x.function === "create");
          setPreScheduleCreate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "preschedule" && x.function === "update");
          setPreScheduleUpdate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "preschedule" && x.function === "read");
          setPreScheduleRead(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "preschedule" && x.function === "delete");
          setPreScheduleDelete(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "scale" && x.function === "read");
          setScaleRead(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "scale" && x.function === "update");
          setScaleUpdate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "availability" && x.function === "read");
          setAvailabilityRead(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "availability" && x.function === "update");
          setAvailabilityUpdate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "clinic" && x.function === "medical_record");
          setClinicMedicalRecord(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "protocol" && x.function === "create");
          setProtocolCreate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "protocol" && x.function === "update");
          setProtocolUpdate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "protocol" && x.function === "read");
          setProtocolRead(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "protocol" && x.function === "delete");
          setProtocolDelete(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "report" && x.function === "create");
          setReportCreate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "report" && x.function === "update");
          setReportUpdate(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "report" && x.function === "read");
          setReportRead(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "operational" && x.function === "invoice");
          setOperationalInvoice(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "operational" && x.function === "receipt");
          setOperationalReceipt(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "operational" && x.function === "commission");
          setOperationalCommission(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "settings" && x.function === "parms");
          setSettingsParms(verifyAccess ? verifyAccess.access : false);

          verifyAccess = roles.find(x => x.feature === "settings" && x.function === "cancel_reason");
          setSettingsCancelReason(verifyAccess ? verifyAccess.access : false);
        }
      }
    }
  }));

  async function handleSaveUser(e) {
    e.preventDefault();

    setLoadingUserForm(true);

    setInvalidName(false);
    setInvalidUsername(false);
    setInvalidEmail(false);
    setInvalidPassword(false);

    let hasError = false;

    if (name === "") {
      setInvalidName(true);
      hasError = true;
    }

    if (username === "") {
      setInvalidUsername(true);
      setInvalidUsernameText("Informe o login do usuário");
      hasError = true;
    } else {
      if (!isUpdate) {
        await api.get(`/users/username/${username}`)
          .then(response => {
            if (response.status === 200) {
              setInvalidUsername(true);
              setInvalidUsernameText("Login já cadastrado");
              hasError = true;
            }
          });
      }
    }

    if (email === "") {
      setInvalidEmail(true);
      hasError = true;
    } else {
      if (!isEmail(email)) {
        setInvalidEmail(true);
        setInvalidEmailText("Informe um e-mail válido");
        hasError = true;
      }
    }

    if (!isUpdate && password === "") {
      setInvalidPassword(true);
      hasError = true;
    }

    if (!hasError) {
      const roles = [
        {
          feature: "dashboard",
          function: "access",
          access: dashboardAccess
        },
        {
          feature: "record",
          function: "user",
          access: recordUser
        },
        {
          feature: "record",
          function: "health_plan",
          access: recordHealthPlan
        },
        {
          feature: "record",
          function: "doctor",
          access: recordDoctor
        },
        {
          feature: "record",
          function: "hospital",
          access: recordHospital
        },
        {
          feature: "record",
          function: "pacient",
          access: recordPacient
        },
        {
          feature: "record",
          function: "supplier",
          access: recordSupplier
        },
        {
          feature: "record",
          function: "procedure_group",
          access: recordProcedureGroup
        },
        {
          feature: "record",
          function: "procedure_type",
          access: recordProcedureType
        },
        {
          feature: "record",
          function: "procedure",
          access: recordProcedure
        },
        {
          feature: "schedule",
          function: "read",
          access: scheduleRead
        },
        {
          feature: "preschedule",
          function: "create",
          access: preScheduleCreate
        },
        {
          feature: "preschedule",
          function: "update",
          access: preScheduleUpdate
        },
        {
          feature: "preschedule",
          function: "read",
          access: preScheduleRead
        },
        {
          feature: "preschedule",
          function: "delete",
          access: preScheduleDelete
        },
        {
          feature: "scale",
          function: "read",
          access: scaleRead
        },
        {
          feature: "scale",
          function: "update",
          access: scaleUpdate
        },
        {
          feature: "availability",
          function: "read",
          access: availabilityRead
        },
        {
          feature: "availability",
          function: "update",
          access: availabilityUpdate
        },
        {
          feature: "clinic",
          function: "medical_record",
          access: clinicMedicalRecord
        },
        {
          feature: "protocol",
          function: "create",
          access: protocolCreate
        },
        {
          feature: "protocol",
          function: "update",
          access: protocolUpdate
        },
        {
          feature: "protocol",
          function: "read",
          access: protocolRead
        },
        {
          feature: "protocol",
          function: "delete",
          access: protocolDelete
        },
        {
          feature: "report",
          function: "create",
          access: reportCreate
        },
        {
          feature: "report",
          function: "update",
          access: reportUpdate
        },
        {
          feature: "report",
          function: "read",
          access: reportRead
        },
        {
          feature: "operational",
          function: "invoice",
          access: operationalInvoice
        },
        {
          feature: "operational",
          function: "receipt",
          access: operationalReceipt
        },
        {
          feature: "operational",
          function: "commission",
          access: operationalCommission
        },
        {
          feature: "settings",
          function: "parms",
          access: settingsParms
        },
        {
          feature: "settings",
          function: "cancel_reason",
          access: settingsCancelReason
        }
      ];

      const user = {
        username,
        name,
        email,
        password,
        roles,
        status: active
      };

      if (!isUpdate) {
        try {
          await api.post("/users", user)
            .then(response => {
              if (response.status === 201) {
                createLog({
                  routine: "user",
                  action: "create",
                  type: "success",
                  message: `Usuário ${user.name} incluído`,
                  jsonOrigin: JSON.stringify(user),
                  jsonReturn: JSON.stringify(response)
                });

                setLoadingUserForm(false);
                notifySuccess();
                resetFields();
                onClose();
                setModalBox(false);
              }
            })
        } catch (err) {
          createLog({
            routine: "user",
            action: "create",
            type: "error",
            message: `Usuário ${user.name} não incluído`,
            jsonOrigin: JSON.stringify(user),
            jsonReturn: JSON.stringify(err)
          });

          notifyError();
        }
      } else {
        try {
          await api.put(`/users/${userId}`, user)
            .then(response => {
              if (response.status === 200) {
                createLog({
                  routine: "user",
                  action: "update",
                  type: "success",
                  message: `Usuário ${user.name} alterado`,
                  jsonOrigin: JSON.stringify(user),
                  jsonReturn: JSON.stringify(response)
                });

                setLoadingUserForm(false);
                notifySuccess();
                resetFields();
                onClose();
                setModalBox(false);
              }
            })
        } catch (err) {
          createLog({
            routine: "user",
            action: "update",
            type: "error",
            message: `Usuário ${user.name} não alterado`,
            jsonOrigin: JSON.stringify(user),
            jsonReturn: JSON.stringify(err)
          });

          notifyError();
        }
      }
    }

    setLoadingUserForm(false);
  }

  function resetFields() {
    setUserId("");
    setName("");
    setUsername("");
    setEmail("");
    setPassword("");
    setActive(true);

    setDashboardAccess(true);

    setRecordUser(true);
    setRecordHealthPlan(true);
    setRecordDoctor(true);
    setRecordHospital(true);
    setRecordPacient(true);
    setRecordSupplier(true);
    setRecordProcedureGroup(true);
    setRecordProcedureType(true);
    setRecordProcedure(true);

    setScheduleRead(true);

    setPreScheduleCreate(true);
    setPreScheduleUpdate(true);
    setPreScheduleRead(true);
    setPreScheduleDelete(true);

    setScaleRead(true);
    setScaleUpdate(true);

    setAvailabilityRead(true);
    setAvailabilityUpdate(true);

    setClinicMedicalRecord(true);

    setProtocolCreate(true);
    setProtocolUpdate(true);
    setProtocolRead(true);
    setProtocolDelete(true);

    setReportCreate(true);
    setReportUpdate(true);
    setReportRead(true);

    setOperationalInvoice(false);
    setOperationalReceipt(false);
    setOperationalCommission(false);

    setSettingsParms(true);
    setSettingsCancelReason(true);

    setInvalidName(false);
    setInvalidUsername(false);
    setInvalidUsernameText("Informe o login do usuário");
    setInvalidEmail(false);
    setInvalidEmailText("Informe o e-mail");
    setInvalidPassword(false);
    setLoadingUserForm(false);
  }

  function isEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function notifySuccess() {
    toast.success(`✔️ Usuário ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function notifyError() {
    toast.error("❌ Ocorreu um problema ao salvar o usuário", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <Modal size='xl' fullscreen onHide={setModalBox} show={modalBox}>
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">{(!isUpdate ? "Novo" : "Alterar")} Usuário</h3>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <div className="row" hidden={!isUpdate}>
                <div className="form-group mb-3 col-md-6">
                  <FormCheck
                    type="switch"
                    id="active"
                    label="Ativo"
                    defaultChecked={active}
                    onChange={() => setActive(!active)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Nome</label>
                  <input
                    type="text"
                    className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                    value={name}
                    onChange={e => setName(e.target.value)}
                  />
                  <DFormText hidden={!invalidName} color="danger">Informe o nome</DFormText>
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Login</label>
                  <input
                    type="text"
                    className={`form-control ${(invalidUsername ? "is-invalid" : "")}`}
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                    disabled={isUpdate}
                  />
                  <DFormText hidden={!invalidUsername} color="danger">{invalidUsernameText}</DFormText>
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Email</label>
                  <input
                    type="email"
                    className={`form-control ${(invalidEmail ? "is-invalid" : "")}`}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <DFormText hidden={!invalidEmail} color="danger">{invalidEmailText}</DFormText>
                </div>
                <div className="form-group mb-3 col-md-6">
                  <label>Senha</label>
                  <input
                    type="password"
                    className={`form-control ${(invalidPassword ? "is-invalid" : "")}`}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <DFormText hidden={!invalidPassword} color="danger">Informe uma senha</DFormText>
                </div>
              </div>
              <br />
              <h5>Acessos</h5>
              <div className="row">
                <div className="form-group mb-12 col-md-12">
                  <Tab.Container>
                    <Tab.Content>
                      <div className="basic-list-group">
                        <Row>
                          <Tab.Container defaultActiveKey="#records">
                            <Col lg="6" xl="2">
                              <ListGroup className="mb-4" id="list-tab">
                                <ListGroup.Item action href="#dashboard">
                                  Dashboard
                                </ListGroup.Item>
                                <ListGroup.Item action href="#records">
                                  Cadastros
                                </ListGroup.Item>
                                <ListGroup.Item action href="#schedule">
                                  Agenda
                                </ListGroup.Item>
                                <ListGroup.Item action href="#clinic">
                                  Procedimento
                                </ListGroup.Item>
                                <ListGroup.Item action href="#protocol">
                                  Protocolo
                                </ListGroup.Item>
                                <ListGroup.Item action href="#operational">
                                  Operacional
                                </ListGroup.Item>
                                <ListGroup.Item action href="#settings">
                                  Configurações
                                </ListGroup.Item>
                              </ListGroup>
                            </Col>
                            <Col lg="6" xl="10">
                              <Tab.Content>
                                <Tab.Pane eventKey="#dashboard">
                                  <h6 className="mb-4">Visualização do Dashboard</h6>
                                  <div className="row">
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Dashboard"
                                        defaultChecked={dashboardAccess}
                                        onChange={() => setDashboardAccess(!dashboardAccess)}
                                      />
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="#records">
                                  <h6 className="mb-4">Rotinas de cadastro</h6>
                                  <div className="row">
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Usuários"
                                        defaultChecked={recordUser}
                                        onChange={() => setRecordUser(!recordUser)}
                                      />
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Convênios"
                                        defaultChecked={recordHealthPlan}
                                        onChange={() => setRecordHealthPlan(!recordHealthPlan)}
                                      />
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Médicos"
                                        defaultChecked={recordDoctor}
                                        onChange={() => setRecordDoctor(!recordDoctor)}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Hospitais"
                                        defaultChecked={recordHospital}
                                        onChange={() => setRecordHospital(!recordHospital)}
                                      />
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Pacientes"
                                        defaultChecked={recordPacient}
                                        onChange={() => setRecordPacient(!recordPacient)}
                                      />
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Fornecedores"
                                        defaultChecked={recordSupplier}
                                        onChange={() => setRecordSupplier(!recordSupplier)}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Grupos de Procedimentos"
                                        defaultChecked={recordProcedureGroup}
                                        onChange={() => setRecordProcedureGroup(!recordProcedureGroup)}
                                      />
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Tipos de Procedimentos"
                                        defaultChecked={recordProcedureType}
                                        onChange={() => setRecordProcedureType(!recordProcedureType)}
                                      />
                                    </div>
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Procedimentos"
                                        defaultChecked={recordProcedure}
                                        onChange={() => setRecordProcedure(!recordProcedure)}
                                      />
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="#schedule">
                                  <h6 className="mb-4">Agenda</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Consulta"
                                        defaultChecked={scheduleRead}
                                        onChange={() => setScheduleRead(!scheduleRead)}
                                      />
                                    </div>
                                  </div>

                                  <h6 className="mb-4">Pré-Agenda</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Inclusão"
                                        defaultChecked={preScheduleCreate}
                                        onChange={() => setPreScheduleCreate(!preScheduleCreate)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Alteração"
                                        defaultChecked={preScheduleUpdate}
                                        onChange={() => setPreScheduleUpdate(!preScheduleUpdate)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Consulta"
                                        defaultChecked={preScheduleRead}
                                        onChange={() => setPreScheduleRead(!preScheduleRead)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Cancelamento"
                                        defaultChecked={preScheduleDelete}
                                        onChange={() => setPreScheduleDelete(!preScheduleDelete)}
                                      />
                                    </div>
                                  </div>

                                  <h6 className="mb-4">Escalas</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Consulta"
                                        defaultChecked={scaleRead}
                                        onChange={() => setScaleRead(!scaleRead)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Alteração"
                                        defaultChecked={scaleUpdate}
                                        onChange={() => setScaleUpdate(!scaleUpdate)}
                                      />
                                    </div>
                                  </div>

                                  <h6 className="mb-4">Indisponibilidade do médico</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Consulta"
                                        defaultChecked={availabilityRead}
                                        onChange={() => setAvailabilityRead(!availabilityRead)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Alteração"
                                        defaultChecked={availabilityUpdate}
                                        onChange={() => setAvailabilityUpdate(!availabilityUpdate)}
                                      />
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="#clinic">
                                  <h6 className="mb-4">Prontuário</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Acesso"
                                        defaultChecked={clinicMedicalRecord}
                                        onChange={() => setClinicMedicalRecord(!clinicMedicalRecord)}
                                      />
                                    </div>
                                  </div>

                                  <h6 className="mb-4">Laudos</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Inclusão"
                                        defaultChecked={reportCreate}
                                        onChange={() => setReportCreate(!reportCreate)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Alteração"
                                        defaultChecked={reportUpdate}
                                        onChange={() => setReportUpdate(!reportUpdate)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Consulta"
                                        defaultChecked={reportRead}
                                        onChange={() => setReportRead(!reportRead)}
                                      />
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="#protocol">
                                  <h6 className="mb-4">Protocolos</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Inclusão"
                                        defaultChecked={protocolCreate}
                                        onChange={() => setProtocolCreate(!protocolCreate)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Alteração"
                                        defaultChecked={protocolUpdate}
                                        onChange={() => setProtocolUpdate(!protocolUpdate)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Consulta"
                                        defaultChecked={protocolRead}
                                        onChange={() => setProtocolRead(!protocolRead)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Exclusão"
                                        defaultChecked={protocolDelete}
                                        onChange={() => setProtocolDelete(!protocolDelete)}
                                      />
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="#operational">
                                  <h6 className="mb-4">Operacional</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Faturamento"
                                        defaultChecked={operationalInvoice}
                                        onChange={() => setOperationalInvoice(!operationalInvoice)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Recebimento"
                                        defaultChecked={operationalReceipt}
                                        onChange={() => setOperationalReceipt(!operationalReceipt)}
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Rateio"
                                        defaultChecked={operationalCommission}
                                        onChange={() => setOperationalCommission(!operationalCommission)}
                                      />
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="#settings">
                                  <h6 className="mb-4">Configurações da aplicação e cadastros auxiliares</h6>
                                  <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Parâmetros"
                                        defaultChecked={settingsParms}
                                        onChange={() => setSettingsParms(!settingsParms)}
                                      />
                                    </div>
                                  </div>
                                  <h6 className="mb-4">Cadastros auxiliares</h6>
                                  <div className="row">
                                    <div className="form-group mb-4 col-md-4">
                                      <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Motivos de Cancelamento"
                                        defaultChecked={settingsCancelReason}
                                        onChange={() => setSettingsCancelReason(!settingsCancelReason)}
                                      />
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Tab.Container>
                        </Row>
                      </div>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
            <Button variant="success btn-rounded" onClick={handleSaveUser}>Salvar</Button>
          </div>
        </div>
      </Modal>

      <ToastContainer />

      <ModalLoading
        visible={loadingUserForm}
        message="Salvando Usuário..."
        onClose={setLoadingUserForm}
      />
    </>
  )
})

export default UserForm;