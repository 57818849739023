import axios from "axios";

const API_URL = process.env.NODE_ENV === "production" ? "https://api.cfnk.com.br" : "http://localhost:3333";

const api = axios.create({
    baseURL: API_URL,
    headers: {
        Accept: "application/json",
    }
});

api.interceptors.request.use(config => {
    let accessToken = undefined;
    if (localStorage.getItem("xLcxRem")) {
        accessToken = localStorage.getItem("xLcxTk");
    } else {
        accessToken = sessionStorage.getItem("xLcxTk");
    }
    if (accessToken) {
        config.headers.common = { Authorization: `Bearer ${accessToken}` };
    }
    return config;
},
    error => {
        Promise.reject(error.response || error.message);
    })

api.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let originalRequest = error.config;
        let refreshToken = sessionStorage.getItem("xLcxTkr");
        if (localStorage.getItem("xLcxRem")) {
            refreshToken = localStorage.getItem("xLcxTkr");
        }

        if (refreshToken && error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            return axios.post(`${API_URL}/refresh-token`, {
                token: refreshToken
            }).then(res => {
                if (res.status === 200) {
                    if (localStorage.getItem("xLcxRem")) {
                        localStorage.setItem("xLcxTk", res.data.token);
                        localStorage.setItem("xLcxTkr", res.data.refresh_token);
                    } else {
                        sessionStorage.setItem("xLcxTk", res.data.token);
                        sessionStorage.setItem("xLcxTkr", res.data.refresh_token);
                    }

                    originalRequest.headers['Authorization'] = `Bearer ${res.data.token}`;

                    return axios(originalRequest);
                }
            }).catch(() => {
                sessionStorage.clear();
                localStorage.clear();
                window.location.assign("/login");
            });
        }
        return Promise.reject(error.response || error.message);
    }
)

export default api;