import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
import Select from "react-select";

import { getMonthTranslated, isAuthenticated } from "../../../services/utils";
import api from "../../../services/api";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

const ScheduleBody = () => {
    const [loading, setLoading] = useState(false);
    const [scheduleItems, setScheduleItems] = useState([]);

    const [doctorsList, setDoctorsList] = useState([]);
    const [availabilityList, setAvailabilityList] = useState([]);

    const [startDateFilter, setStartDateFilter] = useState(dayjs().startOf("week").toDate());
    const [endDateFilter, setEndDateFilter] = useState(dayjs().endOf("week").toDate());
    const [periodFilter, setPeriodFilter] = useState("");
    const [doctorsFilterList, setDoctorsFilterList] = useState([]);
    const [doctorFilter, setDoctorFilter] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated()) {
            return history.push("/login");
        }

        setLoading(true);

        getDoctors();
        getSchedule();
        getAvailabilty();

        setLoading(false);
    }, [])

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true")
            .then(response => {
                if (response.status === 200) {
                    setDoctorsList(response.data.doctors);

                    const doctorsReturned = response.data.doctors.map(doctor => {
                        return {
                            value: doctor._id,
                            label: doctor.name
                        }
                    });
                    setDoctorsFilterList(doctorsReturned);
                }
            })
    }

    async function getSchedule(startDate = startDateFilter, endDate = endDateFilter, doctorId) {
        let filterDescription = `${dayjs(startDate).get("D").toString()} de ${getMonthTranslated(dayjs(startDate).get("M") + 1, "short")}. - ${dayjs(endDate).get("D").toString()} de ${getMonthTranslated(dayjs(endDate).get("M") + 1, "short")}. de ${dayjs(startDate).format("YYYY")}`;
        setPeriodFilter(filterDescription);

        let filter = "";
        if (doctorId) {
            filter += `&doctorId=${doctorId}`;
        }

        await api.get(`/schedules?offset=1&limit=100&status=sch&startDate=${dayjs(startDate).toISOString()}&endDate=${dayjs(endDate).toISOString()}${filter}`)
            .then(response => {
                if (response.status === 200) {
                    const scheduleItemsReturned = response.data.schedules.map(scheduleItem => {
                        const has_assistant = scheduleItem.scale_assistant ?? false;
                        const has_resident = scheduleItem.scale_resident ?? false;

                        return {
                            ...scheduleItem,
                            scale_assistant: has_assistant,
                            scale_resident: has_resident
                        }
                    })

                    setScheduleItems(scheduleItemsReturned);
                }
            })
    }

    async function getAvailabilty(startDate = startDateFilter, endDate = endDateFilter) {
        await api.get(`/availabilities?startDate=${dayjs(startDate).toISOString()}&endDate=${dayjs(endDate).toISOString()}`)
            .then(response => {
                if (response.status === 200) {
                    setAvailabilityList(response.data.availabilities);
                }
            })
    }

    function handleClick(pacientId) {
        history.push(`/medical-record/${pacientId}`);
    }

    function handleChangeWeek(type) {
        setLoading(true);

        let newStartDate = startDateFilter;

        if (type === "B") {
            newStartDate = dayjs(startDateFilter).subtract(1, "week").toDate();
        } else if (type === "A") {
            newStartDate = dayjs(endDateFilter).add(1, "milisecond").toDate();
        } else {
            newStartDate = dayjs().startOf("week").toDate();
        }

        const newEndDate = dayjs(newStartDate).endOf("week").toDate();

        setStartDateFilter(newStartDate);
        setEndDateFilter(newEndDate);

        getSchedule(newStartDate, newEndDate);
        getAvailabilty(newStartDate, newEndDate);

        setLoading(false);
    }

    function handleChangeDoctorFilter(e) {
        setDoctorFilter(e);

        getSchedule(undefined, undefined, e?.value);
        getAvailabilty();
    }

    return (
        <>
            <div id="calendar" className="app-fullcalendar">
                <div className="app-fullcalendar" id="calendar">
                    <div className="fc fc-media-screen fc-direction-ltr fc-theme-standard" style={{ height: "580px" }}>
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                                <label>Médico</label>
                                <Select
                                    value={doctorFilter}
                                    defaultValue={doctorFilter}
                                    options={doctorsFilterList}
                                    placeholder="Selecione..."
                                    onChange={(e) => handleChangeDoctorFilter(e)}
                                    isClearable
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: 45,
                                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="fc-header-toolbar fc-toolbar ">
                            <div className="fc-toolbar-chunk">

                                <div className="fc-button-group">
                                    <button
                                        type="button"
                                        title="Semana Anterior"
                                        aria-pressed="false"
                                        className="fc-prev-button fc-button fc-button-primary"
                                        onClick={() => handleChangeWeek("B")}
                                    >
                                        <span className="fc-icon fc-icon-chevron-left">
                                        </span>
                                    </button>
                                    <button
                                        type="button"
                                        title="Próxima Semana"
                                        aria-pressed="false"
                                        className="fc-next-button fc-button fc-button-primary"
                                        onClick={() => handleChangeWeek("A")}
                                    >
                                        <span className="fc-icon fc-icon-chevron-right">
                                        </span>
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    title="Esta semana"
                                    aria-pressed="false"
                                    className="fc-today-button fc-button fc-button-primary"
                                    onClick={() => handleChangeWeek("T")}
                                >
                                    Hoje
                                </button>
                            </div>
                            <div className="fc-toolbar-chunk">
                                <h2 className="fc-toolbar-title" id="fc-dom-5">{periodFilter}</h2>
                            </div>
                            <div className="fc-toolbar-chunk" />
                        </div>
                        <div aria-labelledby="fc-dom-5" className="fc-view-harness fc-view-harness-active">
                            <div className="fc-listWeek-view fc-view fc-list fc-list-sticky">
                                <div className="fc-scroller fc-scroller-liquid" style={{ overflow: "hidden auto" }}>
                                    <Table responsive>
                                        <thead>
                                            <tr key="header_schedule">
                                                <th key="header_date">Data</th>
                                                <th key="header_pacient">Paciente</th>
                                                <th key="header_health">Convênio</th>
                                                <th key="header_hospital">Hospital</th>
                                                <th key="header_doctor">Médico Resp.</th>
                                                <th key="header_diag">Diagnóstico</th>
                                                <th key="header_supplier">Empresa</th>
                                                {
                                                    doctorsList.map(doctor => {
                                                        return <th key={`header_${doctor._id}`}>{doctor.short_name}</th>
                                                    })
                                                }
                                                <th key="header_assist">Adriane</th>
                                                <th key="header_resident">Residente</th>
                                                <th key="header_resident">Observações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                scheduleItems.map((schedule) => {
                                                    return (
                                                        <tr
                                                            key={schedule._id}
                                                            onClick={() => handleClick(schedule.pacient_id)}
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <td key={`td_date_${schedule._id}`}>
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{dayjs(schedule.schedule_date).format("DD/MM/YYYY - HH:mm")}</span>
                                                                </div>
                                                            </td>
                                                            <td key={`td_pacient_${schedule._id}`}>
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{schedule.pacient_name}</span>
                                                                </div>
                                                            </td>
                                                            <td key={`td_health_${schedule._id}`}>
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{(schedule.health_plan_short_name ? schedule.health_plan_short_name : schedule.health_plan_name)}</span>
                                                                </div>
                                                            </td>
                                                            <td key={`td_hospital_${schedule._id}`}>
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{(schedule.hospital_short_name ? schedule.hospital_short_name : schedule.hospital_name)}</span>
                                                                </div>
                                                            </td>
                                                            <td key={`td_doctor_${schedule._id}`}>
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{(schedule.main_doctor_short_name ? schedule.main_doctor_short_name : schedule.main_doctor_name)}</span>
                                                                </div>
                                                            </td>
                                                            <td key={`td_diag_${schedule._id}`}>
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{schedule.diagnosis}</span>
                                                                </div>
                                                            </td>
                                                            <td key={`td_supplier_${schedule._id}`}>
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{(schedule.supplier_short_name ? schedule.supplier_short_name : schedule.supplier_name)}</span>
                                                                </div>
                                                            </td>
                                                            {
                                                                doctorsList.map(doctor => {
                                                                    const markedDoctor = schedule.doctors?.find(x => x.doctor_id === doctor._id);
                                                                    const isForwarder = (schedule.forward_doctor_id === doctor._id);
                                                                    const doctorUnavailabity = availabilityList.find(x => x.doctor_id === doctor._id && dayjs(schedule.schedule_date).isAfter(x.date_from) && dayjs(schedule.schedule_date).isBefore(x.date_to));

                                                                    return (
                                                                        <td
                                                                            id={`${schedule._id}td${doctor._id}`}
                                                                            key={`${schedule._id}td${doctor._id}`}
                                                                            style={{
                                                                                backgroundColor: markedDoctor ? "#e0e0e0" : "#fff",
                                                                                border: "1px solid"
                                                                            }}
                                                                        >
                                                                            {isForwarder ? "* " : ""}
                                                                            {doctorUnavailabity ? "OUT " : ""}
                                                                            {markedDoctor?.observation ? markedDoctor.observation : ""}
                                                                        </td>
                                                                    )
                                                                })
                                                            }

                                                            <td
                                                                id={`${schedule._id}tdAssistant`}
                                                                key={`${schedule._id}tdAssistant`}
                                                                style={{
                                                                    backgroundColor: schedule.scale_assistant ? "#f9e6ca" : "#fff",
                                                                    border: "1px solid"
                                                                }}
                                                            />

                                                            <td
                                                                id={`${schedule._id}tdResident`}
                                                                key={`${schedule._id}tdResident`}
                                                                style={{
                                                                    backgroundColor: schedule.scale_resident ? "#b5e4ec" : "#fff",
                                                                    border: "1px solid"
                                                                }}
                                                            />

                                                            <td
                                                                key={`td_pacient_${schedule._id}`}
                                                                style={{ border: "1px solid" }}
                                                            >
                                                                <div className="d-flex align-items-left">
                                                                    <span className="w-space-no">{schedule?.scale_observation ?? ""}</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalLoading
                visible={loading}
                message="Carregando Registros..."
                onClose={setLoading}
            />
        </>
    );
};

export default ScheduleBody;
