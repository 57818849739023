import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';

import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from 'react-bootstrap';

import "./styles.css";

import useViewPort from '../../../hooks/useViewPort';

import SimpleInput from './SimpleInput/SimpleInput';
import { hasAccess } from '../../../services/utils';
import { createLog } from '../../../services/logService';

import api from '../../../services/api';
import { loadingToggleAction, loginAction } from '../../../store/actions/AuthActions';


const Login = (props) => {
	const { isDesktop } = useViewPort();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const [loading, setLoading] = useState(false);
	let toasterMessage = "";

	const dispatch = useDispatch();

	// useEffect(() => {
	// 	const token = localStorage.getItem("xLcxRem") ? localStorage.getItem('xLcxTk') : sessionStorage.getItem('xLcxTk');
	// 	if (token) {
	// 		props.history.push("/schedule");
	// 	}

	// 	document.body.style.overflow = "hidden";
	// 	return () => {
	// 		document.body.style.overflow = "visible";
	// 	}
	// }, [])

	async function handleLogin(e) {
		e.preventDefault();

		if (username === '') {
			toasterMessage = "Informe o nome de usuário!";
			notifyWarning();
			return;
		}

		// setLoading(true);

		dispatch(loadingToggleAction(true));
		dispatch(loginAction(username, password, remember, props.history));

		// const userInfoToSend = {
		// 	username,
		// 	password
		// }

		// try {
		// 	await api.post("/sessions", userInfoToSend)
		// 		.then(response => {
		// 			if (remember) {
		// 				localStorage.setItem("xLcxRem", "true");
		// 				localStorage.setItem("xLcxTk", response.data.token);
		// 				localStorage.setItem("xLcxTkr", response.data.refresh_token);
		// 				localStorage.setItem("xLcxUsr", JSON.stringify(response.data.user));
		// 			} else {
		// 				sessionStorage.setItem("xLcxTk", response.data.token);
		// 				sessionStorage.setItem("xLcxTkr", response.data.refresh_token);
		// 				sessionStorage.setItem("xLcxUsr", JSON.stringify(response.data.user));
		// 			}

		// 			createLog({
		// 				routine: "login",
		// 				action: "login",
		// 				type: "success",
		// 				message: "Login realizado",
		// 				jsonOrigin: JSON.stringify(userInfoToSend),
		// 				jsonReturn: JSON.stringify(response)
		// 			});

		// 			if (hasAccess("schedule", "read")) {
		// 				props.history.push("/schedule");
		// 			} else if (hasAccess("clinic", "medical_record")) {
		// 				props.history.push("/medical-record");
		// 			} else if (hasAccess("dashboard", "access")) {
		// 				props.history.push("/dashboard");
		// 			} else if (hasAccess("protocol", "create")) {
		// 				props.history.push("/protocol-form");
		// 			} else if (hasAccess("preschedule", "read")) {
		// 				props.history.push("/preschedule");
		// 			} else if (hasAccess("operational", "invoice")) {
		// 				props.history.push("/invoice");
		// 			} else if (hasAccess("operational", "receipt")) {
		// 				props.history.push("/receipt");
		// 			} else if (hasAccess("operational", "commission")) {
		// 				props.history.push("/commission");
		// 			}
		// 		})
		// } catch (err) {
		// 	setLoading(false);
		// 	toasterMessage = "Usuário ou senha incorretos!";
		// 	notifyError();
		// }
	}

	const notifyWarning = () => {
		toast.warn(toasterMessage, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			draggable: true,
			progress: undefined,
		});
	};

	function notifyError() {
		toast.error(`❌ ${toasterMessage}`, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			draggable: true,
			progress: undefined,
		});
	}

	return (
		<div className="overflow-hidden bg-orange-primary-600">
			<div
				className={`h-screen w-screen absolute z-0 ${isDesktop ? 'animation_moving_background_login' : ''}`}
				style={{
					backgroundImage: "url('/images/background_login.png')",
					backgroundColor: "var(--primary)",
					backgroundPosition: "0px 0px",
					backgroundSize: "auto 180%"
				}}
			/>
			<div className="h-screen w-screen lg-grid lg-grid-cols-3 lg-items-stretch flex justify-center items-center">
				{isDesktop &&
					<div className="col-span-2">
						<img
							alt="imagem decorativa"
							src="./images/A.svg"
							className="absolute z-0 opacity-50 left-0 animation-moving_letter"
							style={{
								height: "130vh",
								width: "100vw",
								right: "-25.78%",
								top: "-20.03%",
								bottom: "-68.46%"
							}}
						/>
						<div className="absolute opacity-50 animation-moving_circle" >
							<img alt="circle" src="./images/circle.svg" className="backdrop-blur-lg " height={450} width={450} />
						</div>
					</div>
				}

				<div className="umx-5 up-5 bg-orange-primary-50 rounded-xl lg-mx-0 lg-rounded-none col-span-1 align flex flex-col justify-center items-center opacity-75 backdrop-blur-lg z-10">
					<div>
						<img src="./images/lec-logo-2.png" alt="Logo LEC" height={200} width={335} />
					</div>

					<div className="mt-20">
						<SimpleInput label="Usuário" value={username} onChange={setUsername} />
						<SimpleInput label="Senha" type="password" value={password} onChange={setPassword} />

						<div className="w-full mt-4 flex justify-between">
							<label>
								<input
									className="w-4 h-4"
									type="checkbox"
									defaultChecked={remember}
									onChange={() => setRemember(!remember)}
								/>
								<span className="ms-2">Manter conectado</span>
							</label>
						</div>

						<button className="mt-11 h-11 uppercase btn btn-primary btn-block" onClick={handleLogin}>Entrar</button>
					</div>
				</div>
			</div>

			<ToastContainer />

			<Modal
				className="fade"
				show={props.showLoading}
				onHide={dispatch(loadingToggleAction(false))}
				centered
			>
				<Modal.Body>
					<div className="row text-center">
						<div className="col-xl-12">
							Efetuando login...
						</div>
					</div>
					<div className="row text-center">
						<div className="col-xl-12">
							<Spinner />
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading
	};
};

export default connect(mapStateToProps)(Login);
