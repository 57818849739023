import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
    actualDaaOptions,
    anticoagOptions,
    ecgConsultOptions,
    mainSymptomOptions,
    protocolYesNoOptions
} from "../../../enum/protocolEnums";
import {
    avaliationOptions,
    holterOptions,
    faPlaceOptions
} from "../../../enum/followUpEnum";

const FollowFaForm = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        followInfoParm,
        hide,
        ...attributes
    } = props;

    const [avaliation, setAvaliation] = useState(null);
    const [otherAvaliation, setOtherAvaliation] = useState("");
    const [place, setPlace] = useState(null);
    const [otherPlace, setOtherPlace] = useState("");
    const [mainSymptom, setMainSymptom] = useState(null);
    const [otherMainSymptom, setOtherMainSymptom] = useState("");
    const [ecg, setEcg] = useState(null);
    const [otherEcg, setOtherEcg] = useState("");
    const [anticoag, setAnticoag] = useState(null);
    const [otherAnticoag, setOtherAnticoag] = useState("");
    const [betablok, setBetablok] = useState(null);
    const [actualDaa, setActualDaa] = useState(null);
    const [otherActualDaa, setOtherActualDaa] = useState("");
    const [holter, setHolter] = useState(null);
    const [otherHolter, setOtherHolter] = useState("");
    const [otherExams, setOtherExams] = useState("");
    const [observation, setObservation] = useState("");

    useEffect(() => {
        setAvaliation(followInfoParm.avaliation ?? null);
        setOtherAvaliation(followInfoParm.otherAvaliation ?? "");
        setPlace(followInfoParm.place ?? null);
        setOtherPlace(followInfoParm.otherPlace ?? "");
        setMainSymptom(followInfoParm.mainSymptom ?? null);
        setEcg(followInfoParm.ecg ?? null);
        setOtherEcg(followInfoParm.otherEcg ?? "");
        setAnticoag(followInfoParm.anticoag ?? null);
        setOtherAnticoag(followInfoParm.otherAnticoag ?? "");
        setOtherMainSymptom(followInfoParm.otherMainSymptom ?? "");
        setBetablok(followInfoParm.betablok ?? null);
        setActualDaa(followInfoParm.actualDaa ?? null);
        setOtherActualDaa(followInfoParm.otherActualDaa ?? "");
        setHolter(followInfoParm.holter ?? null);
        setOtherHolter(followInfoParm.otherHolter ?? "");
        setOtherExams(followInfoParm.otherExams ?? "");
        setObservation(followInfoParm.observation ?? "");
    }, [followInfoParm]);

    return (
        <div hidden={hide}>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Avaliação</label>
                    <Select
                        autoFocus
                        defaultValue={avaliation}
                        value={avaliation}
                        onChange={(e) => {
                            setAvaliation(e);

                            let fieldsChanges = [
                                {
                                    field: "avaliation",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherAvaliation("");

                                fieldsChanges.push({
                                    field: "otherAvaliation",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={avaliationOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Avaliação (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherAvaliation}
                        onChange={e => {
                            setOtherAvaliation(e.target.value);
                            onChangeInfo([{ field: "otherAvaliation", value: e.target.value}]);
                        }}
                        disabled={avaliation?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Local Consulta</label>
                    <Select
                        autoFocus
                        defaultValue={place}
                        value={place}
                        onChange={(e) => {
                            setPlace(e);

                            let fieldsChanges = [
                                {
                                    field: "place",
                                    value: e
                                }
                            ];

                            if (e.value !== 5) {
                                setOtherPlace("");

                                fieldsChanges.push({
                                    field: "otherPlace",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={faPlaceOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Local Consulta (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherPlace}
                        onChange={e => {
                            setOtherPlace(e.target.value);
                            onChangeInfo([{ field: "otherPlace", value: e.target.value}]);
                        }}
                        disabled={place?.value !== 5}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal</label>
                    <Select
                        autoFocus
                        defaultValue={mainSymptom}
                        value={mainSymptom}
                        onChange={(e) => {
                            setMainSymptom(e);

                            let fieldsChanges = [
                                {
                                    field: "mainSymptom",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherMainSymptom("");

                                fieldsChanges.push({
                                    field: "otherMainSymptom",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={mainSymptomOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMainSymptom}
                        onChange={e => {
                            setOtherMainSymptom(e.target.value);
                            onChangeInfo([{ field: "otherMainSymptom", value: e.target.value}]);
                        }}
                        disabled={mainSymptom?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>ECG na Consulta</label>
                    <Select
                        autoFocus
                        defaultValue={ecg}
                        value={ecg}
                        onChange={(e) => {
                            setEcg(e);

                            let fieldsChanges = [
                                {
                                    field: "ecg",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherEcg("");

                                fieldsChanges.push({
                                    field: "otherEcg",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={ecgConsultOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>ECG na Consulta (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherEcg}
                        onChange={e => {
                            setOtherEcg(e.target.value);
                            onChangeInfo([{ field: "otherEcg", value: e.target.value}]);
                        }}
                        disabled={ecg?.value !== 4}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Anticoag</label>
                    <Select
                        autoFocus
                        defaultValue={anticoag}
                        value={anticoag}
                        onChange={(e) => {
                            setAnticoag(e);

                            let fieldsChanges = [
                                {
                                    field: "anticoag",
                                    value: e
                                }
                            ];

                            if (e.value !== 6) {
                                setOtherAnticoag("");

                                fieldsChanges.push({
                                    field: "otherAnticoag",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={anticoagOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Anticoag (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherAnticoag}
                        onChange={e => {
                            setOtherAnticoag(e.target.value);
                            onChangeInfo([{ field: "otherAnticoag", value: e.target.value }]);
                        }}
                        disabled={anticoag?.value !== 6}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Betablok</label>
                    <Select
                        defaultValue={betablok}
                        value={betablok}
                        onChange={(e) => {
                            setBetablok(e);
                            onChangeInfo([{ field: "betablok", value: e }]);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>DAA Atual</label>
                    <Select
                        defaultValue={actualDaa}
                        value={actualDaa}
                        onChange={(e) => {
                            setActualDaa(e);
                            
                            let fieldsChanges = [
                                {
                                    field: "actualDaa",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherActualDaa("");

                                fieldsChanges.push({
                                    field: "otherActualDaa",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={actualDaaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>DAA Atual (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherActualDaa}
                        onChange={e => {
                            setOtherActualDaa(e.target.value);
                            onChangeInfo([{ field: "otherActualDaa", value: e.target.value }]);
                        }}
                        disabled={actualDaa?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Holter</label>
                    <Select
                        defaultValue={holter}
                        value={holter}
                        onChange={(e) => {
                            setHolter(e);

                            let fieldsChanges = [
                                {
                                    field: "holter",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherHolter("");

                                fieldsChanges.push({
                                    field: "otherHolter",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={holterOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Holter (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherHolter}
                        onChange={e => {
                            setOtherHolter(e.target.value);
                            onChangeInfo([{ field: "otherHolter", value: e.target.value }]);
                        }}
                        disabled={holter?.value !== 4}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-6">
                    <label>Outros Exames</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherExams}
                        onChange={e => {
                            setOtherExams(e.target.value);
                            onChangeInfo([{ field: "otherExams", value: e.target.value }]);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-6">
                    <label>Observações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={observation}
                        onChange={e => {
                            setObservation(e.target.value);
                            onChangeInfo([{ field: "observation", value: e.target.value }]);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

FollowFaForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

FollowFaForm.defaultProps = {
    followInfoParm: {},
    hide: false
};

export default FollowFaForm;