import api from "../../../services/api";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { convertStringToPrint } from "../../../services/utils";
import { alcoholOptions, smokeOptions, yesNoOptions } from "../../../enum/enums";

const imgLogo = "../../../images/lec-logo-2.png";
let linePosition = 4.2;

export async function generateAnamnesisPdf(anamnesisId) {
    const anamnesis = await getAnamnesis(anamnesisId);

    const doc = new jsPDF({
        unit: "cm"
    });

    linePosition = 4.2;

    jsPDF.autoTableSetDefaults({
        headStyles: { fillColor: [55, 75, 69] },
        margin: { top: 4.2 }
    });

    if (anamnesis) {
        doc.addImage(imgLogo, "PNG", 5, 7, 9.7, 6.7);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(24);

        doc.text("ANAMNESE", 10.55, 15, { align: "center" });

        doc.setFontSize(12);
        doc.text("Paciente:", 5.8, 19, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(anamnesis.pacient_name, 6, 19);

        doc.setFont("helvetica", "bold");
        doc.text("Médico Responsável:", 5.8, 19.6, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(anamnesis.doctor_name, 6, 19.6);

        doc.setFont("helvetica", "bold");
        doc.text("Data:", 5.8, 20.2, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(dayjs(anamnesis.createdAt).format("DD/MM/YYYY"), 6, 20.2);

        doc.addPage();
        printHeader(doc);

        if (anamnesis.symptoms) {
            const symptomsList = anamnesis.symptoms;
            if (symptomsList.length > 0) {
                doc.setFont("helvetica", "bold");
                doc.text("Queixas", 1.5, linePosition);

                const symptomsToPrint = symptomsList.map(symptom => {
                    return { symptom: symptom.symptom_description };
                });

                linePosition += 0.5;
                doc.autoTable({
                    startY: linePosition,
                    head: [{ symptom: 'Queixa' }],
                    body: symptomsToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.cardio_needs) {
            const cardioNeedsList = anamnesis.cardio_needs;
            if (cardioNeedsList.length > 0) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Necessidade de Cardioversão", 1.5, linePosition);

                const cardioNeedsToPrint = cardioNeedsList.map(cardioNeed => {
                    return {
                        type: cardioNeed.type_description,
                        month: cardioNeed.month,
                        year: cardioNeed.year
                    };
                });

                linePosition += 0.5;
                checkBreak(doc);
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        type: "Cardioversão",
                        month: "Mês",
                        year: "Ano"
                    }],
                    body: cardioNeedsToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.history_time) {
            const historyTime = anamnesis.history_time;

            if (historyTime.type_id) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Tempo de História", 1.5, linePosition);

                const historyTimeToPrint = [{
                    year: historyTime.year,
                    month: historyTime.month,
                    type: historyTime.type_description,
                }];

                linePosition += 0.5;
                checkBreak(doc);
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        year: "Ano",
                        month: "Mês",
                        type: "Tempo"
                    }],
                    body: historyTimeToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.last_crisis) {
            const lastCrisis = anamnesis.last_crisis;

            if (lastCrisis.years || lastCrisis.months || lastCrisis.weeks || lastCrisis.days) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Tempo Transcorrido da Última Crise", 1.5, linePosition);

                const lastCrisisToPrint = [{
                    years: lastCrisis.years,
                    months: lastCrisis.months,
                    weeks: lastCrisis.weeks,
                    days: lastCrisis.days
                }];

                linePosition += 0.5;
                checkBreak(doc);
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        years: "Anos",
                        months: "Meses",
                        weeks: "Semanas",
                        days: "Dias"
                    }],
                    body: lastCrisisToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.heart_diseases) {
            const heartDiseasesList = anamnesis.heart_diseases;
            if (heartDiseasesList.length > 0) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Cardiopatia de Base", 1.5, linePosition);

                const heartDiseasesToPrint = heartDiseasesList.map(heartDisease => {
                    return {
                        type: heartDisease.type_description,
                        year: heartDisease.year,
                        month: heartDisease.month
                    };
                });

                linePosition += 0.5;
                checkBreak(doc);
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        type: "Cardiopatia",
                        year: "Ano",
                        month: "Mês"
                    }],
                    body: heartDiseasesToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.arrhythmia) {
            const arrhythmiasList = anamnesis.arrhythmia;
            if (arrhythmiasList.length > 0) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Diagnóstico de Arritmia", 1.5, linePosition);

                const arrhythmiasToPrint = arrhythmiasList.map(arrhythmia => {
                    return {
                        type: arrhythmia.type_description,
                        month: arrhythmia.month,
                        year: arrhythmia.year
                    };
                });

                linePosition += 0.5;
                checkBreak(doc);
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        type: "Diagnóstico de Arritmia",
                        month: "Mês",
                        year: "Ano"
                    }],
                    body: arrhythmiasToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.particularities) {
            const particularitiesList = anamnesis.particularities;
            if (particularitiesList.length > 0) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Particularidades", 1.5, linePosition);

                const particularitiesToPrint = particularitiesList.map(particularity => {
                    return {
                        type: particularity.particularity
                    };
                });

                linePosition += 0.5;
                checkBreak(doc);
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        type: "Particularidades"
                    }],
                    body: particularitiesToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.associated_diseases) {
            const associatedDiseasesList = anamnesis.associated_diseases;
            if (associatedDiseasesList.length > 0) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Doenças Associadas", 1.5, linePosition);

                const associatedDiseasesToPrint = associatedDiseasesList.map(associatedDisease => {
                    return {
                        type: associatedDisease.type_description,
                        month: associatedDisease.month,
                        year: associatedDisease.year
                    };
                });

                linePosition += 0.5;
                checkBreak(doc);
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        type: "Doença Associada",
                        month: "Mês",
                        year: "Ano"
                    }],
                    body: associatedDiseasesToPrint,
                });

                linePosition = doc.lastAutoTable.finalY;
            }
        }

        if (anamnesis.antiarrhythmic || anamnesis.other_medications || anamnesis.non_medications) {
            const antiarrhythmicsList = anamnesis.antiarrhythmic;
            const otherMedicationsList = anamnesis.other_medications;
            const nonMedicationsList = anamnesis.non_medications;

            if (antiarrhythmicsList.length > 0 || otherMedicationsList.length > 0 || nonMedicationsList.length > 0) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Medicações", 1.5, linePosition);

                if (antiarrhythmicsList.length > 0) {
                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Antiarrítmica", 1.5, linePosition);
                    doc.setFontSize(12);

                    const antiarrhythmicsToPrint = antiarrhythmicsList.map(antiarrhythmic => {
                        return {
                            type: antiarrhythmic.type_description,
                            dose: antiarrhythmic.dose,
                            time: antiarrhythmic.time,
                            description: antiarrhythmic.description
                        };
                    });

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            type: "Antiarrítmica",
                            dose: "Dose",
                            time: "Tempo",
                            description: "Descrição"
                        }],
                        body: antiarrhythmicsToPrint,
                    });

                    linePosition = doc.lastAutoTable.finalY;
                }

                if (otherMedicationsList.length > 0) {
                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Demais Medicações", 1.5, linePosition);
                    doc.setFontSize(12);

                    const otherMedicationsToPrint = otherMedicationsList.map(otherMedication => {
                        return {
                            type: otherMedication.type_description,
                            dose: otherMedication.dose,
                            time: otherMedication.time
                        };
                    });

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            type: "Medicação",
                            dose: "Dose",
                            time: "Tempo"
                        }],
                        body: otherMedicationsToPrint,
                    });

                    linePosition = doc.lastAutoTable.finalY;
                }

                if (nonMedicationsList.length > 0) {
                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Tratamento Não Medicamentoso", 1.5, linePosition);
                    doc.setFontSize(12);

                    const nonMedicationsToPrint = nonMedicationsList.map(nonMedication => {
                        return {
                            type: nonMedication.type_description,
                            dose: nonMedication.dose,
                            months: nonMedication.months,
                            years: nonMedication.years
                        };
                    });

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            type: "Tratamento",
                            dose: "Dose",
                            months: "Meses",
                            years: "Anos"
                        }],
                        body: nonMedicationsToPrint,
                    });

                    linePosition = doc.lastAutoTable.finalY;
                }
            }
        }

        doc.setFontSize(12);

        if (anamnesis.hma) {
            linePosition += 1.1;
            checkBreak(doc);

            doc.setFont("helvetica", "bold");
            doc.text("HMA", 1.5, linePosition);

            const hmaToPrint = [{ hma: convertStringToPrint(anamnesis.hma) }];

            linePosition += 0.5;
            checkBreak(doc);
            doc.autoTable({
                startY: linePosition,
                head: [{ hma: "HMA" }],
                body: hmaToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        if (anamnesis.hmp) {
            linePosition += 1.1;
            checkBreak(doc);

            doc.setFont("helvetica", "bold");
            doc.text("HMP", 1.5, linePosition);

            const hmpToPrint = [{ hmp: anamnesis.hmp }];

            linePosition += 0.5;
            checkBreak(doc);
            doc.autoTable({
                startY: linePosition,
                head: [{ hmp: "HMP" }],
                body: hmpToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        if (anamnesis.family_history) {
            linePosition += 1.1;
            checkBreak(doc);

            doc.setFont("helvetica", "bold");
            doc.text("História Familiar", 1.5, linePosition);

            const familyHistoryToPrint = [{ history: anamnesis.family_history }];

            linePosition += 0.5;
            checkBreak(doc);
            doc.autoTable({
                startY: linePosition,
                head: [{ history: "História Familiar" }],
                body: familyHistoryToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        if (anamnesis.habits) {
            linePosition += 1.1;
            checkBreak(doc);

            doc.setFont("helvetica", "bold");
            doc.text("Condições de Hábito e Vida", 1.5, linePosition);

            const habitsToPrint = [{ habit: anamnesis.habits }];

            linePosition += 0.5;
            checkBreak(doc);
            doc.autoTable({
                startY: linePosition,
                head: [{ habit: "Hábitos" }],
                body: habitsToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        if (anamnesis.other_data) {
            const othersDataList = anamnesis.other_data;
            if (othersDataList.length > 0) {
                linePosition += 1.1;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Outros Dados", 1.5, linePosition);

                for (let indexOther = 0; indexOther < othersDataList.length; indexOther++) {
                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    doc.text("Data: " + dayjs(othersDataList[indexOther].date).format("DD/MM/YYYY - HH:mm"), 1.5, linePosition);
                    doc.setFontSize(12);

                    let physicalActivityText = othersDataList[indexOther].physical_activity_description;
                    if (othersDataList[indexOther].physical_activity_text) {
                        physicalActivityText += "\n" + othersDataList[indexOther].physical_activity_text;
                    }

                    const smokeSelected = yesNoOptions.find(x => x.value === othersDataList[indexOther].smoke_option);
                    let smokeText = smokeSelected.label;
                    if (othersDataList[indexOther].cigarettes) {
                        const cigarettesOption = smokeOptions.find(x => x.value === othersDataList[indexOther].cigarettes);
                        smokeText += "\n" + cigarettesOption.label;
                    }

                    const alcoholSelected = alcoholOptions.find(x => x.value === othersDataList[indexOther].alcohol_option);

                    const othersDataToPrint = [{
                        amp: othersDataList[indexOther].amp,
                        af: othersDataList[indexOther].af,
                        rs: othersDataList[indexOther].rs,
                        physicalActivity: physicalActivityText,
                        alcohol: alcoholSelected,
                        smoke: smokeText
                    }];

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            amp: "AMP",
                            af: "AF",
                            rs: "RS",
                            physicalActivity: "Atividade Física",
                            alcohol: "Álcool",
                            smoke: "Fumo"
                        }],
                        body: othersDataToPrint,
                    });

                    linePosition = doc.lastAutoTable.finalY;

                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Manifestações Clínicas", 1.5, linePosition);
                    doc.text("Tratamentos Prévios", 11, linePosition);
                    doc.setFontSize(12);

                    const clinicalManifestationsToPrint = othersDataList[indexOther].clinical_manifestations.map(clinicalManifestation => {
                        return {
                            type: clinicalManifestation.type_description,
                            month: clinicalManifestation.month,
                            year: clinicalManifestation.year
                        };
                    });

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            type: "Manifestação",
                            month: "Mês",
                            year: "Ano"
                        }],
                        body: clinicalManifestationsToPrint,
                        margin: { right: 10.5 }
                    });

                    const previousTreatmentsToPrint = othersDataList[indexOther].previous_treatments.map(previousTreatment => {
                        return {
                            type: previousTreatment.type_description,
                            month: previousTreatment.month,
                            year: previousTreatment.year
                        };
                    });

                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            type: "Tratamento",
                            month: "Mês",
                            year: "Ano"
                        }],
                        body: previousTreatmentsToPrint,
                        margin: { left: 11 }
                    });

                    linePosition = doc.lastAutoTable.finalY;
                }
            }
        }

        if (anamnesis.physical_exercises) {
            const physicalExercisesList = anamnesis.physical_exercises;
            if (physicalExercisesList.length > 0) {
                linePosition += 1.5;
                checkBreak(doc);

                doc.setFont("helvetica", "bold");
                doc.text("Exercícios Físicos", 1.5, linePosition);

                for (let indexPhysicalExercise = 0; indexPhysicalExercise < physicalExercisesList.length; indexPhysicalExercise++) {
                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.setFont("helvetica", "normal");
                    doc.text("Data: " + dayjs(physicalExercisesList[indexPhysicalExercise].date).format("DD/MM/YYYY - HH:mm"), 1.5, linePosition);
                    doc.setFontSize(12);

                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Exame Segmentar - Parte 1", 1.5, linePosition);
                    doc.setFontSize(12);

                    const particularitySelected = yesNoOptions.find(x => x.value === physicalExercisesList[indexPhysicalExercise].particularity_option);

                    const examPartOne = [{
                        particularity: particularitySelected?.label ?? "",
                        head: physicalExercisesList[indexPhysicalExercise].head,
                        neck: physicalExercisesList[indexPhysicalExercise].neck,
                        lungs: physicalExercisesList[indexPhysicalExercise].lungs,
                        abdomen: physicalExercisesList[indexPhysicalExercise].abdomen,
                        limbs: physicalExercisesList[indexPhysicalExercise].limbs,
                        ictus: physicalExercisesList[indexPhysicalExercise].ictus
                    }];

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            particularity: "Particularidade",
                            head: "Cabeça",
                            neck: "Pescoço",
                            lungs: "Pulmões",
                            abdomen: "Abdomen",
                            limbs: "Membros",
                            ictus: "Ictus"
                        }],
                        body: examPartOne,
                    });

                    linePosition = doc.lastAutoTable.finalY;

                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Exame Segmentar - Parte 2", 1.5, linePosition);
                    doc.setFontSize(12);

                    const examPartTwo = [{
                        systolicPa: physicalExercisesList[indexPhysicalExercise].systolic_pa ?? "",
                        diastolicPa: physicalExercisesList[indexPhysicalExercise].diastolic_pa ?? "",
                        heartRate: physicalExercisesList[indexPhysicalExercise].heart_rate ?? "",
                        pulse: physicalExercisesList[indexPhysicalExercise].pulse ?? "",
                        temperature: physicalExercisesList[indexPhysicalExercise].temperature ?? ""
                    }];

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            systolicPa: "PA - Sistólica",
                            diastolicPa: "PA - Diastólica",
                            heartRate: "FC",
                            pulse: "Pulso",
                            temperature: "Temperatura"
                        }],
                        body: examPartTwo,
                    });

                    linePosition = doc.lastAutoTable.finalY;

                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Informações sobre pulso", 1.5, linePosition);
                    doc.setFontSize(12);

                    let pulseInfo = [];
                    if (physicalExercisesList[indexPhysicalExercise].posterior_tibia) {
                        pulseInfo.push({ type: "Tíbia Posterior" });
                    }
                    if (physicalExercisesList[indexPhysicalExercise].foot) {
                        pulseInfo.push({ type: "Pedioso" });
                    }

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            type: "Pulso"
                        }],
                        body: pulseInfo,
                    });

                    linePosition = doc.lastAutoTable.finalY;

                    linePosition += 0.8;
                    checkBreak(doc);
                    doc.setFontSize(10);
                    doc.text("Précordio", 1.5, linePosition);
                    doc.setFontSize(12);

                    const precordioInfo = [{
                        bulge: physicalExercisesList[indexPhysicalExercise].bulge_description,
                        blow: physicalExercisesList[indexPhysicalExercise].blow,
                        observation: physicalExercisesList[indexPhysicalExercise].observation
                    }];

                    linePosition += 0.5;
                    checkBreak(doc);
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            bulge: "Bulhas",
                            blow: "Sopros",
                            observation: "Observação"
                        }],
                        body: precordioInfo,
                    });

                    linePosition = doc.lastAutoTable.finalY;
                }
            }
        }

        if (anamnesis.comments) {
            linePosition += 1.1;
            checkBreak(doc);

            doc.setFont("helvetica", "bold");
            doc.text("Comentários", 1.5, linePosition);

            const commentsToPrint = [{ comments: convertStringToPrint(anamnesis.comments) }];

            linePosition += 0.5;
            checkBreak(doc);
            doc.autoTable({
                startY: linePosition,
                head: [{ comments: "Comentários" }],
                body: commentsToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        doc.setFont("helvetica", "normal");
        doc.setFontSize(7);
        const pageCount = doc.internal.getNumberOfPages();
        for (let pageItem = 1; pageItem <= pageCount; pageItem++) {
            doc.setPage(pageItem);

            if (pageItem > 1) {
                printHeader(doc);
            }

            printFooter(doc);
            doc.text("Página " + pageItem + " de " + pageCount, 18, 28.8);
        }

        doc.save("anamnesis.pdf");
    }
}

function checkBreak(doc) {
    if (linePosition > 26.5) {
        doc.addPage();
        linePosition = 4.2;
    }
}

function printHeader(doc) {
    doc.addImage(imgLogo, "PNG", 8, 0.8, 5.6, 2.6);
    // doc.setFont("helvetica", "bold");
    // doc.setFontSize(12);
    // doc.text("Laboratório de Eletrofisiologia de Curitiba", 10.55, 3, { align: "center" });
}

function printFooter(doc) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(7);
    doc.text("LEC - Laboratório de Eletrofisiologia de Curitiba", 10.9, 28.2, { align: "right" });

    doc.setFont("helvetica", "normal");
    doc.text(" - Fones (41) 3262-8693 ou (41) 99933-9934", 10.9, 28.2);
    doc.text("Cândido de Abreu, 70 - Sala 1105 - Centro Cívico - Curitiba - PR - 80.530-000 - Email: eletrolec@leccuritiba.com.br", 10.55, 28.6, { align: "center" });
}

async function getAnamnesis(id) {
    return new Promise(resolve => {
        api.get(`/anamnesis/${id}`)
            .then(response => {
                if (response.status === 200) {
                    resolve(response.data);
                }
            })
    });
}