const inducedTachycardiaTypes = [
    {
        value: "EA",
        label: "Extrassístole atrial"
    },
    {
        value: "EV",
        label: "Extrassístole ventricular"
    },
    {
        value: "FA",
        label: "Fibrilação atrial"
    },
    {
        value: "FLA",
        label: "Flutter atrial"
    },
    {
        value: "NAT",
        label: "Nó atrioventricular"
    },
    {
        value: "SIN",
        label: "Procedimento realizado em ritmo sinusal"
    },
    {
        value: "TA",
        label: "Taquicardia atrial"
    },
    {
        value: "OUT",
        label: "Taquicardia clínica que não esteve presente durante o procedimento"
    },
    {
        value: "TRN",
        label: "Taquicardia por reentrada nodal"
    },
    {
        value: "TVA",
        label: "Taquicardia por via acessória"
    },
    {
        value: "TV",
        label: "Taquicardia ventricular"
    }
];

const tachycardiaTypes = [
    {
        value: "SINUSAL",
        label: "Sinusal"
    },
    {
        value: "TAQUICARDIA",
        label: "Taquicardia Atrial"
    },
    {
        value: "FLUTTER",
        label: "Flutter Atrial"
    },
    {
        value: "FIBRILACAO",
        label: "Fibrilação Atrial"
    },
    {
        value: "EA",
        label: "Sinusal com EA"
    },
    {
        value: "TANS",
        label: "Sinusal com TANS"
    },
    {
        value: "EV",
        label: "Sinusal com EV"
    },
    {
        value: "TVNS",
        label: "Sinusal com TVNS"
    },
    {
        value: "VA",
        label: "Sinusal com Via Acessória"
    },
    {
        value: "ECTOPICO",
        label: "Ectópico atrial"
    },
    {
        value: "ESCAPEATRIAL",
        label: "Escape Atrial"
    },
    {
        value: "ESCAPEVENTRICULAR",
        label: "Escape Ventricular"
    },
    {
        value: "BAV1GRAU",
        label: "Sinusal com BAV 1o grau"
    },
    {
        value: "BAV2GRAU",
        label: "Sinusal com BAV 2o grau Mobitz I"
    },
    {
        value: "BAV2GRAUMOBITZ",
        label: "Sinusal com BAV 2o grau Mobitz II"
    },
    {
        value: "BAV3GRAU",
        label: "Sinusal com BAV 3o grau (BAVT)"
    },
    {
        value: "FIBRILACAOBAV3GRAU",
        label: "Fibrilação Atrial com BAV 3o grau (BAVT)"
    },
    {
        value: "TAQATRIOVENTRICULAR",
        label: "Taquicardia Atrioventricular"
    },
    {
        value: "TAQTRN",
        label: "Taquicardia por Reentrada Nodal Comum"
    },
    {
        value: "TAQVIAACESSORIA",
        label: "Taquicardia por Reentrada por Via acessória"
    },
    {
        value: "TAQVENTRICULAR",
        label: "Taquicardia Ventricular"
    },
    {
        value: "RITMOIDIOVENTRICULAR",
        label: "Ritmo Idioventricular"
    },
    {
        value: "OUT",
        label: "Outro"
    }
];

const tiInductionTypes = [
    {
        value: "INS",
        label: "Com Isuprel"
    },
    {
        value: "SINS",
        label: "Sem Isuprel"
    }
];

const reportTypes = [
    {
        value: "EEF",
        label: "EEF/Ablação por Cateter"
    },
    {
        value: "IMC",
        label: "Implante de MP/CDI"
    }
];

const qrsTransitionTypes = [
    {
        value: "V1",
        label: "V1"
    },
    {
        value: "V2",
        label: "V2"
    },
    {
        value: "V3",
        label: "V3"
    },
    {
        value: "V4",
        label: "V4"
    },
    {
        value: "V5",
        label: "V5"
    },
    {
        value: "V6",
        label: "V6"
    }
];

const frontAxisTypes = [
    {
        value: "INFERIOR",
        label: "Inferior"
    },
    {
        value: "SUPERIOR",
        label: "Superior"
    }
];

const horizontalAxisTypes = [
    {
        value: "FRENTE",
        label: "Para a Frente"
    },
    {
        value: "TRAS",
        label: "Para Trás"
    }
];

const accessoryRouteTypes = [
    {
        value: "OCU",
        label: "Oculta"
    },
    {
        value: "MAN",
        label: "Manifesta"
    },
    {
        value: "INT",
        label: "Intermitente"
    }
];

const accessTypes = [
    {
        value: "ART",
        label: "Arterial"
    },
    {
        value: "TRA",
        label: "Transeptal"
    },
    {
        value: "VEN",
        label: "Venoso"
    },
    {
        value: "ARTTRAN",
        label: "Arterial E Transeptal"
    }
];

const avRelationTypes = [
    {
        value: "UU",
        label: "1:1"
    },
    {
        value: "DU",
        label: "2:1"
    },
    {
        value: "TU",
        label: "3:1"
    },
    {
        value: "QU",
        label: "4:1"
    },
    {
        value: "CU",
        label: "5:1"
    },
    {
        value: "UD",
        label: "1:2"
    },
    {
        value: "UT",
        label: "1:3"
    },
    {
        value: "UQ",
        label: "1:4"
    },
    {
        value: "UC",
        label: "1:5"
    }
];

const lesionSuccessPlaceTypes = [
    {
        value: "A1",
        label: "A1"
    },
    {
        value: "A2",
        label: "A2"
    },
    {
        value: "M1",
        label: "M1"
    },
    {
        value: "M2",
        label: "M2"
    },
    {
        value: "P1",
        label: "P1"
    },
    {
        value: "P2",
        label: "P2"
    },
    {
        value: "ANTEROSEPTALESQUERDA",
        label: "Anteroseptal esquerda"
    },
    {
        value: "ANTERIORESQUERDA",
        label: "Anterior esquerda"
    },
    {
        value: "ANTEROLATERALESQUERDA",
        label: "Anterolateral esquerda"
    },
    {
        value: "LATERALESQUERDA",
        label: "Lateral esquerda"
    },
    {
        value: "POSTEROLATERALESQUERDA",
        label: "Posterolateral esquerda"
    },
    {
        value: "POSTERIORESQUERDA",
        label: "Posterior esquerda"
    },
    {
        value: "POSTEROSEPTALESQUERDA",
        label: "Posteroseptal esquerda"
    },
    {
        value: "POSTEROSEPTALDIREITA",
        label: "Posteroseptal direita"
    },
    {
        value: "POSTERIORDIREITA",
        label: "Posterior direita"
    },
    {
        value: "POSTEROLATERALDIREITA",
        label: "Posterolateral direita"
    },
    {
        value: "LATERALDIREITA",
        label: "Lateral direita"
    },
    {
        value: "ANTEROLATERALDIREITA",
        label: "Anterolateral direita"
    },
    {
        value: "ANTERIORDIREITA",
        label: "Anterior direita"
    },
    {
        value: "ANTEROSEPTALDIREITA",
        label: "Anteroseptal direita"
    },
    {
        value: "PARAHISSIANO",
        label: "Parahissiano"
    },
    {
        value: "SEIOCORONARIO",
        label: "Seio Coronário"
    },
    {
        value: "VEIACARDIACAMEDIA",
        label: "Veia Cardíaca Média"
    },
    {
        value: "ISTMOCAVOTRICUSPIE",
        label: "Istmo Cavotricúspide"
    },
    {
        value: "OSTIOVEIASPULMONARES",
        label: "Óstio das Veias Pulmonares"
    },
    {
        value: "OSTIOVEIASPULMONARESNADAMANE",
        label: "Óstio das Veias Pulmonares + Nadamane"
    },
    {
        value: "OSTIOVEIASPULMONARESLINHAS",
        label: "Óstio das Veias Pulmonares + Linhas"
    },
    {
        value: "OSTIOVEIASPULMONARESISTMO",
        label: "Óstio das Veias Pulmonares + Istmo Cavotricuspídeo"
    },
    {
        value: "OSTIOVEIASPULMONARESAURICULETA",
        label: "Óstio das Veias Pulmonares + Auriculeta"
    },
    {
        value: "OSTIOVEIASPULMONARESISTMOAURICULETA",
        label: "Óstio das Veias Pulmonares + Istmo Cavotricuspídeo + Auriculeta"
    },
    {
        value: "OSTIOVEIAPULMONARESOUTROSLOCAIS",
        label: "Óstio das Veias Pulmonares + Outros Locais"
    },
    {
        value: "ANTROVEIASPULMONARES",
        label: "Antro das Veias Pulmonares"
    },
    {
        value: "ANTROVEIASPUMONARESOUTROSLOCAIS",
        label: "Antro das Veias Pulmonares + Outros Locais"
    },
    {
        value: "BOXPAREDEPOSTERIOR",
        label: "Box da Parede Posterior"
    },
    {
        value: "BOXPAREDEOUTROSLOCAIS",
        label: "Box da Parede Posterior + Outros Locais"
    },
    {
        value: "OSTIOVPSE",
        label: "Óstio da VPSE"
    },
    {
        value: "OSTIOVPIE",
        label: "Óstio da VPIE"
    },
    {
        value: "OSTIOVPSD",
        label: "Óstio da VPSD"
    },
    {
        value: "OSTIOVPID",
        label: "Óstio da VPID"
    },
    {
        value: "VPSE",
        label: "VPSE"
    },
    {
        value: "VPIE",
        label: "VPIE"
    },
    {
        value: "VPSD",
        label: "VPSD"
    },
    {
        value: "VPID",
        label: "VPID"
    },
    {
        value: "AURICULETAESQUERDA",
        label: "Auriculeta Esquerda"
    },
    {
        value: "AURICULETADIREITA",
        label: "Auriculeta Direita"
    },
    {
        value: "ANTERIORDIREITO",
        label: "Anterior direito"
    },
    {
        value: "ANTEROSEPTALDIREITO",
        label: "Anteroseptal direito"
    },
    {
        value: "ANTEROLATERALDIREITO",
        label: "Anterolateral direito"
    },
    {
        value: "POSTERIORDIREITO",
        label: "Posterior direito"
    },
    {
        value: "POSTEROSEPTALDIREITO",
        label: "Posteroseptal direito"
    },
    {
        value: "POSTEROLATERALDIREITO",
        label: "Posterolateral direito"
    },
    {
        value: "MEDIOSEPTALDIREITO",
        label: "Medioseptal direito"
    },
    {
        value: "LATERALDIREITO",
        label: "Lateral direito"
    },
    {
        value: "LATERALDIREITOBAIXO",
        label: "Lateral direito baixo"
    },
    {
        value: "LATERALDIREITOALTO",
        label: "Lateral direito alto"
    },
    {
        value: "OSTIODOSEIOCORONARIO",
        label: "Óstio do seio coronário"
    },
    {
        value: "PERISINUSAL",
        label: "Perisinusal"
    },
    {
        value: "ANTERIORESQUERDO",
        label: "Anterior esquerdo"
    },
    {
        value: "ANTEROSEPTALESQUERDO",
        label: "Anteroseptal esquerdo"
    },
    {
        value: "ANTEROLATERALESQUERDO",
        label: "Anterolateral esquerdo"
    },
    {
        value: "POSTERIORESQUERDO",
        label: "Posterior esquerdo"
    },
    {
        value: "POSTEROSEPTALESQUERDO",
        label: "Posteroseptal esquerdo"
    },
    {
        value: "POSTEROLATERALESQUERDO",
        label: "Posterolateral esquerdo"
    },
    {
        value: "MEDIOSEPTALESQUERO",
        label: "Medioseptal esquerdo"
    },
    {
        value: "LATERALESQUERDO",
        label: "Lateral esquerdo"
    },
    {
        value: "LATERALESQUERDOBAIXO",
        label: "Lateral esquerdo baixo"
    },
    {
        value: "LATERALESQUERDOALTO",
        label: "Lateral esquerdo alto"
    },
    {
        value: "VIADESAIDAVD",
        label: "Via de Saída de VD"
    },
    {
        value: "VIADEENTRADAVD",
        label: "Via de Entrada de VD"
    },
    {
        value: "PAREDELATERALVD",
        label: "Parede Lateral de VD"
    },
    {
        value: "PAREDEANTEROLATERALVD",
        label: "Parede Anterolateral de VD"
    },
    {
        value: "PAREDEINFEROLATERALVD",
        label: "Parede Inferolateral de VD"
    },
    {
        value: "PAREDESEPTALVD",
        label: "Parede Septal de VD"
    },
    {
        value: "PAREDEINFEROSEPTALVD",
        label: "Parede Inferoseptal de VD"
    },
    {
        value: "PAREDEANTEROSEPTALVD",
        label: "Parede AnteroSeptal de VD"
    },
    {
        value: "PAREDEAPICALVE",
        label: "Parede Apical de VE"
    },
    {
        value: "VIADESAIDAVE",
        label: "Via de Saída de VE"
    },
    {
        value: "VIADEENTRADAVE",
        label: "Via de Entrada de VE"
    },
    {
        value: "PAREDELATERALVE",
        label: "Parede Lateral de VE"
    },
    {
        value: "PAREDEANTEROLATERALVE",
        label: "Parede Anterolateral de VE"
    },
    {
        value: "PAREDEINFEROLATERALVE",
        label: "Parede Inferolateral de VE"
    },
    {
        value: "PAREDESEPTALVE",
        label: "Parede Septal de VE"
    },
    {
        value: "PAREDEINFEROSEPTAL",
        label: "Parede Inferoseptal de VE"
    },
    {
        value: "PAREDEANTEROSEPTAL",
        label: "Parede AnteroSeptal de VE"
    },
    {
        value: "OUT",
        label: "Outro Local"
    }
];

const mappingCriterionTypes = [
    {
        value: "POTENCIALVIALENTA",
        label: "Potencial da Via Lenta"
    },
    {
        value: "POTENCIALVIARAPIDA",
        label: "Potencial da Via Rápida"
    },
    {
        value: "MENORINTERVALOAV",
        label: "Menor Intervalo AV"
    },
    {
        value: "MENORINTERVALOVA",
        label: "Menor Intervalo VA"
    },
    {
        value: "MENORINTEVALODELTAV",
        label: "Menor Intervalo Delta V"
    },
    {
        value: "UNIPOLARPQS",
        label: "Unipolar PQS precoce"
    },
    {
        value: "POTENCIALVIAANOMALA",
        label: "Potencial da Via anômala"
    },
    {
        value: "ATIVIDADEELETRICA",
        label: "Atividade Elétrica Contínua"
    },
    {
        value: "PRECOCIDADEONDAP",
        label: "Precocidade em relação a onda P"
    },
    {
        value: "PRECOCIDADEQRS",
        label: "Precocidade em relação ao QRS"
    },
    {
        value: "MESODIASTOLICO",
        label: "Potencial Mesodiastólico"
    },
    {
        value: "ENTRAINMENTOCULTO",
        label: "Entrainment Oculto"
    },
    {
        value: "FRAGMENTADO",
        label: "Potencial Fragmentado"
    },
    {
        value: "PACEMAPPING",
        label: "Pacemapping Perfeito"
    },
    {
        value: "POTENCIALVEIA",
        label: "Potencial de Veia"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const techniqueTypes = [
    {
        value: "PM",
        label: "PaceMapping"
    },
    {
        value: "AT",
        label: "Ativação"
    },
    {
        value: "ME",
        label: "Mapeamento Eletroanatômico"
    },
    {
        value: "APME",
        label: "Ativação + Pacemapping + Mapeamento Eletroanatômico"
    },
    {
        value: "OUT",
        label: "Outra"
    }
];

const successCriterionTypes = [
    {
        value: "DEATA",
        label: "Desaparecimento extrassístole atrial ou taquicardia atrial"
    },
    {
        value: "DPV",
        label: "Desaparecimento de potencial de veia"
    },
    {
        value: "IFA",
        label: "Interrupção da fibrilação atrial"
    },
    {
        value: "ITA",
        label: "Interrupção da taquicardia atrial"
    },
    {
        value: "NRFA",
        label: "Não reindução de fibrilação atrial"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const arrhythmiaInterruptionTypes = [
    {
        value: "SIM",
        label: "Sim"
    },
    {
        value: "NAO",
        label: "Não"
    },
    {
        value: "RRS",
        label: "Realizada em Ritmo Sinusal"
    }
];

const tachycardiaReinductionTypes = [
    {
        value: "SIM",
        label: "Sim"
    },
    {
        value: "SIMNAOSUSTENTADA",
        label: "Sim, porém não sustentada"
    },
    {
        value: "NAO",
        label: "Não"
    },
    {
        value: "NAOTESTADO",
        label: "Não Testado"
    }
];

const persistenceTypes = [
    {
        value: "SALTO",
        label: "Salto"
    },
    {
        value: "ECO",
        label: "Eco"
    },
    {
        value: "SALTOEECO",
        label: "Salto e Eco"
    },
    {
        value: "NADA",
        label: "Nada"
    }
];

const istmoBlockLineTypes = [
    {
        value: "NFT",
        label: "Não foi testada"
    },
    {
        value: "BID",
        label: "Bidirecional"
    },
    {
        value: "UHOR",
        label: "Unidirecional horário"
    },
    {
        value: "UANT",
        label: "Unidirecional antihorária"
    }
];

const resultTypes = [
    {
        value: "OUT",
        label: "Outros"
    },
    {
        value: "SUCESSO",
        label: "Sucesso"
    },
    {
        value: "INSUCESSO",
        label: "Insucesso"
    },
    {
        value: "SUCESSONAOINDUCAOARRITMIA",
        label: "Sucesso com: Não indução de arritmias"
    },
    {
        value: "SUCESSOBLOQUEIOANTIRETROGRADO",
        label: "Sucesso com: Bloqueio de condução da via anômala somente anterógrado"
    },
    {
        value: "SUCESSOBLOQUEIORETROGRADO",
        label: "Sucesso com: Bloqueio de condução da via anômala somente retrógrado"
    },
    {
        value: "SUCESSOBLOQUEIOCOMPLETO",
        label: "Sucesso com: Bloqueio completo da condução pela via anômala"
    },
    {
        value: "SUCESSOBLOQUEIOBIRECIONAL",
        label: "Sucesso com: Bloqueio Bidirecional do Itsmo Cavotricuspídeo"
    },
    {
        value: "SUCESSOBLOQUEIOUNIHORARIO",
        label: "Sucesso com: Bloqueio Unidirecional Horário do Istmo Cavotricuspídeo"
    },
    {
        value: "SUCESSOBLOQUEIOUNIANTHORARIO",
        label: "Sucesso com: Bloqueio Unidirecional Antihorário do Istmo Cavotricuspídeo\n"
    },
    {
        value: "SUCESSODESCONEXAO1V",
        label: "Sucesso com: Desconexão 1 veia"
    },
    {
        value: "SUCESSODESCONEXAO2V",
        label: "Sucesso com: Desconexão 2 veias"
    },
    {
        value: "SUCESSODESCONEXAO3V",
        label: "Sucesso com: Desconexão 3 veias"
    },
    {
        value: "SUCESSODESCONEXAO4V",
        label: "Sucesso com: Desconexão 4 veias"
    },
    {
        value: "SUCESSOMODIFICACAO1V",
        label: "Sucesso com: Modificação 1 veia"
    },
    {
        value: "SUCESSOMODIFICACAO2V",
        label: "Sucesso com: Modificação 2 veias"
    },
    {
        value: "SUCESSOMODIFICACAO3V",
        label: "Sucesso com: Modificação 3 veias"
    },
    {
        value: "SUCESSOMODIFICACAO4V",
        label: "Sucesso com: Modificação 4 veias"
    },
    {
        value: "SUCESSOBLOQUEIOAVTOTAL",
        label: "Sucesso com: Bloqueio AV total"
    },
    {
        value: "SUCESSOBLOQUEIOAVAVANCADO",
        label: "Sucesso com: Bloqueio AV avançado"
    },
    {
        value: "SUCESSOBLOQUEIOAV2G",
        label: "Sucesso com: Bloqueio AV de segundo grau"
    }
];

const complicationsTypes = [
    {
        value: "EVENTOSTROMBOEMBOLICOS",
        label: "Eventos tromboembólicos - cerebral"
    },
    {
        value: "TROMBOSEVENOSA",
        label: "Trombose venosa profunda"
    },
    {
        value: "TROMBOFLEBITE",
        label: "Tromboflebite aguda"
    },
    {
        value: "ISQUEMIAMIOCARDICA",
        label: "Isquemia Miocárdica ou Infarto associado ao procedimento"
    },
    {
        value: "DERRAMEPERICARDIO",
        label: "Derrame pericardico significante"
    },
    {
        value: "TAMPONAMENTOVE",
        label: "Tamponamento por perfuração de VE"
    },
    {
        value: "TAMPONAMENTOVD",
        label: "Tamponamento por perfuração de VD"
    },
    {
        value: "TAMPONAMENTOAO",
        label: "Tamponamento por perfuração de Ao"
    },
    {
        value: "TAMPONAMENTOAE",
        label: "Tamponamento por perfuração de AE"
    },
    {
        value: "TAMPONAMENTOSC",
        label: "Tamponamento por perfuração de SC"
    },
    {
        value: "TAMPONAMENTOOUTRACAVIDADE",
        label: "Tamponamento por perfuração de outra cavidade"
    },
    {
        value: "TROMBOEMBOLISMO",
        label: "Tromboembolismo de pulmão"
    },
    {
        value: "MORTEAVC",
        label: "Morte - AVC maciço"
    },
    {
        value: "MORTETAMPONAMENTO",
        label: "Morte - Tamponamento"
    },
    {
        value: "MORTEINFARTO",
        label: "Morte - Infarto"
    },
    {
        value: "MORTEOUTROMOTIVO",
        label: "Morte - Outro motivo"
    },
    {
        value: "SEPSISENDOCARDITE",
        label: "Sepsis por Endocardite"
    },
    {
        value: "ENDOCARDITE",
        label: "Endocardite"
    },
    {
        value: "PNEUMOTORAX",
        label: "Pneumotórax"
    },
    {
        value: "LESAOVALVAR",
        label: "Lesão Valvar"
    },
    {
        value: "TROMBOSEART",
        label: "Trombose Arterial"
    },
    {
        value: "FISURAART",
        label: "Fístura arteriovenosa"
    },
    {
        value: "PSEUDOANEURISMA",
        label: "Pseudoaneurisma"
    },
    {
        value: "BACTEREMIA",
        label: "Bacteremia"
    },
    {
        value: "PERICARDITE",
        label: "Pericardite"
    },
    {
        value: "SIND",
        label: "Síndrome de Dressler"
    },
    {
        value: "HEMA",
        label: "Hematoma"
    },
    {
        value: "BAT",
        label: "Bloqueio atrioventricular total"
    },
    {
        value: "OUT",
        label: "Outras"
    }
];

export {
    accessoryRouteTypes,
    accessTypes,
    arrhythmiaInterruptionTypes,
    avRelationTypes,
    complicationsTypes,
    frontAxisTypes,
    horizontalAxisTypes,
    inducedTachycardiaTypes,
    istmoBlockLineTypes,
    lesionSuccessPlaceTypes,
    mappingCriterionTypes,
    persistenceTypes,
    qrsTransitionTypes,
    reportTypes,
    resultTypes,
    successCriterionTypes,
    tachycardiaTypes,
    tachycardiaReinductionTypes,
    techniqueTypes,
    tiInductionTypes
}