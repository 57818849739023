import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
  Button,
  FormCheck,
  Modal,
  Tab,
  Table,
  Tabs
} from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import InputMask from "react-input-mask";
import CurrencyInput, { formatValue } from "react-currency-input-field";

import ModalLoading from '../../components/ModalLoading/ModalLoading';
import DAddressForm from '../../components/DAddressForm/DAddressForm';
import DFormText from "../../components/DFormText/DFormText";
import PriceTableForm from './PriceTableForm';

import api from '../../../services/api';
import dayjs from 'dayjs';
import { cnpj } from "cpf-cnpj-validator";
import { createLog } from '../../../services/logService';

const HealthPlanForm = forwardRef((props, ref) => {
  const { onClose } = props;
  const childRef = useRef();
  const [modalBox, setModalBox] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [loadingHealthPlanForm, setLoadingHealthPlanForm] = useState(false);

  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [taxPercent, setTaxPercent] = useState(0);
  const [createDate, setCreateDate] = useState(dayjs().format("DD/MM/YYYY"));

  const [addressInfo, setAddressInfo] = useState({});
  const [contactId, setContactId] = useState(undefined);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactHomePhone, setContactHomePhone] = useState("");
  const [contactComercialPhone, setContactComercialPhone] = useState("");
  const [contactCellPhone, setContactCellPhone] = useState("");
  const [observation, setObservation] = useState("");
  const [priceTableList, setPriceTableList] = useState([]);
  const [active, setActive] = useState(true);

  const [healthPlanId, setHealthPlanId] = useState(undefined);

  const [invalidName, setInvalidName] = useState(false);
  const [invalidDocumentId, setInvalidDocumentId] = useState(false);
  const [invalidDocumentIdText, setInvalidDocumentIdText] = useState("Informe um CNPJ válido");

  useImperativeHandle(ref, () => ({
    openModal(mode, healthPlanInfo = {}) {
      setModalBox(true);
      setIsUpdate(false);

      resetFields();

      if (mode === "update") {
        setIsUpdate(true);

        setHealthPlanId(healthPlanInfo._id);
        setName(healthPlanInfo.name);
        setShortName(healthPlanInfo.short_name ?? "");

        const taxValue = healthPlanInfo.tax_percent ?? 0;
        const valueFormatted = formatValue({
          value: taxValue.toString(),
          groupSeparator: ".",
          decimalSeparator: ","
        });
        setTaxPercent(valueFormatted);

        setDocumentId(healthPlanInfo.document_id);
        setCreateDate(dayjs(healthPlanInfo.createdAt).format("DD/MM/YYYY"));
        setObservation(healthPlanInfo.observation);
        setActive(healthPlanInfo.status);

        if (healthPlanInfo.addresses && healthPlanInfo.addresses.length > 0) {
          setAddressInfo({
            zipCode: healthPlanInfo.addresses[0].zip_code,
            address: healthPlanInfo.addresses[0].address,
            addressNumber: healthPlanInfo.addresses[0].address_number,
            complement: healthPlanInfo.addresses[0].complement ?? "",
            district: healthPlanInfo.addresses[0].district ?? "",
            city: healthPlanInfo.addresses[0].city ?? "",
            addressState: healthPlanInfo.addresses[0].state
          });
        }

        if (healthPlanInfo.contacts && healthPlanInfo.contacts.length > 0) {
          setContactId(healthPlanInfo.contacts[0].id);
          setContactName(healthPlanInfo.contacts[0].name);
          setContactEmail(healthPlanInfo.contacts[0].email);

          for (let indexPhone = 0; indexPhone < healthPlanInfo.contacts[0].phones.length; indexPhone++) {
            const phoneReturned = healthPlanInfo.contacts[0].phones[indexPhone].phone;

            if (healthPlanInfo.contacts[0].phones[indexPhone].type === "home") {
              setContactHomePhone(phoneReturned);
            }
            if (healthPlanInfo.contacts[0].phones[indexPhone].type === "comercial") {
              setContactComercialPhone(phoneReturned);
            }

            if (healthPlanInfo.contacts[0].phones[indexPhone].type === "cell") {
              setContactCellPhone(phoneReturned);
            }
          }
        }

        if (healthPlanInfo.price_table) {
          setPriceTableList(healthPlanInfo.price_table);
        }
      }
    }
  }));

  async function handleSaveHealthPlan(e) {
    e.preventDefault();

    setLoadingHealthPlanForm(true);

    setInvalidName(false);

    let hasError = false;

    if (name === "") {
      setInvalidName(true);
      hasError = true;
    }

    const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

    if (!isUpdate && (invalidDocumentId || documentUnformatted.trim() === "")) {
      setInvalidDocumentId(true);
      hasError = true;
    }

    if (!hasError) {
      let healthPlan = {
        name,
        short_name: shortName,
        document_id: documentUnformatted.trim(),
        tax_percent: taxPercent.toString().replace(/[.]/g, "").replace(",", "."),
        status: active,
        addresses: [],
        contacts: [],
        price_table: []
      };

      if (addressInfo) {
        if (addressInfo.zipCode?.trim().length === 8) {
          healthPlan.addresses.push({
            zip_code: addressInfo.zipCode.trim(),
            address: addressInfo.address,
            address_number: addressInfo.addressNumber,
            complement: addressInfo.complement ?? "",
            district: addressInfo.district,
            city: addressInfo.city,
            state: addressInfo.addressState
          });
        }
      }

      const contactHomePhoneUnformatted = contactHomePhone.replace(/[()-]/g, "").replace(" ", "");
      const contactComercialPhoneUnformatted = contactComercialPhone.replace(/[()-]/g, "").replace(" ", "");
      const contactCellPhoneUnformatted = contactCellPhone.replace(/[()-]/g, "").replace(" ", "");

      if (contactName !== "" || contactEmail !== "" || contactHomePhoneUnformatted.trim() !== "" || contactComercialPhoneUnformatted.trim() !== "" || contactCellPhoneUnformatted.trim() !== "") {
        let newContact = {
          type: "personal",
          name: contactName,
          email: contactEmail,
          phones: []
        };

        if (contactHomePhoneUnformatted) {
          newContact.phones.push({
            type: "home",
            phone: contactHomePhoneUnformatted
          });
        }

        if (contactComercialPhoneUnformatted) {
          newContact.phones.push({
            type: "comercial",
            phone: contactComercialPhoneUnformatted
          });
        }

        if (contactCellPhoneUnformatted) {
          newContact.phones.push({
            type: "cell",
            phone: contactCellPhoneUnformatted
          });
        }

        healthPlan.contacts.push(newContact);
      }

      if (priceTableList) {
        healthPlan.price_table = priceTableList;
      }

      if (!isUpdate) {
        try {
          await api.post("/health-plans", healthPlan)
            .then(response => {
              if (response.status === 201) {
                createLog({
                  routine: "healthplan",
                  action: "create",
                  type: "success",
                  message: `Convênio ${healthPlan.name} incluído`,
                  jsonOrigin: JSON.stringify(healthPlan),
                  jsonReturn: JSON.stringify(response)
                });

                setLoadingHealthPlanForm(false);
                notifySuccess();
                resetFields();
                onClose();
                setModalBox(false);
              }
            })
        } catch (err) {
          createLog({
            routine: "healthplan",
            action: "create",
            type: "error",
            message: `Convênio ${healthPlan.name} não incluído`,
            jsonOrigin: JSON.stringify(healthPlan),
            jsonReturn: JSON.stringify(err)
          });

          notifyError();
        }
      } else {
        try {
          await api.put(`/health-plans/${healthPlanId}`, healthPlan)
            .then(response => {
              if (response.status === 200) {
                createLog({
                  routine: "healthplan",
                  action: "update",
                  type: "success",
                  message: `Convênio ${healthPlan.name} alterado`,
                  jsonOrigin: JSON.stringify(healthPlan),
                  jsonReturn: JSON.stringify(response)
                });

                setLoadingHealthPlanForm(false);
                notifySuccess();
                resetFields();
                onClose();
                setModalBox(false);
              }
            })
        } catch (err) {
          createLog({
            routine: "healthplan",
            action: "update",
            type: "error",
            message: `Convênio ${healthPlan.name} não alterado`,
            jsonOrigin: JSON.stringify(healthPlan),
            jsonReturn: JSON.stringify(err)
          });

          notifyError();
        }
      }
    }

    setLoadingHealthPlanForm(false);
  }

  function notifySuccess() {
    toast.success(`✔️ Convênio ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function notifyError() {
    toast.error("❌ Ocorreu um problema ao salvar o convênio", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function resetFields() {
    setHealthPlanId("");
    setName("");
    setShortName("");
    setDocumentId("");
    setTaxPercent(0);
    setCreateDate("");
    setAddressInfo({});
    setContactName("");
    setContactEmail("");
    setContactHomePhone("");
    setContactComercialPhone("");
    setContactCellPhone("");
    setObservation("");
    setPriceTableList([]);
    setActive(true);

    setInvalidName(false);
    setInvalidDocumentId(false);
    setInvalidDocumentIdText("Informe um CNPJ válido");
  }

  async function maskDocumentField() {
    setInvalidDocumentId(false);

    const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

    if (documentUnformatted.length > 0) {
      if (documentUnformatted !== "00000000000000" && !cnpj.isValid(documentUnformatted)) {
        setInvalidDocumentId(true);
      } else {
        if (!isUpdate) {
          await api.get(`/health-plans/document/${documentUnformatted}`)
            .then(response => {
              if (response.status === 200) {
                setInvalidDocumentId(true);
                setInvalidDocumentIdText("CNPJ já cadastrado");
              }
            });
        }
      }
    } else {
      setInvalidDocumentId(true);
    }
  }

  function onClosePrice(mode, priceItem) {
    let newPriceTable = [...priceTableList];

    if (mode === "update") {
      const indexOfItem = newPriceTable.findIndex(x => x._id === priceItem.innerId);
      newPriceTable[indexOfItem] = priceItem;
    } else {
      newPriceTable.push(priceItem);
    }

    setPriceTableList(newPriceTable);
  }

  function handleDeletePrice(id) {
    const newPriceTable = priceTableList.filter(x => x._id !== id);
    setPriceTableList(newPriceTable);
  }

  return (
    <>
      <Modal size='xl' fullscreen onHide={setModalBox} show={modalBox}>
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">{(!isUpdate ? "Novo " : "Alterar ")}Convênio</h3>
            <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <div className="row" hidden={!isUpdate}>
                <div className="form-group mb-3 col-md-6">
                  <FormCheck
                    type="switch"
                    id="active"
                    label="Ativo"
                    defaultChecked={active}
                    onChange={() => setActive(!active)}
                  />
                </div>
              </div>
              <div className="form-group mb-12 col-md-12">
                <Tabs
                  defaultActiveKey="general"
                  id="fill-tab-example"
                  className="mb-3 basic-list-group"
                  fill
                >
                  <Tab eventKey="general" title="Informações Gerais">
                    <div className="row">
                      <div className="form-group mb-3 col-md-2">
                        <label>CNPJ</label>
                        <InputMask
                          mask="99.999.999/9999-99"
                          maskChar=" "
                          value={documentId}
                          onChange={(event) => {
                            setDocumentId(event?.target.value);
                          }}
                          onBlur={() => { maskDocumentField() }}
                          alwaysShowMask={true}
                          disabled={isUpdate}
                        >
                          {(inputProps) => <input {...inputProps} autoFocus type="text" className={`form-control ${(invalidDocumentId ? "is-invalid" : "")}`} disabled={isUpdate} />}
                        </InputMask>
                        <DFormText hidden={!invalidDocumentId} color="danger">{invalidDocumentIdText}</DFormText>
                      </div>
                      <div className="form-group mb-3 col-md-7">
                        <label>Razão Social</label>
                        <input
                          autoFocus={isUpdate}
                          type="text"
                          className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                          value={name}
                          onChange={e => setName(e.target.value)}
                        />
                        <DFormText hidden={!invalidName} color="danger">Informe o nome do convênio</DFormText>
                      </div>
                      <div className="form-group mb-3 col-md-3">
                        <label>Nome Reduzido</label>
                        <input
                          type="text"
                          maxLength={20}
                          className="form-control"
                          value={shortName}
                          onChange={e => setShortName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-2">
                        <label>% Desconto IR</label>
                        <CurrencyInput
                          className="form-control text-end"
                          id="input-taxes"
                          value={taxPercent}
                          maxLength={6}
                          decimalsLimit={2}
                          onValueChange={(value) => setTaxPercent(value)}
                          intlConfig={{ locale: "pt-BR" }}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-2">
                        <label>Data de Cadastro</label>
                        <input
                          type="text"
                          className="form-control"
                          value={createDate}
                          disabled
                        />
                      </div>
                    </div>

                    <DAddressForm
                      addressInfoParm={addressInfo}
                      onChangeAddress={setAddressInfo}
                    />

                    <h5>Contatos</h5>
                    <div className="row">
                      <div className="form-group mb-3 col-md-8">
                        <label>Nome do Contato</label>
                        <input
                          type="text"
                          className="form-control"
                          value={contactName}
                          onChange={e => setContactName(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-3 col-md-4">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          value={contactEmail}
                          onChange={e => setContactEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-4">
                        <label>Residencial</label>
                        <InputMask
                          mask="(99) 9999-9999"
                          maskChar=" "
                          value={contactHomePhone}
                          onChange={(event) => {
                            setContactHomePhone(event?.target.value);
                          }}
                          alwaysShowMask={true}
                        >
                          {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                        </InputMask>
                      </div>
                      <div className="form-group mb-3 col-md-4">
                        <label>Comercial</label>
                        <InputMask
                          mask="(99) 9999-9999"
                          maskChar=" "
                          value={contactComercialPhone}
                          onChange={(event) => {
                            setContactComercialPhone(event?.target.value);
                          }}
                          alwaysShowMask={true}
                        >
                          {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                        </InputMask>
                      </div>
                      <div className="form-group mb-3 col-md-4">
                        <label>Celular</label>
                        <InputMask
                          mask="(99) 99999-9999"
                          maskChar=" "
                          value={contactCellPhone}
                          onChange={(event) => {
                            setContactCellPhone(event?.target.value);
                          }}
                          alwaysShowMask={true}
                        >
                          {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                        </InputMask>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-12">
                        <label>Observações</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={observation}
                          onChange={e => setObservation(e.target.value)}
                        />
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="price" title="Tabela de Preços">
                    <div className="row">
                      <div className="form-group mb-3 col-md-12 text-end">
                        <button
                          className="btn btn-primary ms-1"
                          title="Adicionar novo item"
                          onClick={() => childRef.current.openModal("new")}
                        >
                          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                            <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                          </svg> Adicionar
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-md-12">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                <strong>Procedimento</strong>
                              </th>
                              <th>
                                <strong>Complexidade</strong>
                              </th>
                              <th>
                                <strong>Acomodação</strong>
                              </th>
                              <th>
                                <strong>Valor</strong>
                              </th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              priceTableList.map((priceTableItem) => {
                                return (
                                  <tr key={priceTableItem.id}>
                                    <td>{priceTableItem.procedure_name}</td>
                                    <td>{priceTableItem.complexity_name}</td>
                                    <td>{priceTableItem.accommodation_name}</td>
                                    <td className="text-end">{priceTableItem.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                    <td>
                                      <div className="d-flex">
                                        <button
                                          className="btn btn-primary shadow sharp ms-1"
                                          onClick={() => childRef.current.openModal("update", priceTableItem)}
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </button>
                                        <button
                                          className="btn btn-primary shadow sharp ms-1"
                                          onClick={() => handleDeletePrice(priceTableItem._id)}
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
            <Button variant="success btn-rounded" onClick={handleSaveHealthPlan}>Salvar</Button>
          </div>
        </div>
      </Modal>

      <PriceTableForm ref={childRef} onClose={onClosePrice} />

      <ToastContainer />

      <ModalLoading
        visible={loadingHealthPlanForm}
        message="Gravando Informações..."
        onClose={setLoadingHealthPlanForm}
      />
    </>
  )
})

export default HealthPlanForm;