import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Table } from "react-bootstrap";

const HealthPlanProcedure = props => {

    const {
        className,
        innerRef,
        //
        healthPlanSummary,
        ...attributes
    } = props;

    const [series, setSeries] = useState([]);
    const [seriesTotal, setSeriesTotal] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [selectBtn, setSelectBtn] = useState("Geral");

    useEffect(() => {
        changeSeries([...healthPlanSummary]);
    }, [healthPlanSummary])

    function changeSeries(dataArray) {
        let newSeries = [];
        let totalSeries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (let indexSummary = 0; indexSummary < dataArray.length; indexSummary++) {
            const indexSeries = newSeries.findIndex(x => x.health_plan_id === dataArray[indexSummary].health_plan_id);

            if (indexSeries < 0) {
                newSeries.push({
                    health_plan_id: dataArray[indexSummary].health_plan_id,
                    health_plan_name: dataArray[indexSummary].health_plan_name,
                    quantity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                });
                newSeries[newSeries.length - 1].quantity[dataArray[indexSummary].month - 1] = dataArray[indexSummary].count;
                newSeries[newSeries.length - 1].quantity[12] = dataArray[indexSummary].count;
            } else {
                newSeries[indexSeries].quantity[dataArray[indexSummary].month - 1] += dataArray[indexSummary].count;
                newSeries[indexSeries].quantity[12] += dataArray[indexSummary].count;
            }

            totalSeries[dataArray[indexSummary].month - 1] += dataArray[indexSummary].count;
            totalSeries[12] += dataArray[indexSummary].count;
        }

        const seriesSorted = newSeries.sort((a, b) => a.health_plan_name - b.health_plan_name);

        setSeries(seriesSorted);
        setSeriesTotal(totalSeries);
    }

    function handleChangeComplexity(id) {
        if (id !== "Geral") {
            if (id === "Complexa" || id === "Convencional") {
                const filter = (id === "Complexa" ? "COM" : "NOR");
                const healthPlanSummaryFiltered = healthPlanSummary.filter(x => x.complexity === filter);
                changeSeries([...healthPlanSummaryFiltered]);
            } else {
                const filter = (id === "Apartamento" ? "APT" : "ENF");
                const healthPlanSummaryFiltered = healthPlanSummary.filter(x => x.accommodation === filter);
                changeSeries([...healthPlanSummaryFiltered]);
            }
        } else {
            changeSeries([...healthPlanSummary]);
        }

        setSelectBtn(id);
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-10">
                    <div className="card-header border-0 flex-wrap pb-2">
                        <div className="chart-title mb-2 ">
                            <h2 className="heading">Procedimentos por Convênio</h2>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2">
                    <div className="statistic-content">
                        <div className="d-flex justify-content-between">
                            <Dropdown className="me-3 drop-select">
                                <Dropdown.Toggle
                                    as="div"
                                    className="i-false drop-select-btn style-dark"
                                    style={{backgroundColor: "#fff"}}
                                >
                                    {selectBtn} <i className="fa-solid fa-angle-down"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item key="Geral" onClick={() => handleChangeComplexity("Geral")}>Geral</Dropdown.Item>
                                    <Dropdown.Item key="Complexa" onClick={() => handleChangeComplexity("Complexa")}>Complexa</Dropdown.Item>
                                    <Dropdown.Item key="Convencional" onClick={() => handleChangeComplexity("Convencional")}>Convencional</Dropdown.Item>
                                    <Dropdown.Item key="Apartamento" onClick={() => handleChangeComplexity("Apartamento")}>Apartamento</Dropdown.Item>
                                    <Dropdown.Item key="Enfermaria" onClick={() => handleChangeComplexity("Enfermaria")}>Enfermaria</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card-body pt-0 custome-tooltip">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>CONVÊNIOS</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>JAN</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>FEV</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>MAR</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>ABR</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>MAI</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>JUN</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>JUL</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>AGO</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>SET</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>OUT</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>NOV</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>DEZ</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>TOTAL</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    series.map((seriesItem) => {
                                        const counter = seriesItem.quantity;
                                        let healthPlanPercent = 0;

                                        if (seriesTotal[12] > 0) {
                                            healthPlanPercent = ((counter[12] / seriesTotal[12]) * 100).toFixed(2)
                                        }

                                        return (
                                            <tr key={seriesItem.health_plan_id}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="w-space-no">{seriesItem.health_plan_name}</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="text-center">
                                                        {counter[0]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[1]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[2]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[3]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[4]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[5]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[6]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[7]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[8]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[9]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[10]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[11]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        <strong>{counter[12]}</strong>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-end">
                                                        {healthPlanPercent.toLocaleString("pt-br")} %
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                <tr key="TOTGER">
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="w-space-no"><strong>TOTAL GERAL</strong></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[0]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[1]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[2]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[3]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[4]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[5]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[6]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[7]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[8]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[9]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[10]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[11]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[12]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-end">
                                            <strong>100,00 %</strong>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

HealthPlanProcedure.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    healthPlanSummary: PropTypes.array
};

HealthPlanProcedure.defaultProps = {
    healthPlanSummary: []
};

export default HealthPlanProcedure;