import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Table,
} from "react-bootstrap";
import InputMask from "react-input-mask";

import PageTitle from "../../layouts/PageTitle";
import DFormText from "../../components/DFormText/DFormText";
import DPagination from "../../components/DPagination/DPagination";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

import dayjs from "dayjs";

import api from "../../../services/api";
import { isAuthenticated, hasAccess, checkTermsAndPrivacy } from "../../../services/utils";
import ProtocolForm from "./ProtocolForm";
import Terms from "../UserAgreement/Terms";
import Privacy from "../UserAgreement/Privacy";

const ProtocolList = () => {
    const protocolFormRef = useRef();
    const termsRef = useRef();
    const privacyRef = useRef();
    const [loading, setLoading] = useState(false);
    const [schedules, setSchedules] = useState([]);
    const [pacients, setPacients] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterName, setFilterName] = useState("");
    const [invalidFilterName, setInvalidFilterName] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated()) {
            return history.push("/login");
        }

        if (!checkTermsAndPrivacy("terms")) {
            termsRef.current.openModal();
        }

        if (!checkTermsAndPrivacy("privacy")) {
            privacyRef.current.openModal();
        }
    }, [])

    async function getSchedules(page = currentPage) {
        setLoading(true);

        let filter = "&";
        if (filterName !== "") {
            filter += `qs=${filterName}&`;
        }

        await api.get(`/schedules?offset=${page}&limit=10&complexity=COM${filter}`)
            .then(response => {
                if (response.status === 200) {
                    let schedulesReturned = response.data.schedules;
                    
                    // response.data.schedules.forEach(scheduleItem => {
                    //     if (scheduleItem.reports) {
                    //         scheduleItem.reports.forEach(report => {
                    //             const protocols = scheduleItem.protocols ?? [];
                    //             schedulesReturned.push({ ...report, protocols });
                    //         })                            
                    //     }
                    // });

                    setSchedules(schedulesReturned);

                    const pages = Math.ceil(response.data.total / 10);
                    const maxPages = Math.max(pages, 1);

                    if (maxPages !== totalPages) {
                        setTotalPages(maxPages);
                    }
                }
            })

        setLoading(false);
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getSchedules(page);
    }

    function onCloseModal() {
        getSchedules();
    }

    function handleFilter() {
        setInvalidFilterName(false);

        if (!filterName) {
            setInvalidFilterName(true);
            return;
        }

        setCurrentPage(1);
        getSchedules(1);
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Pacient" motherMenu="Records" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Protocolo</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <label>Informe o nome do paciente</label>
                                            <input
                                                type="text"
                                                className={`form-control ${(invalidFilterName ? "is-invalid" : "")}`}
                                                value={filterName}
                                                onChange={e => setFilterName(e.target.value)}
                                            />
                                            <DFormText hidden={!invalidFilterName} color="danger">Campo obrigatório</DFormText>
                                        </div>
                                    </div>

                                    <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <button className="btn btn-primary ms-1" onClick={handleFilter}>
                                                Filtrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Procedimentos Realizados</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Data Ablação</strong>
                                            </th>
                                            <th>
                                                <strong>Paciente</strong>
                                            </th>
                                            <th>
                                                <strong>Hospital</strong>
                                            </th>
                                            <th>
                                                <strong>Médico Encaminhador</strong>
                                            </th>
                                            <th>
                                                <strong>Médico Responsável</strong>
                                            </th>
                                            <th>
                                                <strong>Convênio</strong>
                                            </th>
                                            <th>
                                                <strong>Procedimento</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            schedules.map((schedule) => {
                                                if (schedule.status === "can") {
                                                    return <></>;
                                                }

                                                return (
                                                    <tr key={schedule._id} onClick={() => protocolFormRef.current.openModal(schedule)} style={{ cursor: "pointer"}}>
                                                        <td>{dayjs(schedule.execution_date).format("DD/MM/YYYY")}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{schedule.pacient_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{schedule.hospital_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{schedule.forward_doctor_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{schedule.main_doctor_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{schedule.health_plan_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{schedule.procedure_name}</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <ProtocolForm ref={protocolFormRef} onClose={onCloseModal} />

            <Terms ref={termsRef} />

            <Privacy ref={privacyRef} />

            <ModalLoading
                visible={loading}
                message="Carregando Procedimentos..."
                onClose={setLoading}
            />
        </>
    );
};

export default ProtocolList;