import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Button, Card, Table } from "react-bootstrap";
import dayjs from "dayjs";

import { yesNoOptions } from "../../../enum/enums";

const BULGE_OPTIONS = [
    {
        value: "NO",
        label: "Não"
    },
    {
        value: "REGULAR",
        label: "Sim - Regular"
    },
    {
        value: "IRREGULAR",
        label: "Sim - Irregular"
    },
];

const PhysicalExerciseCard = props => {
    const {
        children,
        className,
        //
        onRequestAdd,
        physicalExercisesListParm,
        ...attributes
    } = props;

    const [physicalExercisesList, setPhysicalExercisesList] = useState(physicalExercisesListParm);
    const [particularity, setParticularity] = useState(undefined);
    const [head, setHead] = useState("");
    const [neck, setNeck] = useState("");
    const [lungs, setLungs] = useState("");
    const [abdomen, setAbdomen] = useState("");
    const [limbs, setLimbs] = useState("");
    const [ictus, setIctus] = useState("");

    const [systolicPa, setSystolicPa] = useState("");
    const [diastolicPa, setDiastolicPa] = useState("");
    const [heartRate, setHeartRate] = useState("");
    const [pulse, setPulse] = useState("");
    const [temperature, setTemperature] = useState("");
    const [posteriorTibia, setPosteriorTibia] = useState(false);
    const [foot, setFoot] = useState(false);
    const [bulge, setBulge] = useState(undefined);
    const [blow, setBlow] = useState("");
    const [observation, setObservation] = useState("");

    const [isEditing, setIsEditing] = useState(false);
    const [idRowEditing, setIdRowEditing] = useState(undefined);

    useEffect(() => {
        setPhysicalExercisesList(physicalExercisesListParm);
    }, [physicalExercisesListParm]);

    function handleAddPhysicalExercise() {
        if (!isEditing) {
            const newPhysicalExerciseItem = {
                id: (Math.floor(Math.random() * 65536)).toString(),
                date: dayjs().toDate(),
                particularity,
                head,
                neck,
                lungs,
                abdomen,
                limbs,
                ictus,
                systolicPa,
                diastolicPa,
                heartRate,
                pulse,
                temperature,
                posteriorTibia,
                foot,
                bulge,
                blow,
                observation
            };

            const newPhysicalExercisesList = [...physicalExercisesList, newPhysicalExerciseItem];
            setPhysicalExercisesList(newPhysicalExercisesList);
            onRequestAdd(newPhysicalExercisesList);
        } else {
            const newPhysicalExercisesList = physicalExercisesList.map(physicalExerciseItem => {
                if (physicalExerciseItem.id === idRowEditing) {
                    return {
                        id: physicalExerciseItem.id,
                        particularity,
                        head,
                        neck,
                        lungs,
                        abdomen,
                        limbs,
                        ictus,
                        systolicPa,
                        diastolicPa,
                        heartRate,
                        pulse,
                        temperature,
                        posteriorTibia,
                        foot,
                        bulge,
                        blow,
                        observation
                    }
                }

                return physicalExerciseItem;
            })
            setPhysicalExercisesList(newPhysicalExercisesList);
            onRequestAdd(newPhysicalExercisesList);
        }

        resetFields();
    }

    function handleEditPhysicalExercise(id) {
        const editRowInfo = physicalExercisesList.find(x => x.id === id);
        if (editRowInfo) {
            setParticularity(editRowInfo.particularity);
            setHead(editRowInfo.head);
            setNeck(editRowInfo.neck);
            setLungs(editRowInfo.lungs);
            setAbdomen(editRowInfo.abdomen);
            setLimbs(editRowInfo.limbs);
            setIctus(editRowInfo.ictus);
            setSystolicPa(editRowInfo.systolicPa);
            setDiastolicPa(editRowInfo.diastolicPa);
            setHeartRate(editRowInfo.heartRate);
            setPulse(editRowInfo.pulse);
            setTemperature(editRowInfo.temperature);
            setPosteriorTibia(editRowInfo.posteriorTibia);
            setFoot(editRowInfo.foot);
            setBulge(editRowInfo.bulge);
            setBlow(editRowInfo.blow);
            setObservation(editRowInfo.observation);

            setIdRowEditing(id);
            setIsEditing(true);
        }
    }

    function handleDeletePhysicalExercise(id) {
        const newPhysicalExercisesList = physicalExercisesList.filter(x => x.id !== id);
        setPhysicalExercisesList(newPhysicalExercisesList);
        onRequestAdd(newPhysicalExercisesList);
    }

    function resetFields() {
        setParticularity(undefined);
        setHead("");
        setNeck("");
        setLungs("");
        setAbdomen("");
        setLimbs("");
        setIctus("");
        setSystolicPa("");
        setDiastolicPa("");
        setHeartRate("");
        setPulse("");
        setTemperature("");
        setPosteriorTibia(false);
        setFoot(false);
        setBulge(undefined);
        setBlow("");
        setObservation("");

        setIsEditing(false);
        setIdRowEditing(undefined);
    }

    return (
        <>
            <div className="basic-form">
                <div className="row">
                    <div className="form-group mb-3 col-md-6">
                        <Card>
                            <Card.Header>
                                <h4>Exame Segmentar - <small>Parte 1</small></h4>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <label>Particularidades?</label>
                                            <Select
                                                isClearable
                                                defaultValue={particularity}
                                                value={particularity}
                                                onChange={(e) => setParticularity(e)}
                                                options={yesNoOptions}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Cabeça</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={head}
                                                onChange={e => setHead(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Pescoço</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={neck}
                                                onChange={e => setNeck(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Pulmões</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={lungs}
                                                onChange={e => setLungs(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Abdomen</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={abdomen}
                                                onChange={e => setAbdomen(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Membros</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={limbs}
                                                onChange={e => setLimbs(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Ictus</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={ictus}
                                                onChange={e => setIctus(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="form-group mb-3 col-md-6">
                        <Card>
                            <Card.Header>
                                <h4>Exame Segmentar - <small>Parte 2</small></h4>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-3">
                                            <label>PA - Sistólica</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={systolicPa}
                                                onChange={e => setSystolicPa(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <label>PA - Diastólica</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={diastolicPa}
                                                onChange={e => setDiastolicPa(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-2">
                                            <label>FC</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={heartRate}
                                                onChange={e => setHeartRate(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-2">
                                            <label>Pulso</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={pulse}
                                                onChange={e => setPulse(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-2">
                                            <label>Temperatura</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={temperature}
                                                onChange={e => setTemperature(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <h6>Pulso - <small>Insira as informações sobre pulso</small></h6>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-3">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultChecked={posteriorTibia}
                                                    onChange={() => setPosteriorTibia(!posteriorTibia)}
                                                />
                                                <label className="form-check-label">Tíbia Posterior</label>
                                            </div>
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    defaultChecked={foot}
                                                    onChange={() => setFoot(!foot)}
                                                />
                                                <label className="form-check-label">Pedioso</label>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <h6>Précordio - <small>Insira as informações sobre précordio</small></h6>
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <label>Bulhas</label>
                                            <Select
                                                isClearable
                                                defaultValue={bulge}
                                                value={bulge}
                                                onChange={(e) => setBulge(e)}
                                                options={BULGE_OPTIONS}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Sopros</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={blow}
                                                onChange={e => setBlow(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Observações</label>
                                            <textarea
                                                type="text"
                                                className="form-control"
                                                value={observation}
                                                onChange={e => setObservation(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <Button
                            size='sm'
                            variant="warning"
                            onClick={handleAddPhysicalExercise}
                        >
                            {(isEditing ? "Salvar" : "Adicionar")} Exame Físico
                        </Button>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="form-group mb-3 col-md-12" hidden={physicalExercisesList.length === 0}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Data</strong>
                                    </th>
                                    <th>
                                        <strong>Observação</strong>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    physicalExercisesList.map((physicalExerciseItem) => {
                                        return (
                                            <tr key={physicalExerciseItem.id}>
                                                <td>{dayjs(physicalExerciseItem.data).format("DD/MM/YYYY")}</td>
                                                <td>{physicalExerciseItem.observation}</td>
                                                <td>
                                                    <div className="d-flex">
                                                        <button
                                                            className="btn btn-info shadow sharp ms-1"
                                                            onClick={() => handleEditPhysicalExercise(physicalExerciseItem.id)}
                                                            disabled={isEditing}
                                                        >
                                                            <i className="fas fa-pencil-alt"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-danger shadow sharp ms-1"
                                                            onClick={() => handleDeletePhysicalExercise(physicalExerciseItem.id)}
                                                            disabled={isEditing}
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

PhysicalExerciseCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

PhysicalExerciseCard.defaultProps = {
    physicalExercisesListParm: []
};

export default PhysicalExerciseCard;