import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Button,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import api from "../../../services/api";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DPagination from "../../components/DPagination/DPagination";
import ReceiptCard from "./ReceiptCard";

import { checkTermsAndPrivacy, isAuthenticated } from "../../../services/utils";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs";
import Terms from "../UserAgreement/Terms";
import Privacy from "../UserAgreement/Privacy";

registerLocale("ptBR", ptBR);

const Receipt = () => {
  const childRef = useRef();
  const termsRef = useRef();
  const privacyRef = useRef();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Carregando Informações...");
  const [operationals, setOperationals] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [filterDateFrom, setFilterDateFrom] = useState(null);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [healthPlans, setHealthPlans] = useState([]);
  const [filterHealthPlan, setFilterHealthPlan] = useState([]);

  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated()) {
      return history.push("/login");
    }

    if (!checkTermsAndPrivacy("terms")) {
      termsRef.current.openModal();
    }

    if (!checkTermsAndPrivacy("privacy")) {
      privacyRef.current.openModal();
    }

    getHealthPlans();
    getOperationals();
  }, [])

  async function getHealthPlans() {
    await api.get("/health-plans?status=true&offset=1&limit=1000")
      .then(response => {
        if (response.status === 200) {
          setHealthPlans(response.data.healthPlans.map(healthPlan => {
            return {
              value: healthPlan._id,
              label: healthPlan.name
            }
          }));
        }
      })
  }

  async function getOperationals(page = currentPage) {
    setLoadingMessage("Carregando Informações...");
    setLoading(true);

    let filter = "&";
    if (filterName) {
      filter += `qs=${filterName}&`;
    }
    if (filterDateFrom && filterDateTo) {
      const filterDateToUTC = dayjs(filterDateTo).add(1, "day");
      filter += `executionDateStart=${dayjs(filterDateFrom).toISOString()}&executionDateEnd=${dayjs(filterDateToUTC).toISOString()}&`;
    }
    if (filterHealthPlan.length > 0) {
      filter += "healthPlans=";
      for (let i = 0; i < filterHealthPlan.length; i++) {
        if (i > 0) {
          filter += ","
        }
        filter += filterHealthPlan[i].value;
      }
    }

    await api.get(`/operationals?offset=${page}&limit=10&status=inv,rec${filter}`)
      .then(response => {
        if (response.status === 200) {
          const operationalsReturned = response.data.operationals.map((operational) => {
            let isDisabledCheck = false;
            if (operational.status !== "rti" || !operational.commission || (operational.commission && operational.commission?.total === 0)) {
              isDisabledCheck = true;
            }

            const totalReceipt = operational.receipts.reduce((acc, operationalItem) => {
              return acc + operationalItem.value;
            }, 0);

            return {
              ...operational,
              price: operational.commission?.total ?? 0,
              totalReceipt: totalReceipt ?? 0,
              isChecked: false,
              isDisabled: isDisabledCheck,
              statusBadge: getStatus(operational.status)
            }
          })
          setOperationals(operationalsReturned);

          let pages = Math.ceil(response.data.total / 10);
          setTotalPages(Math.max(pages, 1));
        }
      })

    setLoading(false);
  }

  function getStatus(status) {
    let statusColor = "warning";
    let statusLabel = "Apto a faturar";

    switch (status) {
      case "inv":
        statusColor = "info";
        statusLabel = "Faturado";
        break;
      case "rec":
        statusColor = "success";
        statusLabel = "Recebido";
        break;
      default:
        statusColor = "warning";
        statusLabel = "Apto a faturar";
        break;
    }

    return {
      color: statusColor,
      label: statusLabel
    };
  }

  function setActivePage(page) {
    setCurrentPage(page);
    getOperationals(page);
  }

  function handleFilter() {
    setCurrentPage(1);
    getOperationals(1);
  }

  function onCloseModal() {
    getOperationals();
  }

  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Receipt" motherMenu="Operational" />
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Recebimentos</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="basic-form">
                  <div className="row">
                    <div className="form-group mb-3 col-md-3">
                      <label>Data Inicial</label>
                      <br />
                      <DatePicker
                        selected={filterDateFrom}
                        onChange={(date) => setFilterDateFrom(date)}
                        locale="ptBR"
                        dateFormat="P"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3 col-md-3">
                      <label>Data Final</label>
                      <br />
                      <DatePicker
                        selected={filterDateTo}
                        onChange={(date) => setFilterDateTo(date)}
                        locale="ptBR"
                        dateFormat="P"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Paciente - <small>Informe o CPF ou parte do nome</small></label>
                      <input
                        type="text"
                        className="form-control"
                        value={filterName}
                        onChange={e => setFilterName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-12">
                      <label>Convênio(s)</label>
                      <Select
                        value={filterHealthPlan}
                        closeMenuOnSelect={false}
                        defaultValue={filterHealthPlan}
                        isMulti
                        options={healthPlans}
                        placeholder="Selecione..."
                        onChange={setFilterHealthPlan}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            height: 45,
                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                          }),
                        }}
                      />
                    </div>
                  </div>

                  <div className="row" style={{ textAlign: "right" }}>
                    <div className="form-group mb-12 col-md-12">
                      <Button
                        variant="outline-primary btn-rounded"
                        onClick={handleFilter}
                      >
                        Filtrar
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Status</strong>
                      </th>
                      <th>
                        <strong>Data</strong>
                      </th>
                      <th>
                        <strong>Paciente</strong>
                      </th>
                      <th>
                        <strong>Convênio</strong>
                      </th>
                      <th>
                        <strong>Médico</strong>
                      </th>
                      <th>
                        <strong>Valor Previsto</strong>
                      </th>
                      <th>
                        <strong>Valor Recebido</strong>
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      operationals.map((operational) => {
                        return (
                          <tr key={operational._id}>
                            <td>
                              <div className="d-flex align-items-left">
                                <Badge bg="" className={`badge-${operational.statusBadge.color}`} >{operational.statusBadge.label}</Badge>
                              </div>
                            </td>
                            <td>{dayjs(operational.execution_date).format("DD/MM/YYYY")}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="w-space-no">{operational.pacient_name}</span>
                              </div>
                            </td>
                            <td>{operational.health_plan_name}</td>
                            <td>{operational.main_doctor_name}</td>
                            <td>
                              <div className="text-end">
                                {operational.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </div>
                            </td>
                            <td>
                              <div className="text-end">
                                {operational.totalReceipt.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary shadow sharp ms-1"
                                  onClick={() => childRef.current.openModal(operational)}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>

                <DPagination
                  activePage={currentPage}
                  pages={totalPages}
                  onActivePageChange={(i) => setActivePage(i)}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>

      <ReceiptCard ref={childRef} onClose={onCloseModal} />

      <Terms ref={termsRef} />

      <Privacy ref={privacyRef} />

      <ModalLoading
        visible={loading}
        message={loadingMessage}
        onClose={setLoading}
      />
    </>
  );
};

export default Receipt;