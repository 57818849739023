const catheterProblemsList = [
    {
        value: "ABB",
        label: "Abbot"
    },
    {
        value: "MED",
        label: "Medtronic"
    },
    {
        value: "JJ",
        label: "JJ"
    },
    {
        value: "BIO",
        label: "Biotronik"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const complexAblationList = [
    {
        value: "FA",
        label: "FA"
    },
    {
        value: "TV",
        label: "TV"
    },
    {
        value: "FLUATI",
        label: "Flutter atípico"
    },
    {
        value: "EXTVEN",
        label: "Extrassístole ventricular"
    },
    {
        value: "TAQATR",
        label: "Taquicardia atrial"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const complicationsDuringProcedureList = [
    {
        value: "TAMP",
        label: "Tamponamento (punção/cirurgia)"
    },
    {
        value: "EMBCER",
        label: "Embolia cerebral"
    },
    {
        value: "EMBPER",
        label: "Embolia periférica"
    },
    {
        value: "CHO",
        label: "Choque"
    },
    {
        value: "LESVAS",
        label: "Lesão Vascular"
    },
    {
        value: "COMANE",
        label: "Complicação anestésica"
    },
    {
        value: "OBI",
        label: "Óbito"
    }
];

const conventionalAblationList = [
    {
        value: "TRN",
        label: "TRN"
    },
    {
        value: "WPW",
        label: "WPW"
    },
    {
        value: "TAQATR",
        label: "Taquicardia atrial"
    },
    {
        value: "FLUATR",
        label: "Flutter atrial"
    },
    {
        value: "EXTVEN",
        label: "Extrassístole ventricular"
    },
    {
        value: "TAQVEN",
        label: "Taquicardia ventricular"
    }
];

const cryoablationList = [
    {
        value: "FA",
        label: "FA"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const devicesProblemsList = [
    {
        value: "RF",
        label: "RF"
    },
    {
        value: "POL",
        label: "Polígrafo"
    },
    {
        value: "MAPELE",
        label: "Mapeamento eletroanatômico"
    }
];

const femoralPuncturesList = [
    {
        value: "0",
        label: "0"
    },
    {
        value: "1",
        label: "1"
    },
    {
        value: "2",
        label: "2"
    },
    {
        value: "3",
        label: "3"
    }
];

const implantList = [
    {
        value: "CAMUNI",
        label: "Câmara única"
    },
    {
        value: "CAMDUP",
        label: "Dupla Câmara"
    },
    {
        value: "HIS",
        label: "His"
    },
    {
        value: "CDI",
        label: "CDI"
    },
    {
        value: "RES",
        label: "Ressinc"
    },
    {
        value: "CDIRES",
        label: "CDI + Ressinc"
    }
];

const materialList = [
    {
        value: "ABB",
        label: "Abbot"
    },
    {
        value: "MED",
        label: "Medtronic"
    },
    {
        value: "JJ",
        label: "JJ"
    },
    {
        value: "BIO",
        label: "Biotronik"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const preProcedureComplicationsList = [
    {
        value: "ECO",
        label: "Eco TE"
    },
    {
        value: "OUT",
        label: "Outras"
    }
];

const proceduresList = [
    {
        value: "EEF",
        label: "Estudo eletrofisiológico"
    },
    {
        value: "ABNOR",
        label: "Ablação convencional"
    },
    {
        value: "ABCOM",
        label: "Ablação complexa"
    }
];

const punctureList = [
    {
        value: "FEM",
        label: "Femoral"
    },
    {
        value: "JUG",
        label: "Jugular"
    },
    {
        value: "ART",
        label: "Arterial"
    },
    {
        value: "EPI",
        label: "Epicárdica"
    }
];

const sheathProblemsList = [
    {
        value: "ABB",
        label: "Abbot"
    },
    {
        value: "MED",
        label: "Medtronic"
    },
    {
        value: "JJ",
        label: "JJ"
    },
    {
        value: "BIO",
        label: "Biotronik"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

export {
    catheterProblemsList,
    complexAblationList,
    complicationsDuringProcedureList,
    conventionalAblationList,
    cryoablationList,
    devicesProblemsList,
    femoralPuncturesList,
    implantList,
    materialList,
    preProcedureComplicationsList,
    proceduresList,
    punctureList,
    sheathProblemsList
}