import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
} from "react-bootstrap";
import dayjs from "dayjs";

import InputMask from "react-input-mask";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import PreScheduleForm from "../PreSchedule/PreScheduleForm";
import DFormText from "../../components/DFormText/DFormText";
import DPagination from "../../components/DPagination/DPagination";

import api from "../../../services/api";
import { isAuthenticated, hasAccess, checkTermsAndPrivacy } from "../../../services/utils";
import Terms from "../UserAgreement/Terms";
import Privacy from "../UserAgreement/Privacy";

const Clinic = () => {
    const scheduleFormRef = useRef();
    const termsRef = useRef();
    const privacyRef = useRef();
    const [loading, setLoading] = useState(false);
    const [pacients, setPacients] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [hideFilter, setHideFilter] = useState(true);
    const [filterName, setFilterName] = useState("");
    const [filterDocumentId, setFilterDocumentId] = useState("");
    const [filterBirthDate, setFilterBirthDate] = useState("");
    const [filterStatus, setFilterStatus] = useState(false);

    const [invalidFilterBirthDate, setInvalidFilterBirthDate] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated()) {
            return history.push("/login");
        }

        if (!checkTermsAndPrivacy("terms")) {
            termsRef.current.openModal();
        }

        if (!checkTermsAndPrivacy("privacy")) {
            privacyRef.current.openModal();
        }

        getPacients();
    }, [])

    async function getPacients(page = currentPage) {
        setLoading(true);

        let filter = "&";
        if (filterName !== "") {
            filter += `qs=${filterName}&`;
        }
        const documentUnformatted = filterDocumentId.replace(/[.]/g, "").replace("/", "").replace("-", "");
        if (documentUnformatted.trim()) {
            filter += `documentId=${documentUnformatted}&`;
        }
        if (filterBirthDate !== "") {
            const birthDateUnformatted = filterBirthDate.replace(/[/]/g, "");
            if (birthDateUnformatted !== "" && validBirthDate(filterBirthDate)) {
                const birthDate = birthDateUnformatted.substring(4, 8) + "-" + birthDateUnformatted.substring(2, 4) + "-" + birthDateUnformatted.substring(0, 2);
                filter += `birthDate=${birthDate}&`;
            }
        }
        if (filterStatus) {
            filter += "status=true";
        }

        await api.get(`/pacients?offset=${page}&limit=10${filter}`)
            .then(response => {
                if (response.status === 200) {
                    setPacients(response.data.pacients);

                    let pages = Math.ceil(response.data.total / 10);
                    setTotalPages(Math.max(pages, 1));
                }
            })

        setLoading(false);
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getPacients(page);
    }

    function onCloseModal() {
        getPacients();
    }

    function handleFilter() {
        setCurrentPage(1);
        getPacients(1);
    }

    function handleChangeFilterBirthDate(e) {
        setFilterBirthDate(e);
        setInvalidFilterBirthDate(false);

        if (!validBirthDate(e)) {
            setInvalidFilterBirthDate(true);
        }
    }

    function validBirthDate(date) {
        let dateIsValid = true;
        const birthDateUnformatted = date.replace(/[/]/g, "");

        if (birthDateUnformatted.trim().length === 8) {
            const dateToValidate = new Date(birthDateUnformatted.substring(4, 8) + "-" + birthDateUnformatted.substring(2, 4) + "-" + birthDateUnformatted.substring(0, 2));

            if (isNaN(dateToValidate.getTime())) {
                dateIsValid = false;
            }
        }

        return dateIsValid;
    }

    function handleMedicalRecord(pacientId) {
        history.push(`/medical-record/${pacientId}`);
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Pacient" motherMenu="Records" />
                <Row hidden={hideFilter}>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Filtros</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Nome</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={filterName}
                                                onChange={e => setFilterName(e.target.value)}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>CPF</label>
                                            <InputMask
                                                mask="999.999.999-99"
                                                maskChar=" "
                                                value={filterDocumentId}
                                                onChange={(event) => {
                                                    setFilterDocumentId(event?.target.value);
                                                }}
                                                alwaysShowMask={true}
                                            >
                                                {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                            </InputMask>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Data de Nascimento</label>
                                            <InputMask
                                                mask="99/99/9999"
                                                maskChar=" "
                                                value={filterBirthDate}
                                                onChange={(event) => {
                                                    handleChangeFilterBirthDate(event?.target.value);
                                                }}
                                                alwaysShowMask={true}
                                            >
                                                {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidFilterBirthDate ? "is-invalid" : "")}`} />}
                                            </InputMask>
                                            <DFormText hidden={!invalidFilterBirthDate} color="danger">Informe uma data válida</DFormText>
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Somente Ativos?</label>
                                            <div className="form-check">
                                                <input className="form-check-input" value={filterStatus} onChange={(e) => setFilterStatus(e.target.checked)} type="checkbox" />
                                                <label className="form-check-label">Sim</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <button className="btn btn-primary ms-1" onClick={handleFilter}>
                                                Filtrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Pacientes</Card.Title>
                                <div>
                                    <button
                                        className="btn btn-primary ms-1"
                                        onClick={() => setHideFilter(!hideFilter)}
                                        title="Mostrar/esconder filtros"
                                    >
                                        <i className="fas fa-filter"></i>
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Situação</strong>
                                            </th>
                                            <th>
                                                <strong>Nome</strong>
                                            </th>
                                            <th>
                                                <strong>Data de Nascimento</strong>
                                            </th>
                                            <th>
                                                <strong>Convênio</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pacients.map((pacient) => {
                                                return (
                                                    <tr key={pacient._id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={(pacient.status ? "fa fa-circle text-success me-1" : "fa fa-circle text-danger me-1")}></i>{" "}
                                                                {(pacient.status ? "Ativo" : "Inativo")}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{pacient.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>{dayjs(pacient.birth_date).format("DD/MM/YYYY")}</td>
                                                        <td>{pacient.health_plan_name}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                    onClick={() => scheduleFormRef.current.openModal("new", pacient)}
                                                                    disabled={!pacient.status}
                                                                    title="Incluir pré-agendamento"
                                                                    hidden={!hasAccess("preschedule", "create")}
                                                                >
                                                                    <i className="fas fa-calendar-days"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                    onClick={() => handleMedicalRecord(pacient._id)}
                                                                    title="Acessar Prontuário"
                                                                >
                                                                    <i className="fas fa-suitcase-medical"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <PreScheduleForm ref={scheduleFormRef} onClose={onCloseModal} />

            <Terms ref={termsRef} />

            <Privacy ref={privacyRef} />

            <ModalLoading
                visible={loading}
                message="Carregando Pacientes..."
                onClose={setLoading}
            />
        </>
    );
};

export default Clinic;