import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { tagPropType } from "../utils/helper";

const FormText = props => {

    const {
        tag: Tag,
        className,
        innerRef,
        //
        color,
        ...attributes
    } = props;

    const classes = classNames(
        "form-text", color && `text-${color}`, className
    );

    return (
        <Tag className={classes} {...attributes} ref={innerRef}/>
    )
}

FormText.propTypes = {
    tag: tagPropType,
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    color: PropTypes.string
};

FormText.defaultProps = {
    tag: "small",
    color: "muted"
};

export default FormText;