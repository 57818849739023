const avaliationOptions = [
    {
        value: 1,
        label: "1 - consult"
    },
    {
        value: 2,
        label: "2 - telefone"
    },
    {
        value: 3,
        label: "3 - whatsapp"
    },
    {
        value: 4,
        label: "4 - outro"
    }
];

const evtvPlaceOptions = [
    {
        value: 1,
        label: "1 - clínica"
    },
    {
        value: 2,
        label: "2 - marcelino"
    },
    {
        value: 3,
        label: "3 - cardiocar"
    },
    {
        value: 4,
        label: "4 - consult par"
    },
    {
        value: 5,
        label: "5 - outro"
    }
];

const examsOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - eco"
    },
    {
        value: 2,
        label: "2 - RM"
    },
    {
        value: 3,
        label: "3 - AngioT"
    },
    {
        value: 4,
        label: "4 - cintilo"
    },
    {
        value: 5,
        label: "5 - cate"
    },
    {
        value: 6,
        label: "6 - outro"
    }
];

const faPlaceOptions = [
    {
        value: 1,
        label: "1 - clínica"
    },
    {
        value: 2,
        label: "2 - constanti"
    },
    {
        value: 3,
        label: "3 - cardiocar"
    },
    {
        value: 4,
        label: "4 - consult par"
    },
    {
        value: 5,
        label: "5 - outro"
    }
];

const holterOptions = [
    {
        value: 0,
        label: "0 - sinusal"
    },
    {
        value: 1,
        label: "1 - FA"
    },
    {
        value: 2,
        label: "2 - flutter"
    },
    {
        value: 3,
        label: "3 - taqui atrial"
    },
    {
        value: 4,
        label: "4 - outro"
    }
];

const implantEcgOptions = [
    {
        value: 0,
        label: "0 - não tem"
    },
    {
        value: 1,
        label: "1 - normal"
    },
    {
        value: 2,
        label: "2 - MP"
    },
    {
        value: 3,
        label: "3 - BAV"
    },
    {
        value: 4,
        label: "4 - outro"
    }
];

const implantHolterOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - EV"
    },
    {
        value: 2,
        label: "2 - TVNS"
    },
    {
        value: 3,
        label: "3 - TV mono"
    },
    {
        value: 4,
        label: "4 - TV polim"
    },
    {
        value: 5,
        label: "5 - BAV"
    },
    {
        value: 6,
        label: "6 - MP"
    },
    {
        value: 7,
        label: "7 - outro"
    }
];

const postImplantOptions = [
    {
        value: 1,
        label: "1 - sem complica"
    },
    {
        value: 2,
        label: "2 - hematoma"
    },
    {
        value: 3,
        label: "3 - infecção"
    },
    {
        value: 4,
        label: "4 - outro"
    }
];

const ventriArrhythmiaOptions = [
    {
        value: 1,
        label: "1 - Extra Ven"
    },
    {
        value: 2,
        label: "2 - TVNS"
    },
    {
        value: 3,
        label: "3 - TV monom"
    },
    {
        value: 4,
        label: "4 - TVpolim"
    },
    {
        value: 5,
        label: "5 - outra"
    }
];

export {
    avaliationOptions,
    examsOptions,
    evtvPlaceOptions,
    faPlaceOptions,
    holterOptions,
    implantEcgOptions,
    implantHolterOptions,
    postImplantOptions,
    ventriArrhythmiaOptions
};