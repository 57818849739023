import React, { Component } from "react";
import Rte from "./Rte";
import CkEditor from "./CkEditor";
import PageTitle from "../../../layouts/PageTitle";

class Summernote extends Component {
   render() {
      return (
         <>
            <PageTitle activeMenu="CkEditor" motherMenu="Form" />
            <div className="row">
               <div className="col-xl-12 col-xxl-12">
                  <div className="card">
                     <div className="card-header">
                        <h4 className="card-title">Form CkEditor</h4>
                     </div>
                     <div className="card-body custom-ekeditor">
							<CkEditor />
							{/* <div className="summernote">
							<Rte /> 
							</div> */}
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default Summernote;
