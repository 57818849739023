import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

const GeneralSummary = props => {

    const {
        className,
        innerRef,
        //
        generalSummary,
        ...attributes
    } = props;

    const [generalSummaryInfo, setGeneralSummaryInfo] = useState({});

    useEffect(() => {
        // console.log(generalSummary)
        setGeneralSummaryInfo({...generalSummary});
    }, [generalSummary])

    return (
        <>
            <div className="row">
                <div className="col-xl-10">
                    <div className="card-header border-0 flex-wrap pb-2">
                        <div className="chart-title mb-2 ">
                            <h2 className="heading">Comparações Gerais</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card-body pt-0 custome-tooltip">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th className="text-center">
                                        <strong>Geral Convênios</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Convênio Complexa</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Convênio Convencional</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Convênio Enferm</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Convênio Apto</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Unimed Total</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Unimed Enferm</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Unimed Apto</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Unimed Convencional</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Unimed Complexa</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>SUS</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>Particular</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key="main_tr">
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.total}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.other?.complex.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.other?.complex ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.other?.normal.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.other?.normal ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.other?.enferm.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.other?.enferm ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.other?.apto.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.other?.apto ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.unimed?.total.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.unimed?.total ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.unimed?.enferm.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.unimed?.enferm ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.unimed?.apto.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.unimed?.apto ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.unimed?.normal.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.unimed?.normal ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.unimed?.complex.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.unimed?.complex ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.sus?.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.sus ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {generalSummaryInfo.particular?.toLocaleString("pt-br")}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            {(((generalSummaryInfo.particular ?? 0) / generalSummaryInfo.total) * 100).toFixed(2).toLocaleString("pt-br")} %
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

GeneralSummary.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    generalSummary: PropTypes.object
};

GeneralSummary.defaultProps = {
    generalSummary: {}
};

export default GeneralSummary;