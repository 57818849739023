import React,{useState} from 'react';
import { Calendar } from "react-multi-date-picker";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_br from "react-date-object/locales/gregorian_pt_br";
import "react-calendar/dist/Calendar.css";

export default function CalendarBlog(){
	const [value, setValue] = useState([new Date('2023-05-11'), new Date('2023-05-14')]);

	function handleChangeDate(e) {
		console.log(e)
	}

	return(
		<>
			<Calendar
				calendar={gregorian}
				locale={gregorian_br}
				value={value}
				onChange={handleChangeDate}
			/>
		</>
	)
}