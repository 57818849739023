import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import Select from "react-select";
import { Table } from "react-bootstrap";

import { nonDrugTreatments } from "../../../enum/enums";

const NonMedicationTreatmentCard = props => {
    const {
        children,
        className,
        //
        onRequestAdd,
        nonMedicationTreatmentsListParm,
        ...attributes
    } = props;

    const [nonMedicationTreatmentsList, setNonMedicationTreatmentsList] = useState(nonMedicationTreatmentsListParm);
    const [type, setType] = useState(null);
    const [days, setDays] = useState("");
    const [months, setMoths] = useState("");
    const [years, setYears] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [idRowEditing, setIdRowEditing] = useState(undefined);

    useEffect(() => {
        setNonMedicationTreatmentsList(nonMedicationTreatmentsListParm);
    }, [nonMedicationTreatmentsListParm]);

    function handleAddNonMedicationTreatment() {
        if (!isEditing) {
            const newNonMedicationTreatmentItem = {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type,
                days,
                months,
                years
            };

            const newNonMedicationTreatmentsList = [...nonMedicationTreatmentsList, newNonMedicationTreatmentItem];
            setNonMedicationTreatmentsList(newNonMedicationTreatmentsList);
            onRequestAdd(newNonMedicationTreatmentsList);
        } else {
            const newNonMedicationTreatmentsList = nonMedicationTreatmentsList.map(nonMedicationTreatmentItem => {
                if (nonMedicationTreatmentItem.id === idRowEditing) {
                    return {
                        id: nonMedicationTreatmentItem.id,
                        type,
                        days,
                        months,
                        years
                    }
                }

                return nonMedicationTreatmentItem;
            })
            setNonMedicationTreatmentsList(newNonMedicationTreatmentsList);
            onRequestAdd(newNonMedicationTreatmentsList);
        }

        resetFields();
    }

    function handleEditNonMedicationTreatment(id) {
        const editRowInfo = nonMedicationTreatmentsList.find(x => x.id === id);
        if (editRowInfo) {
            setType(editRowInfo.type);
            setDays(editRowInfo.days);
            setMoths(editRowInfo.months);

            setIdRowEditing(id);
            setIsEditing(true);
        }
    }

    function handleDeleteNonMedicationTreatment(id) {
        const newNonMedicationTreatmentsList = nonMedicationTreatmentsList.filter(x => x.id !== id);
        setNonMedicationTreatmentsList(newNonMedicationTreatmentsList);
        onRequestAdd(newNonMedicationTreatmentsList);
    }

    function resetFields() {
        setType(null);
        setDays("");
        setMoths("");
        setYears("");

        setIsEditing(false);
        setIdRowEditing(undefined);
    }

    return (
        <>
            <DCard borderColor="light">
                <DCardHeader color="light" textColor="dark">Tratamento Não Medicamentoso</DCardHeader>
                <DCardBody>
                    <div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-4">
                                <label>Tratamento</label>
                                <Select
                                    isClearable
                                    defaultValue={type}
                                    value={type}
                                    onChange={(e) => setType(e)}
                                    options={nonDrugTreatments}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: 45,
                                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                        }),
                                    }}
                                    placeholder="Selecione..."
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <label>Dias</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={days}
                                    onChange={e => setDays(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <label>Meses</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={months}
                                    onChange={e => setMoths(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <label>Anos</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={years}
                                    onChange={e => setYears(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <button className="btn btn-primary shadow mt-4 ms-1" onClick={handleAddNonMedicationTreatment}>
                                    <i className="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group mb-3 col-md-12" hidden={nonMedicationTreatmentsList.length === 0}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Tratamento</strong>
                                            </th>
                                            <th>
                                                <strong>Dias</strong>
                                            </th>
                                            <th>
                                                <strong>Meses</strong>
                                            </th>
                                            <th>
                                                <strong>Anos</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            nonMedicationTreatmentsList.map((nonMedicationTreatmentItem) => {
                                                return (
                                                    <tr key={nonMedicationTreatmentItem.id}>
                                                        <td>{nonMedicationTreatmentItem.type.label}</td>
                                                        <td>{nonMedicationTreatmentItem.days}</td>
                                                        <td>{nonMedicationTreatmentItem.months}</td>
                                                        <td>{nonMedicationTreatmentItem.years}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-info shadow sharp ms-1"
                                                                    onClick={() => handleEditNonMedicationTreatment(nonMedicationTreatmentItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger shadow sharp ms-1"
                                                                    onClick={() => handleDeleteNonMedicationTreatment(nonMedicationTreatmentItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </DCardBody>
            </DCard>
        </>
    )
}

NonMedicationTreatmentCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

NonMedicationTreatmentCard.defaultProps = {
    nonMedicationTreatmentsListParm: []
};

export default NonMedicationTreatmentCard;