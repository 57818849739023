import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Modal
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { logoutApp } from "../../../services/utils";

import api from "../../../services/api";

const Privacy = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [loadingPrivacy, setLoadingPrivacy] = useState(false);
    const userInfo = (localStorage.getItem("xLcxUsr") ? JSON.parse(localStorage.getItem("xLcxUsr")) : (sessionStorage.getItem("xLcxUsr") ? JSON.parse(sessionStorage.getItem("xLcxUsr")) : null));
    const history = useHistory();

    useImperativeHandle(ref, () => ({
        async openModal() {
            setModalBox(true);
        },
    }));

    async function handleAcceptPrivacy() {
        if (userInfo) {
            setLoadingPrivacy(true);

            const acceptTerm = {
                privacy: true,
                privacy_date: dayjs().toDate()
            }

            try {
                await api.put(`/users/${userInfo.user_id}`, acceptTerm)
                    .then(response => {
                        if (response.status === 200) {
                            notifySuccess();

                            let newUserInfo = { ...userInfo };
                            newUserInfo.privacy = true;
                            if (localStorage.getItem("xLcxRem")) {
                                localStorage.setItem("xLcxUsr", JSON.stringify(newUserInfo));
                            } else {
                                sessionStorage.setItem("xLcxUsr", JSON.stringify(newUserInfo));
                            }

                            setLoadingPrivacy(false);
                            setModalBox(false);
                        } else {
                            notifyError();
                        }
                    })
            } catch (err) {
                notifyError();
            }

            setLoadingPrivacy(false);
        }
    }

    function handleCancelPrivacy() {
        logoutApp();
        history.push("/login");
    }

    function notifySuccess() {
        toast.success(`✔️ Aceite registrado com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao registrar o aceite!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            Política de Privacidade do Cardiosis
                        </h3>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <p className="font-weight-thin text-4 mb-0" align="right">Última Atualização: 01 de Janeiro de 2024</p><br />
                            <p className="font-weight-thin text-4 mb-0">Bem-vindo(a)! Obrigado por utilizar o Cardiosis!</p><br />
                            <p className="font-weight-thin text-4 mb-0">Quando você utiliza o Cardiosis, você nos confia seus dados e informações. Nos comprometemos a manter essa confiança.</p><br />
                            <p className="font-weight-thin text-4 mb-0">Nesse sentido, a presente Política de Privacidade <strong>(“Política”)</strong> explica de maneira clara e acessível como as suas informações e dados serão coletados, usados, compartilhados e armazenados por meio dos nossos sistemas.</p><br />
                            <p className="mb-4" align="justify"><strong>A aceitação da nossa Política será feita quando você acessar ou usar o site, aplicativo ou serviços da Dettalles Consultoria. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados.</strong></p>
                            <p className="mb-4" align="justify">A presente Política está dividida da seguinte forma para facilitar a sua compreensão:</p>
                            <p className="mb-4" align="justify"><strong>1.</strong>	Quais informações a <strong>Dettalles Consultoria</strong> coleta</p>
                            <p className="mb-4" align="justify"><strong>2.</strong>	Como a <strong>Dettalles Consultoria</strong> usa as informações coletadas</p>
                            <p className="mb-4" align="justify"><strong>3.</strong>	Como, quando e com quem a <strong>Dettalles Consultoria</strong> compartilha suas informações</p>
                            <p className="mb-4" align="justify"><strong>4.</strong>	Quais direitos você tem sobre as informações que coletamos</p>
                            <p className="mb-4" align="justify"><strong>5.</strong>	Como a <strong>Dettalles Consultoria</strong> protege suas informações</p>
                            <p className="mb-4" align="justify"><strong>6.</strong>	Atualizações dessa política de privacidade</p>
                            <p className="mb-4" align="justify"><strong>7.</strong>	Lei aplicável</p>
                            <p className="mb-4" align="justify">Este documento deve ser lido em conjunto com o nosso Termos de Uso, que contém uma visão geral da nossa plataforma. Caso tenha dúvidas ou precise tratar de qualquer assunto relacionado a esta Política, entre em contato conosco através do e-mail <a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a>.</p>
                            <p className="mb-4" align="justify"><strong>1.	INFORMAÇÕES QUE COLETAMOS</strong></p>
                            <p className="mb-4" align="justify">Nós coletamos os seguintes tipos de informações:</p>
                            <p className="mb-4" align="justify"><strong>•	Dados de cadastro.</strong> Quando você se cadastra no Cardiosis, você nos fornece informações como login, nome, e-mail, senha.</p>
                            <p className="mb-4" align="justify"><strong>•	Dados de terceiros.</strong> A depender de suas funções, poderá cadastrar no Cardiosis informações de terceiros como nome, e-mail, CPF/CNPJ, endereço, telefone, data de nascimento, data de fundação, foto, gênero, razão social, nome fantasia, cep, cidade, estado, país.</p>
                            <p className="mb-4" align="justify"><strong>•	Informações de autenticação.</strong> Para lhe proporcionar um ambiente seguro, podemos pedir que você nos forneça informações de identificação (por exemplo, contrato social, cartão CNPJ) ou outras informações de autenticação (por exemplo, via SMS ou e-mail). </p>
                            <p className="mb-4" align="justify"><strong>1.1.	Informações geradas quando você usa nossos serviços.</strong></p>
                            <p className="mb-4" align="justify">Nós coletamos as seguintes informações geradas:</p>
                            <p className="mb-4" align="justify"><strong>•	Registros de acesso.</strong> A Dettalles Consultoria coleta automaticamente registros de acesso a aplicação, que incluem o endereço IP, com data e hora, utilizado para acessar o Cardiosis. Esses dados são de coleta obrigatória, de acordo com a Lei 12.965/2014, mas somente serão fornecidos para terceiros com a sua autorização expressa ou por meio de demanda judicial.</p>
                            <p className="mb-4" align="justify"><strong>•	Dados de uso.</strong> Nós coletamos informações sobre suas interações no Cardiosis, como sua navegação, as páginas ou outro conteúdo que você acessa ou cria, suas buscas, participações em pesquisas ou fóruns e outras ações. </p>
                            <p className="mb-4" align="justify"><strong>•	Características do equipamento.</strong> Como a maioria das aplicações, para poder funcionar o Cardiosis coleta automaticamente dados sobre as características do seu aparelho, dentre as quais o seu sistema operacional, a versão deste, informações de hardware, o idioma.</p>
                            <p className="mb-4" align="justify"><strong>•	Comunicações com a Dettalles Consultoria.</strong> Quando você se comunica com a Dettalles Consultoria, coletamos informações sobre sua comunicação, incluindo metadados como data, IP e hora das comunicações e todo o seu conteúdo, assim como qualquer informação que você escolha fornecer.</p>
                            <p className="mb-4" align="justify"><strong>1.2.	Informações de outras fontes.</strong></p>
                            <p className="mb-4" align="justify">Isso pode incluir:</p>
                            <p className="mb-4" align="justify"><strong>•	Dados coletados de outras plataformas.</strong> A Dettalles Consultoria poderá interagir com outras plataformas e outros serviços, como redes sociais, espaços de armazenamento compartilhados, portais de planos de saúde. Alguns desses serviços podem nos fornecer informações sobre você, aos quais coletaremos para lhe proporcionar uma melhor experiência e melhorar cada vez mais os nossos serviços e lhe oferecer novas funcionalidades, bem como para cadastro e autenticação na plataforma.</p>
                            <p className="mb-4" align="justify"><strong>2.	COMO USAMOS SUAS INFORMAÇÕES</strong></p>
                            <p className="mb-4" align="justify">Não custa lembrar, prezamos muito pela sua privacidade. Por isso, todos os dados e informações sobre você são tratadas como confidenciais, e somente as usaremos para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar o Cardiosis de forma plena, visando sempre melhorar a sua experiência como usuário. </p>
                            <p className="mb-4" align="justify"><strong>2.1.	Usos autorizados</strong></p>
                            <p className="mb-4" align="justify">Desta forma, poderemos utilizar seus dados para:</p>
                            <p className="mb-4" align="justify">•	Permitir que você acesse e utilize todas as funcionalidades do Cardiosis;</p>
                            <p className="mb-4" align="justify">•	Enviar a você mensagens a respeito de suporte ou serviço, como alertas, notificações e atualizações;</p>
                            <p className="mb-4" align="justify">•	Nos comunicar com você sobre produtos, serviços, promoções, notícias, atualizações, eventos e outros assuntos que você possa ter interesse;</p>
                            <p className="mb-4" align="justify">•	Analisar o tráfego dos usuários em nossas aplicações;</p>
                            <p className="mb-4" align="justify">•	Realizar publicidade direcionada conforme seus gostos, interesses e outras informações coletadas;</p>
                            <p className="mb-4" align="justify">•	Personalizar o serviço para este adequar cada vez mais aos seus gostos e interesses;</p>
                            <p className="mb-4" align="justify">•	Criarmos novos serviços, produtos e funcionalidades;</p>
                            <p className="mb-4" align="justify">•	Detecção e prevenção de fraudes, spam e incidentes de segurança;</p>
                            <p className="mb-4" align="justify">•	Verificar ou autenticar as informações fornecidas por você, inclusive comparando a dados coletados de outras fontes;</p>
                            <p className="mb-4" align="justify">•	Entender melhor o comportamento do usuário e construir perfis comportamentais;</p>
                            <p className="mb-4" align="justify">•	Para qualquer fim que você autorizar no momento da coleta de dados;</p>
                            <p className="mb-4" align="justify">•	Cumprir obrigações legais.</p>
                            <p className="mb-4" align="justify">Eventualmente, poderemos utilizar dados para finalidades não previstas nesta política de privacidade, mas estas estarão dentro das suas legítimas expectativas. <strong>O eventual uso dos seus dados para finalidades que não cumpram com essa prerrogativa será feito mediante sua autorização prévia.</strong></p>
                            <p className="mb-4" align="justify"><strong>2.2.	Exclusão dos dados</strong></p>
                            <p className="mb-4" align="justify">Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar, por procedimento gratuito e facilitado, ou quando estes não forem mais necessários ou relevantes para lhe oferecermos os nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da Dettalles Consultoria.</p>
                            <p className="mb-4" align="justify">Nos casos em que você solicite a exclusão dos seus dados, ela será feita prontamente, na medida do que for possível.</p>
                            <p className="mb-4" align="justify"><strong>2.3.	Monitoramento</strong></p>
                            <p className="mb-4" align="justify"> A Dettalles Consultoria se reserva no direito de monitorar toda a plataforma, principalmente para assegurar que as regras descritas em nosso Termos de Uso estão sendo observadas, ou ainda se não há violação ou abuso das leis aplicáveis.</p>
                            <p className="mb-4" align="justify"><strong>2.4.	Exclusão de usuário</strong>o</p>
                            <p className="mb-4" align="justify"> A Dettalles Consultria se reserva no direito de excluir determinado usuário, independente do tipo que for, caso a presente Política ou os Termos de Uso não sejam respeitados. Como prezamos pelo bom relacionamento com os usuários, reconhecemos que têm o direito de buscar entender os motivos e até contestá-los, o que pode ser feito pelo seguinte e-mail: <a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a>.</p>
                            <p className="mb-4" align="justify"><strong>3.	COMPARTILHAMENTO DAS INFORMAÇÕES</strong></p>
                            <p className="mb-4" align="justify">Todos os dados, informações e conteúdos sobre você podem ser considerados ativos no caso de negociações em que a Dettalles Consultoria fizer parte. Portanto, nos reservamos no direito de, por exemplo, incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra.<strong> Por meio desta Política você concorda e está ciente desta possibilidade.</strong></p>
                            <p className="mb-4" align="justify"> A Dettalles Consultoria se reserva no direito de fornecer seus dados e informações sobre você, incluindo interações suas, caso seja requisitado judicialmente para tanto, ato necessário para que a empresa esteja em conformidade com as leis nacionais, ou caso você autorize expressamente.</p>
                            <p className="mb-4" align="justify"><strong>4.	DIREITOS DOS TITULARES</strong></p>
                            <p className="mb-4" align="justify">Você sempre poderá optar em não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para utilizar as funcionalidades de nossas aplicações. Independente disso, você sempre possuirá direitos relativos à privacidade e à proteção dos seus dados pessoais.</p>
                            <p className="mb-4" align="justify">Dessa forma, abaixo resumimos todos os direitos que você tem sob as leis setoriais brasileiras relativas à proteção de dados e a Lei Geral de Proteção de Dados <strong>(“LGPD”)</strong>, quais sejam:</p>
                            <p className="mb-4" align="justify"><strong>4.1.	Direito de acesso.</strong> Este direito permite que você possa requisitar e receber uma cópia dos dados pessoais que possuímos sobre você.</p>
                            <p className="mb-4" align="justify"><strong>4.2.	Direito de retificação.</strong> Este direito permite que você, a qualquer momento, possa solicitar a correção e/ou retificação dos seus dados pessoais, caso identifique que alguns deles estão incorretos. Contudo, para ser efetivada essa correção, teremos que checar a validade dos dados que você nos fornece. Você pode retificar diretamente alguns dos seus dados pessoais através do e-mail <a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a>.</p>
                            <p className="mb-4" align="justify"><strong>4.3.	Direito de exclusão.</strong> Este direito permite que você possa nos solicitar a exclusão dos dados pessoais que possuímos sobre você. Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar ou quando estes não forem mais necessários ou relevantes para lhe oferecermos nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da Dettalles Consultoria. Para alterar suas informações pessoais ou excluí-las do nosso banco de dados, basta enviar um e-mail para <a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a>.</p>
                            <p className="mb-4" align="justify"><strong>4.4.	Direito de oposição ao processamento.</strong> Você também tem o direito de contestar onde e em que contexto estamos tratando seus dados pessoais para diferentes finalidades. Em determinadas situações, podemos demonstrar que temos motivos legítimos para tratar seus dados, os quais se sobrepõem aos seus direitos, caso, por exemplo, sejam essenciais para o fornecimento de nossas aplicações.</p>
                            <p className="mb-4" align="justify"><strong>4.5.	Direito de solicitar anonimização, bloqueio ou eliminação.</strong> Este direito permite que você nos peça para suspender o processamento de seus dados pessoais nos seguintes cenários: (a) se você quiser que nós estabeleçamos a precisão dos dados; (b) quando você precisar que sejam mantidos os dados mesmo se não precisarmos mais deles, conforme necessário, para estabelecer, exercer ou defender reivindicações legais; ou (c) se você se opôs ao uso de seus dados, mas nesta hipótese precisamos verificar se temos motivos legítimos para usá-los.</p>
                            <p className="mb-4" align="justify"><strong>4.6.	Direito à portabilidade.</strong> Forneceremos a você, ou a terceiros que você escolheu, seus dados pessoais em formato estruturado e interoperável.</p>
                            <p className="mb-4" align="justify"><strong>4.7.	Direito de retirar o seu consentimento.</strong> Você tem o direito de retirar o seu consentimento em relação aos termos desta Política de Privacidade. No entanto, isso não afetará a legalidade de qualquer processamento realizado anteriormente. Se você retirar o seu consentimento, talvez não possamos fornecer determinados serviços.</p>
                            <p className="mb-4" align="justify"><strong>4.8.	Direito a revisão de decisões automatizadas.</strong> Você também tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de seus dados pessoais que afetem seus interesses, incluídas as decisões destinadas a definição de perfis pessoais, profissionais, de consumo e de crédito e/ou os aspectos de sua personalidade.</p>
                            <p className="mb-4" align="justify">Talvez seja necessário solicitar informações específicas suas para nos ajudar a confirmar sua identidade e garantir seu direito de acessar seus dados pessoais (ou de exercer seus outros direitos). Esta é uma medida de segurança para garantir que os dados pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los. Podemos também contatá-lo para obter mais informações em relação à sua solicitação, a fim de acelerar nossa resposta. Tentamos responder a todas as solicitações legítimas dentro de 5 dias úteis. Ocasionalmente, pode levar mais de 5 dias se sua solicitação for particularmente complexa ou se você tiver feito várias solicitações. Neste caso, iremos comunicá-lo e mantê-lo atualizado sobre o andamento da sua solicitação.</p>
                            <p className="mb-4" align="justify">Caso Você tenha alguma dúvida sobre essas questões e sobre como você pode exercer esses direitos, fique à vontade para entrar em contato conosco no e-mail <a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a>.</p>
                            <p className="mb-4" align="justify"><strong>5.	SEGURANÇA DAS INFORMAÇÕES</strong></p>
                            <p className="mb-4" align="justify">Todos os seus dados são confidenciais e somente as pessoas com as devidas autorizações terão acesso a eles. Qualquer uso destes estará de acordo com a presente Política. A Dettalles Consultoria empreenderá todos os esforços razoáveis de mercado para garantir a segurança dos nossos sistemas e dos seus dados. Nossos servidores estão localizados em diferentes locais para garantir estabilidade e segurança, e somente podem ser acessados por meio de canais de comunicação previamente autorizados.</p>
                            <p className="mb-4" align="justify">Todas as suas informações serão, sempre que possível, criptografadas, caso não inviabilizem o seu uso pela plataforma. A qualquer momento você poderá requisitar cópia dos seus dados armazenados em nossos sistemas. Manteremos os dados e informações somente até quando estas forem necessárias ou relevantes para as finalidades descritas nesta Política, ou em caso de períodos pré-determinados por lei, ou até quando estas forem necessárias para a manutenção de interesses legítimos da Dettalles Consultoria.</p>
                            <p className="mb-4" align="justify"> A Dettalles Consultoria considera a sua privacidade algo extremamente importante e fará tudo que estiver ao alcance para protegê-la. Todavia, não temos como garantir que todos os dados e informações sobre você em nossa plataforma estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das credenciais necessárias para acessar o nosso aplicativo. Portanto, você é o único responsável por manter sua senha de acesso em local seguro e é vedado o compartilhamento desta com terceiros. Você se compromete a notificar a Dettalles Consultoria imediatamente, através de meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.</p>
                            <p className="mb-4" align="justify"><strong>6.	ATUALIZAÇÕES DA POLÍTICA DE PRIVACIDADE</strong></p>
                            <p className="mb-4" align="justify"> A Dettalles Consultoria se reserva no direito de alterar essa Política quantas vezes forem necessárias, visando fornecer a você mais segurança, conveniência, e melhorar cada vez mais a sua experiência. É por isso que é muito importante acessar nossa Política periodicamente. Para facilitar, indicamos no início do documento a data da última atualização. Caso sejam feitas alterações relevantes que ensejem novas autorizações suas, publicaremos uma nova política de privacidade, sujeita novamente ao seu consentimento.</p>
                            <p className="mb-4" align="justify"><strong>7.	LEI APLICÁVEL</strong></p>
                            <p className="mb-4" align="justify">Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil.  Fica eleito o Foro da Comarca de Curitiba, Paraná, como o competente para dirimir quaisquer questões porventura oriundas do presente documento, com expressa renúncia a qualquer outro, por mais privilegiado que seja.</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={handleCancelPrivacy}
                        >
                            Não Concordo
                        </Button>
                        <Button variant="success btn-rounded" onClick={handleAcceptPrivacy}>Eu Concordo</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingPrivacy}
                message="Confirmando aceite..."
                onClose={setLoadingPrivacy}
            />
        </>
    );
});

export default Privacy;