import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Row
} from 'react-bootstrap';
import Select from "react-select";

import DCard from '../../components/card/DCard';
import DCardHeader from '../../components/card/DCardHeader';
import DCardBody from '../../components/card/DCardBody';
import PageTitle from '../../layouts/PageTitle';
import InputMask from "react-input-mask";

import ModalLoading from '../../components/ModalLoading/ModalLoading';
import { ToastContainer, toast } from "react-toastify";

import ReactQuill from 'react-quill';
import CardioversionNeedCard from './CardioversionNeedCard';
import BaseHeartDiseaseCard from './BaseHeartDiseaseCard';
import ArrhythmiaDiagnosisCard from './ArrhythmiaDiagnosisCard';
import ParticularityCard from './ParticularityCard';
import AssociatedDiseaseCard from './AssociatedDiseaseCard';
import AntiarrhythmicMedicationCard from './AntiarrhythmicMedicationCard';
import OtherMedicationCard from './OtherMedicationCard';
import NonMedicationTreatmentCard from './NonMedicationTreatmentCard';
import OtherDataCard from './OtherDataCard';
import PhysicalExerciseCard from './PhysicalExerciseCard';

import { symptoms, historyTimes, yesNoOptions, alcoholOptions, smokeOptions } from "../../../enum/enums";
import dayjs from 'dayjs';
import api from '../../../services/api';

const AnamnesisForm = ({ match }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [loadingAnamnesisForm, setLoadingAnamnesisForm] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Carregando Informações...");

  const [anamnesisId, setAnamnesisId] = useState("");
  const [pacientId, setPacientId] = useState("");
  const [name, setName] = useState("");
  const [doctorsList, setDoctorsList] = useState([]);
  const [doctor, setDoctor] = useState(undefined);
  const [createDate, setCreateDate] = useState(dayjs().format("DD/MM/YYYY"));

  const [symptomsSelected, setSymptomsSelected] = useState([]);
  const [symptomsList, setSymptomsList] = useState(symptoms);

  const [cardioversionNeeds, setCardioversionNeeds] = useState([]);

  const [historyTimeYear, setHistoryTimeYear] = useState("");
  const [historyTimeMonth, setHistoryTimeMonth] = useState("");
  const [historyTimeType, setHistoryTimeType] = useState(undefined);
  const [historyTimeTypeList, setHistoryTimeTypeList] = useState(historyTimes);

  const [lastCrisisYears, setLastCrisisYears] = useState("");
  const [lastCrisisMonths, setLastCrisisMonths] = useState("");
  const [lastCrisisWeeks, setLastCrisisWeeks] = useState("");
  const [lastCrisisDays, setLastCrisisDays] = useState("");

  const [baseHeartDiseases, setBaseHeartDiseases] = useState([]);
  const [arrhythmiaDiagnosis, setArrhythmiaDiagnosis] = useState([]);
  const [particularities, setParticularities] = useState([]);
  const [associatedDiseases, setAssociatedDiseases] = useState([]);
  const [antiarrhythmicMedications, setAntiarrhythmicMedications] = useState([]);
  const [otherMedications, setOtherMedications] = useState([]);
  const [nonMedicationTreatments, setNonMedicationTreatments] = useState([]);

  const [hma, setHma] = useState("");
  const [hmp, setHmp] = useState("");
  const [familyHistory, setFamilyHistory] = useState("");
  const [habits, setHabits] = useState("");

  const [otherData, setOtherData] = useState([]);
  const [physicalExercises, setPhysicalExercises] = useState([]);

  const [comments, setComments] = useState("");

  const history = useHistory();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'align': ['', 'right', 'center', 'justify'] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'align', 'list', 'bullet', 'indent',
    'link', 'image'
  ];

  useEffect(() => {
    setLoadingAnamnesisForm(true);

    getDoctors();

    if (match?.path.includes("new")) {
      getPacient(match.params.id);
    } else if (match?.path.includes("edit")) {
      setIsUpdate(true);
      getAnamnesis(match.params.id);
    }

    setLoadingAnamnesisForm(false);
  }, []);

  async function getDoctors() {
    await api.get("/doctors?status=true&lec=true")
      .then(response => {
        if (response.status === 200) {
          const doctorsReturned = response.data.doctors.map(doctor => {
            return {
              value: doctor._id,
              label: doctor.name
            }
          });

          setDoctorsList(doctorsReturned);
        }
      })
  }

  async function getPacient(id) {
    await api.get(`/pacients/${id}`)
      .then(response => {
        if (response.status === 200) {
          setPacientId(response.data._id);
          setName(response.data.name);
        }
      })
  }

  async function getAnamnesis(id) {
    await api.get(`/anamnesis/${id}`)
      .then(response => {
        if (response.status === 200) {
          const anamnesisInfo = response.data;

          setAnamnesisId(anamnesisInfo._id);
          setPacientId(anamnesisInfo.pacient_id);
          setName(anamnesisInfo.pacient_name);

          if (anamnesisInfo.doctor_id) {
            setDoctor({
              value: anamnesisInfo.doctor_id,
              label: anamnesisInfo.doctor_name
            });
          } else {
            setDoctor(null);
          }

          setCreateDate(dayjs(anamnesisInfo.createdAt).format("DD/MM/YYYY"));
          if (anamnesisInfo.symptoms) {
            const symptonsReturned = anamnesisInfo.symptoms.map(symptomItem => {
              return {
                value: symptomItem.symptom_id,
                label: symptomItem.symptom_description
              }
            });
            setSymptomsSelected(symptonsReturned);
          }

          if (anamnesisInfo.cardio_needs) {
            const cardioNeedsReturned = anamnesisInfo.cardio_needs.map(cardioItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type: {
                  value: cardioItem.type_id,
                  label: cardioItem.type_description,
                },
                month: cardioItem.month,
                year: cardioItem.year
              }
            });

            setCardioversionNeeds(cardioNeedsReturned);
          }

          const historyTime = anamnesisInfo.history_time;
          setHistoryTimeYear(historyTime.year);
          setHistoryTimeMonth(historyTime.month);
          setHistoryTimeType({ value: historyTime.type_id, label: historyTime.type_description });

          const lastCrisis = anamnesisInfo.last_crisis;
          setLastCrisisYears(lastCrisis.years);
          setLastCrisisMonths(lastCrisis.months);
          setLastCrisisWeeks(lastCrisis.weeks);
          setLastCrisisDays(lastCrisis.days);

          if (anamnesisInfo.heart_diseases) {
            const heartDiseasesReturned = anamnesisInfo.heart_diseases.map(diseasesItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type: {
                  value: diseasesItem.type_id,
                  label: diseasesItem.type_description,
                },
                month: diseasesItem.month,
                year: diseasesItem.year
              }
            });
            setBaseHeartDiseases(heartDiseasesReturned);
          }

          if (anamnesisInfo.arrhythmia) {
            const arrhythmiasReturned = anamnesisInfo.arrhythmia.map(arrhythmiaItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type: {
                  value: arrhythmiaItem.type_id,
                  label: arrhythmiaItem.type_description,
                },
                month: arrhythmiaItem.month,
                year: arrhythmiaItem.year
              }
            });
            setArrhythmiaDiagnosis(arrhythmiasReturned);
          }

          if (anamnesisInfo.particularities) {
            const particularitiesReturned = anamnesisInfo.particularities.map(particularityItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                particularity: particularityItem.particularity
              }
            });
            setParticularities(particularitiesReturned);
          }

          if (anamnesisInfo.associated_diseases) {
            const associatedReturned = anamnesisInfo.associated_diseases.map(associatedItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type: {
                  value: associatedItem.type_id,
                  label: associatedItem.type_description,
                },
                month: associatedItem.month,
                year: associatedItem.year
              }
            });
            setAssociatedDiseases(associatedReturned);
          }

          if (anamnesisInfo.antiarrhythmic) {
            const antiarrhythmicReturned = anamnesisInfo.antiarrhythmic.map(antiarrhythmicItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type: {
                  value: antiarrhythmicItem.type_id,
                  label: antiarrhythmicItem.type_description,
                },
                dose: antiarrhythmicItem.dose,
                time: antiarrhythmicItem.time,
                description: antiarrhythmicItem.description
              }
            });
            setAntiarrhythmicMedications(antiarrhythmicReturned);
          }

          if (anamnesisInfo.other_medications) {
            const otherMedsReturned = anamnesisInfo.other_medications.map(otherMedItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type: {
                  value: otherMedItem.type_id,
                  label: otherMedItem.type_description,
                },
                dose: otherMedItem.dose,
                time: otherMedItem.time
              }
            });
            setOtherMedications(otherMedsReturned);
          }

          if (anamnesisInfo.non_medications) {
            const nonMedsReturned = anamnesisInfo.non_medications.map(nonMedItem => {
              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type: {
                  value: nonMedItem.type_id,
                  label: nonMedItem.type_description,
                },
                days: nonMedItem.days,
                months: nonMedItem.months,
                years: nonMedItem.years
              }
            });
            setNonMedicationTreatments(nonMedsReturned);
          }

          setHma(anamnesisInfo.hma);
          setHmp(anamnesisInfo.hmp);
          setFamilyHistory(anamnesisInfo.family_history);
          setHabits(anamnesisInfo.habits);

          if (anamnesisInfo.other_data) {
            const otherDataReturned = anamnesisInfo.other_data.map(otherItem => {
              const alcoholSelected = alcoholOptions.find(x => x.value === otherItem.alcohol_option);
              const smokeSelected = yesNoOptions.find(x => x.value === otherItem.smoke_option);
              const cigarettesSelected = smokeOptions.find(x => x.value === otherItem.cigarettes);

              return {
                date: dayjs(otherItem.date).toDate(),
                amp: otherItem.amp,
                af: otherItem.af,
                rs: otherItem.rs,
                physicalActivity: {
                  value: otherItem.physical_activity_id,
                  label: otherItem.physical_activity_description
                },
                physicalActivityText: otherItem.physical_activity_text,
                alcohol: alcoholSelected ?? null,
                smoke: smokeSelected ?? null,
                cigarettes: cigarettesSelected ?? null,
                clinicalManifestations: otherItem.clinical_manifestations.map(clinicalItem => {
                  return {
                    id: (Math.floor(Math.random() * 65536)).toString(),
                    type: { value: clinicalItem.type_id, label: clinicalItem.type_description },
                    month: clinicalItem.month,
                    year: clinicalItem.year
                  }
                }),
                previousTreatments: otherItem.previous_treatments.map(treatmentItem => {
                  return {
                    id: (Math.floor(Math.random() * 65536)).toString(),
                    type: { value: treatmentItem.type_id, label: treatmentItem.type_description },
                    month: treatmentItem.month,
                    year: treatmentItem.year
                  }
                })
              }
            })
            setOtherData(otherDataReturned);
          }

          if (anamnesisInfo.physical_exercises) {
            const physicalExercisesReturned = anamnesisInfo.physical_exercises.map(exerciseItem => {
              const particularitySelected = yesNoOptions.find(x => x.value === exerciseItem.particularity_option);

              return {
                id: (Math.floor(Math.random() * 65536)).toString(),
                date: dayjs(exerciseItem.date).toDate(),
                particularity: particularitySelected,
                head: exerciseItem.head ?? "",
                neck: exerciseItem.neck ?? "",
                lungs: exerciseItem.lungs ?? "",
                abdomen: exerciseItem.abdomen ?? "",
                limbs: exerciseItem.limbs ?? "",
                ictus: exerciseItem.ictus ?? "",
                systolicPa: exerciseItem.systolic_pa ?? "",
                diastolicPa: exerciseItem.diastolic_pa ?? "",
                heartRate: exerciseItem.heart_rate ?? "",
                pulse: exerciseItem.pulse ?? "",
                temperature: exerciseItem.temperature ?? "",
                posteriorTibia: exerciseItem.posterior_tibia ?? false,
                foot: exerciseItem.foot ?? false,
                bulge: { value: exerciseItem.bulge_option, label: exerciseItem.bulge_description },
                blow: exerciseItem.blow,
                observation: exerciseItem.observation
              }
            })
            setPhysicalExercises(physicalExercisesReturned);
          }

          setComments(anamnesisInfo.comments);
        }
      })
  }

  function handleSaveAnamnesis(e) {
    e.preventDefault();

    setLoadingMessage("Gravando Informações...");
    setLoadingAnamnesisForm(true);

    let anamnesisData = {
      pacient_id: pacientId,
      pacient_name: name,
      doctor_id: doctor?.value ?? "",
      doctor_name: doctor?.label ?? "",
      symptoms: symptomsSelected.map(symptom => {
        return { symptom_id: symptom.value, symptom_description: symptom.label }
      }),
      cardio_needs: cardioversionNeeds.map(cardioNeed => {
        return {
          type_id: cardioNeed.type.value,
          type_description: cardioNeed.type.label,
          month: cardioNeed.month,
          year: cardioNeed.year
        }
      }),
      history_time: {
        type_id: historyTimeType?.value ?? "",
        type_description: historyTimeType?.label ?? "",
        month: historyTimeMonth,
        year: historyTimeYear
      },
      last_crisis: {
        years: lastCrisisYears,
        months: lastCrisisMonths,
        weeks: lastCrisisWeeks,
        days: lastCrisisDays
      },
      heart_diseases: baseHeartDiseases.map(baseDisease => {
        return {
          type_id: baseDisease.type.value,
          type_description: baseDisease.type.label,
          month: baseDisease.month,
          year: baseDisease.year
        }
      }),
      arrhythmia: arrhythmiaDiagnosis.map(arrhythmiaItem => {
        return {
          type_id: arrhythmiaItem.type.value,
          type_description: arrhythmiaItem.type.label,
          month: arrhythmiaItem.month,
          year: arrhythmiaItem.year
        }
      }),
      particularities: particularities.map(particularityItem => {
        return { particularity: particularityItem.particularity }
      }),
      associated_diseases: associatedDiseases.map(associatedDisease => {
        return {
          type_id: associatedDisease.type.value,
          type_description: associatedDisease.type.label,
          month: associatedDisease.month,
          year: associatedDisease.year
        }
      }),
      antiarrhythmic: antiarrhythmicMedications.map(antiarrhythmicItem => {
        return {
          type_id: antiarrhythmicItem.type.value,
          type_description: antiarrhythmicItem.type.label,
          dose: antiarrhythmicItem.dose,
          time: antiarrhythmicItem.time,
          description: antiarrhythmicItem.description
        }
      }),
      other_medications: otherMedications.map(otherMedItem => {
        return {
          type_id: otherMedItem.type.value,
          type_description: otherMedItem.type.label,
          dose: otherMedItem.dose,
          time: otherMedItem.time
        }
      }),
      non_medications: nonMedicationTreatments.map(nonMedItem => {
        return {
          type_id: nonMedItem.type.value,
          type_description: nonMedItem.type.label,
          days: nonMedItem.days,
          months: nonMedItem.months,
          years: nonMedItem.years
        }
      }),
      hma,
      hmp,
      family_history: familyHistory,
      habits,
      other_data: [],
      physical_exercises: physicalExercises.map(exerciseItem => {
        return {
          date: exerciseItem.date,
          particularity_option: exerciseItem.particularity?.value ?? "",
          head: exerciseItem.head,
          neck: exerciseItem.neck,
          lungs: exerciseItem.lungs,
          abdomen: exerciseItem.abdomen,
          limbs: exerciseItem.limbs,
          ictus: exerciseItem.ictus,
          systolic_pa: exerciseItem.systolicPa,
          diastolic_pa: exerciseItem.diastolicPa,
          heart_rate: exerciseItem.heartRate,
          pulse: exerciseItem.pulse,
          temperature: exerciseItem.temperature,
          posterior_tibia: exerciseItem.posteriorTibia,
          foot: exerciseItem.foot,
          bulge_option: exerciseItem.bulge?.value ?? "",
          bulge_description: exerciseItem.bulge?.label ?? "",
          blow: exerciseItem.blow,
          observation: exerciseItem.observation
        }
      }),
      comments
    };

    if (otherData) {
      let otherDataObj = [];

      for (let indexOtherData = 0; indexOtherData < otherData.length; indexOtherData++) {
        otherDataObj.push({
          amp: otherData[indexOtherData].amp,
          af: otherData[indexOtherData].af,
          rs: otherData[indexOtherData].rs,
          physical_activity_id: otherData[indexOtherData].physicalActivity.value,
          physical_activity_description: otherData[indexOtherData].physicalActivity.label,
          physical_activity_text: otherData[indexOtherData].physicalActivityText,
          alcohol_option: otherData[indexOtherData].alcohol.value,
          smoke_option: otherData[indexOtherData].smoke.value,
          cigarettes: otherData[indexOtherData].cigarettes.value,
          clinical_manifestations: otherData[indexOtherData].clinicalManifestations.map(manifestationItem => {
            return {
              type_id: manifestationItem.type.value,
              type_description: manifestationItem.type.label,
              month: manifestationItem.month,
              year: manifestationItem.year
            }
          }),
          previous_treatments: otherData[indexOtherData].previousTreatments.map(previousItem => {
            return {
              type_id: previousItem.type.value,
              type_description: previousItem.type.label,
              month: previousItem.month,
              year: previousItem.year
            }
          })
        });
      }

      anamnesisData.other_data = [...otherDataObj];
    }

    if (!isUpdate) {
      try {
        api.post("/anamnesis", anamnesisData)
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              notifySuccess();
              history.push(`/medical-record/${pacientId}`);
            }
          })
      } catch (err) {
        notifyError();
      }
    } else {
      try {
        api.put(`/anamnesis/${anamnesisId}`, anamnesisData)
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              notifySuccess();
              history.push(`/medical-record/${pacientId}`);
            }
          })
      } catch (err) {
        notifyError();
      }
    }

    setLoadingAnamnesisForm(false);
  }

  function notifySuccess() {
    toast.success(`✔️ Anamnese ${(isUpdate ? "alterada" : "incluída")} com sucesso!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function notifyError() {
    toast.error("❌ Ocorreu um problema ao salvar o convênio", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function handleCancel(e) {
    e.preventDefault();

    history.push(`/medical-record/${pacientId}`);
  }

  return (
    <Fragment>
      <PageTitle activeMenu="Health-Plan" motherMenu="Records" />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Resumo da História Clínica</Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="basic-form">
                <div className="row">
                  <div className="form-group mb-3 col-md-4">
                    <label>Paciente</label>
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      onChange={e => setName(e.target.value)}
                      disabled
                    />
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label>Médico</label>
                    <Select
                      autoFocus
                      defaultValue={doctor}
                      value={doctor}
                      onChange={(e) => setDoctor(e)}
                      options={doctorsList}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          height: 45,
                          backgroundColor: state.isDisabled ? "#dde0e3" : ""
                        }),
                      }}
                      placeholder="Selecione..."
                    />
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label>Data</label>
                    <input
                      type="text"
                      className="form-control"
                      value={createDate}
                      disabled
                    />
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">Queixas (Sintomas)</DCardHeader>
                      <DCardBody>
                        <div className="basic-form">
                          <div className="row">
                            <div className="form-group mb-3 col-md-12">
                              <label>Principal</label>
                              <Select
                                value={symptomsSelected}
                                closeMenuOnSelect={false}
                                defaultValue={symptomsSelected}
                                isMulti
                                options={symptomsList}
                                placeholder="Selecione..."
                                onChange={setSymptomsSelected}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <CardioversionNeedCard
                      cardioversionNeedListParm={cardioversionNeeds}
                      onRequestAdd={setCardioversionNeeds}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">Tempo de História</DCardHeader>
                      <DCardBody>
                        <div className="basic-form">
                          <div className="row">
                            <div className="form-group mb-3 col-md-3">
                              <label>Ano</label>
                              <InputMask
                                mask="9999"
                                maskChar=" "
                                value={historyTimeYear}
                                onChange={(event) => {
                                  if (event.target.value.length !== 4) return false;
                                  setHistoryTimeYear(event?.target.value);
                                }}
                                alwaysShowMask={true}
                              >
                                {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                              </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                              <label>Mês</label>
                              <InputMask
                                mask="99"
                                maskChar=" "
                                value={historyTimeMonth}
                                onChange={(event) => {
                                  if (event.target.value.length !== 2) return false;
                                  setHistoryTimeMonth(event?.target.value);
                                }}
                                alwaysShowMask={true}
                              >
                                {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                              </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-6">
                              <label>Tempo</label>
                              <Select
                                isClearable
                                defaultValue={historyTimeType}
                                value={historyTimeType}
                                onChange={(e) => setHistoryTimeType(e)}
                                options={historyTimeTypeList}
                                styles={{
                                  control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                  }),
                                }}
                                placeholder="Selecione..."
                              />
                            </div>
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">Tempo Transcorrido da Última Crise</DCardHeader>
                      <DCardBody>
                        <div className="basic-form">
                          <div className="row">
                            <div className="form-group mb-3 col-md-3">
                              <label>Anos</label>
                              <InputMask
                                mask="9999"
                                maskChar=" "
                                value={lastCrisisYears}
                                onChange={(event) => {
                                  setLastCrisisYears(event?.target.value);
                                }}
                                alwaysShowMask={true}
                              >
                                {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                              </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                              <label>Meses</label>
                              <InputMask
                                mask="9999"
                                maskChar=" "
                                value={lastCrisisMonths}
                                onChange={(event) => {
                                  setLastCrisisMonths(event?.target.value);
                                }}
                                alwaysShowMask={true}
                              >
                                {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                              </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                              <label>Semanas</label>
                              <InputMask
                                mask="9999"
                                maskChar=" "
                                value={lastCrisisWeeks}
                                onChange={(event) => {
                                  setLastCrisisWeeks(event?.target.value);
                                }}
                                alwaysShowMask={true}
                              >
                                {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                              </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-3">
                              <label>Dias</label>
                              <InputMask
                                mask="9999"
                                maskChar=" "
                                value={lastCrisisDays}
                                onChange={(event) => {
                                  setLastCrisisDays(event?.target.value);
                                }}
                                alwaysShowMask={true}
                              >
                                {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                              </InputMask>
                            </div>
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <BaseHeartDiseaseCard
                      baseHeartDiseaseListParm={baseHeartDiseases}
                      onRequestAdd={setBaseHeartDiseases}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <ArrhythmiaDiagnosisCard
                      arrhythmiaDiagnosisListParm={arrhythmiaDiagnosis}
                      onRequestAdd={setArrhythmiaDiagnosis}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6">
                    <ParticularityCard
                      particularitiesListParm={particularities}
                      onRequestAdd={setParticularities}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <AssociatedDiseaseCard
                      associatedDiseasesListParm={associatedDiseases}
                      onRequestAdd={setAssociatedDiseases}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">Medicações</DCardHeader>
                      <DCardBody>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <AntiarrhythmicMedicationCard
                              antiarrhythmicMedicationsListParm={antiarrhythmicMedications}
                              onRequestAdd={setAntiarrhythmicMedications}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <OtherMedicationCard
                              otherMedicationsListParm={otherMedications}
                              onRequestAdd={setOtherMedications}
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <NonMedicationTreatmentCard
                              nonMedicationTreatmentsListParm={nonMedicationTreatments}
                              onRequestAdd={setNonMedicationTreatments}
                            />
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">HMA - Texto será exibido no laudo</DCardHeader>
                      <DCardBody>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <ReactQuill
                              theme="snow"
                              value={hma}
                              onChange={setHma}
                              modules={modules}
                              formats={formats}
                            />
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-lg-4">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">HMP</DCardHeader>
                      <DCardBody>
                        <div className="basic-form">
                          <div className="row">
                            <div className="form-group mb-3 col-md-12">
                              <textarea
                                type="text"
                                className="form-control"
                                value={hmp}
                                onChange={e => setHmp(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">História Familiar</DCardHeader>
                      <DCardBody>
                        <div className="basic-form">
                          <div className="row">
                            <div className="form-group mb-3 col-md-12">
                              <textarea
                                type="text"
                                className="form-control"
                                value={familyHistory}
                                onChange={e => setFamilyHistory(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                  <div className="col-xl-4 col-lg-4">
                    <DCard borderColor="light">
                      <DCardHeader color="light" textColor="dark">Condições de Hábito e Vida</DCardHeader>
                      <DCardBody>
                        <div className="basic-form">
                          <div className="row">
                            <div className="form-group mb-3 col-md-12">
                              <textarea
                                type="text"
                                className="form-control"
                                value={habits}
                                onChange={e => setHabits(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </DCardBody>
                    </DCard>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Outros dados Anamnese - <small>Adicione outras Informações da anamnese</small></Card.Title>
            </Card.Header>
            <Card.Body>
              <OtherDataCard
                otherDatasListParm={otherData}
                onRequestAdd={setOtherData}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Exercícios Físicos - <small>Adicione informações de exames físicos do paciente</small></Card.Title>
            </Card.Header>
            <Card.Body>
              <PhysicalExerciseCard
                physicalExercisesListParm={physicalExercises}
                onRequestAdd={setPhysicalExercises}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Header>
              <Card.Title>Finalizar Resumo da Histórica Clínica - <small>Adicione os comentários finais da anamnese</small></Card.Title>
            </Card.Header>
            <Card.Body>
              <div className="basic-form">
                <div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label>Comentários</label>
                    <ReactQuill
                      theme="snow"
                      value={comments}
                      onChange={setComments}
                      modules={modules}
                      formats={formats}
                    />
                  </div>
                </div>
              </div>
            </Card.Body>
            <Card.Footer>
              <div className="basic-form">
                <div className="row">
                  <div className="form-group mb-3 col-md-11 text-end">
                    <Button variant="outline-danger btn-rounded" onClick={handleCancel}>Fechar</Button>
                  </div>
                  <div className="form-group mb-3 col-md-1">
                    <Button variant="success btn-rounded" onClick={handleSaveAnamnesis}>Salvar</Button>
                  </div>
                </div>
              </div>
            </Card.Footer>
          </Card>
        </Col>
      </Row>

      <ToastContainer />

      <ModalLoading
        visible={loadingAnamnesisForm}
        message={loadingMessage}
        onClose={setLoadingAnamnesisForm}
      />
    </Fragment>
  )
}

export default AnamnesisForm;