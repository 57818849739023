const atypicalTrnOptions = [
    {
        value: "BOCA",
        label: "Boca do seio coronário"
    },
    {
        value: "TETO",
        label: "Teto do seio coronário"
    },
    {
        value: "LILA",
        label: "LI-LA"
    },
    {
        value: "ANTEROSEPTAL",
        label: "Antero-septal direito"
    }
];

const basalEcgOptions = [
    {
        value: "NORMAL",
        label: "Normal"
    },
    {
        value: "BRE",
        label: "BRE"
    },
    {
        value: "BREBDAS",
        label: "BRE + BDAS"
    },
    {
        value: "BREBDPI",
        label: "BRE + BDPI"
    },
    {
        value: "BRD",
        label: "BRD"
    },
    {
        value: "BRDBDAS",
        label: "BRD + BDAS"
    },
    {
        value: "BRD + BDPI",
        label: "BRD + BDPI"
    },
    {
        value: "BDAM",
        label: "BDAM"
    },
    {
        value: "INARINF",
        label: "Área inativa inferior"
    },
    {
        value: "INARINFAPI",
        label: "Área inativa infero-apical"
    },
    {
        value: "INARANT",
        label: "Área inativa anterior"
    },
    {
        value: "INARANTLAT",
        label: "Área inativa ântero-lateral"
    },
    {
        value: "INARANTSEP",
        label: "Área inativa ântero-septal"
    },
    {
        value: "INARANTAPI",
        label: "Área inativa ântero-apical"
    },
    {
        value: "INARANTEXT",
        label: "Área inativa anterior extensa"
    },
    {
        value: "BAV1",
        label: "BAV 1o grau"
    },
    {
        value: "BAV2M1",
        label: "BAV 2o grau M1"
    },
    {
        value: "BAV2M2",
        label: "BAV 2o grau M2"
    },
    {
        value: "BAV2ADV",
        label: "BAV 2o grau avançado"
    },
    {
        value: "BAV3",
        label: "BAV 3o grau"
    },
    {
        value: "JUNCESCAPE",
        label: "Escape juncional"
    },
    {
        value: "BRANCHESCAPE",
        label: "Escape de ramo"
    },
    {
        value: "VENTRESCAPE",
        label: "Escape ventricular"
    },
    {
        value: "LTVENTROVER",
        label: "Sobrecarga ventricular esquerda"
    },
    {
        value: "RTVENTROVER",
        label: "Sobrecarga ventricular direita"
    },
    {
        value: "BIVENTROVER",
        label: "Sobrecarga biventricular"
    },
    {
        value: "LTATRIALOVER",
        label: "Sobrecarga atrial esquerda"
    },
    {
        value: "RTATRIALOVER",
        label: "Sobrecarga atrial direita"
    },
    {
        value: "BIATRIALOVER",
        label: "Sobrecarga biatrial"
    },
    {
        value: "BACHMANBLOCK",
        label: "Bloqueio intra-atrial de Bachman"
    },
    {
        value: "OUT",
        label: "Outro"
    }
];

const commonTrnOptions = [
    {
        value: "A1",
        label: "A1"
    },
    {
        value: "A2",
        label: "A2"
    },
    {
        value: "M1",
        label: "M1"
    },
    {
        value: "M2",
        label: "M2"
    },
    {
        value: "P1",
        label: "P1"
    },
    {
        value: "P2",
        label: "P2"
    }
];

export {
    atypicalTrnOptions,
    basalEcgOptions,
    commonTrnOptions
};