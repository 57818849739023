const logRoutines = [
    {
        value: "user",
        label: "Usuário"
    },
    {
        value: "supplier",
        label: "Fornecedor"
    },
    {
        value: "protocol",
        label: "Protocolo"
    },
    {
        value: "procedureExecution",
        label: "Execução de Procedimento"
    },
    {
        value: "complication",
        label: "Complicação"
    },
    {
        value: "preschedule",
        label: "Pré-Agendamento"
    },
    {
        value: "pacient",
        label: "Paciente"
    },
    {
        value: "receipt",
        label: "Recebimento"
    },
    {
        value: "invoice",
        label: "Faturamento"
    },
    {
        value: "login",
        label: "Login"
    },
    {
        value: "hospital",
        label: "Hospital"
    },
    {
        value: "healthplan",
        label: "Convênio"
    },
    {
        value: "followup",
        label: "Seguimento"
    },
    {
        value: "doctor",
        label: "Médico"
    }
];

export {
    logRoutines
};