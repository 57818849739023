import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Table } from "react-bootstrap";

import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import DFormText from "../../components/DFormText/DFormText";

import { avRelationTypes, mappingCriterionTypes, successCriterionTypes } from "../../../enum/ablationEnums";

const AblationCard = props => {
    const {
        children,
        className,
        //
        onChange,
        onGetHeaderInfo,
        onEditAblation,
        ablationListParm,
        hide,
        ...attributes
    } = props;

    const [ablationList, setAblationList] = useState(ablationListParm);

    const [atrioventRelation, setAtrioventRelation] = useState(null);
    const [hiddenRetraining, setHiddenRetraining] = useState(false);
    const [hiddenRetrainingPlace, setHiddenRetrainingPlace] = useState("");
    const [mapActivation, setMapActivation] = useState(false);
    const [mapPacemapping, setMapPacemapping] = useState(false);
    const [mapElectro, setMapElectro] = useState(false);
    const [mapSubType, setMapSubType] = useState(null);
    const [mapRightAtrium, setMapRightAtrium] = useState(false);
    const [mapLeftAtrium, setMapLeftAtrium] = useState(false);
    const [mapRightVentricle, setMapRightVentricle] = useState(false);
    const [mapLeftVentricle, setMapLeftVentricle] = useState(false);
    const [mapEpicardialSpace, setMapEpicardialSpace] = useState(false);
    const [mapAtrioventRing, setMapAtrioventRing] = useState(false);
    const [mapAtrioventNode, setMapAtrioventNode] = useState(false);
    const [sinusRhythm, setSinusRhythm] = useState(false);
    const [inducedTachycardia, setInducedTachycardia] = useState(false);
    const [ventricularPacing, setVentricularPacing] = useState(false);
    const [atriumPacing, setAtriumPacing] = useState(false);
    const [junctionalRhythm, setJunctionalRhythm] = useState(false);
    const [exhaust, setExhaust] = useState("");
    const [successCriterion, setSuccessCriterion] = useState(null);
    const [venography, setVenography] = useState(false);
    const [totalRxTime, setTotalRxTime] = useState("");
    const [totalProcedureTime, setTotalProcedureTime] = useState("");
    const [totalLesionTime, setTotalLesionTime] = useState("");
    const [accessoryPathBlock, setAccessoryPathBlock] = useState(false);
    const [interruption, setInterruption] = useState(false);
    const [tachycardiaReinduction, setTachycardiaReinduction] = useState(false);
    const [trnJumpPersistence, setTrnJumpPersistence] = useState(false);
    const [trnEcoPersistence, setTrnEcoPersistence] = useState(false);
    const [flutterBlock, setFlutterBlock] = useState(false);
    const [isuprel, setIsuprel] = useState(false);
    const [adenosine, setAdenosine] = useState(false);

    const [invalidTotalRxTime, setInvalidTotalRxTime] = useState(false);
    const [invalidTotalProcedureTime, setInvalidTotalProcedureTime] = useState(false);
    const [invalidTotalLesionTime, setInvalidTotalLesionTime] = useState(false);

    const [isEditing, setIsEditing] = useState(false);
    const [idRowEditing, setIdRowEditing] = useState(undefined);

    useEffect(() => {
        setAblationList(ablationListParm);
    }, [ablationListParm]);

    function handleAddAblation() {
        const ablationHeaderInfo = onGetHeaderInfo();

        if (!isEditing) {
            const nextSequence = ablationList.length + 1;

            const newAblationItem = {
                id: (Math.floor(Math.random() * 65536)).toString(),
                sequence: nextSequence,
                ablationType: ablationHeaderInfo.ablationType.value,
                ablationDescription: ablationHeaderInfo.ablationType.label,
                punctionInfo: ablationHeaderInfo.punctionInfo,
                materialInfo: ablationHeaderInfo.materialInfo,
                eefInfo: ablationHeaderInfo.eefInfo,
                atrioventRelation,
                hiddenRetraining,
                hiddenRetrainingPlace,
                mapActivation,
                mapPacemapping,
                mapElectro,
                mapSubType,
                mapRightAtrium,
                mapLeftAtrium,
                mapRightVentricle,
                mapLeftVentricle,
                mapEpicardialSpace,
                mapAtrioventRing,
                mapAtrioventNode,
                sinusRhythm,
                inducedTachycardia,
                ventricularPacing,
                atriumPacing,
                junctionalRhythm,
                exhaust,
                successCriterion,
                venography,
                totalRxTime,
                totalProcedureTime,
                totalLesionTime,
                accessoryPathBlock,
                interruption,
                tachycardiaReinduction,
                trnJumpPersistence,
                trnEcoPersistence,
                flutterBlock,
                isuprel,
                adenosine,
                conclusion: ablationHeaderInfo.conclusion?.value,
                conclusionDescription: ablationHeaderInfo.conclusion?.label,
                summaryConclusion: ablationHeaderInfo.summaryConclusion
            };

            const newAblationList = [...ablationList, newAblationItem];
            setAblationList(newAblationList);
            onChange(newAblationList);
        } else {
            const newAblationList = ablationList.map(ablationItem => {
                if (ablationItem.id === idRowEditing) {
                    return {
                        id: ablationItem.id,
                        sequence: ablationItem.sequence,
                        ablationType: ablationHeaderInfo.ablationType.value,
                        ablationDescription: ablationHeaderInfo.ablationType.label,
                        punctionInfo: ablationHeaderInfo.punctionInfo,
                        materialInfo: ablationHeaderInfo.materialInfo,
                        eefInfo: ablationHeaderInfo.eefInfo,
                        atrioventRelation,
                        hiddenRetraining,
                        hiddenRetrainingPlace,
                        mapActivation,
                        mapPacemapping,
                        mapElectro,
                        mapSubType,
                        mapRightAtrium,
                        mapLeftAtrium,
                        mapRightVentricle,
                        mapLeftVentricle,
                        mapEpicardialSpace,
                        mapAtrioventRing,
                        mapAtrioventNode,
                        sinusRhythm,
                        inducedTachycardia,
                        ventricularPacing,
                        atriumPacing,
                        junctionalRhythm,
                        exhaust,
                        successCriterion,
                        venography,
                        totalRxTime,
                        totalProcedureTime,
                        totalLesionTime,
                        accessoryPathBlock,
                        interruption,
                        tachycardiaReinduction,
                        trnJumpPersistence,
                        trnEcoPersistence,
                        flutterBlock,
                        isuprel,
                        adenosine,
                        conclusion: ablationHeaderInfo.conclusion.value,
                        conclusionDescription: ablationHeaderInfo.conclusion.label,
                        summaryConclusion: ablationHeaderInfo.summaryConclusion
                    }
                }

                return ablationItem;
            })
            setAblationList(newAblationList);
            onChange(newAblationList);
        }

        resetFields();
    }

    function handleEditAblation(id) {
        const editRowInfo = ablationList.find(x => x.id === id);
        if (editRowInfo) {
            setAtrioventRelation(editRowInfo.atrioventRelation);
            setHiddenRetraining(editRowInfo.hiddenRetraining);
            setHiddenRetrainingPlace(editRowInfo.hiddenRetrainingPlace);
            setMapActivation(editRowInfo.mapActivation);
            setMapPacemapping(editRowInfo.mapPacemapping);
            setMapElectro(editRowInfo.mapElectro);
            setMapSubType(editRowInfo.mapSubType);
            setMapRightAtrium(editRowInfo.mapRightAtrium);
            setMapLeftAtrium(editRowInfo.mapLeftAtrium);
            setMapRightVentricle(editRowInfo.mapRightVentricle);
            setMapLeftVentricle(editRowInfo.mapLeftVentricle);
            setMapEpicardialSpace(editRowInfo.mapEpicardialSpace);
            setMapAtrioventRing(editRowInfo.mapAtrioventRing);
            setMapAtrioventNode(editRowInfo.mapAtrioventNode);
            setSinusRhythm(editRowInfo.sinusRhythm);
            setInducedTachycardia(editRowInfo.inducedTachycardia);
            setVentricularPacing(editRowInfo.ventricularPacing);
            setAtriumPacing(editRowInfo.atriumPacing);
            setJunctionalRhythm(editRowInfo.junctionalRhythm);
            setExhaust(editRowInfo.exhaust);
            setSuccessCriterion(editRowInfo.successCriterion);
            setVenography(editRowInfo.venography);
            setTotalRxTime(editRowInfo.totalRxTime);
            setTotalProcedureTime(editRowInfo.totalProcedureTime);
            setTotalLesionTime(editRowInfo.totalLesionTime);
            setAccessoryPathBlock(editRowInfo.accessoryPathBlock);
            setInterruption(editRowInfo.interruption);
            setTachycardiaReinduction(editRowInfo.tachycardiaReinduction);
            setTrnJumpPersistence(editRowInfo.trnJumpPersistence);
            setTrnEcoPersistence(editRowInfo.trnEcoPersistence);
            setFlutterBlock(editRowInfo.flutterBlock);
            setIsuprel(editRowInfo.isuprel);
            setAdenosine(editRowInfo.adenosine);

            onEditAblation(editRowInfo);

            setIdRowEditing(id);
            setIsEditing(true);
        }
    }

    function handleDeleteAblation(id) {
        const ablationsDeleted = ablationList.filter(x => x.id !== id);
        const newAblationList = ablationsDeleted.map((ablationItem, indexAblation) => {
            return { ...ablationItem, sequence: (indexAblation + 1) }
        });

        setAblationList(newAblationList);
        onChange(newAblationList);
    }

    function resetFields() {
        setAtrioventRelation(null);
        setHiddenRetraining(false);
        setHiddenRetrainingPlace("");
        setMapActivation(false);
        setMapPacemapping(false);
        setMapElectro(false);
        setMapSubType(null);
        setMapRightAtrium(false);
        setMapLeftAtrium(false);
        setMapRightVentricle(false);
        setMapLeftVentricle(false);
        setMapEpicardialSpace(false);
        setMapAtrioventRing(false);
        setMapAtrioventNode(false);
        setSinusRhythm(false);
        setInducedTachycardia(false);
        setVentricularPacing(false);
        setAtriumPacing(false);
        setJunctionalRhythm(false);
        setExhaust("");
        setSuccessCriterion(null);
        setVenography(false);
        setTotalRxTime("");
        setTotalProcedureTime("");
        setTotalLesionTime("");
        setAccessoryPathBlock(false);
        setInterruption(false);
        setTachycardiaReinduction(false);
        setTrnJumpPersistence(false);
        setTrnEcoPersistence(false);
        setFlutterBlock(false);
        setIsuprel(false);
        setAdenosine(false);

        setIsEditing(false);
        setIdRowEditing(undefined);
    }

    return (
        <div hidden={hide}>
            <div className="form-group mb-3 col-md-12">
                <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Detalhes da ablação</DCardHeader>
                    <DCardBody>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-2 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="materialOption21">
                                                Relação atrioventricular durante ablação
                                            </label>
                                        </div>

                                        <div className="form-group mb-2 col-md-2">
                                            <Select
                                                defaultValue={atrioventRelation}
                                                value={atrioventRelation}
                                                onChange={e => setAtrioventRelation(e)}
                                                options={avRelationTypes}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 43,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="hiddenRetraining"
                                        checked={hiddenRetraining}
                                        onChange={() => setHiddenRetraining(!hiddenRetraining)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="hiddenRetraining">
                                        Encarrilhamento oculto presente
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-3 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="hiddenRetrainingPlace">
                                                Local do encarrilhamento oculto
                                            </label>
                                        </div>

                                        <div className="form-group mb-3 col-md-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ height: "2rem", width: "4.5rem" }}
                                                value={hiddenRetrainingPlace}
                                                onChange={e => setHiddenRetrainingPlace(e.target.value)}
                                                maxLength={100}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-2">
                                    <div
                                        className="mb-3 mt-1 col-md-12"
                                        style={{ width: "auto" }}
                                    >
                                        <label>Mapeamento realizado usando critérios de:</label>
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-3"
                                            id="mapActivation"
                                            checked={mapActivation}
                                            onChange={() => setMapActivation(!mapActivation)}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="mapActivation">
                                            Ativação
                                        </label>

                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-3"
                                            id="mapPacemapping"
                                            checked={mapPacemapping}
                                            onChange={() => setMapPacemapping(!mapPacemapping)}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="mapPacemapping">
                                            Pacemapping
                                        </label>

                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-3"
                                            id="mapElectro"
                                            checked={mapElectro}
                                            onChange={() => setMapElectro(!mapElectro)}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="mapElectro">
                                            Mapa eletroanatômico
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-2 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="mapSubType">
                                                Subtipo de critério de mapeamento
                                            </label>
                                        </div>

                                        <div className="form-group mb-2 col-md-3">
                                            <Select
                                                defaultValue={mapSubType}
                                                value={mapSubType}
                                                onChange={e => setMapSubType(e)}
                                                options={mappingCriterionTypes}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 43,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div
                                        className="form-group mb-2 mt-1 col-md-3"
                                        style={{ width: "auto" }}
                                    >
                                        <label className="form-check-label" htmlFor="ablationOption8">
                                            Estruturas mapeadas:
                                        </label>
                                    </div>
                                    <div className="form-group mb-2 mt-1 col-md-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-5"
                                            id="mapRightAtrium"
                                            checked={mapRightAtrium}
                                            onChange={() => setMapRightAtrium(!mapRightAtrium)}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="mapRightAtrium">
                                            Átrio direito
                                        </label>
                                    </div>
                                    <div className="form-group mb-2 mt-1 col-md-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-5"
                                            id="mapLeftAtrium"
                                            checked={mapLeftAtrium}
                                            onChange={() => setMapLeftAtrium(!mapLeftAtrium)}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="mapLeftAtrium">
                                            Átrio esquerdo
                                        </label>
                                    </div>
                                    <div className="form-group mb-2 mt-1 col-md-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-5"
                                            id="mapRightVentricle"
                                            checked={mapRightVentricle}
                                            onChange={() => setMapRightVentricle(!mapRightVentricle)}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="mapRightVentricle">
                                            Ventrículo direito
                                        </label>
                                    </div>
                                    <div className="form-group mb-2 mt-1 col-md-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-5"
                                            id="mapLeftVentricle"
                                            checked={mapLeftVentricle}
                                            onChange={() => setMapLeftVentricle(!mapLeftVentricle)}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="mapLeftVentricle">
                                            Ventrículo esquerdo
                                        </label>
                                    </div>
                                    <div className="form-group mb-2 mt-1 col-md-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-5"
                                            id="mapEpicardialSpace"
                                            checked={mapEpicardialSpace}
                                            onChange={() => setMapEpicardialSpace(!mapEpicardialSpace)}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="mapEpicardialSpace">
                                            Espaço epicárdico
                                        </label>
                                    </div>
                                    <div className="form-group mb-2 mt-1 col-md-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-5"
                                            id="mapAtrioventRing"
                                            checked={mapAtrioventRing}
                                            onChange={() => setMapAtrioventRing(!mapAtrioventRing)}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="mapAtrioventRing">
                                            Anel atrioventricular
                                        </label>
                                    </div>
                                    <div className="form-group mb-2 mt-1 col-md-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input ms-5"
                                            id="mapAtrioventNode"
                                            checked={mapAtrioventNode}
                                            onChange={() => setMapAtrioventNode(!mapAtrioventNode)}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="mapAtrioventNode">
                                            Nó atrioventricular
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="sinusRhythm"
                                        checked={sinusRhythm}
                                        onChange={() => setSinusRhythm(!sinusRhythm)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="sinusRhythm">
                                        A ablação foi realizada durante ritmo sinusal
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="inducedTachycardia"
                                        checked={inducedTachycardia}
                                        onChange={() => setInducedTachycardia(!inducedTachycardia)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="inducedTachycardia">
                                        A ablação foi realizada durante a taquicardia induzida
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="ventricularPacing"
                                        checked={ventricularPacing}
                                        onChange={() => setVentricularPacing(!ventricularPacing)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="ventricularPacing">
                                        A ablação foi realizada durante "pacing" ventricular
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="atriumPacing"
                                        checked={atriumPacing}
                                        onChange={() => setAtriumPacing(!atriumPacing)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="atriumPacing">
                                        A ablação foi realizada durante "pacing" atrial
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="junctionalRhythm"
                                        checked={junctionalRhythm}
                                        onChange={() => setJunctionalRhythm(!junctionalRhythm)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="junctionalRhythm">
                                        Presença de ritmo juncional durante a ablação
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-3 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="exhaust">
                                                Escape após ablação em ms
                                            </label>
                                        </div>

                                        <div className="form-group mb-3 col-md-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ height: "2rem", width: "4.5rem" }}
                                                value={exhaust}
                                                onChange={e => setExhaust(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-2 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="successCriterion">
                                                Critério de sucesso usado na ablação de FA
                                            </label>
                                        </div>

                                        <div className="form-group mb-2 col-md-4">
                                            <Select
                                                defaultValue={successCriterion}
                                                value={successCriterion}
                                                onChange={e => setSuccessCriterion(e)}
                                                options={successCriterionTypes}
                                                isClearable
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 43,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                                placeholder="Selecione..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="venography"
                                        checked={venography}
                                        onChange={() => setVenography(!venography)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="venography">
                                        Venografia de veias pulmonares
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-3 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="totalRxTime">
                                                Tempo total de Raio X em min
                                            </label>
                                        </div>

                                        <div className="form-group mb-3 col-md-2">
                                            <input
                                                type="text"
                                                className={`form-control ${(invalidTotalRxTime ? "is-invalid" : "")}`}
                                                style={{ height: "2rem", width: "4.5rem" }}
                                                value={totalRxTime}
                                                onChange={e => setTotalRxTime(e.target.value)}
                                            />
                                            <DFormText hidden={!invalidTotalRxTime} color="danger">Campo obrigatório</DFormText>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-3 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="totalProcedureTime">
                                                Tempo total de procedimento em min
                                            </label>
                                        </div>

                                        <div className="form-group mb-3 col-md-2">
                                            <input
                                                type="text"
                                                className={`form-control ${(invalidTotalProcedureTime ? "is-invalid" : "")}`}
                                                style={{ height: "2rem", width: "4.5rem" }}
                                                value={totalProcedureTime}
                                                onChange={e => setTotalProcedureTime(e.target.value)}
                                            />
                                            <DFormText hidden={!invalidTotalProcedureTime} color="danger">Campo obrigatório</DFormText>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-3 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="totalLesionTime">
                                                Tempo total de lesões em min
                                            </label>
                                        </div>

                                        <div className="form-group mb-3 col-md-2">
                                            <input
                                                type="text"
                                                className={`form-control ${(invalidTotalLesionTime ? "is-invalid" : "")}`}
                                                style={{ height: "2rem", width: "4.5rem" }}
                                                value={totalLesionTime}
                                                onChange={e => setTotalLesionTime(e.target.value)}
                                            />
                                            <DFormText hidden={!invalidTotalLesionTime} color="danger">Campo obrigatório</DFormText>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="accessoryPathBlock"
                                        checked={accessoryPathBlock}
                                        onChange={() => setAccessoryPathBlock(!accessoryPathBlock)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="accessoryPathBlock">
                                        Bloqueio da via acessória durante ablação
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="interruption"
                                        checked={interruption}
                                        onChange={() => setInterruption(!interruption)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="interruption">
                                        Interrupção da arritmia durante ablação
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="tachycardiaReinduction"
                                        checked={tachycardiaReinduction}
                                        onChange={() => setTachycardiaReinduction(!tachycardiaReinduction)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="tachycardiaReinduction">
                                        Reindução da taquicardia após ablação
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="trnJumpPersistence"
                                        checked={trnJumpPersistence}
                                        onChange={() => setTrnJumpPersistence(!trnJumpPersistence)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="trnJumpPersistence">
                                        Se ablação de TRN final com persistência de salto
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="trnEcoPersistence"
                                        checked={trnEcoPersistence}
                                        onChange={() => setTrnEcoPersistence(!trnEcoPersistence)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="trnEcoPersistence">
                                        Se ablação de TRN final com persistência de eco
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="flutterBlock"
                                        checked={flutterBlock}
                                        onChange={() => setFlutterBlock(!flutterBlock)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="flutterBlock">
                                        Se ablação de Flutter atrial comum final com linha de bloqueio bidirecional
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="isuprel"
                                        checked={isuprel}
                                        onChange={() => setIsuprel(!isuprel)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="isuprel">
                                        Isuprel foi usado nos testes finais
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="adenosine"
                                        checked={adenosine}
                                        onChange={() => setAdenosine(!adenosine)}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="adenosine">
                                        Adenosina foi usada nos testes finais
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group mb-3 col-md-12 text-end">
                                <button className="btn btn-primary shadow mt-4 ms-1" onClick={handleAddAblation}>
                                    Salvar Ablação
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group mb-3 col-md-12">
                                <DCard borderColor="light">
                                    <DCardHeader color="light" textColor="dark">Lista de ablações deste procedimento</DCardHeader>
                                    <DCardBody>
                                        <Table responsive>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>Sequência</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Ablação</strong>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    ablationList.map((ablationItem) => {
                                                        return (
                                                            <tr key={ablationItem.id}>
                                                                <td>{ablationItem.sequence}</td>
                                                                <td>{ablationItem.ablationDescription ?? ""}</td>
                                                                <td>
                                                                    <div className="d-flex">
                                                                        <button
                                                                            className="btn btn-info shadow sharp ms-1"
                                                                            onClick={() => handleEditAblation(ablationItem.id)}
                                                                            disabled={isEditing}
                                                                        >
                                                                            <i className="fas fa-pencil-alt"></i>
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-danger shadow sharp ms-1"
                                                                            onClick={() => handleDeleteAblation(ablationItem.id)}
                                                                            disabled={isEditing}
                                                                        >
                                                                            <i className="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </DCardBody>
                                </DCard>
                            </div>
                        </div>
                    </DCardBody>
                </DCard>
            </div>
        </div>

    )
}

AblationCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

AblationCard.defaultProps = {
    ablationListParm: {},
    hide: false
};

export default AblationCard;