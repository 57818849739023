import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Button,
    FormCheck,
    Modal
} from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import dayjs from 'dayjs';

import InputMask from "react-input-mask";
import Select from "react-select";

import { cnpj } from "cpf-cnpj-validator";

import ModalLoading from '../../components/ModalLoading/ModalLoading';
import DFormText from "../../components/DFormText/DFormText";
import DAddressForm from '../../components/DAddressForm/DAddressForm';

import api from '../../../services/api';
import { createLog } from '../../../services/logService';

const TYPES_LIST = [
    { value: "HO", label: "Hospital" },
    { value: "CL", label: "Clínica" },
    { value: "CO", label: "Consultório" }
];

const HospitalForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Gravando Informações...");
    const [loadingHospitalForm, setLoadingHospitalForm] = useState(false);

    const [hospitalId, setHospitalId] = useState(undefined);

    const [documentId, setDocumentId] = useState("");
    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [type, setType] = useState(undefined);
    const [createDate, setCreateDate] = useState(dayjs().format("DD/MM/YYYY"));

    const [addressInfo, setAddressInfo] = useState({});

    const [contactId, setContactId] = useState(undefined);
    const [contactName, setContactName] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactHomePhone, setContactHomePhone] = useState("");
    const [contactComercialPhone, setContactComercialPhone] = useState("");
    const [contactCellPhone, setContactCellPhone] = useState("");
    const [observation, setObservation] = useState("");
    const [active, setActive] = useState(true);

    const [invalidName, setInvalidName] = useState(false);
    const [invalidDocumentId, setInvalidDocumentId] = useState(false);
    const [invalidDocumentIdText, setInvalidDocumentIdText] = useState("Informe um CNPJ válido");
    const [invalidType, setInvalidType] = useState(false);

    const [toastErrorMessage, setToasterErrorMessage] = useState("Ocorreu um problema ao salvar o hospital");

    useImperativeHandle(ref, () => ({
        openModal(mode, hospitalInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            resetFields();

            if (mode === "update") {
                setIsUpdate(true);

                setHospitalId(hospitalInfo._id);
                setDocumentId(hospitalInfo.document_id);
                setName(hospitalInfo.name);
                setShortName(hospitalInfo.short_name ?? "");
                setActive(hospitalInfo.status);
                setObservation(hospitalInfo.observation);

                if (hospitalInfo.type) {
                    const hospitalType = TYPES_LIST.find(x => x.value === hospitalInfo.type);
                    if (hospitalType) {
                        setType(hospitalType);
                    }
                }
                setCreateDate(dayjs(hospitalInfo.createdAt).format("DD/MM/YYYY"));
            }

            if (hospitalInfo.addresses && hospitalInfo.addresses.length > 0) {
                setAddressInfo({
                    zipCode: hospitalInfo.addresses[0].zip_code,
                    address: hospitalInfo.addresses[0].address,
                    addressNumber: hospitalInfo.addresses[0].address_number,
                    complement: hospitalInfo.addresses[0].complement ?? "",
                    district: hospitalInfo.addresses[0].district ?? "",
                    city: hospitalInfo.addresses[0].city ?? "",
                    addressState: hospitalInfo.addresses[0].state
                });
            }

            if (hospitalInfo.contacts && hospitalInfo.contacts.length > 0) {
                setContactId(hospitalInfo.contacts[0].id);
                setContactName(hospitalInfo.contacts[0].name);
                setContactEmail(hospitalInfo.contacts[0].email);

                for (let indexPhone = 0; indexPhone < hospitalInfo.contacts[0].phones.length; indexPhone++) {
                    const phoneReturned = hospitalInfo.contacts[0].phones[indexPhone].phone;

                    if (hospitalInfo.contacts[0].phones[indexPhone].type === "home") {
                        setContactHomePhone(phoneReturned);
                    }
                    if (hospitalInfo.contacts[0].phones[indexPhone].type === "comercial") {
                        setContactComercialPhone(phoneReturned);
                    }

                    if (hospitalInfo.contacts[0].phones[indexPhone].type === "cell") {
                        setContactCellPhone(phoneReturned);
                    }
                }
            }
        }
    }));

    async function handleSaveHospital(e) {
        e.preventDefault();

        setLoadingMessage("Gravando Informações...");
        setLoadingHospitalForm(true);

        setInvalidName(false);
        setInvalidType(false);

        let hasError = false;

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");
        if (!isUpdate && (invalidDocumentId || documentUnformatted.trim() === "")) {
            setInvalidDocumentId(true);
            hasError = true;
        }

        if (name === "") {
            setInvalidName(true);
            hasError = true;
        }

        if (!type) {
            setInvalidType(true);
            hasError = true;
        }

        if (!hasError) {
            let hospital = {
                document_id: documentUnformatted.trim(),
                name,
                short_name: shortName,
                type: type.value,
                status: active,
                addresses: [],
                contacts: [],
                observation
            };

            if (addressInfo) {
                if (addressInfo.zipCode?.trim().length === 8) {
                    hospital.addresses.push({
                        zip_code: addressInfo.zipCode.trim(),
                        address: addressInfo.address,
                        address_number: addressInfo.addressNumber,
                        complement: addressInfo.complement ?? "",
                        district: addressInfo.district,
                        city: addressInfo.city,
                        state: addressInfo.addressState
                    });
                }
            }

            const contactHomePhoneUnformatted = contactHomePhone.replace(/[()-]/g, "").replace(" ", "");
            const contactComercialPhoneUnformatted = contactComercialPhone.replace(/[()-]/g, "").replace(" ", "");
            const contactCellPhoneUnformatted = contactCellPhone.replace(/[()-]/g, "").replace(" ", "");

            if (contactName !== "" || contactEmail !== "" || contactHomePhoneUnformatted.trim() !== "" || contactComercialPhoneUnformatted.trim() !== "" || contactCellPhoneUnformatted.trim() !== "") {
                let newContact = {
                    type: "personal",
                    name: contactName,
                    email: contactEmail,
                    phones: []
                };

                if (contactHomePhoneUnformatted) {
                    newContact.phones.push({
                        type: "home",
                        phone: contactHomePhoneUnformatted
                    });
                }

                if (contactComercialPhoneUnformatted) {
                    newContact.phones.push({
                        type: "comercial",
                        phone: contactComercialPhoneUnformatted
                    });
                }

                if (contactCellPhoneUnformatted) {
                    newContact.phones.push({
                        type: "cell",
                        phone: contactCellPhoneUnformatted
                    });
                }

                hospital.contacts.push(newContact);
            }

            if (!isUpdate) {
                try {
                    await api.post("/hospitals", hospital)
                        .then(response => {
                            if (response.status === 201) {
                                createLog({
                                    routine: "hospital",
                                    action: "create",
                                    type: "success",
                                    message: `Hospital ${hospital.name} incluído`,
                                    jsonOrigin: JSON.stringify(hospital),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingHospitalForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data?.error === "ALREADY_EXISTS") {
                        setToasterErrorMessage("Hospital já cadastrado!");
                    }

                    createLog({
                        routine: "hospital",
                        action: "create",
                        type: "error",
                        message: `Hospital ${hospital.name} não incluído`,
                        jsonOrigin: JSON.stringify(hospital),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            } else {
                try {
                    await api.put(`/hospitals/${hospitalId}`, hospital)
                        .then(response => {
                            if (response.status === 200) {
                                createLog({
                                    routine: "hospital",
                                    action: "update",
                                    type: "success",
                                    message: `Hospital ${hospital.name} alterado`,
                                    jsonOrigin: JSON.stringify(hospital),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingHospitalForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "hospital",
                        action: "update",
                        type: "error",
                        message: `Hospital ${hospital.name} não alterado`,
                        jsonOrigin: JSON.stringify(hospital),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            }
        }

        setLoadingHospitalForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Hospital ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${toastErrorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setHospitalId(undefined);
        setName("");
        setShortName("");
        setDocumentId("");
        setType(undefined);
        setCreateDate("");
        setAddressInfo({});
        setContactName("");
        setContactEmail("");
        setContactHomePhone("");
        setContactComercialPhone("");
        setContactCellPhone("");
        setObservation("");
        setActive(true);
        setLoadingMessage("Gravando Informações...");

        setInvalidName(false);
        setInvalidDocumentId(false);
        setInvalidDocumentIdText("Informe um CNPJ válido");
        setInvalidType(false);

        setToasterErrorMessage("Ocorreu um problema ao salvar o hospital");
    }

    async function maskDocumentField() {
        setInvalidDocumentId(false);

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

        if (documentUnformatted.length > 0) {
            if (!cnpj.isValid(documentUnformatted)) {
                setInvalidDocumentId(true);
            } else {
                if (!isUpdate) {
                    await api.get(`/hospitals/document/${documentUnformatted}`)
                        .then(response => {
                            if (response.status === 200) {
                                setInvalidDocumentId(true);
                                setInvalidDocumentIdText("CNPJ já cadastrado");
                            }
                        });
                }
            }
        } else {
            setInvalidDocumentId(true);
        }
    }

    return (
        <>
            <Modal size='xl' fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">{(!isUpdate ? "Novo " : "Alterar ")}Hospital</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-2">
                                    <label>CNPJ</label>
                                    <InputMask
                                        id="documentId"
                                        autoFocus
                                        mask="99.999.999/9999-99"
                                        maskChar=" "
                                        value={documentId}
                                        onChange={(event) => {
                                            setDocumentId(event?.target.value);
                                        }}
                                        onBlur={() => { maskDocumentField() }}
                                        alwaysShowMask={true}
                                        disabled={isUpdate}
                                    >
                                        {(inputProps) => <input {...inputProps} autoFocus type="text" className={`form-control ${(invalidDocumentId ? "is-invalid" : "")}`} disabled={isUpdate} />}
                                    </InputMask>
                                    <DFormText hidden={!invalidDocumentId} color="danger">{invalidDocumentIdText}</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-5">
                                    <label>Razão Social</label>
                                    <input
                                        autoFocus={isUpdate}
                                        type="text"
                                        className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                    <DFormText hidden={!invalidName} color="danger">Informe o nome do hospital</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Nome Reduzido</label>
                                    <input
                                        type="text"
                                        maxLength={10}
                                        className="form-control"
                                        value={shortName}
                                        onChange={e => setShortName(e.target.value)}
                                    />
                                    <DFormText hidden={!invalidName} color="danger">Informe o nome do hospital</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Tipo de Hospital</label>
                                    <Select
                                        className={`${(invalidType ? "form-control is-invalid" : "")}`}
                                        defaultValue={type}
                                        value={type}
                                        onChange={(e) => setType(e)}
                                        options={TYPES_LIST}
                                        style={{
                                            lineHeight: "40px",
                                            color: "#7e7e7e",
                                            paddingLeft: " 15px",
                                        }}
                                        placeholder="Selecione..."
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                    />
                                    <DFormText hidden={!invalidType} color="danger">Campo obrigatório</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-2">
                                    <label>Data de Cadastro</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={createDate}
                                        disabled
                                    />
                                </div>
                            </div>

                            <DAddressForm
                                addressInfoParm={addressInfo}
                                onChangeAddress={setAddressInfo}
                            />

                            <h5>Contatos</h5>
                            <div className="row">
                                <div className="form-group mb-3 col-md-8">
                                    <label>Nome do Contato</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contactName}
                                        onChange={e => setContactName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contactEmail}
                                        onChange={e => setContactEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Residencial</label>
                                    <InputMask
                                        mask="(99) 9999-9999"
                                        maskChar=" "
                                        value={contactHomePhone}
                                        onChange={(event) => {
                                            setContactHomePhone(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                    </InputMask>
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Comercial</label>
                                    <InputMask
                                        mask="(99) 9999-9999"
                                        maskChar=" "
                                        value={contactComercialPhone}
                                        onChange={(event) => {
                                            setContactComercialPhone(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                    </InputMask>
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Celular</label>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        maskChar=" "
                                        value={contactCellPhone}
                                        onChange={(event) => {
                                            setContactCellPhone(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                    </InputMask>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Observações</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        value={observation}
                                        onChange={e => setObservation(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                        <Button variant="success btn-rounded" onClick={handleSaveHospital}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingHospitalForm}
                message={loadingMessage}
                onClose={setLoadingHospitalForm}
            />
        </>
    )
})

export default HospitalForm;