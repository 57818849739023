import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
    Button,
    ButtonGroup,
    Card,
    Col,
    Modal,
    Row
} from "react-bootstrap";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import ReactQuill from "react-quill";
import dayjs from "dayjs";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import { ageInString } from "../../../services/utils";
import { yesNoOptions } from "../../../enum/enums";
import { resultTypes, tachycardiaTypes } from "../../../enum/ablationEnums";

import DFormText from "../../components/DFormText/DFormText";

import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";

import AblationCard from "./AblationCard";
import PacemakerCard from "./PacemakerCard";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import PunctionCard from "./PunctionCard";
import MaterialCard from "./MaterialCard";
import EefCard from "./EefCard";
import ComplicationCard from "../Complications/ComplicationCard";

import { generateReport } from "./GenerateReport";
import { createLog } from "../../../services/logService";

import api from "../../../services/api";
registerLocale("ptBR", ptBR);

const ProcedureExecution = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingProcedureExecution, setLoadingProcedureExecution] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Salvando Registro...");

    const [pacientId, setPacientId] = useState(undefined);
    const [name, setName] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [gender, setGender] = useState("");
    const [birthDate, setBirthDate] = useState(null);
    const [age, setAge] = useState("");

    const [scheduleId, setScheduleId] = useState(undefined);

    const [forwardDoctor, setForwardDoctor] = useState(undefined);
    const [forwardDoctorEmail, setForwardDoctorEmail] = useState("");
    const [doctorsList, setDoctorsList] = useState([]);
    const [fullDoctorsList, setFullDoctorsList] = useState([]);

    const [healthPlan, setHealthPlan] = useState("");
    const [healthPlansList, setHealthPlansList] = useState([]);

    const [hospital, setHospital] = useState(undefined);
    const [hospitalsList, setHospitalsList] = useState([]);

    const [procedureDate, setProcedureDate] = useState(dayjs().toDate());
    const [eefNumber, setEefNumber] = useState("");

    const [procedure, setProcedure] = useState(undefined);
    const [proceduresList, setProceduresList] = useState([]);
    const [preDiagnosis, setPreDiagnosis] = useState("");

    const [reportType, setReportType] = useState(null);

    const [eefType, setEefType] = useState(null);
    const [anesthesiaType, setAnesthesiaType] = useState(null);

    const [punctionInfo, setPunctionInfo] = useState({});
    const [punctionInvalidFields, setPunctionInvalidFields] = useState([]);

    const [materialInfo, setMaterialInfo] = useState({});

    const [ablationType, setAblationType] = useState(null);

    const [eefInfo, setEefInfo] = useState({});

    const [ablationList, setAblationList] = useState([]);

    const [conclusion, setConclusion] = useState(null);
    const [summaryConclusion, setSummaryConclusion] = useState("");

    const [heparin, setHeparin] = useState(false);
    const [heparinQuantity, setHeparinQuantity] = useState("");
    const [bandageRightInguinal, setBandageRightInguinal] = useState(false);
    const [bandageLeftInguinal, setBandageLeftInguinal] = useState(false);
    const [bandageJugular, setBandageJugular] = useState(false);
    const [bandageSubclavian, setBandageSubclavian] = useState(false);
    const [bandageXiphoid, setBandageXiphoid] = useState(false);
    const [reportFormat, setReportFormat] = useState(null);
    const [printHma, setPrintHma] = useState({ value: "NO", label: "Não" });
    const [finalReport, setFinalReport] = useState("");

    const [hasComplication, setHasComplication] = useState({ value: "NO", label: "Não" });
    const [complicationInfo, setComplicationInfo] = useState({});

    const [invalidReportType, setInvalidReportType] = useState(false);
    const [invalidEefType, setInvalidEefType] = useState(false);
    const [invalidConclusion, setInvalidConclusion] = useState(false);
    const [invalidSummaryConclusion, setInvalidSummaryConclusion] = useState(false);
    const [invalidHeparinQuantity, setInvalidHeparinQuantity] = useState(false);

    const [hma, setHma] = useState("");

    const [mainDoctor, setMainDoctor] = useState(undefined);
    const [firstAssistDoctor, setFirstAssistDoctor] = useState(undefined);
    const [secondAssistDoctor, setSecondAssistDoctor] = useState(undefined);

    const [procedureGroup, setProcedureGroup] = useState(undefined);
    const [procedureGroupsList, setProcedureGroupsList] = useState([]);
    const [procedureType, setProcedureType] = useState(undefined);
    const [procedureTypesList, setProcedureTypesList] = useState([]);

    const [isPacemaker, setIsPacemaker] = useState(false);
    const [pacemakerInfo, setPacemakerInfo] = useState({});

    const [logs, setLogs] = useState([]);

    const [invalidForwardDoctor, setInvalidForwardDoctor] = useState(false);
    const [invalidHealthPlan, setInvalidHealthPlan] = useState(false);
    const [invalidHospital, setInvalidHospital] = useState(false);
    const [invalidExecutionDate, setInvalidExecutionDate] = useState(false);
    const [invalidProcedure, setInvalidProcedure] = useState(false);
    const [invalidMainDoctor, setInvalidMainDoctor] = useState(false);

    const userInfo = JSON.parse(localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxUsr") : sessionStorage.getItem("xLcxUsr"));

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o registro");

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': ['', 'right', 'center', 'justify'] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align', 'list', 'bullet', 'indent',
        'link', 'image'
    ];

    useImperativeHandle(ref, () => ({
        async openModal(mode, id) {
            setModalBox(true);
            setIsUpdate(false);

            if (mode === "new") {
                loadPacient(id);
            } else if (mode === "edit") {
                setIsUpdate(true);
                loadReport(id);
            }
        },
    }));

    useEffect(() => {
        getDoctors();
        getHealthPlans();
        getHospitals();
        getProcedures();
        getProcedureGroups();
    }, []);


    async function loadPacient(id) {
        setLoadingMessage("Recuperando informações...");
        setLoadingProcedureExecution(true);

        const pacientInfo = await getPacient(id);

        if (pacientInfo) {
            setPacientId(pacientInfo._id);
            setName(pacientInfo.name);
            setDocumentId(pacientInfo.document_id);
            setEefNumber(pacientInfo.document_id + "_" + dayjs().format("YYYYMMDD"));
            setGender(pacientInfo.gender === "M" ? "Masculino" : "Feminino");
            setBirthDate(dayjs(pacientInfo.birth_date).toDate());

            const ageInStr = ageInString(dayjs(pacientInfo.birth_date).toDate());
            setAge(ageInStr);

            const scheduleInfo = await getScheduleByPacient(id);

            if (scheduleInfo) {
                setScheduleId(scheduleInfo._id);

                const forwardDoctorInfo = await getDoctorById(scheduleInfo.forward_doctor_id);
                if (forwardDoctorInfo) {
                    if (forwardDoctorInfo.contacts) {
                        const forwardDoctorContact = forwardDoctorInfo.contacts.find(x => x.type === "personal");
                        setForwardDoctorEmail(forwardDoctorContact?.email ?? "");
                    }
                }

                setForwardDoctor({
                    value: scheduleInfo.forward_doctor_id,
                    label: scheduleInfo.forward_doctor_name
                });

                setHealthPlan({
                    value: scheduleInfo.health_plan_id,
                    label: scheduleInfo.health_plan_name
                });

                setHospital({
                    value: scheduleInfo.hospital_id,
                    label: scheduleInfo.hospital_name
                });

                setProcedureDate(dayjs(scheduleInfo.schedule_date).toDate());

                setProcedure({
                    value: scheduleInfo.procedure_id,
                    label: scheduleInfo.procedure_name
                });

                handleChangeProcedure({
                    value: scheduleInfo.procedure_id,
                    label: scheduleInfo.procedure_name
                });

                if (scheduleInfo.diagnosis) {
                    setPreDiagnosis(scheduleInfo.diagnosis);
                }

                if (scheduleInfo.main_doctor_id) {
                    setMainDoctor({
                        value: scheduleInfo.main_doctor_id,
                        label: scheduleInfo.main_doctor_name
                    });
                }

                if (scheduleInfo.doctors) {
                    const doctorsList = scheduleInfo.doctors.filter(x => x.doctor_id !== scheduleInfo.main_doctor_id);

                    for (let indexDoctor = 0; indexDoctor < doctorsList.length; indexDoctor++) {
                        if (indexDoctor === 0) {
                            setFirstAssistDoctor({
                                value: doctorsList[indexDoctor].doctor_id,
                                label: doctorsList[indexDoctor].doctor_name
                            });
                        } else if (indexDoctor === 1) {
                            setSecondAssistDoctor({
                                value: doctorsList[indexDoctor].doctor_id,
                                label: doctorsList[indexDoctor].doctor_name
                            });
                        }
                    }
                }
            }

            const anamnesisInfo = await getAnamnesis(id);
            if (anamnesisInfo) {
                setHma(anamnesisInfo.hma);
            }
        }

        setLoadingProcedureExecution(false);
    }

    async function loadReport(id) {
        setLoadingMessage("Recuperando informações...");
        setLoadingProcedureExecution(true);

        setScheduleId(id);

        const scheduleInfo = await getScheduleById(id);

        if (scheduleInfo) {
            const scheduleReportInfo = scheduleInfo.reports[0];

            if (scheduleReportInfo) {
                setPacientId(scheduleReportInfo.pacient_id);
                setName(scheduleReportInfo.pacient_name);
                setGender(scheduleReportInfo.pacient_gender);
                setBirthDate(dayjs(scheduleReportInfo.pacient_birth_date).toDate());

                const ageInStr = ageInString(dayjs(scheduleReportInfo.pacient_birth_date).toDate());
                setAge(ageInStr);

                setForwardDoctor({
                    value: scheduleReportInfo.forward_doctor_id,
                    label: scheduleReportInfo.forward_doctor_name
                });
                setForwardDoctorEmail(scheduleReportInfo.forward_doctor_email);

                setHealthPlan({
                    value: scheduleReportInfo.health_plan_id,
                    label: scheduleReportInfo.health_plan_name
                });

                setHospital({
                    value: scheduleReportInfo.hospital_id,
                    label: scheduleReportInfo.hospital_name
                });

                setProcedureDate(dayjs(scheduleReportInfo.execution_date).toDate());
                setEefNumber(scheduleReportInfo.eef_number ?? "");

                setProcedure({
                    value: scheduleReportInfo.procedure_id,
                    label: scheduleReportInfo.procedure_name
                });

                setPreDiagnosis(scheduleReportInfo.pre_diagnosis);

                setReportType(scheduleReportInfo.report_type);
                setEefType(scheduleReportInfo.eef_type);
                setAnesthesiaType(scheduleReportInfo.anesthesia_type);

                setPunctionInfo({
                    punction1pfd: scheduleReportInfo.punction_1pfd,
                    punction2pfd: scheduleReportInfo.punction_2pfd,
                    punction3pfd: scheduleReportInfo.punction_3pfd,
                    punction1pfe: scheduleReportInfo.punction_1pfe,
                    punction1pj: scheduleReportInfo.punction_1pj,
                    punction1ps: scheduleReportInfo.punction_1ps,
                    punction1paf: scheduleReportInfo.punction_1paf,
                    punction1pe: scheduleReportInfo.punction_1pe,
                    punction1pt: scheduleReportInfo.punction_1pt,
                    punction2pt: scheduleReportInfo.punction_2pt,
                    punction1blc: scheduleReportInfo.punction_1blc,
                    punction2blc: scheduleReportInfo.punction_2blc,
                    punction1blev: scheduleReportInfo.punction_1blev,
                    punction1blea: scheduleReportInfo.punction_1blea,
                    punction7f: scheduleReportInfo.punction_7f ?? "0",
                    punction8f: scheduleReportInfo.punction_8f ?? "0"
                });

                setMaterialInfo({
                    catheterQuad: scheduleReportInfo.catheter_quad,
                    catheterDeca: scheduleReportInfo.catheter_deca,
                    catheterConv4mm: scheduleReportInfo.catheter_conv_4mm,
                    catheterConv8mm: scheduleReportInfo.catheter_conv_8mm,
                    catheterIrriContact: scheduleReportInfo.catheter_irri_contact,
                    catheterIrriEnergy: scheduleReportInfo.catheter_irri_energy,
                    catheterIrriElectro: scheduleReportInfo.catheter_irri_electro,
                    catheterPumpKit: scheduleReportInfo.catheter_pumpKit,
                    catheterPenta: scheduleReportInfo.catheter_penta,
                    catheterOcta: scheduleReportInfo.catheter_octa,
                    catheterGrid: scheduleReportInfo.catheter_grid,
                    bioimpedanceBoard: scheduleReportInfo.bioimpedance_board,
                    faCryoablationKit: scheduleReportInfo.fa_cryoablation_kit,
                    focalCryoablationKit: scheduleReportInfo.focal_cryoablation_kit,
                    brokenbroughtNeedle: scheduleReportInfo.brokenbrought_needle,
                    intracardiacContrast: scheduleReportInfo.intracardiac_contrast,
                    echocardiogramCatheter: scheduleReportInfo.echocardiogram_catheter,
                    oroesophagealThermometer: scheduleReportInfo.oroesophageal_thermometer,
                    pam: scheduleReportInfo.pam,
                    bladderCatheter: scheduleReportInfo.bladder_catheter,
                    punctionNeedle: scheduleReportInfo.punction_needle,
                    salineSolution: scheduleReportInfo.saline_solution,
                    evAdenosine: scheduleReportInfo.ev_adenosine,
                });

                if (scheduleReportInfo.ablation_type) {
                    setAblationType({
                        value: scheduleReportInfo.ablation_type,
                        label: scheduleReportInfo.ablation_description
                    });
                }

                setEefInfo({
                    basalRhythm: scheduleReportInfo.basal_rhythm ? { value: scheduleReportInfo.basal_rhythm, label: scheduleReportInfo.basal_rhythm_description } : null,
                    ventricularRate: scheduleReportInfo.ventricular_rate,
                    atrialRate: scheduleReportInfo.atrial_rate,
                    prInterval: scheduleReportInfo.pr_interval,
                    qtcInterval: scheduleReportInfo.qtc_interval,
                    ecgReport: scheduleReportInfo.ecg_report?.map(ecgReportItem => {
                        return {
                            value: ecgReportItem.ecg_id,
                            label: ecgReportItem.ecg_description
                        }
                    }),
                    ahInterval: scheduleReportInfo.ah_interval,
                    hvInterval: scheduleReportInfo.hv_interval,
                    qrsInterval: scheduleReportInfo.qrs_interval,
                    sinusNodeRecovery: scheduleReportInfo.sinus_node_recovery,
                    bav: scheduleReportInfo.bav,
                    atrioventPeriodAnterograde: scheduleReportInfo.atriovent_period_anterograde,
                    atrioventPeriodFast: scheduleReportInfo.atriovent_period_fast,
                    atrioventPeriodSlow: scheduleReportInfo.atriovent_period_slow,
                    bva: scheduleReportInfo.bva,
                    atrioventPeriodRetrograde: scheduleReportInfo.atriovent_period_retrograde,
                    atriumPeriod: scheduleReportInfo.atrium_period,
                    ventriclePeriod: scheduleReportInfo.ventricle_period,
                    accessoryPath: scheduleReportInfo.accessory_path ? { value: scheduleReportInfo.accessory_path, label: scheduleReportInfo.accessory_path_description } : null,
                    accessoryPathAnterograde: scheduleReportInfo.accessory_path_anterograde,
                    accessoryPathRetrograde: scheduleReportInfo.accessory_path_retrograde,
                    inducedTachycardia: scheduleReportInfo.induced_tachycardia ? { value: scheduleReportInfo.induced_tachycardia, label: scheduleReportInfo.induced_tachycardia_description } : null,
                    commonTrnFastSlow: scheduleReportInfo.common_trn_fast_slow,
                    commonTrnFastSlowPlace: scheduleReportInfo.common_trn_fast_slow_place ? { value: scheduleReportInfo.common_trn_fast_slow_place, label: scheduleReportInfo.common_trn_fast_slow_place_description } : null,
                    atypicalTrnSlowSlow: scheduleReportInfo.atypical_trn_slow_slow,
                    atypicalTrnSlowSlowPlace: scheduleReportInfo.atypical_trn_slow_slow_place ? { value: scheduleReportInfo.atypical_trn_slow_slow_place, label: scheduleReportInfo.atypical_trn_slow_slow_place_description } : null,
                    atypicalTrnFastSlow: scheduleReportInfo.atypical_trn_fast_slow,
                    atypicalTrnFastSlowPlace: scheduleReportInfo.atypical_trn_fast_slow_place ? { value: scheduleReportInfo.atypical_trn_fast_slow_place, label: scheduleReportInfo.atypical_trn_fast_slow_place_description } : null,
                    tvaCommonShown: scheduleReportInfo.tva_common_shown,
                    tvaCommonShownBavAnterograde: scheduleReportInfo.tva_common_shown_bav_anterograde,
                    tvaCommonShownBavRetrograde: scheduleReportInfo.tva_common_shown_bav_retrograde,
                    tvaCommonShownAnterogradePeriod: scheduleReportInfo.tva_common_shown_anterograde_period,
                    tvaCommonShownRetrogradePeriod: scheduleReportInfo.tva_common_shown_retrograde_period,
                    tvaAtypicalShown: scheduleReportInfo.tva_atypical_shown,
                    tvaAtypicalShownAtriumVentricular: scheduleReportInfo.tva_atypical_shown_atrium_ventricular,
                    tvaAtypicalShownAtriumFascicular: scheduleReportInfo.tva_atypical_shown_atrium_fascicular,
                    tvaAtypicalShownNodeVentricular: scheduleReportInfo.tva_atypical_shown_node_ventricular,
                    tvaAtypicalShownNodeFascicular: scheduleReportInfo.tva_atypical_shown_node_fascicular,
                    tvaAtypicalShownFascicleVentricular: scheduleReportInfo.tva_atypical_shown_fascicle_ventricular,
                    tvaCommonHidden: scheduleReportInfo.tva_common_hidden,
                    tvaCommonHiddenBva: scheduleReportInfo.tva_common_hidden_bva,
                    tvaCommonHiddenRetrogradePeriod: scheduleReportInfo.tva_common_hidden_retrograde_period,
                    tvaAtypicalHidden: scheduleReportInfo.tva_atypical_hidden,
                    tvaAtypicalHiddenBva: scheduleReportInfo.tva_atypical_hidden_bva,
                    tvaAtypicalHiddenRetrogradePeriod: scheduleReportInfo.tva_atypical_hidden_retrograde_period,
                    taAutomatic: scheduleReportInfo.ta_automatic,
                    taReentrant: scheduleReportInfo.ta_reentrant,
                    taTriggered: scheduleReportInfo.ta_triggered,
                    flaCommonAntiClockwise: scheduleReportInfo.fla_common_anti_clockwise,
                    flaCommonClockwise: scheduleReportInfo.fla_common_clockwise,
                    flaAtypicalRight: scheduleReportInfo.fla_atypical_right,
                    flaAtypicalLeft: scheduleReportInfo.fla_atypical_left,
                    tvNormal: scheduleReportInfo.tv_normal,
                    tvIschemic: scheduleReportInfo.tv_ischemic,
                    tvChagastic: scheduleReportInfo.tv_chagastic,
                    tvOther: scheduleReportInfo.tv_other,
                    natEscape: scheduleReportInfo.nat_escape,
                    natBpm: scheduleReportInfo.nat_bpm,
                    natNoEscape: scheduleReportInfo.nat_no_escape,
                    faOstium: scheduleReportInfo.fa_ostium,
                    faLeftBox: scheduleReportInfo.fa_left_box,
                    faEarpiece: scheduleReportInfo.fa_earpiece,
                    faLeftLine: scheduleReportInfo.fa_left_line,
                    faIstmo: scheduleReportInfo.fa_istmo,
                    faRightLine: scheduleReportInfo.fa_right_line,
                    outTa: scheduleReportInfo.out_ta,
                    outFla: scheduleReportInfo.out_fla,
                    outTrn: scheduleReportInfo.out_trn,
                    outTva: scheduleReportInfo.out_tva,
                    outTv: scheduleReportInfo.out_tv,
                    outEa: scheduleReportInfo.out_ea,
                    outEv: scheduleReportInfo.out_ev,
                    outOther: scheduleReportInfo.out_other,
                    outOtherText: scheduleReportInfo.out_other_text,
                    outComment: scheduleReportInfo.out_comment,
                    tachycardiaMode: scheduleReportInfo.tachycardia_mode,
                    tachycardiaCycle: scheduleReportInfo.tachycardia_cycle,
                    tachycardiaEcgReport: scheduleReportInfo.tachycardia_ecg_report,
                });

                if (scheduleReportInfo.ablations) {
                    const ablationsReturned = scheduleReportInfo.ablations.map(ablationItem => {
                        let basalRhythmReturned = undefined;

                        if (ablationItem.eefInfo?.basal_rhythm) {
                            basalRhythmReturned = { value: ablationItem.eefInfo.basal_rhythm, label: ablationItem.eefInfo.basal_rhythm_description };
                        } else if (scheduleReportInfo.basal_rhythm) {
                            basalRhythmReturned = { value: scheduleReportInfo.basal_rhythm, label: scheduleReportInfo.basal_rhythm_description };
                        }

                        return {
                            id: (Math.floor(Math.random() * 65536)).toString(),
                            sequence: ablationItem.sequence,
                            ablationType: ablationItem.ablation_type ?? scheduleReportInfo.ablation_type,
                            ablationDescription: ablationItem.ablation_description ?? scheduleReportInfo.ablation_description,
                            punctionInfo: {
                                punction1pfd: ablationItem.punction_info?.punction_1pfd ?? scheduleReportInfo.punction_1pfd,
                                punction2pfd: ablationItem.punction_info?.punction_2pfd ?? scheduleReportInfo.punction_2pfd,
                                punction3pfd: ablationItem.punction_info?.punction_3pfd ?? scheduleReportInfo.punction_3pfd,
                                punction1pfe: ablationItem.punction_info?.punction_1pfe ?? scheduleReportInfo.punction_1pfe,
                                punction1pj: ablationItem.punction_info?.punction_1pj ?? scheduleReportInfo.punction_1pj,
                                punction1ps: ablationItem.punction_info?.punction_1ps ?? scheduleReportInfo.punction_1ps,
                                punction1paf: ablationItem.punction_info?.punction_1paf ?? scheduleReportInfo.punction_1paf,
                                punction1pe: ablationItem.punction_info?.punction_1pe ?? scheduleReportInfo.punction_1pe,
                                punction1pt: ablationItem.punction_info?.punction_1pt ?? scheduleReportInfo.punction_1pt,
                                punction2pt: ablationItem.punction_info?.punction_2pt ?? scheduleReportInfo.punction_2pt,
                                punction1blc: ablationItem.punction_info?.punction_1blc ?? scheduleReportInfo.punction_1blc,
                                punction2blc: ablationItem.punction_info?.punction_2blc ?? scheduleReportInfo.punction_2blc,
                                punction1blev: ablationItem.punction_info?.punction_1blev ?? scheduleReportInfo.punction_1blev,
                                punction1blea: ablationItem.punction_info?.punction_1blea ?? scheduleReportInfo.punction_1blea,
                                punction7f: ablationItem.punction_info?.punction_7f ?? scheduleReportInfo.punction_7f ?? "0",
                                punction8f: ablationItem.punction_info?.punction_8f ?? scheduleReportInfo.punction_8f ?? "0"
                            },
                            materialInfo: {
                                catheterQuad: ablationItem.material_info?.catheter_quad ?? scheduleReportInfo.catheter_quad,
                                catheterDeca: ablationItem.material_info?.catheter_deca ?? scheduleReportInfo.catheter_deca,
                                catheterConv4mm: ablationItem.material_info?.catheter_conv_4mm ?? scheduleReportInfo.catheter_conv_4mm,
                                catheterConv8mm: ablationItem.material_info?.catheter_conv_8mm ?? scheduleReportInfo.catheter_conv_8mm,
                                catheterIrriContact: ablationItem.material_info?.catheter_irri_contact ?? scheduleReportInfo.catheter_irri_contact,
                                catheterIrriEnergy: ablationItem.material_info?.catheter_irri_energy ?? scheduleReportInfo.catheter_irri_energy,
                                catheterIrriElectro: ablationItem.material_info?.catheter_irri_electro ?? scheduleReportInfo.catheter_irri_electro,
                                catheterPumpKit: ablationItem.material_info?.catheter_pumpKit ?? scheduleReportInfo.catheter_pumpKit,
                                catheterPenta: ablationItem.material_info?.catheter_penta ?? scheduleReportInfo.catheter_penta,
                                catheterOcta: ablationItem.material_info?.catheter_octa ?? scheduleReportInfo.catheter_octa,
                                catheterGrid: ablationItem.material_info?.catheter_grid ?? scheduleReportInfo.catheter_grid,
                                bioimpedanceBoard: ablationItem.material_info?.bioimpedance_board ?? scheduleReportInfo.bioimpedance_board,
                                faCryoablationKit: ablationItem.material_info?.fa_cryoablation_kit ?? scheduleReportInfo.fa_cryoablation_kit,
                                focalCryoablationKit: ablationItem.material_info?.focal_cryoablation_kit ?? scheduleReportInfo.focal_cryoablation_kit,
                                brokenbroughtNeedle: ablationItem.material_info?.brokenbrought_needle ?? scheduleReportInfo.brokenbrought_needle,
                                intracardiacContrast: ablationItem.material_info?.intracardiac_contrast ?? scheduleReportInfo.intracardiac_contrast,
                                echocardiogramCatheter: ablationItem.material_info?.echocardiogram_catheter ?? scheduleReportInfo.echocardiogram_catheter,
                                oroesophagealThermometer: ablationItem.material_info?.oroesophageal_thermometer ?? scheduleReportInfo.oroesophageal_thermometer,
                                pam: ablationItem.material_info?.pam ?? scheduleReportInfo.pam,
                                bladderCatheter: ablationItem.material_info?.bladder_catheter ?? scheduleReportInfo.bladder_catheter,
                                punctionNeedle: ablationItem.material_info?.punction_needle ?? scheduleReportInfo.punction_needle,
                                salineSolution: ablationItem.material_info?.saline_solution ?? scheduleReportInfo.saline_solution,
                                evAdenosine: ablationItem.material_info?.ev_adenosine ?? scheduleReportInfo.ev_adenosine,
                            },
                            eefInfo: {
                                basalRhythm: basalRhythmReturned,
                                ventricularRate: ablationItem.eefInfo?.ventricular_rate ?? scheduleReportInfo.ventricular_rate,
                                atrialRate: ablationItem.eefInfo?.atrial_rate ?? scheduleReportInfo.atrial_rate,
                                prInterval: ablationItem.eefInfo?.pr_interval ?? scheduleReportInfo.pr_interval,
                                qtcInterval: ablationItem.eefInfo?.qtc_interval ?? scheduleReportInfo.qtc_interval,
                                ecgReport: ablationItem.eefInfo?.ecg_report?.map(ecgReportItem => {
                                    return {
                                        ecgId: ecgReportItem.ecg_id,
                                        ecgDescription: ecgReportItem.ecg_description
                                    }
                                }),
                                ahInterval: ablationItem.eefInfo?.ah_interval ?? scheduleReportInfo.ah_interval,
                                hvInterval: ablationItem.eefInfo?.hv_interval ?? scheduleReportInfo.hv_interval,
                                qrsInterval: ablationItem.eefInfo?.qrs_interval ?? scheduleReportInfo.qrs_interval,
                                sinusNodeRecovery: ablationItem.eefInfo?.sinus_node_recovery ?? scheduleReportInfo.sinus_node_recovery,
                                bav: ablationItem.eefInfo?.bav ?? scheduleReportInfo.bav,
                                atrioventPeriodAnterograde: ablationItem.eefInfo?.atriovent_period_anterograde ?? scheduleReportInfo.atriovent_period_anterograde,
                                atrioventPeriodFast: ablationItem.eefInfo?.atriovent_period_fast ?? scheduleReportInfo.atriovent_period_fast,
                                atrioventPeriodSlow: ablationItem.eefInfo?.atriovent_period_slow ?? scheduleReportInfo.atriovent_period_slow,
                                bva: ablationItem.eefInfo?.bva ?? scheduleReportInfo.bva,
                                atrioventPeriodRetrograde: ablationItem.eefInfo?.atriovent_period_retrograde ?? scheduleReportInfo.atriovent_period_retrograde,
                                atriumPeriod: ablationItem.eefInfo?.atrium_period ?? scheduleReportInfo.atrium_period,
                                ventriclePeriod: ablationItem.eefInfo?.ventricle_period ?? scheduleReportInfo.ventricle_period,
                                accessoryPath: ablationItem.eefInfo?.accessory_path ?? scheduleReportInfo.accessory_path,
                                accessoryPathDescription: ablationItem.eefInfo?.accessory_path_description ?? scheduleReportInfo.accessory_path_description,
                                accessoryPathAnterograde: ablationItem.eefInfo?.accessory_path_anterograde ?? scheduleReportInfo.accessory_path_anterograde,
                                accessoryPathRetrograde: ablationItem.eefInfo?.accessory_path_retrograde ?? scheduleReportInfo.accessory_path_retrograde,
                                inducedTachycardia: ablationItem.eefInfo?.induced_tachycardia ?? scheduleReportInfo.induced_tachycardia,
                                inducedTachycardiaDescription: ablationItem.eefInfo?.induced_tachycardia_description ?? scheduleReportInfo.induced_tachycardia_description,
                                commonTrnFastSlow: ablationItem.eefInfo?.common_trn_fast_slow ?? scheduleReportInfo.common_trn_fast_slow,
                                commonTrnFastSlowPlace: ablationItem.eefInfo?.common_trn_fast_slow_place ?? scheduleReportInfo.common_trn_fast_slow_place,
                                commonTrnFastSlowPlaceDescription: ablationItem.eefInfo?.common_trn_fast_slow_place_description ?? scheduleReportInfo.common_trn_fast_slow_place_description,
                                atypicalTrnSlowSlow: ablationItem.eefInfo?.atypical_trn_slow_slow ?? scheduleReportInfo.atypical_trn_slow_slow,
                                atypicalTrnSlowSlowPlace: ablationItem.eefInfo?.atypical_trn_slow_slow_place ?? scheduleReportInfo.atypical_trn_slow_slow_place,
                                atypicalTrnSlowSlowPlaceDescription: ablationItem.eefInfo?.atypical_trn_slow_slow_place_description ?? scheduleReportInfo.atypical_trn_slow_slow_place_description,
                                atypicalTrnFastSlow: ablationItem.eefInfo?.atypical_trn_fast_slow ?? scheduleReportInfo.atypical_trn_fast_slow,
                                atypicalTrnFastSlowPlace: ablationItem.eefInfo?.atypical_trn_fast_slow_place ?? scheduleReportInfo.atypical_trn_fast_slow_place,
                                atypicalTrnFastSlowPlaceDescription: ablationItem.eefInfo?.atypical_trn_fast_slow_place_description ?? scheduleReportInfo.atypical_trn_fast_slow_place_description,
                                tvaCommonShown: ablationItem.eefInfo?.tva_common_shown ?? scheduleReportInfo.tva_common_shown,
                                tvaCommonShownBavAnterograde: ablationItem.eefInfo?.tva_common_shown_bav_anterograde ?? scheduleReportInfo.tva_common_shown_bav_anterograde,
                                tvaCommonShownBavRetrograde: ablationItem.eefInfo?.tva_common_shown_bav_retrograde ?? scheduleReportInfo.tva_common_shown_bav_retrograde,
                                tvaCommonShownAnterogradePeriod: ablationItem.eefInfo?.tva_common_shown_anterograde_period ?? scheduleReportInfo.tva_common_shown_anterograde_period,
                                tvaCommonShownRetrogradePeriod: ablationItem.eefInfo?.tva_common_shown_retrograde_period ?? scheduleReportInfo.tva_common_shown_retrograde_period,
                                tvaAtypicalShown: ablationItem.eefInfo?.tva_atypical_shown ?? scheduleReportInfo.tva_atypical_shown,
                                tvaAtypicalShownAtriumVentricular: ablationItem.eefInfo?.tva_atypical_shown_atrium_ventricular ?? scheduleReportInfo.tva_atypical_shown_atrium_ventricular,
                                tvaAtypicalShownAtriumFascicular: ablationItem.eefInfo?.tva_atypical_shown_atrium_fascicular ?? scheduleReportInfo.tva_atypical_shown_atrium_fascicular,
                                tvaAtypicalShownNodeVentricular: ablationItem.eefInfo?.tva_atypical_shown_node_ventricular ?? scheduleReportInfo.tva_atypical_shown_node_ventricular,
                                tvaAtypicalShownNodeFascicular: ablationItem.eefInfo?.tva_atypical_shown_node_fascicular ?? scheduleReportInfo.tva_atypical_shown_node_fascicular,
                                tvaAtypicalShownFascicleVentricular: ablationItem.eefInfo?.tva_atypical_shown_fascicle_ventricular ?? scheduleReportInfo.tva_atypical_shown_fascicle_ventricular,
                                tvaCommonHidden: ablationItem.eefInfo?.tva_common_hidden ?? scheduleReportInfo.tva_common_hidden,
                                tvaCommonHiddenBva: ablationItem.eefInfo?.tva_common_hidden_bva ?? scheduleReportInfo.tva_common_hidden_bva,
                                tvaCommonHiddenRetrogradePeriod: ablationItem.eefInfo?.tva_common_hidden_retrograde_period ?? scheduleReportInfo.tva_common_hidden_retrograde_period,
                                tvaAtypicalHidden: ablationItem.eefInfo?.tva_atypical_hidden ?? scheduleReportInfo.tva_atypical_hidden,
                                tvaAtypicalHiddenBva: ablationItem.eefInfo?.tva_atypical_hidden_bva ?? scheduleReportInfo.tva_atypical_hidden_bva,
                                tvaAtypicalHiddenRetrogradePeriod: ablationItem.eefInfo?.tva_atypical_hidden_retrograde_period ?? scheduleReportInfo.tva_atypical_hidden_retrograde_period,
                                taAutomatic: ablationItem.eefInfo?.ta_automatic ?? scheduleReportInfo.ta_automatic,
                                taReentrant: ablationItem.eefInfo?.ta_reentrant ?? scheduleReportInfo.ta_reentrant,
                                taTriggered: ablationItem.eefInfo?.ta_triggered ?? scheduleReportInfo.ta_triggered,
                                flaCommonAntiClockwise: ablationItem.eefInfo?.fla_common_anti_clockwise ?? scheduleReportInfo.fla_common_anti_clockwise,
                                flaCommonClockwise: ablationItem.eefInfo?.fla_common_clockwise ?? scheduleReportInfo.fla_common_clockwise,
                                flaAtypicalRight: ablationItem.eefInfo?.fla_atypical_right ?? scheduleReportInfo.fla_atypical_right,
                                flaAtypicalLeft: ablationItem.eefInfo?.fla_atypical_left ?? scheduleReportInfo.fla_atypical_left,
                                tvNormal: ablationItem.eefInfo?.tv_normal ?? scheduleReportInfo.tv_normal,
                                tvIschemic: ablationItem.eefInfo?.tv_ischemic ?? scheduleReportInfo.tv_ischemic,
                                tvChagastic: ablationItem.eefInfo?.tv_chagastic ?? scheduleReportInfo.tv_chagastic,
                                tvOther: ablationItem.eefItem?.tv_other ?? scheduleReportInfo.tv_other,
                                natEscape: ablationItem.eefItem?.nat_escape ?? scheduleReportInfo.nat_escape,
                                natBpm: ablationItem.eefItem?.nat_bpm ?? scheduleReportInfo.nat_bpm,
                                natNoEscape: ablationItem.eefItem?.nat_no_escape ?? scheduleReportInfo.nat_no_escape,
                                faOstium: ablationItem.eefItem?.fa_ostium ?? scheduleReportInfo.fa_ostium,
                                faLeftBox: ablationItem.eefItem?.fa_left_box ?? scheduleReportInfo.fa_left_box,
                                faEarpiece: ablationItem.eefItem?.fa_earpiece ?? scheduleReportInfo.fa_earpiece,
                                faLeftLine: ablationItem.eefItem?.fa_left_line ?? scheduleReportInfo.fa_left_line,
                                faIstmo: ablationItem.eefItem?.fa_istmo ?? scheduleReportInfo.fa_istmo,
                                faRightLine: ablationItem.eefItem?.fa_right_line ?? scheduleReportInfo.fa_right_line,
                                outTa: ablationItem.eefItem?.out_ta ?? scheduleReportInfo.out_ta,
                                outFla: ablationItem.eefItem?.out_fla ?? scheduleReportInfo.out_fla,
                                outTrn: ablationItem.eefItem?.out_trn ?? scheduleReportInfo.out_trn,
                                outTva: ablationItem.eefItem?.out_tva ?? scheduleReportInfo.out_tva,
                                outTv: ablationItem.eefItem?.out_tv ?? scheduleReportInfo.out_tv,
                                outEa: ablationItem.eefItem?.out_ea ?? scheduleReportInfo.out_ea,
                                outEv: ablationItem.eefItem?.out_ev ?? scheduleReportInfo.out_ev,
                                outOther: ablationItem.eefItem?.out_other ?? scheduleReportInfo.out_other,
                                outOtherText: ablationItem.eefItem?.out_other_text ?? scheduleReportInfo.out_other_text,
                                outComment: ablationItem.eefItem?.out_comment ?? scheduleReportInfo.out_comment,
                                tachycardiaMode: ablationItem.eefItem?.tachycardia_mode ?? scheduleReportInfo.tachycardia_mode,
                                tachycardiaCycle: ablationItem.eefItem?.tachycardia_cycle ?? scheduleReportInfo.tachycardia_cycle,
                                tachycardiaEcgReport: ablationItem.eefItem?.tachycardia_ecg_report ?? scheduleReportInfo.tachycardia_ecg_report
                            },
                            atrioventRelation: ablationItem.atriovent_relation ? { value: ablationItem.atriovent_relation, label: ablationItem.atriovent_relation_description } : null,
                            hiddenRetraining: ablationItem.hidden_retraining,
                            hiddenRetrainingPlace: ablationItem.hidden_retraining_place,
                            mapActivation: ablationItem.map_activation,
                            mapPacemapping: ablationItem.map_pacemapping,
                            mapElectro: ablationItem.map_electro,
                            mapSubType: ablationItem.map_sub_type ? { value: ablationItem.map_sub_type, label: ablationItem.map_sub_type_description } : null,
                            mapRightAtrium: ablationItem.map_right_atrium,
                            mapLeftAtrium: ablationItem.map_left_atrium,
                            mapRightVentricle: ablationItem.map_right_ventricle,
                            mapLeftVentricle: ablationItem.map_left_ventricle,
                            mapEpicardialSpace: ablationItem.map_epicardial_space,
                            mapAtrioventRing: ablationItem.map_atriovent_ring,
                            mapAtrioventNode: ablationItem.map_atriovent_node,
                            sinusRhythm: ablationItem.sinus_rhythm,
                            inducedTachycardia: ablationItem.induced_tachycardia,
                            ventricularPacing: ablationItem.ventricular_pacing,
                            atriumPacing: ablationItem.atrium_pacing,
                            junctionalRhythm: ablationItem.junctional_rhythm,
                            exhaust: ablationItem.exhaust,
                            successCriterion: ablationItem.success_criterion ? { value: ablationItem.success_criterion, label: ablationItem.success_criterion_description } : null,
                            venography: ablationItem.venography,
                            totalRxTime: ablationItem.total_rx_time,
                            totalProcedureTime: ablationItem.total_procedure_time,
                            totalLesionTime: ablationItem.total_lesion_time,
                            accessoryPathBlock: ablationItem.accessory_path_block,
                            interruption: ablationItem.interruption,
                            tachycardiaReinduction: ablationItem.tachycardia_reinduction,
                            trnJumpPersistence: ablationItem.trn_jump_persistence,
                            trnEcoPersistence: ablationItem.trn_eco_persistence,
                            flutterBlock: ablationItem.flutter_block,
                            isuprel: ablationItem.isuprel,
                            adenosin: ablationItem.adenosine,
                            conclusion: ablationItem.conclusion ?? scheduleReportInfo.conclusion,
                            conclusionDescription: ablationItem.conclusion_description ?? scheduleReportInfo.conclusion_description,
                            summaryConclusion: ablationItem.summary_conclusion ?? scheduleReportInfo.summary_conclusion
                        }
                    });

                    setAblationList(ablationsReturned);
                }

                if (scheduleReportInfo.pacemakers) {
                    setPacemakerInfo({
                        implant: scheduleReportInfo.pacemakers.implant_option ? { value: scheduleReportInfo.pacemakers.implant_option, label: scheduleReportInfo.pacemakers.implant_description } : null,
                        implantDate: dayjs(scheduleReportInfo.pacemakers.implant_date).toDate(),
                        supplier: scheduleReportInfo.pacemakers.supplier_id ? { value: scheduleReportInfo.pacemakers.supplier_id, label: scheduleReportInfo.pacemakers.supplier_name } : null,
                        generator: scheduleReportInfo.pacemakers.generator ?? "",
                        place: scheduleReportInfo.pacemakers.place_option ? { value: scheduleReportInfo.pacemakers.place_option, label: scheduleReportInfo.pacemakers.place_description } : null,
                        defibrillation: scheduleReportInfo.pacemakers.defibrillation_option ? { value: scheduleReportInfo.pacemakers.defibrillation_option, label: scheduleReportInfo.pacemakers.defibrillation_description } : null,
                        result: scheduleReportInfo.pacemakers.result_option ? { value: scheduleReportInfo.pacemakers.result_option, label: scheduleReportInfo.pacemakers.result_description } : null,
                        complications: scheduleReportInfo.pacemakers.complications ?? "",
                        observations: scheduleReportInfo.pacemakers.observations ?? ""
                    });
                }

                if (scheduleReportInfo.conclusion) {
                    setConclusion({
                        value: scheduleReportInfo.conclusion,
                        label: scheduleReportInfo.conclusion_description
                    });
                }

                setSummaryConclusion(scheduleReportInfo.summary_conclusion);
                setHeparin(scheduleReportInfo.heparin);
                setHeparinQuantity(scheduleReportInfo.heparin_quantity);
                setBandageRightInguinal(scheduleReportInfo.bandage_right_inguinal);
                setBandageLeftInguinal(scheduleReportInfo.bandage_left_inguinal);
                setBandageJugular(scheduleReportInfo.bandage_jugular);
                setBandageSubclavian(scheduleReportInfo.bandage_subclavian);
                setBandageXiphoid(scheduleReportInfo.bandage_xiphoid);

                if (scheduleReportInfo.report_format) {
                    setReportFormat({
                        value: scheduleReportInfo.report_format,
                        label: scheduleReportInfo.report_format_description
                    });
                }

                if (scheduleReportInfo.print_hma) {
                    const printHmaOption = yesNoOptions.find(x => x.value === scheduleReportInfo.print_hma);
                    if (printHmaOption) {
                        setPrintHma(printHmaOption);
                    }
                }

                if (scheduleReportInfo.hma) {
                    setHma(scheduleReportInfo.hma);
                }

                setFinalReport(scheduleReportInfo.final_report);

                setMainDoctor({
                    value: scheduleReportInfo.main_doctor_id,
                    label: scheduleReportInfo.main_doctor_name
                });

                if (scheduleReportInfo.first_assist_doctor_id) {
                    setFirstAssistDoctor({
                        value: scheduleReportInfo.first_assist_doctor_id,
                        label: scheduleReportInfo.first_assist_doctor_name
                    });
                }

                if (scheduleReportInfo.second_assist_doctor_id) {
                    setSecondAssistDoctor({
                        value: scheduleReportInfo.second_assist_doctor_id,
                        label: scheduleReportInfo.second_assist_doctor_name
                    });
                }

                if (scheduleReportInfo.procedure_group_id) {
                    setProcedureGroup({
                        value: scheduleReportInfo.procedure_group_id,
                        label: scheduleReportInfo.procedure_group_name
                    });
                }

                if (scheduleReportInfo.procedure_type_id) {
                    setProcedureType({
                        value: scheduleReportInfo.procedure_type_id,
                        label: scheduleReportInfo.procedure_type_name
                    });
                }

                getComplications(id);
            }
        }

        setLoadingProcedureExecution(false);
    }

    async function handleChangeForwardDoctor(e) {
        setForwardDoctor(e);
        setForwardDoctorEmail("");

        const forwardDoctorInfo = await getDoctorById(e.value);

        if (forwardDoctorInfo) {
            if (forwardDoctorInfo.contacts) {
                setForwardDoctorEmail(forwardDoctorInfo.contacts[0].email ?? "");
            }
        }
    }

    async function handleChangeProcedure(e) {
        setProcedure(e);
        resetPacemakerStates();

        const procedureInfo = await getProcedureById(e.value);

        if (procedureInfo) {
            if (procedureInfo.code === "MP") {
                setIsPacemaker(true);
            }
        }
    }

    async function handleChangeProcedureGroup(e) {
        setLoadingMessage("Recuperando tipos de procedimentos...");
        setLoadingProcedureExecution(true);

        setProcedureTypesList([]);

        setProcedureGroup(e);
        await getProcedureTypes(e.value);

        setLoadingProcedureExecution(false);
    }

    async function handleChangeProcedureType(e) {
        setProcedureType(e);
        const procedureTypeInfo = await getProcedureTypeById(e.value);
        if (procedureTypeInfo) {
            setFinalReport(procedureTypeInfo.diagnosis);
        }
    }

    async function handleSaveReport(e) {
        e.preventDefault();

        saveReport();
    }

    async function saveReport() {
        setLoadingMessage("Gravando Registro...")
        setLoadingProcedureExecution(true);

        setInvalidForwardDoctor(false);
        setInvalidHealthPlan(false);
        setInvalidHospital(false);
        setInvalidExecutionDate(false);
        setInvalidProcedure(false);

        setInvalidMainDoctor(false);
        setInvalidReportType(false);
        setInvalidEefType(false);
        setPunctionInvalidFields([]);
        setInvalidConclusion(false);
        setInvalidSummaryConclusion(false);
        setInvalidHeparinQuantity(false);

        let hasError = false;

        if (!forwardDoctor) {
            setInvalidForwardDoctor(true);
            hasError = true;
        }

        if (!healthPlan) {
            setInvalidHealthPlan(true);
            hasError = true;
        }

        if (!hospital) {
            setInvalidHospital(true);
            hasError = true;
        }

        if (!procedureDate) {
            setInvalidExecutionDate(true);
            hasError = true;
        }

        if (!procedure) {
            setInvalidProcedure(true);
            hasError = true;
        }

        if (!reportType) {
            setInvalidReportType(true);
            hasError = true;
        } else if (reportType === "ABL") {
            if (!eefType) {
                setInvalidEefType(true);
                hasError = true;
            }
        }

        if (reportType !== "MP") {
            if (punctionInfo) {
                let invalidPunctionFields = [];

                if (punctionInfo.punction7f === "") {
                    invalidPunctionFields.push("punction7f");
                }

                if (punctionInfo.punction8f === "") {
                    invalidPunctionFields.push("punction8f");
                }

                if (invalidPunctionFields.length > 0) {
                    hasError = true;
                }

                setPunctionInvalidFields(invalidPunctionFields);
            }
        }

        if (reportType !== "MP") {
            if (!conclusion) {
                setInvalidConclusion(true);
                hasError = true;
            }

            if (heparinQuantity) {
                if (heparinQuantity < 5000 || heparinQuantity > 30000) {
                    setInvalidHeparinQuantity(true);
                    hasError = true;
                }
            }
        }

        if (!summaryConclusion) {
            setInvalidSummaryConclusion(true);
            hasError = true;
        }

        if (!mainDoctor) {
            setInvalidMainDoctor(true);
            hasError = true;
        }

        if (hasError) {
            swal("Existem campos obrigatórios que não foram preenchidos!", { icon: "warning", });
        } else {
            let report = {
                pacient_id: pacientId,
                pacient_name: name,
                pacient_gender: gender,
                pacient_birth_date: birthDate,
                forward_doctor_id: forwardDoctor.value,
                forward_doctor_name: forwardDoctor.label,
                forward_doctor_email: forwardDoctorEmail,
                health_plan_id: healthPlan.value,
                health_plan_name: healthPlan.label,
                hospital_id: hospital.value,
                hospital_name: hospital.label,
                execution_date: procedureDate,
                eef_number: eefNumber,
                procedure_id: procedure.value,
                procedure_name: procedure.label,
                pre_diagnosis: preDiagnosis,
                report_type: reportType,
                eef_type: eefType,
                anesthesia_type: anesthesiaType,
                punction_1pfd: punctionInfo.punction1pfd,
                punction_2pfd: punctionInfo.punction2pfd,
                punction_3pfd: punctionInfo.punction3pfd,
                punction_1pfe: punctionInfo.punction1pfe,
                punction_1pj: punctionInfo.punction1pj,
                punction_1ps: punctionInfo.punction1ps,
                punction_1paf: punctionInfo.punction1paf,
                punction_1pe: punctionInfo.punction1pe,
                punction_1pt: punctionInfo.punction1pt,
                punction_2pt: punctionInfo.punction2pt,
                punction_1blc: punctionInfo.punction1blc,
                punction_2blc: punctionInfo.punction2blc,
                punction_1blev: punctionInfo.punction1blev,
                punction_1blea: punctionInfo.punction1blea,
                punction_7f: punctionInfo.punction7f,
                punction_8f: punctionInfo.punction8f,
                catheter_quad: materialInfo.catheterQuad,
                catheter_deca: materialInfo.catheterDeca,
                catheter_conv_4mm: materialInfo.catheterConv4mm,
                catheter_conv_8mm: materialInfo.catheterConv8mm,
                catheter_irri_contact: materialInfo.catheterIrriContact,
                catheter_irri_energy: materialInfo.catheterIrriEnergy,
                catheter_irri_electro: materialInfo.catheterIrriElectro,
                catheter_pumpKit: materialInfo.catheterPumpKit,
                catheter_penta: materialInfo.catheterPenta,
                catheter_octa: materialInfo.catheterOcta,
                catheter_grid: materialInfo.catheterGrid,
                bioimpedance_board: materialInfo.bioimpedanceBoard,
                fa_cryoablation_kit: materialInfo.faCryoablationKit,
                focal_cryoablation_kit: materialInfo.focalCryoablationKit,
                brokenbrought_needle: materialInfo.brokenbroughtNeedle,
                intracardiac_contrast: materialInfo.intracardiacContrast,
                echocardiogram_catheter: materialInfo.echocardiogramCatheter,
                oroesophageal_thermometer: materialInfo.oroesophagealThermometer,
                pam: materialInfo.pam,
                bladder_catheter: materialInfo.bladderCatheter,
                punction_needle: materialInfo.punctionNeedle,
                saline_solution: materialInfo.salineSolution,
                ev_adenosine: materialInfo.evAdenosine,
                ablation_type: ablationType?.value ?? "",
                ablation_description: ablationType?.label ?? "",
                basal_rhythm: eefInfo.basalRhythm?.value ?? "",
                basal_rhythm_description: eefInfo.basalRhythm?.label ?? "",
                other_basal_rhythm: eefInfo.otherBasalRhythm,
                ventricular_rate: eefInfo.ventricularRate,
                atrial_rate: eefInfo.atrialRate,
                pr_interval: eefInfo.prInterval,
                qtc_interval: eefInfo.qtcInterval,
                ecg_report: eefInfo.ecgReport?.map(ecgReportItem => {
                    return {
                        ecg_id: ecgReportItem.value,
                        ecg_description: ecgReportItem.label
                    }
                }),
                ah_interval: eefInfo.ahInterval,
                hv_interval: eefInfo.hvInterval,
                qrs_interval: eefInfo.qrsInterval,
                sinus_node_recovery: eefInfo.sinusNodeRecovery,
                bav: eefInfo.bav,
                atriovent_period_anterograde: eefInfo.atrioventPeriodAnterograde,
                atriovent_period_fast: eefInfo.atrioventPeriodFast,
                atriovent_period_slow: eefInfo.atrioventPeriodSlow,
                bva: eefInfo.bva,
                atriovent_period_retrograde: eefInfo.atrioventPeriodRetrograde,
                atrium_period: eefInfo.atriumPeriod,
                ventricle_period: eefInfo.ventriclePeriod,
                accessory_path: eefInfo.accessoryPath?.value ?? "",
                accessory_path_description: eefInfo.accessoryPath?.label ?? "",
                accessory_path_anterograde: eefInfo.accessoryPathAnterograde,
                accessory_path_retrograde: eefInfo.accessoryPathRetrograde,
                induced_tachycardia: eefInfo.inducedTachycardia?.value ?? "",
                induced_tachycardia_description: eefInfo.inducedTachycardia?.label ?? "",
                common_trn_fast_slow: eefInfo.commonTrnFastSlow,
                common_trn_fast_slow_place: eefInfo.commonTrnFastSlowPlace?.value ?? "",
                common_trn_fast_slow_place_description: eefInfo.commonTrnFastSlowPlace?.label ?? "",
                atypical_trn_slow_slow: eefInfo.atypicalTrnSlowSlow,
                atypical_trn_slow_slow_place: eefInfo.atypicalTrnSlowSlowPlace?.value ?? "",
                atypical_trn_slow_slow_place_description: eefInfo.atypicalTrnSlowSlowPlace?.label ?? "",
                atypical_trn_fast_slow: eefInfo.atypicalTrnFastSlow,
                atypical_trn_fast_slow_place: eefInfo.atypicalTrnFastSlowPlace?.value ?? "",
                atypical_trn_fast_slow_place_description: eefInfo.atypicalTrnFastSlowPlace?.label ?? "",
                tva_common_shown: eefInfo.tvaCommonShown,
                tva_common_shown_bav_anterograde: eefInfo.tvaCommonShownBavAnterograde,
                tva_common_shown_bav_retrograde: eefInfo.tvaCommonShownBavRetrograde,
                tva_common_shown_anterograde_period: eefInfo.tvaCommonShownAnterogradePeriod,
                tva_common_shown_retrograde_period: eefInfo.tvaCommonShownRetrogradePeriod,
                tva_atypical_shown: eefInfo.tvaAtypicalShown,
                tva_atypical_shown_atrium_ventricular: eefInfo.tvaAtypicalShownAtriumVentricular,
                tva_atypical_shown_atrium_fascicular: eefInfo.tvaAtypicalShownAtriumFascicular,
                tva_atypical_shown_node_ventricular: eefInfo.tvaAtypicalShownNodeVentricular,
                tva_atypical_shown_node_fascicular: eefInfo.tvaAtypicalShownNodeFascicular,
                tva_atypical_shown_fascicle_ventricular: eefInfo.tvaAtypicalShownFascicleVentricular,
                tva_common_hidden: eefInfo.tvaCommonHidden,
                tva_common_hidden_bva: eefInfo.tvaCommonHiddenBva,
                tva_common_hidden_retrograde_period: eefInfo.tvaCommonHiddenRetrogradePeriod,
                tva_atypical_hidden: eefInfo.tvaAtypicalHidden,
                tva_atypical_hidden_bva: eefInfo.tvaAtypicalHiddenBva,
                tva_atypical_hidden_retrograde_period: eefInfo.tvaAtypicalHiddenRetrogradePeriod,
                ta_automatic: eefInfo.taAutomatic,
                ta_reentrant: eefInfo.taReentrant,
                ta_triggered: eefInfo.taTriggered,
                fla_common_anti_clockwise: eefInfo.flaCommonAntiClockwise,
                fla_common_clockwise: eefInfo.flaCommonClockwise,
                fla_atypical_right: eefInfo.flaAtypicalRight,
                fla_atypical_left: eefInfo.flaAtypicalLeft,
                tv_normal: eefInfo.tvNormal,
                tv_ischemic: eefInfo.tvIschemic,
                tv_chagastic: eefInfo.tvChagastic,
                tv_other: eefInfo.tvOther,
                nat_escape: eefInfo.natEscape,
                nat_bpm: eefInfo.natBpm,
                nat_no_escape: eefInfo.natNoEscape,
                fa_ostium: eefInfo.faOstium,
                fa_left_box: eefInfo.faLeftBox,
                fa_earpiece: eefInfo.faEarpiece,
                fa_left_line: eefInfo.faLeftLine,
                fa_istmo: eefInfo.faIstmo,
                fa_right_line: eefInfo.faRightLine,
                out_ta: eefInfo.outTa,
                out_fla: eefInfo.outFla,
                out_trn: eefInfo.outTrn,
                out_tva: eefInfo.outTva,
                out_tv: eefInfo.outTv,
                out_ea: eefInfo.outEa,
                out_ev: eefInfo.outEv,
                out_other: eefInfo.outOther,
                out_other_text: eefInfo.outOtherText,
                out_comment: eefInfo.outComment,
                tachycardia_mode: eefInfo.tachycardiaMode,
                tachycardia_cycle: eefInfo.tachycardiaCycle,
                tachycardia_ecg_report: eefInfo.tachycardiaEcgReport,
                ablations: ablationList.map(ablation => {
                    console.log(ablation)
                    return {
                        sequence: ablation.sequence,
                        ablation_type: ablation.ablationType,
                        ablation_description: ablation.ablationDescription,
                        punction_info: {
                            punction_1pfd: ablation.punctionInfo.punction1pfd,
                            punction_2pfd: ablation.punctionInfo.punction2pfd,
                            punction_3pfd: ablation.punctionInfo.punction3pfd,
                            punction_1pfe: ablation.punctionInfo.punction1pfe,
                            punction_1pj: ablation.punctionInfo.punction1pj,
                            punction_1ps: ablation.punctionInfo.punction1ps,
                            punction_1paf: ablation.punctionInfo.punction1paf,
                            punction_1pe: ablation.punctionInfo.punction1pe,
                            punction_1pt: ablation.punctionInfo.punction1pt,
                            punction_2pt: ablation.punctionInfo.punction2pt,
                            punction_1blc: ablation.punctionInfo.punction1blc,
                            punction_2blc: ablation.punctionInfo.punction2blc,
                            punction_1blev: ablation.punctionInfo.punction1blev,
                            punction_1blea: ablation.punctionInfo.punction1blea,
                            punction_7f: ablation.punctionInfo.punction7f,
                            punction_8f: ablation.punctionInfo.punction8f
                        },
                        material_info: {
                            catheter_quad: ablation.materialInfo.catheterQuad,
                            catheter_deca: ablation.materialInfo.catheterDeca,
                            catheter_conv_4mm: ablation.materialInfo.catheterConv4mm,
                            catheter_conv_8mm: ablation.materialInfo.catheterConv8mm,
                            catheter_irri_contact: ablation.materialInfo.catheterIrriContact,
                            catheter_irri_energy: ablation.materialInfo.catheterIrriEnergy,
                            catheter_irri_electro: ablation.materialInfo.catheterIrriElectro,
                            catheter_pumpKit: ablation.materialInfo.catheterPumpKit,
                            catheter_penta: ablation.materialInfo.catheterPenta,
                            catheter_octa: ablation.materialInfo.catheterOcta,
                            catheter_grid: ablation.materialInfo.catheterGrid,
                            bioimpedance_board: ablation.materialInfo.bioimpedanceBoard,
                            fa_cryoablation_kit: ablation.materialInfo.faCryoablationKit,
                            focal_cryoablation_kit: ablation.materialInfo.focalCryoablationKit,
                            brokenbrought_needle: ablation.materialInfo.brokenbroughtNeedle,
                            intracardiac_contrast: ablation.materialInfo.intracardiacContrast,
                            echocardiogram_catheter: ablation.materialInfo.echocardiogramCatheter,
                            oroesophageal_thermometer: ablation.materialInfo.oroesophagealThermometer,
                            pam: ablation.materialInfo.pam,
                            bladder_catheter: ablation.materialInfo.bladderCatheter,
                            punction_needle: ablation.materialInfo.punctionNeedle,
                            saline_solution: ablation.materialInfo.salineSolution,
                            ev_adenosine: ablation.materialInfo.evAdenosine
                        },
                        eef_info: {
                            basal_rhythm: ablation.eefInfo.basalRhythm?.value,
                            basal_rhythm_description: ablation.eefInfo.basalRhythm?.label,
                            other_basal_rhythm: ablation.eefInfo?.otherBasalRhythm,
                            ventricular_rate: ablation.eefInfo.ventricularRate,
                            atrial_rate: ablation.eefInfo.atrialRate,
                            pr_interval: ablation.eefInfo.prInterval,
                            qtc_interval: ablation.eefInfo.qtcInterval,
                            ecg_report: ablation.eefInfo.ecgReport?.map(ecgReportItem => {
                                return {
                                    ecg_id: ecgReportItem.ecgId,
                                    ecg_description: ecgReportItem.ecgDescription
                                }
                            }),
                            ah_interval: ablation.eefInfo.ahInterval,
                            hv_interval: ablation.eefInfo.hvInterval,
                            qrs_interval: ablation.eefInfo.qrsInterval,
                            sinus_node_recovery: ablation.eefInfo.sinusNodeRecovery,
                            bav: ablation.eefInfo.bav,
                            atriovent_period_anterograde: ablation.eefInfo.atrioventPeriodAnterograde,
                            atriovent_period_fast: ablation.eefInfo.atrioventPeriodFast,
                            atriovent_period_slow: ablation.eefInfo.atrioventPeriodSlow,
                            bva: ablation.eefInfo.bva,
                            atriovent_period_retrograde: ablation.eefInfo.atrioventPeriodRetrograde,
                            atrium_period: ablation.eefInfo.atriumPeriod,
                            ventricle_period: ablation.eefInfo.ventriclePeriod,
                            accessory_path: ablation.eefInfo.accessoryPath?.value,
                            accessory_path_description: ablation.eefInfo.accessoryPath?.label,
                            accessory_path_anterograde: ablation.eefInfo.accessoryPathAnterograde,
                            accessory_path_retrograde: ablation.eefInfo.accessoryPathRetrograde,
                            induced_tachycardia: ablation.eefInfo.inducedTachycardia?.value,
                            induced_tachycardia_description: ablation.eefInfo.inducedTachycardia?.label,
                            common_trn_fast_slow: ablation.eefInfo.commonTrnFastSlow,
                            common_trn_fast_slow_place: ablation.eefInfo.commonTrnFastSlowPlace?.value,
                            common_trn_fast_slow_place_description: ablation.eefInfo.commonTrnFastSlowPlace?.label,
                            atypical_trn_slow_slow: ablation.eefInfo.atypicalTrnSlowSlow,
                            atypical_trn_slow_slow_place: ablation.eefInfo.atypicalTrnSlowSlowPlace?.value,
                            atypical_trn_slow_slow_place_description: ablation.eefInfo.atypicalTrnSlowSlowPlace?.label,
                            atypical_trn_fast_slow: ablation.eefInfo.atypicalTrnFastSlow,
                            atypical_trn_fast_slow_place: ablation.eefInfo.atypicalTrnFastSlowPlace?.value,
                            atypical_trn_fast_slow_place_description: ablation.eefInfo.atypicalTrnFastSlowPlace?.label,
                            tva_common_shown: ablation.eefInfo.tvaCommonShown,
                            tva_common_shown_bav_anterograde: ablation.eefInfo.tvaCommonShownBavAnterograde,
                            tva_common_shown_bav_retrograde: ablation.eefInfo.tvaCommonShownBavRetrograde,
                            tva_common_shown_anterograde_period: ablation.eefInfo.tvaCommonShownAnterogradePeriod,
                            tva_common_shown_retrograde_period: ablation.eefInfo.tvaCommonShownRetrogradePeriod,
                            tva_atypical_shown: ablation.eefInfo.tvaAtypicalShown,
                            tva_atypical_shown_atrium_ventricular: ablation.eefInfo.tvaAtypicalShownAtriumVentricular,
                            tva_atypical_shown_atrium_fascicular: ablation.eefInfo.tvaAtypicalShownAtriumFascicular,
                            tva_atypical_shown_node_ventricular: ablation.eefInfo.tvaAtypicalShownNodeVentricular,
                            tva_atypical_shown_node_fascicular: ablation.eefInfo.tvaAtypicalShownNodeFascicular,
                            tva_atypical_shown_fascicle_ventricular: ablation.eefInfo.tvaAtypicalShownFascicleVentricular,
                            tva_common_hidden: ablation.eefInfo.tvaCommonHidden,
                            tva_common_hidden_bva: ablation.eefInfo.tvaCommonHiddenBva,
                            tva_common_hidden_retrograde_period: ablation.eefInfo.tvaCommonHiddenRetrogradePeriod,
                            tva_atypical_hidden: ablation.eefInfo.tvaAtypicalHidden,
                            tva_atypical_hidden_bva: ablation.eefInfo.tvaAtypicalHiddenBva,
                            tva_atypical_hidden_retrograde_period: ablation.eefInfo.tvaAtypicalHiddenRetrogradePeriod,
                            ta_automatic: ablation.eefInfo.taAutomatic,
                            ta_reentrant: ablation.eefInfo.taReentrant,
                            ta_triggered: ablation.eefInfo.taTriggered,
                            fla_common_anti_clockwise: ablation.eefInfo.flaCommonAntiClockwise,
                            fla_common_clockwise: ablation.eefInfo.flaCommonClockwise,
                            fla_atypical_right: ablation.eefInfo.flaAtypicalRight,
                            fla_atypical_left: ablation.eefInfo.flaAtypicalLeft,
                            tv_normal: ablation.eefInfo.tvNormal,
                            tv_ischemic: ablation.eefInfo.tvIschemic,
                            tv_chagastic: ablation.eefInfo.tvChagastic,
                            tv_other: ablation.eefInfo.tvOther,
                            nat_escape: ablation.eefInfo.natEscape,
                            nat_bpm: ablation.eefInfo.natBpm,
                            nat_no_escape: ablation.eefInfo.natNoEscape,
                            fa_ostium: ablation.eefInfo.faOstium,
                            fa_left_box: ablation.eefInfo.faLeftBox,
                            fa_earpiece: ablation.eefInfo.faEarpiece,
                            fa_left_line: ablation.eefInfo.faLeftLine,
                            fa_istmo: ablation.eefInfo.faIstmo,
                            fa_right_line: ablation.eefInfo.faRightLine,
                            out_ta: ablation.eefInfo.outTa,
                            out_fla: ablation.eefInfo.outFla,
                            out_trn: ablation.eefInfo.outTrn,
                            out_tva: ablation.eefInfo.outTva,
                            out_tv: ablation.eefInfo.outTv,
                            out_ea: ablation.eefInfo.outEa,
                            out_ev: ablation.eefInfo.outEv,
                            out_other: ablation.eefInfo.outOther,
                            out_other_text: ablation.eefInfo.outOtherText,
                            out_comment: ablation.eefInfo.outComment,
                            tachycardia_mode: ablation.eefInfo.tachycardiaMode,
                            tachycardia_cycle: ablation.eefInfo.tachycardiaCycle,
                            tachycardia_ecg_report: ablation.eefInfo.tachycardiaEcgReport,
                        },
                        atriovent_relation: ablation.atrioventRelation?.value ?? "",
                        atriovent_relation_description: ablation.atrioventRelation?.label ?? "",
                        hidden_retraining: ablation.hiddenRetraining,
                        hidden_retraining_place: ablation.hiddenRetrainingPlace,
                        map_activation: ablation.mapActivation,
                        map_pacemapping: ablation.mapPacemapping,
                        map_electro: ablation.mapElectro,
                        map_sub_type: ablation.mapSubType?.value ?? "",
                        map_sub_type_description: ablation.mapSubType?.label ?? "",
                        map_right_atrium: ablation.mapRightAtrium,
                        map_left_atrium: ablation.mapLeftAtrium,
                        map_right_ventricle: ablation.mapRightVentricle,
                        map_left_ventricle: ablation.mapLeftVentricle,
                        map_epicardial_space: ablation.mapEpicardialSpace,
                        map_atriovent_ring: ablation.mapAtrioventRing,
                        map_atriovent_node: ablation.mapAtrioventNode,
                        sinus_rhythm: ablation.sinusRhythm,
                        induced_tachycardia: ablation.inducedTachycardia,
                        ventricular_pacing: ablation.ventricularPacing,
                        atrium_pacing: ablation.atriumPacing,
                        junctional_rhythm: ablation.junctionalRhythm,
                        exhaust: ablation.exhaust,
                        success_criterion: ablation.successCriterion?.value ?? "",
                        success_criterion_description: ablation.successCriterion?.label ?? "",
                        venography: ablation.venography,
                        total_rx_time: ablation.totalRxTime,
                        total_procedure_time: ablation.totalProcedureTime,
                        total_lesion_time: ablation.totalLesionTime,
                        accessory_path_block: ablation.accessoryPathBlock,
                        interruption: ablation.interruption,
                        tachycardia_reinduction: ablation.tachycardiaReinduction,
                        trn_jump_persistence: ablation.trnJumpPersistence,
                        trn_eco_persistence: ablation.trnEcoPersistence,
                        flutter_block: ablation.flutterBlock,
                        isuprel: ablation.isuprel,
                        adenosine: ablation.adenosine,
                        conclusion: ablation.conclusion,
                        conclusion_description: ablation.conclusionDescription,
                        summary_conclusion: ablation.summaryConclusion
                    }
                }),
                pacemakers: {
                    implant_option: pacemakerInfo.implant?.value ?? "",
                    implant_description: pacemakerInfo.implant?.label ?? "",
                    implant_date: pacemakerInfo.implantDate,
                    supplier_id: pacemakerInfo.supplier?.value ?? "",
                    supplier_name: pacemakerInfo.supplier?.label ?? "",
                    generator: pacemakerInfo.generator,
                    place_option: pacemakerInfo.place?.value ?? "",
                    place_description: pacemakerInfo.place?.label ?? "",
                    defibrillation_option: pacemakerInfo.defibrillation?.value ?? "",
                    defibrillation_description: pacemakerInfo.defibrillation?.label ?? "",
                    result_option: pacemakerInfo.result?.value ?? "",
                    result_description: pacemakerInfo.result?.label ?? "",
                    complications: pacemakerInfo.complications,
                    observations: pacemakerInfo.observations
                },
                conclusion: conclusion?.value ?? "",
                conclusion_description: conclusion?.label ?? "",
                summary_conclusion: summaryConclusion,
                heparin,
                heparin_quantity: heparinQuantity,
                bandage_right_inguinal: bandageRightInguinal,
                bandage_left_inguinal: bandageLeftInguinal,
                bandage_jugular: bandageJugular,
                bandage_subclavian: bandageSubclavian,
                bandage_xiphoid: bandageXiphoid,
                report_format: reportFormat?.value ?? "",
                report_format_description: reportFormat?.label ?? "",
                print_hma: printHma.value,
                hma,
                final_report: finalReport,
                logs: [...logs]
            };

            const mainDoctorInfo = fullDoctorsList.find(x => x._id === mainDoctor.value);
            if (mainDoctorInfo) {
                report.main_doctor_id = mainDoctor.value;
                report.main_doctor_name = mainDoctor.label;
                report.main_doctor_crm = mainDoctorInfo.crm;
            }

            if (firstAssistDoctor) {
                report.first_assist_doctor_id = firstAssistDoctor.value;
                report.first_assist_doctor_name = firstAssistDoctor.label;

                const firstAssistDoctorInfo = fullDoctorsList.find(x => x._id === firstAssistDoctor.value);
                if (firstAssistDoctorInfo) {
                    report.first_assist_doctor_crm = firstAssistDoctorInfo.crm;
                }
            }

            if (secondAssistDoctor) {
                report.second_assist_doctor_id = secondAssistDoctor.value;
                report.second_assist_doctor_name = secondAssistDoctor.label;

                const secondAssistDoctorInfo = fullDoctorsList.find(x => x._id === secondAssistDoctor.value);
                if (secondAssistDoctorInfo) {
                    report.second_assist_doctor_crm = secondAssistDoctorInfo.crm;
                }
            }

            if (procedureGroup) {
                report.procedure_group_id = procedureGroup.value;
                report.procedure_group_name = procedureGroup.label;
            }

            if (procedureType) {
                report.procedure_type_id = procedureType.value;
                report.procedure_type_name = procedureType.label;
            }

            let scheduleInfo = undefined;
            if (scheduleId) {
                scheduleInfo = await getScheduleById(scheduleId);
            }

            if (!isUpdate) {
                report.logs.push({
                    status: "add",
                    comment: "Inclusão do laudo",
                    user: userInfo.name,
                    date: dayjs().toDate()
                });

                let scheduleUpdate = {
                    reports: (scheduleInfo ? [...scheduleInfo.reports] : []),
                    logs: (scheduleInfo ? [...scheduleInfo.logs] : [])
                }

                scheduleUpdate.reports.push(report);

                if (scheduleInfo.status === "sch") {
                    scheduleUpdate.status = "done";

                    scheduleUpdate.logs.push({
                        status: "done",
                        comment: "Procedimento realizado",
                        user: userInfo.name,
                        date: dayjs().toDate()
                    })
                }

                try {
                    await api.put(`/schedules/${scheduleId}`, scheduleUpdate)
                        .then(response => {
                            if (response.status === 200) {
                                createLog({
                                    routine: "procedureExecution",
                                    action: "create",
                                    type: "success",
                                    message: `Laudo para ${report.pacient_name} incluído`,
                                    jsonOrigin: JSON.stringify(scheduleUpdate),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingProcedureExecution(false);
                                notifySuccess();
                                generateComplication();
                                generateOperational();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "procedureExecution",
                        action: "create",
                        type: "error",
                        message: `Laudo para ${report.pacient_name} não incluído`,
                        jsonOrigin: JSON.stringify(scheduleUpdate),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            } else {
                let scheduleUpdate = {
                    reports: [],
                    logs: (scheduleInfo ? [...scheduleInfo.logs] : [])
                }

                scheduleUpdate.logs.push({
                    status: "upd",
                    comment: "Laudo alterado",
                    user: userInfo.name,
                    date: dayjs().toDate()
                });

                report.logs.push({
                    status: "upd",
                    comment: "Laudo alterado",
                    user: userInfo.name,
                    date: dayjs().toDate()
                });

                scheduleUpdate.reports.push(report);

                try {
                    await api.put(`/schedules/${scheduleId}`, scheduleUpdate)
                        .then(response => {
                            if (response.status === 200) {
                                createLog({
                                    routine: "procedureExecution",
                                    action: "update",
                                    type: "success",
                                    message: `Laudo para ${report.pacient_name} alterado`,
                                    jsonOrigin: JSON.stringify(scheduleUpdate),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingProcedureExecution(false);
                                notifySuccess();
                                generateComplication();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "procedureExecution",
                        action: "update",
                        type: "error",
                        message: `Laudo para ${report.pacient_name} não alterado`,
                        jsonOrigin: JSON.stringify(scheduleUpdate),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            }
        }

        setLoadingProcedureExecution(false);
    }

    async function generateComplication() {
        if (hasComplication && complicationInfo) {
            const complicationData = {
                schedule_id: scheduleId,
                type_id: "2",
                type_name: "Complicações ablação",
                pacient_id: pacientId,
                pacient_name: name,
                birth_date: birthDate,
                procedure_date: procedureDate,
                exam_number: eefNumber,
                hospital_name: hospital.label,
                doctor_id: mainDoctor.value,
                doctor_name: mainDoctor.label,
                gender,
                final_diagnosis: complicationInfo.diagnosis,
                operator1_id: complicationInfo.operatorOne?.value ?? "",
                operator1_name: complicationInfo.operatorOne?.label ?? "",
                operator2_id: complicationInfo.operatorTwo?.value ?? "",
                operator2_name: complicationInfo.operatorTwo?.label ?? "",
                procedure_id: complicationInfo.procedures?.value ?? "",
                procedure_name: complicationInfo.procedures?.label ?? "",
                normal_ablation_id: complicationInfo.conventionalAblation?.value ?? "",
                normal_ablation_name: complicationInfo.conventionalAblation?.label ?? "",
                complex_ablation_id: complicationInfo.complexAblation?.value ?? "",
                complex_ablation_name: complicationInfo.complexAblation?.label ?? "",
                complex_ablation_other: complicationInfo.complexAblationOthers ?? "",
                cryoablation_id: complicationInfo.cryoablation?.value ?? "",
                cryoablation_name: complicationInfo.cryoablation?.label ?? "",
                cryoablation_other: complicationInfo.cryoablationOthers ?? "",
                pacemaker_id: complicationInfo.implant?.value ?? "",
                pacemaker_name: complicationInfo.implant?.label ?? "",
                eletrode_extraction_id: complicationInfo.electrodeExtraction?.value ?? "",
                eletrode_extraction_name: complicationInfo.electrodeExtraction?.label ?? "",
                aae_occlusion_id: complicationInfo.occlusion?.value ?? "",
                aae_occlusion_name: complicationInfo.occlusion?.label ?? "",
                material_id: complicationInfo.material?.value ?? "",
                material_name: complicationInfo.material?.label ?? "",
                material_other: complicationInfo.materialOthers ?? "",
                punches_id: complicationInfo.puncture?.value ?? "",
                punches_name: complicationInfo.puncture?.label ?? "",
                femoral_punches_id: complicationInfo.femoralPunctures?.value ?? "",
                femoral_punches_name: complicationInfo.femoralPunctures?.label ?? "",
                preprocedure_complications_id: complicationInfo.preProcedureComplications?.value ?? "",
                preprocedure_complications_name: complicationInfo.preProcedureComplications?.label ?? "",
                preprocedure_complications_description: complicationInfo.preProcedureComplicationsDesc ?? "",
                procedure_complications: [],
                procedure_complications_description: complicationInfo.complicationDesc ?? "",
                catheter_problem_id: complicationInfo.catheterProblems?.value ?? "",
                catheter_problem_name: complicationInfo.catheterProblems?.label ?? "",
                catheter_problem_description: complicationInfo.catheterProblemsDesc ?? "",
                sheath_problem_id: complicationInfo.sheathProblems?.value ?? "",
                sheath_problem_name: complicationInfo.sheathProblems?.label ?? "",
                sheath_problem_description: complicationInfo.sheathProblemsDesc ?? "",
                device_problems: [],
                device_problems_description: complicationInfo.devicesProblemsDesc ?? "",
                interference_solved_id: complicationInfo.interferenceSolved?.value ?? "",
                interference_solved_name: complicationInfo.interferenceSolved?.label ?? "",
                interference_description: complicationInfo.interferenceDescription ?? "",
                comments: complicationInfo.comments ?? "",
            }

            if (complicationInfo.complicationsDuringProcedure) {
                complicationData.procedure_complications = complicationInfo.complicationsDuringProcedure.map(procedureItem => {
                    return {
                        procedure_complications_id: procedureItem.value,
                        procedure_complications_name: procedureItem.label
                    };
                });
            }

            if (complicationInfo.devicesProblems) {
                complicationData.device_problems = complicationInfo.devicesProblems.map(deviceItem => {
                    return {
                        device_problem_id: deviceItem.value,
                        device_problem_name: deviceItem.label
                    }
                });
            }

            if (!complicationInfo.id) {
                try {
                    await api.post("/complications", complicationData)
                        .then(response => {
                            if (response.status === 200 || response.status === 201) {
                                createLog({
                                    routine: "complication",
                                    action: "create",
                                    type: "success",
                                    message: `Complicação para ${complicationData.pacient_name} incluído`,
                                    jsonOrigin: JSON.stringify(complicationData),
                                    jsonReturn: JSON.stringify(response)
                                });
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "complication",
                        action: "create",
                        type: "error",
                        message: `Complicação para ${complicationData.pacient_name} não incluído`,
                        jsonOrigin: JSON.stringify(complicationData),
                        jsonReturn: JSON.stringify(err)
                    });
                };
            } else {
                try {
                    await api.put(`/complications/${complicationInfo.id}`, complicationData)
                        .then(response => {
                            if (response.status === 200 || response.status === 201) {
                                createLog({
                                    routine: "complication",
                                    action: "update",
                                    type: "success",
                                    message: `Complicação para ${complicationData.pacient_name} alterada`,
                                    jsonOrigin: JSON.stringify(complicationData),
                                    jsonReturn: JSON.stringify(response)
                                });
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "complication",
                        action: "update",
                        type: "error",
                        message: `Complicação para ${complicationData.pacient_name} não alterada`,
                        jsonOrigin: JSON.stringify(complicationData),
                        jsonReturn: JSON.stringify(err)
                    });
                };
            }
        }
    }

    async function generateOperational() {
        if (scheduleId) {
            const scheduleInfo = await getScheduleById(scheduleId);

            if (scheduleInfo) {
                const operationalData = {
                    status: "rti",
                    pacient_id: scheduleInfo.pacient_id,
                    pacient_name: scheduleInfo.pacient_name,
                    document_id: documentId,
                    main_doctor_id: scheduleInfo.main_doctor_id,
                    main_doctor_name: scheduleInfo.main_doctor_name,
                    forward_doctor_id: scheduleInfo.forward_doctor_id,
                    forward_doctor_name: scheduleInfo.forward_doctor_name,
                    forward_doctor_lec: scheduleInfo.forward_doctor_lec,
                    health_plan_id: scheduleInfo.health_plan_id,
                    health_plan_name: scheduleInfo.health_plan_name,
                    health_plan_accommodation: scheduleInfo.health_plan_accommodation,
                    hospital_id: scheduleInfo.hospital_id,
                    hospital_name: scheduleInfo.hospital_name,
                    procedure_id: scheduleInfo.procedure_id,
                    procedure_name: scheduleInfo.procedure_name,
                    complexity: scheduleInfo.complexity,
                    supplier_id: scheduleInfo.supplier_id,
                    supplier_name: scheduleInfo.supplier_name,
                    schedule_id: scheduleId,
                    schedule_date: scheduleInfo.schedule_date,
                    execution_date: procedureDate,
                    invoiceDate: null,
                    receipts: [],
                    logs: [{
                        status: "rti",
                        comment: "Procedimento realizado",
                        user: userInfo.name,
                        date: dayjs().toDate()
                    }]
                }

                api.post("/operationals", operationalData);
            }
        }
    }

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    setFullDoctorsList(response.data.doctors);

                    const doctorsReturned = response.data.doctors.map(doctor => {
                        return {
                            value: doctor._id,
                            label: doctor.name
                        }
                    });
                    setDoctorsList(doctorsReturned);
                }
            })
    }

    async function getDoctorById(id) {
        return new Promise(resolve => {
            api.get(`/doctors/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function getHealthPlans() {
        await api.get("/health-plans?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const healthPlansReturned = response.data.healthPlans.map(healthPlan => {
                        return {
                            value: healthPlan._id,
                            label: healthPlan.name
                        }
                    });
                    setHealthPlansList(healthPlansReturned);
                }
            })
    }

    async function getHospitals() {
        await api.get("/hospitals?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const hospitalsReturned = response.data.hospitals.map(hospital => {
                        return {
                            value: hospital._id,
                            label: hospital.name
                        }
                    });
                    setHospitalsList(hospitalsReturned);
                }
            })
    }

    async function getComplications(id) {
        await api.get(`/complications?offset=1&limit=1&scheduleId=${id}`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.total > 0) {
                        const complicationReturned = response.data.complications[0];

                        setHasComplication({
                            value: "YES",
                            label: "Sim"
                        });

                        let complicationsDuringProcedure = [];
                        if (complicationReturned.procedure_complications) {
                            complicationsDuringProcedure = complicationReturned.procedure_complications.map(procedureItem => {
                                return {
                                    value: procedureItem.procedure_complications_id,
                                    label: procedureItem.procedure_complications_name
                                };
                            });
                        }

                        let devicesProblems = [];
                        if (complicationReturned.device_problems) {
                            devicesProblems = complicationReturned.device_problems.map(deviceItem => {
                                return {
                                    value: deviceItem.device_problem_id,
                                    label: deviceItem.device_problem_name
                                }
                            });
                        }

                        setComplicationInfo({
                            id: complicationReturned._id,
                            diagnosis: complicationReturned.final_diagnosis,
                            operatorOne: complicationReturned.operator1_id ? { value: complicationReturned.operator1_id, label: complicationReturned.operator1_name } : null,
                            operatorTwo: complicationReturned.operator2_id ? { value: complicationReturned.operator2_id, label: complicationReturned.operator2_name } : null,
                            procedures: complicationReturned.procedure_id ? { value: complicationReturned.procedure_id, label: complicationReturned.procedure_name } : null,
                            conventionalAblation: complicationReturned.normal_ablation_id ? { value: complicationReturned.normal_ablation_id, label: complicationReturned.normal_ablation_name } : null,
                            complexAblation: complicationReturned.complex_ablation_id ? { value: complicationReturned.complex_ablation_id, label: complicationReturned.complex_ablation_name } : null,
                            complexAblationOthers: complicationReturned.complex_ablation_other ?? "",
                            cryoablation: complicationReturned.cryoablation_id ? { value: complicationReturned.cryoablation_id, label: complicationReturned.cryoablation_name } : null,
                            cryoablationOthers: complicationReturned.cryoablation_other ?? "",
                            implant: complicationReturned.pacemaker_id ? { value: complicationReturned.pacemaker_id, label: complicationReturned.pacemaker_name } : null,
                            electrodeExtraction: complicationReturned.eletrode_extraction_id ? { value: complicationReturned.eletrode_extraction_id, label: complicationReturned.eletrode_extraction_name } : null,
                            occlusion: complicationReturned.aae_occlusion_id ? { value: complicationReturned.aae_occlusion_id, label: complicationReturned.aae_occlusion_name } : null,
                            material: complicationReturned.material_id ? { value: complicationReturned.material_id, label: complicationReturned.material_name } : null,
                            materialOthers: complicationReturned.material_other ?? "",
                            puncture: complicationReturned.punches_id ? { value: complicationReturned.punches_id, label: complicationReturned.punches_name } : null,
                            femoralPunctures: complicationReturned.femoral_punches_id ? { value: complicationReturned.femoral_punches_id, label: complicationReturned.femoral_punches_name } : null,
                            preProcedureComplications: complicationReturned.preprocedure_complications_id ? { value: complicationReturned.preprocedure_complications_id, label: complicationReturned.preprocedure_complications_name } : null,
                            preProcedureComplicationsDesc: complicationReturned.preprocedure_complications_description ?? "",
                            complicationsDuringProcedure,
                            complicationDesc: complicationReturned.procedure_complications_description ?? "",
                            catheterProblems: complicationReturned.catheter_problem_id ? { value: complicationReturned.catheter_problem_id, label: complicationReturned.catheter_problem_name } : null,
                            catheterProblemsDesc: complicationReturned.catheter_problem_description ?? "",
                            sheathProblems: complicationReturned.sheath_problem_id ? { value: complicationReturned.sheath_problem_id, label: complicationReturned.sheath_problem_name } : null,
                            sheathProblemsDesc: complicationReturned.sheath_problem_description ?? "",
                            devicesProblems,
                            devicesProblemsDesc: complicationReturned.device_problems_description ?? "",
                            interferenceSolved: complicationReturned.interference_solved_id ? { value: complicationReturned.interference_solved_id, label: complicationReturned.interference_solved_name } : null,
                            interferenceDescription: complicationReturned.interference_description ?? "",
                            comments: complicationReturned.comments ?? ""
                        });
                    }
                }
            })
    }

    async function getProcedures() {
        await api.get("/procedures?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const proceduresReturned = response.data.procedures.map(procedure => {
                        return {
                            value: procedure._id,
                            label: procedure.code + "-" + procedure.name
                        }
                    });
                    setProceduresList(proceduresReturned);
                }
            })
    }

    async function getProcedureById(id) {
        return new Promise(resolve => {
            api.get(`/procedures/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function getProcedureGroups() {
        await api.get("/procedure-groups?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const procedureGroupsReturned = response.data.procedureGroups.map(procedureGroup => {
                        return {
                            value: procedureGroup._id,
                            label: procedureGroup.name
                        }
                    });
                    setProcedureGroupsList(procedureGroupsReturned);
                }
            })
    }

    async function getProcedureTypes(groupId) {
        await api.get(`/procedure-types?status=true&offset=1&limit=1000&procedureGroupId=${groupId}`)
            .then(response => {
                if (response.status === 200) {
                    const procedureTypesReturned = response.data.procedureTypes.map(procedureType => {
                        return {
                            value: procedureType._id,
                            label: procedureType.name
                        }
                    });
                    setProcedureTypesList(procedureTypesReturned);
                }
            })
    }

    async function getProcedureTypeById(id) {
        return new Promise(resolve => {
            api.get(`/procedure-types/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        })
    }

    async function getPacient(id) {
        return new Promise(resolve => {
            api.get(`/pacients/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function getScheduleById(id) {
        return new Promise(resolve => {
            api.get(`/schedules/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function getScheduleByPacient(id) {
        return new Promise(resolve => {
            api.get(`/schedules?status=sch&pacientId=${id}`)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.total > 0) {
                            resolve(response.data.schedules[0]);
                        }
                    }
                })
        });
    }

    async function getAnamnesis(id) {
        return new Promise(resolve => {
            api.get(`/anamnesis?pacientId=${id}`)
                .then(response => {
                    if (response.status === 200) {
                        if (response.data.total > 0) {
                            resolve(response.data.anamnesis[0]);
                        } else {
                            resolve(undefined);
                        }
                    }
                })
        });
    }

    function notifySuccess() {
        toast.success(`✔️ Laudo ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setIsUpdate(false);
        setLoadingProcedureExecution(false);
        setLoadingMessage("Salvando Registro...");

        setPacientId(undefined);
        setName("");
        setDocumentId("");
        setGender("");
        setBirthDate(null);
        setAge("");

        setScheduleId(undefined);

        setForwardDoctor(null);
        setForwardDoctorEmail("");

        setHealthPlan(null);
        setHealthPlansList([]);
        setHospital(null);
        setProcedureDate(dayjs().toDate());
        setEefNumber("");
        setProcedure(null);
        setProceduresList([]);
        setPreDiagnosis("");

        setReportType(null);
        setEefType(null);
        setAnesthesiaType(null);

        setPunctionInfo({});
        setPunctionInvalidFields([]);

        setMaterialInfo({});
        setAblationType(null);
        setEefInfo({});
        setAblationList([]);

        setConclusion(null);
        setSummaryConclusion("");

        setHeparin(false);
        setHeparinQuantity("");
        setBandageRightInguinal(false);
        setBandageLeftInguinal(false);
        setBandageJugular(false);
        setBandageSubclavian(false);
        setBandageXiphoid(false);
        setReportFormat(null);
        setPrintHma({ value: "NO", label: "Não" });
        setFinalReport("");

        setHasComplication({ value: "NO", label: "Não" });
        setComplicationInfo({});

        setInvalidReportType(false);
        setInvalidEefType(false);
        setInvalidConclusion(false);
        setInvalidSummaryConclusion(false);
        setInvalidHeparinQuantity(false);
    }

    function resetPacemakerStates() {
        setIsPacemaker(false);
        setPacemakerInfo({});
    }

    function handleCancel() {
        resetFields();
        setModalBox(false);
    }

    function handleChangePunctionInfo(field, value) {
        const newPunctionInfo = { ...punctionInfo };
        newPunctionInfo[field] = value;
        setPunctionInfo({ ...newPunctionInfo });
    }

    function handleChangeMaterialInfo(field, value) {
        const newMaterialInfo = { ...materialInfo };
        newMaterialInfo[field] = value;
        setMaterialInfo({ ...newMaterialInfo });
    }

    function handleChangeEefInfo(field, value) {
        const newEefInfo = { ...eefInfo };
        newEefInfo[field] = value;
        setEefInfo({ ...newEefInfo });
    }

    function handleChangePacemakerInfo(field, value) {
        const newPacemakerInfo = { ...pacemakerInfo };
        newPacemakerInfo[field] = value;
        setPacemakerInfo({ ...newPacemakerInfo });
    }

    function handleChangeAblationInfo(newAblationList) {
        setAblationList(newAblationList);
    }

    function handleAblationHeaderInfo() {
        const ablationHeaderInfo = {
            ablationType,
            punctionInfo,
            materialInfo,
            eefInfo,
            conclusion,
            summaryConclusion
        }

        return ablationHeaderInfo;
    }

    function handleEditAblation(ablationItem) {
        if (ablationItem.ablationType) {
            setAblationType({ value: ablationItem.ablationType, label: ablationItem.ablationDescription });
        }

        if (ablationItem.punctionInfo) {
            setPunctionInfo({
                punction1pfd: ablationItem.punctionInfo.punction1pfd ?? false,
                punction2pfd: ablationItem.punctionInfo.punction2pfd ?? false,
                punction3pfd: ablationItem.punctionInfo.punction3pfd ?? false,
                punction1pfe: ablationItem.punctionInfo.punction1pfe ?? false,
                punction1pj: ablationItem.punctionInfo.punction1pj ?? false,
                punction1ps: ablationItem.punctionInfo.punction1ps ?? false,
                punction1paf: ablationItem.punctionInfo.punction1paf ?? false,
                punction1pe: ablationItem.punctionInfo.punction1pe ?? false,
                punction1pt: ablationItem.punctionInfo.punction1pt ?? false,
                punction2pt: ablationItem.punctionInfo.punction2pt ?? false,
                punction1blc: ablationItem.punctionInfo.punction1blc ?? false,
                punction2blc: ablationItem.punctionInfo.punction2blc ?? false,
                punction1blev: ablationItem.punctionInfo.punction1blev ?? false,
                punction1blea: ablationItem.punctionInfo.punction1blea ?? false,
                punction7f: ablationItem.punctionInfo.punction7f ?? "0",
                punction8f: ablationItem.punctionInfo.punction8f ?? "0"
            });
        }

        if (ablationItem.materialInfo) {
            setMaterialInfo({
                catheterQuad: ablationItem.materialInfo.catheterQuad ?? false,
                catheterDeca: ablationItem.materialInfo.catheterDeca ?? false,
                catheterConv4mm: ablationItem.materialInfo.catheterConv4mm ?? false,
                catheterConv8mm: ablationItem.materialInfo.catheterConv8mm ?? false,
                catheterIrriContact: ablationItem.materialInfo.catheterIrriContact ?? false,
                catheterIrriEnergy: ablationItem.materialInfo.catheterIrriEnergy ?? false,
                catheterIrriElectro: ablationItem.materialInfo.catheterIrriElectro ?? false,
                catheterPumpKit: ablationItem.materialInfo.catheterPumpKit ?? false,
                catheterPenta: ablationItem.materialInfo.catheterPenta ?? false,
                catheterOcta: ablationItem.materialInfo.catheterOcta ?? false,
                catheterGrid: ablationItem.materialInfo.catheterGrid ?? false,
                bioimpedanceBoard: ablationItem.materialInfo.bioimpedanceBoard ?? false,
                faCryoablationKit: ablationItem.materialInfo.faCryoablationKit ?? false,
                focalCryoablationKit: ablationItem.materialInfo.focalCryoablationKit ?? false,
                brokenbroughtNeedle: ablationItem.materialInfo.brokenbroughtNeedle ?? false,
                intracardiacContrast: ablationItem.materialInfo.intracardiacContrast ?? false,
                echocardiogramCatheter: ablationItem.materialInfo.echocardiogramCatheter ?? false,
                oroesophagealThermometer: ablationItem.materialInfo.oroesophagealThermometer ?? false,
                pam: ablationItem.materialInfo.pam ?? false,
                bladderCatheter: ablationItem.materialInfo.bladderCatheter ?? false,
                punctionNeedle: ablationItem.materialInfo.punctionNeedle ?? false,
                salineSolution: ablationItem.materialInfo.salineSolution ?? false,
                evAdenosine: ablationItem.materialInfo.evAdenosine ?? false,
            });
        }

        if (ablationItem.eefInfo) {
            setEefInfo({
                basalRhythm: ablationItem.eefInfo.basalRhythm ?? null,
                ventricularRate: ablationItem.eefInfo.ventricularRate ?? "",
                atrialRate: ablationItem.eefInfo.atrialRate ?? "",
                prInterval: ablationItem.eefInfo.prInterval ?? "",
                qtcInterval: ablationItem.eefInfo.qtcInterval ?? "",
                ecgReport: ablationItem.eefInfo.ecgReport?.map(ecgReportItem => {
                    return {
                        value: ecgReportItem.ecgId,
                        label: ecgReportItem.ecgDescription
                    }
                }),
                ahInterval: ablationItem.eefInfo.ahInterval ?? "",
                hvInterval: ablationItem.eefInfo.hvInterval ?? "",
                qrsInterval: ablationItem.eefInfo.qrsInterval ?? "",
                sinusNodeRecovery: ablationItem.eefInfo.sinusNodeRecovery ?? "",
                bav: ablationItem.eefInfo.bav ?? "",
                atrioventPeriodAnterograde: ablationItem.eefInfo.atrioventPeriodAnterograde ?? "",
                atrioventPeriodFast: ablationItem.eefInfo.atrioventPeriodFast ?? "",
                atrioventPeriodSlow: ablationItem.eefInfo.atrioventPeriodSlow ?? "",
                bva: ablationItem.eefInfo.bva ?? "",
                atrioventPeriodRetrograde: ablationItem.eefInfo.atrioventPeriodRetrograde ?? "",
                atriumPeriod: ablationItem.eefInfo.atriumPeriod ?? "",
                ventriclePeriod: ablationItem.eefInfo.ventriclePeriod ?? "",
                accessoryPath: ablationItem.eefInfo.accessoryPath ? { value: ablationItem.eefInfo.accessoryPath, label: ablationItem.eefInfo.accessoryPathDescription } : null,
                accessoryPathAnterograde: ablationItem.eefInfo.accessoryPathAnterograde ?? "",
                accessoryPathRetrograde: ablationItem.eefInfo.accessoryPathRetrograde ?? "",
                inducedTachycardia: ablationItem.eefInfo.inducedTachycardia ? { value: ablationItem.eefInfo.inducedTachycardia, label: ablationItem.eefInfo.inducedTachycardiaDescription } : null,
                commonTrnFastSlow: ablationItem.eefInfo.commonTrnFastSlow ?? false,
                commonTrnFastSlowPlace: ablationItem.eefInfo.commonTrnFastSlowPlace ? { value: ablationItem.eefInfo.commonTrnFastSlowPlace, label: ablationItem.eefInfo.commonTrnFastSlowPlaceDescription } : null,
                atypicalTrnSlowSlow: ablationItem.eefInfo.atypicalTrnSlowSlow ?? false,
                atypicalTrnSlowSlowPlace: ablationItem.eefInfo.atypicalTrnSlowSlowPlace ? { value: ablationItem.eefInfo.atypicalTrnSlowSlowPlace, label: ablationItem.eefInfo.atypicalTrnSlowSlowPlaceDescription } : null,
                atypicalTrnFastSlow: ablationItem.eefInfo.atypicalTrnFastSlow ?? false,
                atypicalTrnFastSlowPlace: ablationItem.eefInfo.atypicalTrnFastSlowPlace ? { value: ablationItem.eefInfo.atypicalTrnFastSlowPlace, label: ablationItem.eefInfo.atypicalTrnFastSlowPlaceDescription } : null,
                tvaCommonShown: ablationItem.eefInfo.tvaCommonShown ?? false,
                tvaCommonShownBavAnterograde: ablationItem.eefInfo.tvaCommonShownBavAnterograde ?? "",
                tvaCommonShownBavRetrograde: ablationItem.eefInfo.tvaCommonShownBavRetrograde ?? "",
                tvaCommonShownAnterogradePeriod: ablationItem.eefInfo.tvaCommonShownAnterogradePeriod ?? "",
                tvaCommonShownRetrogradePeriod: ablationItem.eefInfo.tvaCommonShownRetrogradePeriod ?? "",
                tvaAtypicalShown: ablationItem.eefInfo.tvaAtypicalShown ?? false,
                tvaAtypicalShownAtriumVentricular: ablationItem.eefInfo.tvaAtypicalShownAtriumVentricular ?? false,
                tvaAtypicalShownAtriumFascicular: ablationItem.eefInfo.tvaAtypicalShownAtriumFascicular ?? false,
                tvaAtypicalShownNodeVentricular: ablationItem.eefInfo.tvaAtypicalShownNodeVentricular ?? false,
                tvaAtypicalShownNodeFascicular: ablationItem.eefInfo.tvaAtypicalShownNodeFascicular ?? false,
                tvaAtypicalShownFascicleVentricular: ablationItem.eefInfo.tvaAtypicalShownFascicleVentricular ?? false,
                tvaCommonHidden: ablationItem.eefInfo.tvaCommonHidden ?? false,
                tvaCommonHiddenBva: ablationItem.eefInfo.tvaCommonHiddenBva ?? "",
                tvaCommonHiddenRetrogradePeriod: ablationItem.eefInfo.tvaCommonHiddenRetrogradePeriod ?? "",
                tvaAtypicalHidden: ablationItem.eefInfo.tvaAtypicalHidden ?? false,
                tvaAtypicalHiddenBva: ablationItem.eefInfo.tvaAtypicalHiddenBva ?? "",
                tvaAtypicalHiddenRetrogradePeriod: ablationItem.eefInfo.tvaAtypicalHiddenRetrogradePeriod ?? "",
                taAutomatic: ablationItem.eefInfo.taAutomatic ?? false,
                taReentrant: ablationItem.eefInfo.taReentrant ?? false,
                taTriggered: ablationItem.eefInfo.taTriggered ?? false,
                flaCommonAntiClockwise: ablationItem.eefInfo.flaCommonAntiClockwise ?? false,
                flaCommonClockwise: ablationItem.eefInfo.flaCommonClockwise ?? false,
                flaAtypicalRight: ablationItem.eefInfo.flaAtypicalRight ?? false,
                flaAtypicalLeft: ablationItem.eefInfo.flaAtypicalLeft ?? false,
                tvNormal: ablationItem.eefInfo.tvNormal ?? false,
                tvIschemic: ablationItem.eefInfo.tvIschemic ?? false,
                tvChagastic: ablationItem.eefInfo.tvChagastic ?? false,
                tvOther: ablationItem.eefInfo.tvOther ?? false,
                natEscape: ablationItem.eefInfo.natEscape ?? false,
                natBpm: ablationItem.eefInfo.natBpm ?? "",
                natNoEscape: ablationItem.eefInfo.natNoEscape ?? false,
                faOstium: ablationItem.eefInfo.faOstium ?? false,
                faLeftBox: ablationItem.eefInfo.faLeftBox ?? false,
                faEarpiece: ablationItem.eefInfo.faEarpiece ?? false,
                faLeftLine: ablationItem.eefInfo.faLeftLine ?? false,
                faIstmo: ablationItem.eefInfo.faIstmo ?? false,
                faRightLine: ablationItem.eefInfo.faRightLine ?? false,
                outTa: ablationItem.eefInfo.outTa ?? false,
                outFla: ablationItem.eefInfo.outFla ?? false,
                outTrn: ablationItem.eefInfo.outTrn ?? false,
                outTva: ablationItem.eefInfo.outTva ?? false,
                outTv: ablationItem.eefInfo.outTv ?? false,
                outEa: ablationItem.eefInfo.outEa ?? false,
                outEv: ablationItem.eefInfo.outEv ?? false,
                outOther: ablationItem.eefInfo.outOther ?? false,
                outOtherText: ablationItem.eefInfo.outOtherText ?? "",
                outComment: ablationItem.eefInfo.outComment ?? "",
                tachycardiaMode: ablationItem.eefInfo.tachycardiaMode ?? "",
                tachycardiaCycle: ablationItem.eefInfo.tachycardiaCycle ?? "",
                tachycardiaEcgReport: ablationItem.eefInfo.tachycardiaEcgReport ?? "",
            });
        }

        if (ablationItem.conclusion) {
            setConclusion({
                value: ablationItem.conclusion,
                label: ablationItem.conclusionDescription
            });
        }

        if (ablationItem.summaryConclusion) {
            setSummaryConclusion(ablationItem.summaryConclusion);
        }
    }

    function handleChangeComplicationInfo(field, value) {
        const newComplicationInfo = { ...complicationInfo };
        newComplicationInfo[field] = value;
        setComplicationInfo({ ...newComplicationInfo });
    }

    function handleChangeReportFormat(e) {
        setReportFormat(e)

        const reportInfo = {
            format: e.value,
            hospital,
            procedureDate,
            eefNumber,
            procedure,
            preDiagnosis,
            reportType,
            eefType,
            anesthesiaType,
            punctionInfo,
            materialInfo,
            ablationType,
            eefInfo,
            ablationList,
            conclusion,
            summaryConclusion,
            heparin,
            heparinQuantity,
            bandageRightInguinal,
            bandageLeftInguinal,
            bandageJugular,
            bandageSubclavian,
            bandageXiphoid,
            mainDoctor,
            firstAssistDoctor,
            secondAssistDoctor,
            reportFormat
        };

        const reportText = generateReport(reportInfo);
        setFinalReport(reportText);
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>{`${(isUpdate ? "Alterar" : "Incluir")} Laudo`}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <div className="form-group mb-12 col-md-12">
                                        <div className="row">
                                            <div className="form-group mb-3 col-md-7">
                                                <label>Nome</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={name}
                                                    onChange={e => setName(e.target.value)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-md-2">
                                                <label>Sexo</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={gender}
                                                    onChange={e => setGender(e.target.value)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-md-3">
                                                <label>Idade</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={age}
                                                    onChange={e => setAge(e.target.value)}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-md-4">
                                                <label>Médico Encaminhador</label>
                                                <Select
                                                    className={`${(invalidForwardDoctor ? "form-control is-invalid" : "")}`}
                                                    defaultValue={forwardDoctor}
                                                    value={forwardDoctor}
                                                    onChange={(e) => handleChangeForwardDoctor(e)}
                                                    options={doctorsList}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            height: 45,
                                                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                        }),
                                                    }}
                                                    placeholder="Selecione..."
                                                />
                                                <DFormText hidden={!invalidForwardDoctor} color="danger">Campo obrigatório</DFormText>
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label>E-mail do Médico Encaminhador</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={forwardDoctorEmail}
                                                    onChange={e => setForwardDoctorEmail(e.target.value)}
                                                    disabled
                                                />
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label>Convênio</label>
                                                <Select
                                                    className={`${(invalidHealthPlan ? "form-control is-invalid" : "")}`}
                                                    defaultValue={healthPlan}
                                                    value={healthPlan}
                                                    onChange={(e) => setHealthPlan(e)}
                                                    options={healthPlansList}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            height: 45,
                                                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                        }),
                                                    }}
                                                    placeholder="Selecione..."
                                                />
                                                <DFormText hidden={!invalidHealthPlan} color="danger">Campo obrigatório</DFormText>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group mb-3 col-md-4">
                                                <label>Hospital</label>
                                                <Select
                                                    className={`${(invalidHospital ? "form-control is-invalid" : "")}`}
                                                    defaultValue={hospital}
                                                    value={hospital}
                                                    onChange={(e) => setHospital(e)}
                                                    options={hospitalsList}
                                                    styles={{
                                                        control: (baseStyles, state) => ({
                                                            ...baseStyles,
                                                            height: 45,
                                                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                        }),
                                                    }}
                                                    placeholder="Selecione..."
                                                />
                                                <DFormText hidden={!invalidHospital} color="danger">Campo obrigatório</DFormText>
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label>Data/Hora</label>
                                                <br />
                                                <DatePicker
                                                    selected={procedureDate}
                                                    onChange={(date) => setProcedureDate(date)}
                                                    locale="ptBR"
                                                    showTimeSelect
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    dateFormat="Pp"
                                                    className={`${(invalidExecutionDate ? "form-control is-invalid" : "form-control")}`}
                                                    timeCaption="Hora"
                                                />
                                                <DFormText hidden={!invalidExecutionDate} color="danger">Campo obrigatório</DFormText>
                                            </div>
                                            <div className="form-group mb-3 col-md-4">
                                                <label>Número EEF</label>
                                                <input
                                                    autoFocus
                                                    type="text"
                                                    className="form-control"
                                                    value={eefNumber}
                                                    onChange={e => setEefNumber(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Card>
                                        <Card.Header>
                                            <h4>Dados para o laudo</h4>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-4">
                                                    <DCard borderColor="light">
                                                        <DCardHeader color="light" textColor="dark">Procedimento Executado</DCardHeader>
                                                        <DCardBody>
                                                            <div className="row">
                                                                <div className="col-xl-12 col-lg-12">
                                                                    <DFormText hidden={!invalidReportType} color="danger">Selecione uma opção</DFormText>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="reportType"
                                                                                onClick={() => setReportType("EEF")}
                                                                                checked={reportType === "EEF"}
                                                                            /> EEF Simples
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="reportType"
                                                                                onClick={() => setReportType("ABL")}
                                                                                checked={reportType === "ABL"}
                                                                            /> Ablação
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="reportType"
                                                                                onClick={() => setReportType("MP")}
                                                                                checked={reportType === "MP"}
                                                                            /> Marcapasso
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </DCardBody>
                                                    </DCard>
                                                </div>
                                                <div
                                                    className="form-group mb-3 col-md-4"
                                                    hidden={reportType !== "ABL"}
                                                >
                                                    <DCard borderColor="light">
                                                        <DCardHeader color="light" textColor="dark">Procedimento de eletrofisiologia realizado</DCardHeader>
                                                        <DCardBody>
                                                            <div className="row">
                                                                <div className="col-xl-12 col-lg-12">
                                                                    <DFormText hidden={!invalidEefType} color="danger">Selecione uma opção</DFormText>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="eefType"
                                                                                onClick={() => setEefType("CON")}
                                                                                checked={eefType === "CON"}
                                                                            /> Ablação convencional
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="eefType"
                                                                                onClick={() => setEefType("COM")}
                                                                                checked={eefType === "COM"}
                                                                            /> Ablação complexa
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="eefType"
                                                                                onClick={() => setEefType("CRI")}
                                                                                checked={eefType === "CRI"}
                                                                            /> CrioAblação
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </DCardBody>
                                                    </DCard>
                                                </div>

                                                <div className="form-group mb-3 col-md-4">
                                                    <DCard borderColor="light">
                                                        <DCardHeader color="light" textColor="dark">Tipo de anestesia utilizada</DCardHeader>
                                                        <DCardBody>
                                                            <div className="row">
                                                                <div className="col-xl-12 col-lg-12">
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="anesthesiaType"
                                                                                onClick={() => setAnesthesiaType("LOC")}
                                                                                checked={anesthesiaType === "LOC"}
                                                                            /> Anestesia local
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="anesthesiaType"
                                                                                onClick={() => setAnesthesiaType("SED")}
                                                                                checked={anesthesiaType === "SED"}
                                                                            /> Sedação anestésica
                                                                        </label>
                                                                    </div>
                                                                    <div className="radio">
                                                                        <label>
                                                                            <input
                                                                                type="radio"
                                                                                name="anesthesiaType"
                                                                                onClick={() => setAnesthesiaType("GER")}
                                                                                checked={anesthesiaType === "GER"}
                                                                            /> Anestesia geral
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </DCardBody>
                                                    </DCard>
                                                </div>
                                            </div>

                                            <div className="row" hidden={reportType !== "MP"}>
                                                <div className="col-xl-12 col-lg-12">
                                                    <PacemakerCard
                                                        pacemakerInfoParm={pacemakerInfo}
                                                        onChangeInfo={handleChangePacemakerInfo}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row" hidden={reportType !== "EEF" && reportType !== "ABL"}>
                                                <PunctionCard
                                                    punctionInfoParm={punctionInfo}
                                                    onChangeInfo={handleChangePunctionInfo}
                                                    invalidFieldsParm={punctionInvalidFields}
                                                />
                                            </div>


                                            <div className="row" hidden={reportType !== "EEF" && reportType !== "ABL"}>
                                                <MaterialCard
                                                    materialInfoParm={materialInfo}
                                                    onChangeInfo={handleChangeMaterialInfo}
                                                />
                                            </div>

                                            <div className="row" hidden={reportType !== "ABL"}>
                                                <div className="form-group mb-3 col-md-12">
                                                    <DCard borderColor="light">
                                                        <DCardHeader color="light" textColor="dark">Ablação</DCardHeader>
                                                        <DCardBody>
                                                            <div className="row">
                                                                <div className="col-xl-12 col-lg-12">
                                                                    <div className="form-group mb-3 col-md-12">
                                                                        <label>Tipo de Ablação realizada</label>
                                                                        <Select
                                                                            isClearable
                                                                            defaultValue={ablationType}
                                                                            value={ablationType}
                                                                            onChange={e => setAblationType(e)}
                                                                            options={tachycardiaTypes}
                                                                            styles={{
                                                                                control: (baseStyles, state) => ({
                                                                                    ...baseStyles,
                                                                                    height: 45,
                                                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                                }),
                                                                            }}
                                                                            placeholder="Selecione..."
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </DCardBody>
                                                    </DCard>
                                                </div>
                                            </div>

                                            <div className="row" hidden={reportType !== "EEF" && reportType !== "ABL"}>
                                                <EefCard
                                                    eefInfoParm={eefInfo}
                                                    onChangeInfo={handleChangeEefInfo}
                                                />
                                            </div>

                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    <DCard borderColor="light">
                                                        <DCardHeader color="light" textColor="dark">Conclusões</DCardHeader>
                                                        <DCardBody>
                                                            <div className="row" hidden={reportType === "MP"}>
                                                                <div className="form-group mb-3 col-md-6">
                                                                    <label>Resultado Final</label>
                                                                    <Select
                                                                        className={`${(invalidConclusion ? "form-control is-invalid" : "")}`}
                                                                        defaultValue={conclusion}
                                                                        value={conclusion}
                                                                        onChange={e => setConclusion(e)}
                                                                        options={resultTypes}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                    <DFormText hidden={!invalidConclusion} color="danger">Campo obrigatório</DFormText>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Conclusão Descritiva do Procedimento</label>
                                                                    <textarea
                                                                        type="text"
                                                                        className={`form-control ${(invalidSummaryConclusion ? "form-control is-invalid" : "")}`}
                                                                        value={summaryConclusion}
                                                                        onChange={e => setSummaryConclusion(e.target.value)}
                                                                    />
                                                                    <DFormText hidden={!invalidSummaryConclusion} color="danger">Campo obrigatório</DFormText>
                                                                </div>
                                                            </div>
                                                        </DCardBody>
                                                    </DCard>
                                                </div>
                                            </div>

                                            <div className="row" hidden={reportType !== "ABL"}>
                                                <AblationCard
                                                    ablationListParm={ablationList}
                                                    onChange={handleChangeAblationInfo}
                                                    onGetHeaderInfo={handleAblationHeaderInfo}
                                                    onEditAblation={handleEditAblation}
                                                />
                                            </div>

                                            <div className="row">
                                                <div className="col-xl-12 col-lg-12">
                                                    <DCard borderColor="light">
                                                        <DCardHeader color="light" textColor="dark">Descrição de Procedimentos</DCardHeader>
                                                        <DCardBody>
                                                            <div className="row" hidden={reportType === "MP"}>
                                                                <div className="mb-2">
                                                                    <div
                                                                        className="form-group mb-2 mt-1 col-md-3"
                                                                        style={{ width: "auto" }}
                                                                    >
                                                                        <label className="form-check-label" htmlFor="heparin">
                                                                            Uso de Heparina durante todo o procedimento:
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-2 mt-1 col-md-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input ms-5"
                                                                            id="heparin"
                                                                            checked={heparin}
                                                                            onChange={() => setHeparin(!heparin)}
                                                                        />
                                                                        <label className="form-check-label ms-3" htmlFor="heparin">
                                                                            Heparina não foi usada
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" hidden={reportType === "MP"}>
                                                                <div
                                                                    className="form-group mb-3 mt-1 ms-5 col-md-3"
                                                                    style={{ width: "auto" }}
                                                                >
                                                                    <label className="form-check-label" htmlFor="heparinQuantity">
                                                                        Heparina foi usada na dose total de X.XXX UI
                                                                    </label>
                                                                </div>

                                                                <div className="form-group mb-3 col-md-2">
                                                                    <input
                                                                        type="number"
                                                                        className={`form-control ${(invalidHeparinQuantity ? "is-invalid" : "")}`}
                                                                        style={{ height: "2rem", width: "7.5rem" }}
                                                                        value={heparinQuantity}
                                                                        onChange={e => setHeparinQuantity(e.target.value)}
                                                                        disabled={heparin}
                                                                        min={5000}
                                                                        max={30000}
                                                                    />
                                                                    <DFormText hidden={!invalidHeparinQuantity} color="danger">O valor deve estar entre 5.000 e 30.000</DFormText>
                                                                </div>
                                                            </div>
                                                            <div className="row" hidden={reportType === "MP"}>
                                                                <div className="mb-2">
                                                                    <div
                                                                        className="form-group mb-2 mt-1 col-md-3"
                                                                        style={{ width: "auto" }}
                                                                    >
                                                                        <label className="form-check-label">
                                                                            Curativo Realizado no final do procedimento:
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-2 mt-1 col-md-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input ms-5"
                                                                            id="bandageRightInguinal"
                                                                            checked={bandageRightInguinal}
                                                                            onChange={() => setBandageRightInguinal(!bandageRightInguinal)}
                                                                        />
                                                                        <label className="form-check-label ms-3" htmlFor="bandageRightInguinal">
                                                                            Inguinal direito
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-2 mt-1 col-md-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input ms-5"
                                                                            id="bandageLeftInguinal"
                                                                            checked={bandageLeftInguinal}
                                                                            onChange={() => setBandageLeftInguinal(!bandageLeftInguinal)}
                                                                        />
                                                                        <label className="form-check-label ms-3" htmlFor="bandageLeftInguinal">
                                                                            Inguinal esquerdo
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-2 mt-1 col-md-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input ms-5"
                                                                            id="bandageJugular"
                                                                            checked={bandageJugular}
                                                                            onChange={() => setBandageJugular(!bandageJugular)}
                                                                        />
                                                                        <label className="form-check-label ms-3" htmlFor="bandageJugular">
                                                                            Jugular
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-2 mt-1 col-md-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input ms-5"
                                                                            id="bandageSubclavian"
                                                                            checked={bandageSubclavian}
                                                                            onChange={() => setBandageSubclavian(!bandageSubclavian)}
                                                                        />
                                                                        <label className="form-check-label ms-3" htmlFor="bandageSubclavian">
                                                                            Subclávia
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-group mb-2 mt-1 col-md-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input ms-5"
                                                                            id="bandageXiphoid"
                                                                            checked={bandageXiphoid}
                                                                            onChange={() => setBandageXiphoid(!bandageXiphoid)}
                                                                        />
                                                                        <label className="form-check-label ms-3" htmlFor="bandageXiphoid">
                                                                            Xifóide epicárdico
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Cirurgião</label>
                                                                    <Select
                                                                        className={`${(invalidMainDoctor ? "form-control is-invalid" : "")}`}
                                                                        defaultValue={mainDoctor}
                                                                        value={mainDoctor}
                                                                        onChange={(e) => setMainDoctor(e)}
                                                                        options={doctorsList}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                    <DFormText hidden={!invalidMainDoctor} color="danger">Campo obrigatório</DFormText>
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Primeiro Auxiliar</label>
                                                                    <Select
                                                                        defaultValue={firstAssistDoctor}
                                                                        value={firstAssistDoctor}
                                                                        onChange={(e) => setFirstAssistDoctor(e)}
                                                                        options={doctorsList}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Segundo Auxiliar</label>
                                                                    <Select
                                                                        defaultValue={secondAssistDoctor}
                                                                        value={secondAssistDoctor}
                                                                        onChange={(e) => setSecondAssistDoctor(e)}
                                                                        options={doctorsList}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row" hidden={reportType === "MP"}>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Formato do Laudo</label>
                                                                    <Select
                                                                        defaultValue={reportFormat}
                                                                        value={reportFormat}
                                                                        onChange={(e) => handleChangeReportFormat(e)}
                                                                        options={[
                                                                            {
                                                                                value: "NAR",
                                                                                label: "Narrativo"
                                                                            },
                                                                            {
                                                                                value: "TOP",
                                                                                label: "Em forma de tópicos"
                                                                            }
                                                                        ]}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                </div>
                                                                <div className="form-group mb-3 col-md-4">
                                                                    <label>Imprime História Clínica?</label>
                                                                    <Select
                                                                        defaultValue={printHma}
                                                                        value={printHma}
                                                                        onChange={e => setPrintHma(e)}
                                                                        options={yesNoOptions}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row" hidden={reportType !== "MP"}>
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Acesso com:</label>
                                                                    <Select
                                                                        defaultValue={procedureGroup}
                                                                        value={procedureGroup}
                                                                        onChange={(e) => handleChangeProcedureGroup(e)}
                                                                        options={procedureGroupsList}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row" hidden={reportType !== "MP"}>
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Tipo de Procedimento:</label>
                                                                    <Select
                                                                        defaultValue={procedureType}
                                                                        value={procedureType}
                                                                        onChange={(e) => handleChangeProcedureType(e)}
                                                                        options={procedureTypesList}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-12">
                                                                    <label>Laudo Final</label>
                                                                    <ReactQuill
                                                                        theme="snow"
                                                                        value={finalReport}
                                                                        onChange={setFinalReport}
                                                                        modules={modules}
                                                                        formats={formats}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </DCardBody>
                                                    </DCard>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <DCard borderColor="light">
                                                        <DCardHeader color="light" textColor="dark">Complicações do Procedimento</DCardHeader>
                                                        <DCardBody>
                                                            <div className="row">
                                                                <div className="form-group mb-3 col-md-2">
                                                                    <label>Houve complicação?</label>
                                                                    <Select
                                                                        defaultValue={hasComplication}
                                                                        value={hasComplication}
                                                                        onChange={e => setHasComplication(e)}
                                                                        options={yesNoOptions}
                                                                        styles={{
                                                                            control: (baseStyles, state) => ({
                                                                                ...baseStyles,
                                                                                height: 45,
                                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                            }),
                                                                        }}
                                                                        placeholder="Selecione..."
                                                                    />
                                                                </div>
                                                            </div>
                                                            <ComplicationCard
                                                                complicationInfoParm={complicationInfo}
                                                                onChangeInfo={handleChangeComplicationInfo}
                                                                hide={hasComplication.value !== "YES"}
                                                            />
                                                        </DCardBody>
                                                    </DCard>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12 text-end">
                                            <ButtonGroup>
                                                <Button
                                                    variant="outline-danger btn-rounded"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    className="ms-2"
                                                    variant="success btn-rounded"
                                                    onClick={handleSaveReport}
                                                >
                                                    Salvar
                                                </Button>
                                                {/* <Dropdown>
                                                <Dropdown.Toggle className="ms-2" variant="success btn-rounded">
                                                    Salvar
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={handleSaveReport}>Salvar</Dropdown.Item>
                                                    <Dropdown.Item to="#">Salvar e enviar e-mails</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col >
                </Row >

                <ToastContainer />
            </Modal>

            <ModalLoading
                visible={loadingProcedureExecution}
                message={loadingMessage}
                onClose={setLoadingProcedureExecution}
            />
        </>
    );
});

export default ProcedureExecution;