import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';
import Select from "react-select";
import CurrencyInput, { formatValue } from "react-currency-input-field";

import DFormText from "../../components/DFormText/DFormText";
import ModalLoading from '../../components/ModalLoading/ModalLoading';
import api from '../../../services/api';

import { receiptType, accountType as accountTypesList } from '../../../enum/receiptEnums';

import dayjs from 'dayjs';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
registerLocale("ptBR", ptBR);

const ReceiptForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingReceiptForm, setLoadingReceiptForm] = useState(false);

    const [receiptId, setReceiptId] = useState(undefined);
    const [receiptInnerId, setReceiptInnerId] = useState(undefined);
    const [receiptDate, setReceiptDate] = useState(dayjs().toDate());
    const [type, setType] = useState({
        value: "NOR",
        label: "Normal"
    });
    const [receiptValue, setReceiptValue] = useState(0);
    const [accountType, setAccountType] = useState({
        value: "LEC",
        label: "LEC"
    });
    const [accountDoctor, setAccountDoctor] = useState(null);
    const [doctorsList, setDoctorsList] = useState([]);

    const [invalidDate, setInvalidDate] = useState(false);
    const [invalidType, setInvalidType] = useState(false);
    const [invalidValue, setInvalidValue] = useState(false);
    const [invalidAccountType, setInvalidAccountType] = useState(false);
    const [invalidDoctor, setInvalidDoctor] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal(mode, receiptItemInfo = {}) {
            getDoctors();

            setModalBox(true);
            setIsUpdate(false);

            resetFields();

            if (mode === "update") {
                setIsUpdate(true);

                setReceiptId(receiptItemInfo.id);
                setReceiptInnerId(receiptItemInfo.innerId);
                setReceiptDate(dayjs(receiptItemInfo.receiptDate).toDate());
                setType(receiptItemInfo.type);
                setReceiptValue(formatValue({
                    value: receiptItemInfo.value.toString(),
                    groupSeparator: ".",
                    decimalSeparator: ","
                }));
                setAccountType(receiptItemInfo.accountType);
                setAccountDoctor(receiptItemInfo.accountDoctor);
            }
        }
    }));

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true")
            .then(response => {
                if (response.status === 200) {
                    const doctorsReturned = response.data.doctors.map((doctor) => {
                        return {
                            value: doctor._id,
                            label: doctor.name
                        }
                    });

                    setDoctorsList(doctorsReturned);
                }
            })
    }

    async function handleSaveReceiptItem(e) {
        e.preventDefault();

        setLoadingReceiptForm(true);

        setInvalidDate(false);
        setInvalidType(false);
        setInvalidValue(false);
        setInvalidAccountType(false);
        setInvalidDoctor(false);

        let hasError = false;

        if (!receiptDate) {
            setInvalidDate(true);
            hasError = true;
        }

        if (!type) {
            setInvalidType(true);
            hasError = true;
        }

        if (!receiptValue || receiptValue <= 0) {
            setInvalidValue(true);
            hasError = true;
        }

        if (!accountType) {
            setInvalidAccountType(true);
            hasError = true;
        } else {
            if (accountType.value === "DOC" && !accountDoctor) {
                setInvalidDoctor(true);
                hasError = true;
            }
        }

        if (!hasError) {
            let mode = "new";
            let receiptItem = {
                id: receiptId,
                innerId: (Math.floor(Math.random() * 65536)).toString(),
                receiptDate,
                type,
                value: +(receiptValue.replace(/[.]/g, "").replace(",", ".")),
                accountType,
                accountDoctor,
                isDeleted: false
            }

            if (isUpdate) {
                receiptItem.innerId = receiptInnerId;
                mode = "update"
            }

            resetFields();
            onClose(mode, receiptItem);
            setModalBox(false);
        }

        setLoadingReceiptForm(false);
    }

    function resetFields() {
        setReceiptId(undefined);
        setReceiptDate(dayjs().toDate());
        setType({
            value: "NOR",
            label: "Normal"
        });
        setReceiptValue(0);
        setAccountType({
            value: "LEC",
            label: "LEC"
        });
        setAccountDoctor(null);
        setDoctorsList([]);

        setInvalidDate(false);
        setInvalidType(false);
        setInvalidValue(false);
        setInvalidAccountType(false);
        setInvalidDoctor(false);
    }

    return (
        <>
            <Modal size='xl' onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">{(!isUpdate ? "Novo " : "Alterar ")}Item</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Data/Hora</label>
                                    <br />
                                    <DatePicker
                                        selected={receiptDate}
                                        onChange={(date) => setReceiptDate(date)}
                                        locale="ptBR"
                                        dateFormat="P"
                                        className="form-control"
                                    />
                                    <DFormText hidden={!invalidDate} color="danger">Informe a data do recebimento</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Tipo</label>
                                    <Select
                                        autoFocus
                                        defaultValue={type}
                                        value={type}
                                        onChange={(e) => setType(e)}
                                        options={receiptType}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidType} color="danger">Informe o tipo do recebimento</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Valor</label>
                                    <CurrencyInput
                                        className="form-control text-end"
                                        id="input-price"
                                        defaultValue={receiptValue}
                                        decimalsLimit={2}
                                        onValueChange={(value) => setReceiptValue(value)}
                                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                    />
                                    <DFormText hidden={!invalidValue} color="danger">Informe o valor recebido</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Conta</label>
                                    <Select
                                        defaultValue={accountType}
                                        value={accountType}
                                        onChange={(e) => setAccountType(e)}
                                        options={accountTypesList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidAccountType} color="danger">Informe a conta de recebimento</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-6" hidden={accountType?.value !== "DOC"}>
                                    <label>Médico</label>
                                    <Select
                                        defaultValue={accountDoctor}
                                        value={accountDoctor}
                                        onChange={(e) => setAccountDoctor(e)}
                                        options={doctorsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidDoctor} color="danger">Informe o médico que fez o recebimento</DFormText>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                        <Button variant="success btn-rounded" onClick={handleSaveReceiptItem}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ModalLoading
                visible={loadingReceiptForm}
                message="Gravando Informações..."
                onClose={setLoadingReceiptForm}
            />
        </>
    )
})

export default ReceiptForm;