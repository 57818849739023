import { Suspense, lazy, useEffect } from 'react';

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";

//action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from './services/utils';

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./vendor/swiper/css/swiper-bundle.min.css";
import "./css/style.css";

const Login = lazy(() => import("./jsx/pages/Login/Login"));

function App(props) {
    const dispatch = useDispatch();

    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);

    let routes = (
        <Switch>
            <Route path="/login" component={Login} />
        </Switch>
    );

    if (props.isAuthenticated) {
        return (
            <>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    <Index />
                </Suspense>
            </>
        );
    } else {
        return (
            <div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                }
                >
                    {routes}
                </Suspense>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App));