import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import {
    actualDaaOptions,
    mainSymptomOptions,
    protocolYesNoOptions
} from "../../../enum/protocolEnums";
import {
    avaliationOptions,
    evtvPlaceOptions,
    examsOptions,
    ventriArrhythmiaOptions
} from "../../../enum/followUpEnum";

const FollowEvTvForm = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        followInfoParm,
        hide,
        ...attributes
    } = props;

    const [avaliation, setAvaliation] = useState(null);
    const [otherAvaliation, setOtherAvaliation] = useState("");
    const [place, setPlace] = useState(null);
    const [otherPlace, setOtherPlace] = useState("");
    const [mainSymptom, setMainSymptom] = useState(null);
    const [otherMainSymptom, setOtherMainSymptom] = useState("");
    const [ventriArrhythmia, setVentriArrhythmia] = useState(null);
    const [otherVentriArrhythmia, setOtherVentriArrhythmia] = useState("");
    const [betablok, setBetablok] = useState(null);
    const [actualDaa, setActualDaa] = useState(null);
    const [otherActualDaa, setOtherActualDaa] = useState("");
    const [holterArrhytm, setHolterArrhytm] = useState("");
    const [exams, setExams] = useState(null);
    const [otherExams, setOtherExams] = useState("");
    const [observation, setObservation] = useState("");

    useEffect(() => {
        setAvaliation(followInfoParm.avaliation ?? null);
        setOtherAvaliation(followInfoParm.otherAvaliation ?? "");
        setPlace(followInfoParm.place ?? null);
        setOtherPlace(followInfoParm.otherPlace ?? "");
        setMainSymptom(followInfoParm.mainSymptom ?? null);
        setOtherMainSymptom(followInfoParm.otherMainSymptom ?? "");
        setVentriArrhythmia(followInfoParm.ventriArrhythmia ?? null);
        setOtherVentriArrhythmia(followInfoParm.otherVentriArrhythmia ?? "");
        setBetablok(followInfoParm.betablok ?? null);
        setActualDaa(followInfoParm.actualDaa ?? null);
        setOtherActualDaa(followInfoParm.otherActualDaa ?? "");
        setHolterArrhytm(followInfoParm.holterArrhytm ?? null);
        setExams(followInfoParm.exams ?? null);
        setOtherExams(followInfoParm.otherExams ?? "");
        setObservation(followInfoParm.observation ?? "");
    }, [followInfoParm]);

    return (
        <div hidden={hide}>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Avaliação</label>
                    <Select
                        autoFocus
                        defaultValue={avaliation}
                        value={avaliation}
                        onChange={(e) => {
                            setAvaliation(e);
                            
                            let fieldsChanges = [
                                {
                                    field: "avaliation",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherAvaliation("");

                                fieldsChanges.push({
                                    field: "otherAvaliation",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={avaliationOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Avaliação (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherAvaliation}
                        onChange={e => {
                            setOtherAvaliation(e.target.value);
                            onChangeInfo([{ field: "otherAvaliation", value: e.target.value }]);
                        }}
                        disabled={avaliation?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Local Consulta</label>
                    <Select
                        autoFocus
                        defaultValue={place}
                        value={place}
                        onChange={(e) => {
                            setPlace(e);
                            
                            let fieldsChanges = [
                                {
                                    field: "place",
                                    value: e
                                }
                            ];

                            if (e.value !== 5) {
                                setOtherPlace("");

                                fieldsChanges.push({
                                    field: "otherPlace",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={evtvPlaceOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Local Consulta (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherPlace}
                        onChange={e => {
                            setOtherPlace(e.target.value);
                            onChangeInfo([{ field: "otherPlace", value: e.target.value }]);
                        }}
                        disabled={place?.value !== 5}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal</label>
                    <Select
                        autoFocus
                        defaultValue={mainSymptom}
                        value={mainSymptom}
                        onChange={(e) => {
                            setMainSymptom(e);

                            let fieldsChanges = [
                                {
                                    field: "mainSymptom",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherMainSymptom("");

                                fieldsChanges.push({
                                    field: "otherMainSymptom",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={mainSymptomOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMainSymptom}
                        onChange={e => {
                            setOtherMainSymptom(e.target.value);
                            onChangeInfo([{ field: "otherMainSymptom", value: e.target.value }]);
                        }}
                        disabled={mainSymptom?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Arritmia Ventri</label>
                    <Select
                        autoFocus
                        defaultValue={ventriArrhythmia}
                        value={ventriArrhythmia}
                        onChange={(e) => {
                            setVentriArrhythmia(e);
                            
                            let fieldsChanges = [
                                {
                                    field: "ventriArrhythmia",
                                    value: e
                                }
                            ];

                            if (e.value !== 5) {
                                setOtherVentriArrhythmia("");

                                fieldsChanges.push({
                                    field: "otherVentriArrhythmia",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={ventriArrhythmiaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Arritmia Ventri (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherVentriArrhythmia}
                        onChange={e => {
                            setOtherVentriArrhythmia(e.target.value);
                            onChangeInfo([{ field: "otherVentriArrhythmia", value: e.target.value }]);
                        }}
                        disabled={ventriArrhythmia?.value !== 5}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Betablok</label>
                    <Select
                        defaultValue={betablok}
                        value={betablok}
                        onChange={(e) => {
                            setBetablok(e);
                            onChangeInfo([{ field: "betablok", value: e }]);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>DAA Atual</label>
                    <Select
                        defaultValue={actualDaa}
                        value={actualDaa}
                        onChange={(e) => {
                            setActualDaa(e);

                            let fieldsChanges = [
                                {
                                    field: "actualDaa",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherActualDaa("");

                                fieldsChanges.push({
                                    field: "otherActualDaa",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={actualDaaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>DAA Atual (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherActualDaa}
                        onChange={e => {
                            setOtherActualDaa(e.target.value);
                            onChangeInfo([{ field: "otherActualDaa", value: e.target.value }]);
                        }}
                        disabled={actualDaa?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Holter Arrítm Ventricular (%)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={holterArrhytm}
                        onChange={e => {
                            setHolterArrhytm(e.target.value);
                            onChangeInfo([{ field: "holterArrhytm", value: e.target.value }]);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Exames</label>
                    <Select
                        defaultValue={exams}
                        value={exams}
                        onChange={(e) => {
                            setExams(e);

                            let fieldsChanges = [
                                {
                                    field: "exams",
                                    value: e
                                }
                            ];

                            if (e.value !== 6) {
                                setOtherExams("");

                                fieldsChanges.push({
                                    field: "otherExams",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={examsOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Exames (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherExams}
                        onChange={e => {
                            setOtherExams(e.target.value);
                            onChangeInfo([{ field: "otherExams", value: e.target.value }]);
                        }}
                        disabled={exams?.value !== 6}
                    />
                </div>
                <div className="form-group mb-12 col-md-6">
                    <label>Observações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={observation}
                        onChange={e => {
                            setObservation(e.target.value);
                            onChangeInfo([{ field: "observation", value: e.target.value }]);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

FollowEvTvForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

FollowEvTvForm.defaultProps = {
    followInfoParm: {},
    hide: false
};

export default FollowEvTvForm;