import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Row,
    Table
} from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import CurrencyInput, { formatValue } from "react-currency-input-field";

import DCard from '../../components/card/DCard';
import DCardHeader from '../../components/card/DCardHeader';
import DCardBody from '../../components/card/DCardBody';
import PageTitle from '../../layouts/PageTitle';
import ModalLoading from '../../components/ModalLoading/ModalLoading';
import PartitionForm from './PartitionForm';

import api from '../../../services/api';

const Settings = () => {
    const childRef = useRef();
    const [loadingSettings, setLoadingSettings] = useState(false);

    const [taxes, setTaxes] = useState("0");
    const [forwarderLecDoctor, setForwarderLecDoctor] = useState("0");
    const [forwarderDoctor, setForwarderDoctor] = useState("0");
    const [doctorsList, setDoctorsList] = useState([]);

    useEffect(() => {
        setLoadingSettings(true);

        getInfo();

        setLoadingSettings(false);
    }, []);

    async function getInfo() {
        const settingsInfo = await getSettings();
        let doctorsSettings = [];

        for (let indexSettings = 0; indexSettings < settingsInfo.length; indexSettings++) {
            if (settingsInfo[indexSettings].parameter === "taxes" || settingsInfo[indexSettings].parameter === "forwardLec" || settingsInfo[indexSettings].parameter === "forwardDoctor") {
                const valueFormatted = formatValue({
                    value: settingsInfo[indexSettings].value.toString(),
                    groupSeparator: ".",
                    decimalSeparator: ","
                });

                if (settingsInfo[indexSettings].parameter === "taxes") {
                    setTaxes(valueFormatted);
                } else if (settingsInfo[indexSettings].parameter === "forwardLec") {
                    setForwarderLecDoctor(valueFormatted);
                } else {
                    setForwarderDoctor(valueFormatted);
                }
            } else if (settingsInfo[indexSettings].parameter === "partition") {
                doctorsSettings = JSON.parse(settingsInfo[indexSettings].value);
            }
        }

        if (doctorsSettings.length === 0) {
            doctorsSettings = await getDoctors();
        }

        setDoctorsList(doctorsSettings);
    }

    async function getSettings() {
        return new Promise(resolve => {
            api.get("/settings")
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }

                    resolve([]);
                })
        })
    }

    async function getDoctors() {
        return new Promise(resolve => {
            api.get("/doctors?status=true&lec=true")
                .then(response => {
                    if (response.status === 200) {
                        const doctorsReturn = response.data.doctors.map((doctor) => {
                            return {
                                _id: doctor._id,
                                name: doctor.name,
                                value: 0
                            };
                        })
                        resolve(doctorsReturn);
                    }
                })
        });
    }

    async function handleSaveSettings(e) {
        e.preventDefault();

        const settingsInfo = {
            settings: [
                {
                    parameter: "taxes",
                    value: taxes.toString().replace(/[.]/g, "").replace(",", ".")
                },
                {
                    parameter: "forwardLec",
                    value: forwarderLecDoctor.toString().replace(/[.]/g, "").replace(",", ".")
                },
                {
                    parameter: "forwardDoctor",
                    value: forwarderDoctor.toString().replace(/[.]/g, "").replace(",", ".")
                },
                {
                    parameter: "partition",
                    value: JSON.stringify(doctorsList)
                },
            ]
        };

        try {
            await api.post("/settings", settingsInfo)
                .then(response => {
                    if (response.status === 201) {
                        notifySuccess();
                    }
                })
        } catch (err) {
            notifyError();
        }
    }

    function notifySuccess() {
        toast.success(`✔️ Parâmetros salvos com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error("❌ Ocorreu um problema ao salvar os parâmetros", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function onClosePartition(id, value) {
        const indexToUpdate = doctorsList.findIndex(x => x._id === id);
        let doctorsListUpdated = [...doctorsList];

        doctorsListUpdated[indexToUpdate].value = value;

        setDoctorsList(doctorsListUpdated);
    }

    return (
        <Fragment>
            <PageTitle activeMenu="Settings" motherMenu="Settings" />
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Parâmetros da Aplicação</Card.Title>
                            <div>
                                <Button variant="success btn-rounded" onClick={handleSaveSettings}>Salvar</Button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="basic-form">
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12">
                                        <DCard borderColor="light">
                                            <DCardHeader color="light" textColor="dark">Rateio de Comissões</DCardHeader>
                                            <DCardBody>
                                                <div className="basic-form">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>% Impostos</label>
                                                            <CurrencyInput
                                                                autoFocus
                                                                className="form-control text-end"
                                                                id="input-taxes"
                                                                value={taxes}
                                                                maxLength={6}
                                                                decimalsLimit={2}
                                                                onValueChange={(value) => setTaxes(value)}
                                                                intlConfig={{ locale: "pt-BR" }}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>% Encaminhador LEC</label>
                                                            <CurrencyInput
                                                                className="form-control text-end"
                                                                id="input-forwarderLecDoctor"
                                                                value={forwarderLecDoctor}
                                                                maxLength={6}
                                                                decimalsLimit={2}
                                                                onValueChange={(value) => setForwarderLecDoctor(value)}
                                                                intlConfig={{ locale: "pt-BR" }}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Valor Encaminhador Externo</label>
                                                            <CurrencyInput
                                                                className="form-control text-end"
                                                                id="input-forwarderDoctor"
                                                                value={forwarderDoctor}
                                                                decimalsLimit={2}
                                                                onValueChange={(value) => setForwarderDoctor(value)}
                                                                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-12">
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <strong>Médico</strong>
                                                                        </th>
                                                                        <th>
                                                                            <strong>% Rateio</strong>
                                                                        </th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        doctorsList.map((doctor) => {
                                                                            return (
                                                                                <tr key={doctor._id}>
                                                                                    <td>{doctor.name}</td>
                                                                                    <td className="text-center">{doctor.value.toLocaleString('pt-br')}</td>
                                                                                    <td>
                                                                                        <div className="d-flex">
                                                                                            <button
                                                                                                className="btn btn-primary shadow sharp ms-1"
                                                                                                onClick={() => childRef.current.openModal(doctor)}
                                                                                            >
                                                                                                <i className="fas fa-pencil-alt"></i>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </DCardBody>
                                        </DCard>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <PartitionForm ref={childRef} onClose={onClosePartition} />

            <ToastContainer />

            <ModalLoading
                visible={loadingSettings}
                message="Carregando Informações..."
                onClose={setLoadingSettings}
            />
        </Fragment>
    )
}

export default Settings;