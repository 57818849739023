import api from "../../../services/api";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { ageInString, convertStringToPrint } from "../../../services/utils";

const imgLogo = "../../../images/lec-logo-2.png";
var scheduleReport = undefined;
var linePosition = 4.2;

export async function generateProcedureExecutionPdf(reportId) {
    scheduleReport = await getScheduleReport(reportId);

    let doc = new jsPDF({
        unit: "cm"
    });

    linePosition = 4.2;

    jsPDF.autoTableSetDefaults({
        headStyles: { fillColor: [55, 75, 69] },
        margin: { top: 4.2 }
    });

    if (scheduleReport) {
        doc.addImage(imgLogo, "PNG", 5, 7, 9.7, 6.7);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(24);

        doc.text("LAUDO", 10.55, 15, { align: "center" });

        doc.setFontSize(12);
        doc.text("Nome:", 1.5, 17);
        doc.text("Sexo:", 14.5, 17);

        doc.setFont("helvetica", "normal");
        doc.text(scheduleReport.pacient_name, 3, 17);
        doc.text(scheduleReport.pacient_gender, 16, 17);

        doc.setFont("helvetica", "bold");
        doc.text("Idade:", 1.5, 17.6);

        doc.setFont("helvetica", "normal");
        const ageInStr = ageInString(dayjs(scheduleReport.pacient_birth_date).toDate());
        doc.text(ageInStr, 3, 17.6);

        doc.setFont("helvetica", "bold");
        doc.text("Procedimento:", 1.5, 20);

        let procedureName = scheduleReport.procedure_name;
        if (scheduleReport.ablations) {
            procedureName = "";

            for (let ablationItem = 0; ablationItem < scheduleReport.ablations.length; ablationItem++) {
                if (ablationItem > 0) {
                    procedureName += " / ";
                }
                procedureName += scheduleReport.ablations[ablationItem].ablation_description;
            }
        }

        doc.setFont("helvetica", "normal");
        doc.text(procedureName, 4.6, 20);

        doc.setFont("helvetica", "bold");
        doc.text("Local:", 1.5, 21.1);

        doc.setFont("helvetica", "normal");
        doc.text(scheduleReport.hospital_name, 3, 21.1);

        doc.setFont("helvetica", "bold");
        doc.text("Data:", 1.5, 23);

        doc.setFont("helvetica", "normal");
        doc.text(dayjs(scheduleReport.execution_date).format("DD/MM/YYYY"), 3, 23);

        doc.addPage();

        if (scheduleReport.report_type !== "MP") {
            if (scheduleReport?.print_hma) {
                const hmaToPrint = [{ hmaDescription: convertStringToPrint(scheduleReport.hma) }];
                doc.autoTable({
                    startY: linePosition,
                    head: [{ hmaDescription: "História Clínica" }],
                    body: hmaToPrint,
                });
    
                linePosition = doc.lastAutoTable.finalY;
            }
    
            let eefToPrint = [];

            if (scheduleReport.basal_rhythm_option) {
                let basalRhythmDescription = scheduleReport.basal_rhythm_description;
                if (scheduleReport.basal_rhythm === "OUT") {
                    basalRhythmDescription = scheduleReport.other_basal_rhythm;
                }

                eefToPrint.push({
                    item: "Ritmo Basal",
                    result: basalRhythmDescription
                });
            }

            if (scheduleReport.ventricular_rate) {
                eefToPrint.push({
                    item: "Frequência ventricular basal em ms",
                    result: scheduleReport.ventricular_rate
                });
            }

            if (scheduleReport.atrial_rate) {
                eefToPrint.push({
                    item: "Frequência atrial basal em ms",
                    result: scheduleReport.atrial_rate
                });
            }

            if (scheduleReport.pr_interval) {
                eefToPrint.push({
                    item: "Intervalo PR basal em ms",
                    result: scheduleReport.pr_interval
                });
            }

            if (scheduleReport.qtc_interval) {
                eefToPrint.push({
                    item: "Intervalo QTc basal em ms",
                    result: scheduleReport.qtc_interval
                });
            }

            if (scheduleReport.ecg_report) {
                let ecgReportItems = "";
                for (let indexEcgReport = 0; indexEcgReport < scheduleReport.ecg_report.length; indexEcgReport++) {
                    if (indexEcgReport > 0) {
                        ecgReportItems += " / ";
                    }

                    ecgReportItems += scheduleReport.ecg_report[indexEcgReport].ecg_description;
                }

                eefToPrint.push({
                    item: "Laudo do ECG basal",
                    result: ecgReportItems
                });
            }

            if (scheduleReport.ah_interval) {
                eefToPrint.push({
                    item: "Intervalo AH em ms",
                    result: scheduleReport.ah_interval
                });
            }

            if (scheduleReport.hv_interval) {
                eefToPrint.push({
                    item: "Intervalo HV em ms",
                    result: scheduleReport.hv_interval
                });
            }

            if (scheduleReport.qrs_interval) {
                eefToPrint.push({
                    item: "Intervalo QRS em ms",
                    result: scheduleReport.qrs_interval
                });
            }

            if (scheduleReport.sinus_node_recovery) {
                eefToPrint.push({
                    item: "Tempo corrigido de recuperação do nó sinusal em ms",
                    result: scheduleReport.sinus_node_recovery
                });
            }

            if (scheduleReport.bav) {
                eefToPrint.push({
                    item: "Ponto de Wenckebach (BAV) do nó atrioventricular em ms",
                    result: scheduleReport.bav
                });
            }

            if (scheduleReport.atriovent_period_anterograde) {
                eefToPrint.push({
                    item: "Período refratário efetivo anterógrado do nó atrioventricular (Sem dupla vida nodal) em ms",
                    result: scheduleReport.atriovent_period_anterograde
                });
            }

            if (scheduleReport.atriovent_period_fast) {
                eefToPrint.push({
                    item: "Período refratário efetivo anterógrado da via rápida do nó atrioventricular",
                    result: scheduleReport.atriovent_period_fast
                });
            }

            if (scheduleReport.atriovent_period_slow) {
                eefToPrint.push({
                    item: "Período refratário efetivo anterógrado da via lenta do nó atrioventricular",
                    result: scheduleReport.atriovent_period_slow
                });
            }

            if (scheduleReport.bva) {
                eefToPrint.push({
                    item: "Ponto de Wenckebach (BVA) retrógrado do nó atrioventricular em ms",
                    result: scheduleReport.bva
                });
            }

            if (scheduleReport.atriovent_period_retrograde) {
                eefToPrint.push({
                    item: "Período refratário retrógrado do nó atrioventricular",
                    result: scheduleReport.atriovent_period_retrograde
                });
            }

            if (scheduleReport.atrium_period) {
                eefToPrint.push({
                    item: "Período refratário efetivo do átrio",
                    result: scheduleReport.atrium_period
                });
            }

            if (scheduleReport.ventricle_period) {
                eefToPrint.push({
                    item: "Período refratário efetivo do ventrículo",
                    result: scheduleReport.ventricle_period
                });
            }

            if (scheduleReport.accessory_path_description) {
                eefToPrint.push({
                    item: "Via acessória manifesta presente",
                    result: scheduleReport.accessory_path_description
                });
            }

            if (scheduleReport.accessory_path_anterograde) {
                eefToPrint.push({
                    item: "Período refratário efetivo anterógrado da via acessória",
                    result: scheduleReport.accessory_path_anterograde
                });
            }

            if (scheduleReport.accessory_path_retrograde) {
                eefToPrint.push({
                    item: "Período refratário efetivo retrógrado da via acessória",
                    result: scheduleReport.accessory_path_retrograde
                });
            }

            if (scheduleReport.induced_tachycardia_description) {
                eefToPrint.push({
                    item: "Taquicardia induzida",
                    result: scheduleReport.induced_tachycardia_description
                });
            }

            if (scheduleReport.tachycardia_mode) {
                eefToPrint.push({
                    item: "Modo de indução da taquicardia",
                    result: scheduleReport.tachycardia_mode
                });
            }

            if (scheduleReport.tachycardia_cycle) {
                eefToPrint.push({
                    item: "Ciclo da taquicardia induzida em ms",
                    result: scheduleReport.tachycardia_cycle
                });
            }

            if (scheduleReport.tachycardia_ecg_report) {
                eefToPrint.push({
                    item: "Laudo do ECG da taquicardia induzida",
                    result: scheduleReport.tachycardia_ecg_report
                });
            }

            if (eefToPrint.length > 0) {
                linePosition += 0.5;
                doc.autoTable({
                    startY: linePosition,
                    head: [{
                        item: "Detalhes do Estudo Eletrofisiológico",
                        result: "Resultado"
                    }],
                    body: eefToPrint
                });

                linePosition = doc.lastAutoTable.finalY;
                linePosition += 1.1;
                checkBreak(doc);
            }

            if (scheduleReport.induced_tachycardia) {
                let tachycardiaToPrint = [];

                if (scheduleReport.induced_tachycardia === "TRN") {
                    if (scheduleReport.common_trn_fast_slow) {
                        if (scheduleReport.common_trn_fast_slow_place_description) {
                            tachycardiaToPrint.push({
                                item: "TRN Comum fast-slow",
                                result: scheduleReport.common_trn_fast_slow_place_description
                            });
                        }
                    }

                    if (scheduleReport.atypical_trn_slow_slow) {
                        if (scheduleReport.atypical_trn_slow_slow_place_description) {
                            tachycardiaToPrint.push({
                                item: "TRN Atípica slow-slow",
                                result: scheduleReport.atypical_trn_slow_slow_place_description
                            });
                        }
                    }

                    if (scheduleReport.atypical_trn_fast_slow) {
                        if (scheduleReport.atypical_trn_fast_slow_place_description) {
                            tachycardiaToPrint.push({
                                item: "TRN Atípica fast-slow",
                                result: scheduleReport.atypical_trn_fast_slow_place_description
                            });
                        }
                    }
                }

                if (scheduleReport.induced_tachycardia === "TVA") {
                    if (scheduleReport.tva_common_shown) {
                        let tvaCommonDescription = "";

                        if (scheduleReport.tva_common_shown_bav_anterograde) {
                            tvaCommonDescription += `BAV anterógrado da via acessória comum: ${scheduleReport.tva_common_shown_bav_anterograde}`;
                        }

                        if (scheduleReport.tva_common_shown_bav_retrograde) {
                            if (tvaCommonDescription !== "") {
                                tvaCommonDescription += "\n";
                            }
                            tvaCommonDescription += `BAV retrógrado da via acessória comum: ${scheduleReport.tva_common_shown_bav_retrograde}`;
                        }

                        if (scheduleReport.tva_common_shown_anterograde_period) {
                            if (tvaCommonDescription !== "") {
                                tvaCommonDescription += "\n";
                            }
                            tvaCommonDescription += `Período refratário anterógrado da via acessória comum: ${scheduleReport.tva_common_shown_anterograde_period}`;
                        }

                        if (scheduleReport.tva_common_shown_retrograde_period) {
                            if (tvaCommonDescription !== "") {
                                tvaCommonDescription += "\n";
                            }
                            tvaCommonDescription += `Período refratário retrógrado da via acessória comum: ${scheduleReport.tva_common_shown_retrograde_period}`;
                        }

                        if (tvaCommonDescription !== "") {
                            tachycardiaToPrint.push({
                                item: "Via acessória manifesta comum",
                                result: tvaCommonDescription
                            });
                        }
                    }

                    if (scheduleReport.tva_atypical_shown) {
                        let tvaAtypicalDescription = "";

                        if (scheduleReport.tva_atypical_shown_atrium_ventricular) {
                            tvaAtypicalDescription += "átrio-ventricular"
                        }

                        if (scheduleReport.tva_atypical_shown_atrium_fascicular) {
                            if (tvaAtypicalDescription !== "") {
                                tvaAtypicalDescription += "\n";
                            }
                            tvaAtypicalDescription += "átrio-fascicular"
                        }

                        if (scheduleReport.tva_atypical_shown_node_ventricular) {
                            if (tvaAtypicalDescription !== "") {
                                tvaAtypicalDescription += "\n";
                            }
                            tvaAtypicalDescription += "nodo-ventricular"
                        }

                        if (scheduleReport.tva_atypical_shown_node_fascicular) {
                            if (tvaAtypicalDescription !== "") {
                                tvaAtypicalDescription += "\n";
                            }
                            tvaAtypicalDescription += "nodo-fascicular"
                        }

                        if (scheduleReport.tva_atypical_shown_fascicle_ventricular) {
                            if (tvaAtypicalDescription !== "") {
                                tvaAtypicalDescription += "\n";
                            }
                            tvaAtypicalDescription += "fasículo-ventricular"
                        }

                        if (tvaAtypicalDescription !== "") {
                            tachycardiaToPrint.push({
                                item: "Via acessória manifesta atípica (Mahaim)",
                                result: tvaAtypicalDescription
                            });
                        }
                    }

                    if (scheduleReport.tva_common_hidden) {
                        let tvaHiddenDescription = "";

                        if (scheduleReport.tva_common_hidden_bva) {
                            tvaHiddenDescription += `BVA da via acessória oculta comum: ${scheduleReport.tva_common_hidden_bva}`;
                        }

                        if (scheduleReport.tva_common_hidden_retrograde_period) {
                            if (tvaHiddenDescription !== "") {
                                tvaHiddenDescription += "\n";
                            }
                            tvaHiddenDescription += `Período refratário retrógrado da via acessória oculta comum: ${scheduleReport.tva_common_hidden_retrograde_period}`;
                        }

                        if (tvaHiddenDescription) {
                            tachycardiaToPrint.push({
                                item: "Via acessória oculta comum",
                                result: tvaHiddenDescription
                            });
                        }
                    }

                    if (scheduleReport.tva_atypical_hidden) {
                        let tvaAtypicalHiddenDescription = "";

                        if (scheduleReport.tva_atypical_hidden_bva) {
                            tvaAtypicalHiddenDescription += `BVA da via acessória oculta atípica de Coumel: ${scheduleReport.tva_atypical_hidden_bva}`;
                        }

                        if (scheduleReport.tva_atypical_hidden_retrograde_period) {
                            if (tvaAtypicalHiddenDescription !== "") {
                                tvaAtypicalHiddenDescription += "\n";
                            }
                            tvaAtypicalHiddenDescription += `Período refratário retrógrado da via acessória oculta atípica: ${scheduleReport.tva_atypical_hidden_retrograde_period}`;
                        }

                        if (tvaAtypicalHiddenDescription) {
                            tachycardiaToPrint.push({
                                item: "Via acessória oculta atípica (Coumel)",
                                result: tvaAtypicalHiddenDescription
                            });
                        }
                    }
                }

                if (scheduleReport.induced_tachycardia === "TA") {
                    let taDescription = "";

                    if (scheduleReport.ta_automatic) {
                        taDescription += "Automática";
                    }

                    if (scheduleReport.ta_reentrant) {
                        if (taDescription !== "") {
                            taDescription += "\n";
                        }
                        taDescription += "Reentrante";
                    }

                    if (scheduleReport.ta_triggered) {
                        if (taDescription !== "") {
                            taDescription += "\n";
                        }
                        taDescription += "Deflagrada";
                    }

                    if (taDescription !== "") {
                        tachycardiaToPrint.push({
                            item: "Taquicardia atrial",
                            result: taDescription
                        });
                    }
                }

                if (scheduleReport.induced_tachycardia === "FLA") {
                    let flaDescription = "";

                    if (scheduleReport.fla_common_anti_clockwise) {
                        flaDescription += "Comum anti-horário istmo cavotricúspide dependente";
                    }

                    if (scheduleReport.fla_common_clockwise) {
                        if (flaDescription !== "") {
                            flaDescription += "\n";
                        }
                        flaDescription += "Comum horário istmo cavotricúspide dependente";
                    }

                    if (scheduleReport.fla_atypical_right) {
                        if (flaDescription !== "") {
                            flaDescription += "\n";
                        }
                        flaDescription += "Atípico de átrio direito";
                    }

                    if (scheduleReport.fla_atypical_left) {
                        if (flaDescription !== "") {
                            flaDescription += "\n";
                        }
                        flaDescription += "Atípico de átrio esquerdo";
                    }

                    if (flaDescription !== "") {
                        tachycardiaToPrint.push({
                            item: "Flutter atrial",
                            result: flaDescription
                        });
                    }
                }

                if (scheduleReport.induced_tachycardia === "TV") {
                    let tvDescription = "";

                    if (scheduleReport.tv_normal) {
                        tvDescription += "Coração normal";
                    }

                    if (scheduleReport.tv_ischemic) {
                        if (tvDescription !== "") {
                            tvDescription += "\n";
                        }
                        tvDescription += "Coração com cicatriz isquêmica";
                    }

                    if (scheduleReport.tv_chagastic) {
                        if (tvDescription !== "") {
                            tvDescription += "\n";
                        }
                        tvDescription += "Coração com cicatriz Chagástica";
                    }

                    if (scheduleReport.tv_other) {
                        if (tvDescription !== "") {
                            tvDescription += "\n";
                        }
                        tvDescription += "Coração com cicatriz por outra doença";
                    }

                    if (tvDescription !== "") {
                        tachycardiaToPrint.push({
                            item: "Taquicardia ventricular",
                            result: tvDescription
                        });
                    }
                }

                if (scheduleReport.induced_tachycardia === "NAT") {
                    let natDescription = "";

                    if (scheduleReport.nat_escape) {
                        natDescription = `Ablação do nó atrioventricular com escape de: ${scheduleReport.nat_bpm ?? ""} batimentos por minuto`;
                    }

                    if (scheduleReport.nat_no_escape) {
                        natDescription = "Ablação do nó atrioventricular sem escape";
                    }

                    if (natDescription !== "") {
                        tachycardiaToPrint.push({
                            item: "Nó atrioventricular",
                            result: natDescription
                        });
                    }
                }

                if (scheduleReport.induced_tachycardia === "FA") {
                    let faDescription = "Foram abordados:";

                    if (scheduleReport.fa_ostium) {
                        faDescription += "\nÓstio das veias pulmonares";
                    }

                    if (scheduleReport.fa_left_box) {
                        faDescription += "\nBox da parede posterior do átrio esquerdo";
                    }

                    if (scheduleReport.fa_earpiece) {
                        faDescription += "\nAuriculeta";
                    }

                    if (scheduleReport.fa_left_line) {
                        faDescription += "\nLinha de lesão acessória no átrio esquerdo";
                    }

                    if (scheduleReport.fa_istmo) {
                        faDescription += "\nIstmo cavo-tricúspide";
                    }

                    if (scheduleReport.fa_right_line) {
                        faDescription += "\nLinha de lesão acessória no átrio direito";
                    }

                    if (faDescription !== "") {
                        tachycardiaToPrint.push({
                            item: "Fibrilação atrial",
                            result: faDescription
                        });
                    }
                }

                if (scheduleReport.induced_tachycardia === "OUT") {
                    let otherDescription = "";

                    if (scheduleReport.out_ta) {
                        otherDescription += "Taquicardia atrial";
                    }

                    if (scheduleReport.out_fla) {
                        if (otherDescription !== "") {
                            otherDescription += "\n";
                        }
                        otherDescription += "Flutter atrial";
                    }

                    if (scheduleReport.out_trn) {
                        if (otherDescription !== "") {
                            otherDescription += "\n";
                        }
                        otherDescription += "Taquicardia por reentrada nodal comum";
                    }

                    if (scheduleReport.out_tva) {
                        if (otherDescription !== "") {
                            otherDescription += "\n";
                        }
                        otherDescription += "Taquicardia por via acessória";
                    }

                    if (scheduleReport.out_tv) {
                        if (otherDescription !== "") {
                            otherDescription += "\n";
                        }
                        otherDescription += "Taquicardia ventricular";
                    }

                    if (scheduleReport.out_ea) {
                        if (otherDescription !== "") {
                            otherDescription += "\n";
                        }
                        otherDescription += "Extrassístole atrial";
                    }

                    if (scheduleReport.out_ev) {
                        if (otherDescription !== "") {
                            otherDescription += "\n";
                        }
                        otherDescription += "Extrassístole ventricular";
                    }

                    if (scheduleReport.out_other) {
                        if (otherDescription !== "") {
                            otherDescription += "\n";
                        }
                        otherDescription += scheduleReport.out_other_text;
                    }

                    if (otherDescription !== "") {
                        tachycardiaToPrint.push({
                            item: "Taquicardia clínica não induzida ou não indutível, descrita como",
                            result: otherDescription
                        });
                    }

                    if (scheduleReport.out_comment) {
                        tachycardiaToPrint.push({
                            item: "Motivo da ablação empírica e comentários eventuais dessa abordagem",
                            result: scheduleReport.out_comment
                        });
                    }
                }

                if (tachycardiaToPrint.length > 0) {
                    linePosition += 0.5;
                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            item: "Detalhes Eletrofisiológicos da Arritmia Induzida",
                            result: "Resultado"
                        }],
                        body: tachycardiaToPrint
                    });

                    linePosition = doc.lastAutoTable.finalY;
                    linePosition += 1.1;
                    checkBreak(doc);
                }
            }

            if (scheduleReport.ablations) {
                const ablationsList = [...scheduleReport.ablations];

                if (ablationsList.length > 0) {
                    doc.setFont("helvetica", "bold");
                    doc.text("DETALHES TÉCNICOS DA ABLAÇÃO POR CATETER - RESULTADO", 1.5, linePosition);

                    for (let ablationItem of ablationsList) {
                        linePosition += 0.8;
                        checkBreak(doc);

                        let ablationToPrint = [];

                        ablationToPrint.push({
                            item: "Número Taquicardia Induzida",
                            result: ablationItem.sequence
                        });

                        if (ablationItem.atriovent_relation) {
                            ablationToPrint.push({
                                item: "Relação atrioventricular durante ablação",
                                result: ablationItem.atriovent_relation_description
                            });
                        }

                        ablationToPrint.push({
                            item: "Encarrilhamento oculto presente",
                            result: (ablationItem.hidden_retraining ? "Sim" : "Não")
                        });

                        if (ablationItem.hidden_retraining && ablationItem.hidden_retraining_place) {
                            ablationToPrint.push({
                                item: "Local do encarrilhamento oculto",
                                result: ablationItem.hidden_retraining_place
                            });
                        }

                        if (ablationItem.map_activation || ablationItem.map_pacemapping || ablationItem.map_electro) {
                            let mapDescription = "";

                            if (ablationItem.map_activation) {
                                mapDescription += "Ativação";
                            }

                            if (ablationItem.map_pacemapping) {
                                if (mapDescription !== "") {
                                    mapDescription += "\n";
                                }
                                mapDescription += "Pacemapping"
                            }

                            if (ablationItem.map_electro) {
                                if (mapDescription !== "") {
                                    mapDescription += "\n";
                                }
                                mapDescription += "Mapa eletroanatômico"
                            }

                            if (mapDescription !== "") {
                                ablationToPrint.push({
                                    item: "Mapeamento realizado usando critérios de",
                                    result: mapDescription
                                });
                            }
                        }

                        if (ablationItem.map_sub_type_description) {
                            ablationToPrint.push({
                                item: "Subtipo de critério de mapeamento",
                                result: ablationItem.map_sub_type_description
                            });
                        }

                        if (ablationItem.map_right_atrium || ablationItem.map_left_atrium || ablationItem.map_right_ventricle || ablationItem.map_left_ventricle ||
                            ablationItem.map_epicardial_space || ablationItem.map_atriovent_ring || ablationItem.map_atriovent_node) {
                            let structDescription = "";

                            if (ablationItem.map_right_atrium) {
                                structDescription += "Átrio direito";
                            }

                            if (ablationItem.map_left_atrium) {
                                if (structDescription !== "") {
                                    structDescription += "\n";
                                }
                                structDescription += "Átrio esquerdo"
                            }

                            if (ablationItem.map_right_ventricle) {
                                if (structDescription !== "") {
                                    structDescription += "\n";
                                }
                                structDescription += "Ventrículo direito"
                            }

                            if (ablationItem.map_left_ventricle) {
                                if (structDescription !== "") {
                                    structDescription += "\n";
                                }
                                structDescription += "Ventrículo esquerdo"
                            }

                            if (ablationItem.map_epicardial_space) {
                                if (structDescription !== "") {
                                    structDescription += "\n";
                                }
                                structDescription += "Espaço epicárdico"
                            }

                            if (ablationItem.map_atriovent_ring) {
                                if (structDescription !== "") {
                                    structDescription += "\n";
                                }
                                structDescription += "Anel atrioventricular"
                            }

                            if (ablationItem.map_atriovent_node) {
                                if (structDescription !== "") {
                                    structDescription += "\n";
                                }
                                structDescription += "Nó atrioventricular"
                            }

                            if (structDescription !== "") {
                                ablationToPrint.push({
                                    item: "Estruturas mapeadas",
                                    result: structDescription
                                });
                            }
                        }

                        if (ablationItem.sinus_rhythm) {
                            ablationToPrint.push({
                                item: "A ablação foi realizada durante ritmo sinusal",
                                result: ""
                            });
                        }

                        if (ablationItem.induced_tachycardia) {
                            ablationToPrint.push({
                                item: "A ablação foi realizada durante a taquicardia induzida",
                                result: ""
                            });
                        }

                        if (ablationItem.ventricular_pacing) {
                            ablationToPrint.push({
                                item: 'A ablação foi realizada durante "pacing" ventricular',
                                result: ""
                            });
                        }

                        if (ablationItem.atrium_pacing) {
                            ablationToPrint.push({
                                item: 'A ablação foi realizada durante "pacing" atrial',
                                result: ""
                            });
                        }

                        if (ablationItem.junctional_rhythm) {
                            ablationToPrint.push({
                                item: "Presença de ritmo juncional durante a ablação",
                                result: ""
                            });
                        }

                        if (ablationItem.exhaust) {
                            ablationToPrint.push({
                                item: "Escape após ablação em ms",
                                result: ablationItem.exhaust
                            });
                        }

                        if (ablationItem.success_criterion_description) {
                            ablationToPrint.push({
                                item: "Critério de sucesso usado na ablação de FA",
                                result: ablationItem.success_criterion_description
                            });
                        }

                        if (ablationItem.venography) {
                            ablationToPrint.push({
                                item: "Venografia de veias pulmonares",
                                result: ""
                            });
                        }

                        if (ablationItem.total_rx_time) {
                            ablationToPrint.push({
                                item: "Tempo total de Raio X em min",
                                result: ablationItem.total_rx_time
                            });
                        }

                        if (ablationItem.total_procedure_time) {
                            ablationToPrint.push({
                                item: "Tempo total de procedimento em min",
                                result: ablationItem.total_procedure_time
                            });
                        }

                        if (ablationItem.total_lesion_time) {
                            ablationToPrint.push({
                                item: "Tempo total de lesões em min",
                                result: ablationItem.total_lesion_time
                            });
                        }

                        if (ablationItem.interruption) {
                            ablationToPrint.push({
                                item: "Interrupção da arritmia durante ablação",
                                result: ""
                            });
                        }

                        if (ablationItem.tachycardia_reinduction) {
                            ablationToPrint.push({
                                item: "Reindução da taquicardia após ablação",
                                result: ""
                            });
                        }

                        if (ablationItem.trn_jump_persistence) {
                            ablationToPrint.push({
                                item: "Ablação de TRN final com persistência de salto",
                                result: ""
                            });
                        }

                        if (ablationItem.trn_eco_persistence) {
                            ablationToPrint.push({
                                item: "Ablação de TRN final com persistência de eco",
                                result: ""
                            });
                        }

                        if (ablationItem.flutter_block) {
                            ablationToPrint.push({
                                item: "Ablação de Flutter atrial comum final com linha de bloqueio bidirecional",
                                result: ""
                            });
                        }

                        if (ablationItem.isuprel) {
                            ablationToPrint.push({
                                item: "Isuprel foi usado nos testes finais",
                                result: ""
                            });
                        }

                        if (ablationItem.adenosine) {
                            ablationToPrint.push({
                                item: "Adenosina foi usada nos testes finais",
                                result: ""
                            });
                        }

                        doc.autoTable({
                            startY: linePosition,
                            head: [{
                                item: "Ablações da Taquicardia Induzida " + ablationItem.sequence,
                                result: "Resultado"
                            }],
                            body: ablationToPrint
                        });

                        linePosition = doc.lastAutoTable.finalY;
                        linePosition += 1.1;
                        checkBreak(doc);
                    }
                }
            }
        }

        if (scheduleReport.pacemakers) {
            const paceMakerInfo = scheduleReport.pacemakers;

            if (paceMakerInfo) {
                if (paceMakerInfo.implant_option || paceMakerInfo.supplier_id || paceMakerInfo.generator ||
                    paceMakerInfo.place_option || paceMakerInfo.defibrillation_option || paceMakerInfo.result_option || paceMakerInfo.complications ||
                    paceMakerInfo.observations) {

                    let pacemakerInfoToPrint = [];

                    if (paceMakerInfo.implant_option) {
                        pacemakerInfoToPrint.push({
                            item: "Implante de MP/CDI?",
                            result: paceMakerInfo.implant_description
                        });
                    }

                    if (paceMakerInfo.implant_date) {
                        pacemakerInfoToPrint.push({
                            item: "Data Primeiro Implante MP/CDI",
                            result: dayjs(paceMakerInfo.implant_date).format("DD/MM/YYYY")
                        });
                    }

                    if (paceMakerInfo.supplier_id) {
                        pacemakerInfoToPrint.push({
                            item: "Fabricante",
                            result: paceMakerInfo.supplier_name
                        });
                    }

                    if (paceMakerInfo.generator) {
                        pacemakerInfoToPrint.push({
                            item: "Gerador",
                            result: paceMakerInfo.generator
                        });
                    }

                    if (paceMakerInfo.place_option) {
                        pacemakerInfoToPrint.push({
                            item: "Local",
                            result: paceMakerInfo.place_description
                        });
                    }

                    if (paceMakerInfo.defibrillation_option) {
                        pacemakerInfoToPrint.push({
                            item: "Limiar de Desfibrilação",
                            result: paceMakerInfo.defibrillation_description
                        });
                    }

                    if (paceMakerInfo.result_option) {
                        pacemakerInfoToPrint.push({
                            item: "Resultado do Implante de MP/CDI",
                            result: paceMakerInfo.result_description
                        });
                    }

                    if (paceMakerInfo.complications) {
                        pacemakerInfoToPrint.push({
                            item: "Complicações do Implante de MP/CDI",
                            result: paceMakerInfo.complications
                        });
                    }

                    if (paceMakerInfo.observations) {
                        pacemakerInfoToPrint.push({
                            item: "Observação sobre o MP/CDI",
                            result: paceMakerInfo.observations
                        });
                    }

                    doc.autoTable({
                        startY: linePosition,
                        head: [{
                            item: "Informações MP/CDI",
                            result: "Resultado"
                        }],
                        body: pacemakerInfoToPrint,
                    });

                    linePosition = doc.lastAutoTable.finalY;
                    linePosition += 1.1;
                    checkBreak(doc);
                }
            }
        }

        if (scheduleReport.final_report) {
            const reportToPrint = [{ finalReport: convertStringToPrint(scheduleReport.final_report) }];
            doc.autoTable({
                startY: linePosition,
                head: [{ finalReport: "Laudo do Procedimento" }],
                body: reportToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        linePosition += 1.1;
        checkBreak(doc);

        if (scheduleReport.summary_conclusion) {
            const reportToPrint = [{ summaryConclusion: convertStringToPrint(scheduleReport.summary_conclusion) }];
            doc.autoTable({
                startY: linePosition,
                head: [{ summaryConclusion: "Conclusão Final" }],
                body: reportToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        linePosition += 1.1;
        checkBreak(doc);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.text(`Curitiba, PR, ${dayjs(scheduleReport.execution_date).format("DD/MM/YYYY")}`, 1.5, linePosition);

        linePosition += 0.8;
        checkBreak(doc);
        doc.text(`${scheduleReport.main_doctor_name} - CRM: ${scheduleReport.main_doctor_crm}`, 19.4, linePosition, { align: "right" });

        if (scheduleReport.first_assist_doctor_id) {
            linePosition += 1.3;
            checkBreak(doc);
            doc.text(`${scheduleReport.first_assist_doctor_name} - CRM: ${scheduleReport.first_assist_doctor_crm}`, 19.4, linePosition, { align: "right" });
        }

        if (scheduleReport.second_assist_doctor_id) {
            linePosition += 1.3;
            checkBreak(doc);
            doc.text(`${scheduleReport.second_assist_doctor_name} - CRM: ${scheduleReport.second_assist_doctor_crm}`, 19.4, linePosition, { align: "right" });
        }

        if (scheduleReport.third_assist_doctor_id) {
            linePosition += 1.3;
            checkBreak(doc);
            doc.text(`${scheduleReport.third_assist_doctor_name} - CRM: ${scheduleReport.third_assist_doctor_crm}`, 19.4, linePosition, { align: "right" });
        }


        doc.setFont("helvetica", "normal");
        doc.setFontSize(7);
        const pageCount = doc.internal.getNumberOfPages();
        for (let pageItem = 1; pageItem <= pageCount; pageItem++) {
            doc.setPage(pageItem);

            if (pageItem > 1) {
                printHeader(doc);
            }

            printFooter(doc);
            doc.text("Página " + pageItem + " de " + pageCount, 18, 28.8);
        }

        doc.save("laudo.pdf");
    }
}

function checkBreak(doc) {
    if (linePosition > 26.5) {
        doc.addPage();
        linePosition = 4.2;
    }
}

function printHeader(doc) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text("Nome:", 1.5, 2);
    doc.text("Sexo:", 14.5, 2);

    doc.setFont("helvetica", "normal");
    doc.text(scheduleReport.pacient_name, 3, 2);
    doc.text(scheduleReport.pacient_gender, 16, 2);

    doc.setFont("helvetica", "bold");
    doc.text("Idade:", 1.5, 2.6);

    doc.setFont("helvetica", "normal");
    const ageInStr = ageInString(dayjs(scheduleReport.pacient_birth_date).toDate());
    doc.text(ageInStr, 3, 2.6);
    // doc.addImage(imgLogo, "PNG", 8, 0.8, 5.8, 2.8);
}

function printFooter(doc) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(7);
    doc.text("LEC - Laboratório de Eletrofisiologia de Curitiba", 10.9, 28.2, { align: "right" });

    doc.setFont("helvetica", "normal");
    doc.text(" - Fones (41) 3262-8693 ou (41) 99933-9934", 10.9, 28.2);
    doc.text("Cândido de Abreu, 70 - Sala 1105 - Centro Cívico - Curitiba - PR - 80.530-000 - Email: eletrolec@leccuritiba.com.br", 10.55, 28.6, { align: "center" });
}

async function getScheduleReport(id) {
    return new Promise(resolve => {
        api.get(`/schedules/${id}`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.reports && response.data.reports.length > 0) {
                        resolve(response.data.reports[0]);
                    }
                }

                resolve(undefined);
            })
    });
}