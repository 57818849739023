import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { implantMainSymptomOptions } from "../../../enum/protocolEnums";
import {
    implantEcgOptions,
    implantHolterOptions,
    postImplantOptions
} from "../../../enum/followUpEnum";

const FollowImplantForm = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        followInfoParm,
        hide,
        ...attributes
    } = props;

    const [mainSymptom, setMainSymptom] = useState(null);
    const [otherMainSymptom, setOtherMainSymptom] = useState("");
    const [postImplant, setPostImplant] = useState(null);
    const [otherPostImplant, setOtherPostImplant] = useState("");
    const [ecg, setEcg] = useState(null);
    const [otherEcg, setOtherEcg] = useState("");
    const [holter, setHolter] = useState(null);
    const [otherHolter, setOtherHolter] = useState("");
    const [adLimit, setAdLimit] = useState("");
    const [vdLimit, setVdLimit] = useState("");
    const [veLimit, setVeLimit] = useState("");
    const [observation, setObservation] = useState("");

    useEffect(() => {
        setMainSymptom(followInfoParm.mainSymptom ?? null);
        setOtherMainSymptom(followInfoParm.otherMainSymptom ?? "");
        setPostImplant(followInfoParm.postImplant ?? null);
        setOtherPostImplant(followInfoParm.otherPostImplant ?? "");
        setEcg(followInfoParm.ecg ?? null);
        setOtherEcg(followInfoParm.otherEcg ?? "");
        setHolter(followInfoParm.holter ?? null);
        setOtherHolter(followInfoParm.otherHolter ?? "");
        setAdLimit(followInfoParm.adLimit ?? "");
        setVdLimit(followInfoParm.vdLimit ?? "");
        setVeLimit(followInfoParm.veLimit ?? "");
        setObservation(followInfoParm.observation ?? "");
    }, [followInfoParm]);

    return (
        <div hidden={hide}>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal</label>
                    <Select
                        autoFocus
                        defaultValue={mainSymptom}
                        value={mainSymptom}
                        onChange={(e) => {
                            setMainSymptom(e);

                            let fieldsChanges = [
                                {
                                    field: "mainSymptom",
                                    value: e
                                }
                            ];

                            if (e.value !== 5) {
                                setOtherMainSymptom("");

                                fieldsChanges.push({
                                    field: "otherMainSymptom",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={implantMainSymptomOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMainSymptom}
                        onChange={e => {
                            setOtherMainSymptom(e.target.value);
                            onChangeInfo([{ field: "otherMainSymptom", value: e.target.value }]);
                        }}
                        disabled={mainSymptom?.value !== 5}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>1 Visita pós implante</label>
                    <Select
                        autoFocus
                        defaultValue={postImplant}
                        value={postImplant}
                        onChange={(e) => {
                            setPostImplant(e);

                            let fieldsChanges = [
                                {
                                    field: "postImplant",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherPostImplant("");

                                fieldsChanges.push({
                                    field: "otherPostImplant",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={postImplantOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>1 Visita pós implante (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherPostImplant}
                        onChange={e => {
                            setOtherPostImplant(e.target.value);
                            onChangeInfo([{ field: "otherPostImplant", value: e.target.value }]);
                        }}
                        disabled={postImplant?.value !== 4}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>ECG</label>
                    <Select
                        autoFocus
                        defaultValue={ecg}
                        value={ecg}
                        onChange={(e) => {
                            setEcg(e);

                            let fieldsChanges = [
                                {
                                    field: "ecg",
                                    value: e
                                }
                            ];

                            if (e.value !== 4) {
                                setOtherEcg("");

                                fieldsChanges.push({
                                    field: "otherEcg",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={implantEcgOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>ECG (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherEcg}
                        onChange={e => {
                            setOtherEcg(e.target.value);
                            onChangeInfo([{ field: "otherEcg", value: e.target.value }]);
                        }}
                        disabled={ecg?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Holter</label>
                    <Select
                        defaultValue={holter}
                        value={holter}
                        onChange={(e) => {
                            setHolter(e);

                            let fieldsChanges = [
                                {
                                    field: "holter",
                                    value: e
                                }
                            ];

                            if (e.value !== 7) {
                                setOtherHolter("");

                                fieldsChanges.push({
                                    field: "otherHolter",
                                    value: ""
                                });
                            }

                            onChangeInfo(fieldsChanges);
                        }}
                        options={implantHolterOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Holter (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherHolter}
                        onChange={e => {
                            setOtherHolter(e.target.value);
                            onChangeInfo([{ field: "otherHolter", value: e.target.value }]);
                        }}
                        disabled={holter?.value !== 7}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Limiar AD (mV)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={adLimit}
                        onChange={e => {
                            setAdLimit(e.target.value);
                            onChangeInfo([{ field: "adLimit", value: e.target.value }]);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Limiar VD (mV)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={vdLimit}
                        onChange={e => {
                            setVdLimit(e.target.value);
                            onChangeInfo([{ field: "vdLimit", value: e.target.value }]);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Limiar VE (mV)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={veLimit}
                        onChange={e => {
                            setVeLimit(e.target.value);
                            onChangeInfo([{ field: "veLimit", value: e.target.value }]);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Observações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={observation}
                        onChange={e => {
                            setObservation(e.target.value);
                            onChangeInfo([{ field: "observation", value: e.target.value }]);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

FollowImplantForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

FollowImplantForm.defaultProps = {
    followInfoParm: {},
    hide: false
};

export default FollowImplantForm;