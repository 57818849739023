import dayjs from "dayjs";

export function generateReport(reportInfo) {
    let report = `Local onde o procedimento foi realizado: ${reportInfo.hospital.label}<br>`;

    if (reportInfo.reportType === "EEF") {
        report += "Foi realizado um estudo eletrofisiológico e mapeamento convencional simples<br>";
    } else {
        if (reportInfo.eefType === "CON") {
            report += "Foi realizado um estudo eletrofisiológico, mapeamento e ablação convencional<br>";
        } else if (reportInfo.eefType === "COM") {
            report += "Foi realizado um estudo eletrofisiológico, mapeamento eletro anatômico e ablação complexa<br>";
        } else if (reportInfo.eefType === "CRI") {
            report += "Foi realizado um estudo eletrofisiológico, mapeamento e ablação através da técnica de CIOABLAÇÃO<br>";
        }
    }

    const breaker = reportInfo.format === "TOP" ? "<br>" : ", ";

    if (reportInfo.reportType !== "ABL") {
        report += printFirstPart(reportInfo, breaker);
        report += printPunctionInfo(reportInfo.punctionInfo, breaker);
        report += printHeparinInfo(reportInfo, breaker);
        report += printSecondPart(reportInfo, breaker);
        report += printFinalPart(reportInfo, breaker);
        report += printEefInfo(reportInfo.eefInfo, breaker);
        report += printMaterialInfo(reportInfo.punctionInfo, reportInfo.materialInfo, reportInfo.format, breaker);
        report += printFinalInfo(reportInfo, breaker);
    } else {
        for (let reportItem = 0; reportItem < reportInfo.ablationList.length; reportItem++) {
            if (reportInfo.ablationList.length > 1) {
                report += `=======================================================================================${breaker}`;
            }

            const ablationItem = reportInfo.ablationList[reportItem];

            report += printFirstPart(reportInfo, breaker);
            report += printPunctionInfo(ablationItem?.punctionInfo ?? reportInfo.punctionInfo, breaker);
            report += printHeparinInfo(reportInfo, breaker);

            if (ablationItem.mapRightAtrium) {
                report += `o átrio direito foi mapeado${breaker}`;
            }

            if (ablationItem.mapLeftAtrium) {
                report += `o átrio esquerdo foi mapeado${breaker}`;
            }

            if (ablationItem.mapRightVentricle) {
                report += `o ventrículo direito foi mapeado${breaker}`;
            }

            if (ablationItem.mapLeftVentricle) {
                report += `o ventrículo esquerdo foi mapeado${breaker}`;
            }

            if (ablationItem.mapEpicardialSpace) {
                report += `o espaço epicárdico foi mapeado pela punção epicárdica${breaker}`;
            }

            if (ablationItem.mapAtrioventRing) {
                report += `o anel atrioventricular foi mapeado${breaker}`;
            }

            if (ablationItem.mapAtrioventNode) {
                report += `o nó atrioventricular foi mapeado${breaker}`;
            }

            report += printSecondPart(ablationItem, breaker);

            if (ablationItem.atrioventRelation) {
                report += `Relação atrioventricular durante ablação: ${ablationItem.atrioventRelation.label}${breaker}`;
            }

            if (ablationItem.hiddenRetraining) {
                report += `Encarrilhamento oculto presente${breaker}`;
            }

            if (ablationItem.hiddenRetrainingPlace) {
                report += `Local do encarrilhamento oculto: ${ablationItem.hiddenRetrainingPlace}${breaker}`;
            }

            if (ablationItem.mapActivation || ablationItem.mapPacemapping || ablationItem.mapElectro) {
                if (ablationItem.mapActivation || ablationItem.mapPacemapping) {
                    report += "Mapeamento realizado usando critérios de ";
                    let isFirst = true;
                    if (ablationItem.mapActivation) {
                        report += "Ativação";
                        isFirst = false;
                    }

                    if (ablationItem.mapPacemapping) {
                        if (!isFirst) {
                            report += ", ";
                        } else {
                            isFirst = false;
                        }

                        report += "Pacemapping";
                    }

                    report += `.${breaker}`;
                }

                if (ablationItem.mapElectro) {
                    report += `Realizado através de uso de bobinas eletromagnéticas abaixo do paciente conectadas com placas transcutâneas de bioimpedância, usando geometria de campo tridimensional para monitorização em tempo real dos cateteres de uso compatível com o sistema, permitindo sua determinação espacial precisa conforme anatomia do paciente e integrado com o polígrafo de eletrofisiologia, permitindo tanto o mapa de ativação a cores quanto o mapa eletroanatômico e de voltagem.${breaker}`;
                }

                if (ablationItem.mapSubType) {
                    report += `Subtipo de critério de mapeamento: ${ablationItem.mapSubType.label}${breaker}`;
                }

                if (ablationItem.sinusRhythm) {
                    report += `A ablação foi realizada durante ritmo sinusal${breaker}`;
                }

                if (ablationItem.inducedTachycardia) {
                    report += `A ablação foi realizada durante a taquicardia induzida${breaker}`;
                }

                if (ablationItem.ventricularPacing) {
                    report += `A ablação foi realizada durante “pacing” ventricular${breaker}`;
                }

                if (ablationItem.atriumPacing) {
                    report += `A ablação foi realizada durante “pacing” atrial${breaker}`;
                }

                if (ablationItem?.junctionalRhythm) {
                    report += `Presença de ritmo juncional durante a ablação${breaker}`;
                }

                if (ablationItem.exhaust) {
                    report += `Escape após ablação: ${ablationItem.exhaust}${breaker}`;
                }

                if (ablationItem.successCriterion) {
                    report += `Critério de sucesso usado na ablação de FA: ${ablationItem.successCriterion.label}${breaker}`;
                }

                if (ablationItem.venography) {
                    report += `Venografia de veias pulmonares${breaker}`;
                }

                if (ablationItem.totalRxTime) {
                    report += `Tempo total de Raio X: ${ablationItem.totalRxTime}${breaker}`;
                }

                if (ablationItem.totalProcedureTime) {
                    report += `Tempo total de procedimento: ${ablationItem.totalProcedureTime}${breaker}`;
                }

                if (ablationItem.totalLesionTime) {
                    report += `Tempo total de lesões: ${ablationItem.totalLesionTime}${breaker}`;
                }

                if (ablationItem.interruption) {
                    report += `Interrupção da arritmia durante ablação${breaker}`;
                }

                if (ablationItem.tachycardiaReinduction) {
                    report += `Reindução da taquicardia após ablação${breaker}`;
                }

                if (ablationItem.trnJumpPersistence) {
                    report += `Ablação de TRN final com persistência de salto${breaker}`;
                }

                if (ablationItem.trnEcoPersistence) {
                    report += `Ablação de TRN final com persistência de eco${breaker}`;
                }

                if (ablationItem.flutterBlock) {
                    report += `Obtenção da linha com bloqueio bidirecional no istmo${breaker}`;
                }

                if (ablationItem.isuprel) {
                    report += `Isuprel utilizado nos testes finais${breaker}`;
                }

                if (ablationItem.adenosine) {
                    report += `Adenosina utilizada nos testes finais${breaker}`;
                }
            }

            report += printFinalPart(reportInfo, breaker);
            report += printEefInfo(ablationItem.eefInfo, breaker);
            report += printMaterialInfo(ablationItem.punctionInfo, ablationItem.materialInfo, reportInfo.format, breaker);
        }

        report += printFinalInfo(reportInfo, breaker);
    }

    return report;
}

function printFirstPart(reportInfo, breaker) {
    let reportToPrint = "";

    reportToPrint += `Paciente na sala de procedimento${breaker}`;
    reportToPrint += `Punção venosa periférica${breaker}`;
    reportToPrint += `Monitorização cardíaca / oximetria${breaker}`;
    reportToPrint += `Eletrodos eletrocardiográficos para o polígrafo de eletrofisiologia${breaker}`;
    reportToPrint += `Eletrodos para o monitor do anestesista${breaker}`;
    reportToPrint += `Antissepsia de pele${breaker}`;

    if (reportInfo.anesthesiaType === "LOC") {
        reportToPrint += `Procedimento realizado apenas com anestesia local${breaker}`;
    } else if (reportInfo.anesthesiaType === "SED") {
        reportToPrint += `Procedimento realizado com anestesia local e sedação anestésica${breaker}`;
    } else if (reportInfo.anesthesiaType === "GER") {
        reportToPrint += `Procedimento realizado com anestesia geral com intubação orotraqueal${breaker}`;
    }

    reportToPrint += `Campos Cirúrgicos${breaker}`;
    reportToPrint += `Uso de intensificador de imagem por Raio X (Fluoroscopia)${breaker}`;
    reportToPrint += `Sob anestesia local, foram realizadas as seguintes punções:${breaker}`;

    return reportToPrint;
}

function printPunctionInfo(punctionInfo, breaker) {
    let reportToPrint = "";

    if (punctionInfo.punction1pfd) {
        reportToPrint += `Uma punção venosa femoral direita${breaker}`;
    }

    if (punctionInfo.punction2pfd) {
        reportToPrint += `Duas punções venosas femorais direitas${breaker}`;
    }

    if (punctionInfo.punction3pfd) {
        reportToPrint += `Três punções venosas femorais direitas${breaker}`;
    }

    if (punctionInfo.punction1pfe) {
        reportToPrint += `Uma punção venosa femoral esquerda${breaker}`;
    }

    if (punctionInfo.punction1pj) {
        reportToPrint += `Uma punção venosa jugular${breaker}`;
    }

    if (punctionInfo.punction1ps) {
        reportToPrint += `Uma punção venosa subclávia${breaker}`;
    }

    if (punctionInfo.punction1paf) {
        reportToPrint += `Uma punção arterial femoral${breaker}`;
    }

    if (punctionInfo.punction1pe) {
        reportToPrint += `Uma punção epicárdica${breaker}`;
    }

    if (punctionInfo.punction1pt) {
        reportToPrint += `Uma punção transeptal${breaker}`;
    }

    if (punctionInfo.punction2pt) {
        reportToPrint += `Duas punções transeptais${breaker}`;
    }

    if ((punctionInfo.punction7f && punctionInfo.punction7f !== "0") || (punctionInfo.punction8f && punctionInfo.punction8f !== "0")) {
        reportToPrint += `No total, foram usadas as seguintes bainhas curtas${breaker}`;

        if (punctionInfo.punction7f && +punctionInfo.punction7f > 0) {
            reportToPrint += `Introdutor curto 7F: ${punctionInfo.punction7f} unidade(s)${breaker}`;
        }

        if (punctionInfo.punction8f && +punctionInfo.punction8f > 0) {
            reportToPrint += `Introdutor curto 8F: ${punctionInfo.punction8f} unidade(s)${breaker}`;
        }
    }

    if (punctionInfo.punction1blc) {
        reportToPrint += `Foi usada uma bainha longa convencional para apoio ou punção transeptal${breaker}`;
    }

    if (punctionInfo.punction2blc) {
        reportToPrint += `Foram usadas duas bainhas longas convencionais para apoio ou punção transeptal${breaker}`;
    }

    if (punctionInfo.punction1blev) {
        reportToPrint += `Foi usada uma bainha longa especial VISIGO${breaker}`;
    }

    if (punctionInfo.punction1blea) {
        reportToPrint += `Foi usada uma bainha longa especial AGILIS${breaker}`;
    }

    return reportToPrint;
}

function printHeparinInfo(reportInfo, breaker) {
    let reportToPrint = "";

    if (reportInfo.heparin) {
        reportToPrint += `Heparina endovenosa não foi usada durante o procedimento${breaker}`;
    } else if (reportInfo.heparinQuantity) {
        reportToPrint += `Heparina endovenosa foi usada durante o procedimento num total de ${reportInfo.heparinQuantity} unidades sempre com monitorização rigorosa do TCA em sala de procedimento${breaker}`;
    }

    reportToPrint += `Os cateteres, com seus conectores respectivos integrados ao polígrafo de eletrofisiologia, foram colocados através dos introdutores curtos e/ou longos respectivos${breaker}`;
    reportToPrint += `Realização de estudo eletrofisiológico e mapeamento de feixes elétricos com posicionamento dos cateteres intracardíacos em tempos específicos, onde:${breaker}`;

    return reportToPrint;
}

function printSecondPart(reportInfo, breaker) {
    let reportToPrint = "";

    reportToPrint += `O procedimento revelou os seguintes achados eletrofisiológicos:${breaker}`;
    reportToPrint += `Parâmetros eletrofisiológicos mensurados pertinentes para este laudo:${breaker}`;

    if (reportInfo.eefInfo.basalRythm) {
        if (reportInfo.eefInfo.otherBasalRythm === "") {
            reportToPrint += `Ritmo basal: ${reportInfo.eefInfo.basalRythm.label}${breaker}`;
        } else {
            reportToPrint += `Ritmo basal: ${reportInfo.eefInfo.otherBasalRythm}${breaker}`;
        }
    }

    if (reportInfo.eefInfo.ventricularRate) {
        reportToPrint += `Frequência ventricular basal: ${reportInfo.eefInfo.ventricularRate}${breaker}`;
    }

    if (reportInfo.eefInfo.atrialRate) {
        reportToPrint += `Frequência atrial basal: ${reportInfo.eefInfo.atrialRate}${breaker}`;
    }

    if (reportInfo.eefInfo.prInterval) {
        reportToPrint += `Intervalo PR basal: ${reportInfo.eefInfo.prInterval}${breaker}`;
    }

    if (reportInfo.eefInfo.qtcInterval) {
        reportToPrint += `Intervalo QTc basal: ${reportInfo.eefInfo.qtcInterval}${breaker}`;
    }

    if (reportInfo.eefInfo.ecgReport && reportInfo.eefInfo.ecgReport.length > 0) {
        reportToPrint += `Laudo do ECG basal: `;

        for (let ecgIndex = 0; ecgIndex < reportInfo.eefInfo.ecgReport.length; ecgIndex++) {
            if (ecgIndex > 0) {
                reportToPrint += " / ";
            }

            reportToPrint += reportInfo.eefInfo.ecgReport[ecgIndex].label;
        }
        reportToPrint += `${breaker}`;
    }

    if (reportInfo.eefInfo.ahInterval) {
        reportToPrint += `Intervalo AH: ${reportInfo.eefInfo.ahInterval}${breaker}`;
    }

    if (reportInfo.eefInfo.hvInterval) {
        reportToPrint += `Intervalo HV: ${reportInfo.eefInfo.hvInterval}${breaker}`;
    }

    if (reportInfo.eefInfo.qrsInterval) {
        reportToPrint += `Intervalo QRS: ${reportInfo.eefInfo.qrsInterval}${breaker}`;
    }

    if (reportInfo.eefInfo.sinusNodeRecovery) {
        reportToPrint += `Tempo corrigido de recuperação do nó sinusal: ${reportInfo.eefInfo.sinusNodeRecovery}${breaker}`;
    }

    if (reportInfo.eefInfo.bav) {
        reportToPrint += `Ponto de Wenckebach (BAV) do nó atrioventricular: ${reportInfo.eefInfo.bav}${breaker}`;
    }

    if (reportInfo.eefInfo.atrioventPeriodAnterograde) {
        reportToPrint += `Período refratário efetivo anterógrado do nó atrioventricular (Sem dupla vida nodal): ${reportInfo.eefInfo.atrioventPeriodAnterograde}${breaker}`;
    }

    if (reportInfo.eefInfo.atrioventPeriodFast) {
        reportToPrint += `Período refratário efetivo anterógrado da via rápida do nó atrioventricular: ${reportInfo.eefInfo.atrioventPeriodFast}${breaker}`;
    }

    if (reportInfo.eefInfo.atrioventPeriodSlow) {
        reportToPrint += `Período refratário efetivo anterógrado da via lenta do nó atrioventricular: ${reportInfo.eefInfo.atrioventPeriodSlow}${breaker}`;
    }

    if (reportInfo.eefInfo.bva) {
        reportToPrint += `Ponto de Wenckebach (BVA) retrógrado do nó atrioventricular: ${reportInfo.eefInfo.bva}${breaker}`;
    }

    if (reportInfo.eefInfo.atrioventPeriodRetrograde) {
        reportToPrint += `Período refratário retrógrado do nó atrioventricular: ${reportInfo.eefInfo.atrioventPeriodRetrograde}${breaker}`;
    }

    if (reportInfo.eefInfo.atriumPeriod) {
        reportToPrint += `Período refratário efetivo do átrio: ${reportInfo.eefInfo.atriumPeriod}${breaker}`;
    }

    if (reportInfo.eefInfo.ventriclePeriod) {
        reportToPrint += `Período refratário efetivo do ventrículo: ${reportInfo.eefInfo.ventriclePeriod}${breaker}`;
    }

    if (reportInfo.eefInfo.accessoryPath) {
        reportToPrint += `Via acessória manifesta presente: ${reportInfo.eefInfo.accessoryPath.label}${breaker}`;
    }

    if (reportInfo.eefInfo.accessoryPathAnterograde) {
        reportToPrint += `Período refratária efetivo anterógrado da via acessória: ${reportInfo.eefInfo.accessoryPathAnterograde}${breaker}`;
    }

    if (reportInfo.eefInfo.accessoryPathRetrograde) {
        reportToPrint += `Período refratário efetivo retrógrado da via acessória: ${reportInfo.eefInfo.accessoryPathRetrograde}${breaker}`;
    }

    if (reportInfo.eefInfo.inducedTachycardia) {
        reportToPrint += `Taquicardia induzida: ${reportInfo.eefInfo.inducedTachycardia.label}${breaker}`;
    }

    if (reportInfo.eefInfo.tachycardiaMode) {
        reportToPrint += `Modo de indução da taquicardia: ${reportInfo.eefInfo.tachycardiaMode}${breaker}`;
    }

    if (reportInfo.eefInfo.tachycardiaCycle) {
        reportToPrint += `Ciclo da taquicardia induzida: ${reportInfo.eefInfo.tachycardiaCycle}${breaker}`;
    }

    if (reportInfo.eefInfo.tachycardiaEcgReport) {
        reportToPrint += `Laudo do ECG da taquicardia induzida: ${reportInfo.eefInfo.tachycardiaEcgReport}${breaker}`;
    }

    return reportToPrint;
}

function printFinalPart(reportInfo, breaker) {
    let reportToPrint = "";

    reportToPrint += `Testes eletrofisiológicos finais${breaker}`;
    reportToPrint += `Retirada dos cateteres${breaker}`;
    reportToPrint += `Retirada dos introdutores${breaker}`;

    if (reportInfo.bandageRightInguinal) {
        reportToPrint += `Curativo compressivo na região inguinal direita${breaker}`;
    }

    if (reportInfo.bandageLeftInguinal) {
        reportToPrint += `Curativo compressivo na região inguinal esquerda${breaker}`;
    }

    if (reportInfo.bandageJugular) {
        reportToPrint += `Curativo na região jugular direita${breaker}`;
    }

    if (reportInfo.bandageSubclavian) {
        reportToPrint += `Curativo na região subclávia direita${breaker}`;
    }

    if (reportInfo.bandageXiphoid) {
        reportToPrint += `Curativo na região xifóide/epicárdica${breaker}`;
    }

    reportToPrint += `Fim do procedimento anestésico${breaker}`;
    reportToPrint += `Paciente já acordado, com dados vitais normais${breaker}`;
    reportToPrint += `Exame segmentar normal e neurológico normais${breaker}`;
    reportToPrint += `Fim do procedimento${breaker}`;

    if (reportInfo.summaryConclusion) {
        reportToPrint += `Conclusão descritiva do procedimento:<br>${reportInfo.summaryConclusion}<br>`;
    }

    return reportToPrint;
}

function printEefInfo(eefInfo, breaker) {
    let reportToPrint = "";

    if (eefInfo.inducedTachycardia) {
        reportToPrint += `Detalhes eletrofisiológicos da arritmia induzida:${breaker}`;

        if (eefInfo.inducedTachycardia.value === "TRN") {
            if (eefInfo.commonTrnFastSlow) {
                reportToPrint += `TRN comum fast-slow${breaker}`;
                if (eefInfo.commonTrnFastSlowPlace) {
                    reportToPrint += `  Local da via lenta: ${eefInfo.commonTrnFastSlowPlace.label}${breaker}`;
                }
            }

            if (eefInfo.atypicalTrnSlowSlow) {
                reportToPrint += `TRN atípica slow-slow${breaker}`;
                if (eefInfo.atypicalTrnSlowSlowPlace) {
                    reportToPrint += `  Local da via lenta: ${eefInfo.atypicalTrnSlowSlowPlace.label}${breaker}`;
                }
            }

            if (eefInfo.atypicalTrnFastSlow) {
                reportToPrint += `TRN atípica fast-slow${breaker}`;
                if (eefInfo.atypicalTrnFastSlowPlace) {
                    reportToPrint += `  Local da via lenta: ${eefInfo.atypicalTrnFastSlowPlace.label}${breaker}`;
                }
            }
        }

        if (eefInfo.inducedTachycardia.value === "TVA") {
            if (eefInfo.tvaCommonShown) {
                reportToPrint += `Via acessória manifesta comum:${breaker}`;
                if (eefInfo.tvaCommonShownBavAnterograde) {
                    reportToPrint += `  BAV anterógrado da via acessória comum: ${eefInfo.tvaCommonShownBavAnterograde}${breaker}`;
                }
                if (eefInfo.tvaCommonShownBavRetrograde) {
                    reportToPrint += `  BAV retrógrado da via acessória comum: ${eefInfo.tvaCommonShownBavRetrograde}${breaker}`;
                }
                if (eefInfo.tvaCommonShownAnterogradePeriod) {
                    reportToPrint += `  Período refratário anterógrado da via acessória comum: ${eefInfo.tvaCommonShownAnterogradePeriod}${breaker}`;
                }
                if (eefInfo.tvaCommonShownRetrogradePeriod) {
                    reportToPrint += `  Período refratário retrógrado da via acessória comum: ${eefInfo.tvaCommonShownRetrogradePeriod}${breaker}`;
                }
            }

            if (eefInfo.tvaAtypicalShown) {
                reportToPrint += `Via acessória manifesta atípica (Mahaim):${breaker}`;
                if (eefInfo.tvaAtypicalShownAtriumVentricular) {
                    reportToPrint += `  átrio-ventricular${breaker}`;
                }
                if (eefInfo.tvaAtypicalShownAtriumFascicular) {
                    reportToPrint += `  átrio-fascicular${breaker}`;
                }
                if (eefInfo.tvaAtypicalShownNodeVentricular) {
                    reportToPrint += `  nodo-ventricular${breaker}`;
                }
                if (eefInfo.tvaAtypicalShownNodeFascicular) {
                    reportToPrint += `  nodo-fascicular${breaker}`;
                }
                if (eefInfo.tvaAtypicalShownFascicleVentricular) {
                    reportToPrint += `  fasículo-ventricular${breaker}`;
                }
            }

            if (eefInfo.tvaCommonHidden) {
                reportToPrint += `Via acessória oculta comum:${breaker}`;
                if (eefInfo.tvaCommonHiddenBva) {
                    reportToPrint += `  BVA da via acessória oculta comum: ${eefInfo.tvaCommonHiddenBva}${breaker}`;
                }
                if (eefInfo.tvaCommonHiddenRetrogradePeriod) {
                    reportToPrint += `  Período refratário retrógrado da via acessória oculta comum: ${eefInfo.tvaCommonHiddenRetrogradePeriod}${breaker}`;
                }
            }

            if (eefInfo.tvaAtypicalHidden) {
                reportToPrint += `Via acessória oculta atípica (Coumel):${breaker}`;
                if (eefInfo.tvaAtypicalHiddenBva) {
                    reportToPrint += `  BVA da via acessória oculta atípica de Coumel: ${eefInfo.tvaAtypicalHiddenBva}${breaker}`;
                }
                if (eefInfo.tvaAtypicalHiddenRetrogradePeriod) {
                    reportToPrint += `  Período refratário retrógrado da via acessória oculta atípica: ${eefInfo.tvaAtypicalHiddenRetrogradePeriod}${breaker}`;
                }
            }
        }

        if (eefInfo.inducedTachycardia.value === "TA") {
            reportToPrint += `Taquicardia atrial:${breaker}`;
            if (eefInfo.taAutomatic) {
                reportToPrint += `  Automática${breaker}`;
            }
            if (eefInfo.taReentrant) {
                reportToPrint += `  Reentrante${breaker}`;
            }
            if (eefInfo.taTriggered) {
                reportToPrint += `  Deflagrada${breaker}`;
            }
        }

        if (eefInfo.inducedTachycardia.value === "FLA") {
            reportToPrint += `Flutter atrial:${breaker}`;
            if (eefInfo.flaCommonAntiClockwise) {
                reportToPrint += `  Comum anti-horário istmo cavotricúspide dependente${breaker}`;
            }
            if (eefInfo.flaCommonClockwise) {
                reportToPrint += `  Comum horário istmo cavotricúspide dependente${breaker}`;
            }
            if (eefInfo.flaAtypicalRight) {
                reportToPrint += `  Atípico de átrio direito${breaker}`;
            }
            if (eefInfo.flaAtypicalLeft) {
                reportToPrint += `  Atípico de átrio esquerdo${breaker}`;
            }
        }

        if (eefInfo.inducedTachycardia.value === "TV") {
            reportToPrint += `Taquicardia ventricular de:${breaker}`;
            if (eefInfo.tvNormal) {
                reportToPrint += `  Coração normal${breaker}`;
            }
            if (eefInfo.tvIschemic) {
                reportToPrint += `  Coração com cicatriz isquêmica${breaker}`;
            }
            if (eefInfo.tvChagastic) {
                reportToPrint += `  Coração com cicatriz Chagástica${breaker}`;
            }
            if (eefInfo.tvOther) {
                reportToPrint += `  Coração com cicatriz por outra doença${breaker}`;
            }
        }

        if (eefInfo.inducedTachycardia.value === "NAT") {
            if (eefInfo.natEscape) {
                reportToPrint += `Ablação do nó atrioventricular com escape de: ${eefInfo.natBpm ?? ""} batimentos por minuto${breaker}`;
            }
            if (eefInfo.natNoEscape) {
                reportToPrint += `Ablação do nó atrioventricular sem escape${breaker}`;
            }
        }

        if (eefInfo.inducedTachycardia.value === "FA") {
            reportToPrint += `Foram abordados:${breaker}`;
            if (eefInfo.faOstium) {
                reportToPrint += `  Óstio das veias pulmonares${breaker}`;
            }
            if (eefInfo.faLeftBox) {
                reportToPrint += `  Box da parede posterior do átrio esquerdo${breaker}`;
            }
            if (eefInfo.faEarpiece) {
                reportToPrint += `  Auriculeta${breaker}`;
            }
            if (eefInfo.faLeftLine) {
                reportToPrint += `  Linha de lesão acessória no átrio esquerdo${breaker}`;
            }
            if (eefInfo.faIstmo) {
                reportToPrint += `  Istmo cavo-tricúspide${breaker}`;
            }
            if (eefInfo.faRightLine) {
                reportToPrint += `  Linha de lesão acessória no átrio direito${breaker}`;
            }
        }

        if (eefInfo.inducedTachycardia.value === "OUT") {
            reportToPrint += `Taquicardia clínica não induzida ou não indutível, descrita como:${breaker}`;
            if (eefInfo.outTa) {
                reportToPrint += `  Taquicardia atrial${breaker}`;
            }
            if (eefInfo.outFla) {
                reportToPrint += `  Flutter atrial${breaker}`;
            }
            if (eefInfo.outTrn) {
                reportToPrint += `  Taquicardia por reentrada nodal comum${breaker}`;
            }
            if (eefInfo.outTva) {
                reportToPrint += `  Taquicardia por via acessória${breaker}`;
            }
            if (eefInfo.outTv) {
                reportToPrint += `  Taquicardia ventricular${breaker}`;
            }
            if (eefInfo.outEa) {
                reportToPrint += `  Extrassístole atrial${breaker}`;
            }
            if (eefInfo.outEv) {
                reportToPrint += `  Extrassístole ventricular${breaker}`;
            }
            if (eefInfo.outOther) {
                if (eefInfo.outOtherText) {
                    reportToPrint += `  ${eefInfo.outOtherText}${breaker}`;
                }
            }

            if (eefInfo.outComment) {
                reportToPrint += `Motivo da ablação empírica e comentários eventuais dessa abordagem:<br>${eefInfo.outComment}<br>`;
            }
        }
    }

    return reportToPrint;
}

function printMaterialInfo(punctionInfo, materialInfo, reportFormat, breaker) {
    let reportToPrint = "";

    reportToPrint += "Resumo dos materiais utilizados: ";

    if (punctionInfo.punction8f) {
        reportToPrint += `Introdutor 8F: ${punctionInfo.punction8f} unidade(s)${breaker}`;
    }

    if (punctionInfo.punction7f) {
        reportToPrint += `Introdutor 7F: ${punctionInfo.punction7f} unidade(s)${breaker}`;
    }

    if (materialInfo.catheterQuad) {
        reportToPrint += `Cateter quadripolar e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterDeca) {
        reportToPrint += `Cateter decapolar e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterConv4mm) {
        reportToPrint += `Cateter de ablação convencional de 4mm e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterConv8mm) {
        reportToPrint += `Cateter de ablação convencional de 8mm e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterIrriContact) {
        reportToPrint += `Cateter de ablação irrigada com força de contato e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterIrriEnergy) {
        reportToPrint += `Cateter de ablação irrigada com força de contato usando alta energia e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterIrriElectro) {
        reportToPrint += `Cateter de ablação irrigada com orça de contato e ELETROPORAÇÃO e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterPumpKit) {
        reportToPrint += `Kit com bomba de infusão para cateter irrigado${breaker}`;
    }

    if (materialInfo.catheterPenta) {
        reportToPrint += `Cateter Penta Array e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterOcta) {
        reportToPrint += `Cateter Octa Array e conector respectivo${breaker}`;
    }

    if (materialInfo.catheterGrid) {
        reportToPrint += `Cateter HD GRID e conector respectivo${breaker}`;
    }

    if (materialInfo.bioimpedanceBoard) {
        reportToPrint += `Placas de bioimpedância${breaker}`;
    }

    if (materialInfo.faCryoablationKit) {
        if (reportFormat === "TOP") {
            reportToPrint += "KIT Crioablação FA:<br>";
            reportToPrint += " - Bainha longa 12 Flex Cath<br>";
            reportToPrint += " - Agulha de Touhy 16F<br>";
            reportToPrint += " - Cateter balão de crio ablação<br>";
            reportToPrint += " - Cateter Circular ACHIEVE<br>";
            reportToPrint += " - Cabo Coaxial<br>";
            reportToPrint += " - Cabo elétrico<br>";
        } else {
            reportToPrint += `KIT Crioablação FA:
            Bainha longa 12 Flex Cath
            Agulha de Touhy 16F
            Cateter balão de crio ablação
            Cateter Circular ACHIEVE
            Cabo Coaxial
            Cabo elétrico${breaker}`;
        }
    }

    if (materialInfo.focalCryoablationKit) {
        if (reportFormat === "TOP") {
            reportToPrint += "KIT Crioablação focal:<br>";
            reportToPrint += " - Cateter focal de crio ablação<br>";
            reportToPrint += " - Cabo Coaxial<br>";
            reportToPrint += " - Cabo elétrico<br>";
        } else {
            reportToPrint += `KIT Crioablação focal:
            Cateter focal de crio ablação
            Cabo Coaxial
            Cabo elétrico${breaker}`;
        }
    }

    if (materialInfo.brokenbroughtNeedle) {
        reportToPrint += `Agulha de Brokenbrought${breaker}`;
    }

    if (materialInfo.intracardiacContrast) {
        reportToPrint += `Contraste intracardíaco${breaker}`;
    }

    if (materialInfo.echocardiogramCatheter) {
        reportToPrint += `Sonda de Ecocardiograma intracardíaco e conector respectivo${breaker}`;
    }

    if (materialInfo.oroesophagealThermometer) {
        reportToPrint += `Termômetro oroesofágico${breaker}`;
    }

    if (materialInfo.pam) {
        reportToPrint += `PAM${breaker}`;
    }

    if (materialInfo.bladderCatheter) {
        reportToPrint += `Sonda vesical de demora${breaker}`;
    }

    if (materialInfo.punctionNeedle) {
        reportToPrint += `Agulha de punção venosa central: ${materialInfo.punctionNeedle}${breaker}`;
    }

    if (materialInfo.salineSolution) {
        reportToPrint += `Soro fisiológico 250ml com 1 ampola de isoprenalina EV gota a gota para testes eletrofisiológicos${breaker}`;
    }

    if (materialInfo.evAdenosine) {
        reportToPrint += `Adenosina 3 ampolas EV para testes eletrofisiológicos${breaker}`;
    }

    return reportToPrint;
}

function printFinalInfo(reportInfo, breaker) {
    let reportToPrint = "";

    reportToPrint += `Soro fisiológico 500ml para apoio na mesa cirúrgica${breaker}`;
    reportToPrint += `Seringas de 20ml e 5 ml para apoio na mesa cirúrgica${breaker}`;

    if (reportInfo.reportType === "EEF" || (reportInfo.reportType === "ABL" && (reportInfo.eefType === "CON" || reportInfo.eefType === "COM"))) {
        reportToPrint += "De acordo com a descrição acima, serão cobrados os seguintes códigos de procedimento:<br>";

        if (reportInfo.reportType === "EEF") {
            reportToPrint += "  30918014 - Estudo eletrofisiológico<br>";
            reportToPrint += "  30918022 - Mapeamento de feixes anômalos<br>";
        } else {
            if (reportInfo.eefType === "CON") {
                reportToPrint += "  30918013 - Estudo eletrofisiológico<br>";
                reportToPrint += "  30918022 - Mapeamento de feixes anômalos<br>";
                reportToPrint += "  30918073 - Ablação percutânea por cateter<br>";
            } else if (reportInfo.eefType === "COM") {
                reportToPrint += "  30918014 - Estudo eletrofisiológico<br>";
                reportToPrint += "  30918022 - Mapeamento de feixes anômalos<br>";
                reportToPrint += "  30918081 - Ablação percutânea por cateter<br>";
                reportToPrint += "  30918065 - Punção transeptal<br>";
                reportToPrint += "  30918030 - Mapeamento eletro-anatômico<br>";
                reportToPrint += "  30918057 - Punção de saco pericárdico<br>";
            }
        }
    }

    reportToPrint += `Anestesiologista responsável durante o procedimento: Equipe anestésica do ${reportInfo.hospital.label}${breaker}`;
    reportToPrint += `Cirurgião Principal: ${reportInfo.mainDoctor.label}${breaker}`;

    if (reportInfo.firstAssistDoctor) {
        reportToPrint += `Primeiro auxiliar: ${reportInfo.firstAssistDoctor.label}${breaker}`;
    }

    if (reportInfo.secondAssistDoctor) {
        reportToPrint += `Segundo auxiliar: ${reportInfo.secondAssistDoctor.label}${breaker}`;
    }

    reportToPrint += `Data da realização do procedimento: ${dayjs(reportInfo.procedureDate).format("DD/MM/YYYY")}.`;

    return reportToPrint;
}