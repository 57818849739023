import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    Modal,
    Tab,
    Tabs
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import { useFiles } from "../../../context/files";
import UploadFile from "../../components/UploadFile/UploadFile";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import EvTvForm from "./EvTvForm";
import ImplantForm from "./ImplantForm";

import api from "../../../services/api";
import FaForm from "./FaForm";
import { ablationEvTvOptions, ablationOptions, ablationPlaceOptions, ablationTypeOptions, actualDaaOptions, anticoagOptions, cdiPreventOptions, cdiShockYesNoOptions, ecgConsultOptions, ecgCrisisOptions, ecgOptions, evMiocNonIsqOptions, evTvOptions, faDiagnosisOptions, faMiocNonIsqOptions, faSessionOptions, faTypeOptions, genericNormalAnormalOptions, holterOptions, implantAccessTypeOptions, implantMainSymptomOptions, implantMiocNonIsqOptions, implantReasonOptions, implantResultOptions, implantTypeOptions, implantVeElectrodeOptions, implantVeinOptions, mainSymptomOptions, meaOptions, protocolYesNoOptions, ventriArrhythmiaEcgOptions, ventriArrhythmiaOptions } from "../../../enum/protocolEnums";
import { createLog } from "../../../services/logService";

const ProtocolForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingProtocolForm, setLoadingProtocolForm] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Salvando Protocolo...");

    const [schedule, setSchedule] = useState({});

    const [procedureCode, setProcedureCode] = useState("");
    const [protocolType, setProtocolType] = useState("");

    const [protocolInfo, setProtocolInfo] = useState({});
    const [protocolId, setProtocolId] = useState(undefined);

    const { uploadedFiles, resetFiles, loadFiles } = useFiles();

    useImperativeHandle(ref, () => ({
        async openModal(scheduleInfo) {
            setModalBox(true);
            setIsUpdate(false);

            setSchedule(scheduleInfo);

            const procedureCode = scheduleInfo.procedure_name.substr(0, scheduleInfo.procedure_name.indexOf("-") - 1).trim();
            setProcedureCode(procedureCode);
            setProtocolType(`Protocolo de ${procedureCode}`);

            resetFiles();

            if (scheduleInfo.protocols) {
                if (scheduleInfo.protocols.length > 0) {
                    loadProtocol(scheduleInfo.protocols[0]);
                    setIsUpdate(true);
                }
            }         
        },
    }));

    function loadProtocol(protocol) {
        let protocolReturned = {};

        setProtocolId(protocol._id);

        if (protocol?.main_symptom >= 0) {
            let mainSymptomOption = null;

            if (protocol.procedure_id === "MP" || protocol.procedure_id === "CDI") {
                mainSymptomOption = implantMainSymptomOptions.find(x => x.value === protocol.main_symptom);
            } else {
                mainSymptomOption = mainSymptomOptions.find(x => x.value === protocol.main_symptom);
            }

            protocolReturned.mainSymptom = mainSymptomOption;
        }

        if (protocol.other_main_symptom) {
            protocolReturned.otherMainSymptom = protocol.other_main_symptom;
        }

        if (protocol.functional_class) {
            protocolReturned.functionalClass = protocol.functional_class;
        }

        if (protocol?.ventri_arrhythmia >= 0) {
            const ventriArrhythmiaOption = ventriArrhythmiaOptions.find(x => x.value === protocol.ventri_arrhythmia);
            protocolReturned.ventriArrhythmia = ventriArrhythmiaOption;
        }

        if (protocol.other_ventri_arrhythmia) {
            protocolReturned.otherVentriArrhythmia = protocol.other_ventri_arrhythmia;
        }

        if (protocol?.ventri_arrhythmia_ecg >= 0) {
            let ventriArrhythmiaEcgOption = null;

            if (protocol.procedure_id === "MP" || protocol.procedure_id === "CDI") {
                ventriArrhythmiaEcgOption = ecgOptions.find(x => x.value === protocol.ventri_arrhythmia_ecg);
            } else {
                ventriArrhythmiaEcgOption = ventriArrhythmiaEcgOptions.find(x => x.value === protocol.ventri_arrhythmia_ecg);
            }

            protocolReturned.ventriArrhythmiaEcg = ventriArrhythmiaEcgOption;
        }

        if (protocol.other_ventri_arrhythmia_ecg) {
            protocolReturned.otherVentriArrhythmiaEcg = protocol.other_ventri_arrhythmia_ecg;
        }

        if (protocol.start_month) {
            protocolReturned.startMonth = protocol.start_month;
        }

        if (protocol.ventri_arrhythmia_holter) {
            protocolReturned.ventriArrhythmiaHolter = protocol.ventri_arrhythmia_holter;
        }

        if (protocol.holter) {
            const holterOption = holterOptions.find(x => x.value === protocol.holter);
            protocolReturned.holter = holterOption;
        }

        if (protocol.other_holter) {
            protocolReturned.otherHolter = protocol.other_holter;
        }

        if (protocol.ae) {
            protocolReturned.ae = protocol.ae;
        }

        if (protocol.ae_ml) {
            protocolReturned.aeMl = protocol.ae_ml;
        }

        if (protocol.ve) {
            protocolReturned.ve = protocol.ve;
        }

        if (protocol.septo) {
            protocolReturned.septo = protocol.septo;
        }

        if (protocol.ppost) {
            protocolReturned.ppost = protocol.ppost;
        }

        if (protocol.fe) {
            protocolReturned.fe = protocol.fe;
        }

        if (protocol?.mag_resso >= 0) {
            const magRessoOption = genericNormalAnormalOptions.find(x => x.value === protocol.mag_resso);
            protocolReturned.magResso = magRessoOption;
        }

        if (protocol?.angio_tomo >= 0) {
            const angioTomoOption = genericNormalAnormalOptions.find(x => x.value === protocol.angio_tomo);
            protocolReturned.angioTomo = angioTomoOption;
        }

        if (protocol?.cintilo >= 0) {
            const cintiloOption = genericNormalAnormalOptions.find(x => x.value === protocol.cintilo);
            protocolReturned.cintilo = cintiloOption;
        }

        if (protocol?.cate >= 0) {
            const cateOption = genericNormalAnormalOptions.find(x => x.value === protocol.cate);
            protocolReturned.cate = cateOption;
        }

        if (protocol.evtv) {
            const evtvOption = evTvOptions.find(x => x.value === protocol.evtv);
            protocolReturned.evtv = evtvOption;
        }

        if (protocol.other_evtv) {
            protocolReturned.otherEvtv = protocol.other_evtv;
        }

        if (protocol?.mioc_isq >= 0) {
            const miocIscOption = protocolYesNoOptions.find(x => x.value === protocol.mioc_isq);
            protocolReturned.miocIsq = miocIscOption;
        }

        if (protocol?.mioc_non_isq >= 0) {
            let miocNonIsqOption = null;

            if (protocol.procedure_id === "EV" || protocol.procedure_id === "TVS") {
                miocNonIsqOption = evMiocNonIsqOptions.find(x => x.value === protocol.mioc_non_isq);
            } else if (protocol.procedure_id === "MP" || protocol.procedure_id === "CDI") {
                miocNonIsqOption = implantMiocNonIsqOptions.find(x => x.value === protocol.mioc_non_isq);
            } else {
                miocNonIsqOption = faMiocNonIsqOptions.find(x => x.value === protocol.mioc_non_isq);
            }

            protocolReturned.miocNonIsq = miocNonIsqOption;
        }

        if (protocol.other_mioc_non_isq) {
            protocolReturned.otherMiocNonIsq = protocol.other_mioc_non_isq;
        }

        if (protocol?.has >= 0) {
            const hasOption = protocolYesNoOptions.find(x => x.value === protocol.has);
            protocolReturned.has = hasOption;
        }

        if (protocol?.diab_melli >= 0) {
            const diabMelliOption = protocolYesNoOptions.find(x => x.value === protocol.diab_melli);
            protocolReturned.diabMelli = diabMelliOption;
        }

        if (protocol?.cdi >= 0) {
            const cdiOption = protocolYesNoOptions.find(x => x.value === protocol.cdi);
            protocolReturned.cdi = cdiOption;
        }

        if (protocol.cdi_shock) {
            const cdiShockOption = cdiShockYesNoOptions.find(x => x.value === protocol.cdi_shock);
            protocolReturned.cdiShock = cdiShockOption;
        }

        if (protocol?.actual_daa >= 0) {
            const actualDaaOption = actualDaaOptions.find(x => x.value === protocol.actual_daa);
            protocolReturned.actualDaa = actualDaaOption;
        }

        if (protocol.other_actual_daa) {
            protocolReturned.otherActualDaa = protocol.other_actual_daa;
        }

        if (protocol?.betablok >= 0) {
            const betablokOption = protocolYesNoOptions.find(x => x.value === protocol.betablok);
            protocolReturned.betablok = betablokOption;
        }

        if (protocol.implant_reason) {
            const implantReasonOption = implantReasonOptions.find(x => x.value === protocol.implant_reason);
            protocolReturned.implantReason = implantReasonOption;
        }

        if (protocol.other_implant_reason) {
            protocolReturned.otherImplantReason = protocol.other_implant_reason;
        }

        if (protocol?.cdi_prevent >= 0) {
            const cdiPreventOption = cdiPreventOptions.find(x => x.value === protocol.cdi_prevent);
            protocolReturned.cdiPrevent = cdiPreventOption;
        }

        if (protocol.other_pathology) {
            protocolReturned.otherPathology = protocol.other_pathology;
        }

        if (protocol.other_medications) {
            protocolReturned.otherMedications = protocol.other_medications;
        }

        if (protocol.observation) {
            protocolReturned.observation = protocol.observation;
        }

        if (protocol.ablations) {
            protocolReturned.ablations = protocol.ablations.map(ablationItem => {
                const ablationOption = ablationOptions.find(x => x.value === ablationItem.ablation);
                const ablationEvtvOption = ablationEvTvOptions.find(x => x.value === ablationItem.ablation_ev_tv);
                const ablationTypeOption = ablationTypeOptions.find(x => x.value === ablationItem.ablation_type);
                const ablationPlaceOption = ablationPlaceOptions.find(x => x.value === ablationItem.ablation_place);
                const ablationSuccessOption = protocolYesNoOptions.find(x => x.value === ablationItem.success);

                let ablationEvTvLabel = "";
                let ablationTypeLabel = "";
                let ablationPlaceLabel = "";

                if (ablationEvtvOption) {
                    ablationEvTvLabel = (ablationEvtvOption.value === 7 ? `7 - ${ablationItem.other_ablation_ev_tv}` : ablationEvtvOption.label);
                }

                if (ablationTypeOption) {
                    ablationTypeLabel = (ablationTypeOption.value === 4 ? `4 - ${ablationItem.other_ablation_type}` : ablationTypeOption.label);
                }

                if (ablationPlaceOption) {
                    ablationPlaceLabel = (ablationPlaceOption.value === 5 ? `"5 - ${ablationItem.other_ablation_place}` : ablationPlaceOption.label);
                }

                return {
                    _id: ablationItem._id,
                    ablation: ablationOption,
                    ablationEvTv: ablationEvtvOption,
                    ablationEvTvLabel,
                    otherAblationEvTv: ablationItem.other_ablation_ev_tv,
                    ablationType: ablationTypeOption,
                    ablationTypeLabel,
                    otherAblationType: ablationItem.other_ablation_type,
                    lesionCount: ablationItem.lesion_count,
                    lesionTime: ablationItem.lesion_time,
                    ablationPlace: ablationPlaceOption,
                    ablationPlaceLabel,
                    otherAblationPlace: ablationItem.other_ablation_place,
                    success: ablationSuccessOption,
                    ablationObservation: ablationItem.ablation_observation
                };
            });
        }

        if (protocol.eef_number) {
            protocolReturned.eefNumber = protocol.eef_number;
        }

        if (protocol.mea) {
            const meaOption = meaOptions.find(x => x.value === protocol.mea);
            protocolReturned.mea = meaOption;
        }

        if (protocol?.ecointra >= 0) {
            const ecointraOption = protocolYesNoOptions.find(x => x.value === protocol.ecointra);
            protocolReturned.ecointra = ecointraOption;
        }

        if (protocol.punction_start) {
            protocolReturned.punctionStart = protocol.punction_start;
        }

        if (protocol.eef_start) {
            protocolReturned.eefStart = protocol.eef_start;
        }

        if (protocol.induced_tachycardia) {
            protocolReturned.inducedTachycardia = protocol.induced_tachycardia;
        }

        if (protocol.ablation_start) {
            protocolReturned.ablationStart = protocol.ablation_start;
        }

        if (protocol.ablation_end) {
            protocolReturned.ablationEnd = protocol.ablation_end;
        }

        if (protocol.implant_type) {
            const implantTypeOption = implantTypeOptions.find(x => x.value === protocol.implant_type);
            protocolReturned.implantType = implantTypeOption;
        }

        if (protocol.access_type) {
            const accessTypeOption = implantAccessTypeOptions.find(x => x.value === protocol.access_type);
            protocolReturned.accessType = accessTypeOption;
        }

        if (protocol.ad_limit) {
            protocolReturned.adLimit = protocol.ad_limit;
        }

        if (protocol.vd_limit) {
            protocolReturned.vdLimit = protocol.vd_limit;
        }

        if (protocol.ve_limit) {
            protocolReturned.veLimit = protocol.ve_limit;
        }

        if (protocol.implant_vein) {
            const implantVeinOption = implantVeinOptions.find(x => x.value === protocol.implant_vein);
            protocolReturned.implantVein = implantVeinOption;
        }

        if (protocol.other_implant_vein) {
            protocolReturned.otherImplantVein = protocol.other_implant_vein;
        }

        if (protocol.ve_electrode) {
            const veElectrodeOption = implantVeElectrodeOptions.find(x => x.value === protocol.ve_electrode);
            protocolReturned.veElectrode = veElectrodeOption;
        }

        if (protocol.other_ve_electrode) {
            protocolReturned.otherVeElectrode = protocol.other_ve_electrode;
        }

        if (protocol.qlv) {
            protocolReturned.qlv = protocol.qlv;
        }

        if (protocol.qrs_before) {
            protocolReturned.qrsBefore = protocol.qrs_before;
        }

        if (protocol.qrs_after) {
            protocolReturned.qrsAfter = protocol.qrs_after;
        }

        if (protocol.implant_result) {
            const implantResultOption = implantResultOptions.find(x => x.value === protocol.implant_result);
            protocolReturned.implantResult = implantResultOption;
        }

        if (protocol.complications) {
            protocolReturned.complications = protocol.complications;
        }

        if (protocol.implant_observation) {
            protocolReturned.implantObservation = protocol.implant_observation;
        }

        if (protocol.fa_type) {
            const faTypeOption = faTypeOptions.find(x => x.value === protocol.fa_type);
            protocolReturned.faType = faTypeOption;
        }

        if (protocol.fa_time) {
            protocolReturned.faTime = protocol.fa_time;
        }

        if (protocol.cve) {
            protocolReturned.cve = protocol.cve;
        }

        if (protocol.hospitalization) {
            protocolReturned.hospitalization = protocol.hospitalization;
        }

        if (protocol.used_daa) {
            protocolReturned.usedDaa = protocol.used_daa;
        }

        if (protocol?.anticoag >= 0) {
            const anticoagOption = anticoagOptions.find(x => x.value === protocol.anticoag);
            protocolReturned.anticoag = anticoagOption;
        }

        if (protocol.other_anticoag) {
            protocolReturned.otherAnticoag = protocol.other_anticoag;
        }

        if (protocol.chadsvasc) {
            protocolReturned.chadsvasc = protocol.chadsvasc;
        }

        if (protocol.diagnosis) {
            const diagnosisOption = faDiagnosisOptions.find(x => x.value === protocol.diagnosis);
            protocolReturned.diagnosis = diagnosisOption;
        }

        if (protocol.other_diagnosis) {
            protocolReturned.otherDiagnosis = protocol.other_diagnosis;
        }

        if (protocol?.apne >= 0) {
            const apneOption = protocolYesNoOptions.find(x => x.value === protocol.apne);
            protocolReturned.apne = apneOption;
        }

        if (protocol?.obes >= 0) {
            const obesOption = protocolYesNoOptions.find(x => x.value === protocol.obes);
            protocolReturned.obes = obesOption;
        }

        if (protocol.other_pathology_non_cardio) {
            protocolReturned.otherPathologyNonCardio = protocol.other_pathology_non_cardio;
        }

        if (protocol.ecg_crisis) {
            const ecgCrisisOption = ecgCrisisOptions.find(x => x.value === protocol.ecg_crisis);
            protocolReturned.ecgCrisis = ecgCrisisOption;
        }

        if (protocol.other_ecg_crisis) {
            protocolReturned.otherEcgCrisis = protocol.other_ecg_crisis;
        }

        if (protocol.ecg_consult) {
            const ecgConsultOption = ecgConsultOptions.find(x => x.value === protocol.ecg_consult);
            protocolReturned.ecgConsult = ecgConsultOption;
        }

        if (protocol.other_ecg_consult) {
            protocolReturned.otherEcgConsult = protocol.other_ecg_consult;
        }

        if (protocol.rni) {
            protocolReturned.rni = protocol.rni;
        }

        if (protocol?.vvpp >= 0) {
            const vvppOption = protocolYesNoOptions.find(x => x.value === protocol.vvpp);
            protocolReturned.vvpp = vvppOption;
        }

        if (protocol?.session >= 0) {
            const sessionOption = faSessionOptions.find(x => x.value === protocol.session);
            protocolReturned.session = sessionOption;
        }

        if (protocol?.ecg_ablation >= 0) {
            const ecgAblationOption = ecgConsultOptions.find(x => x.value === protocol.ecg_ablation);
            protocolReturned.ecgAblation = ecgAblationOption;
        }

        if (protocol.other_ecg_ablation) {
            protocolReturned.otherEcgAblation = protocol.other_ecg_ablation;
        }

        if (protocol.geometry_start) {
            protocolReturned.geometryStart = protocol.geometry_start;
        }

        if (protocol.geometry_end) {
            protocolReturned.geometryEnd = protocol.geometry_end;
        }

        if (protocol.tca1_time) {
            protocolReturned.tca1Time = protocol.tca1_time;
        }

        if (protocol.tca1_value) {
            protocolReturned.tca1Value = protocol.tca1_value;
        }

        if (protocol.tca2_time) {
            protocolReturned.tca2Time = protocol.tca2_time;
        }

        if (protocol.tca2_value) {
            protocolReturned.tca2Value = protocol.tca2_value;
        }

        if (protocol.tca3_time) {
            protocolReturned.tca3Time = protocol.tca3_time;
        }

        if (protocol.tca3_value) {
            protocolReturned.tca3Value = protocol.tca3_value;
        }

        if (protocol.tca4_time) {
            protocolReturned.tca4Time = protocol.tca4_time;
        }

        if (protocol.tca4_value) {
            protocolReturned.tca4Value = protocol.tca4_value;
        }

        if (protocol.tca5_time) {
            protocolReturned.tca5Time = protocol.tca5_time;
        }

        if (protocol.tca5_value) {
            protocolReturned.tca5Value = protocol.tca5_value;
        }

        if (protocol.tca6_time) {
            protocolReturned.tca6Time = protocol.tca6_time;
        }

        if (protocol.tca6_value) {
            protocolReturned.tca6Value = protocol.tca6_value;
        }

        if (protocol.tca7_time) {
            protocolReturned.tca7Time = protocol.tca7_time;
        }

        if (protocol.tca7_value) {
            protocolReturned.tca7Value = protocol.tca7_value;
        }

        if (protocol.left_vvpp_start) {
            protocolReturned.leftVvppStart = protocol.left_vvpp_start;
        }

        if (protocol.left_vvpp_end) {
            protocolReturned.leftVvppEnd = protocol.left_vvpp_end;
        }

        if (protocol?.left_karina >= 0) {
            const leftKarinaOption = protocolYesNoOptions.find(x => x.value === protocol.left_karina);
            protocolReturned.leftKarina = leftKarinaOption;
        }

        if (protocol.left_vvpp_lesions) {
            protocolReturned.leftVvppLesions = protocol.left_vvpp_lesions;
        }

        if (protocol.right_vvpp_start) {
            protocolReturned.rightVvppStart = protocol.right_vvpp_start;
        }

        if (protocol.right_vvpp_end) {
            protocolReturned.rightVvppEnd = protocol.right_vvpp_end;
        }

        if (protocol?.right_karina >= 0) {
            const rightKarinaOption = protocolYesNoOptions.find(x => x.value === protocol.right_karina);
            protocolReturned.rightKarina = rightKarinaOption;
        }

        if (protocol.right_vvpp_lesions) {
            protocolReturned.rightVvppLesions = protocol.right_vvpp_lesions;
        }

        if (protocol?.vpse_block >= 0) {
            const vpseBlockOption = protocolYesNoOptions.find(x => x.value === protocol.vpse_block);
            protocolReturned.vpseBlock = vpseBlockOption;
        }

        if (protocol?.vpie_block >= 0) {
            const vpieBlockOption = protocolYesNoOptions.find(x => x.value === protocol.vpie_block);
            protocolReturned.vpieBlock = vpieBlockOption;
        }

        if (protocol?.vpsd_block >= 0) {
            const vpsdBlockOption = protocolYesNoOptions.find(x => x.value === protocol.vpsd_block);
            protocolReturned.vpsdBlock = vpsdBlockOption;
        }

        if (protocol?.vpid_block >= 0) {
            const vpidBlockOption = protocolYesNoOptions.find(x => x.value === protocol.vpid_block);
            protocolReturned.vpidBlock = vpidBlockOption;
        }

        if (protocol.left_aditional_lesions) {
            protocolReturned.leftAditionalLesions = protocol.left_aditional_lesions;
        }

        if (protocol.right_aditional_lesions) {
            protocolReturned.rightAditionalLesions = protocol.right_aditional_lesions;
        }

        if (protocol.total_lesions) {
            protocolReturned.totalLesions = protocol.total_lesions;
        }

        if (protocol.left_esophagus_quantity) {
            protocolReturned.leftEsophagusQuantity = protocol.left_esophagus_quantity;
        }

        if (protocol.left_esophagus_temperature) {
            protocolReturned.leftEsophagusTemperature = protocol.left_esophagus_temperature;
        }

        if (protocol.right_esophagus_quantity) {
            protocolReturned.rightEsophagusQuantity = protocol.right_esophagus_quantity;
        }

        if (protocol.right_esophagus_temperature) {
            protocolReturned.rightEsophagusTemperature = protocol.right_esophagus_temperature;
        }

        if (protocol?.max_block >= 0) {
            const maxBlockOption = protocolYesNoOptions.find(x => x.value === protocol.max_block);
            protocolReturned.maxBlock = maxBlockOption;
        }

        if (protocol?.box_block >= 0) {
            const boxBlockOption = protocolYesNoOptions.find(x => x.value === protocol.box_block);
            protocolReturned.boxBlock = boxBlockOption;
        }

        if (protocol.other_strategy) {
            protocolReturned.otherStrategy = protocol.other_strategy;
        }

        if (protocol.rx_time) {
            protocolReturned.rxTime = protocol.rx_time;
        }

        if (protocol.fa_final) {
            protocolReturned.faFinal = protocol.fa_final;
        }

        if (protocol.flutter_start) {
            protocolReturned.flutterStart = protocol.flutter_start;
        }

        if (protocol.flutter_end) {
            protocolReturned.flutterEnd = protocol.flutter_end;
        }

        if (protocol?.istmo_block >= 0) {
            const istmoBlockOption = protocolYesNoOptions.find(x => x.value === protocol.istmo_block);
            protocolReturned.istmoBlock = istmoBlockOption;
        }

        if (protocol.catheter_removal) {
            protocolReturned.catheterRemoval = protocol.catheter_removal;
        }

        if (protocol.hemodin_exit) {
            protocolReturned.hemodinExit = protocol.hemodin_exit;
        }

        if (protocol.ablation_observation) {
            protocolReturned.ablationObservation = protocol.ablation_observation;
        }

        if (protocol.files) {
            loadFiles(protocol.files);
        }

        setProtocolInfo(protocolReturned);
    }

    async function handleSaveProtocol(e) {
        e.preventDefault();

        setLoadingProtocolForm(true);

        let protocol = undefined;

        if (!isUpdate) {
            protocol = {
                pacient_id: schedule.pacient_id,
                pacient_name: schedule.pacient_name,
                schedule_id: schedule._id,
                procedure_id: procedureCode,
                procedure_date: protocolInfo.execution_date ?? schedule.schedule_date,
                protocol_date: dayjs().toDate()
            };
        }

        protocol = {
            ...protocol,
            main_symptom: protocolInfo.mainSymptom?.value ?? null,
            other_main_symptom: protocolInfo.otherMainSymptom ?? "",
            functional_class: protocolInfo.functionalClass ?? "",
            ventri_arrhythmia: protocolInfo.ventriArrhythmia?.value ?? null,
            other_ventri_arrhythmia: protocolInfo.otherVentriArrhythmia ?? "",
            ventri_arrhythmia_ecg: protocolInfo.ventriArrhythmiaEcg?.value ?? null,
            other_ventri_arrhythmia_ecg: protocolInfo.otherVentriArrhythmiaEcg ?? "",
            start_month: protocolInfo.startMonth ?? "",
            ventri_arrhythmia_holter: protocolInfo.ventriArrhythmiaHolter ?? "",
            holter: protocolInfo.holter?.value ?? null,
            other_holter: protocolInfo.otherHolter ?? "",
            ae: protocolInfo.ae ?? "",
            ae_ml: protocolInfo.aeMl ?? "",
            ve: protocolInfo.ve ?? "",
            septo: protocolInfo.septo ?? "",
            ppost: protocolInfo.ppost ?? "",
            fe: protocolInfo.fe ?? "",
            mag_resso: protocolInfo.magResso?.value ?? null,
            angio_tomo: protocolInfo.angioTomo?.value ?? null,
            cintilo: protocolInfo.cintilo?.value ?? null,
            cate: protocolInfo.cate?.value ?? null,
            evtv: protocolInfo.evtv?.value ?? null,
            other_evtv: protocolInfo.otherEvtv ?? "",
            mioc_isq: protocolInfo.miocIsq?.value ?? null,
            mioc_non_isq: protocolInfo.miocNonIsq?.value ?? null,
            other_mioc_non_isq: protocolInfo.otherMiocNonIsq ?? "",
            has: protocolInfo.has?.value ?? null,
            diab_melli: protocolInfo.diabMelli?.value ?? null,
            cdi: protocolInfo.cdi?.value ?? null,
            cdi_shock: protocolInfo.cdiShock?.value ?? null,
            actual_daa: protocolInfo.actualDaa?.value ?? null,
            other_actual_daa: protocolInfo.otherActualDaa ?? "",
            betablok: protocolInfo.betablok?.value ?? null,
            implant_reason: protocolInfo.implantReason?.value ?? null,
            other_implant_reason: protocolInfo.otherImplantReason ?? "",
            cdi_prevent: protocolInfo.cdiPrevent?.value ?? null,
            other_pathology: protocolInfo.otherPathology ?? "",
            other_medications: protocolInfo.otherMedications ?? "",
            observation: protocolInfo.observation ?? "",
            ablations: [],
            eef_number: protocolInfo.eefNumber ?? "",
            mea: protocolInfo.mea?.value ?? null,
            ecointra: protocolInfo.ecointra?.value ?? null,
            punction_start: protocolInfo.punctionStart ?? "",
            eef_start: protocolInfo.eefStart ?? "",
            induced_tachycardia: protocolInfo.inducedTachycardia ?? "",
            ablation_start: protocolInfo.ablationStart ?? "",
            ablation_end: protocolInfo.ablationEnd ?? "",
            implant_type: protocolInfo.implantType?.value ?? null,
            access_type: protocolInfo.accessType?.value ?? null,
            ad_limit: protocolInfo.adLimit ?? "",
            vd_limit: protocolInfo.vdLimit ?? "",
            ve_limit: protocolInfo.veLimit ?? "",
            implant_vein: protocolInfo.implantVein?.value ?? null,
            other_implant_vein: protocolInfo.otherImplantVein ?? "",
            ve_electrode: protocolInfo.veElectrode?.value ?? null,
            other_ve_electrode: protocolInfo.otherVeElectrode ?? "",
            qlv: protocolInfo.qlv ?? "",
            qrs_before: protocolInfo.qrsBefore ?? "",
            qrs_after: protocolInfo.qrsAfter ?? "",
            implant_result: protocolInfo.implantResult?.value ?? null,
            complications: protocolInfo.complications ?? "",
            implant_observation: protocolInfo.implantObservation ?? "",
            fa_type: protocolInfo.faType?.value ?? null,
            fa_time: protocolInfo.faTime ?? "",
            cve: protocolInfo.cve ?? "",
            hospitalization: protocolInfo.hospitalization ?? "",
            used_daa: protocolInfo.usedDaa ?? "",
            anticoag: protocolInfo.anticoag?.value ?? null,
            other_anticoag: protocolInfo.otherAnticoag ?? "",
            chadsvasc: protocolInfo.chadsvasc ?? "",
            diagnosis: protocolInfo.diagnosis?.value ?? null,
            other_diagnosis: protocolInfo.otherDiagnosis ?? "",
            apne: protocolInfo.apne?.value ?? null,
            obes: protocolInfo.obes?.value ?? null,
            other_pathology_non_cardio: protocolInfo.otherPathologyNonCardio ?? "",
            ecg_crisis: protocolInfo.ecgCrisis?.value ?? null,
            other_ecg_crisis: protocolInfo.otherEcgCrisis ?? "",
            ecg_consult: protocolInfo.ecgConsult?.value ?? null,
            other_ecg_consult: protocolInfo.otherEcgConsult ?? "",
            rni: protocolInfo.rni ?? "",
            vvpp: protocolInfo.vvpp?.value ?? null,
            session: protocolInfo.session?.value ?? null,
            ecg_ablation: protocolInfo.ecgAblation?.value ?? null,
            other_ecg_ablation: protocolInfo.otherEcgAblation ?? "",
            geometry_start: protocolInfo.geometryStart ?? "",
            geometry_end: protocolInfo.geometryEnd ?? "",
            tca1_time: protocolInfo.tca1Time ?? "",
            tca1_value: protocolInfo.tca1Value ?? "",
            tca2_time: protocolInfo.tca2Time ?? "",
            tca2_value: protocolInfo.tca2Value ?? "",
            tca3_time: protocolInfo.tca3Time ?? "",
            tca3_value: protocolInfo.tca3Value ?? "",
            tca4_time: protocolInfo.tca4Time ?? "",
            tca4_value: protocolInfo.tca4Value ?? "",
            tca5_time: protocolInfo.tca5Time ?? "",
            tca5_value: protocolInfo.tca5Value ?? "",
            tca6_time: protocolInfo.tca6Time ?? "",
            tca6_value: protocolInfo.tca6Value ?? "",
            tca7_time: protocolInfo.tca7Time ?? "",
            tca7_value: protocolInfo.tca7Value ?? "",
            left_vvpp_start: protocolInfo.leftVvppStart ?? "",
            left_vvpp_end: protocolInfo.leftVvppEnd ?? "",
            left_karina: protocolInfo.leftKarina?.value ?? null,
            left_vvpp_lesions: protocolInfo.leftVvppLesions ?? "",
            right_vvpp_start: protocolInfo.rightVvppStart ?? "",
            right_vvpp_end: protocolInfo.rightVvppEnd ?? "",
            right_karina: protocolInfo.rightKarina?.value ?? null,
            right_vvpp_lesions: protocolInfo.rightVvppLesions ?? "",
            vpse_block: protocolInfo.vpseBlock?.value ?? null,
            vpie_block: protocolInfo.vpieBlock?.value ?? null,
            vpsd_block: protocolInfo.vpsdBlock?.value ?? null,
            vpid_block: protocolInfo.vpidBlock?.value ?? null,
            left_aditional_lesions: protocolInfo.leftAditionalLesions ?? "",
            right_aditional_lesions: protocolInfo.rightAditionalLesions ?? "",
            total_lesions: protocolInfo.totalLesions ?? "",
            left_esophagus_quantity: protocolInfo.leftEsophagusQuantity ?? "",
            left_esophagus_temperature: protocolInfo.leftEsophagusTemperature ?? "",
            right_esophagus_quantity: protocolInfo.rightEsophagusQuantity ?? "",
            right_esophagus_temperature: protocolInfo.rightEsophagusTemperature ?? "",
            max_block: protocolInfo.maxBlock?.value ?? null,
            box_block: protocolInfo.boxBlock?.value ?? null,
            other_strategy: protocolInfo.otherStrategy ?? "",
            rx_time: protocolInfo.rxTime ?? "",
            fa_final: protocolInfo.faFinal ?? "",
            flutter_start: protocolInfo.flutterStart ?? "",
            flutter_end: protocolInfo.flutterEnd ?? "",
            istmo_block: protocolInfo.istmoBlock?.value ?? null,
            catheter_removal: protocolInfo.catheterRemoval ?? "",
            hemodin_exit: protocolInfo.hemodinExit ?? "",
            ablation_observation: protocolInfo.ablationObservation ?? "",
            files: []
        };

        if (protocolInfo.ablations) {
            protocol.ablations = protocolInfo.ablations.map(ablationItem => {
                return {
                    ablation: ablationItem.ablation.value,
                    ablation_ev_tv: ablationItem.ablationEvTv.value,
                    other_ablation_ev_tv: ablationItem.otherAblationEvTv,
                    ablation_type: ablationItem.ablationType.value,
                    other_ablation_type: ablationItem.otherAblationType,
                    lesion_count: ablationItem.lesionCount,
                    lesion_time: ablationItem.lesionTime,
                    ablation_place: ablationItem.ablationPlace.value,
                    other_ablation_place: ablationItem.otherAblationPlace,
                    success: ablationItem.success.value,
                    ablation_observation: ablationItem.ablationObservation
                };
            });
        }

        if (uploadedFiles) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                protocol.files.push({
                    filename: uploadedFiles[i].name,
                    original_name: uploadedFiles[i].originalName,
                    size: uploadedFiles[i].file.size
                })
            }
        }

        if (!isUpdate) {
            try {
                await api.post("/protocols", protocol)
                    .then(response => {
                        if (response.status === 201) {
                            createLog({
                                routine: "protocol",
                                action: "create",
                                type: "success",
                                message: `Protocolo para ${schedule.pacient_name} incluído`,
                                jsonOrigin: JSON.stringify(protocol),
                                jsonReturn: JSON.stringify(response)
                            });

                            setLoadingProtocolForm(false);
                            notifySuccess();
                            onClose();
                            setProtocolInfo({});
                            setModalBox(false);
                        }
                    })
            } catch (err) {
                createLog({
                    routine: "protocol",
                    action: "create",
                    type: "error",
                    message: `Protocolo para ${schedule.pacient_name} não incluído`,
                    jsonOrigin: JSON.stringify(protocol),
                    jsonReturn: JSON.stringify(err)
                });

                notifyError();
            }
        } else {
            try {
                await api.put(`/protocols/${protocolId}`, protocol)
                    .then(response => {
                        if (response.status === 200) {
                            createLog({
                                routine: "protocol",
                                action: "update",
                                type: "success",
                                message: `Protocolo para ${schedule.pacient_name} alterado`,
                                jsonOrigin: JSON.stringify(protocol),
                                jsonReturn: JSON.stringify(response)
                            });

                            setLoadingProtocolForm(false);
                            notifySuccess();
                            onClose();
                            setProtocolInfo({});
                            setModalBox(false);
                        }
                    })
            } catch (err) {
                createLog({
                    routine: "protocol",
                    action: "update",
                    type: "error",
                    message: `Protocolo para ${schedule.pacient_name} não alterado`,
                    jsonOrigin: JSON.stringify(protocol),
                    jsonReturn: JSON.stringify(err)
                });

                notifyError();
            }
        }

        setLoadingProtocolForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Protocolo ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao salvar o registro`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleChangeInfo(field, value) {
        const newprotocolInfo = { ...protocolInfo };
        newprotocolInfo[field] = value;
        setProtocolInfo({ ...newprotocolInfo });
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {protocolType}
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <Tabs
                                        defaultActiveKey="baseForm"
                                        id="fill-tab-example"
                                        className="mb-3 basic-list-group"
                                        fill
                                    >
                                        <Tab eventKey="baseForm" title="Formulário">
                                            <EvTvForm
                                                protocolInfoParm={protocolInfo}
                                                onChangeInfo={handleChangeInfo}
                                                hide={procedureCode !== "EV" && procedureCode !== "TVS"}
                                            />

                                            <ImplantForm
                                                protocolInfoParm={protocolInfo}
                                                onChangeInfo={handleChangeInfo}
                                                hide={procedureCode !== "MP" && procedureCode !== "CDI"}
                                            />

                                            <FaForm protocolInfoParm={protocolInfo}
                                                onChangeInfo={handleChangeInfo}
                                                hide={procedureCode !== "FA" && procedureCode !== "FLU"}
                                            />
                                        </Tab>

                                        <Tab eventKey="documents" title="Documentos">
                                            <UploadFile />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={() => {
                                setProtocolInfo({});
                                setModalBox(false);
                            }}
                        >
                            Fechar
                        </Button>
                        <Button variant="success btn-rounded" onClick={handleSaveProtocol}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingProtocolForm}
                message={loadingMessage}
                onClose={setLoadingProtocolForm}
            />
        </>
    );
});

export default ProtocolForm;
