import React, {
    createContext,
    useState,
    useCallback,
    useContext
} from "react";
import { v4 as uuidv4 } from "uuid";
import { filesize } from "filesize";

const FileContext = createContext({});

const FileProvider = ({ children }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const updateFile = useCallback(
        (file) => {
            const newUploadedFiles = {
                file,
                id: file.id,
                fileId: file.fileId || uuidv4(),
                name: file.key,
                originalName: file.originalname,
                readableSize: (file.size ? filesize(file.size) : 0),
                isDeleted: false
            };

            setUploadedFiles((state) => [...state, newUploadedFiles]);
        }, []);

    const deleteFile = useCallback((id) => {
        setUploadedFiles((state) => {
            const newUploadedFiles = [...state];
            const indexOfFile = newUploadedFiles.findIndex((file) => file.fileId === id);
            let removeFile = true;

            if (indexOfFile >= 0) {
                if (newUploadedFiles[indexOfFile].id) {
                    newUploadedFiles[indexOfFile].isDeleted = true;
                    removeFile = false;
                }
            }

            if (removeFile) {
                return state.filter((file) => file.fileId !== id);
            }

            return newUploadedFiles;
        });
    }, []);

    const resetFiles = useCallback(() => {
        setUploadedFiles([]);
    }, []);

    const loadFiles = useCallback((filesToLoad) => {
        for (let i = 0; i < filesToLoad.length; i++) {
            const file = {
                file: filesToLoad[i],
                id: filesToLoad[i].id,
                fileId: filesToLoad[i].id,
                name: filesToLoad[i].filename,
                originalName: filesToLoad[i].original_name,
                readableSize: filesize(filesToLoad[i].size),
                isDeleted: false
            };

            setUploadedFiles((state) => [...state, file]);
        }
    }, []);

    return (
        <FileContext.Provider value={{ uploadedFiles, deleteFile, updateFile, resetFiles, loadFiles }}>
            {children}
        </FileContext.Provider>
    );
};

function useFiles() {
    const context = useContext(FileContext);

    if (!context) {
        throw new Error("useFiles must be used within FileProvider");
    }

    return context;
}

export { FileProvider, useFiles };