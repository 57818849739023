import { useEffect, useState } from "react";

const useViewPort = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }

        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    const isMobile = width <= 640;
    const isTablet = width <= 1024 && width > 640;
    const isDesktop = width > 1024;

    return { width, height, isMobile, isTablet, isDesktop };
}

export default useViewPort;