import React, { useState, useEffect, useRef } from "react";

import { Link } from "react-router-dom";
/// Scroll
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import LogoutPage from './Logout';

import Avatar, { ConfigProvider } from "react-avatar";
import ChangePwdForm from "../../pages/User/ChangePwdForm";

const Header = ({ onNote }) => {
	const changePwdRef = useRef();
	//For header fixed 
	const [headerFix, setheaderFix] = useState(false);
	const [username, setUsername] = useState('');


	useEffect(() => {
		window.addEventListener("scroll", () => {
			setheaderFix(window.scrollY > 50);
		});

		const userInfo = JSON.parse(localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxUsr") : sessionStorage.getItem("xLcxUsr"));

		if (userInfo) {
			setUsername(userInfo.name);
		}
	}, []);

	const customColors = [
		'#5E005E',
		'#AB2F52',
		'#E88554',
		'#4194A6',
		'#82CCD9',
		'#FFCC6B',
		'#7D323B'
	];

	return (
		<>
			<div className={`header ${headerFix ? "sticky" : ""}`}>
				<div className="header-content">
					<nav className="navbar navbar-expand">
						<div className="collapse navbar-collapse justify-content-between">
							<div className="header-left" />
							<ul className="navbar-nav header-right">
								{/* <Dropdown as="li" className="nav-item  notification_dropdown">
								<Dropdown.Toggle variant="" as="a" className="nav-link  ai-icon i-false c-pointer">
									<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M22.75 10.8334C22.7469 9.8751 22.4263 8.94488 21.8382 8.18826C21.2501 7.43163 20.4279 6.89126 19.5 6.6517V4.33337C19.4997 4.15871 19.4572 3.98672 19.3761 3.83204C19.295 3.67736 19.1777 3.54459 19.0342 3.44503C18.8922 3.34623 18.7286 3.28286 18.5571 3.26024C18.3856 3.23763 18.2111 3.25641 18.0484 3.31503L8.59086 6.7492L4.39835 6.50003C4.25011 6.49047 4.10147 6.51151 3.9617 6.56183C3.82192 6.61215 3.69399 6.69068 3.58585 6.79253C3.4789 6.89448 3.39394 7.01723 3.33619 7.15323C3.27843 7.28924 3.24911 7.43561 3.25002 7.58337V15.1667C3.25022 15.3205 3.28316 15.4725 3.34667 15.6126C3.41018 15.7527 3.5028 15.8777 3.61835 15.9792C3.733 16.0795 3.86752 16.1545 4.01312 16.1993C4.15873 16.2441 4.31214 16.2577 4.46335 16.2392L5.88252 16.0659L6.90085 21.8509C6.94471 22.1052 7.07794 22.3356 7.27655 22.5004C7.47516 22.6653 7.7261 22.7538 7.98419 22.75H11.9167C12.0748 22.7521 12.2314 22.7195 12.3756 22.6545C12.5197 22.5896 12.648 22.4939 12.7512 22.3741C12.8544 22.2544 12.9302 22.1135 12.9732 21.9613C13.0162 21.8092 13.0253 21.6494 13 21.4934L12.1984 16.7267L18.1242 18.4167C18.2211 18.4325 18.3198 18.4325 18.4167 18.4167C18.704 18.4167 18.9796 18.3026 19.1827 18.0994C19.3859 17.8962 19.5 17.6207 19.5 17.3334V15.015C20.4279 14.7755 21.2501 14.2351 21.8382 13.4785C22.4263 12.7218 22.7469 11.7916 22.75 10.8334ZM5.41669 8.7317L7.58335 8.85087V13.6717L5.41669 13.9425V8.7317ZM10.6384 20.5834H8.88336L8.03836 15.795L8.59086 15.73L9.89086 16.0875L10.6384 20.5834ZM17.3334 15.9034L11.4292 14.2675C11.2529 14.1491 11.0457 14.085 10.8334 14.0834L9.75002 13.78V8.6667L17.3334 5.91503V15.9034ZM19.5 12.6534V8.97003C19.8233 9.16188 20.0912 9.43455 20.2772 9.76124C20.4632 10.0879 20.5611 10.4574 20.5611 10.8334C20.5611 11.2093 20.4632 11.5788 20.2772 11.9055C20.0912 12.2322 19.8233 12.5049 19.5 12.6967V12.6534Z" fill="#666666" />
									</svg>
								</Dropdown.Toggle>
								<Dropdown.Menu align="end" className="mt-4 dropdown-menu dropdown-menu-end">
									<PerfectScrollbar className="widget-timeline dz-scroll style-1 ps p-3 height370">
										<ul className="timeline">
											<li>
												<div className="timeline-badge info"></div> */}
								{/* <Link
													className="timeline-panel c-pointer text-muted"
													to="#"
												> */}
								{/* <span>5 minutos atrás</span>
													<h6 className="mb-0">
														Novo Agendamento
													</h6>
													<p className="mb-0">
														15/05/2023 10:00
													</p>
													<p className="mb-0">
														JOSE DA SILVA
													</p> */}
								{/* </Link> */}
								{/* </li>
											<li>
												<div className="timeline-badge danger"></div> */}
								{/* <Link
													className="timeline-panel c-pointer text-muted"
													to="#"
												> */}
								{/* <span>8 minutos atrás</span>
													<h6 className="mb-0">
														Novo Agendamento
													</h6>
													<p className="mb-0">
														11/05/2023 14:00
													</p>
													<p className="mb-0">
														JOAO SOUZA
													</p> */}
								{/* </Link> */}
								{/* </li>
										</ul>
										<div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
											<div
												className="ps__thumb-x"
												tabIndex={0}
												style={{ left: 0, width: 0 }}
											/>
										</div>
										<div className="ps__rail-y" style={{ top: 0, right: 0 }}>
											<div
												className="ps__thumb-y"
												tabIndex={0}
												style={{ top: 0, height: 0 }}
											/>
										</div>
									</PerfectScrollbar>
								</Dropdown.Menu> */}
								{/* </Dropdown> */}

								<li className="nav-item ">
									<Dropdown className="dropdown header-profile2">
										<Dropdown.Toggle variant="" as="a" className="nav-link i-false c-pointer">
											<div className="header-info2 d-flex align-items-center">
												<div className="d-flex align-items-center sidebar-info">
													<div>
														<h4 className="mb-0">{username}</h4>
													</div>
												</div>
												<div className="avatar">
													<ConfigProvider colors={customColors}>
														<Avatar
															name={username}
															size="35"
															round
															style={{ paddingLeft: 10 }}
														/>
													</ConfigProvider>
												</div>
												{/* <img src={profile}  alt="" /> */}
											</div>
										</Dropdown.Toggle>
										<Dropdown.Menu align="right" className="mt-3 dropdown-menu dropdown-menu-end">
											<div className="dropdown-item ai-icon" style={{ cursor: "pointer" }} onClick={() => changePwdRef.current.openModal()}>
												<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
													<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
														<polygon points="0 0 24 0 24 24 0 24" />
														<path d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
														<path d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z" fill="var(--primary)" fillRule="nonzero" />
													</g>
												</svg>
												<span className="ms-2">Alterar Senha </span>
											</div>
											<LogoutPage />
										</Dropdown.Menu>
									</Dropdown>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>

			<ChangePwdForm ref={changePwdRef} />
		</>
	);
};

export default Header;
