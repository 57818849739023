import React from "react";
import PropTypes from "prop-types";
import { Modal, Spinner } from "react-bootstrap";

const ModalLoading = props => {
    const {
        children,
        className,
        onClose,
        visible,
        message,
        ...attributes
    } = props;

    return (
        <Modal
            className="fade"
            show={visible}
            onHide={() => onClose(false)}
            centered
        >
            <Modal.Body>
            <div className="row text-center">
                <div className="col-xl-12">
                {message}
                </div>
            </div>
            <div className="row text-center">
                <div className="col-xl-12">
                <Spinner />
                </div>
            </div>
            </Modal.Body>
        </Modal>
    )
}

ModalLoading.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

ModalLoading.defaultProps = {
    visible: false,
    message: ""
};

export default ModalLoading;