import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import ModalLoading from '../../components/ModalLoading/ModalLoading';
import DFormText from '../../components/DFormText/DFormText';

import api from '../../../services/api';
import { createLog } from '../../../services/logService';

const ChangePwdForm = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [loadingChangePwdForm, setLoadingChangePwdForm] = useState(false);

    const [userId, setUserId] = useState("");

    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");

    const [invalidPassword, setInvalidPassword] = useState(false);
    const [invalidConfirmPassword, setInvalidConfirmPassword] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal() {
            setModalBox(true);

            resetFields();

            getUser();
        }
    }));

    async function getUser() {
        const userInfo = JSON.parse(localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxUsr") : sessionStorage.getItem("xLcxUsr"));

        await api.get(`/users/username/${userInfo.username}`)
            .then(response => {
                if (response.status === 200) {
                    setUserId(response.data._id);
                }
            })
    }

    async function handleSaveUser(e) {
        e.preventDefault();

        setLoadingChangePwdForm(true);

        setInvalidPassword(false);
        setInvalidConfirmPassword(false);

        let hasError = false;

        if (password === "") {
            setInvalidPassword(true);
            hasError = true;
        }

        if (password !== confirmPassword) {
            setInvalidConfirmPassword(true);
            hasError = true;
        }

        if (!hasError) {
            const user = {
                password,
            };

            try {
                await api.put(`/users/${userId}`, user)
                    .then(response => {
                        if (response.status === 200) {
                            createLog({
                                routine: "user",
                                action: "pwd",
                                type: "success",
                                message: `Usuário ${user.name} alterado`,
                                jsonOrigin: JSON.stringify(user),
                                jsonReturn: JSON.stringify(response)
                            });

                            setLoadingChangePwdForm(false);
                            notifySuccess();
                            resetFields();
                            setModalBox(false);
                        }
                    })
            } catch (err) {
                notifyError();
            }
        }

        setLoadingChangePwdForm(false);
    }

    function resetFields() {
        setPassword("");
        setconfirmPassword("");

        setInvalidPassword(false);
        setInvalidConfirmPassword(false);
        setLoadingChangePwdForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Senha alterada com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error("❌ Ocorreu um problema ao salvar o usuário", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <Modal size='xl' onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">Alterar Senha</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Senha</label>
                                    <input
                                        type="password"
                                        className={`form-control ${(invalidPassword ? "is-invalid" : "")}`}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                    />
                                    <DFormText hidden={!invalidPassword} color="danger">Informe uma senha</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Confirmar Senha</label>
                                    <input
                                        type="password"
                                        className={`form-control ${(invalidConfirmPassword ? "is-invalid" : "")}`}
                                        value={confirmPassword}
                                        onChange={e => setconfirmPassword(e.target.value)}
                                    />
                                    <DFormText hidden={!invalidConfirmPassword} color="danger">As senhas não conferem</DFormText>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                        <Button variant="success btn-rounded" onClick={handleSaveUser}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingChangePwdForm}
                message="Salvando Usuário..."
                onClose={setLoadingChangePwdForm}
            />
        </>
    )
})

export default ChangePwdForm;