import dayjs from "dayjs";
import api from "./api";

export async function updateSummaryCount(scheduleDate, forwardDoctorId, operation, type) {
    const period = dayjs(scheduleDate).format("YYYYMM");
    const summaryInfo = {
        period,
        doctorId: forwardDoctorId,
        type,
        operation
    };

    await api.post("/doctor-summaries/count", summaryInfo);
}