/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
//import { add } from "date-fns";

import { isAuthenticated, hasAccess } from "../../../services/utils";

//import profile from "../../../images/user.jpg";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
  } = useContext(ThemeContext);

  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated()) {
      return history.push("/login");
    }

    // var btn = document.querySelector(".nav-control");
    // var aaa = document.querySelector("#main-wrapper");
    // function toggleFunc() {
    //   return aaa.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);


    //for sidebar overlay 

    // var btn = document.querySelector(".nav-control");
    // var mainwrapper = document.querySelector("#main-wrapper");
    // var sidebarclose = document.querySelector(".sidebar-close");
    // function toggleFunc() {
    //   return mainwrapper.classList.toggle("menu-toggle"), sidebarclose.classList.toggle("menu-toggle");
    // }
    // btn.addEventListener("click", toggleFunc);

    // function CloseFunc() {
    //   return mainwrapper.classList.remove("menu-toggle"), sidebarclose.classList.toggle("menu-toggle");
    // }

    // sidebarclose.addEventListener("click", CloseFunc);


    //sidebar icon Heart blast
    //var handleheartBlast = document.querySelector('.heart');


  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y
      if (isShow !== hideOnScroll) setHideOnScroll(isShow)
    },
    [hideOnScroll]
  )
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let records = [
    "user",
    "health-plan",
    "doctor",
    "hospital",
    "pacient",
    "supplier",
    "procedure-group",
    "procedure-type",
    "procedure",
  ],
    schedule = [
      "preschedule",
      "schedule",
      "scale",
      "availability"
    ],
    clinic = [
      "clinic",
      "medical-record",
      "procedure"
    ],
    protocol = [
      "protocol-form"
    ],
    operational = [
      "invoice",
      "receipt",
      "commissions"
    ],
    dashboard = [
      "dashboard",
    ],
    settings = [
      "speciality",
      "settings",
      "cancel-reasons"
    ],
    events = [
      "logs"
    ];

  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${dashboard.includes(path) ? "mm-active" : ""}`} hidden={!hasAccess("dashboard", "access")}>
            <Link className="has-arrow" to="#" title="Dashboard" >
              <i className="material-icons-outlined">dashboard</i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <ul>
              <li><Link className={`${path === "dashboard" ? "mm-active" : ""}`} to="/dashboard"> Dashboard</Link></li>
            </ul>
          </li>
          <li className={`${records.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" title="Cadastros" >
              <i className="material-icons"> app_registration </i>
              <span className="nav-text" >Cadastros</span>
            </Link>
            <ul>
              <li hidden={!hasAccess("record", "health_plan")}><Link className={`${path === "health-plan" ? "mm-active" : ""}`} to="/health-plan">Convênios</Link></li>
              <li hidden={!hasAccess("record", "doctor")}><Link className={`${path === "doctor" ? "mm-active" : ""}`} to="/doctor">Médicos</Link></li>
              <li hidden={!hasAccess("record", "hospital")}><Link className={`${path === "hospital" ? "mm-active" : ""}`} to="/hospital">Hospitais</Link></li>
              <li hidden={!hasAccess("record", "pacient")}><Link className={`${path === "pacient" ? "mm-active" : ""}`} to="/pacient">Pacientes</Link></li>
              <li hidden={!hasAccess("record", "supplier")}><Link className={`${path === "supplier" ? "mm-active" : ""}`} to="/supplier">Fornecedores</Link></li>
              <li hidden={!hasAccess("record", "procedure_group")}><Link className={`${path === "procedure-group" ? "mm-active" : ""}`} to="/procedure-group">Grupos de Procedimentos</Link></li>
              <li hidden={!hasAccess("record", "procedure_type")}><Link className={`${path === "procedure-type" ? "mm-active" : ""}`} to="/procedure-type">Tipos de Procedimentos</Link></li>
              <li hidden={!hasAccess("record", "procedure")}><Link className={`${path === "procedure" ? "mm-active" : ""}`} to="/procedure">Procedimentos</Link></li>
              <li hidden={!hasAccess("record", "user")}><Link className={`${path === "user" ? "mm-active" : ""}`} to="/user">Usuários</Link></li>
            </ul>
          </li>
          <li className={`${schedule.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#" title="Agenda" >
              <i className="material-icons"> table_chart </i>
              <span className="nav-text">Agenda</span>
            </Link>
            <ul>
              <li hidden={!hasAccess("preschedule", "read")}><Link className={`${path === "preschedule" ? "mm-active" : ""}`} to="/preschedule">Pré-Agenda</Link></li>
              <li hidden={!hasAccess("schedule", "read")}><Link className={`${path === "schedule" ? "mm-active" : ""}`} to="/schedule">Agenda</Link></li>
              <li hidden={!hasAccess("scale", "read") && !hasAccess("scale", "update")}><Link className={`${path === "scale" ? "mm-active" : ""}`} to="/scale">Escala</Link></li>
              <li hidden={!hasAccess("availability", "read") && !hasAccess("availability", "update")}><Link className={`${path === "availability" ? "mm-active" : ""}`} to="/availability">Indisponibilidade</Link></li>
            </ul>
          </li>
          <li className={`${clinic.includes(path) ? "mm-active" : ""}`}>
            <Link to="/clinic" className="ai-icon" title="Procedimento" >
              <i className="material-icons"> library_books </i>
              <span className="nav-text">Procedimento</span>
            </Link>
          </li>
          <li className={`${protocol.includes(path) ? "mm-active" : ""}`} hidden={!hasAccess("protocol", "create")}>
            <Link to="/protocol-form" className="ai-icon" title="Protocolo" >
              <i className="material-icons"> list_alt </i>
              <span className="nav-text">Protocolo</span>
            </Link>
          </li>
          <li
            className={`${operational.includes(path) ? "mm-active" : ""}`}
            hidden={!hasAccess("operational", "invoice") && !hasAccess("operational", "receipt") && !hasAccess("operational", "commission")}
          >
            <Link className="has-arrow" to="#" title="Operacional" >
              <i className="material-icons"> attach_money </i>
              <span className="nav-text">Operacional</span>
            </Link>
            <ul>
              <li hidden={!hasAccess("operational", "invoice")}><Link className={`${path === "invoice" ? "mm-active" : ""}`} to="/invoice">Faturamento</Link></li>
              <li hidden={!hasAccess("operational", "receipt")}><Link className={`${path === "receipt" ? "mm-active" : ""}`} to="/receipt">Recebimento</Link></li>
              <li hidden={!hasAccess("operational", "commission")}><Link className={`${path === "commissions" ? "mm-active" : ""}`} to="/commissions">Rateio</Link></li>
            </ul>
          </li>
          <li
            className={`${settings.includes(path) ? "mm-active" : ""}`}
            hidden={!hasAccess("settings", "parms") && !hasAccess("settings", "cancel_reason")}
          >
            <Link className="has-arrow" to="#" title="Configurações" >
              <i className="material-icons"> settings </i>
              <span className="nav-text">Configurações</span>
            </Link>
            <ul>
              <li hidden={!hasAccess("settings", "parms")}><Link className={`${path === "settings" ? "mm-active" : ""}`} to="/settings">Parâmetros</Link></li>
              <li hidden={!hasAccess("settings", "cancel_reason")}><Link className={`${path === "cancel-reasons" ? "mm-active" : ""}`} to="/cancel-reasons">Motivos de Cancelamento</Link></li>
            </ul>
          </li>
          <li
            className={`${events.includes(path) ? "mm-active" : ""}`}
            hidden={!hasAccess("events", "parms") && !hasAccess("events", "cancel_reason")}
          >
            <Link className="has-arrow" to="#" title="Configurações" >
              <i className="material-icons"> events </i>
              <span className="nav-text">Eventos</span>
            </Link>
            <ul>
              <li hidden={!hasAccess("events", "logs")}><Link className={`${path === "settings" ? "mm-active" : ""}`} to="/logs">Logs</Link></li>
            </ul>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
