import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Table } from "react-bootstrap";

import { otherMedications } from "../../../enum/enums";

const OtherMedicationCard = props => {
    const {
        children,
        className,
        //
        onRequestAdd,
        otherMedicationsListParm,
        ...attributes
    } = props;

    const [otherMedicationsList, setOtherMedicationsList] = useState(otherMedicationsListParm);
    const [type, setType] = useState(null);
    const [dose, setDose] = useState("");
    const [time, setTime] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [idRowEditing, setIdRowEditing] = useState(undefined);

    useEffect(() => {
        setOtherMedicationsList(otherMedicationsListParm);
    }, [otherMedicationsListParm]);

    function handleAddOtherMedication() {
        if (!isEditing) {
            const newOtherMedicationItem = {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type,
                dose,
                time
            };

            const newOtherMedicationsList = [...otherMedicationsList, newOtherMedicationItem];
            setOtherMedicationsList(newOtherMedicationsList);
            onRequestAdd(newOtherMedicationsList);
        } else {
            const newOtherMedicationsList = otherMedicationsList.map(otherMedicationItem => {
                if (otherMedicationItem.id === idRowEditing) {
                    return {
                        id: otherMedicationItem.id,
                        type,
                        dose,
                        time
                    }
                }

                return otherMedicationItem;
            })
            setOtherMedicationsList(newOtherMedicationsList);
            onRequestAdd(newOtherMedicationsList);
        }

        resetFields();
    }

    function handleEditOtherMedication(id) {
        const editRowInfo = otherMedicationsList.find(x => x.id === id);
        if (editRowInfo) {
            setType(editRowInfo.type);
            setDose(editRowInfo.dose);
            setTime(editRowInfo.time);

            setIdRowEditing(id);
            setIsEditing(true);
        }
    }

    function handleDeleteOtherMedications(id) {
        const newOtherMedicationsList = otherMedicationsList.filter(x => x.id !== id);
        setOtherMedicationsList(newOtherMedicationsList);
        onRequestAdd(newOtherMedicationsList);
    }

    function resetFields() {
        setType(null);
        setDose("");
        setTime("");

        setIsEditing(false);
        setIdRowEditing(undefined);
    }

    return (
        <>
            <DCard borderColor="light">
                <DCardHeader color="light" textColor="dark">Demais Medicações</DCardHeader>
                <DCardBody>
                    <div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-6">
                                <label>Demais Medicações</label>
                                <Select
                                    isClearable
                                    defaultValue={type}
                                    value={type}
                                    onChange={(e) => setType(e)}
                                    options={otherMedications}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: 45,
                                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                        }),
                                    }}
                                    placeholder="Selecione..."
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <label>Dose</label>
                                <InputMask
                                    mask="99"
                                    maskChar=" "
                                    value={dose}
                                    onChange={(event) => {
                                        setDose(event?.target.value);
                                    }}
                                    alwaysShowMask={true}
                                >
                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <label>Tempo</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={time}
                                    onChange={e => setTime(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <button className="btn btn-primary shadow mt-4 ms-1" onClick={handleAddOtherMedication}>
                                    <i className="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group mb-3 col-md-12" hidden={otherMedicationsList.length === 0}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Medicação</strong>
                                            </th>
                                            <th>
                                                <strong>Dose</strong>
                                            </th>
                                            <th>
                                                <strong>Tempo</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            otherMedicationsList.map((otherMedicationItem) => {
                                                return (
                                                    <tr key={otherMedicationItem.id}>
                                                        <td>{otherMedicationItem.type.label}</td>
                                                        <td>{otherMedicationItem.dose}</td>
                                                        <td>{otherMedicationItem.time}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-info shadow sharp ms-1"
                                                                    onClick={() => handleEditOtherMedication(otherMedicationItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger shadow sharp ms-1"
                                                                    onClick={() => handleDeleteOtherMedications(otherMedicationItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </DCardBody>
            </DCard>
        </>
    )
}

OtherMedicationCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

OtherMedicationCard.defaultProps = {
    otherMedicationsListParm: []
};

export default OtherMedicationCard;