import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, FormCheck, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import DFormText from "../../components/DFormText/DFormText";

import api from "../../../services/api";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

const CancelReasonForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingCancelReasonForm, setLoadingCancelReasonForm] = useState(false);

    const [code, setCode] = useState("");
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(true);

    const [cancelReasonId, setCancelReasonId] = useState(undefined);

    const [invalidCode, setInvalidCode] = useState(false);
    const [invalidDescription, setInvalidDescription] = useState(false);

    let errorMessage = "Ocorreu um problema ao salvar o registro";

    useImperativeHandle(ref, () => ({
        openModal(mode, cancelReasonInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            resetFields();

            if (mode === "update") {
                setIsUpdate(true);

                setCancelReasonId(cancelReasonInfo._id);
                setCode(cancelReasonInfo.code);
                setDescription(cancelReasonInfo.description);
                setActive(cancelReasonInfo.status);
            }
        },
    }));

    async function handleSaveCancelReason(e) {
        e.preventDefault();

        setLoadingCancelReasonForm(true);

        setInvalidCode(false);
        setInvalidDescription(false);

        let hasError = false;

        if (!code) {
            setInvalidCode(true);
            hasError = true;
        }

        if (!description) {
            setInvalidDescription(true);
            hasError = true;
        }

        if (!hasError) {
            let cancelReason = {
                code,
                description,
                status: active
            };

            if (!isUpdate) {
                try {
                    await api.post("/cancel-reasons", cancelReason)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingCancelReasonForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data?.error === "ALREADY_EXISTS") {
                        errorMessage = "Motivo de cancelamento já cadastrado";
                    }
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/cancel-reasons/${cancelReasonId}`, cancelReason)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingCancelReasonForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            }
        }

        setLoadingCancelReasonForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Motivo de cancelamento ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setCancelReasonId(undefined);
        setCode("");
        setDescription("");
        setActive(true);

        setInvalidCode(false);
        setInvalidDescription(false);

        errorMessage = "Ocorreu um problema ao salvar o registro";
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Novo " : "Alterar ")}Motivo de Cancelamento
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-2">
                                    <label>Código</label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className={`form-control ${(invalidCode ? "is-invalid" : "")}`}
                                        value={code}
                                        onChange={e => setCode(e.target.value)}
                                        disabled={isUpdate}
                                        maxLength={3}
                                    />
                                    <DFormText hidden={!invalidCode} color="danger">Informe o código do motivo</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-10">
                                    <label>Descrição</label>
                                    <input
                                        type="text"
                                        className={`form-control ${(invalidDescription ? "is-invalid" : "")}`}
                                        value={description}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                    <DFormText hidden={!invalidDescription} color="danger">Informe a decrição do motivo</DFormText>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                variant="outline-danger btn-rounded"
                                onClick={() => setModalBox(false)}
                            >
                                Fechar
                            </Button>
                            <Button
                                variant="success btn-rounded"
                                onClick={handleSaveCancelReason}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal >

            <ToastContainer />

            <ModalLoading
                visible={loadingCancelReasonForm}
                message="Salvando Registro..."
                onClose={setLoadingCancelReasonForm}
            />
        </>
    );
});

export default CancelReasonForm;
