import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { actualDaaOptions, anticoagOptions, ecgConsultOptions, ecgCrisisOptions, faDiagnosisOptions, faMiocNonIsqOptions, faSessionOptions, faTypeOptions, mainSymptomOptions, meaOptions, protocolYesNoOptions } from "../../../enum/protocolEnums";

const FaForm = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        protocolInfoParm,
        hide,
        ...attributes
    } = props;

    const [mainSymptom, setMainSymptom] = useState(null);
    const [otherMainSymptom, setOtherMainSymptom] = useState("");
    const [faType, setFaType] = useState(null);
    const [faTime, setFaTime] = useState("");
    const [cve, setCve] = useState("");
    const [hospitalization, setHospitalization] = useState("");
    const [betablok, setBetablok] = useState(null);
    const [usedDaa, setUsedDaa] = useState("");
    const [actualDaa, setActualDaa] = useState(null);
    const [otherActualDaa, setOtherActualDaa] = useState("");
    const [anticoag, setAnticoag] = useState(null);
    const [otherAnticoag, setOtherAnticoag] = useState("");
    const [chadsvasc, setChadsvasc] = useState("");
    const [otherMedications, setOtherMedications] = useState("");
    const [diagnosis, setDiagnosis] = useState(null);
    const [otherDiagnosis, setOtherDiagnosis] = useState("");
    const [has, setHas] = useState(null);
    const [diabMelli, setDiabMelli] = useState(null);
    const [apne, setApne] = useState(null);
    const [obes, setObes] = useState(null);
    const [miocIsq, setMiocIsq] = useState(null);
    const [miocNonIsq, setMiocNonIsq] = useState(null);
    const [otherMiocNonIsq, setOtherMiocNonIsq] = useState("");
    const [functionalClass, setFunctionalClass] = useState("");
    const [otherPathology, setOtherPathology] = useState("");
    const [otherPathologyNonCardio, setOtherPathologyNonCardio] = useState("");
    const [ecgCrisis, setEcgCrisis] = useState(null);
    const [otherEcgCrisis, setOtherEcgCrisis] = useState("");
    const [ecgConsult, setEcgConsult] = useState(null);
    const [otherEcgConsult, setOtherEcgConsult] = useState("");
    const [ae, setAe] = useState("");
    const [aeMl, setAeMl] = useState("");
    const [ve, setVe] = useState("");
    const [septo, setSepto] = useState("");
    const [ppost, setPpost] = useState("");
    const [fe, setFe] = useState("");
    const [rni, setRni] = useState("");
    const [angioTomo, setAngioTomo] = useState(null);
    const [vvpp, setVvpp] = useState(null);
    const [observation, setObservation] = useState("");

    const [eefNumber, setEefNumber] = useState("");
    const [session, setSession] = useState(null);
    const [mea, setMea] = useState(null);
    const [ecointra, setEcointra] = useState(null);
    const [ecgAblation, setEcgAblation] = useState(null);
    const [otherEcgAblation, setOtherEcgAblation] = useState("");
    const [punctionStart, setPunctionStart] = useState("");
    const [geometryStart, setGeometryStart] = useState("");
    const [geometryEnd, setGeometryEnd] = useState("");
    const [tca1Time, setTca1Time] = useState("");
    const [tca1Value, setTca1Value] = useState("");
    const [tca2Time, setTca2Time] = useState("");
    const [tca2Value, setTca2Value] = useState("");
    const [tca3Time, setTca3Time] = useState("");
    const [tca3Value, setTca3Value] = useState("");
    const [tca4Time, setTca4Time] = useState("");
    const [tca4Value, setTca4Value] = useState("");
    const [tca5Time, setTca5Time] = useState("");
    const [tca5Value, setTca5Value] = useState("");
    const [tca6Time, setTca6Time] = useState("");
    const [tca6Value, setTca6Value] = useState("");
    const [tca7Time, setTca7Time] = useState("");
    const [tca7Value, setTca7Value] = useState("");
    const [leftVvppStart, setLeftVvppStart] = useState("");
    const [leftVvppEnd, setLeftVvppEnd] = useState("");
    const [leftKarina, setLeftKarina] = useState("");
    const [leftVvppLesions, setLeftVvppLesions] = useState("");
    const [rightVvppStart, setRightVvppStart] = useState("");
    const [rightVvppEnd, setRightVvppEnd] = useState("");
    const [rightKarina, setRightKarina] = useState("");
    const [rightVvppLesions, setRightVvppLesions] = useState("");
    const [vpseBlock, setVpseBlock] = useState(null);
    const [vpieBlock, setVpieBlock] = useState(null);
    const [vpsdBlock, setVpsdBlock] = useState(null);
    const [vpidBlock, setVpidBlock] = useState(null);
    const [leftAditionalLesions, setLeftAditionalLesions] = useState("");
    const [rightAditionalLesions, setRightAditionalLesions] = useState("");
    const [totalLesions, setTotalLesions] = useState("");
    const [leftEsophagusQuantity, setLeftEsophagusQuantity] = useState("");
    const [leftEsophagusTemperature, setLeftEsophagusTemperature] = useState("");
    const [rightEsophagusQuantity, setRightEsophagusQuantity] = useState("");
    const [rightEsophagusTemperature, setRightEsophagusTemperature] = useState("");
    const [maxBlock, setMaxBlock] = useState(null);
    const [boxBlock, setBoxBlock] = useState(null);
    const [otherStrategy, setOtherStrategy] = useState("");
    const [rxTime, setRxTime] = useState("");
    const [faFinal, setFaFinal] = useState("");
    const [flutterStart, setFlutterStart] = useState("");
    const [flutterEnd, setFlutterEnd] = useState("");
    const [istmoBlock, setIstmoBlock] = useState(null);
    const [catheterRemoval, setCatheterRemoval] = useState("");
    const [hemodinExit, setHemodinExit] = useState("");
    const [complications, setComplications] = useState("");
    const [ablationObservation, setAblationObservation] = useState("");

    useEffect(() => {
        setMainSymptom(protocolInfoParm.mainSymptom ?? null);
        setOtherMainSymptom(protocolInfoParm.otherMainSymptom ?? "");
        setFaType(protocolInfoParm.faType ?? null);
        setFaTime(protocolInfoParm.faTime ?? "");
        setCve(protocolInfoParm.cve ?? "");
        setHospitalization(protocolInfoParm.hospitalization ?? "");
        setBetablok(protocolInfoParm.betablok ?? null);
        setUsedDaa(protocolInfoParm.usedDaa ?? "");
        setActualDaa(protocolInfoParm.actualDaa ?? null);
        setOtherActualDaa(protocolInfoParm.otherActualDaa ?? "");
        setAnticoag(protocolInfoParm.anticoag ?? null);
        setOtherAnticoag(protocolInfoParm.otherAnticoag ?? "");
        setChadsvasc(protocolInfoParm.chadsvasc ?? "");
        setOtherMedications(protocolInfoParm.otherMedications ?? "");
        setDiagnosis(protocolInfoParm.diagnosis ?? null);
        setOtherDiagnosis(protocolInfoParm.otherDiagnosis ?? "");
        setHas(protocolInfoParm.has ?? null);
        setDiabMelli(protocolInfoParm.diabMelli ?? null);
        setApne(protocolInfoParm.apne ?? null);
        setObes(protocolInfoParm.obes ?? null);
        setMiocIsq(protocolInfoParm.miocIsq ?? null);
        setMiocNonIsq(protocolInfoParm.miocNonIsq ?? null);
        setOtherMiocNonIsq(protocolInfoParm.otherMiocNonIsq ?? "");
        setFunctionalClass(protocolInfoParm.functionalClass ?? "");
        setOtherPathology(protocolInfoParm.otherPathology ?? "");
        setOtherPathologyNonCardio(protocolInfoParm.otherPathologyNonCardio ?? "");
        setEcgCrisis(protocolInfoParm.ecgCrisis ?? null);
        setOtherEcgCrisis(protocolInfoParm.otherEcgCrisis ?? "");
        setEcgConsult(protocolInfoParm.ecgConsult ?? null);
        setOtherEcgConsult(protocolInfoParm.otherEcgConsult ?? "");
        setAe(protocolInfoParm.ae ?? "");
        setAeMl(protocolInfoParm.aeMl ?? "");
        setVe(protocolInfoParm.ve ?? "");
        setSepto(protocolInfoParm.septo ?? "");
        setPpost(protocolInfoParm.ppost ?? "");
        setFe(protocolInfoParm.fe ?? "");
        setRni(protocolInfoParm.rni ?? "");
        setAngioTomo(protocolInfoParm.angioTomo ?? null);
        setVvpp(protocolInfoParm.vvpp ?? null);
        setObservation(protocolInfoParm.observation ?? "");

        setEefNumber(protocolInfoParm.eefNumber ?? "");
        setSession(protocolInfoParm.session ?? null);
        setMea(protocolInfoParm.mea ?? null);
        setEcointra(protocolInfoParm.ecointra ?? null);
        setEcgAblation(protocolInfoParm.ecgAblation ?? null);
        setOtherEcgAblation(protocolInfoParm.otherEcgAblation ?? "");
        setPunctionStart(protocolInfoParm.punctionStart ?? "");
        setGeometryStart(protocolInfoParm.geometryStart ?? "");
        setGeometryEnd(protocolInfoParm.geometryEnd ?? "");
        setTca1Time(protocolInfoParm.tca1Time ?? "");
        setTca1Value(protocolInfoParm.tca1Value ?? "");
        setTca2Time(protocolInfoParm.tca2Time ?? "");
        setTca2Value(protocolInfoParm.tca2Value ?? "");
        setTca3Time(protocolInfoParm.tca3Time ?? "");
        setTca3Value(protocolInfoParm.tca3Value ?? "");
        setTca4Time(protocolInfoParm.tca4Time ?? "");
        setTca4Value(protocolInfoParm.tca4Value ?? "");
        setTca5Time(protocolInfoParm.tca5Time ?? "");
        setTca5Value(protocolInfoParm.tca5Value ?? "");
        setTca6Time(protocolInfoParm.tca6Time ?? "");
        setTca6Value(protocolInfoParm.tca6Value ?? "");
        setTca7Time(protocolInfoParm.tca7Time ?? "");
        setTca7Value(protocolInfoParm.tca7Value ?? "");
        setLeftVvppStart(protocolInfoParm.leftVvppStart ?? "");
        setLeftVvppEnd(protocolInfoParm.leftVvppEnd ?? "");
        setLeftKarina(protocolInfoParm.leftKarina ?? "");
        setLeftVvppLesions(protocolInfoParm.leftVvppLesions ?? "");
        setRightVvppStart(protocolInfoParm.rightVvppStart ?? "");
        setRightVvppEnd(protocolInfoParm.rightVvppEnd ?? "");
        setRightKarina(protocolInfoParm.rightKarina ?? "");
        setRightVvppLesions(protocolInfoParm.rightVvppLesions ?? "");
        setVpseBlock(protocolInfoParm.vpseBlock ?? null);
        setVpieBlock(protocolInfoParm.vpieBlock ?? null);
        setVpsdBlock(protocolInfoParm.vpsdBlock ?? null);
        setVpidBlock(protocolInfoParm.vpidBlock ?? null);
        setLeftAditionalLesions(protocolInfoParm.leftAditionalLesions ?? "");
        setRightAditionalLesions(protocolInfoParm.rightAditionalLesions ?? "");
        setTotalLesions(protocolInfoParm.totalLesions ?? "");
        setLeftEsophagusQuantity(protocolInfoParm.leftEsophagusQuantity ?? "");
        setLeftEsophagusTemperature(protocolInfoParm.leftEsophagusTemperature ?? "");
        setRightEsophagusQuantity(protocolInfoParm.rightEsophagusQuantity ?? "");
        setRightEsophagusTemperature(protocolInfoParm.rightEsophagusTemperature ?? "");
        setMaxBlock(protocolInfoParm.maxBlock ?? null);
        setBoxBlock(protocolInfoParm.boxBlock ?? null);
        setOtherStrategy(protocolInfoParm.otherStrategy ?? "");
        setRxTime(protocolInfoParm.rxTime ?? "");
        setFaFinal(protocolInfoParm.faFinal ?? "");
        setFlutterStart(protocolInfoParm.flutterStart ?? "");
        setFlutterEnd(protocolInfoParm.flutterEnd ?? "");
        setIstmoBlock(protocolInfoParm.istmoBlock ?? null);
        setCatheterRemoval(protocolInfoParm.catheterRemoval ?? "");
        setHemodinExit(protocolInfoParm.hemodinExit ?? "");
        setComplications(protocolInfoParm.complications ?? "");
        setAblationObservation(protocolInfoParm.ablationObservation ?? "");
    }, [protocolInfoParm]);

    return (
        <div hidden={hide}>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Queixa Principal</b></label>
                    <Select
                        autoFocus
                        defaultValue={mainSymptom}
                        value={mainSymptom}
                        onChange={(e) => {
                            setMainSymptom(e);
                            onChangeInfo("mainSymptom", e);
                        }}
                        options={mainSymptomOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Queixa Principal (Outra)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMainSymptom}
                        onChange={e => {
                            setOtherMainSymptom(e.target.value);
                            onChangeInfo("otherMainSymptom", e.target.value);
                        }}
                        disabled={mainSymptom?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Tipo de FA</b></label>
                    <Select
                        defaultValue={faType}
                        value={faType}
                        onChange={(e) => {
                            setFaType(e);
                            onChangeInfo("faType", e);
                        }}
                        options={faTypeOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Tempo de FA (meses)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={faTime}
                        onChange={e => {
                            setFaTime(e.target.value);
                            onChangeInfo("faTime", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Quantas CVEs (número)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={cve}
                        onChange={e => {
                            setCve(e.target.value);
                            onChangeInfo("cve", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Quantas Hospitalizações (número)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={hospitalization}
                        onChange={e => {
                            setHospitalization(e.target.value);
                            onChangeInfo("hospitalization", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Betablok</b></label>
                    <Select
                        defaultValue={betablok}
                        value={betablok}
                        onChange={(e) => {
                            setBetablok(e);
                            onChangeInfo("betablok", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Número DAAs já usadas</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={usedDaa}
                        onChange={e => {
                            setUsedDaa(e.target.value);
                            onChangeInfo("usedDaa", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>DAA Atual</b></label>
                    <Select
                        defaultValue={actualDaa}
                        value={actualDaa}
                        onChange={(e) => {
                            setActualDaa(e);
                            onChangeInfo("actualDaa", e);
                        }}
                        options={actualDaaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>DAA Atual (Outra)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherActualDaa}
                        onChange={e => {
                            setOtherActualDaa(e.target.value);
                            onChangeInfo("otherActualDaa", e.target.value);
                        }}
                        disabled={actualDaa?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Anticoag</b></label>
                    <Select
                        defaultValue={anticoag}
                        value={anticoag}
                        onChange={(e) => {
                            setAnticoag(e);
                            onChangeInfo("anticoag", e);
                        }}
                        options={anticoagOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Anticoag (Outro)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherAnticoag}
                        onChange={e => {
                            setOtherAnticoag(e.target.value);
                            onChangeInfo("otherAnticoag", e.target.value);
                        }}
                        disabled={anticoag?.value !== 6}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Chadsvasc (escore)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={chadsvasc}
                        onChange={e => {
                            setChadsvasc(e.target.value);
                            onChangeInfo("chadsvasc", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Outros Medicamentos</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMedications}
                        onChange={e => {
                            setOtherMedications(e.target.value);
                            onChangeInfo("otherMedications", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Diagnóstico</b></label>
                    <Select
                        defaultValue={diagnosis}
                        value={diagnosis}
                        onChange={(e) => {
                            setDiagnosis(e);
                            onChangeInfo("diagnosis", e);
                        }}
                        options={faDiagnosisOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Diagnóstico (Outro)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherDiagnosis}
                        onChange={e => {
                            setOtherDiagnosis(e.target.value);
                            onChangeInfo("otherDiagnosis", e.target.value);
                        }}
                        disabled={diagnosis?.value !== 7}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>HAS</b></label>
                    <Select
                        defaultValue={has}
                        value={has}
                        onChange={(e) => {
                            setHas(e);
                            onChangeInfo("has", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Diab Melli</b></label>
                    <Select
                        defaultValue={diabMelli}
                        value={diabMelli}
                        onChange={(e) => {
                            setDiabMelli(e);
                            onChangeInfo("diabMelli", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Apne O Sono</b></label>
                    <Select
                        defaultValue={apne}
                        value={apne}
                        onChange={(e) => {
                            setApne(e);
                            onChangeInfo("apne", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Obes</b></label>
                    <Select
                        defaultValue={obes}
                        value={obes}
                        onChange={(e) => {
                            setObes(e);
                            onChangeInfo("obes", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Mioc Isq</b></label>
                    <Select
                        defaultValue={miocIsq}
                        value={miocIsq}
                        onChange={(e) => {
                            setMiocIsq(e);
                            onChangeInfo("miocIsq", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Mioc Não Isq</b></label>
                    <Select
                        defaultValue={miocNonIsq}
                        value={miocNonIsq}
                        onChange={(e) => {
                            setMiocNonIsq(e);
                            onChangeInfo("miocNonIsq", e);
                        }}
                        options={faMiocNonIsqOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Mioc Não Isq (Outra)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMiocNonIsq}
                        onChange={e => {
                            setOtherMiocNonIsq(e.target.value);
                            onChangeInfo("otherMiocNonIsq", e.target.value);
                        }}
                        disabled={miocNonIsq?.value !== 6}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Classe Funcional NYHA (número)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={functionalClass}
                        onChange={e => {
                            setFunctionalClass(e.target.value);
                            onChangeInfo("functionalClass", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Outras Patologias Cardiológicas</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherPathology}
                        onChange={e => {
                            setOtherPathology(e.target.value);
                            onChangeInfo("otherPathology", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Outras patologias não cardiológicas</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherPathologyNonCardio}
                        onChange={e => {
                            setOtherPathologyNonCardio(e.target.value);
                            onChangeInfo("otherPathologyNonCardio", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>ECG na crise</b></label>
                    <Select
                        defaultValue={ecgCrisis}
                        value={ecgCrisis}
                        onChange={(e) => {
                            setEcgCrisis(e);
                            onChangeInfo("ecgCrisis", e);
                        }}
                        options={ecgCrisisOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>ECG na crise (Outro)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherEcgCrisis}
                        onChange={e => {
                            setOtherEcgCrisis(e.target.value);
                            onChangeInfo("otherEcgCrisis", e.target.value);
                        }}
                        disabled={ecgCrisis?.value !== 4}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>ECG na consulta</b></label>
                    <Select
                        defaultValue={ecgConsult}
                        value={ecgConsult}
                        onChange={(e) => {
                            setEcgConsult(e);
                            onChangeInfo("ecgConsult", e);
                        }}
                        options={ecgConsultOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>ECG na consulta (Outro)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherEcgConsult}
                        onChange={e => {
                            setOtherEcgConsult(e.target.value);
                            onChangeInfo("otherEcgConsult", e.target.value);
                        }}
                        disabled={ecgConsult?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>AE (mm)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={ae}
                        onChange={e => {
                            setAe(e.target.value);
                            onChangeInfo("ae", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>AE (ml)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={aeMl}
                        onChange={e => {
                            setAeMl(e.target.value);
                            onChangeInfo("aeMl", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>VE (mm)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={ve}
                        onChange={e => {
                            setVe(e.target.value);
                            onChangeInfo("ve", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Septo (mm)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={septo}
                        onChange={e => {
                            setSepto(e.target.value);
                            onChangeInfo("septo", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Ppost (mm)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={ppost}
                        onChange={e => {
                            setPpost(e.target.value);
                            onChangeInfo("ppost", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>FE (%)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={fe}
                        onChange={e => {
                            setFe(e.target.value);
                            onChangeInfo("fe", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>RNI</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rni}
                        onChange={e => {
                            setRni(e.target.value);
                            onChangeInfo("rni", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Angio Tomo</b></label>
                    <Select
                        defaultValue={angioTomo}
                        value={angioTomo}
                        onChange={(e) => {
                            setAngioTomo(e);
                            onChangeInfo("angioTomo", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Anomalia VVPP</b></label>
                    <Select
                        defaultValue={vvpp}
                        value={vvpp}
                        onChange={(e) => {
                            setVvpp(e);
                            onChangeInfo("vvpp", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Obs</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={observation}
                        onChange={e => {
                            setObservation(e.target.value);
                            onChangeInfo("observation", e.target.value);
                        }}
                    />
                </div>
            </div>

            <hr className="mt-4" />

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Número EEF</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={eefNumber}
                        onChange={e => {
                            setEefNumber(e.target.value);
                            onChangeInfo("eefNumber", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Sessão</b></label>
                    <Select
                        defaultValue={session}
                        value={session}
                        onChange={(e) => {
                            setSession(e);
                            onChangeInfo("session", e);
                        }}
                        options={faSessionOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>MEA</b></label>
                    <Select
                        defaultValue={mea}
                        value={mea}
                        onChange={(e) => {
                            setMea(e);
                            onChangeInfo("mea", e);
                        }}
                        options={meaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Eco intra cardíaco</b></label>
                    <Select
                        defaultValue={ecointra}
                        value={ecointra}
                        onChange={(e) => {
                            setEcointra(e);
                            onChangeInfo("ecointra", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>ECG na ablação</b></label>
                    <Select
                        defaultValue={ecgAblation}
                        value={ecgAblation}
                        onChange={(e) => {
                            setEcgAblation(e);
                            onChangeInfo("ecgAblation", e);
                        }}
                        options={ecgConsultOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>ECG na ablação (Outro)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherEcgAblation}
                        onChange={e => {
                            setOtherEcgAblation(e.target.value);
                            onChangeInfo("otherEcgAblation", e.target.value);
                        }}
                        disabled={ecgAblation?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Ablação Início Punção (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={punctionStart}
                        onChange={e => {
                            setPunctionStart(e.target.value);
                            onChangeInfo("punctionStart", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Início geometria (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={geometryStart}
                        onChange={e => {
                            setGeometryStart(e.target.value);
                            onChangeInfo("geometryStart", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Fim geometria (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={geometryEnd}
                        onChange={e => {
                            setGeometryEnd(e.target.value);
                            onChangeInfo("geometryEnd", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 1 (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca1Time}
                        onChange={e => {
                            setTca1Time(e.target.value);
                            onChangeInfo("tca1Time", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 1 (valor)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca1Value}
                        onChange={e => {
                            setTca1Value(e.target.value);
                            onChangeInfo("tca1Value", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 2 (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca2Time}
                        onChange={e => {
                            setTca2Time(e.target.value);
                            onChangeInfo("tca2Time", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 2 (valor)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca2Value}
                        onChange={e => {
                            setTca2Value(e.target.value);
                            onChangeInfo("tca2Value", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 3 (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca3Time}
                        onChange={e => {
                            setTca3Time(e.target.value);
                            onChangeInfo("tca3Time", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 3 (valor)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca3Value}
                        onChange={e => {
                            setTca3Value(e.target.value);
                            onChangeInfo("tca3Value", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 4 (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca4Time}
                        onChange={e => {
                            setTca4Time(e.target.value);
                            onChangeInfo("tca4Time", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 4 (valor)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca4Value}
                        onChange={e => {
                            setTca4Value(e.target.value);
                            onChangeInfo("tca4Value", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 5 (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca5Time}
                        onChange={e => {
                            setTca5Time(e.target.value);
                            onChangeInfo("tca5Time", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 5 (valor)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca5Value}
                        onChange={e => {
                            setTca5Value(e.target.value);
                            onChangeInfo("tca5Value", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 6 (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca6Time}
                        onChange={e => {
                            setTca6Time(e.target.value);
                            onChangeInfo("tca6Time", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 6 (valor)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca6Value}
                        onChange={e => {
                            setTca6Value(e.target.value);
                            onChangeInfo("tca6Value", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 7 (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca7Time}
                        onChange={e => {
                            setTca7Time(e.target.value);
                            onChangeInfo("tca7Time", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>TCA 7 (valor)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={tca7Value}
                        onChange={e => {
                            setTca7Value(e.target.value);
                            onChangeInfo("tca7Value", e.target.value);
                        }}
                    />
                </div>
            </div>

            <hr className="mt-4" />

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Ablação VVPP Esq Início (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={leftVvppStart}
                        onChange={e => {
                            setLeftVvppStart(e.target.value);
                            onChangeInfo("leftVvppStart", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Ablação VVPP Esq Fim (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={leftVvppEnd}
                        onChange={e => {
                            setLeftVvppEnd(e.target.value);
                            onChangeInfo("leftVvppEnd", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Carina Esq</b></label>
                    <Select
                        defaultValue={leftKarina}
                        value={leftKarina}
                        onChange={(e) => {
                            setLeftKarina(e);
                            onChangeInfo("leftKarina", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Lesões RF VVPP Esq (min)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={leftVvppLesions}
                        onChange={e => {
                            setLeftVvppLesions(e.target.value);
                            onChangeInfo("leftVvppLesions", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Ablação VVPP Direita Início (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rightVvppStart}
                        onChange={e => {
                            setRightVvppStart(e.target.value);
                            onChangeInfo("rightVvppStart", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Ablação VVPP Direita Fim (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rightVvppEnd}
                        onChange={e => {
                            setRightVvppEnd(e.target.value);
                            onChangeInfo("rightVvppEnd", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Carina Direita</b></label>
                    <Select
                        defaultValue={rightKarina}
                        value={rightKarina}
                        onChange={(e) => {
                            setRightKarina(e);
                            onChangeInfo("rightKarina", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Lesões RF VVPP Direita (min)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rightVvppLesions}
                        onChange={e => {
                            setRightVvppLesions(e.target.value);
                            onChangeInfo("rightVvppLesions", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Após 20 min VPSE Bloqueio Bidirecion</b></label>
                    <Select
                        defaultValue={vpseBlock}
                        value={vpseBlock}
                        onChange={(e) => {
                            setVpseBlock(e);
                            onChangeInfo("vpseBlock", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Após 20 min VPIE Bloqueio Bidirecion</b></label>
                    <Select
                        defaultValue={vpieBlock}
                        value={vpieBlock}
                        onChange={(e) => {
                            setVpieBlock(e);
                            onChangeInfo("vpieBlock", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Após 20 min VPSD Bloqueio Bidirecion</b></label>
                    <Select
                        defaultValue={vpsdBlock}
                        value={vpsdBlock}
                        onChange={(e) => {
                            setVpsdBlock(e);
                            onChangeInfo("vpsdBlock", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Após 20 min VPID Bloqueio Bidirecion</b></label>
                    <Select
                        defaultValue={vpidBlock}
                        value={vpidBlock}
                        onChange={(e) => {
                            setVpidBlock(e);
                            onChangeInfo("vpidBlock", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Lesões RF Adicionais Esquerda (min)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={leftAditionalLesions}
                        onChange={e => {
                            setLeftAditionalLesions(e.target.value);
                            onChangeInfo("leftAditionalLesions", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Lesões RF Adicionais Direita (min)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rightAditionalLesions}
                        onChange={e => {
                            setRightAditionalLesions(e.target.value);
                            onChangeInfo("rightAditionalLesions", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Total Lesões RF (min)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={totalLesions}
                        onChange={e => {
                            setTotalLesions(e.target.value);
                            onChangeInfo("totalLesions", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Esofago Aquecim VVPP Esq quantas x</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={leftEsophagusQuantity}
                        onChange={e => {
                            setLeftEsophagusQuantity(e.target.value);
                            onChangeInfo("leftEsophagusQuantity", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Esofago Aquecimen VVPP Esq {">"} Temp (oC)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={leftEsophagusTemperature}
                        onChange={e => {
                            setLeftEsophagusTemperature(e.target.value);
                            onChangeInfo("leftEsophagusTemperature", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Esofago Aquecim VVPP Direi quantas x</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rightEsophagusQuantity}
                        onChange={e => {
                            setRightEsophagusQuantity(e.target.value);
                            onChangeInfo("rightEsophagusQuantity", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Esofago Aquecimen VVPP Direi {">"} Temp (oC)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rightEsophagusTemperature}
                        onChange={e => {
                            setRightEsophagusTemperature(e.target.value);
                            onChangeInfo("rightEsophagusTemperature", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Linha Teto com bloq</b></label>
                    <Select
                        defaultValue={maxBlock}
                        value={maxBlock}
                        onChange={(e) => {
                            setMaxBlock(e);
                            onChangeInfo("maxBlock", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Box com bloqueio</b></label>
                    <Select
                        defaultValue={boxBlock}
                        value={boxBlock}
                        onChange={(e) => {
                            setBoxBlock(e);
                            onChangeInfo("boxBlock", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Outras estratégias</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherStrategy}
                        onChange={e => {
                            setOtherStrategy(e.target.value);
                            onChangeInfo("otherStrategy", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Tempo Rx (min)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={rxTime}
                        onChange={e => {
                            setRxTime(e.target.value);
                            onChangeInfo("rxTime", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Final FA (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={faFinal}
                        onChange={e => {
                            setFaFinal(e.target.value);
                            onChangeInfo("faFinal", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Flutter início (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={flutterStart}
                        onChange={e => {
                            setFlutterStart(e.target.value);
                            onChangeInfo("flutterStart", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Flutter fim (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={flutterEnd}
                        onChange={e => {
                            setFlutterEnd(e.target.value);
                            onChangeInfo("flutterEnd", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Bloqueio bi-direc istmo CT</b></label>
                    <Select
                        defaultValue={istmoBlock}
                        value={istmoBlock}
                        onChange={(e) => {
                            setIstmoBlock(e);
                            onChangeInfo("istmoBlock", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label><b>Retirada cateteres (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={catheterRemoval}
                        onChange={e => {
                            setCatheterRemoval(e.target.value);
                            onChangeInfo("catheterRemoval", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Saída hemodin (hora)</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={hemodinExit}
                        onChange={e => {
                            setHemodinExit(e.target.value);
                            onChangeInfo("hemodinExit", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Complicações</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={complications}
                        onChange={e => {
                            setComplications(e.target.value);
                            onChangeInfo("complications", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label><b>Observações</b></label>
                    <input
                        type="text"
                        className="form-control"
                        value={ablationObservation}
                        onChange={e => {
                            setAblationObservation(e.target.value);
                            onChangeInfo("ablationObservation", e.target.value);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

FaForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

FaForm.defaultProps = {
    protocolInfoParm: {},
    hide: false
};

export default FaForm;