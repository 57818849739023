import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { Button, Table } from "react-bootstrap";

import ClinicalManifestationCard from "./ClinicalManifestationCard";
import PreviousTreatmentCard from "./PreviousTreatmentCard";
import dayjs from "dayjs";
import { alcoholOptions, physicalActivityOptions, smokeOptions, yesNoOptions } from "../../../enum/enums";

const OtherDataCard = props => {
    const {
        children,
        className,
        //
        onRequestAdd,
        otherDatasListParm,
        ...attributes
    } = props;

    const [otherDatasList, setOtherDatasList] = useState(otherDatasListParm);
    const [amp, setAmp] = useState("");
    const [af, setAf] = useState("");
    const [rs, setRs] = useState("");
    const [physicalActivity, setPhysicalActivity] = useState(null);
    const [physicalActivityText, setPhysicalActivityText] = useState("");
    const [alcohol, setAlcohol] = useState(null);
    const [smoke, setSmoke] = useState(null);
    const [cigarettes, setCigarettes] = useState(null);

    const [clinicalManifestations, setClinicalManifestations] = useState([]);
    const [previousTreatments, setPreviousTreatments] = useState([]);

    const [isEditing, setIsEditing] = useState(false);
    const [idRowEditing, setIdRowEditing] = useState(undefined);

    useEffect(() => {
        setOtherDatasList(otherDatasListParm);
    }, [otherDatasListParm]);

    function handleAddOtherData() {
        if (!isEditing) {
            const newOtherDataItem = {
                id: (Math.floor(Math.random() * 65536)).toString(),
                date: dayjs().toDate(),
                amp,
                af,
                rs,
                physicalActivity,
                physicalActivityText,
                alcohol,
                smoke,
                cigarettes,
                clinicalManifestations,
                previousTreatments
            };

            const newOtherDatasList = [...otherDatasList, newOtherDataItem];
            setOtherDatasList(newOtherDatasList);
            onRequestAdd(newOtherDatasList);
        } else {
            const newOtherDatasList = otherDatasList.map(otherDataItem => {
                if (otherDataItem.id === idRowEditing) {
                    return {
                        id: otherDataItem.id,
                        amp,
                        af,
                        rs,
                        physicalActivity,
                        physicalActivityText,
                        alcohol,
                        smoke,
                        cigarettes,
                        clinicalManifestations,
                        previousTreatments
                    }
                }

                return otherDataItem;
            })
            setOtherDatasList(newOtherDatasList);
            onRequestAdd(newOtherDatasList);
        }

        resetFields();
    }

    function handleEditOtherData(id) {
        const editRowInfo = otherDatasList.find(x => x.id === id);
        if (editRowInfo) {
            setAmp(editRowInfo.amp)
            setAf(editRowInfo.af)
            setRs(editRowInfo.rs)
            setPhysicalActivity(editRowInfo.physicalActivity)
            setPhysicalActivityText(editRowInfo.physicalActivityText)
            setAlcohol(editRowInfo.alcohol)
            setSmoke(editRowInfo.smoke)
            setCigarettes(editRowInfo.cigarettes)
            setClinicalManifestations(editRowInfo.clinicalManifestations)
            setPreviousTreatments(editRowInfo.previousTreatments)

            setIdRowEditing(id);
            setIsEditing(true);
        }
    }

    function handleDeleteOtherData(id) {
        const newOtherDatasList = otherDatasList.filter(x => x.id !== id);
        setOtherDatasList(newOtherDatasList);
        onRequestAdd(newOtherDatasList);
    }

    function resetFields() {
        setAmp("");
        setAf("");
        setRs("");
        setPhysicalActivity(null);
        setPhysicalActivityText("");
        setAlcohol(null);
        setSmoke(null);
        setCigarettes(null);
        setClinicalManifestations([]);
        setPreviousTreatments([]);

        setIsEditing(false);
        setIdRowEditing(undefined);
    }

    return (
        <>
            <div className="basic-form">
                <div className="row">
                    <div className="form-group mb-3 col-md-3">
                        <label>AMP</label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={amp}
                            onChange={e => setAmp(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-3">
                        <label>AF</label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={af}
                            onChange={e => setAf(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-3">
                        <label>RS</label>
                        <textarea
                            type="text"
                            className="form-control"
                            value={rs}
                            onChange={e => setRs(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-3 col-md-3">
                        <label>Atividade Física</label>
                        <Select
                            isClearable
                            defaultValue={physicalActivity}
                            value={physicalActivity}
                            onChange={(e) => setPhysicalActivity(e)}
                            options={physicalActivityOptions}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                }),
                            }}
                            placeholder="Selecione..."
                        />
                        <br />
                        <textarea
                            type="text"
                            className="form-control"
                            value={physicalActivityText}
                            onChange={e => setPhysicalActivityText(e.target.value)}
                            hidden={!physicalActivity || physicalActivity?.value === "NO"}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group mb-3 col-md-3">
                        <label>Álcool</label>
                        <Select
                            isClearable
                            defaultValue={alcohol}
                            value={alcohol}
                            onChange={(e) => setAlcohol(e)}
                            options={alcoholOptions}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                }),
                            }}
                            placeholder="Selecione..."
                        />
                    </div>
                    <div className="form-group mb-3 col-md-3">
                        <label>Fumo</label>
                        <Select
                            isClearable
                            defaultValue={smoke}
                            value={smoke}
                            onChange={(e) => setSmoke(e)}
                            options={yesNoOptions}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                }),
                            }}
                            placeholder="Selecione..."
                        />
                    </div>
                    <div className="form-group mt-4 mb-3 col-md-3" hidden={!smoke || smoke?.value === "NO"}>
                        <Select
                            isClearable
                            defaultValue={cigarettes}
                            value={cigarettes}
                            onChange={(e) => setCigarettes(e)}
                            options={smokeOptions}
                            styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    height: 45,
                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                }),
                            }}
                            placeholder="Selecione..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <ClinicalManifestationCard
                            onRequestAdd={setClinicalManifestations}
                            clinicalManifestationsListParm={clinicalManifestations}
                        />
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <PreviousTreatmentCard
                            onRequestAdd={setPreviousTreatments}
                            previousTreatmentsListParm={previousTreatments}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-4 col-lg-4">
                        <Button
                            size='sm'
                            variant="warning"
                            onClick={handleAddOtherData}
                        >
                            {(isEditing ? "Salvar" : "Adicionar")} Outros Dados Anamnese
                        </Button>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="form-group mb-3 col-md-12" hidden={otherDatasList.length === 0}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>Data</strong>
                                    </th>
                                    <th>
                                        <strong>Tipo</strong>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    otherDatasList.map((otherDataItem) => {
                                        return (
                                            <tr key={otherDataItem.id}>
                                                <td>{dayjs(otherDataItem.data).format("DD/MM/YYYY")}</td>
                                                <td>Outros dados da Anamnese</td>
                                                <td>
                                                    <div className="d-flex">
                                                        <button
                                                            className="btn btn-info shadow sharp ms-1"
                                                            onClick={() => handleEditOtherData(otherDataItem.id)}
                                                            disabled={isEditing}
                                                        >
                                                            <i className="fas fa-pencil-alt"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-danger shadow sharp ms-1"
                                                            onClick={() => handleDeleteOtherData(otherDataItem.id)}
                                                            disabled={isEditing}
                                                        >
                                                            <i className="fas fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

OtherDataCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

OtherDataCard.defaultProps = {
    otherDatasListParm: []
};

export default OtherDataCard;