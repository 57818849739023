import React, { Fragment, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";

// import PreScheduleForm from "../PreSchedule/PreScheduleForm";
import PageTitle from "../../layouts/PageTitle";

import { isAuthenticated, checkTermsAndPrivacy } from "../../../services/utils";
import ScheduleBody from "./ScheduleBody";
import Terms from "../UserAgreement/Terms";
import Privacy from "../UserAgreement/Privacy";

const Schedule = () => {
    const history = useHistory();
    const termsRef = useRef();
    const privacyRef = useRef();

    useEffect(() => {
        if (!isAuthenticated()) {
            return history.push("/login");
        }

        if (!checkTermsAndPrivacy("terms")) {
            termsRef.current.openModal();
        }

        if (!checkTermsAndPrivacy("privacy")) {
            privacyRef.current.openModal();
        }
    }, [])

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Doctor" motherMenu="Records" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Agenda LEC</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div id="calendar" className="app-fullcalendar">
                                    <ScheduleBody />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <Terms ref={termsRef} />

            <Privacy ref={privacyRef} />
        </>
    )
}

export default Schedule;