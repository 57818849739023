import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, FormCheck, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import DFormText from "../../components/DFormText/DFormText";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import api from "../../../services/api";

const ProcedureGroupForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingProcedureGroupForm, setLoadingProcedureGroupForm] = useState(false);

    const [name, setName] = useState("");
    const [active, setActive] = useState(true);

    const [procedureGroupId, setProcedureGroupId] = useState(undefined);

    const [invalidName, setInvalidName] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o grupo de procedimento");

    useImperativeHandle(ref, () => ({
        openModal(mode, procedureGroupInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            resetFields();

            if (mode === "update") {
                setIsUpdate(true);

                setProcedureGroupId(procedureGroupInfo._id);
                setName(procedureGroupInfo.name);
                setActive(procedureGroupInfo.status);
            }
        },
    }));

    async function handleSaveProcedureGroup(e) {
        e.preventDefault();

        setLoadingProcedureGroupForm(true);

        setInvalidName(false);

        let hasError = false;

        if (name === "") {
            setInvalidName(true);
            hasError = true;
        }

        if (!hasError) {
            let procedureGroup = {
                name,
                status: active
            };

            if (!isUpdate) {
                try {
                    await api.post("/procedure-groups", procedureGroup)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingProcedureGroupForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data?.error === "ALREADY_EXISTS") {
                        setErrorMessage("Grupo de procedimento já cadastrado");
                    }
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/procedure-groups/${procedureGroupId}`, procedureGroup)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingProcedureGroupForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            }
        }

        setLoadingProcedureGroupForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Grupo ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setProcedureGroupId(undefined);
        setName("");
        setActive(true);

        setInvalidName(false);
        // setErrorMessage("Ocorreu um problema ao salvar o grupo de procedimento");
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Novo " : "Alterar ")}Grupo de Procedimento
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        />
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Nome</label>
                                    <input
                                        autoFocus
                                        type="text"
                                        className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        disabled={isUpdate}
                                        maxLength={50}
                                    />
                                    <DFormText hidden={!invalidName} color="danger">Informe o nome do grupo</DFormText>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                variant="outline-danger btn-rounded"
                                onClick={() => setModalBox(false)}
                            >
                                Fechar
                            </Button>
                            <Button
                                variant="success btn-rounded"
                                onClick={handleSaveProcedureGroup}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingProcedureGroupForm}
                message="Salvando Grupo de Procedimento"
                onClose={setLoadingProcedureGroupForm}
            />
        </>
    );
});

export default ProcedureGroupForm;
