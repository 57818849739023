import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import InputMask from "react-input-mask";

import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import { basalRhythms, yesNoOptions } from "../../../enum/enums";
import { inducedTachycardiaTypes } from "../../../enum/ablationEnums";
import { atypicalTrnOptions, basalEcgOptions, commonTrnOptions } from "../../../enum/eefEnums";

const EefCard = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        eefInfoParm,
        hide,
        ...attributes
    } = props;

    const [basalRhythm, setBasalRhythm] = useState(undefined);
    const [otherBasalRhythm, setOtherBasalRhythm] = useState("");
    const [ventricularRate, setVentricularRate] = useState("");
    const [atrialRate, setAtrialRate] = useState("");
    const [prInterval, setPrInterval] = useState("");
    const [qtcInterval, setQtcInterval] = useState("");
    const [ecgReport, setEcgReport] = useState([]);
    const [ahInterval, setAhInterval] = useState("");
    const [hvInterval, setHvInterval] = useState("");
    const [qrsInterval, setQrsInterval] = useState("");
    const [sinusNodeRecovery, setSinusNodeRecovery] = useState("");
    const [bav, setBav] = useState("");
    const [atrioventPeriodAnterograde, setAtrioventPeriodAnterograde] = useState("");
    const [atrioventPeriodFast, setAtrioventPeriodFast] = useState("");
    const [atrioventPeriodSlow, setAtrioventPeriodSlow] = useState("");
    const [bva, setBva] = useState("");
    const [atrioventPeriodRetrograde, setAtrioventPeriodRetrograde] = useState("");
    const [atriumPeriod, setAtriumPeriod] = useState("");
    const [ventriclePeriod, setVentriclePeriod] = useState("");
    const [accessoryPath, setAccessoryPath] = useState(null);
    const [accessoryPathAnterograde, setAccessoryPathAnterograde] = useState("");
    const [accessoryPathRetrograde, setAccessoryPathRetrograde] = useState("");
    const [inducedTachycardia, setInducedTachycardia] = useState(null);
    const [tachycardiaMode, setTachycardiaMode] = useState("");

    const [commonTrnFastSlow, setCommonTrnFastSlow] = useState(false);
    const [commonTrnFastSlowPlace, setCommonTrnFastSlowPlace] = useState(null);
    const [atypicalTrnSlowSlow, setAtypicalTrnSlowSlow] = useState(false);
    const [atypicalTrnSlowSlowPlace, setAtypicalTrnSlowSlowPlace] = useState(null);
    const [atypicalTrnFastSlow, setAtypicalTrnFastSlow] = useState(false);
    const [atypicalTrnFastSlowPlace, setAtypicalTrnFastSlowPlace] = useState(null);

    const [tvaCommonShown, setTvaCommonShown] = useState(false);
    const [tvaCommonShownBavAnterograde, setTvaCommonShownBavAnterograde] = useState("");
    const [tvaCommonShownBavRetrograde, setTvaCommonShownBavRetrograde] = useState("");
    const [tvaCommonShownAnterogradePeriod, setTvaCommonShownAnterogradePeriod] = useState("");
    const [tvaCommonShownRetrogradePeriod, setTvaCommonShownRetrogradePeriod] = useState("");

    const [tvaAtypicalShown, setTvaAtypicalShown] = useState(false);
    const [tvaAtypicalShownAtriumVentricular, setTvaAtypicalShownAtriumVentricular] = useState(false);
    const [tvaAtypicalShownAtriumFascicular, setTvaAtypicalShownAtriumFascicular] = useState(false);
    const [tvaAtypicalShownNodeVentricular, setTvaAtypicalShownNodeVentricular] = useState(false);
    const [tvaAtypicalShownNodeFascicular, setTvaAtypicalShownNodeFascicular] = useState(false);
    const [tvaAtypicalShownFascicleVentricular, setTvaAtypicalShownFascicleVentricular] = useState(false);

    const [tvaCommonHidden, setTvaCommonHidden] = useState(false);
    const [tvaCommonHiddenBva, setTvaCommonHiddenBva] = useState("");
    const [tvaCommonHiddenRetrogradePeriod, setTvaCommonHiddenRetrogradePeriod] = useState("");

    const [tvaAtypicalHidden, setTvaAtypicalHidden] = useState(false);
    const [tvaAtypicalHiddenBva, setTvaAtypicalHiddenBva] = useState("");
    const [tvaAtypicalHiddenRetrogradePeriod, setTvaAtypicalHiddenRetrogradePeriod] = useState("");

    const [taAutomatic, setTaAutomatic] = useState(false);
    const [taReentrant, setTaReentrant] = useState(false);
    const [taTriggered, setTaTriggered] = useState(false);

    const [flaCommonAntiClockwise, setFlaCommonAntiClockwise] = useState(false);
    const [flaCommonClockwise, setFlaCommonClockwise] = useState(false);
    const [flaAtypicalRight, setFlaAtypicalRight] = useState(false);
    const [flaAtypicalLeft, setFlaAtypicalLeft] = useState(false);

    const [tvNormal, setTvNormal] = useState(false);
    const [tvIschemic, setTvIschemic] = useState(false);
    const [tvChagastic, setTvChagastic] = useState(false);
    const [tvOther, setTvOther] = useState(false);

    const [natEscape, setNatEscape] = useState(false);
    const [natBpm, setNatBpm] = useState("");
    const [natNoEscape, setNatNoEscape] = useState(false);

    const [faOstium, setFaOstium] = useState(false);
    const [faLeftBox, setFaLeftBox] = useState(false);
    const [faEarpiece, setFaEarpiece] = useState(false);
    const [faLeftLine, setFaLeftLine] = useState(false);
    const [faIstmo, setFaIstmo] = useState(false);
    const [faRightLine, setFaRightLine] = useState(false);

    const [outTa, setOutTa] = useState(false);
    const [outFla, setOutFla] = useState(false);
    const [outTrn, setOutTrn] = useState(false);
    const [outTva, setOutTva] = useState(false);
    const [outTv, setOutTv] = useState(false);
    const [outEa, setOutEa] = useState(false);
    const [outEv, setOutEv] = useState(false);
    const [outOther, setOutOther] = useState(false);
    const [outOtherText, setOutOtherText] = useState("");
    const [outComment, setOutComment] = useState("");

    const [tachycardiaCycle, setTachycardiaCycle] = useState("");
    const [tachycardiaEcgReport, setTachycardiaEcgReport] = useState("");

    useEffect(() => {
        setBasalRhythm(eefInfoParm.basalRhythm ?? null);
        setOtherBasalRhythm(eefInfoParm.otherBasalRhythm ?? "");
        setVentricularRate(eefInfoParm.ventricularRate ?? "");
        setAtrialRate(eefInfoParm.atrialRate ?? "");
        setPrInterval(eefInfoParm.prInterval ?? "");
        setQtcInterval(eefInfoParm.qtcInterval ?? "");
        setEcgReport(eefInfoParm.ecgReport ?? []);
        setAhInterval(eefInfoParm.ahInterval ?? "");
        setHvInterval(eefInfoParm.hvInterval ?? "");
        setQrsInterval(eefInfoParm.qrsInterval ?? "");
        setSinusNodeRecovery(eefInfoParm.sinusNodeRecovery ?? "");
        setBav(eefInfoParm.bav ?? "");
        setAtrioventPeriodAnterograde(eefInfoParm.atrioventPeriodAnterograde ?? "");
        setAtrioventPeriodFast(eefInfoParm.atrioventPeriodFast ?? "");
        setAtrioventPeriodSlow(eefInfoParm.atrioventPeriodSlow ?? "");
        setBva(eefInfoParm.bva ?? "");
        setAtrioventPeriodRetrograde(eefInfoParm.atrioventPeriodRetrograde ?? "");
        setAtriumPeriod(eefInfoParm.atriumPeriod ?? "");
        setVentriclePeriod(eefInfoParm.ventriclePeriod ?? "");
        setAccessoryPath(eefInfoParm.accessoryPath ?? null);
        setAccessoryPathAnterograde(eefInfoParm.accessoryPathAnterograde ?? "");
        setAccessoryPathRetrograde(eefInfoParm.accessoryPathRetrograde ?? "");
        setInducedTachycardia(eefInfoParm.inducedTachycardia ?? null);
        setCommonTrnFastSlow(eefInfoParm.commonTrnFastSlow);
        setCommonTrnFastSlowPlace(eefInfoParm.commonTrnFastSlowPlace ?? null);
        setAtypicalTrnSlowSlow(eefInfoParm.atypicalTrnSlowSlow);
        setAtypicalTrnSlowSlowPlace(eefInfoParm.atypicalTrnSlowSlowPlace ?? null);
        setAtypicalTrnFastSlow(eefInfoParm.atypicalTrnFastSlow);
        setAtypicalTrnFastSlowPlace(eefInfoParm.atypicalTrnFastSlowPlace ?? null);
        setTvaCommonShown(eefInfoParm.tvaCommonShown);
        setTvaCommonShownBavAnterograde(eefInfoParm.tvaCommonShownBavAnterograde ?? "");
        setTvaCommonShownBavRetrograde(eefInfoParm.tvaCommonShownBavRetrograde ?? "");
        setTvaCommonShownAnterogradePeriod(eefInfoParm.tvaCommonShownAnterogradePeriod ?? "");
        setTvaCommonShownRetrogradePeriod(eefInfoParm.tvaCommonShownRetrogradePeriod ?? "");
        setTvaAtypicalShown(eefInfoParm.tvaAtypicalShown);
        setTvaAtypicalShownAtriumVentricular(eefInfoParm.tvaAtypicalShownAtriumVentricular);
        setTvaAtypicalShownAtriumFascicular(eefInfoParm.tvaAtypicalShownAtriumFascicular);
        setTvaAtypicalShownNodeVentricular(eefInfoParm.tvaAtypicalShownNodeVentricular);
        setTvaAtypicalShownNodeFascicular(eefInfoParm.tvaAtypicalShownNodeFascicular);
        setTvaAtypicalShownFascicleVentricular(eefInfoParm.tvaAtypicalShownFascicleVentricular);
        setTvaCommonHidden(eefInfoParm.tvaCommonHidden);
        setTvaCommonHiddenBva(eefInfoParm.tvaCommonHiddenBva ?? "");
        setTvaCommonHiddenRetrogradePeriod(eefInfoParm.tvaCommonHiddenRetrogradePeriod ?? "");
        setTvaAtypicalHidden(eefInfoParm.tvaAtypicalHidden);
        setTvaAtypicalHiddenBva(eefInfoParm.tvaAtypicalHiddenBva ?? "");
        setTvaAtypicalHiddenRetrogradePeriod(eefInfoParm.tvaAtypicalHiddenRetrogradePeriod ?? "");
        setTaAutomatic(eefInfoParm.taAutomatic);
        setTaReentrant(eefInfoParm.taReentrant);
        setTaTriggered(eefInfoParm.taTriggered);
        setFlaCommonAntiClockwise(eefInfoParm.flaCommonAntiClockwise);
        setFlaCommonClockwise(eefInfoParm.flaCommonClockwise);
        setFlaAtypicalRight(eefInfoParm.flaAtypicalRight);
        setFlaAtypicalLeft(eefInfoParm.flaAtypicalLeft);
        setTvNormal(eefInfoParm.tvNormal);
        setTvIschemic(eefInfoParm.tvIschemic);
        setTvChagastic(eefInfoParm.tvChagastic);
        setTvOther(eefInfoParm.tvOther);
        setNatEscape(eefInfoParm.natEscape);
        setNatBpm(eefInfoParm.natBpm ?? "");
        setNatNoEscape(eefInfoParm.natNoEscape);
        setFaOstium(eefInfoParm.faOstium);
        setFaLeftBox(eefInfoParm.faLeftBox);
        setFaEarpiece(eefInfoParm.faEarpiece);
        setFaLeftLine(eefInfoParm.faLeftLine);
        setFaIstmo(eefInfoParm.faIstmo);
        setFaRightLine(eefInfoParm.faRightLine);
        setOutTa(eefInfoParm.outTa);
        setOutFla(eefInfoParm.outFla);
        setOutTrn(eefInfoParm.outTrn);
        setOutTva(eefInfoParm.outTva);
        setOutTv(eefInfoParm.outTv);
        setOutEa(eefInfoParm.outEa);
        setOutEv(eefInfoParm.outEv);
        setOutOther(eefInfoParm.outOther);
        setOutOtherText(eefInfoParm.outOtherText ?? "");
        setOutComment(eefInfoParm.outComment ?? "");
        setTachycardiaMode(eefInfoParm.tachycardiaMode ?? "");
        setTachycardiaCycle(eefInfoParm.tachycardiaCycle ?? "");
        setTachycardiaEcgReport(eefInfoParm.tachycardiaEcgReport ?? "");
    }, [eefInfoParm]);

    return (
        <div hidden={hide}>
            <div className="form-group mb-3 col-md-12">
                <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Detalhes do estudo eletrofisiológico</DCardHeader>
                    <DCardBody>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Ritmo Basal
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <Select
                                            defaultValue={basalRhythm}
                                            value={basalRhythm}
                                            onChange={(e) => {
                                                setBasalRhythm(e);
                                                onChangeInfo("basalRhythm", e)
                                            }}
                                            options={basalRhythms}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                            placeholder="Selecione..."
                                        />
                                    </div>
                                    <div
                                        className="form-group mb-3 col-md-3"
                                        hidden={basalRhythm?.value !== "OUT"}
                                    >
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={otherBasalRhythm}
                                            onChange={e => {
                                                setOtherBasalRhythm(e.target.value);
                                                onChangeInfo("otherBasalRhythm", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Frequência ventricular basal em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={ventricularRate}
                                            onChange={e => {
                                                setVentricularRate(e.target.value);
                                                onChangeInfo("ventricularRate", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Frequência atrial basal em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={atrialRate}
                                            onChange={e => {
                                                setAtrialRate(e.target.value);
                                                onChangeInfo("atrialRate", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Intervalo PR basal em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={prInterval}
                                            onChange={e => {
                                                setPrInterval(e.target.value);
                                                onChangeInfo("prInterval", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Intervalo QTc basal em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={qtcInterval}
                                            onChange={e => {
                                                setQtcInterval(e.target.value);
                                                onChangeInfo("qtcInterval", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Laudo do ECG basal
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-8">
                                        <Select
                                            value={ecgReport}
                                            closeMenuOnSelect={false}
                                            defaultValue={ecgReport}
                                            isMulti
                                            options={basalEcgOptions}
                                            placeholder="Selecione..."
                                            onChange={(e) => {
                                                setEcgReport(e);
                                                onChangeInfo("ecgReport", e)
                                            }}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Intervalo AH em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={ahInterval}
                                            onChange={e => {
                                                setAhInterval(e.target.value);
                                                onChangeInfo("ahInterval", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Intervalo HV em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={hvInterval}
                                            onChange={e => {
                                                setHvInterval(e.target.value);
                                                onChangeInfo("hvInterval", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "18rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Intervalo QRS em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={qrsInterval}
                                            onChange={e => {
                                                setQrsInterval(e.target.value);
                                                onChangeInfo("qrsInterval", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "28rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Tempo corrigido de recuperação do nó sinusal em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={sinusNodeRecovery}
                                            onChange={e => {
                                                setSinusNodeRecovery(e.target.value);
                                                onChangeInfo("sinusNodeRecovery", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "28rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Ponto de Wenckebach (BAV) do nó atrioventricular em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={bav}
                                            onChange={e => {
                                                setBav(e.target.value);
                                                onChangeInfo("bav", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-5"
                                        style={{ width: "43rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário efetivo anterógrado do nó atrioventricular (Sem dupla vida nodal) em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={atrioventPeriodAnterograde}
                                            onChange={(e) => {
                                                setAtrioventPeriodAnterograde(e.target.value);
                                                onChangeInfo("atrioventPeriodAnterograde", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "35rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário efetivo anterógrado da via rápida do nó atrioventricular
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={atrioventPeriodFast}
                                            onChange={(e) => {
                                                setAtrioventPeriodFast(e.target.value);
                                                onChangeInfo("atrioventPeriodFast", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "35rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário efetivo anterógrado da via lenta do nó atrioventricular
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={atrioventPeriodSlow}
                                            onChange={(e) => {
                                                setAtrioventPeriodSlow(e.target.value);
                                                onChangeInfo("atrioventPeriodSlow", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "35rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Ponto de Wenckebach (BVA) retrógrado do nó atrioventricular em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={bva}
                                            onChange={e => {
                                                setBva(e.target.value);
                                                onChangeInfo("bva", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "27rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário retrógrado do nó atrioventricular
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={atrioventPeriodRetrograde}
                                            onChange={(e) => {
                                                setAtrioventPeriodRetrograde(e.target.value);
                                                onChangeInfo("atrioventPeriodRetrograde", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "19rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário efetivo do átrio
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={atriumPeriod}
                                            onChange={(e) => {
                                                setAtriumPeriod(e.target.value);
                                                onChangeInfo("atriumPeriod", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "19rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário efetivo do ventrículo
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={ventriclePeriod}
                                            onChange={(e) => {
                                                setVentriclePeriod(e.target.value);
                                                onChangeInfo("ventriclePeriod", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "19rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Via acessória manifesta presente
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <Select
                                            defaultValue={accessoryPath}
                                            value={accessoryPath}
                                            onChange={e => {
                                                setAccessoryPath(e);
                                                onChangeInfo("accessoryPath", e);
                                            }}
                                            options={yesNoOptions}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                            placeholder="Selecione..."
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "27rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratária efetivo anterógrado da via acessória
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={accessoryPathAnterograde}
                                            onChange={(e) => {
                                                setAccessoryPathAnterograde(e.target.value);
                                                onChangeInfo("accessoryPathAnterograde", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "27rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário efetivo retrógrado da via acessória
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={accessoryPathRetrograde}
                                            onChange={(e) => {
                                                setAccessoryPathRetrograde(e.target.value);
                                                onChangeInfo("accessoryPathRetrograde", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Taquicardia induzida
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-5">
                                        <Select
                                            defaultValue={inducedTachycardia}
                                            value={inducedTachycardia}
                                            onChange={e => {
                                                setInducedTachycardia(e);
                                                onChangeInfo("inducedTachycardia", e);
                                            }}
                                            options={inducedTachycardiaTypes}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                            placeholder="Selecione..."
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TRN"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="commonTrnFastSlow"
                                            checked={commonTrnFastSlow}
                                            onChange={() => {
                                                setCommonTrnFastSlow(!commonTrnFastSlow);
                                                onChangeInfo("commonTrnFastSlow", !commonTrnFastSlow);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="commonTrnFastSlow">
                                            TRN Comum slow-fast
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <Select
                                            defaultValue={commonTrnFastSlowPlace}
                                            value={commonTrnFastSlowPlace}
                                            onChange={e => {
                                                setCommonTrnFastSlowPlace(e);
                                                onChangeInfo("commonTrnFastSlowPlace", e);
                                            }}
                                            options={commonTrnOptions}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                            placeholder="Local da via lenta..."
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TRN"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="atypicalTrnSlowSlow"
                                            checked={atypicalTrnSlowSlow}
                                            onChange={() => {
                                                setAtypicalTrnSlowSlow(!atypicalTrnSlowSlow);
                                                onChangeInfo("atypicalTrnSlowSlow", !atypicalTrnSlowSlow);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="atypicalTrnSlowSlow">
                                            TRN Atípica slow-slow
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <Select
                                            defaultValue={atypicalTrnSlowSlowPlace}
                                            value={atypicalTrnSlowSlowPlace}
                                            onChange={e => {
                                                setAtypicalTrnSlowSlowPlace(e);
                                                onChangeInfo("atypicalTrnSlowSlowPlace", e);
                                            }}
                                            options={atypicalTrnOptions}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                            placeholder="Local da via lenta..."
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TRN"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="atypicalTrnFastSlow"
                                            checked={atypicalTrnFastSlow}
                                            onChange={() => {
                                                setAtypicalTrnFastSlow(!atypicalTrnFastSlow);
                                                onChangeInfo("atypicalTrnFastSlow", !atypicalTrnFastSlow);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="atypicalTrnFastSlow">
                                            TRN Atípica slow-fast
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <Select
                                            defaultValue={atypicalTrnFastSlowPlace}
                                            value={atypicalTrnFastSlowPlace}
                                            onChange={e => {
                                                setAtypicalTrnFastSlowPlace(e);
                                                onChangeInfo("atypicalTrnFastSlowPlace", e);
                                            }}
                                            options={atypicalTrnOptions}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                            placeholder="Local da via lenta..."
                                        />
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "TVA"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaCommonShown"
                                            checked={tvaCommonShown}
                                            onChange={() => {
                                                setTvaCommonShown(!tvaCommonShown);
                                                onChangeInfo("tvaCommonShown", !tvaCommonShown);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaCommonShown">
                                            Via acessória manifesta comum
                                        </label>
                                    </div>
                                </div>

                                <div className="row" hidden={!tvaCommonShown}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            BAV anterógrado da via acessória comum
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={tvaCommonShownBavAnterograde}
                                            onChange={e => {
                                                setTvaCommonShownBavAnterograde(e.target.value);
                                                onChangeInfo("tvaCommonShownBavAnterograde", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaCommonShown}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            BAV retrógrado da via acessória comum (se houver)
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={tvaCommonShownBavRetrograde}
                                            onChange={e => {
                                                setTvaCommonShownBavRetrograde(e.target.value);
                                                onChangeInfo("tvaCommonShownBavRetrograde", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaCommonShown}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário anterógrado da via acessória comum
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={tvaCommonShownAnterogradePeriod}
                                            onChange={(e) => {
                                                setTvaCommonShownAnterogradePeriod(e.target.value);
                                                onChangeInfo("tvaCommonShownAnterogradePeriod", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaCommonShown}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário retrógrado da via acessória comum (se houver)
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={tvaCommonShownRetrogradePeriod}
                                            onChange={(e) => {
                                                setTvaCommonShownRetrogradePeriod(e.target.value);
                                                onChangeInfo("tvaCommonShownRetrogradePeriod", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "TVA"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaAtypicalShown"
                                            checked={tvaAtypicalShown}
                                            onChange={() => {
                                                setTvaAtypicalShown(!tvaAtypicalShown);
                                                onChangeInfo("tvaAtypicalShown", !tvaAtypicalShown);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaAtypicalShown">
                                            Via acessória manifesta atípica (Mahaim)
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaAtypicalShown}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaAtypicalShownAtriumVentricular"
                                            checked={tvaAtypicalShownAtriumVentricular}
                                            onChange={() => {
                                                setTvaAtypicalShownAtriumVentricular(!tvaAtypicalShownAtriumVentricular);
                                                onChangeInfo("tvaAtypicalShownAtriumVentricular", !tvaAtypicalShownAtriumVentricular);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaAtypicalShownAtriumVentricular">
                                            átrio-ventricular
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaAtypicalShown}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaAtypicalShownAtriumFascicular"
                                            checked={tvaAtypicalShownAtriumFascicular}
                                            onChange={() => {
                                                setTvaAtypicalShownAtriumFascicular(!tvaAtypicalShownAtriumFascicular);
                                                onChangeInfo("tvaAtypicalShownAtriumFascicular", !tvaAtypicalShownAtriumFascicular);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaAtypicalShownAtriumFascicular">
                                            átrio-fascicular
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaAtypicalShown}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaAtypicalShownNodeVentricular"
                                            checked={tvaAtypicalShownNodeVentricular}
                                            onChange={() => {
                                                setTvaAtypicalShownNodeVentricular(!tvaAtypicalShownNodeVentricular);
                                                onChangeInfo("tvaAtypicalShownNodeVentricular", !tvaAtypicalShownNodeVentricular);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaAtypicalShownNodeVentricular">
                                            nodo-ventricular
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaAtypicalShown}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaAtypicalShownNodeFascicular"
                                            checked={tvaAtypicalShownNodeFascicular}
                                            onChange={() => {
                                                setTvaAtypicalShownNodeFascicular(!tvaAtypicalShownNodeFascicular);
                                                onChangeInfo("tvaAtypicalShownNodeFascicular", !tvaAtypicalShownNodeFascicular);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaAtypicalShownNodeFascicular">
                                            nodo-fascicular
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaAtypicalShown}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaAtypicalShownFascicleVentricular"
                                            checked={tvaAtypicalShownFascicleVentricular}
                                            onChange={() => {
                                                setTvaAtypicalShownFascicleVentricular(!tvaAtypicalShownFascicleVentricular);
                                                onChangeInfo("tvaAtypicalShownFascicleVentricular", !tvaAtypicalShownFascicleVentricular);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaAtypicalShownFascicleVentricular">
                                            fasículo-ventricular
                                        </label>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "TVA"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaCommonHidden"
                                            checked={tvaCommonHidden}
                                            onChange={() => {
                                                setTvaCommonHidden(!tvaCommonHidden);
                                                onChangeInfo("tvaCommonHidden", !tvaCommonHidden);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaCommonHidden">
                                            Via acessória oculta comum
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaCommonHidden}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            BVA da via acessória oculta comum
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={tvaCommonHiddenBva}
                                            onChange={e => {
                                                setTvaCommonHiddenBva(e.target.value);
                                                onChangeInfo("tvaCommonHiddenBva", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaCommonHidden}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário retrógrado da via acessória oculta comum
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={tvaCommonHiddenRetrogradePeriod}
                                            onChange={(e) => {
                                                setTvaCommonHiddenRetrogradePeriod(e.target.value);
                                                onChangeInfo("tvaCommonHiddenRetrogradePeriod", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "TVA"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvaAtypicalHidden"
                                            checked={tvaAtypicalHidden}
                                            onChange={() => {
                                                setTvaAtypicalHidden(!tvaAtypicalHidden);
                                                onChangeInfo("tvaAtypicalHidden", !tvaAtypicalHidden);
                                            }}
                                        />
                                        <label className="form-check-label ms-3" htmlFor="tvaAtypicalHidden">
                                            Via acessória oculta atípica (Coumel)
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaAtypicalHidden}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            BVA da via acessória oculta atípica de Coumel
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={tvaAtypicalHiddenBva}
                                            onChange={e => {
                                                setTvaAtypicalHiddenBva(e.target.value);
                                                onChangeInfo("tvaAtypicalHiddenBva", e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={!tvaAtypicalHidden}>
                                    <div
                                        className="form-group mb-2 mt-2 ms-5 col-md-4"
                                        style={{ width: "32rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Período refratário retrógrado da via acessória oculta atípica
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-2">
                                        <InputMask
                                            mask="999:999"
                                            maskChar=" "
                                            value={tvaAtypicalHiddenRetrogradePeriod}
                                            onChange={(e) => {
                                                setTvaAtypicalHiddenRetrogradePeriod(e.target.value);
                                                onChangeInfo("tvaAtypicalHiddenRetrogradePeriod", e.target.value);
                                            }}
                                            alwaysShowMask={true}
                                        >
                                            {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                        </InputMask>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "TA"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Taquicardia atrial:
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="taAutomatic"
                                            checked={taAutomatic}
                                            onChange={() => {
                                                setTaAutomatic(!taAutomatic);
                                                onChangeInfo("taAutomatic", !taAutomatic);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="taAutomatic">
                                            Automática
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="taReentrant"
                                            checked={taReentrant}
                                            onChange={() => {
                                                setTaReentrant(!taReentrant);
                                                onChangeInfo("taReentrant", !taReentrant);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="taReentrant">
                                            Reentrante
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="taTriggered"
                                            checked={taTriggered}
                                            onChange={() => {
                                                setTaTriggered(!taTriggered);
                                                onChangeInfo("taTriggered", !taTriggered);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="taTriggered">
                                            Deflagrada
                                        </label>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "FLA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Flutter atrial:
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FLA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="flaCommonAntiClockwise"
                                            checked={flaCommonAntiClockwise}
                                            onChange={() => {
                                                setFlaCommonAntiClockwise(!flaCommonAntiClockwise);
                                                onChangeInfo("flaCommonAntiClockwise", !flaCommonAntiClockwise);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="flaCommonAntiClockwise">
                                            Comum anti-horário istmo cavotricúspide dependente
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FLA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="flaCommonClockwise"
                                            checked={flaCommonClockwise}
                                            onChange={() => {
                                                setFlaCommonClockwise(!flaCommonClockwise);
                                                onChangeInfo("flaCommonClockwise", !flaCommonClockwise);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="flaCommonClockwise">
                                            Comum horário istmo cavotricúspide dependente
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FLA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="flaAtypicalRight"
                                            checked={flaAtypicalRight}
                                            onChange={() => {
                                                setFlaAtypicalRight(!flaAtypicalRight);
                                                onChangeInfo("flaAtypicalRight", !flaAtypicalRight);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="flaAtypicalRight">
                                            Atípico de átrio direito
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FLA"}>
                                    <div
                                        className="form-group mb-3 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="flaAtypicalLeft"
                                            checked={flaAtypicalLeft}
                                            onChange={() => {
                                                setFlaAtypicalLeft(!flaAtypicalLeft);
                                                onChangeInfo("flaAtypicalLeft", !flaAtypicalLeft);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="flaAtypicalLeft">
                                            Atípico de átrio esquerdo
                                        </label>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "TV"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Taquicardia ventricular de:
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TV"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvNormal"
                                            checked={tvNormal}
                                            onChange={() => {
                                                setTvNormal(!tvNormal);
                                                onChangeInfo("tvNormal", !tvNormal);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="tvNormal">
                                            Coração normal
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TV"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvIschemic"
                                            checked={tvIschemic}
                                            onChange={() => {
                                                setTvIschemic(!tvIschemic);
                                                onChangeInfo("tvIschemic", !tvIschemic);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="tvIschemic">
                                            Coração com cicatriz isquêmica
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TV"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvChagastic"
                                            checked={tvChagastic}
                                            onChange={() => {
                                                setTvChagastic(!tvChagastic);
                                                onChangeInfo("tvChagastic", !tvChagastic);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="tvChagastic">
                                            Coração com cicatriz Chagástica
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "TV"}>
                                    <div
                                        className="form-group mb-3 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="tvOther"
                                            checked={tvOther}
                                            onChange={() => {
                                                setTvOther(!tvOther);
                                                onChangeInfo("tvOther", !tvOther);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="tvOther">
                                            Coração com cicatriz por outra doença
                                        </label>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "NAT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Nó atrioventricular:
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "NAT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-2"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="natEscape"
                                            checked={natEscape}
                                            onChange={() => {
                                                setNatEscape(!natEscape);
                                                onChangeInfo("natEscape", !natEscape);
                                            }}
                                        />
                                        <label className="form-check-label">
                                            Ablação do nó atrioventricular com escape de:
                                        </label>
                                    </div>
                                    <div
                                        className="form-group mb-3 col-md-1"
                                        style={{ width: "5rem" }}
                                    >
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ height: "2rem", width: "4.5rem" }}
                                            value={natBpm}
                                            onChange={(e) => {
                                                setNatBpm(e.target.value);
                                                onChangeInfo("natBpm", e.target.value);
                                            }}
                                            maxLength={2}
                                            disabled={!natEscape}
                                        />
                                    </div>
                                    <div
                                        className="form-group mb-3 mt-1 col-md-3"
                                        style={{ width: "auto" }}
                                    >
                                        <label className="form-check-label ms-2" htmlFor="natEscape">
                                            batimentos por minuto
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "NAT"}>
                                    <div
                                        className="form-group mb-3 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="natNoEscape"
                                            checked={natNoEscape}
                                            onChange={() => {
                                                setNatNoEscape(!natNoEscape);
                                                onChangeInfo("natNoEscape", !natNoEscape);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="natNoEscape">
                                            Ablação do nó atrioventricular sem escape
                                        </label>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "FA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Foram abordados:
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="faOstium"
                                            checked={faOstium}
                                            onChange={() => {
                                                setFaOstium(!faOstium);
                                                onChangeInfo("faOstium", !faOstium);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="faOstium">
                                            Óstio das veias pulmonares
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="faLeftBox"
                                            checked={faLeftBox}
                                            onChange={() => {
                                                setFaLeftBox(!faLeftBox);
                                                onChangeInfo("faLeftBox", !faLeftBox);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="faLeftBox">
                                            Box da parede posterior do átrio esquerdo
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="faEarpiece"
                                            checked={faEarpiece}
                                            onChange={() => {
                                                setFaEarpiece(!faEarpiece);
                                                onChangeInfo("faEarpiece", !faEarpiece);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="faEarpiece">
                                            Auriculeta
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="faLeftLine"
                                            checked={faLeftLine}
                                            onChange={() => {
                                                setFaLeftLine(!faLeftLine);
                                                onChangeInfo("faLeftLine", !faLeftLine);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="faLeftLine">
                                            Linha de lesão acessória no átrio esquerdo
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FA"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="faIstmo"
                                            checked={faIstmo}
                                            onChange={() => {
                                                setFaIstmo(!faIstmo);
                                                onChangeInfo("faIstmo", !faIstmo);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="faIstmo">
                                            Istmo cavo-tricúspide
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "FA"}>
                                    <div
                                        className="form-group mb-3 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="faRightLine"
                                            checked={faRightLine}
                                            onChange={() => {
                                                setFaRightLine(!faRightLine);
                                                onChangeInfo("faRightLine", !faRightLine);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="faRightLine">
                                            Linha de lesão acessória no átrio direito
                                        </label>
                                    </div>
                                </div>

                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div className="form-group mb-2 mt-1 col-md-4">
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Taquicardia clínica não induzida ou não indutível, descrita como:
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outTa"
                                            checked={outTa}
                                            onChange={() => {
                                                setOutTa(!outTa);
                                                onChangeInfo("outTa", !outTa);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outTa">
                                            Taquicardia atrial
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outFla"
                                            checked={outFla}
                                            onChange={() => {
                                                setOutFla(!outFla);
                                                onChangeInfo("outFla", !outFla);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outFla">
                                            Flutter atrial
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outTrn"
                                            checked={outTrn}
                                            onChange={() => {
                                                setOutTrn(!outTrn);
                                                onChangeInfo("outTrn", !outTrn);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outTrn">
                                            Taquicardia por reentrada nodal comum
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outTva"
                                            checked={outTva}
                                            onChange={() => {
                                                setOutTva(!outTva);
                                                onChangeInfo("outTva", !outTva);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outTva">
                                            Taquicardia por via acessória
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outTv"
                                            checked={outTv}
                                            onChange={() => {
                                                setOutTv(!outTv);
                                                onChangeInfo("outTv", !outTv);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outTv">
                                            Taquicardia ventricular
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outEa"
                                            checked={outEa}
                                            onChange={() => {
                                                setOutEa(!outEa);
                                                onChangeInfo("outEa", !outEa);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outEa">
                                            Extrassístole atrial
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-1 col-md-8"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outEv"
                                            checked={outEv}
                                            onChange={() => {
                                                setOutEv(!outEv);
                                                onChangeInfo("outEv", !outEv);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outEv">
                                            Extrassístole ventricular
                                        </label>
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-3 mt-1 col-md-2"
                                        style={{ width: "auto" }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="outOther"
                                            checked={outOther}
                                            onChange={() => {
                                                setOutOther(!outOther);
                                                onChangeInfo("outOther", !outOther);
                                            }}
                                        />
                                        <label className="form-check-label ms-2" htmlFor="outOther">
                                            Outra
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            style={{ height: "2rem" }}
                                            value={outOtherText}
                                            onChange={e => setOutOtherText(e.target.value)}
                                            maxLength={64}
                                            disabled={!outOther}
                                        />
                                    </div>
                                </div>
                                <div className="row" hidden={inducedTachycardia?.value !== "OUT"}>
                                    <div
                                        className="form-group mb-2 mt-2 col-md-2"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Motivo da ablação empírica e comentários eventuais dessa abordagem
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-9">
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            value={outComment}
                                            onChange={e => {
                                                setOutComment(e.target.value);
                                                onChangeInfo("outComment", e.target.value);
                                            }}
                                            maxLength={256}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Modo de indução da taquicardia
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={tachycardiaMode}
                                            onChange={e => {
                                                setTachycardiaMode(e.target.value);
                                                onChangeInfo("tachycardiaMode", e.target.value);
                                            }}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Ciclo da taquicardia induzida em ms
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={tachycardiaCycle}
                                            onChange={e => {
                                                setTachycardiaCycle(e.target.value);
                                                onChangeInfo("tachycardiaCycle", e.target.value);
                                            }}
                                            min="0"
                                            oninput="validity.valid||(value='');"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div
                                        className="form-group mb-2 mt-2 col-md-4"
                                        style={{ width: "20rem" }}
                                    >
                                        <label className="form-check-label" htmlFor="materialOption21">
                                            Laudo do ECG da taquicardia induzida
                                        </label>
                                    </div>
                                    <div className="form-group mb-3 col-md-9">
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            value={tachycardiaEcgReport}
                                            onChange={e => {
                                                setTachycardiaEcgReport(e.target.value);
                                                onChangeInfo("tachycardiaEcgReport", e.target.value);
                                            }}
                                            maxLength={256}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DCardBody>
                </DCard>
            </div>
        </div >
    )
}

EefCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

EefCard.defaultProps = {
    eefInfoParm: {},
    hide: false
};

export default EefCard;