import React, {
    forwardRef,
    useImperativeHandle,
    Fragment,
    useRef,
    useState
} from "react";
import { useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import dayjs from "dayjs";

import PageTitle from "../../layouts/PageTitle";
import DPagination from "../../components/DPagination/DPagination";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

import FollowUpForm from "./FollowUpForm";

import { createLog } from "../../../services/logService";
import api from "../../../services/api";

const FollowUpList = forwardRef((props, ref) => {
    const followUpFormRef = useRef();
    const [modalBox, setModalBox] = useState(false);
    const [loading, setLoading] = useState(false);

    const [followUps, setFollowUps] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [pacientId, setPacientId] = useState(1);

    useImperativeHandle(ref, () => ({
        async openModal(pacientIdParm = "") {
            setPacientId(pacientIdParm);

            getFollowUps(currentPage, pacientIdParm);
        },
    }));

    async function getFollowUps(page = currentPage, id = pacientId) {
        setLoading(true);

        await api.get(`/follow-ups?offset=${page}&limit=10&pacientId=${id}`)
            .then(response => {
                if (response.status === 200) {
                    setFollowUps(response.data.followUps);

                    const pages = Math.ceil(response.data.total / 10);
                    const maxPages = Math.max(pages, 1);

                    if (maxPages !== totalPages) {
                        setTotalPages(maxPages);
                    }
                }
            })

        setLoading(false);
        setModalBox(true);
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getFollowUps(page);
    }

    function handleDeleteFollowUp(id) {
        swal({
            title: "Deseja excluir o seguimento?",
            icon: "warning",
            buttons: ["Fechar", "Confirmar"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                try {
                    api.delete(`/follow-ups/${id}`)
                        .then(response => {
                            if (response.status === 200) {
                                createLog({
                                    routine: "followup",
                                    action: "delete",
                                    type: "success",
                                    message: `Seguimento ${id} excluído`,
                                    jsonOrigin: JSON.stringify({ id }),
                                    jsonReturn: JSON.stringify(response)
                                });

                                notifySuccess();
                                getFollowUps();
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "followup",
                        action: "delete",
                        type: "error",
                        message: `Seguimento ${id} não excluído`,
                        jsonOrigin: JSON.stringify({ id }),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            }
        })
    }

    function notifySuccess() {
        toast.success(`✔️ Formulário excluído com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao excluir o formulário`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    function onCloseModal() {
        getFollowUps();
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <Fragment>
                    <PageTitle activeMenu="Medical-Record" motherMenu="Medical-Record" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Header>
                                    <Card.Title>Seguimentos</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong>Data do seguimento</strong>
                                                </th>
                                                <th>
                                                    <strong>Data do procedimento</strong>
                                                </th>
                                                <th>
                                                    <strong>Procedimento</strong>
                                                </th>
                                                <th>
                                                    <strong>Médico</strong>
                                                </th>
                                                <th>
                                                    <strong>Hospital</strong>
                                                </th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                followUps.map((followUpItem) => {
                                                    return (
                                                        <tr key={followUpItem._id}>
                                                            <td>{dayjs(followUpItem.follow_date).format("DD/MM/YYYY")}</td>
                                                            <td>{dayjs(followUpItem.procedure_date).format("DD/MM/YYYY")}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="w-space-no">{followUpItem.procedure_id}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="w-space-no">{followUpItem.doctor_name}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="w-space-no">{followUpItem.hospital_name}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <button
                                                                        className="btn btn-primary shadow sharp ms-1"
                                                                        onClick={() => followUpFormRef.current.openModal("update", followUpItem._id)}
                                                                        title="Editar Seguimento"
                                                                    >
                                                                        <i className="fas fa-pencil-alt"></i>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-primary shadow sharp ms-1"
                                                                        onClick={() => handleDeleteFollowUp(followUpItem._id)}
                                                                        title="Excluir Seguimento"
                                                                    >
                                                                        <i className="fas fa-trash"></i>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>

                                    <DPagination
                                        activePage={currentPage}
                                        pages={totalPages}
                                        onActivePageChange={(i) => setActivePage(i)}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Fragment>

                <div className="modal-footer">
                    <Button
                        variant="outline-danger btn-rounded"
                        onClick={() => setModalBox(false)}
                    >
                        Fechar
                    </Button>
                </div>

                <ModalLoading
                    visible={loading}
                    message="Carregando Seguimentos..."
                    onClose={setLoading}
                />
            </Modal>

            <ToastContainer />

            <FollowUpForm ref={followUpFormRef} onClose={onCloseModal} />
        </>
    );
});

export default FollowUpList;