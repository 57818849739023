import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
    Button
} from "react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { downloadExcel } from "react-export-table-to-excel";

import api from "../../../services/api";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

import { checkTermsAndPrivacy, isAuthenticated } from "../../../services/utils";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs";
import swal from "sweetalert";
import Terms from "../UserAgreement/Terms";
import Privacy from "../UserAgreement/Privacy";

registerLocale("ptBR", ptBR);

const Commissions = () => {
    const termsRef = useRef();
    const privacyRef = useRef();
    const [loading, setLoading] = useState(false);
    const [commissionsList, setCommissionsList] = useState([]);
    const [commissionsGeneralList, setCommissionsGeneralList] = useState([]);

    const [doctorsFullList, setDoctorsFullList] = useState([]);
    const [healthPlans, setHealthPlans] = useState([]);

    const [filterDateFrom, setFilterDateFrom] = useState(dayjs().toDate());
    const [filterDateTo, setFilterDateTo] = useState(dayjs().toDate());
    const [filterHealthPlans, setFilterHealthPlans] = useState([]);

    const [hidePrint, setHidePrint] = useState(true);

    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated()) {
            return history.push("/login");
        }

        if (!checkTermsAndPrivacy("terms")) {
            termsRef.current.openModal();
        }

        if (!checkTermsAndPrivacy("privacy")) {
            privacyRef.current.openModal();
        }

        getDoctors();
        getHealthPlans();
    }, [])

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true&offset=1&limit=100")
            .then(response => {
                if (response.status === 200) {
                    const doctorsReturned = response.data.doctors;
                    setDoctorsFullList(doctorsReturned);
                }
            })
    }

    async function getHealthPlans() {
        await api.get("/health-plans?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const healthPlansReturned = response.data.healthPlans.map(healthPlanItem => {
                        return {
                            value: healthPlanItem._id,
                            label: healthPlanItem.short_name ?? healthPlanItem.name
                        };
                    });

                    setHealthPlans(healthPlansReturned);
                }
            })
    }

    function handleGenerate() {
        getCommissions();
    }

    async function getCommissions() {
        setLoading(true);

        let filter = "";
        if (filterHealthPlans.length > 0) {
            filter += "healthPlans="
            for (let indexHealthPlan = 0; indexHealthPlan < filterHealthPlans.length; indexHealthPlan++) {
                if (indexHealthPlan > 0) {
                    filter += ","
                }
                filter += filterHealthPlans[indexHealthPlan].value;
            }
        }

        const filterDateToUTC = dayjs(filterDateTo).add(1, "day");
        await api.get(`/receipts/commissions?startDate=${dayjs(filterDateFrom).toISOString()}&endDate=${dayjs(filterDateToUTC).toISOString()}&${filter}`)
            .then(response => {
                if (response.status === 200) {
                    const dataReturned = response.data;

                    setCommissionsGeneralList([...dataReturned]);

                    let commissionsReturned = [];

                    for (let indexData = 0; indexData < dataReturned.length; indexData++) {
                        const commissionList = dataReturned[indexData].commission;
                        for (let indexCommission = 0; indexCommission < commissionList.length; indexCommission++) {
                            const indexDoctor = commissionsReturned.findIndex(x => x.doctor_id === commissionList[indexCommission].doctor_id);

                            if (indexDoctor < 0) {
                                commissionsReturned.push({
                                    doctor_id: commissionList[indexCommission].doctor_id,
                                    doctor_name: commissionList[indexCommission].doctor_name,
                                    procedure_value: commissionList[indexCommission].procedure_value,
                                    forward_value: commissionList[indexCommission].forward_value,
                                    discount_value: commissionList[indexCommission].discount_value
                                });
                            } else {
                                commissionsReturned[indexDoctor].procedure_value += commissionList[indexCommission].procedure_value;
                                commissionsReturned[indexDoctor].forward_value += commissionList[indexCommission].forward_value;
                                commissionsReturned[indexDoctor].discount_value += commissionList[indexCommission].discount_value;
                            }
                        }
                    }

                    setCommissionsList([...commissionsReturned]);

                    if (response.data.length === 0) {
                        swal("Não foram encontrados registros no período indicado!", { icon: "error", });
                        setHidePrint(true);
                    } else {
                        swal("Cálculo do rateio realizado com sucesso!", { icon: "success", });
                        setHidePrint(false);
                    }
                }
            })

        setLoading(false);
    }

    function handleSheet() {
        let header = ["Convênio", "Valor Bruto", "Valor Líquido"];
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            header.push(`20% ${doctorsFullList[indexDoctor].short_name}`);
        }
        header.push("250,00 Encaminhamento");
        header.push("Valor a dividir");
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            header.push(`% ${doctorsFullList[indexDoctor].short_name}`);
        }
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            header.push(`A receber ${doctorsFullList[indexDoctor].short_name}`);
        }

        let doctorsTotals = [];

        let body = [];
        for (let indexList = 0; indexList < commissionsGeneralList.length; indexList++) {
            let bodyContent = [];

            let healthPlanName = commissionsGeneralList[indexList].health_plan_name;

            if (commissionsGeneralList[indexList].complexity) {
                healthPlanName += (commissionsGeneralList[indexList].complexity === "NOR" ? " convencional" : " complexa");
            }

            if (commissionsGeneralList[indexList].health_plan_accomodation) {
                healthPlanName += (commissionsGeneralList[indexList].health_plan_accomodation === "ENF" ? " enfermaria" : " apartamento");
            }

            bodyContent.push(healthPlanName);
            bodyContent.push(commissionsGeneralList[indexList].total_value.toLocaleString("pt-br"));
            bodyContent.push((commissionsGeneralList[indexList].total_value - commissionsGeneralList[indexList].tax_value).toLocaleString("pt-br"));

            let totalForwarded = 0;
            for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
                let forwardedValue = 0;
                let discountValue = 0;
                const indexCommision = commissionsGeneralList[indexList].commission.findIndex(x => x.doctor_id === doctorsFullList[indexDoctor]._id);
                if (indexCommision >= 0) {
                    forwardedValue = commissionsGeneralList[indexList].commission[indexCommision].forward_value;
                    discountValue = commissionsGeneralList[indexList].commission[indexCommision].discount_value;
                }
                bodyContent.push(forwardedValue.toLocaleString("pt-br"));

                totalForwarded += forwardedValue;

                const indexTotal = doctorsTotals.findIndex(x => x.id === doctorsFullList[indexDoctor]._id);
                if (indexTotal < 0) {
                    doctorsTotals.push({
                        id: doctorsFullList[indexDoctor]._id,
                        forwarded: forwardedValue,
                        commission: 0,
                        discount: discountValue
                    });
                } else {
                    doctorsTotals[indexTotal].forwarded += forwardedValue;
                    doctorsTotals[indexTotal].discount += discountValue;
                }
            }

            bodyContent.push(commissionsGeneralList[indexList].other_forwards);
            bodyContent.push((commissionsGeneralList[indexList].total_value - commissionsGeneralList[indexList].tax_value - totalForwarded).toLocaleString("pt-br"));

            for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
                let doctorCommision = 0;
                const indexCommision = commissionsGeneralList[indexList].commission.findIndex(x => x.doctor_id === doctorsFullList[indexDoctor]._id);
                if (indexCommision >= 0) {
                    doctorCommision = commissionsGeneralList[indexList].commission[indexCommision].procedure_value;
                }
                bodyContent.push(doctorCommision.toLocaleString("pt-br"));

                const indexTotal = doctorsTotals.findIndex(x => x.id === doctorsFullList[indexDoctor]._id);
                if (indexTotal < 0) {
                    doctorsTotals.push({
                        id: doctorsFullList[indexDoctor]._id,
                        forwarded: 0,
                        commission: doctorCommision,
                        discount: 0
                    });
                } else {
                    doctorsTotals[indexTotal].commission += doctorCommision;
                }
            }

            for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
                let doctorCommision = 0;
                const indexCommision = commissionsGeneralList[indexList].commission.findIndex(x => x.doctor_id === doctorsFullList[indexDoctor]._id);
                if (indexCommision >= 0) {
                    doctorCommision = commissionsGeneralList[indexList].commission[indexCommision].procedure_value + commissionsGeneralList[indexList].commission[indexCommision].forward_value;
                }
                bodyContent.push(doctorCommision.toLocaleString("pt-br"));
            }

            body.push(bodyContent);
        }

        let bodyContent = [];
        bodyContent.push("TOTAL");

        let totalValue = 0;
        let totalTax = 0;
        let totalOtherForward = 0;

        for (let indexCommision = 0; indexCommision < commissionsGeneralList.length; indexCommision++) {
            totalValue += commissionsGeneralList[indexCommision].total_value;
            totalTax += commissionsGeneralList[indexCommision].tax_value;
            totalOtherForward += commissionsGeneralList[indexCommision].other_forwards;
        }

        bodyContent.push(totalValue.toLocaleString("pt-br"));
        bodyContent.push((totalValue - totalTax).toLocaleString("pt-br"));

        let totalForwarded = 0;
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            let forwardedValue = 0;
            const indexTotals = doctorsTotals.findIndex(x => x.id === doctorsFullList[indexDoctor]._id);
            if (indexTotals >= 0) {
                forwardedValue += doctorsTotals[indexTotals].forwarded;
            }
            bodyContent.push(forwardedValue.toLocaleString("pt-br"));

            totalForwarded += forwardedValue;
        }

        bodyContent.push(totalOtherForward.toLocaleString("pt-br"));
        bodyContent.push((totalValue - totalTax - totalOtherForward - totalForwarded).toLocaleString("pt-br"));

        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            let doctorCommision = 0;
            const indexTotals = doctorsTotals.findIndex(x => x.id === doctorsFullList[indexDoctor]._id);
            if (indexTotals >= 0) {
                doctorCommision += doctorsTotals[indexTotals].commission;
            }
            bodyContent.push(doctorCommision.toLocaleString("pt-br"));
        }

        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            let doctorCommision = 0;
            const indexTotals = doctorsTotals.findIndex(x => x.id === doctorsFullList[indexDoctor]._id);
            if (indexTotals >= 0) {
                doctorCommision += doctorsTotals[indexTotals].commission + doctorsTotals[indexTotals].forwarded;
            }
            bodyContent.push(doctorCommision.toLocaleString("pt-br"));
        }

        body.push(bodyContent);

        bodyContent = ["", "", ""];
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            bodyContent.push("");
        }
        bodyContent.push("");
        bodyContent.push("");
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length - 1; indexDoctor++) {
            bodyContent.push("");
        }
        bodyContent.push("Valor Bruto já recebido");

        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            let doctorDiscount = 0;
            const indexTotals = doctorsTotals.findIndex(x => x.id === doctorsFullList[indexDoctor]._id);
            if (indexTotals >= 0) {
                doctorDiscount += doctorsTotals[indexTotals].discount;
            }
            bodyContent.push(doctorDiscount.toLocaleString("pt-br"));
        }
        body.push(bodyContent);

        bodyContent = ["", "", ""];
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            bodyContent.push("");
        }
        bodyContent.push("");
        bodyContent.push("");
        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length - 1; indexDoctor++) {
            bodyContent.push("");
        }
        bodyContent.push("TOTAL A RECEBER");

        for (let indexDoctor = 0; indexDoctor < doctorsFullList.length; indexDoctor++) {
            let doctorCommision = 0;
            const indexTotals = doctorsTotals.findIndex(x => x.id === doctorsFullList[indexDoctor]._id);
            if (indexTotals >= 0) {
                doctorCommision += doctorsTotals[indexTotals].commission + doctorsTotals[indexTotals].forwarded - doctorsTotals[indexTotals].discount;
            }
            bodyContent.push(doctorCommision.toLocaleString("pt-br"));
        }
        body.push(bodyContent);

        downloadExcel({
            fileName: "comissoes",
            sheet: "Rateio",
            tablePayload: {
                header,
                body
            }
        });
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Receipt" motherMenu="Operational" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Rateio</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Inicial</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateFrom}
                                                onChange={(date) => setFilterDateFrom(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Final</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateTo}
                                                onChange={(date) => setFilterDateTo(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mt-4 mb-3 col-md-6" style={{ textAlign: "right" }}>
                                            <div className="form-group mb-12 col-md-12">
                                                <Button
                                                    variant="outline-primary btn-rounded"
                                                    onClick={handleGenerate}
                                                >
                                                    Gerar Rateio
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <label>Convênios</label>
                                            <Select
                                                value={filterHealthPlans}
                                                closeMenuOnSelect={false}
                                                defaultValue={filterHealthPlans}
                                                isMulti
                                                options={healthPlans}
                                                placeholder="Selecione..."
                                                onChange={setFilterHealthPlans}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <h4>{filterDateFrom ? `Rateio dos procedimentos recebidos no período entre ${dayjs(filterDateFrom).format("DD/MM/YYYY")} e ${dayjs(filterDateTo).format("DD/MM/YYYY")}` : ""}</h4>

                                <Button
                                    className="ms-2"
                                    variant="info"
                                    onClick={handleSheet}
                                    hidden={hidePrint}
                                >
                                    Gerar Planilha
                                </Button>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Médico</strong>
                                            </th>
                                            <th className="text-center">
                                                <strong>Procedimentos (+)</strong>
                                            </th>
                                            <th className="text-center">
                                                <strong>Encaminhamentos (+)</strong>
                                            </th>
                                            <th className="text-center">
                                                <strong>Recebidos (-)</strong>
                                            </th>
                                            <th className="text-center">
                                                <strong>Total a Receber (=)</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            commissionsList.map((commissionItem) => {
                                                return (
                                                    <tr key={commissionItem.doctor_id}>
                                                        <td>
                                                            <td>{commissionItem.doctor_name}</td>
                                                        </td>
                                                        <td>
                                                            <div className="text-end">
                                                                {commissionItem.procedure_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-end">
                                                                {commissionItem.forward_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-end">
                                                                {commissionItem.discount_value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="text-end">
                                                                {(commissionItem.procedure_value + commissionItem.forward_value - commissionItem.discount_value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <Terms ref={termsRef} />

            <Privacy ref={privacyRef} />

            <ModalLoading
                visible={loading}
                message="Calculando rateio..."
                onClose={setLoading}
            />
        </>
    );
};

export default Commissions;