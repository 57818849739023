import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";

import DFormText from "../../components/DFormText/DFormText";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import api from "../../../services/api";

const PreScheduleCancelModal = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [loadingPreScheduleCancelModal, setLoadingPreScheduleCancelModal] = useState(false);

    const [cancelReason, setCancelReason] = useState(null);
    const [cancelReasonsList, setCancelReasonsList] = useState([]);
    const [cancelMessage, setCancelMessage] = useState("");

    const [invalidCancelReason, setInvalidCancelReason] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal() {
            setModalBox(true);

            resetFields();

            getCancelReasons();
        },
    }));

    async function getCancelReasons() {
        await api.get("/cancel-reasons?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const cancelReasonsReturned = response.data.cancelReasons.map((cancelReasonItem) => {
                        return {
                            value: cancelReasonItem._id,
                            label: `${cancelReasonItem.code} - ${cancelReasonItem.description}`
                        }
                    });
                    setCancelReasonsList(cancelReasonsReturned);
                }
            })
    }

    async function handleConfirmCancelation(e) {
        e.preventDefault();

        setInvalidCancelReason(false);

        if (!cancelReason) {
            setInvalidCancelReason(true);
            return
        }

        onClose(cancelReason.value, cancelMessage);
        setModalBox(false);
    }

    function resetFields() {
        setCancelReason(null);
        setCancelReasonsList([]);
        setCancelMessage("");

        setInvalidCancelReason(false);
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            Cancelar Agendamento
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        />
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Motivo de Cancelamento</label>
                                    <Select
                                        autoFocus
                                        className={`${(invalidCancelReason ? "form-control is-invalid" : "")}`}
                                        defaultValue={cancelReason}
                                        value={cancelReason}
                                        onChange={(e) => setCancelReason(e)}
                                        options={cancelReasonsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidCancelReason} color="danger">Informe o motivo do cancelamento</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Observação</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={cancelMessage}
                                        onChange={e => setCancelMessage(e.target.value)}
                                        maxLength={50}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button
                                variant="outline-danger btn-rounded"
                                onClick={() => setModalBox(false)}
                            >
                                Fechar
                            </Button>
                            <Button
                                variant="success btn-rounded"
                                onClick={handleConfirmCancelation}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <ModalLoading
                visible={loadingPreScheduleCancelModal}
                message="Carregando informações..."
                onClose={setLoadingPreScheduleCancelModal}
            />
        </>
    );
});

export default PreScheduleCancelModal;
