import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Button,
    FormCheck,
    Modal
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import Select from "react-select";
import InputMask from "react-input-mask";

import ModalLoading from '../../components/ModalLoading/ModalLoading';
import DAddressForm from '../../components/DAddressForm/DAddressForm';
import DFormText from "../../components/DFormText/DFormText";

import { cpf } from 'cpf-cnpj-validator';
import api from '../../../services/api';
import dayjs from 'dayjs';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import MaskedInput from "react-maskedinput";
import { createLog } from '../../../services/logService';

registerLocale("ptBR", ptBR);

const DoctorForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingDoctorForm, setLoadingDoctorForm] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Gravando Informações...");

    const [name, setName] = useState("");
    const [shortName, setShortName] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [birthDate, setBirthDate] = useState(undefined);
    const [crm, setCrm] = useState("");
    const [speciality, setSpeciality] = useState("");
    const [entity, setEntity] = useState("");
    const [position, setPosition] = useState("");
    const [lecDoctor, setLecDoctor] = useState(false);
    const [lecForwarder, setLecForwarder] = useState(false);
    const [createDate, setCreateDate] = useState("");
    const [accessCode, setAccessCode] = useState("");
    const [healthPlans, setHealthPlans] = useState([]);
    const [healthPlansList, setHealthPlansList] = useState([]);
    const [addressInfo, setAddressInfo] = useState({});
    const [contactId, setContactId] = useState(undefined);
    const [contactEmail, setContactEmail] = useState("");
    const [contactHomePhone, setContactHomePhone] = useState("");
    const [contactComercialPhone, setContactComercialPhone] = useState("");
    const [contactCellPhone, setContactCellPhone] = useState("");
    const [observation, setObservation] = useState("");
    const [active, setActive] = useState(true);
    const [usersList, setUsersList] = useState([]);
    const [user, setUser] = useState(undefined);

    const [doctorId, setDoctorId] = useState(undefined);

    const [invalidName, setInvalidName] = useState(false);
    const [invalidDocumentId, setInvalidDocumentId] = useState(false);
    const [invalidDocumentIdText, setInvalidDocumentIdText] = useState("Informe um CPF válido");
    const [invalidBirthDate, setInvalidBirthDate] = useState(false);
    const [invalidCrm, setInvalidCrm] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o registro");

    useImperativeHandle(ref, () => ({
        async openModal(mode, doctorInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            resetFields();

            getHealthPlans();
            getUsers();

            if (mode === "update") {
                await loadDoctor(doctorInfo);
            }
        }
    }));

    async function loadDoctor(doctorInfo) {
        setLoadingMessage("Recuperando informações...");
        setLoadingDoctorForm(true);
        setIsUpdate(true);

        setDoctorId(doctorInfo._id);
        setName(doctorInfo.name);
        setShortName(doctorInfo.short_name ?? "");
        setDocumentId(doctorInfo.document_id);
        setBirthDate(dayjs(doctorInfo.birth_date).toDate());
        setCrm(doctorInfo.crm);
        setSpeciality(doctorInfo.speciality);
        setEntity(doctorInfo.entity);
        setPosition(doctorInfo.position);
        setLecDoctor(doctorInfo.lec_doctor);
        setLecForwarder(doctorInfo.lec_forwarder ?? false);
        setCreateDate(dayjs(doctorInfo.createdAt).format("DD/MM/YYYY"));
        setAccessCode(doctorInfo.access_code);
        setActive(doctorInfo.status);

        if (doctorInfo.user_id) {
            const userInfo = await getUserById(doctorInfo.user_id);
            if (userInfo) {
                setUser({
                    value: userInfo._id,
                    label: userInfo.name
                });
            }
        }

        let healthPlansInfo = [];
        if (doctorInfo.health_plans) {
            for (let indexHealthPlan = 0; indexHealthPlan < doctorInfo.health_plans.length; indexHealthPlan++) {
                healthPlansInfo.push({
                    value: doctorInfo.health_plans[indexHealthPlan].health_plan_id,
                    label: doctorInfo.health_plans[indexHealthPlan].health_plan_name
                });
            }
        }
        setHealthPlans(healthPlansInfo);

        if (doctorInfo.addresses && doctorInfo.addresses.length > 0) {
            setAddressInfo((prevInfo) => Object.assign({}, prevInfo, {
                zipCode: doctorInfo.addresses[0].zip_code,
                address: doctorInfo.addresses[0].address,
                addressNumber: doctorInfo.addresses[0].address_number,
                complement: doctorInfo.addresses[0].complement ?? "",
                district: doctorInfo.addresses[0].district ?? "",
                city: doctorInfo.addresses[0].city ?? "",
                addressState: doctorInfo.addresses[0].state
            }));
        }

        if (doctorInfo.contacts && doctorInfo.contacts.length > 0) {
            setContactId(doctorInfo.contacts[0].id);
            setContactEmail(doctorInfo.contacts[0].email);

            for (let indexPhone = 0; indexPhone < doctorInfo.contacts[0].phones.length; indexPhone++) {
                const phoneReturned = doctorInfo.contacts[0].phones[indexPhone].phone;

                if (doctorInfo.contacts[0].phones[indexPhone].type === "home") {
                    setContactHomePhone(phoneReturned);
                }
                if (doctorInfo.contacts[0].phones[indexPhone].type === "comercial") {
                    setContactComercialPhone(phoneReturned);
                }

                if (doctorInfo.contacts[0].phones[indexPhone].type === "cell") {
                    setContactCellPhone(phoneReturned);
                }
            }
        }

        setLoadingDoctorForm(false);
    }

    async function getHealthPlans() {
        await api.get("/health-plans?offset=1&limit=100")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    let healthPlansReturned = [];
                    for (let i = 0; i < response.data.healthPlans.length; i++) {
                        healthPlansReturned.push({
                            value: response.data.healthPlans[i]._id,
                            label: response.data.healthPlans[i].name
                        })
                    }
                    setHealthPlansList(healthPlansReturned)
                }
            })
    }

    async function getUsers() {
        await api.get("/users?status=true")
            .then(response => {
                if (response.status === 200) {
                    const usersReturned = response.data.users.map(userInfo => {
                        return {
                            value: userInfo._id,
                            label: userInfo.name
                        }
                    });
                    setUsersList(usersReturned);
                }
            })
    }

    async function getUserById(id) {
        return new Promise(resolve => {
            api.get(`/users/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function handleSaveDoctor(e) {
        e.preventDefault();

        setLoadingDoctorForm(true);

        setInvalidName(false);
        setInvalidCrm(false);
        setInvalidBirthDate(false);

        let hasError = false;

        if (name === "") {
            setInvalidName(true);
            hasError = true;
        }

        if (crm === "") {
            setInvalidCrm(true);
            hasError = true;
        }

        if (!birthDate) {
            setInvalidBirthDate(true);
            hasError = true;
        } else {
            if (birthDate > dayjs().toDate()) {
                setInvalidBirthDate(true);
                hasError = true;
            }
        }

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

        if (!isUpdate && (invalidDocumentId || documentUnformatted.trim() === "")) {
            setInvalidDocumentId(true);
            hasError = true;
        }

        if (!hasError) {
            let doctor = {
                name,
                short_name: shortName,
                document_id: documentUnformatted,
                birth_date: birthDate,
                crm,
                speciality,
                entity,
                position,
                lec_doctor: lecDoctor,
                lec_forwarder: lecForwarder,
                access_code: accessCode,
                health_plans: [],
                addresses: [],
                contacts: [],
                status: active
            }

            for (let i = 0; i < healthPlans.length; i++) {
                doctor.health_plans.push({ health_plan_id: healthPlans[i].value, health_plan_name: healthPlans[i].label });
            }

            if (user) {
                doctor.user_id = user.value;
            }

            if (addressInfo) {
                if (addressInfo.zipCode?.trim().length === 8) {
                    doctor.addresses.push({
                        zip_code: addressInfo.zipCode.trim(),
                        address: addressInfo.address,
                        address_number: addressInfo.addressNumber,
                        complement: addressInfo.complement ?? "",
                        district: addressInfo.district,
                        city: addressInfo.city,
                        state: addressInfo.addressState
                    });
                }
            }

            const contactHomePhoneUnformatted = contactHomePhone.replace(/[()-]/g, "").replace(" ", "");
            const contactComercialPhoneUnformatted = contactComercialPhone.replace(/[()-]/g, "").replace(" ", "");
            const contactCellPhoneUnformatted = contactCellPhone.replace(/[()-]/g, "").replace(" ", "");

            if (contactEmail !== "" || contactHomePhoneUnformatted.trim() !== "" || contactComercialPhoneUnformatted.trim() !== "" || contactCellPhoneUnformatted.trim() !== "") {
                let newContact = {
                    type: "personal",
                    name: name,
                    email: contactEmail,
                    phones: []
                };

                if (contactHomePhoneUnformatted) {
                    newContact.phones.push({
                        type: "home",
                        phone: contactHomePhoneUnformatted
                    });
                }

                if (contactComercialPhoneUnformatted) {
                    newContact.phones.push({
                        type: "comercial",
                        phone: contactComercialPhoneUnformatted
                    });
                }

                if (contactCellPhoneUnformatted) {
                    newContact.phones.push({
                        type: "cell",
                        phone: contactCellPhoneUnformatted
                    });
                }

                doctor.contacts.push(newContact);
            }

            if (!isUpdate) {
                try {
                    await api.post("/doctors", doctor)
                        .then(response => {
                            if (response.status === 201) {
                                createLog({
                                    routine: "doctor",
                                    action: "create",
                                    type: "success",
                                    message: `Médico ${doctor.name} incluído`,
                                    jsonOrigin: JSON.stringify(doctor),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingDoctorForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data?.error === "ALREADY_EXISTS") {
                        setErrorMessage("Médico já cadastrado");
                    }

                    createLog({
                        routine: "doctor",
                        action: "create",
                        type: "error",
                        message: `Médico ${doctor.name} não incluído`,
                        jsonOrigin: JSON.stringify(doctor),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            } else {
                try {
                    await api.put(`/doctors/${doctorId}`, doctor)
                        .then(response => {
                            if (response.status === 200) {
                                createLog({
                                    routine: "doctor",
                                    action: "update",
                                    type: "success",
                                    message: `Médico ${doctor.name} alterado`,
                                    jsonOrigin: JSON.stringify(doctor),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingDoctorForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "doctor",
                        action: "update",
                        type: "error",
                        message: `Médico ${doctor.name} não alterado`,
                        jsonOrigin: JSON.stringify(doctor),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            }
        }

        setLoadingDoctorForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Médico ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setDoctorId(undefined);
        setName("");
        setShortName("");
        setDocumentId("");
        setBirthDate("");
        setCrm("");
        setSpeciality("");
        setEntity("");
        setPosition("");
        setLecDoctor(false);
        setLecForwarder(false);
        setCreateDate("");
        setAccessCode("");
        setHealthPlans([]);
        setAddressInfo({});
        setContactId(undefined);
        setContactEmail("");
        setContactHomePhone("");
        setContactComercialPhone("");
        setContactCellPhone("");
        setObservation("");
        setActive(true);
        setUsersList([]);
        setUser(undefined);

        setInvalidName(false);
        setInvalidDocumentId(false);
        setInvalidDocumentIdText("Informe um CPF válido");
        setInvalidBirthDate(false);
        setInvalidCrm(false);

        setLoadingMessage("Gravando Informações...");
        setErrorMessage("Ocorreu um problema ao salvar o registro");
    }

    function handleChangeLecDoctor(newLecDoctor) {
        setLecDoctor(newLecDoctor);
        setUser(null);
        setLecForwarder(false);
    }

    async function maskDocumentField() {
        setInvalidDocumentId(false);

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

        if (documentUnformatted.length > 0) {
            if (!cpf.isValid(documentUnformatted)) {
                setInvalidDocumentId(true);
            } else {
                if (!isUpdate) {
                    await api.get(`/doctors/document/${documentUnformatted}`)
                        .then(response => {
                            if (response.status === 200) {
                                setInvalidDocumentId(true);
                                setInvalidDocumentIdText("CPF já cadastrado");
                            }
                        });
                }
            }
        } else {
            setInvalidDocumentId(true);
        }
    }

    function validBirthDate(e) {
        setBirthDate(e);

        setInvalidBirthDate(false);
        if (e > dayjs().toDate()) {
            setInvalidBirthDate(true);
        }
    }

    return (
        <>
            <Modal size='xl' fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">{(!isUpdate ? "Novo " : "Alterar ")}Médico</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-2">
                                    <label>CPF</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        maskChar=" "
                                        value={documentId}
                                        onChange={(event) => {
                                            setDocumentId(event?.target.value);
                                        }}
                                        onBlur={() => { maskDocumentField() }}
                                        alwaysShowMask={true}
                                        disabled={isUpdate}
                                    >
                                        {(inputProps) => <input {...inputProps} autoFocus type="text" className={`form-control ${(invalidDocumentId ? "is-invalid" : "")}`} disabled={isUpdate} />}
                                    </InputMask>
                                    <DFormText hidden={!invalidDocumentId} color="danger">{invalidDocumentIdText}</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-5">
                                    <label>Nome</label>
                                    <input
                                        autoFocus={isUpdate}
                                        type="text"
                                        className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                    <DFormText hidden={!invalidName} color="danger">Informe o nome do médico</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Nome Reduzido</label>
                                    <input
                                        type="text"
                                        maxLength={3}
                                        className="form-control"
                                        value={shortName}
                                        onChange={e => setShortName(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Data de Nascimento</label>
                                    <br />
                                    <DatePicker
                                        selected={birthDate}
                                        onChange={(date) => validBirthDate(date)}
                                        locale="ptBR"
                                        dateFormat="P"
                                        className={`form-control ${invalidBirthDate ? "is-invalid" : ""}`}
                                        customInput={
                                            <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" />
                                        }
                                    />
                                    <br />
                                    <DFormText hidden={!invalidBirthDate} color="danger">Informe uma data válida</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>CRM</label>
                                    <input
                                        type="text"
                                        className={`form-control ${(invalidCrm ? "is-invalid" : "")}`}
                                        value={crm}
                                        onChange={e => setCrm(e.target.value)}
                                        maxLength={12}
                                    />
                                    <DFormText hidden={!invalidCrm} color="danger">Informe o CRM</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Especialidade</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={speciality}
                                        onChange={e => setSpeciality(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-5">
                                    <label>Entidade</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={entity}
                                        onChange={e => setEntity(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Cargo</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={position}
                                        onChange={e => setPosition(e.target.value)}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Médico do LEC</label>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultChecked={lecDoctor}
                                            onChange={() => handleChangeLecDoctor(!lecDoctor)}
                                        />
                                        <label className="form-check-label">Sim</label>
                                    </div>
                                </div>
                                <div className="form-group mb-3 col-md-2">
                                    <label>Encaminhador LEC</label>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            defaultChecked={lecForwarder}
                                            onChange={() => setLecForwarder(!lecForwarder)}
                                            disabled={lecDoctor}
                                        />
                                        <label className="form-check-label">Sim</label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Usuário</label>
                                    <Select
                                        value={user}
                                        defaultValue={user}
                                        options={usersList}
                                        placeholder="Selecione..."
                                        onChange={setUser}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        isDisabled={!lecDoctor}
                                        isClearable
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Data de Cadastro</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={createDate}
                                        disabled
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Código de Acesso</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={accessCode}
                                        disabled
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Convênios</label>
                                    <Select
                                        value={healthPlans}
                                        closeMenuOnSelect={false}
                                        defaultValue={healthPlans}
                                        isMulti
                                        options={healthPlansList}
                                        placeholder="Selecione..."
                                        onChange={setHealthPlans}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                    />
                                </div>
                            </div>

                            <DAddressForm
                                addressInfoParm={addressInfo}
                                onChangeAddress={setAddressInfo}
                            />

                            <h5>Contatos</h5>
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Residencial</label>
                                    <InputMask
                                        mask="(99) 9999-9999"
                                        maskChar=" "
                                        value={contactHomePhone}
                                        onChange={(event) => {
                                            setContactHomePhone(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                    </InputMask>
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Comercial</label>
                                    <InputMask
                                        mask="(99) 9999-9999"
                                        maskChar=" "
                                        value={contactComercialPhone}
                                        onChange={(event) => {
                                            setContactComercialPhone(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                    </InputMask>
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Celular</label>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        maskChar=" "
                                        value={contactCellPhone}
                                        onChange={(event) => {
                                            setContactCellPhone(event?.target.value);
                                        }}
                                        alwaysShowMask={true}
                                    >
                                        {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                    </InputMask>
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Email</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={contactEmail}
                                        onChange={e => setContactEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Observações</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        value={observation}
                                        onChange={e => setObservation(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                        <Button variant="success btn-rounded" onClick={handleSaveDoctor}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingDoctorForm}
                message={loadingMessage}
                onClose={setLoadingDoctorForm}
            />
        </>
    )
})

export default DoctorForm;