import React, { forwardRef, useImperativeHandle, Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Modal,
  Button,
} from "react-bootstrap";

import PageTitle from "../../layouts/PageTitle";
import DPagination from "../../components/DPagination/DPagination";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import ComplicationsForm from "../Complications/ComplicationsForm";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";

import dayjs from "dayjs";

import api from "../../../services/api";
import { isAuthenticated, hasAccess } from "../../../services/utils";

const ComplicationsList = forwardRef((props, ref) => {
  const complicationsFormRef = useRef();
  const [modalBox, setModalBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [complications, setComplications] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pacientId, setPacientId] = useState(1);

  const history = useHistory();

  useImperativeHandle(ref, () => ({
    async openModal(pacientId = {}) {
      setPacientId(pacientId)
      getComplications(currentPage, pacientId);
    },
  }));

  useEffect(() => {
    if (!isAuthenticated()) {
      return history.push("/login");
    }
  }, [])

  async function getComplications(page = currentPage, pacientId) {
    setLoading(true);

    await api.get(`/complications?offset=${page}&limit=10&pacientId=${pacientId}`)
      .then(response => {
        if (response.status === 200) {
          setComplications(response.data.complications);

          const pages = Math.ceil(response.data.total / 10);
          const maxPages = Math.max(pages, 1);

          if (maxPages !== totalPages) {
            setTotalPages(maxPages);
          }
        }
      })

    setLoading(false);
    setModalBox(true);
  }

  function setActivePage(page) {
    setCurrentPage(page);
    getComplications(page, pacientId);
  }

  function handleDeleteForm(complicationId) {

    swal({
      title: "Deseja excluir o formulário?",
      //text: "Após deletado não há ",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        try {
          api.delete(`/complications/${complicationId}`)
          .then(response => {
            if (response.status === 200) {
              notifySuccess()
              getComplications(currentPage, pacientId)
          }
          })
        } catch (err) {
          notifyError()
        }  
      } else {
        return
      }
    })
  }

  function notifySuccess() {
    toast.success(`✔️ Formulário excluído com sucesso!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function notifyError() {
    toast.error(`❌ Ocorreu um problema ao excluir o formulário`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
    <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
        <Fragment>
          <PageTitle activeMenu="complicationsList" motherMenu="Records" />
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Header>
                  <Card.Title>Complicações</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Data do procedimento</strong>
                        </th>
                        <th>
                          <strong>Procedimento</strong>
                        </th>
                        <th>
                          <strong>Médico</strong>
                        </th>
                        <th>
                          <strong>Hospital</strong>
                        </th>
                        <th>
                          <strong>Tipo da complicação</strong>
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        complications.map((complication) => {
                          return (
                            <tr key={complication._id}>
                              <td>{dayjs(complication.procedure_date).format("DD/MM/YYYY")}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="w-space-no">{complication.procedure_name}</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="w-space-no">{complication.doctor_name}</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="w-space-no">{complication.hospital_name}</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="w-space-no">{complication.type_name}</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn btn-primary shadow sharp ms-1"
                                    onClick={() => complicationsFormRef.current.openModal(complication._id, "edit")}
                                    title="Editar complicação"
                                  >
                                    <i className="fas fa-pencil-alt"></i>
                                  </button>
                                  <button
                                    className="btn btn-primary shadow sharp ms-1"
                                    onClick={() => handleDeleteForm(complication._id)}
                                    title="Excluir Complicação"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>

                  <DPagination
                    activePage={currentPage}
                    pages={totalPages}
                    onActivePageChange={(i) => setActivePage(i)}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Fragment>
        <div className="modal-footer">
          <Button
              variant="outline-danger btn-rounded"
              onClick={() => setModalBox(false)}
          >
              Fechar
          </Button>
          </div>
        <ModalLoading
          visible={loading}
          message="Carregando Pacientes..."
          onClose={setLoading}
        />
    </Modal>

    <ComplicationsForm ref={complicationsFormRef} /*onClose={}*/ />

    </>
  );
});

export default ComplicationsList;