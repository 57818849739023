import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Card,
    Col,
    Modal,
    Row,
    Table
} from "react-bootstrap";
import InputMask from "react-input-mask";
import dayjs from "dayjs";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import PageTitle from "../../layouts/PageTitle";
import DPagination from "../../components/DPagination/DPagination";
import api from "../../../services/api";

const PacientSelectForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [loading, setLoading] = useState(false);

    const [pacients, setPacients] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterQs, setFilterQs] = useState("");
    const [filterDocumentId, setFilterDocumentId] = useState("");

    useImperativeHandle(ref, () => ({
        async openModal() {
            setModalBox(true);

            resetFields();
        },
    }));

    function resetFields() {
        setPacients([]);
        setTotalPages(1);
        setCurrentPage(1);

        setFilterQs("");
        setFilterDocumentId("");
    }

    async function getPacients(page = currentPage) {
        setLoading(true);

        let filter = "&";
        if (filterQs !== "") {
            filter += `qs=${filterQs}&`;
        }

        await api.get(`/pacients?offset=${page}&limit=10${filter}`)
            .then(response => {
                if (response.status === 200) {
                    setPacients(response.data.pacients);

                    const pages = Math.ceil(response.data.total / 10);
                    const maxPages = Math.max(pages, 1);

                    if (maxPages !== totalPages) {
                        setTotalPages(maxPages);
                    }
                }
            })

        setLoading(false);
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getPacients(page);
    }

    function onCloseModal(pacientInfo) {
        onClose(pacientInfo);
        setModalBox(false);
    }

    function handleFilter() {
        setCurrentPage(1);
        getPacients(1);
    }

    return (
        <>
            <Modal size="lg" onHide={setModalBox} show={modalBox}>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Selecione o Paciente</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <label>Informe o CPF ou uma parte do nome</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={filterQs}
                                                onChange={e => setFilterQs(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <button className="btn btn-primary ms-1" onClick={handleFilter}>
                                                Filtrar
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Situação</strong>
                                            </th>
                                            <th>
                                                <strong>Nome</strong>
                                            </th>
                                            <th>
                                                <strong>Data de Nascimento</strong>
                                            </th>
                                            <th>
                                                <strong>Convênio</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pacients.map((pacient) => {
                                                return (
                                                    <tr
                                                        key={pacient._id}
                                                        onClick={() => onCloseModal(pacient)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={(pacient.status ? "fa fa-circle text-success me-1" : "fa fa-circle text-danger me-1")}></i>{" "}
                                                                {(pacient.status ? "Ativo" : "Inativo")}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{pacient.name}</span>
                                                            </div>
                                                        </td>
                                                        <td>{dayjs(pacient.birth_date).format("DD/MM/YYYY")}</td>
                                                        <td>{pacient.health_plan_name}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Modal>

            <ModalLoading
                visible={loading}
                message="Recuperando informações..."
                onClose={setLoading}
            />
        </>
    );
});

export default PacientSelectForm;
