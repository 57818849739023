import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Modal
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { logoutApp } from "../../../services/utils";

import api from "../../../services/api";

const Terms = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [loadingTerms, setLoadingTerms] = useState(false);
    const userInfo = (localStorage.getItem("xLcxUsr") ? JSON.parse(localStorage.getItem("xLcxUsr")) : (sessionStorage.getItem("xLcxUsr") ? JSON.parse(sessionStorage.getItem("xLcxUsr")) : null));
    const history = useHistory();

    useImperativeHandle(ref, () => ({
        async openModal() {
            setModalBox(true);
        },
    }));

    async function handleAcceptTerms() {
        if (userInfo) {
            setLoadingTerms(true);

            const acceptTerm = {
                terms: true,
                terms_date: dayjs().toDate()
            }

            try {
                await api.put(`/users/${userInfo.user_id}`, acceptTerm)
                    .then(response => {
                        if (response.status === 200) {
                            notifySuccess();

                            let newUserInfo = { ...userInfo };
                            newUserInfo.terms = true;
                            if (localStorage.getItem("xLcxRem")) {
                                localStorage.setItem("xLcxUsr", JSON.stringify(newUserInfo));
                            } else {
                                sessionStorage.setItem("xLcxUsr", JSON.stringify(newUserInfo));
                            }

                            setLoadingTerms(false);
                            setModalBox(false);
                        } else {
                            notifyError();
                        }
                    })
                } catch(err) {
                    notifyError();
                }

            setLoadingTerms(false);
        }
    }

    function handleCancelTerms() {
        logoutApp();
        history.push("/login");
    }

    function notifySuccess() {
        toast.success(`✔️ Aceite registrado com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao registrar o aceite!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            Termos de Uso do Cardiosis
                        </h3>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div>
                                <p classname="font-weight-thin text-4 mb-0" align="right">Última Atualização: 01 de Janeiro de 2024</p><br /><br />
                                <p classname="font-weight-thin text-4 mb-0">Bem-vindo(a)! Obrigado por utilizar o Cardiosis!</p><br /><br />
                                <p classname="font-weight-thin text-4 mb-0">Esta aplicação e seu conteúdo <strong>("Cardiosis")</strong> são controlados e operados pela Dettalles Consultoria. <strong>Todos os direitos reservados.</strong></p><br /><br />
                                <p classname="mb-4" align="justify">Estes termos de uso têm por objeto definir as regras a serem seguidas para a utilização do Cardiosis <strong>("Termos de Uso")</strong>, sem prejuízo da aplicação da legislação vigente.</p>
                                <p classname="mb-4" align="justify"><strong>AO UTILIZAR O CARDIOSIS, VOCÊ AUTOMATICAMENTE CONCORDA COM ESTES TERMOS DE USO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS PRATICADOS POR VOCÊ NO CARDIOSIS OU EM SERVIÇOS A ELE RELACIONADOS. CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ NÃO DEVE UTILIZAR O CARDIOSIS.</strong></p>
                                <p classname="mb-4" align="justify">Destaca-se que, a partir do aceite destes termos, qualquer acordo prévio pactuado entre as partes, seja verbal, seja escrito, perderá sua vigência.</p>
                                <p classname="mb-4" align="justify"><strong>1.	O QUE É A DETTALLES CONSULTORIA?</strong></p>
                                <p classname="mb-4" align="justify"><strong>1.1.	Serviços.</strong> A Dettalles Consultoria presta serviços relacionados a software, incluindo configuração, manutenção e integração de dados entre sistemas. O CARDIOSIS, disponível na Internet como serviço (SaaS), é composto por aplicativos que permitem o controle de cirurgias e geração de laudos pós-cirúrgicos dos pacientes;</p>
                                <p classname="mb-4" align="justify"><strong>1.2.	Consultoria.</strong> O objetivo desta área é disponibilizar o apoio da equipe Dettalles para que, em conjunto com a você e sua empresa, possamos acompanhar cenários envolvendo as atividades relativas aos processos de elaboração de laudos pós-cirúrgicos, controle de recebimentos de valores de planos de saúde, cálculos operacionais para rateios de valores de apoio ao administrativo, erros de forma geral. Cabe ressaltar que esta área é de apoio, não sendo possível que ela seja responsável por todos os processos envolvendo a plataforma, também não cabe a consultoria qualquer ação nos cenários de questões e regras específicas, questões financeiras, jurídicas ou fiscais.</p>
                                <p classname="mb-4" align="justify"><strong>1.3.	Desenvolvimento.</strong> A Dettalles possui uma equipe especializada em desenvolvimento de sistemas e integrações. Sempre que necessário sua empresa poderá nos acionar via e-mail <strong><a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a></strong> e solicitar o levantamento de melhorias ou novas integrações.</p>
                                <p classname="mb-4" align="justify"><strong>1.4.	Suspensão.</strong> Nós nos reservamos o direito de suspender ou cancelar, a qualquer momento, o seu acesso à aplicação em caso de suspeita de fraude, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas nestes Termos de Uso, na Política de Privacidade ou na legislação aplicável. Nesses casos, não será devida qualquer indenização a você, e a Dettalles Consultoria poderá promover a competente ação de regresso, se necessário, bem como tomar quaisquer outras medidas necessárias para perseguir e resguardar seus interesses.</p>
                                <p classname="mb-4" align="justify"><strong>2.	COMO ACESSO O CARDIOSIS?</strong></p>
                                <p classname="mb-4" align="justify"><strong>2.1.	Acesso.</strong> Para acessar o Cardiosis e utilizar suas funcionalidades é necessário solicitar ao administrador da plataforma que irá efetuar um cadastro e disponibilizar um usuário e uma senha inicial para acesso. Para cadastrar-se, você nos fornecerá informações pessoais, conforme descrito em nossa Política de Privacidade. Para saber mais sobre a privacidade de suas informações pessoais no Cardiosis, acesse nossa Política de Privacidade.</p>
                                <p classname="mb-4" align="justify"><strong>2.2.	Idade de Utilização.</strong> Para utilizar o Cardiosis, você deve ter pelo menos 18 (dezoito) anos.</p>
                                <p classname="mb-4" align="justify"><strong>2.3.	Titularidade.</strong> A partir do cadastro, você será titular de uma conta que somente poderá ser acessada por você. Caso a Dettalles Consultoria detecte alguma conta feita a partir de informações falsas, por usuários que, por exemplo, não possuem a idade mínima permitida, essa conta será automaticamente deletada.</p>
                                <p classname="mb-4" align="justify"><strong>2.4.	Atualização das Informações.</strong> Desde já, você se compromete a manter as suas informações pessoais atualizadas. Você também concorda que irá manter o seu login e senha seguros e fora do alcance de terceiros, e não permitirá que a sua conta no Cardiosis seja usada por outras pessoas. Dessa forma, o usuário responsabiliza-se por todas as ações realizadas em sua conta.</p>
                                <p classname="mb-4" align="justify"><strong>3.	A RELAÇÃO CONTRATUAL ENTRE A DETTALLES CONSULTORIA E O USUÁRIO</strong></p>
                                <p classname="mb-4" align="justify"><strong>3.1.	Relação Contratual.</strong> Os serviços e o conteúdo oferecidos pela plataforma são propriedade da Dettalles Consultoria. Ao estabelecer o contrato que permite ao usuário o gozo das funcionalidades do sistema, a Dettalles Consultoria está oferecendo uma licença de uso, que é não-exclusiva, limitada, revogável e de uso pessoal. É da liberalidade do usuário subscrever a qualquer plano oferecido pela Dettalles Consultoria, sujeito às regras descritas nesses Termos de Uso.</p>
                                <p classname="mb-4" align="justify"><strong>4.	QUAIS SÃO OS DIREITOS DA DETTALLES CONSULTORIA SOBRE A APLICAÇÃO?</strong></p>
                                <p classname="mb-4" align="justify"><strong>4.1.	Nossos Direitos.</strong> Todos os direitos relativos ao Cardiosis e suas funcionalidades são de propriedade exclusiva da Dettalles Consultoria, inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias e demais conteúdos produzidos direta ou indiretamente pela Dettalles Consultoria <strong>(“Conteúdo da Dettalles”)</strong>. O Conteúdo da Dettalles é protegido pela lei de direitos autorais e de propriedade intelectual. É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo da Dettalles, para qualquer finalidade, sem o consentimento prévio e expresso da Dettalles Consultoria. Qualquer uso não autorizado do Conteúdo da Dettalles será considerado como violação dos direitos autorais e de propriedade intelectual da Dettalles Consultoria.</p>
                                <p classname="mb-4" align="justify"><strong>5.	PROPRIEDADE INTELECTUAL SOBRE O SOFTWARE E OS MATERIAIS DISPONIBILIZADOS</strong></p>
                                <p classname="mb-4" align="justify"><strong>5.1.	Propriedade Intelectual.</strong> Para nós da Dettalles Consultoria, a qualidade dos materiais disponibilizados ao usuário é de suma importância. A criação deles é fruto de muito trabalho e dedicação de nossos desenvolvedores. Por isso, reafirmamos que a Dettalles Consultoria garante que todos os direitos, título e interesse (incluindo, mas não apenas, os direitos autorais, marcários e outros de propriedade intelectual) sobre o serviço disponibilizado por nós permanecerão sob a titularidade da Dettalles Consultoria.</p>
                                <p classname="mb-4" align="justify"><strong>5.2.	Não-aquisição de Direitos.</strong> O usuário não adquirirá nenhum direito de propriedade sobre os serviços e conteúdos do Cardiosis, exceto quando haja outorga expressa neste Termos de Uso.</p>
                                <p classname="mb-4" align="justify"><strong>5.3.	Download de Conteúdo. É proibido que o usuário faça o download de nosso conteúdo com o intuito de armazená-lo em banco de dados para oferecer para terceiro que não seja o próprio usuário. Veda-se, também, que o conteúdo disponibilizado por nós seja usado para criar uma base de dados ou um serviço que possa concorrer de qualquer maneira com o nosso negócio.</strong></p>
                                <p classname="mb-4" align="justify"><strong>6.	RECLAMAÇÕES SOBRE VIOLAÇÃO DE DIREITO AUTORAL</strong></p>
                                <p classname="mb-4" align="justify"><strong>6.1.	Infringência de Direitos.</strong> Alegações de infringência de direito autoral de qualquer conteúdo disponível no Cardiosis devem ser encaminhadas por meio do e-mail <strong><a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a></strong>.</p>
                                <p classname="mb-4" align="justify"><strong>7.	RESPONSABILIDADES DO USUÁRIO E DA DETTALLES CONSULTORIA</strong></p>
                                <p classname="mb-4" align="justify"><strong>7.1.	Responsabilidade pelo Uso.</strong> Você é exclusivamente responsável pelo uso do Cardiosis e deverá respeitar as regras destes Termos de Uso, bem como a legislação aplicável ao Cardiosis.</p>
                                <p classname="mb-4" align="justify"><strong>7.2.	Responsabilização por Eventuais Danos.</strong> A Dettalles Consultoria, seu controlador, suas afiliadas, parceiras ou funcionários não serão, em hipótese alguma, responsabilizados por danos diretos ou indiretos que resultem de, ou que tenham relação com o acesso, uso ou a incapacidade de acessar ou utilizar o Cardiosis.</p>
                                <p classname="mb-4" align="justify"><strong>7.3.	Não-Responsabilização. TENDO EM VISTA AS CARACTERÍSTICAS INERENTES AO AMBIENTE DA INTERNET, A DETTALLES CONSULTORIA NÃO SE RESPONSABILIZA POR INTERRUPÇÕES OU SUSPENSÕES DE CONEXÃO, TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU QUE FALHEM, BEM COMO POR FALHA TÉCNICA DE QUALQUER TIPO, INCLUINDO, MAS NÃO SE LIMITANDO, AO MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE, HARDWARE OU SOFTWARE. A INDISPONIBILIDADE DE ACESSO À INTERNET OU AO CARDIOSIS, ASSIM COMO QUALQUER INFORMAÇÃO INCORRETA OU INCOMPLETA SOBRE O CARDIOSIS E QUALQUER FALHA HUMANA, TÉCNICA OU DE QUALQUER OUTRO TIPO NO PROCESSAMENTO DAS INFORMAÇÕES DO CARDIOSIS NÃO SERÃO CONSIDERADAS RESPONSABILIDADE DA DETTALLES CONSULTORIA. A DETTALLES CONSULTORIA SE EXIME DE QUALQUER RESPONSABILIDADE PROVENIENTE DE TAIS FATOS E/OU ATOS.</strong></p>
                                <p classname="mb-4" align="justify"><strong>7.4.	Perda de Lucros.</strong> Quando permitido por lei, a Dettalles Consultoria e os fornecedores ou distribuidores não serão responsáveis por perda de lucros, perda de receita, perda de dados, perdas financeiras ou por danos indiretos, especiais, consequenciais, exemplares ou punitivos.</p>
                                <p classname="mb-4" align="justify"><strong>7.5.	Manutenção.</strong> É de sua inteira responsabilidade manter o ambiente de seu dispositivo (computador, celular, tablet, entre outros) seguro, com o uso de ferramentas disponíveis, como antivírus, firewall, entre outras, de modo a contribuir na prevenção de riscos eletrônicos.</p>
                                <p classname="mb-4" align="justify"><strong>7.6.	Links Externos.</strong> É possível que o Cardiosis possa conter links para sites e aplicativos de terceiros, assim como ter tecnologias integradas. Isso não implica, de maneira alguma, que a Dettalles Consultoria endossa, verifica, garante ou possui qualquer ligação com os proprietários desses sites ou aplicativos, não sendo responsável pelo seu conteúdo, precisão, políticas, práticas ou opiniões. A Dettalles Consltoria recomenda que você leia os termos de uso e políticas de privacidade de cada site de terceiros ou serviço que o usuário vier a visitar ou utilizar.</p>
                                <p classname="mb-4" align="justify"><strong>7.7.	Exclusão de Responsabilidade.</strong> Quando a Dettalles Consultoria facilita a interação de você com prestadores terceiros independentes, como Considerando que o CARDIOSIS utiliza recursos de terceiros para a prestação do serviço e que os quais interagem com os serviços dos Correios e dependem da disponibilidade contínua das APIs, na hipótese dos mantenedores destes sistemas terceiros deixarem de disponibilizar as APIs em condições razoáveis para os serviços, a DETTALLES CONSULTORIA poderá interromper o fornecimento de tais recursos, mediante notificação prévia, sem que exista direito a qualquer reembolso, crédito ou outras compensações desde que devidamente comprovado pela DETTALLES CONSULTORIA. NESSAS SITUAÇÕES, A DETTALLES CONSULTORIA ATUA COMO MERO FACILITADOR DA TRANSAÇÃO ENTRE VOCÊ E TAIS TERCEIROS, DE MODO QUE A DETTALLES CONSULTORIA NÃO É FORNECEDOR DE BENS OU SERVIÇOS, OS QUAIS SÃO PRESTADOS DIRETAMENTE POR TERCEIROS PRESTADORES INDEPENDENTES. A DETTALLES CONSULTORIA NÃO SERÁ, EM HIPÓTESE ALGUMA, RESPONSÁVEL POR TAIS PRODUTOS OU SERVIÇOS DE TERCEIROS FORNECEDORES E REITERAMOS A NECESSIDADE DO USUÁRIO LER, ANALISAR E ACEITAR OS TERMOS DE USO DAS PLATAFORMAS QUE POSSAM TER ALGUMA INFERFACE CONOSCO.</p>
                                <p classname="mb-4" align="justify"><strong>8.	COMO A DETTALLES CONSULTORIA LIDA COM O CONTEÚDO QUE VOCÊ E OUTROS USUÁRIOS PRODUZEM?</strong></p>
                                <p classname="mb-4" align="justify"><strong>8.1.	Criação de Conteúdo.</strong> A Dettalles Consultoria poderá, a seu exclusivo critério, permitir que você ou qualquer outro usuário apresente, carregue, publique ou disponibilize, na aplicação, conteúdo ou informações de texto, imagem, áudio ou vídeo <strong>(“Conteúdo de Usuário”)</strong>.</p>
                                <p classname="mb-4" align="justify"><strong>8.2.	Conteúdos Proibidos.</strong> É proibido qualquer Conteúdo de Usuário de caráter difamatório, calunioso, injurioso, violento, pornográfico, obsceno, ofensivo ou ilícito, conforme apuração da Dettalles Consultoria a seu critério exclusivo, inclusive informações de propriedade exclusiva pertencentes a outras pessoas ou empresas (ex. direito de autor, marcas), sem a expressa autorização do titular desses direitos, cuja violação não será de responsabilidade da Dettalles Consultoria.</p>
                                <p classname="mb-4" align="justify"><strong>8.3.	Titularidade do Conteúdo.</strong> Qualquer Conteúdo de Usuário fornecido por você permanece de sua propriedade. Contudo, ao fornecê-lo para a Dettalles Consultoria, você nos outorga uma licença em nível mundial, por prazo indeterminado, gratuita e transferível, e com direito a sublicenciar, usar, copiar, modificar, criar obras derivadas, distribuir, publicar, exibir esse Conteúdo de Usuário em todos os formatos e canais de distribuição possíveis, sem necessidade de novo aviso a você, e sem necessidade de qualquer pagamento, desde que isso esteja relacionado ao funcionamento da plataforma. <strong>Ademais, Dados da empresa (CNPJ, Razão Social, e-mail, telefone, endereço), dados dos usuários (Nome, e-mail, telefone, senha, endereço) são protegidos por criptografia, o que significa que nós seremos “cegos” com relação a esse Conteúdo.</strong></p>
                                <p classname="mb-4" align="justify"><strong>8.4.	Exclusão do Conteúdo.</strong> A Dettalles Consultoria poderá, mas não se obriga a analisar, monitorar e remover Conteúdo de Usuário, a critério exclusivo da Dettalles Consultoria, a qualquer momento e por qualquer motivo, sem nenhum aviso a você.</p>
                                <p classname="mb-4" align="justify"><strong>9.	LEI GERAL DE PROTEÇÃO DE DADOS</strong></p>
                                <p classname="mb-4" align="justify"><strong>9.1.	</strong> Sendo certo que a plataforma poderá conter dados de pessoas físicas por ela disponibilizados, você deverá se certificar de que os gestores e outros envolvidos no processo que tiverem acesso aos dados e informações dos Usuários observarão as regras de tratamento de dados previstas na legislação aplicável, e não compartilharão os dados com quaisquer terceiros.</p>
                                <p classname="mb-4" align="justify"><strong>9.2.	</strong> Em caso de descumprimento das normas de tratamento de dados por você ou qualquer de seus funcionários, representantes ou prepostos, você deverá reportar tal violação à Dettalles Consultoria, bem como se responsabilizará por todas e quaisquer consequências decorrentes da violação, mantendo a Dettalles Consultoria livre e indene de quaisquer obrigações perante o Usuário nesse sentido.</p>
                                <p classname="mb-4" align="justify"><strong>9.3.	</strong> Adicionalmente ao acima mencionado, em caso de descumprimento você responderá por todas as perdas e danos causados à Dettalles Consultoria em razão do descumprimento, devendo indenizar e ressarcir a Dettalles Consultoria de quaisquer valores relacionados a processos administrativos e/ou judicias que sejam movidos em face da Dettalles Consultoria.</p>
                                <p classname="mb-4" align="justify"><strong>10.	O QUE MAIS EU PRECISO SABER SOBRE ESTES TERMOS DE USO?</strong></p>
                                <p classname="mb-4" align="justify"><strong>10.1.	Alterações.</strong> Para melhorar sua experiência, O Cardiosis está sempre sendo atualizado. Por esse motivo, estes Termos de Uso podem ser alterados, a qualquer tempo, a fim de refletir os ajustes realizados. No entanto, sempre que ocorrer qualquer modificação, você será previamente informando pelo endereço de e-mail fornecido por você no momento do cadastro ou por um aviso em destaque na aplicação. Caso você não concorde com os novos Termos de Uso, você poderá rejeitá-los, mas, infelizmente, isso significa que você não poderá mais ter acesso e fazer uso do Cardiosis. Se de qualquer maneira você utilizar o Cardiosis mesmo após a alteração destes Termos de Uso, isso significa que <strong>você concorda com todas as modificações.</strong></p>
                                <p classname="mb-4" align="justify"><strong>10.2.	Conflito entre Disposições. Em caso de conflito entre estes termos e os termos modificados, os termos posteriores prevalecerão com relação a esse conflito.</strong></p>
                                <p classname="mb-4" align="justify"><strong>10.3.	Lei e Foro.</strong> Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. Quaisquer dúvidas e situações não previstas nestes Termos de Uso serão primeiramente resolvidas pela Dettalles Consultoria e, caso persistam, deverão ser solucionadas pelo Foro da Comarca de Curitiba, Paraná, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
                                <p classname="mb-4" align="justify"><strong>10.4.	Dúvidas.</strong> Caso você tenha alguma dúvida, comentário ou sugestão, por favor, entre em contato conosco por meio do e-mail <strong><a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a></strong>.</p>
                                <p classname="mb-4" align="justify">De acordo com a disposição do artigo 41 da Lei Geral de Proteção de Dados, a Dettalles Consultoria indica Eduardo Deonizio Oliveira dos Santos, Diretor Geral como pessoa encarregado pelo tratamento de dados, sendo possível o contato através do seguinte e-mail: <strong><a href="mailto:eduardo@dettalles.com.br">eduardo@dettalles.com.br</a></strong>.</p>
                                <p classname="mb-4" align="justify">Caso queira nos dar algum feedback sobre o Cardiosis, tenha dúvidas ou precise tratar de qualquer assunto relacionado a estes Termos de Uso, entre em contato conosco através do e-mail <strong><a href="mailto:dettalles@dettalles.com.br">dettalles@dettalles.com.br</a></strong>.</p>
                            </div>

                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={handleCancelTerms}
                        >
                            Não Concordo
                        </Button>
                        <Button variant="success btn-rounded" onClick={handleAcceptTerms}>Eu Concordo</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingTerms}
                message="Confirmando aceite..."
                onClose={setLoadingTerms}
            />
        </>
    );
});

export default Terms;