import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import Select from "react-select";

import DFormText from "../../components/DFormText/DFormText";
import {
    ablationEvTvOptions,
    ablationOptions,
    ablationPlaceOptions,
    ablationTypeOptions,
    actualDaaOptions,
    cdiShockYesNoOptions,
    evMiocNonIsqOptions,
    evTvOptions,
    genericNormalAnormalOptions,
    mainSymptomOptions,
    meaOptions,
    protocolYesNoOptions,
    ventriArrhythmiaEcgOptions,
    ventriArrhythmiaOptions
} from "../../../enum/protocolEnums";

const EvTvForm = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        protocolInfoParm,
        hide,
        ...attributes
    } = props;

    const [mainSymptom, setMainSymptom] = useState(null);
    const [otherMainSymptom, setOtherMainSymptom] = useState("");
    const [functionalClass, setFunctionalClass] = useState("");
    const [ventriArrhythmia, setVentriArrhythmia] = useState(null);
    const [otherVentriArrhythmia, setOtherVentriArrhythmia] = useState("");
    const [ventriArrhythmiaEcg, setVentriArrhythmiaEcg] = useState(null);
    const [startMonth, setStartMonth] = useState("");
    const [ventriArrhythmiaHolter, setVentriArrhythmiaHolter] = useState("");
    const [ae, setAe] = useState("");
    const [ve, setVe] = useState("");
    const [septo, setSepto] = useState("");
    const [ppost, setPpost] = useState("");
    const [fe, setFe] = useState("");
    const [magResso, setMagResso] = useState(null);
    const [angioTomo, setAngioTomo] = useState(null);
    const [cintilo, setCintilo] = useState(null);
    const [cate, setCate] = useState(null);
    const [evtv, setEvtv] = useState(null);
    const [otherEvtv, setOtherEvtv] = useState("");
    const [miocNonIsq, setMiocNonIsq] = useState(null);
    const [otherMiocNonIsq, setOtherMiocNonIsq] = useState("");

    const [has, setHas] = useState(null);
    const [diabMelli, setDiabMelli] = useState(null);
    const [cdi, setCdi] = useState(null);
    const [cdiShock, setCdiShock] = useState(null);
    const [actualDaa, setActualDaa] = useState(null);
    const [otherActualDaa, setOtherActualDaa] = useState("");
    const [betablok, setBetablok] = useState(null);
    const [otherPathology, setOtherPathology] = useState("");
    const [otherMedications, setOtherMedications] = useState("");
    const [observation, setObservation] = useState("");

    const [eefNumber, setEefNumber] = useState("");
    const [mea, setMea] = useState(null);
    const [ecointra, setEcointra] = useState(null);
    const [punctionStart, setPunctionStart] = useState("");
    const [eefStart, setEefStart] = useState("");
    const [inducedTachycardia, setInducedTachycardia] = useState("");
    const [ablationStart, setAblationStart] = useState("");
    const [ablationEnd, setAblationEnd] = useState("");

    const [ablationsList, setAblationsList] = useState([]);
    const [ablation, setAblation] = useState(null);
    const [ablationEvTv, setAblationEvTv] = useState(null);
    const [otherAblationEvTv, setOtherAblationEvTv] = useState("");
    const [ablationType, setAblationType] = useState(null);
    const [otherAblationType, setOtherAblationType] = useState("");
    const [lesionCount, setLesionCount] = useState("");
    const [lesionTime, setLesionTime] = useState("");
    const [ablationPlace, setAblationPlace] = useState(null);
    const [otherAblationPlace, setOtherAblationPlace] = useState("");
    const [success, setSuccess] = useState(null);
    const [ablationObservation, setAblationObservation] = useState("");

    const [invalidAblation, setInvalidAblation] = useState(false);

    useEffect(() => {
        setMainSymptom(protocolInfoParm.mainSymptom ?? null);
        setOtherMainSymptom(protocolInfoParm.otherMainSymptom ?? "");
        setFunctionalClass(protocolInfoParm.functionalClass ?? "");
        setVentriArrhythmia(protocolInfoParm.ventriArrhythmia ?? null);
        setOtherVentriArrhythmia(protocolInfoParm.otherVentriArrhythmia ?? "");
        setVentriArrhythmiaEcg(protocolInfoParm.ventriArrhythmiaEcg ?? null);
        setStartMonth(protocolInfoParm.startMonth ?? null);
        setVentriArrhythmiaHolter(protocolInfoParm.ventriArrhythmiaHolter ?? "");
        setAe(protocolInfoParm.ae ?? "");
        setVe(protocolInfoParm.ve ?? "");
        setSepto(protocolInfoParm.septo ?? "");
        setPpost(protocolInfoParm.ppost ?? "");
        setFe(protocolInfoParm.fe ?? "");
        setMagResso(protocolInfoParm.magResso ?? null);
        setAngioTomo(protocolInfoParm.angioTomo ?? null);
        setCintilo(protocolInfoParm.cintilo ?? null);
        setCate(protocolInfoParm.cate ?? null);
        setEvtv(protocolInfoParm.evtv ?? null);
        setOtherEvtv(protocolInfoParm.otherEvtv ?? "");
        setMiocNonIsq(protocolInfoParm.miocNonIsq ?? null);
        setOtherMiocNonIsq(protocolInfoParm.otherMiocNonIsq ?? null);

        setHas(protocolInfoParm.has ?? null);
        setDiabMelli(protocolInfoParm.diabMelli ?? null);
        setCdi(protocolInfoParm.cdi ?? null);
        setCdiShock(protocolInfoParm.cdiShock ?? null);
        setActualDaa(protocolInfoParm.actualDaa ?? null);
        setOtherActualDaa(protocolInfoParm.otherActualDaa ?? "");
        setBetablok(protocolInfoParm.betablok ?? null);
        setOtherPathology(protocolInfoParm.otherPathology ?? "");
        setOtherMedications(protocolInfoParm.otherMedications ?? "");
        setObservation(protocolInfoParm.observation ?? "");

        setEefNumber(protocolInfoParm.eefNumber ?? null);
        setMea(protocolInfoParm.mea ?? "");
        setEcointra(protocolInfoParm.ecointra ?? "");
        setPunctionStart(protocolInfoParm.punctionStart ?? "");
        setEefStart(protocolInfoParm.eefStart ?? null);
        setInducedTachycardia(protocolInfoParm.inducedTachycardia ?? "");
        setAblationStart(protocolInfoParm.ablationStart ?? "");
        setAblationEnd(protocolInfoParm.ablationEnd ?? "");
        setAblationsList(protocolInfoParm.ablations ?? []);
    }, [protocolInfoParm]);

    function handleAddAblation() {
        if (ablation || ablationEvTv || otherAblationEvTv || ablationType || otherAblationType || lesionCount ||
            lesionTime || ablationPlace || otherAblationPlace || success) {
            let newAblationItem = {
                _id: (Math.floor(Math.random() * 65536)).toString()
            };

            if (ablation) {
                newAblationItem.ablation = ablation;
            }

            if (ablationEvTv) {
                newAblationItem.ablationEvTv = ablationEvTv;
                newAblationItem.ablationEvTvLabel = getLabel("ablationEvTv", ablationEvTv);
            }

            if (otherAblationEvTv) {
                newAblationItem.otherAblationEvTv = otherAblationEvTv;
            }

            if (ablationType) {
                newAblationItem.ablationType = ablationType;
                newAblationItem.ablationTypeLabel = getLabel("ablationType", ablationType);
            }

            if (otherAblationType) {
                newAblationItem.otherAblationType = otherAblationType;
            }

            if (lesionCount) {
                newAblationItem.lesionCount = lesionCount;
            }

            if (lesionTime) {
                newAblationItem.lesionTime = lesionTime;
            }

            if (ablationPlace) {
                newAblationItem.ablationPlace = ablationPlace;
                newAblationItem.ablationPlaceLabel = getLabel("ablationPlace", ablationPlace);
            }

            if (otherAblationPlace) {
                newAblationItem.otherAblationPlace = otherAblationPlace;
            }

            if (success) {
                newAblationItem.success = success;
            }

            if (observation) {
                newAblationItem.observation = observation;
            }

            let newAblationsList = [...ablationsList];
            newAblationsList.push(newAblationItem);
            setAblationsList(newAblationsList);

            onChangeInfo("ablations", newAblationsList);

            resetAblationFields();
        } else {
            setInvalidAblation(true);
        }
    }

    function getLabel(field, value) {
        let label = "";

        if (field === "ablationEvTv") {
            label = (value === 7 ? `7 - ${otherAblationEvTv}` : ablationEvTv.label);
        } else if (field === "ablationType") {
            label = (value === 4 ? `4 - ${otherAblationType}` : ablationType.label);
        } else if (field === "ablationPlace") {
            label = (value === 5 ? `"5 - ${otherAblationPlace}` : ablationPlace.label);
        }

        return label;
    }
    
    function resetAblationFields() {
        setAblation(null);
        setAblationEvTv(null);
        setOtherAblationEvTv("");
        setAblationType(null);
        setOtherAblationType("");
        setLesionCount("");
        setLesionTime("");
        setAblationPlace(null);
        setOtherAblationPlace("");
        setSuccess(null);
        setAblationObservation("");
    }

    function handleDeleteAblation(id) {
        const newAblationsList = ablationsList.filter(x => x._id !== id);
        setAblationsList(newAblationsList);
        onChangeInfo("ablations", newAblationsList);
    }

    return (
        <div hidden={hide}>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>Queixa Principal</label>
                    <Select
                        autoFocus
                        defaultValue={mainSymptom}
                        value={mainSymptom}
                        onChange={(e) => {
                            setMainSymptom(e);
                            onChangeInfo("mainSymptom", e);
                        }}
                        options={mainSymptomOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Queixa Principal (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMainSymptom}
                        onChange={e => {
                            setOtherMainSymptom(e.target.value);
                            onChangeInfo("otherMainSymptom", e.target.value);
                        }}
                        disabled={mainSymptom?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Classe Funcional NYHA (número)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={functionalClass}
                        onChange={e => {
                            setFunctionalClass(e.target.value);
                            onChangeInfo("functionalClass", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Arritmia Ventri</label>
                    <Select
                        defaultValue={ventriArrhythmia}
                        value={ventriArrhythmia}
                        onChange={(e) => {
                            setVentriArrhythmia(e);
                            onChangeInfo("ventriArrhythmia", e);
                        }}
                        options={ventriArrhythmiaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Arritmia Ventri (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherVentriArrhythmia}
                        onChange={e => {
                            setOtherVentriArrhythmia(e.target.value);
                            onChangeInfo("otherVentriArrhythmia", e.target.value);
                        }}
                        disabled={ventriArrhythmia?.value !== 5}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>ECG Arritmia Ventricular</label>
                    <Select
                        defaultValue={ventriArrhythmiaEcg}
                        value={ventriArrhythmiaEcg}
                        onChange={(e) => {
                            setVentriArrhythmiaEcg(e);
                            onChangeInfo("ventriArrhythmiaEcg", e);
                        }}
                        options={ventriArrhythmiaEcgOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>Início (meses)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={startMonth}
                        onChange={e => {
                            setStartMonth(e.target.value);
                            onChangeInfo("startMonth", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Holter Arritm Ventric (%)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ventriArrhythmiaHolter}
                        onChange={e => {
                            setVentriArrhythmiaHolter(e.target.value);
                            onChangeInfo("ventriArrhythmiaHolter", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>AE (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ae}
                        onChange={e => {
                            setAe(e.target.value);
                            onChangeInfo("ae", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>VE (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ve}
                        onChange={e => {
                            setVe(e.target.value);
                            onChangeInfo("ve", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Septo (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={septo}
                        onChange={e => {
                            setSepto(e.target.value);
                            onChangeInfo("septo", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Ppost (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ppost}
                        onChange={e => {
                            setPpost(e.target.value);
                            onChangeInfo("ppost", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>FE (%)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={fe}
                        onChange={e => {
                            setFe(e.target.value);
                            onChangeInfo("fe", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Resso Mag</label>
                    <Select
                        defaultValue={magResso}
                        value={magResso}
                        onChange={(e) => {
                            setMagResso(e);
                            onChangeInfo("magResso", e);
                        }}
                        options={genericNormalAnormalOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Angio Tomo</label>
                    <Select
                        defaultValue={angioTomo}
                        value={angioTomo}
                        onChange={(e) => {
                            setAngioTomo(e);
                            onChangeInfo("angioTomo", e);
                        }}
                        options={genericNormalAnormalOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Cintilo</label>
                    <Select
                        defaultValue={cintilo}
                        value={cintilo}
                        onChange={(e) => {
                            setCintilo(e);
                            onChangeInfo("cintilo", e);
                        }}
                        options={genericNormalAnormalOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>CATE</label>
                    <Select
                        defaultValue={cate}
                        value={cate}
                        onChange={(e) => {
                            setCate(e);
                            onChangeInfo("cate", e);
                        }}
                        options={genericNormalAnormalOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>EV/TV</label>
                    <Select
                        defaultValue={evtv}
                        value={evtv}
                        onChange={(e) => {
                            setEvtv(e);
                            onChangeInfo("evtv", e);
                        }}
                        options={evTvOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>EV/TV (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherEvtv}
                        onChange={e => {
                            setOtherEvtv(e.target.value);
                            onChangeInfo("otherEvtv", e.target.value);
                        }}
                        disabled={evtv?.value !== 5}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Mioc Não Isq</label>
                    <Select
                        defaultValue={miocNonIsq}
                        value={miocNonIsq}
                        onChange={(e) => {
                            setMiocNonIsq(e);
                            onChangeInfo("miocNonIsq", e);
                        }}
                        options={evMiocNonIsqOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Mioc Não Isq (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMiocNonIsq}
                        onChange={e => {
                            setOtherMiocNonIsq(e.target.value);
                            onChangeInfo("otherMiocNonIsq", e.target.value);
                        }}
                        disabled={miocNonIsq?.value !== 9}
                    />
                </div>
            </div>

            <hr />

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>HAS</label>
                    <Select
                        defaultValue={has}
                        value={has}
                        onChange={(e) => {
                            setHas(e);
                            onChangeInfo("has", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Diab Melli</label>
                    <Select
                        defaultValue={diabMelli}
                        value={diabMelli}
                        onChange={(e) => {
                            setDiabMelli(e);
                            onChangeInfo("diabMelli", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>CDI</label>
                    <Select
                        defaultValue={cdi}
                        value={cdi}
                        onChange={(e) => {
                            setCdi(e);
                            onChangeInfo("cdi", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Choque CDI</label>
                    <Select
                        defaultValue={cdiShock}
                        value={cdiShock}
                        onChange={(e) => {
                            setCdiShock(e);
                            onChangeInfo("cdiShock", e);
                        }}
                        options={cdiShockYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>DAA Atual</label>
                    <Select
                        defaultValue={actualDaa}
                        value={actualDaa}
                        onChange={(e) => {
                            setActualDaa(e);
                            onChangeInfo("actualDaa", e);
                        }}
                        options={actualDaaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>DAA Atual (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherActualDaa}
                        onChange={e => {
                            setOtherActualDaa(e.target.value);
                            onChangeInfo("otherActualDaa", e.target.value);
                        }}
                        disabled={actualDaa?.value !== 4}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>Betablok</label>
                    <Select
                        defaultValue={betablok}
                        value={betablok}
                        onChange={(e) => {
                            setBetablok(e);
                            onChangeInfo("betablok", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Outras Patologias</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherPathology}
                        onChange={e => {
                            setOtherPathology(e.target.value);
                            onChangeInfo("otherPathology", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Outros Medicamentos</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMedications}
                        onChange={e => {
                            setOtherMedications(e.target.value);
                            onChangeInfo("otherMedications", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-4">
                    <label>Observações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={observation}
                        onChange={e => {
                            setObservation(e.target.value);
                            onChangeInfo("observation", e.target.value);
                        }}
                    />
                </div>
            </div>

            <hr />

            <div className="row mt-4">
                <h5>Ablações</h5>
            </div>

            <div className="row mt-2">
                <div className="form-group mb-12 col-md-3">
                    <label>Número EEF</label>
                    <input
                        type="text"
                        className="form-control"
                        value={eefNumber}
                        onChange={e => {
                            setEefNumber(e.target.value);
                            onChangeInfo("eefNumber", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>MEA</label>
                    <Select
                        defaultValue={mea}
                        value={mea}
                        onChange={(e) => {
                            setMea(e);
                            onChangeInfo("mea", e);
                        }}
                        options={meaOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Ecointra cardíaco</label>
                    <Select
                        defaultValue={ecointra}
                        value={ecointra}
                        onChange={(e) => {
                            setEcointra(e);
                            onChangeInfo("ecointra", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Início punção (hora)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={punctionStart}
                        onChange={e => {
                            setPunctionStart(e.target.value);
                            onChangeInfo("punctionStart", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Início EEF</label>
                    <input
                        type="text"
                        className="form-control"
                        value={eefStart}
                        onChange={e => {
                            setEefStart(e.target.value);
                            onChangeInfo("eefStart", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Número Taquicard Induzidas</label>
                    <input
                        type="text"
                        className="form-control"
                        value={inducedTachycardia}
                        onChange={e => {
                            setInducedTachycardia(e.target.value);
                            onChangeInfo("inducedTachycardia", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Início Ablação (hora)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ablationStart}
                        onChange={e => {
                            setAblationStart(e.target.value);
                            onChangeInfo("ablationStart", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Fim Ablação (hora)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ablationEnd}
                        onChange={e => {
                            setAblationEnd(e.target.value);
                            onChangeInfo("ablationEnd", e.target.value);
                        }}
                    />
                </div>
            </div>

            <hr />

            <div className="row mt-4">
                <small>Preencha os campos e clique no botão adicionar (+) para inserir as ablações</small>
                <DFormText hidden={!invalidAblation} color="danger">Informe os campos para inclusão da ablação</DFormText>
            </div>

            <div className="row mt-2">
                <div className="form-group mb-12 col-md-2">
                    <label>Ablação</label>
                    <Select
                        defaultValue={ablation}
                        value={ablation}
                        onChange={(e) => {
                            setAblation(e);
                            onChangeInfo("ablation", e);
                        }}
                        options={ablationOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>EV/TV Ablação</label>
                    <Select
                        defaultValue={ablationEvTv}
                        value={ablationEvTv}
                        onChange={(e) => {
                            setAblationEvTv(e);
                            onChangeInfo("ablationEvTv", e);
                        }}
                        options={ablationEvTvOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Tipo Ablação</label>
                    <Select
                        defaultValue={ablationType}
                        value={ablationType}
                        onChange={(e) => {
                            setAblationType(e);
                            onChangeInfo("ablationType", e);
                        }}
                        options={ablationTypeOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Tipo Ablação (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherAblationType}
                        onChange={(e) => {
                            setOtherAblationType(e.target.value);
                            onChangeInfo("otherAblationType", e.target.value);
                        }}
                        disabled={ablationType?.value !== 5}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Número lesões RF</label>
                    <input
                        type="text"
                        className="form-control"
                        value={lesionCount}
                        onChange={(e) => {
                            setLesionCount(e.target.value);
                            onChangeInfo("lesionCount", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Tempo RF lesões (min)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={lesionTime}
                        onChange={(e) => {
                            setLesionTime(e.target.value);
                            onChangeInfo("lesionTime", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>Local Ablação</label>
                    <Select
                        defaultValue={ablationPlace}
                        value={ablationPlace}
                        onChange={(e) => {
                            setAblationPlace(e);
                            onChangeInfo("ablationPlace", e);
                        }}
                        options={ablationPlaceOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Local Ablação (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherAblationPlace}
                        onChange={(e) => {
                            setOtherAblationPlace(e.target.value);
                            onChangeInfo("otherAblationPlace", e.target.value);
                        }}
                        disabled={ablationPlace?.value !== 5}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Sucesso</label>
                    <Select
                        defaultValue={success}
                        value={success}
                        onChange={(e) => {
                            setSuccess(e);
                            onChangeInfo("success", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-6">
                    <label>Observações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ablationObservation}
                        onChange={(e) => {
                            setAblationObservation(e.target.value);
                            onChangeInfo("ablationObservation", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mt-4 mb-12 col-md-1">
                    <button
                        className="btn btn-primary shadow btn-sm me-1"
                        title="Adicionar ablação"
                        onClick={handleAddAblation}
                    >
                        <i className="fas fa-plus"></i>
                    </button>
                </div>
            </div>

            <div className="row mt-4">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Ablação</th>
                            <th>EV/TV Ablação</th>
                            <th>Tipo Ablação</th>
                            <th>Número Lesões</th>
                            <th>Tempo RF Lesões</th>
                            <th>Local</th>
                            <th>Sucesso</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            ablationsList.map((ablationItem) => {
                                return (
                                    <tr key={ablationItem._id}>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="w-space-no">{ablationItem.ablation?.label}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="w-space-no">{ablationItem.ablationEvTvLabel}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="w-space-no">{ablationItem.ablationTypeLabel}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="w-space-no">{ablationItem.lesionCount}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="w-space-no">{ablationItem.lesionTime}</span>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="w-space-no">{ablationItem.ablationPlaceLabel}</span>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="d-flex align-items-center">
                                                <span className="w-space-no">{ablationItem.success?.label}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="d-flex">
                                                <button
                                                    className="btn btn-danger shadow btn-sm me-1"
                                                    onClick={() => handleDeleteAblation(ablationItem._id)}
                                                >
                                                    <i className="fas fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

EvTvForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

EvTvForm.defaultProps = {
    protocolInfoParm: {},
    hide: false
};

export default EvTvForm;