import React, { Fragment, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

//comp
import AvailabilityCalendar from "./AvailabilityCalendar";

import PageTitle from "../../layouts/PageTitle";
import { Button, Card, Col, Row } from "react-bootstrap";
import { isAuthenticated } from "../../../services/utils";

import DoctorAvailabilityForm from "./DoctorAvailabilityForm";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import brLocale from "@fullcalendar/core/locales/pt-br";
import api from '../../../services/api';
import dayjs from 'dayjs';


const DoctorAvailability = () => {
    const calendarRef = useRef(null);
    const scheduleFormRef = useRef();
    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated()) {
            return history.push("/login");
        }
    }, [])

    async function getEvents(info, successCallBack, failureCallback) {
        const { startStr, endStr } = info;

        try {
            await api.get(`/availabilities?startDate=${startStr}&endDate=${endStr}`)
                .then(response => {
                    if (response.status === 200) {
                        const calendarEvents = response.data.availabilities.map(availabilityInfo => {
                            return {
                                id: availabilityInfo._id,
                                title: availabilityInfo.doctor_name,
                                start: dayjs(availabilityInfo.date_from).toDate(),
                                end: dayjs(availabilityInfo.date_to).toDate(),
                                allDay: true,
                                backgroundColor: availabilityInfo.color
                            }
                        })

                        successCallBack(calendarEvents);
                    }
                })
        } catch (err) {
            if (err.status === 401) {
                history.push("/login");
            }
            failureCallback();
        }
    }

    function handleEventClick(clickInfo) {
        // api.delete(`/availabilities/${clickInfo.event.id}`);
        // clickInfo.event.remove()
        scheduleFormRef.current.openModal("update", clickInfo.event.id);
    }

    function onCloseModal() {
        if (calendarRef.current) {
            calendarRef.current.getApi().refetchEvents();
        }
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Doctor" motherMenu="Records" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Indisponibilidade</Card.Title>
                                <div>
                                    <Button
                                        variant="success btn-rounded"
                                        onClick={() => scheduleFormRef.current.openModal()}
                                        title="Incluir"
                                    >
                                        Incluir
                                    </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div id="calendar" className="app-fullcalendar">
                                    <FullCalendar
                                        ref={calendarRef}
                                        defaultView="dayGridMonth"
                                        headerToolbar={{
                                            start: "prev,next today",
                                            center: "title",
                                            end: "dayGridMonth,timeGridWeek,timeGridDay",
                                        }}
                                        rerenderDelay={10}
                                        eventDurationEditable={false}
                                        editable={true}
                                        droppable={true}
                                        plugins={[
                                            dayGridPlugin,
                                            timeGridPlugin,
                                            interactionPlugin,
                                        ]}
                                        events={getEvents}
                                        eventClick={handleEventClick}
                                        eventTimeFormat={{ hour: '2-digit', minute: '2-digit', meridiem: false }}
                                        locale={brLocale}
                                        height={700}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <DoctorAvailabilityForm ref={scheduleFormRef} onClose={onCloseModal} />
        </>
    )
}

export default DoctorAvailability;