import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";

const MaterialCard = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        materialInfoParm,
        hide,
        ...attributes
    } = props;

    const [catheterQuad, setCatheterQuad] = useState(false);
    const [catheterDeca, setCatheterDeca] = useState(false);
    const [catheterConv4mm, setCatheterConv4mm] = useState(false);
    const [catheterConv8mm, setCatheterConv8mm] = useState(false);
    const [catheterIrriContact, setCatheterIrriContact] = useState(false);
    const [catheterIrriEnergy, setCatheterIrriEnergy] = useState(false);
    const [catheterIrriElectro, setCatheterIrriElectro] = useState(false);
    const [catheterPumpKit, setCatheterPumpKit] = useState(false);
    const [catheterPenta, setCatheterPenta] = useState(false);
    const [catheterOcta, setCatheterOcta] = useState(false);
    const [catheterGrid, setCatheterGrid] = useState(false);
    const [bioimpedanceBoard, setBioimpedanceBoard] = useState(false);
    const [faCryoablationKit, setFaCryoablationKit] = useState(false);
    const [focalCryoablationKit, setFocalCryoablationKit] = useState(false);
    const [brokenbroughtNeedle, setBrokenbroughtNeedle] = useState(false);
    const [intracardiacContrast, setIntracardiacContrast] = useState(false);
    const [echocardiogramCatheter, setEchocardiogramCatheter] = useState(false);
    const [oroesophagealThermometer, setOroesophagealThermometer] = useState(false);
    const [pam, setPam] = useState(false);
    const [bladderCatheter, setBladderCatheter] = useState(false);
    const [punctionNeedle, setPunctionNeedle] = useState("");
    const [salineSolution, setSalineSolution] = useState(false);
    const [evAdenosine, setEvAdenosine] = useState(false);

    useEffect(() => {
        setCatheterQuad(materialInfoParm.catheterQuad ?? false);
        setCatheterDeca(materialInfoParm.catheterDeca ?? false);
        setCatheterConv4mm(materialInfoParm.catheterConv4mm ?? false);
        setCatheterConv8mm(materialInfoParm.catheterConv8mm ?? false);
        setCatheterIrriContact(materialInfoParm.catheterIrriContact ?? false);
        setCatheterIrriEnergy(materialInfoParm.catheterIrriEnergy ?? false);
        setCatheterIrriElectro(materialInfoParm.catheterIrriElectro ?? false);
        setCatheterPumpKit(materialInfoParm.catheterPumpKit ?? false);
        setCatheterPenta(materialInfoParm.catheterPenta ?? false);
        setCatheterOcta(materialInfoParm.catheterOcta ?? false);
        setCatheterGrid(materialInfoParm.catheterGrid ?? false);
        setBioimpedanceBoard(materialInfoParm.bioimpedanceBoard ?? false);
        setFaCryoablationKit(materialInfoParm.faCryoablationKit ?? false);
        setFocalCryoablationKit(materialInfoParm.focalCryoablationKit ?? false);
        setBrokenbroughtNeedle(materialInfoParm.brokenbroughtNeedle ?? false);
        setIntracardiacContrast(materialInfoParm.intracardiacContrast ?? false);
        setEchocardiogramCatheter(materialInfoParm.echocardiogramCatheter ?? false);
        setOroesophagealThermometer(materialInfoParm.oroesophagealThermometer ?? false);
        setPam(materialInfoParm.pam ?? false);
        setBladderCatheter(materialInfoParm.bladderCatheter ?? false);
        setPunctionNeedle(materialInfoParm.punctionNeedle ?? "");
        setSalineSolution(materialInfoParm.salineSolution ?? false);
        setEvAdenosine(materialInfoParm.evAdenosine ?? false);
    }, [materialInfoParm]);

    return (
        <div hidden={hide}>
            <div className="form-group mb-3 col-md-12">
                <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Materiais usados no procedimento</DCardHeader>
                    <DCardBody>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterQuad"
                                        checked={catheterQuad}
                                        onChange={() => {
                                            setCatheterQuad(!catheterQuad);
                                            onChangeInfo("catheterQuad", !catheterQuad);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterQuad">
                                        Cateter quadripolar e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterDeca"
                                        checked={catheterDeca}
                                        onChange={() => {
                                            setCatheterDeca(!catheterDeca);
                                            onChangeInfo("catheterDeca", !catheterDeca);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterDeca">
                                        Cateter decapolar e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterConv4mm"
                                        checked={catheterConv4mm}
                                        onChange={() => {
                                            setCatheterConv4mm(!catheterConv4mm);
                                            onChangeInfo("catheterConv4mm", !catheterConv4mm);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterConv4mm">
                                        Cateter de ablação convencional de 4mm e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterConv8mm"
                                        checked={catheterConv8mm}
                                        onChange={() => {
                                            setCatheterConv8mm(!catheterConv8mm);
                                            onChangeInfo("catheterConv8mm", !catheterConv8mm);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterConv8mm">
                                        Cateter de ablação convencional de 8mm e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterIrriContact"
                                        checked={catheterIrriContact}
                                        onChange={() => {
                                            setCatheterIrriContact(!catheterIrriContact);
                                            onChangeInfo("catheterIrriContact", !catheterIrriContact);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterIrriContact">
                                        Cateter de ablação irrigada com força de contato e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterIrriEnergy"
                                        checked={catheterIrriEnergy}
                                        onChange={() => {
                                            setCatheterIrriEnergy(!catheterIrriEnergy);
                                            onChangeInfo("catheterIrriEnergy", !catheterIrriEnergy);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterIrriEnergy">
                                        Cateter de ablação irrigada com força de contato usando alta energia e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterIrriElectro"
                                        checked={catheterIrriElectro}
                                        onChange={() => {
                                            setCatheterIrriElectro(!catheterIrriElectro);
                                            onChangeInfo("catheterIrriElectro", !catheterIrriElectro);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterIrriElectro">
                                        Cateter de ablação irrigada com força de contato e ELETROPORAÇÃO e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterPumpKit"
                                        checked={catheterPumpKit}
                                        onChange={() => {
                                            setCatheterPumpKit(!catheterPumpKit);
                                            onChangeInfo("catheterPumpKit", !catheterPumpKit);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterPumpKit">
                                        Kit com bomba de infusão para cateter irrigado
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterPenta"
                                        checked={catheterPenta}
                                        onChange={() => {
                                            setCatheterPenta(!catheterPenta);
                                            onChangeInfo("catheterPenta", !catheterPenta);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterPenta">
                                        Cateter Penta Array e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterOcta"
                                        checked={catheterOcta}
                                        onChange={() => {
                                            setCatheterOcta(!catheterOcta);
                                            onChangeInfo("catheterOcta", !catheterOcta);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterOcta">
                                        Cateter Octa Array e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="catheterGrid"
                                        checked={catheterGrid}
                                        onChange={() => {
                                            setCatheterGrid(!catheterGrid);
                                            onChangeInfo("catheterGrid", !catheterGrid);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="catheterGrid">
                                        Cateter HD GRID e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="bioimpedanceBoard"
                                        checked={bioimpedanceBoard}
                                        onChange={() => {
                                            setBioimpedanceBoard(!bioimpedanceBoard);
                                            onChangeInfo("bioimpedanceBoard", !bioimpedanceBoard);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="bioimpedanceBoard">
                                        Placas de bioimpedância
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="faCryoablationKit"
                                        checked={faCryoablationKit}
                                        onChange={() => {
                                            setFaCryoablationKit(!faCryoablationKit);
                                            onChangeInfo("faCryoablationKit", !faCryoablationKit);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="faCryoablationKit">
                                        KIT Crioablação FA:<br />
                                        - Bainha longa 12 Flex Cath<br />
                                        - Agulha de Touhy 16F<br />
                                        - Cateter balão de crio ablação<br />
                                        - Cateter Circular ACHIEVE<br />
                                        - Cabo Coaxial<br />
                                        - Cabo elétrico<br />
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="focalCryoablationKit"
                                        checked={focalCryoablationKit}
                                        onChange={() => {
                                            setFocalCryoablationKit(!focalCryoablationKit);
                                            onChangeInfo("focalCryoablationKit", !focalCryoablationKit);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="focalCryoablationKit">
                                        KIT Crioablação focal:<br />
                                        - Cateter focval de crio ablação<br />
                                        - Cabo Coaxial<br />
                                        - Cabo elétrico<br />
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="brokenbroughtNeedle"
                                        checked={brokenbroughtNeedle}
                                        onChange={() => {
                                            setBrokenbroughtNeedle(!brokenbroughtNeedle);
                                            onChangeInfo("brokenbroughtNeedle", !brokenbroughtNeedle);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="brokenbroughtNeedle">
                                        Agulha de Brokenbrought
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="intracardiacContrast"
                                        checked={intracardiacContrast}
                                        onChange={() => {
                                            setIntracardiacContrast(!intracardiacContrast);
                                            onChangeInfo("intracardiacContrast", !intracardiacContrast);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="intracardiacContrast">
                                        Contraste intracardíaco
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="echocardiogramCatheter"
                                        checked={echocardiogramCatheter}
                                        onChange={() => {
                                            setEchocardiogramCatheter(!echocardiogramCatheter);
                                            onChangeInfo("echocardiogramCatheter", !echocardiogramCatheter);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="echocardiogramCatheter">
                                        Sonda de Ecocardiograma intracardíaco e conector respectivo
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="oroesophagealThermometer"
                                        checked={oroesophagealThermometer}
                                        onChange={() => {
                                            setOroesophagealThermometer(!oroesophagealThermometer);
                                            onChangeInfo("oroesophagealThermometer", !oroesophagealThermometer);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="oroesophagealThermometer">
                                        Termômetro oroesofágico
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="pam"
                                        checked={pam}
                                        onChange={() => {
                                            setPam(!pam);
                                            onChangeInfo("pam", !pam);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="pam">
                                        PAM
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="bladderCatheter"
                                        checked={bladderCatheter}
                                        onChange={() => {
                                            setBladderCatheter(!bladderCatheter);
                                            onChangeInfo("bladderCatheter", !bladderCatheter);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="bladderCatheter">
                                        Sonda vesical de demora
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <div className="row">
                                        <div
                                            className="form-group mb-3 mt-1 col-md-3"
                                            style={{ width: "auto" }}
                                        >
                                            <label className="form-check-label" htmlFor="punctionNeedle">
                                                Agulha de punção venosa central
                                            </label>
                                        </div>

                                        <div className="form-group mb-3 col-md-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                style={{ height: "2rem", width: "4.5rem" }}
                                                value={punctionNeedle}
                                                onChange={e => {
                                                    setPunctionNeedle(e.target.value);
                                                    onChangeInfo("punctionNeedle", e.target.value);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="salineSolution"
                                        checked={salineSolution}
                                        onChange={() => {
                                            setSalineSolution(!salineSolution);
                                            onChangeInfo("salineSolution", !salineSolution);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="salineSolution">
                                        Soro fisiológico 250ml com 1 ampola de isoprenalina EV gota a gota para testes
                                    </label>
                                </div>
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="evAdenosine"
                                        checked={evAdenosine}
                                        onChange={() => {
                                            setEvAdenosine(!evAdenosine);
                                            onChangeInfo("evAdenosine", !evAdenosine);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="evAdenosine">
                                        Adenosina EV para testes eletrofisiológicos
                                    </label>
                                </div>
                            </div>
                        </div>
                    </DCardBody>
                </DCard>
            </div>
        </div>
    )
}

MaterialCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

MaterialCard.defaultProps = {
    materialInfoParm: {},
    hide: false
};

export default MaterialCard;