import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { Dropdown, Table } from "react-bootstrap";

const HospitalProcedure = props => {

    const {
        className,
        innerRef,
        //
        hospitalSummary,
        ...attributes
    } = props;

    const [hospitalList, setHospitalList] = useState([]);
    const [options, setOptions] = useState({
        chart: {
            id: 'assetDistribution',
            type: "bar",
            height: 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '45%',
                borderRadius: 5,
            },
        },
        colors: ['#', '#77248B'],
        dataLabels: {
            enabled: false,
        },
        markers: {
            shape: "circle",
        },
        legend: {
            show: false,
            fontSize: '12px',
            labels: {
                colors: '#000000',

            },
            markers: {
                width: 30,
                height: 30,
                strokeWidth: 0,
                strokeColor: '#fff',
                fillColors: undefined,
                radius: 35,
            }
        },
        stroke: {
            show: true,
            width: 6,
            colors: ['transparent']
        },
        grid: {
            borderColor: 'rgba(252, 252, 252,0.2)',
        },
        xaxis: {
            categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
            labels: {
                style: {
                    colors: '#ffffff',
                    fontSize: '13px',
                    fontFamily: 'poppins',
                    fontWeight: 100,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
                borderType: 'solid',
                color: '#78909C',
                height: 6,
                offsetX: 0,
                offsetY: 0
            },
            crosshairs: {
                show: false,
            }
        },
        yaxis: {
            labels: {
                offsetX: -16,
                style: {
                    colors: '#ffffff',
                    fontSize: '13px',
                    fontFamily: 'poppins',
                    fontWeight: 100,
                    cssClass: 'apexcharts-xaxis-label',
                },
            },
        },
        fill: {
            opacity: 1,
            colors: ['#C86B2A', '#fff'],
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        responsive: [{
            breakpoint: 575,
            options: {
                plotOptions: {
                    bar: {
                        columnWidth: '1%',
                        borderRadius: -1,
                    },
                },
                chart: {
                    height: 250,
                },
                series: [
                    {
                        name: 'Projects',
                        data: [31, 40, 28, 31, 40, 28, 31, 40]
                    },
                    {
                        name: 'Projects',
                        data: [11, 32, 45, 31, 40, 28, 31, 40]
                    },

                ],
            }
        }]
    });
    const [series, setSeries] = useState([]);
    const [seriesTotal, setSeriesTotal] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    const [selectBtn, setSelectBtn] = useState("Geral");
    const [totalComplex, setTotalComplex] = useState(0);
    const [totalPercentComplex, setTotalPercentComplex] = useState(0);
    const [totalNormal, setTotalNormal] = useState(0);
    const [totalPercentNormal, setTotalPercentNormal] = useState(0);

    useEffect(() => {
        const hospitalSet = new Set(hospitalSummary.map(item => `${item.hospital_id}-${item.hospital_name}`));
        const hospitalArray = Array.from(hospitalSet).map(item => {
            const [hospital_id, hospital_name] = item.split("-");
            return { hospital_id, hospital_name };
        });
        const hospitalSorted = hospitalArray.sort((a, b) => a.hospital_name - b.hospital_name);
        setHospitalList(hospitalSorted);

        changeSeries([...hospitalSummary]);
    }, [hospitalSummary])

    function changeSeries(dataArray) {
        let newSeries = [];
        let totalSeries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        for (let indexSummary = 0; indexSummary < dataArray.length; indexSummary++) {
            const indexSeries = newSeries.findIndex(x => x.hospital_id === dataArray[indexSummary].hospital_id);

            if (indexSeries < 0) {
                newSeries.push({
                    hospital_id: dataArray[indexSummary].hospital_id,
                    hospital_name: dataArray[indexSummary].hospital_name,
                    quantity: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                });
                newSeries[newSeries.length - 1].quantity[dataArray[indexSummary].month - 1] = dataArray[indexSummary].count;
                newSeries[newSeries.length - 1].quantity[12] = dataArray[indexSummary].count;
            } else {
                newSeries[indexSeries].quantity[dataArray[indexSummary].month - 1] += dataArray[indexSummary].count;
                newSeries[indexSeries].quantity[12] += dataArray[indexSummary].count;
            }

            totalSeries[dataArray[indexSummary].month - 1] += dataArray[indexSummary].count;
            totalSeries[12] += dataArray[indexSummary].count;
        }

        const seriesSorted = newSeries.sort((a, b) => a.hospital_name - b.hospital_name);

        setSeries(seriesSorted);
        setSeriesTotal(totalSeries);
    }

    function handleChangeHospital(id) {
        if (id !== "Geral") {
            const filter = (id === "Complexa" ? "COM" : "NOR");
            const hospitalSummaryFiltered = hospitalSummary.filter(x => x.complexity === filter);
            changeSeries([...hospitalSummaryFiltered]);
        } else {
            changeSeries([...hospitalSummary]);
        }

        setSelectBtn(id);
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-10">
                    <div className="card-header border-0 flex-wrap pb-2">
                        <div className="chart-title mb-2 ">
                            <h2 className="heading">Procedimentos por Hospital</h2>
                        </div>
                    </div>
                </div>
                <div className="col-xl-2">
                    <div className="statistic-content">
                        <div className="d-flex justify-content-between">
                            <Dropdown className="me-3 drop-select">
                                <Dropdown.Toggle
                                    as="div"
                                    className="i-false drop-select-btn style-dark"
                                    style={{backgroundColor: "#fff"}}
                                >
                                    {selectBtn} <i className="fa-solid fa-angle-down"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item key="Geral" onClick={() => handleChangeHospital("Geral")}>Geral</Dropdown.Item>
                                    <Dropdown.Item key="Complexa" onClick={() => handleChangeHospital("Complexa")}>Complexa</Dropdown.Item>
                                    <Dropdown.Item key="Convencional" onClick={() => handleChangeHospital("Convencional")}>Convencional</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12">
                    <div className="card-body pt-0 custome-tooltip">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>HOSPITAL</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>JAN</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>FEV</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>MAR</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>ABR</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>MAI</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>JUN</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>JUL</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>AGO</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>SET</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>OUT</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>NOV</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>DEZ</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>TOTAL</strong>
                                    </th>
                                    <th className="text-center">
                                        <strong>%</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    series.map((seriesItem) => {
                                        const counter = seriesItem.quantity;
                                        let hospitalPercent = 0;

                                        if (seriesTotal[12] > 0) {
                                            hospitalPercent = ((counter[12] / seriesTotal[12]) * 100).toFixed(2)
                                        }

                                        return (
                                            <tr key={seriesItem.hospital_id}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span className="w-space-no">{seriesItem.hospital_name}</span>
                                                    </div>
                                                </td>

                                                <td>
                                                    <div className="text-center">
                                                        {counter[0]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[1]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[2]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[3]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[4]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[5]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[6]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[7]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[8]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[9]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[10]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        {counter[11]}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-center">
                                                        <strong>{counter[12]}</strong>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="text-end">
                                                        {hospitalPercent.toLocaleString("pt-br")} %
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }

                                <tr key="TOTGER">
                                    <td>
                                        <div className="d-flex align-items-center">
                                            <span className="w-space-no"><strong>TOTAL GERAL</strong></span>
                                        </div>
                                    </td>

                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[0]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[1]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[2]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[3]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[4]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[5]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[6]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[7]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[8]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[9]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[10]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[11]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-center">
                                            <strong>{seriesTotal[12]}</strong>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-end">
                                            <strong>100,00 %</strong>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    )
}

HospitalProcedure.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    hospitalSummary: PropTypes.array
};

HospitalProcedure.defaultProps = {
    hospitalSummary: []
};

export default HospitalProcedure;