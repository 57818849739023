import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';
import Select from "react-select";

import CurrencyInput, { formatValue } from "react-currency-input-field";
import DFormText from "../../components/DFormText/DFormText";
import ModalLoading from '../../components/ModalLoading/ModalLoading';
import api from '../../../services/api';

const COMPLEXITY_TYPES = [
    {
        value: "COM",
        label: "Complexo"
    },
    {
        value: "NOR",
        label: "Convencional"
    }
];

const ACCOMMODATION_TYPES = [
    {
        value: "APT",
        label: "Apartamento"
    },
    {
        value: "ENF",
        label: "Enfermaria"
    }
];

const PriceTableForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingPriceTableForm, setLoadingPriceTableForm] = useState(false);

    const [proceduresList, setProceduresList] = useState([]);
    const [procedure, setProcedure] = useState(undefined);
    const [complexity, setComplexity] = useState(undefined);
    const [accommodation, setAccommodation] = useState(undefined);
    const [price, setPrice] = useState(0);

    const [priceItemId, setPriceItemId] = useState(undefined);

    const [invalidProcedure, setInvalidProcedure] = useState(false);
    const [invalidComplexity, setInvalidComplexity] = useState(false);
    const [invalidAccommodation, setInvalidAccommodation] = useState(false);
    const [invalidPrice, setInvalidPrice] = useState(false);

    useImperativeHandle(ref, () => ({
        openModal(mode, priceItemInfo = {}) {
            getProcedures();

            setModalBox(true);
            setIsUpdate(false);

            resetFields();

            if (mode === "update") {
                setIsUpdate(true);

                setPriceItemId(priceItemInfo._id);
                
                setProcedure({ value: priceItemInfo.procedure_id, label: priceItemInfo.procedure_name });

                const complexitySelected = COMPLEXITY_TYPES.find(x => x.value === priceItemInfo.complexity_id);
                setComplexity(complexitySelected ?? null);

                const accommodationSelected = ACCOMMODATION_TYPES.find(x => x.value === priceItemInfo.accommodation_id)
                setAccommodation(accommodationSelected ?? accommodationSelected);

                setPrice(formatValue({
                    value: priceItemInfo.price.toString(),
                    groupSeparator: ".",
                    decimalSeparator: ","
                }));
            }
        }
    }));

    async function getProcedures() {
        await api.get("/procedures?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const proceduresReturned = response.data.procedures?.map((procedure) => {
                        return {
                            value: procedure._id,
                            label: procedure.code + " - " + procedure.name
                        }
                    });

                    setProceduresList(proceduresReturned);
                }
            })
    }

    async function handleSavePriceItem(e) {
        e.preventDefault();

        setLoadingPriceTableForm(true);

        setInvalidProcedure(false);
        setInvalidComplexity(false);
        setInvalidAccommodation(false);
        setInvalidPrice(false);

        let hasError = false;

        if (!procedure) {
            setInvalidProcedure(true);
            hasError = true;
        }

        if (!complexity) {
            setInvalidComplexity(true);
            hasError = true;
        }

        if (!accommodation) {
            setInvalidAccommodation(true);
            hasError = true;
        }

        if (price <= 0) {
            setInvalidPrice(true);
            hasError = true;
        }

        if (!hasError) {
            let mode = "new";
            let priceItem = {
                innerId: (Math.floor(Math.random() * 65536)).toString(),
                procedure_id: procedure.value,
                procedure_name: procedure.label,
                complexity_id: complexity.value,
                complexity_name: complexity.label,
                accommodation_id: accommodation.value,
                accommodation_name: accommodation.label,
                price: +(price.replace(/[.]/g, "").replace(",", "."))
            }

            if (isUpdate) {
                priceItem.innerId = priceItemId;
                mode = "update"
            }

            resetFields();
            onClose(mode, priceItem);
            setModalBox(false);
        }

        setLoadingPriceTableForm(false);
    }

    function resetFields() {
        setProceduresList([]);
        setProcedure(undefined);
        setComplexity(undefined);
        setAccommodation(undefined);
        setPrice(0);

        setInvalidProcedure(false);
        setInvalidComplexity(false);
        setInvalidAccommodation(false);
        setInvalidPrice(false);
    }

    return (
        <>
            <Modal size='xl' onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">{(!isUpdate ? "Novo " : "Alterar ")}Item</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Procedimento</label>
                                    <Select
                                        autoFocus
                                        defaultValue={procedure}
                                        value={procedure}
                                        onChange={(e) => setProcedure(e)}
                                        options={proceduresList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidProcedure} color="danger">Selecione o procedimento</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Complexidade</label>
                                    <Select
                                        defaultValue={complexity}
                                        value={complexity}
                                        onChange={(e) => setComplexity(e)}
                                        options={COMPLEXITY_TYPES}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidComplexity} color="danger">Selecione a complexidade</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Tipo de Acomodação</label>
                                    <Select
                                        defaultValue={accommodation}
                                        value={accommodation}
                                        onChange={(e) => setAccommodation(e)}
                                        options={ACCOMMODATION_TYPES}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidAccommodation} color="danger">Selecione o tipo de acomodação</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Valor</label>
                                    <CurrencyInput
                                        className="form-control text-end"
                                        id="input-price"
                                        defaultValue={price}
                                        decimalsLimit={2}
                                        onValueChange={(value) => setPrice(value)}
                                        intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                                    />
                                    <DFormText hidden={!invalidPrice} color="danger">Informe o valor do procedimento</DFormText>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                        <Button variant="success btn-rounded" onClick={handleSavePriceItem}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ModalLoading
                visible={loadingPriceTableForm}
                message="Gravando Informações..."
                onClose={setLoadingPriceTableForm}
            />
        </>
    )
})

export default PriceTableForm;