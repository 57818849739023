import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
    Badge,
    Button,
} from "react-bootstrap";
import Select from "react-select";

import api from "../../../services/api";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DPagination from "../../components/DPagination/DPagination";
import { checkTermsAndPrivacy, isAuthenticated } from "../../../services/utils";
import { useHistory } from "react-router-dom";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs";
import swal from "sweetalert";
import Terms from "../UserAgreement/Terms";
import Privacy from "../UserAgreement/Privacy";
import { createLog } from "../../../services/logService";
registerLocale("ptBR", ptBR);

const OPERATIONAL_STATUS = [
    {
        value: "rti",
        label: "Apto a faturar"
    },
    {
        value: "inv",
        label: "Faturado"
    },
    {
        value: "rec",
        label: "Recebido"
    }
];

const Invoice = () => {
    const termsRef = useRef();
    const privacyRef = useRef();
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Carregando Informações...");
    const [operationals, setOperationals] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterDateFrom, setFilterDateFrom] = useState(null);
    const [filterDateTo, setFilterDateTo] = useState(null);
    const [filterName, setFilterName] = useState("");
    const [healthPlans, setHealthPlans] = useState([]);
    const [filterHealthPlan, setFilterHealthPlan] = useState([]);
    const [filterStatus, setFilterStatus] = useState([]);

    const [checkAll, setCheckAll] = useState(false);

    const userInfo = JSON.parse(localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxUsr") : sessionStorage.getItem("xLcxUsr"));

    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated()) {
            return history.push("/login");
        }

        if (!checkTermsAndPrivacy("terms")) {
            termsRef.current.openModal();
        }

        if (!checkTermsAndPrivacy("privacy")) {
            privacyRef.current.openModal();
        }

        getHealthPlans();
        getOperationals();
    }, [])

    async function getHealthPlans() {
        await api.get("/health-plans?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    setHealthPlans(response.data.healthPlans.map(healthPlan => {
                        return {
                            value: healthPlan._id,
                            label: healthPlan.name
                        }
                    }));
                }
            })
    }

    async function getOperationals(page = currentPage) {
        setLoadingMessage("Carregando Informações...");
        setLoading(true);

        let filter = "&";
        if (filterName) {
            filter += `qs=${filterName}&`;
        }
        if (filterDateFrom && filterDateTo) {
            const filterDateToUTC = dayjs(filterDateTo).add(1, "day");
            filter += `executionDateStart=${dayjs(filterDateFrom).toISOString()}&executionDateEnd=${dayjs(filterDateToUTC).toISOString()}&`;
        }
        if (filterHealthPlan.length > 0) {
            filter += "healthPlans=";
            for (let i = 0; i < filterHealthPlan.length; i++) {
                if (i > 0) {
                    filter += ","
                }
                filter += filterHealthPlan[i].value;
            }
        }
        if (filterStatus.length > 0) {
            filter += "status=";
            for (let i = 0; i < filterStatus.length; i++) {
                if (i > 0) {
                    filter += ","
                }
                filter += filterStatus[i].value;
            }
        }

        await api.get(`/operationals?offset=${page}&limit=10${filter}`)
            .then(response => {
                if (response.status === 200) {
                    const operationalsReturned = response.data.operationals.map((operational) => {
                        let isDisabledCheck = false;
                        if (operational.status !== "rti" || !operational.commission || (operational.commission && operational.commission?.total === 0)) {
                            isDisabledCheck = true;
                        }

                        return {
                            ...operational,
                            price: operational.commission?.total ?? 0,
                            isChecked: false,
                            isDisabled: isDisabledCheck,
                            statusBadge: getStatus(operational.status)
                        }
                    })
                    setOperationals(operationalsReturned);

                    let pages = Math.ceil(response.data.total / 10);
                    setTotalPages(Math.max(pages, 1));
                }
            })

        setLoading(false);
    }

    function getStatus(status) {
        let statusColor = "warning";
        let statusLabel = "Apto a faturar";

        switch (status) {
            case "inv":
                statusColor = "info";
                statusLabel = "Faturado";
                break;
            case "rec":
                statusColor = "success";
                statusLabel = "Recebido";
                break;
            default:
                statusColor = "warning";
                statusLabel = "Apto a faturar";
                break;
        }

        return {
            color: statusColor,
            label: statusLabel
        };
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getOperationals(page);
    }

    function handleFilter() {
        setCurrentPage(1);
        getOperationals(1);
    }

    function handleCheck(type) {
        if (type === "all") {
            const newStatus = !checkAll;
            setCheckAll(newStatus);

            let newOperationalsList = [...operationals];
            for (let i = 0; i < newOperationalsList.length; i++) {
                newOperationalsList[i].isChecked = newStatus;
            }
            setOperationals(newOperationalsList);
        } else {
            let newOperationalsList = [...operationals];
            for (let i = 0; i < newOperationalsList.length; i++) {
                if (newOperationalsList[i]._id === type) {
                    newOperationalsList[i].isChecked = !newOperationalsList[i].isChecked;
                }
            }
            setOperationals(newOperationalsList);

            if (newOperationalsList.some(x => !x.isChecked)) {
                setCheckAll(false);
            } else {
                setCheckAll(true);
            }
        }
    }

    function handleClickGenerate() {
        if (operationals.some(x => x.isChecked)) {
            swal({
                title: "Confirma o faturamento?",
                text:
                    "Os registros selecionados serão marcados como faturados!",
                icon: "warning",
                buttons: ["Fechar", "Confirmar"],
            }).then((willDelete) => {
                if (willDelete) {
                    handleGenerateInvoice();
                }
            })
        } else {
            if (operationals.some(x => !x.isDisabled)) {
                swal("Não foi selecionado nenhum registro!", {
                    icon: "warning",
                });
            } else {
                swal("Não existem registros a faturar!", {
                    icon: "warning",
                });
            }
        }
    }

    async function handleGenerateInvoice() {
        setLoadingMessage("Atualizando registros...");
        setLoading(true);

        let operationalsList = [];

        for (let i = 0; i < operationals.length; i++) {
            if (!operationals[i].isDisabled && operationals[i].isChecked) {
                operationalsList.push(operationals[i]._id);
            }
        }

        if (operationalsList.length === 0) {
            swal("Atenção", "Não foi selecionado nenhum registro!", "error");
            setLoading(false);
            return
        }

        const invoiceToGenerate = { user: userInfo.name, operationals: operationalsList };

        await api.post("/operationals/invoices", invoiceToGenerate)
            .then(response => {
                if (response.status === 200) {
                    createLog({
                        routine: "invoice",
                        action: "create",
                        type: "success",
                        message: `Faturamento realizado`,
                        jsonOrigin: JSON.stringify(invoiceToGenerate),
                        jsonReturn: JSON.stringify(response)
                    });

                    const invoicesReturned = response.data.invoices;

                    let finalMessage = "Faturamento realizado!";
                    if (invoicesReturned.some(x => x.status !== 201)) {
                        finalMessage = "Alguns registros tiveram problemas, porém os demais foram faturados!";
                    }

                    swal(finalMessage, {
                        icon: "success",
                    });
                }
            })

        setLoading(false);

        getOperationals();
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Invoice" motherMenu="Operational" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Faturamento</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Inicial</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateFrom}
                                                onChange={(date) => setFilterDateFrom(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Final</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateTo}
                                                onChange={(date) => setFilterDateTo(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-6">
                                            <label>Paciente - <smal>Informe o CPF ou parte do nome</smal></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={filterName}
                                                onChange={e => setFilterName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-8">
                                            <label>Convênio(s)</label>
                                            <Select
                                                value={filterHealthPlan}
                                                closeMenuOnSelect={false}
                                                defaultValue={filterHealthPlan}
                                                isMulti
                                                options={healthPlans}
                                                placeholder="Selecione..."
                                                onChange={setFilterHealthPlan}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <label>Status</label>
                                            <Select
                                                value={filterStatus}
                                                closeMenuOnSelect={false}
                                                defaultValue={filterStatus}
                                                isMulti
                                                options={OPERATIONAL_STATUS}
                                                placeholder="Selecione..."
                                                onChange={setFilterStatus}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <Button
                                                variant="outline-primary btn-rounded"
                                                onClick={handleFilter}
                                            >
                                                Filtrar
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <h5>Selecione os procedimentos a faturar</h5>
                                <div>
                                    <button
                                        className="btn btn-primary ms-1"
                                        // onClick={handleGenerateInvoice}
                                        onClick={() => handleClickGenerate()}
                                    >
                                        Marcar como faturados
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkAll"
                                                    required=""
                                                    checked={checkAll}
                                                    onChange={() => handleCheck("all")}
                                                    hidden={operationals.every(x => x.isDisabled)}
                                                />
                                            </th>
                                            <th>
                                                <strong>Status</strong>
                                            </th>
                                            <th>
                                                <strong>Data</strong>
                                            </th>
                                            <th>
                                                <strong>Paciente</strong>
                                            </th>
                                            <th>
                                                <strong>Convênio</strong>
                                            </th>
                                            <th>
                                                <strong>Médico</strong>
                                            </th>
                                            <th>
                                                <strong>Valor Previsto</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            operationals.map((operational) => {
                                                return (
                                                    <tr key={operational._id}>
                                                        <td>
                                                            <div className="checkbox me-0 align-self-center">
                                                                <div className="custom-control custom-checkbox ">
                                                                    <input type="checkbox" className="form-check-input" required=""
                                                                        onClick={() => handleCheck(operational._id)}
                                                                        checked={operational.isChecked}
                                                                        disabled={operational.isDisabled}
                                                                        hidden={operational.isDisabled}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-left">
                                                                <Badge bg="" className={`badge-${operational.statusBadge.color}`} >{operational.statusBadge.label}</Badge>
                                                            </div>
                                                        </td>
                                                        <td>{dayjs(operational.execution_date).format("DD/MM/YYYY")}</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{operational.pacient_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>{operational.health_plan_name}</td>
                                                        <td>{operational.main_doctor_name}</td>
                                                        <td>
                                                            <div className="text-end">
                                                                {operational.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <Terms ref={termsRef} />

            <Privacy ref={privacyRef} />

            <ModalLoading
                visible={loading}
                message={loadingMessage}
                onClose={setLoading}
            />
        </>
    );
};

export default Invoice;