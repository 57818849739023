const ablationEvTvOptions = [
    {
        value: "EV",
        label: "EV"
    },
    {
        value: "TV",
        label: "TV"
    }
];

const ablationOptions = [
    {
        value: 1,
        label: "1 - Endocard"
    },
    {
        value: 2,
        label: "2 - Epicard"
    }
];

const ablationPlaceOptions = [
    {
        value: 1,
        label: "1 - VSVD"
    },
    {
        value: 2,
        label: "2 - VSVE"
    },
    {
        value: 3,
        label: "3 - VD"
    },
    {
        value: 4,
        label: "4 - VE"
    },
    {
        value: 5,
        label: "5 - outro"
    }
];

const ablationTypeOptions = [
    {
        value: 1,
        label: "1 - Precocid"
    },
    {
        value: 2,
        label: "2 - Pacemap"
    },
    {
        value: 3,
        label: "3 - Pot Tardio"
    },
    {
        value: 4,
        label: "4 - Ilam"
    },
    {
        value: 5,
        label: "5 - Outra"
    }
];

const actualDaaOptions = [
    {
        value: 0,
        label: "0 - não usa"
    },
    {
        value: 1,
        label: "1 - Amio"
    },
    {
        value: 2,
        label: "2 - Prop"
    },
    {
        value: 3,
        label: "3 - Sot"
    },
    {
        value: 4,
        label: "4 - outra"
    }
];

const anticoagOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - dabi"
    },
    {
        value: 2,
        label: "2 - riva"
    },
    {
        value: 3,
        label: "3 - apix"
    },
    {
        value: 4,
        label: "4 - endox"
    },
    {
        value: 5,
        label: "5 - warfa"
    },
    {
        value: 6,
        label: "6 - outro"
    }
];

const cdiPreventOptions = [
    {
        value: 1,
        label: "1 - primária"
    },
    {
        value: 2,
        label: "2 - secundária"
    }
];

const cdiShockYesNoOptions = [
    {
        value: 1,
        label: "1 - não"
    },
    {
        value: 2,
        label: "2 - sim"
    }
];

const ecgConsultOptions = [
    {
        value: 0,
        label: "0 - Sinusal"
    },
    {
        value: 1,
        label: "1 - FA"
    },
    {
        value: 2,
        label: "2 - Flutter"
    },
    {
        value: 3,
        label: "3 - Taqui Atrial"
    },
    {
        value: 4,
        label: "4 - Outro"
    }
];

const ecgCrisisOptions = [
    {
        value: 1,
        label: "1 - FA"
    },
    {
        value: 2,
        label: "2 - Flutter"
    },
    {
        value: 3,
        label: "3 - Taqui Atrial"
    },
    {
        value: 4,
        label: "4 - Outro"
    }
];

const ecgOptions = [
    {
        value: 0,
        label: "0 - Não tem"
    },
    {
        value: 1,
        label: "1 - Normal"
    },
    {
        value: 2,
        label: "2 - BAV"
    },
    {
        value: 3,
        label: "3 - BAVT"
    },
    {
        value: 4,
        label: "4 - Outro"
    }
];

const evMiocNonIsqOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - hipertrof"
    },
    {
        value: 2,
        label: "2 - DAVD"
    },
    {
        value: 3,
        label: "3 - Dilat"
    },
    {
        value: 4,
        label: "4 - hipertens"
    },
    {
        value: 5,
        label: "5 - sarc"
    },
    {
        value: 6,
        label: "6 - alcool"
    },
    {
        value: 7,
        label: "7 - Chagas"
    },
    {
        value: 8,
        label: "8 - amiloid"
    },
    {
        value: 9,
        label: "9 - outra"
    }
];

const evTvOptions = [
    {
        value: 1,
        label: "1 - idiopat"
    },
    {
        value: 2,
        label: "2 - isquem"
    },
    {
        value: 3,
        label: "3 - não isquem"
    },
    {
        value: 4,
        label: "4 - canalop"
    },
    {
        value: 5,
        label: "5 - outra"
    }
];

const faDiagnosisOptions = [
    {
        value: 1,
        label: "1 - FA"
    },
    {
        value: 2,
        label: "2 - Flutter típico"
    },
    {
        value: 3,
        label: "3 - Flutter atip"
    },
    {
        value: 4,
        label: "4 - FA + flut tip"
    },
    {
        value: 5,
        label: "5 - FA + flut atip"
    },
    {
        value: 6,
        label: "6 - Taq Atrial"
    },
    {
        value: 7,
        label: "7 - Outro"
    }
];

const faMiocNonIsqOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - hipertrof"
    },
    {
        value: 2,
        label: "2 - DAVD"
    },
    {
        value: 3,
        label: "3 - Dilat"
    },
    {
        value: 4,
        label: "4 - hipertens"
    },
    {
        value: 5,
        label: "5 - sarc"
    },
    {
        value: 6,
        label: "6 - Outra"
    }
];

const faSessionOptions = [
    {
        value: 0,
        label: "0 - primeira"
    },
    {
        value: 1,
        label: "1 - redu 1"
    },
    {
        value: 2,
        label: "2 - redu 2"
    },
    {
        value: 3,
        label: "3 - redu 3"
    },
    {
        value: 4,
        label: "4 - redu 4"
    }
];

const faTypeOptions = [
    {
        value: 1,
        label: "1 - Parox"
    },
    {
        value: 2,
        label: "2 - Persist"
    },
    {
        value: 3,
        label: "3 - PLD"
    },
    {
        value: 4,
        label: "4 - Perm"
    }
];

const genericNormalAnormalOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - normal"
    },
    {
        value: 2,
        label: "2 - anormal"
    }
];

const holterOptions = [
    {
        value: 1,
        label: "1 - EV"
    },
    {
        value: 2,
        label: "2 - TVNS"
    },
    {
        value: 3,
        label: "3 - TV mono"
    },
    {
        value: 4,
        label: "4 - TV polim"
    },
    {
        value: 5,
        label: "5 - BAV"
    },
    {
        value: 6,
        label: "6 - Outro"
    }
];

const implantAccessTypeOptions = [
    {
        value: 1,
        label: "1 - cefalica"
    },
    {
        value: 2,
        label: "2 - subclavia"
    },
    {
        value: 3,
        label: "3 - axiliar"
    }
];

const implantMainSymptomOptions = [
    {
        value: 0,
        label: "0 - assintom"
    },
    {
        value: 1,
        label: "1 - palpit"
    },
    {
        value: 2,
        label: "2 - cansaço"
    },
    {
        value: 3,
        label: "3 - tontura"
    },
    {
        value: 4,
        label: "4 - síncope"
    },
    {
        value: 5,
        label: "5 - outra"
    }
];

const implantMiocNonIsqOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - hipertrof"
    },
    {
        value: 2,
        label: "2 - DAVD"
    },
    {
        value: 3,
        label: "3 - Dilat"
    },
    {
        value: 4,
        label: "4 - hipertens"
    },
    {
        value: 5,
        label: "5 - sarc"
    },
    {
        value: 6,
        label: "6 - Chagas"
    },
    {
        value: 7,
        label: "7 - Outra"
    }
];

const implantReasonOptions = [
    {
        value: 1,
        label: "1 - DNS"
    },
    {
        value: 2,
        label: "2 - BAV"
    },
    {
        value: 3,
        label: "3 - Síncope"
    },
    {
        value: 4,
        label: "4 - Arrit ventr"
    },
    {
        value: 5,
        label: "5 - ICC"
    },
    {
        value: 6,
        label: "6 - Outro"
    }
];

const implantResultOptions = [
    {
        value: 1,
        label: "1 - insucesso"
    },
    {
        value: 2,
        label: "2 - sucesso"
    }
];

const implantTypeOptions = [
    {
        value: 1,
        label: "1 - MP Camara Unica"
    },
    {
        value: 2,
        label: "2 - Bicameral"
    },
    {
        value: 3,
        label: "3 - CDI"
    },
    {
        value: 4,
        label: "4 - Resinc"
    },
    {
        value: 5,
        label: "5 - Troca Gerad"
    }
];

const implantVeElectrodeOptions = [
    {
        value: 1,
        label: "1 - unipolar"
    },
    {
        value: 2,
        label: "2 - bipolar"
    },
    {
        value: 3,
        label: "3 - qaudril"
    },
    {
        value: 4,
        label: "4 - outro"
    }
];

const implantVeinOptions = [
    {
        value: 1,
        label: "1 - anterior"
    },
    {
        value: 2,
        label: "2 - posterior"
    },
    {
        value: 3,
        label: "3 - lateral"
    },
    {
        value: 4,
        label: "4 - antlat"
    },
    {
        value: 5,
        label: "5 - antpost"
    },
    {
        value: 6,
        label: "6 - outra"
    }
];

const mainSymptomOptions = [
    {
        value: 0,
        label: "0 - assintom"
    },
    {
        value: 1,
        label: "1 - palpit"
    },
    {
        value: 2,
        label: "2 - cansaço"
    },
    {
        value: 3,
        label: "3 - dispn"
    },
    {
        value: 4,
        label: "4 - outra"
    }
];

const meaOptions = [
    {
        value: 1,
        label: "1 - Carto"
    },
    {
        value: 2,
        label: "2 - Ensite"
    }
];

const protocolYesNoOptions = [
    {
        value: 0,
        label: "0 - não"
    },
    {
        value: 1,
        label: "1 - sim"
    }
];

const ventriArrhythmiaOptions = [
    {
        value: 1,
        label: "1 - Extra Ven"
    },
    {
        value: 2,
        label: "2 - TVNS"
    },
    {
        value: 3,
        label: "3 - TV"
    },
    {
        value: 4,
        label: "4 - TV polim"
    },
    {
        value: 5,
        label: "5 - outra"
    }
];

const ventriArrhythmiaEcgOptions = [
    {
        value: 0,
        label: "0 - Não tem"
    },
    {
        value: 1,
        label: "1 - Via Saída VD/VE"
    },
    {
        value: 2,
        label: "2 - VD"
    },
    {
        value: 3,
        label: "3 - VE"
    },
    {
        value: 4,
        label: "4 - Indeter"
    }
];

export {
    ablationEvTvOptions,
    ablationOptions,
    ablationPlaceOptions,
    ablationTypeOptions,
    actualDaaOptions,
    anticoagOptions,
    cdiPreventOptions,
    cdiShockYesNoOptions,
    ecgConsultOptions,
    ecgCrisisOptions,
    ecgOptions,
    evMiocNonIsqOptions,
    evTvOptions,
    faDiagnosisOptions,
    faMiocNonIsqOptions,
    faSessionOptions,
    faTypeOptions,
    genericNormalAnormalOptions,
    holterOptions,
    implantAccessTypeOptions,
    implantMainSymptomOptions,
    implantMiocNonIsqOptions,
    implantReasonOptions,
    implantResultOptions,
    implantTypeOptions,
    implantVeElectrodeOptions,
    implantVeinOptions,
    mainSymptomOptions,
    meaOptions,
    protocolYesNoOptions,
    ventriArrhythmiaOptions,
    ventriArrhythmiaEcgOptions
};