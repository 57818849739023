const implantLimit = [
    {
        value: "MQD",
        label: "Menor que 10"
    },
    {
        value: "EEQ",
        label: "Entre 11 e 15"
    },
    {
        value: "EDV",
        label: "Entre 16 e 20"
    },
    {
        value: "MQV",
        label: "Maior que 20"
    }
];

const implantTypes = [
    {
        value: "CDIDDD",
        label: "CDI DDD"
    },
    {
        value: "CDIDDDBIV",
        label: "CDI DDD biventricular"
    },
    {
        value: "CDIVVI",
        label: "CDI VVI"
    },
    {
        value: "CDIVVIBIV",
        label: "CDI VVI biventricular"
    },
    {
        value: "EXTELE",
        label: "Extração de eletrodo"
    },
    {
        value: "MPDDD",
        label: "MP DDD"
    },
    {
        value: "MPDDDBIV",
        label: "MP DDD biventricular"
    },
    {
        value: "MPDDDHIS",
        label: "MP DDD Hissiano"
    },
    {
        value: "MPDDDLT",
        label: "MP DDD ramo esquerdo"
    },
    {
        value: "MPVVI",
        label: "MP VVI"
    },
    {
        value: "MPVVIBIV",
        label: "MP VVI biventricular"
    },
    {
        value: "MPVVIHIS",
        label: "MP VVI Hissiano"
    },
    {
        value: "MPCVVILT",
        label: "MP VVI ramo esquerdo"
    },
    {
        value: "OUT",
        label: "Outros"
    },
    {
        value: "GEN",
        label: "Troca de gerador"
    }
];

const inductionPlaces = [
    {
        value: "PED",
        label: "Peitoral Direito"
    },
    {
        value: "PEE",
        label: "Peitoral Esquerdo"
    },
    {
        value: "ABD",
        label: "Abdominal"
    },
    {
        value: "OUT",
        label: "Outro"
    }
];

const resultsList = [
    {
        value: "SUC",
        label: "Sucesso"
    },
    {
        value: "INS",
        label: "Insucesso"
    },
    {
        value: "OUT",
        label: "Outro"
    }
];

const suppliersList = [
    {
        value: "BIO",
        label: "Biotronik"
    },
    {
        value: "MED",
        label: "Medtronic"
    },
    {
        value: "BOS",
        label: "Boston"
    },
    {
        value: "SAI",
        label: "Saint Jude"
    },
    {
        value: "ABB",
        label: "Abbott"
    }
];

export {
    implantLimit,
    implantTypes,
    inductionPlaces,
    resultsList,
    suppliersList
};