import React from "react";
import "./style.css";

const SimpleInput = (props) => {
    const { label, value, type, onChange } = props;

    return (
        <div className="simple-input mt-4">
            <input
                className="w-full bg-transparent border-none outline-none ushadow-none text-base tracking-widest pt-2 pb-1"
                type={(type) ? type : "text"}
                placeholder=""
                value={value}
                onChange={(event) => onChange(event.target.value)}
            />
            <span className="absolute left-0 pt-2 pb-1 uppercase pointer-events-none tracking-widest duration-500"> {label} </span>
        </div>
    )
}

export default SimpleInput;