import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import { Table } from "react-bootstrap";

const ParticularityCard = props => {
    const {
        children,
        className,
        //
        onRequestAdd,
        particularitiesListParm,
        ...attributes
    } = props;

    const [particularitiesList, setParticularitiesList] = useState(particularitiesListParm);
    const [particularity, setParticularity] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [idRowEditing, setIdRowEditing] = useState(undefined);

    useEffect(() => {
        setParticularitiesList(particularitiesListParm);
    }, [particularitiesListParm]);

    function handleAddParticularity() {
        if (!isEditing) {
            const newParticularityItem = {
                id: (Math.floor(Math.random() * 65536)).toString(),
                particularity
            };

            const newParticularitiesList = [...particularitiesList, newParticularityItem];
            setParticularitiesList(newParticularitiesList);
            onRequestAdd(newParticularitiesList);
        } else {
            const newParticularitiesList = particularitiesList.map(particularityItem => {
                if (particularityItem.id === idRowEditing) {
                    return {
                        id: particularityItem.id,
                        particularity
                    }
                }

                return particularityItem;
            })
            setParticularitiesList(newParticularitiesList);
            onRequestAdd(newParticularitiesList);
        }

        resetFields();
    }

    function handleEditParticularity(id) {
        const editRowInfo = particularitiesList.find(x => x.id === id);
        if (editRowInfo) {
            setParticularity(editRowInfo.particularity);

            setIdRowEditing(id);
            setIsEditing(true);
        }
    }

    function handleDeleteParticularity(id) {
        const newParticularitiesList = particularitiesList.filter(x => x.id !== id);
        setParticularitiesList(newParticularitiesList);
        onRequestAdd(newParticularitiesList);
    }

    function resetFields() {
        setParticularity("");

        setIsEditing(false);
        setIdRowEditing(undefined);
    }

    return (
        <>
            <DCard borderColor="light">
                <DCardHeader color="light" textColor="dark">Particularidades</DCardHeader>
                <DCardBody>
                    <div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-10">
                                <label><small>Particularidade</small></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={particularity}
                                    onChange={e => setParticularity(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <button className="btn btn-primary shadow mt-4 ms-1" onClick={handleAddParticularity}>
                                    <i className="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group mb-3 col-md-12" hidden={particularitiesList.length === 0}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Particularidade</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            particularitiesList.map((particularityItem) => {
                                                return (
                                                    <tr key={particularityItem.id}>
                                                        <td>{particularityItem.particularity}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-info shadow sharp ms-1"
                                                                    onClick={() => handleEditParticularity(particularityItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger shadow sharp ms-1"
                                                                    onClick={() => handleDeleteParticularity(particularityItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </DCardBody>
            </DCard>
        </>
    )
}

ParticularityCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

ParticularityCard.defaultProps = {
    particularitiesListParm: []
};

export default ParticularityCard;