import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    FormCheck,
    Modal,
    Tab,
    Tabs
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import Select from "react-select";
import InputMask from "react-input-mask";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import { cpf } from "cpf-cnpj-validator";
import dayjs from "dayjs";

import { useFiles } from "../../../context/files";
import UploadFile from "../../components/UploadFile/UploadFile";

import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DAddressForm from "../../components/DAddressForm/DAddressForm";
import DFormText from "../../components/DFormText/DFormText";

import api from "../../../services/api";
import { states, healthPlanAccommodation } from "../../../enum/enums";

import MaskedInput from "react-maskedinput";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import { createLog } from "../../../services/logService";
registerLocale("ptBR", ptBR);

const PacientForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingPacientForm, setLoadingPacientForm] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Salvando Paciente...");

    const [name, setName] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [birthDate, setBirthDate] = useState(dayjs().toDate());
    const [gender, setGender] = useState("");
    const [birthPlace, setBirthPlace] = useState("");
    const [birthState, setBirthState] = useState("");
    const [occupation, setOccupation] = useState("");
    const [deceased, setDeceased] = useState(false);
    const [healthPlan, setHealthPlan] = useState("");
    const [healthPlansList, setHealthPlansList] = useState([]);
    const [weight, setWeight] = useState("0");
    const [height, setHeight] = useState("0");
    const [imc, setImc] = useState(0);
    const [createDate, setCreateDate] = useState("");
    const [addressInfo, setAddressInfo] = useState({});
    const [contact0Name, setContact0Name] = useState("");
    const [contact0Email, setContact0Email] = useState("");
    const [contact0HomePhone, setContact0HomePhone] = useState("");
    const [contact0ComercialPhone, setContact0ComercialPhone] = useState("");
    const [contact0CellPhone, setContact0CellPhone] = useState("");
    const [contact1Name, setContact1Name] = useState("");
    const [contact1Email, setContact1Email] = useState("");
    const [contact1HomePhone, setContact1HomePhone] = useState("");
    const [contact1ComercialPhone, setContact1ComercialPhone] = useState("");
    const [contact1CellPhone, setContact1CellPhone] = useState("");
    const [observation, setObservation] = useState("");
    const [active, setActive] = useState(true);
    const [accommodation, setAccommodation] = useState("");

    const { uploadedFiles, resetFiles, loadFiles } = useFiles();

    const [pacientId, setPacientId] = useState(undefined);

    const [invalidName, setInvalidName] = useState(false);
    const [invalidDocumentId, setInvalidDocumentId] = useState(false);
    const [invalidDocumentIdText, setInvalidDocumentIdText] = useState("Informe um CPF válido");
    const [invalidBirthDate, setInvalidBirthDate] = useState(false);
    const [invalidGender, setInvalidGender] = useState(false);
    const [invalidBirthPlace, setInvalidBirthPlace] = useState(false);
    const [invalidBirthState, setInvalidBirthState] = useState(false);
    const [invalidHealthPlan, setInvalidHealthPlan] = useState(false);
    const [invalidAccommodation, setInvalidAccommodation] = useState(false);
    const [invalidWeight, setInvalidWeight] = useState(false);
    const [invalidHeight, setInvalidHeight] = useState(false);
    const [invalidContact0, setInvalidContact0] = useState(false);
    const [invalidContact1, setInvalidContact1] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o registro");

    useImperativeHandle(ref, () => ({
        async openModal(mode, pacientInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            resetFields();
            resetFiles();

            getHealthPlans();

            if (mode === "update") {
                setIsUpdate(true);
                await loadPacient(pacientInfo);
            }
        },
    }));

    async function loadPacient(pacientInfo) {
        setLoadingMessage("Recuperando informações...");
        setLoadingPacientForm(true);
        setIsUpdate(true);

        loadFiles(pacientInfo.files);

        setPacientId(pacientInfo._id);
        setActive(pacientInfo.status);
        setName(pacientInfo.name);
        setDocumentId(pacientInfo.document_id);
        setBirthDate(dayjs(pacientInfo.birth_date).toDate());
        setGender({
            value: pacientInfo.gender,
            label: (pacientInfo.gender === "M" ? "Masculino" : "Feminino")
        });
        setBirthPlace(pacientInfo.birth_place);

        const birthStateItem = states.find(x => x.value === pacientInfo.birth_state);
        setBirthState(birthStateItem);
        setOccupation(pacientInfo.occupation);
        setDeceased(pacientInfo.deceased);
        setCreateDate(dayjs(pacientInfo.createdAt).format("DD/MM/YYYY"));
        setObservation(pacientInfo.observation);

        if (pacientInfo.health_plan_id) {
            setHealthPlan({
                value: pacientInfo.health_plan_id,
                label: pacientInfo.health_plan_name
            });
        }

        const accommodationItem = healthPlanAccommodation.find(x => x.value === pacientInfo.health_plan_accommodation);
        setAccommodation(accommodationItem);

        const weightFormatted = formatValue({
            value: pacientInfo.weight.toString(),
            groupSeparator: ".",
            decimalSeparator: ","
        });
        const heightFormatted = formatValue({
            value: pacientInfo.height.toString(),
            groupSeparator: ".",
            decimalSeparator: ","
        })

        setWeight(weightFormatted);
        setHeight(heightFormatted);

        calculateImc(weightFormatted, heightFormatted);

        if (pacientInfo.addresses && pacientInfo.addresses.length > 0) {
            setAddressInfo({
                zipCode: pacientInfo.addresses[0].zip_code,
                address: pacientInfo.addresses[0].address,
                addressNumber: pacientInfo.addresses[0].address_number,
                complement: pacientInfo.addresses[0].complement ?? "",
                district: pacientInfo.addresses[0].district ?? "",
                city: pacientInfo.addresses[0].city ?? "",
                addressState: pacientInfo.addresses[0].state
            });
        }

        if (pacientInfo.contacts) {
            for (let indexContacts = 0; indexContacts < pacientInfo.contacts.length; indexContacts++) {
                if (pacientInfo.contacts[indexContacts].type === "personal") {
                    setContact0Name(pacientInfo.contacts[indexContacts].name);
                    setContact0Email(pacientInfo.contacts[indexContacts].email);

                    for (let indexPhone = 0; indexPhone < pacientInfo.contacts[indexContacts].phones.length; indexPhone++) {
                        const phoneReturned = pacientInfo.contacts[indexContacts].phones[indexPhone].phone;

                        if (pacientInfo.contacts[indexContacts].phones[indexPhone].type === "home") {
                            setContact0HomePhone(phoneReturned);
                        }
                        if (pacientInfo.contacts[indexContacts].phones[indexPhone].type === "comercial") {
                            setContact0ComercialPhone(phoneReturned);
                        }

                        if (pacientInfo.contacts[indexContacts].phones[indexPhone].type === "cell") {
                            setContact0CellPhone(phoneReturned);
                        }
                    }
                } else if (pacientInfo.contacts[indexContacts].type === "contact") {
                    setContact1Name(pacientInfo.contacts[indexContacts].name);
                    setContact1Email(pacientInfo.contacts[indexContacts].email);

                    for (let indexPhone = 0; indexPhone < pacientInfo.contacts[indexContacts].phones.length; indexPhone++) {
                        const phoneReturned = pacientInfo.contacts[indexContacts].phones[indexPhone].phone;

                        if (pacientInfo.contacts[indexContacts].phones[indexPhone].type === "home") {
                            setContact1HomePhone(phoneReturned);
                        }
                        if (pacientInfo.contacts[indexContacts].phones[indexPhone].type === "comercial") {
                            setContact1ComercialPhone(phoneReturned);
                        }

                        if (pacientInfo.contacts[indexContacts].phones[indexPhone].type === "cell") {
                            setContact1CellPhone(phoneReturned);
                        }
                    }
                }
            }
        }

        setLoadingPacientForm(false);
    }

    async function getHealthPlans() {
        await api.get("/health-plans?offset=1&limit=1000")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    let healthPlansReturned = [];
                    for (let i = 0; i < response.data.healthPlans.length; i++) {
                        healthPlansReturned.push({
                            value: response.data.healthPlans[i]._id,
                            label: response.data.healthPlans[i].name
                        })
                    }
                    setHealthPlansList(healthPlansReturned);
                }
            })
    }

    async function handleSavePacient(e) {
        e.preventDefault();

        setLoadingPacientForm(true);

        setInvalidName(false);
        setInvalidBirthDate(false);
        setInvalidGender(false);
        setInvalidBirthPlace(false);
        setInvalidBirthState(false);
        setInvalidHealthPlan(false);
        setInvalidAccommodation(false);
        setInvalidWeight(false);
        setInvalidHeight(false);
        setInvalidContact0(false);
        setInvalidContact1(false);

        let hasError = false;

        if (name === "") {
            setInvalidName(true);
            hasError = true;
        }

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

        if (!isUpdate && (invalidDocumentId || documentUnformatted.trim() === "")) {
            setInvalidDocumentId(true);
            hasError = true;
        }

        if (!birthDate) {
            setInvalidBirthDate(true);
            hasError = true;
        } else {
            if (birthDate > dayjs().toDate()) {
                setInvalidBirthDate(true);
                hasError = true;
            }
        }

        if (gender === "") {
            setInvalidGender(true);
            hasError = true;
        }

        if (birthPlace === "") {
            setInvalidBirthPlace(true);
            hasError = true;
        }

        if (birthState === "") {
            setInvalidBirthState(true);
            hasError = true;
        }

        if (healthPlan === "") {
            setInvalidHealthPlan(true);
            hasError = true;
        }

        if (accommodation === "") {
            setInvalidAccommodation(true);
            hasError = true;
        }

        if (weight === "0") {
            setInvalidWeight(true);
            hasError = true;
        }

        if (height === "0") {
            setInvalidHeight(true);
            hasError = true;
        }

        const weightInNumber = +(weight.replace(/[.]/g, "").replace(",", "."));
        const heightInNumber = +(height.replace(/[.]/g, "").replace(",", "."));

        let hasContactError = false;

        const contact0HomePhoneUnformatted = contact0HomePhone.replace(/[()-]/g, "").replace(" ", "");
        const contact0ComercialPhoneUnformatted = contact0ComercialPhone.replace(/[()-]/g, "").replace(" ", "");
        const contact0CellPhoneUnformatted = contact0CellPhone.replace(/[()-]/g, "").replace(" ", "");
        // if (contact0HomePhoneUnformatted.trim() === "" && contact0ComercialPhoneUnformatted.trim() === "" && contact0CellPhoneUnformatted.trim() === "") {
        //     setInvalidContact0(true);
        //     hasError = true;
        //     hasContactError = true;
        // }

        const contact1HomePhoneUnformatted = contact1HomePhone.replace(/[()-]/g, "").replace(" ", "");
        const contact1ComercialPhoneUnformatted = contact1ComercialPhone.replace(/[()-]/g, "").replace(" ", "");
        const contact1CellPhoneUnformatted = contact1CellPhone.replace(/[()-]/g, "").replace(" ", "");
        // if (contact1HomePhoneUnformatted.trim() === "" && contact1ComercialPhoneUnformatted.trim() === "" && contact1CellPhoneUnformatted.trim() === "") {
        //     setInvalidContact1(true);
        //     hasError = true;
        //     hasContactError = true;
        // }

        if (hasContactError) {
            swal("Existem campos obrigatórios não preenchidos!", { icon: "warning" });
        }

        if (!hasError) {
            let pacient = {
                document_id: documentUnformatted,
                name,
                birth_date: birthDate,
                gender: gender.value,
                birth_place: birthPlace,
                birth_state: birthState.value,
                occupation,
                deceased,
                health_plan_id: healthPlan.value,
                health_plan_name: healthPlan.label,
                health_plan_accommodation: accommodation.value,
                weight: weightInNumber,
                height: heightInNumber,
                observation,
                addresses: [],
                contacts: [],
                files: [],
                status: active
            };

            if (addressInfo) {
                if (addressInfo.zipCode?.trim().length === 8) {
                    pacient.addresses.push({
                        zip_code: addressInfo.zipCode.trim(),
                        address: addressInfo.address,
                        address_number: addressInfo.addressNumber,
                        complement: addressInfo.complement ?? "",
                        district: addressInfo.district,
                        city: addressInfo.city,
                        state: addressInfo.addressState
                    });
                }
            }

            if (contact0Email !== "" || contact0HomePhoneUnformatted.trim() !== "" || contact0ComercialPhoneUnformatted.trim() !== "" || contact0CellPhoneUnformatted.trim() !== "") {
                let newContact0 = {
                    type: "personal",
                    name: contact0Name,
                    email: contact0Email,
                    phones: []
                };

                if (contact0HomePhoneUnformatted) {
                    newContact0.phones.push({
                        type: "home",
                        phone: contact0HomePhoneUnformatted
                    });
                }

                if (contact0ComercialPhoneUnformatted) {
                    newContact0.phones.push({
                        type: "comercial",
                        phone: contact0ComercialPhoneUnformatted
                    });
                }

                if (contact0CellPhoneUnformatted) {
                    newContact0.phones.push({
                        type: "cell",
                        phone: contact0CellPhoneUnformatted
                    });
                }

                pacient.contacts.push(newContact0);
            }

            if (uploadedFiles) {
                for (let i = 0; i < uploadedFiles.length; i++) {
                    pacient.files.push({
                        filename: uploadedFiles[i].name,
                        original_name: uploadedFiles[i].originalName,
                        size: uploadedFiles[i].file.size
                    })
                }
            }

            if (contact1HomePhoneUnformatted.trim() !== "" || contact1ComercialPhoneUnformatted.trim() !== "" || contact1CellPhoneUnformatted.trim() !== "") {
                let newContact1 = {
                    type: "contact",
                    name: contact1Name,
                    email: contact1Email,
                    phones: []
                };

                if (contact1HomePhoneUnformatted) {
                    newContact1.phones.push({
                        type: "home",
                        phone: contact1HomePhoneUnformatted
                    });
                }

                if (contact1ComercialPhoneUnformatted) {
                    newContact1.phones.push({
                        type: "comercial",
                        phone: contact1ComercialPhoneUnformatted
                    });
                }

                if (contact1CellPhoneUnformatted) {
                    newContact1.phones.push({
                        type: "cell",
                        phone: contact1CellPhoneUnformatted
                    });
                }

                pacient.contacts.push(newContact1);
            }

            if (!isUpdate) {
                try {
                    await api.post("/pacients", pacient)
                        .then(response => {
                            if (response.status === 201) {
                                createLog({
                                    routine: "pacient",
                                    action: "create",
                                    type: "success",
                                    message: `Paciente ${pacient.name} incluído`,
                                    jsonOrigin: JSON.stringify(pacient),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingPacientForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data?.error === "ALREADY_EXISTS") {
                        setErrorMessage("Paciente já cadastrado");
                    }

                    createLog({
                        routine: "pacient",
                        action: "create",
                        type: "error",
                        message: `Paciente ${pacient.name} não incluído`,
                        jsonOrigin: JSON.stringify(pacient),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            } else {
                try {
                    await api.put(`/pacients/${pacientId}`, pacient)
                        .then(response => {
                            if (response.status === 200) {
                                createLog({
                                    routine: "pacient",
                                    action: "update",
                                    type: "success",
                                    message: `Paciente ${pacient.name} alterado`,
                                    jsonOrigin: JSON.stringify(pacient),
                                    jsonReturn: JSON.stringify(response)
                                });

                                setLoadingPacientForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "pacient",
                        action: "update",
                        type: "error",
                        message: `Paciente ${pacient.name} não alterado`,
                        jsonOrigin: JSON.stringify(pacient),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            }
        }

        setLoadingPacientForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Paciente ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setPacientId(undefined);
        setName("");
        setDocumentId("");
        setBirthDate(dayjs().toDate());
        setGender("");
        setBirthPlace("");
        setBirthState("");
        setOccupation("");
        setDeceased(false);
        setCreateDate("");
        setHealthPlan("");
        setAccommodation("")
        setWeight("0");
        setHeight("0");
        setImc(0);
        setAddressInfo({});
        setContact0Name("");
        setContact0Email("");
        setContact0HomePhone("");
        setContact0ComercialPhone("");
        setContact0CellPhone("");
        setContact1Name("");
        setContact1Email("");
        setContact1HomePhone("");
        setContact1ComercialPhone("");
        setContact1CellPhone("");
        setObservation("");
        setActive(true);

        setInvalidName(false);
        setInvalidDocumentId(false);
        setInvalidDocumentIdText("Informe um CPF válido");
        setInvalidBirthDate(false);
        setInvalidGender(false);
        setInvalidBirthPlace(false);
        setInvalidBirthState(false);
        setInvalidHealthPlan(false);
        setInvalidAccommodation(false);
        setInvalidWeight(false);
        setInvalidHeight(false);
        setInvalidContact0(false);
        setInvalidContact1(false);

        setLoadingMessage("Salvando Paciente...");
        setErrorMessage("Ocorreu um problema ao salvar o registro");
    }

    async function maskDocumentField() {
        setInvalidDocumentId(false);

        const documentUnformatted = documentId.replace(/[.]/g, "").replace("/", "").replace("-", "");

        if (documentUnformatted.length > 0) {
            if (!cpf.isValid(documentUnformatted)) {
                setInvalidDocumentId(true);
            } else {
                if (!isUpdate) {
                    await api.get(`/pacients/document/${documentUnformatted}`)
                        .then(response => {
                            if (response.status === 200) {
                                setInvalidDocumentId(true);
                                setInvalidDocumentIdText("CPF já cadastrado");
                            }
                        });
                }
            }
        } else {
            setInvalidDocumentId(true);
        }
    }

    function validBirthDate(date) {
        setBirthDate(date);

        setInvalidBirthDate(date > dayjs().toDate());
    }

    function calculateImc(weightInfo = weight, heightInfo = height) {
        let imcCalculated = 0;

        const weightInNumber = +(weightInfo.replace(/[.]/g, "").replace(",", "."));
        const heightInNumber = +(heightInfo.replace(/[.]/g, "").replace(",", "."));

        if (heightInNumber > 0) {
            imcCalculated = weightInNumber / (heightInNumber ** 2);
        }

        setImc(imcCalculated.toFixed(2).toLocaleString('pt-br'));
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Novo " : "Alterar ")}Paciente
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <Tabs
                                        defaultActiveKey="personal"
                                        id="fill-tab-example"
                                        className="mb-3 basic-list-group"
                                        fill
                                    >
                                        <Tab eventKey="personal" title="Dados Pessoais">
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-7">
                                                    <label>Nome</label>
                                                    <input
                                                        autoFocus
                                                        type="text"
                                                        className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                                        value={name}
                                                        onChange={e => setName(e.target.value.toUpperCase())}
                                                    />
                                                    <DFormText hidden={!invalidName} color="danger">Informe o nome do paciente</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>CPF</label>
                                                    <InputMask
                                                        mask="999.999.999-99"
                                                        maskChar=" "
                                                        value={documentId}
                                                        onChange={(event) => {
                                                            setDocumentId(event?.target.value);
                                                        }}
                                                        onBlur={() => { maskDocumentField() }}
                                                        alwaysShowMask={true}
                                                        disabled={isUpdate}
                                                    >
                                                        {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidDocumentId ? "is-invalid" : "")}`} disabled={isUpdate} />}
                                                    </InputMask>
                                                    <DFormText hidden={!invalidDocumentId} color="danger">{invalidDocumentIdText}</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Data de Nascimento</label>
                                                    <br />
                                                    <DatePicker
                                                        selected={birthDate}
                                                        onChange={(date) => validBirthDate(date)}
                                                        locale="ptBR"
                                                        dateFormat="P"
                                                        className={`form-control ${(invalidBirthDate ? " is-invalid" : "")}`}
                                                        customInput={
                                                            <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" />
                                                        }
                                                    />
                                                    <DFormText hidden={!invalidBirthDate} color="danger">Informe uma data válida</DFormText>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Sexo</label>
                                                    <Select
                                                        className={`${(invalidGender ? "form-control is-invalid" : "")}`}
                                                        defaultValue={gender}
                                                        onChange={setGender}
                                                        options={[
                                                            { value: "M", label: "Masculino" },
                                                            { value: "F", label: "Feminino" }
                                                        ]}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: 45,
                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                            }),
                                                        }}
                                                        placeholder="Selecione..."
                                                    />
                                                    <DFormText hidden={!invalidGender} color="danger">Campo obrigatório</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-6">
                                                    <label>Naturalidade</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${(invalidBirthPlace ? "is-invalid" : "")}`}
                                                        value={birthPlace}
                                                        onChange={e => setBirthPlace(e.target.value)}
                                                    />
                                                    <DFormText hidden={!invalidBirthPlace} color="danger">Campo obrigatório</DFormText>
                                                </div>

                                                <div className="form-group mb-3 col-md-3">
                                                    <label>UF</label>
                                                    <Select
                                                        className={`${(invalidBirthState ? "form-control is-invalid" : "")}`}
                                                        defaultValue={birthState}
                                                        value={birthState}
                                                        onChange={(e) => setBirthState(e)}
                                                        options={states}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: 45,
                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                            }),
                                                        }}
                                                        placeholder="Selecione..."
                                                    />
                                                    <DFormText hidden={!invalidBirthState} color="danger">Campo obrigatório</DFormText>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Profissão</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={occupation}
                                                        onChange={e => setOccupation(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-4">
                                                    <label>Convênio</label>
                                                    <Select
                                                        className={`${(invalidHealthPlan ? "form-control is-invalid" : "")}`}
                                                        defaultValue={healthPlan}
                                                        value={healthPlan}
                                                        onChange={(e) => setHealthPlan(e)}
                                                        options={healthPlansList}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: 45,
                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                            }),
                                                        }}
                                                        placeholder="Selecione..."
                                                    />
                                                    <DFormText hidden={!invalidHealthPlan} color="danger">Campo obrigatório</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label for="acomodacao">Acomodação</label>
                                                    <Select
                                                        className={`${(invalidAccommodation ? "form-control is-invalid" : "")}`}
                                                        defaultValue={accommodation}
                                                        value={accommodation}
                                                        onChange={(e) => setAccommodation(e)}
                                                        options={healthPlanAccommodation}
                                                        styles={{
                                                            control: (baseStyles, state) => ({
                                                                ...baseStyles,
                                                                height: 45,
                                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                            }),
                                                        }}
                                                        placeholder="Selecione..."
                                                    />
                                                    <DFormText hidden={!invalidAccommodation} color="danger">Campo obrigatório</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Óbito</label>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked={deceased}
                                                            onChange={() => setDeceased(!deceased)}
                                                        />
                                                        <label className="form-check-label">Sim</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Data de Cadastro</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={createDate}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Peso (kg)</label>
                                                    <CurrencyInput
                                                        className={`form-control text-end ${(invalidWeight ? "is-invalid" : "")}`}
                                                        id="input-weight"
                                                        value={weight}
                                                        maxLength={5}
                                                        decimalsLimit={1}
                                                        onValueChange={(value) => setWeight(value)}
                                                        intlConfig={{ locale: "pt-BR" }}
                                                        onBlur={() => calculateImc()}
                                                    />
                                                    <DFormText hidden={!invalidWeight} color="danger">Campo obrigatório</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>Altura (m)</label>
                                                    <CurrencyInput
                                                        className={`form-control text-end ${(invalidHeight ? "is-invalid" : "")}`}
                                                        id="input-height"
                                                        value={height}
                                                        maxLength={4}
                                                        decimalsLimit={2}
                                                        onValueChange={(value) => setHeight(value)}
                                                        intlConfig={{ locale: "pt-BR" }}
                                                        onBlur={() => calculateImc()}
                                                    />
                                                    <DFormText hidden={!invalidHeight} color="danger">Campo obrigatório</DFormText>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <label>IMC</label>
                                                    <input
                                                        type="text"
                                                        className="form-control text-end"
                                                        value={imc}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <label>Observações</label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control"
                                                        value={observation}
                                                        onChange={e => setObservation(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <br />

                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <DAddressForm
                                                        addressInfoParm={addressInfo}
                                                        onChangeAddress={setAddressInfo}
                                                    />
                                                </div>
                                            </div>

                                            <br />

                                            <h5>Contato - Pessoal</h5>
                                            <DFormText hidden={!invalidContact0} color="danger">Informe ao menos um número de telefone</DFormText>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-6">
                                                    <label>Email</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={contact0Email}
                                                        onChange={e => setContact0Email(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Celular</label>
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        maskChar=" "
                                                        value={contact0CellPhone}
                                                        onChange={(event) => {
                                                            setContact0CellPhone(event?.target.value);
                                                        }}
                                                        alwaysShowMask={true}
                                                    >
                                                        {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidContact0 ? "is-invalid" : "")}`} />}
                                                    </InputMask>
                                                </div>
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Residencial</label>
                                                    <InputMask
                                                        mask="(99) 9999-9999"
                                                        maskChar=" "
                                                        value={contact0HomePhone}
                                                        onChange={(event) => {
                                                            setContact0HomePhone(event?.target.value);
                                                        }}
                                                        alwaysShowMask={true}
                                                    >
                                                        {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidContact0 ? "is-invalid" : "")}`} />}
                                                    </InputMask>
                                                </div>
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Comercial</label>
                                                    <InputMask
                                                        mask="(99) 9999-9999"
                                                        maskChar=" "
                                                        value={contact0ComercialPhone}
                                                        onChange={(event) => {
                                                            setContact0ComercialPhone(event?.target.value);
                                                        }}
                                                        alwaysShowMask={true}
                                                    >
                                                        {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidContact0 ? "is-invalid" : "")}`} />}
                                                    </InputMask>
                                                </div>
                                            </div>

                                            <h5>Contato - Emergência</h5>
                                            <DFormText hidden={!invalidContact1} color="danger">Informe um contato de emergência</DFormText>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-6">
                                                    <label>Nome</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${(invalidContact1 ? "is-invalid" : "")}`}
                                                        value={contact1Name}
                                                        onChange={e => setContact1Name(e.target.value)}
                                                    />
                                                </div>
                                                <div className="form-group mb-3 col-md-6">
                                                    <label>Email</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${(invalidContact1 ? "is-invalid" : "")}`}
                                                        value={contact1Email}
                                                        onChange={e => setContact1Email(e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Celular</label>
                                                    <InputMask
                                                        mask="(99) 99999-9999"
                                                        maskChar=" "
                                                        value={contact1CellPhone}
                                                        onChange={(event) => {
                                                            setContact1CellPhone(event?.target.value);
                                                        }}
                                                        alwaysShowMask={true}
                                                    >
                                                        {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidContact1 ? "is-invalid" : "")}`} />}
                                                    </InputMask>
                                                </div>
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Residencial</label>
                                                    <InputMask
                                                        mask="(99) 9999-9999"
                                                        maskChar=" "
                                                        value={contact1HomePhone}
                                                        onChange={(event) => {
                                                            setContact1HomePhone(event?.target.value);
                                                        }}
                                                        alwaysShowMask={true}
                                                    >
                                                        {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidContact1 ? "is-invalid" : "")}`} />}
                                                    </InputMask>
                                                </div>
                                                <div className="form-group mb-3 col-md-2">
                                                    <label>Comercial</label>
                                                    <InputMask
                                                        mask="(99) 9999-9999"
                                                        maskChar=" "
                                                        value={contact1ComercialPhone}
                                                        onChange={(event) => {
                                                            setContact1ComercialPhone(event?.target.value);
                                                        }}
                                                        alwaysShowMask={true}
                                                    >
                                                        {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidContact1 ? "is-invalid" : "")}`} />}
                                                    </InputMask>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="documents" title="Documentos">
                                            <UploadFile />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={() => setModalBox(false)}
                        >
                            Fechar
                        </Button>
                        <Button variant="success btn-rounded" onClick={handleSavePacient}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingPacientForm}
                message={loadingMessage}
                onClose={setLoadingPacientForm}
            />
        </>
    );
});

export default PacientForm;
