import dayjs from "dayjs";

export function isAuthenticated() {
    if (localStorage.getItem("xLcxRem")) {
        if (localStorage.getItem("xLcxTk")) return true;
    } else {
        if (sessionStorage.getItem("xLcxTk")) return true;
    }
    return false;
}

export function hasAccess(group, item) {
    let userInfo = undefined;

    if (localStorage.getItem("xLcxRem")) {
        if (localStorage.getItem("xLcxUsr")) {
            userInfo = JSON.parse(localStorage.getItem("xLcxUsr"));
        }
    } else {
        if (sessionStorage.getItem("xLcxUsr")) {
            userInfo = JSON.parse(sessionStorage.getItem("xLcxUsr"));
        }
    }

    if (userInfo) {
        if (userInfo.username === "admin") {
            return true;
        }
        
        const userRole = userInfo.roles.find(x => x.feature === group && x.function === item);
        if (userRole) {
            return userRole.access;
        }
    }

    return false;
}

export function minutesToTime(minutesToConvert) {
    const hours = Math.floor(minutesToConvert / 60);
    const minutes = minutesToConvert % 60;
    return String(hours).padStart(2, "0") + ":" + String(minutes).padStart(2, "0");
}

export function ageInString(dateToConvert) {
    const difAge = dayjs().diff(dateToConvert, "year", true);
    const yearAge = Math.floor(difAge);
    const monthAge = Math.floor((difAge - yearAge) * 12);
    return `${yearAge} anos e ${monthAge} meses`;
}

export function getScheduleStatus(status) {
    let statusColor = "warning";
    let statusLabel = "Aguardando Liberação";

    switch (status) {
        case "sch":
            statusColor = "info";
            statusLabel = "Agendamento Confirmado";
            break;
        case "can":
            statusColor = "danger";
            statusLabel = "Agendamento Cancelado";
            break;
        case "not":
            statusColor = "dark";
            statusLabel = "Procedimento Não Realizado";
            break;
        case "done":
            statusColor = "success";
            statusLabel = "Procedimento Realizado";
            break;
        default:
            statusColor = "warning";
            statusLabel = "Aguardando Liberação";
            break;
    }

    return {
        color: statusColor,
        label: statusLabel
    };
}

export function convertStringToPrint(text) {
    const convertedString = text.replace(/<p>/g, "")
        .replace(/<p class="ql-align-justify">/gi, "")
        .replace(/<\/p>/gi, "\n")
        .replace(/<u>/gi, "\n")
        .replace(/<\/u>/gi, "\n")
        .replace(/<br>/gi, "\n")
        .replace(/&nbsp;/gi, "")
        .replace(/<strong>/gi, "")
        .replace(/<\/strong>/gi, "")
        .replace(/&gt;/gi, ">");

    return convertedString;
}

export function getMonthTranslated(month, type = "long") {
    let description = "";

    if (month === 1) {
        description = (type === "short" ? "Jan" : "Janeiro");
    } else if (month === 2) {
        description = (type === "short" ? "Fev" : "Fevereiro");
    } else if (month === 3) {
        description = (type === "short" ? "Mar" : "Março");
    } else if (month === 4) {
        description = (type === "short" ? "Abr" : "Abril");
    } else if (month === 5) {
        description = (type === "short" ? "Mai" : "Maio");
    } else if (month === 6) {
        description = (type === "short" ? "Jun" : "Junho");
    } else if (month === 7) {
        description = (type === "short" ? "Jul" : "Julho");
    } else if (month === 8) {
        description = (type === "short" ? "Ago" : "Agosto");
    } else if (month === 9) {
        description = (type === "short" ? "Set" : "Setembro");
    } else if (month === 10) {
        description = (type === "short" ? "Out" : "Outubro");
    } else if (month === 11) {
        description = (type === "short" ? "Nov" : "Novembro");
    } else if (month === 12) {
        description = (type === "short" ? "Dez" : "Dezembro");
    }

    return description;
}

export function checkTermsAndPrivacy(field) {
    let userInfo = undefined;

    if (localStorage.getItem("xLcxRem")) {
        if (localStorage.getItem("xLcxUsr")) {
            userInfo = JSON.parse(localStorage.getItem("xLcxUsr"));
        }
    } else {
        if (sessionStorage.getItem("xLcxUsr")) {
            userInfo = JSON.parse(sessionStorage.getItem("xLcxUsr"));
        }
    }

    if (userInfo) {
        if (field === "terms") {
            if (userInfo.terms) {
                return true;
            } else {
                return false;
            }
        } else {
            if (userInfo.privacy) {
                return true;
            } else {
                return false;
            }
        }
    }

    return false;
}


export function logoutApp() {
    if (localStorage.getItem("xLcxRem")) {
        localStorage.removeItem("xLcxRem");
        localStorage.removeItem("xLcxTk");
        localStorage.removeItem("xLcxTkr");
        localStorage.removeItem("xLcxUsr");
    } else {
        sessionStorage.removeItem("xLcxTk");
        sessionStorage.removeItem("xLcxTkr");
        sessionStorage.removeItem("xLcxUsr");
    }
}