import { toast } from "react-toastify";
import {
    loginConfirmedAction,
    logout,
} from '../store/actions/AuthActions';
import api from './api';

export function login(username, password) {
    const postData = {
        username,
        password,
    };
    return api.post("/sessions", postData);
}

export function formatError(errorResponse) {
    switch (errorResponse.error) {
        case 'USER_INCORRECT':
            // swal("Não autorizado", "Usuário ou senha inválidos!", "error",{ button: "Fechar",});
            toast.error("❌ Usuário ou senha inválidos!", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            break;
        default:
            return '';
    }
}

export function saveTokenInLocalStorage(tokenDetails) {
    localStorage.setItem("xLcxRem", "true");
    localStorage.setItem("xLcxTk", tokenDetails.token);
    localStorage.setItem("xLcxTkr", tokenDetails.refresh_token);
    localStorage.setItem("xLcxUsr", JSON.stringify(tokenDetails.user));
}

export function saveTokenInSessionStorage(tokenDetails) {
    sessionStorage.setItem("xLcxTk", tokenDetails.token);
    sessionStorage.setItem("xLcxTkr", tokenDetails.refresh_token);
    sessionStorage.setItem("xLcxUsr", JSON.stringify(tokenDetails.user));
}

export function runLogoutTimer(dispatch, timer, history) {
    setTimeout(() => {
        dispatch(logout(history));
    }, timer);
}

export function checkAutoLogin(dispatch, history) {
    let token = undefined;
    if (localStorage.getItem("xLcxRem")) {
        token = localStorage.getItem('xLcxTk');
    } else {
        token = sessionStorage.getItem('xLcxTk');
    }
    if (!token) {
        dispatch(logout(history));
        return;
    }

    dispatch(loginConfirmedAction(token));
}
