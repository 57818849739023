import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, Table, Row, Col } from "react-bootstrap";
import Select from "react-select";

import PreScheduleForm from "./PreScheduleForm";
import PacientSelectForm from "../Pacient/PacientSelectForm";

import api from "../../../services/api";
import { checkTermsAndPrivacy, hasAccess, isAuthenticated } from "../../../services/utils";
import { useHistory } from "react-router-dom";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs";
import Terms from "../UserAgreement/Terms";
import Privacy from "../UserAgreement/Privacy";

registerLocale("ptBR", ptBR);

const SCHEDULE_STATUS = [
    {
        value: "pre",
        label: "Aguardando Liberação"
    },
    {
        value: "sch",
        label: "Agendamento Confirmado"
    },
    {
        value: "can",
        label: "Agendamento Cancelado"
    },
    {
        value: "not",
        label: "Procedimento Não Realizado"
    },
    {
        value: "done",
        label: "Procedimento Realizado"
    }
];

const PreSchedule = () => {
    const childRef = useRef();
    const termsRef = useRef();
    const privacyRef = useRef();
    const pacientSelectForm = useRef();
    const [scheduleItems, setScheduleItems] = useState([]);

    const [doctorsList, setDoctorsList] = useState([]);
    const [healthPlansList, setHealthPlansList] = useState([]);
    const [hospitalsList, setHospitalsList] = useState([]);

    const [hideFilter, setHideFilter] = useState(true);
    const [filterDoctor, setFilterDoctor] = useState("");
    const [filterHealthPlan, setFilterHealthPlan] = useState("");
    const [filterHospital, setFilterHospital] = useState("");
    const [filterDateFrom, setFilterDateFrom] = useState(null);
    const [filterDateTo, setFilterDateTo] = useState(null);
    const [filterStatus, setFilterStatus] = useState([{ value: "pre", label: "Aguardando Liberação" }]);

    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated) {
            history.push("/login");
        }

        if (!checkTermsAndPrivacy("terms")) {
            termsRef.current.openModal();
        }

        if (!checkTermsAndPrivacy("privacy")) {
            privacyRef.current.openModal();
        }

        getSchedule();
        getDoctors();
        getHealthPlans();
        getHospitals();
    }, [])

    async function getSchedule() {
        let filter = "";
        if (filterStatus.length > 0) {
            filter += "status=";
            for (let i = 0; i < filterStatus.length; i++) {
                if (i > 0) {
                    filter += ","
                }
                filter += filterStatus[i].value;
            }
            filter += "&"
        }

        if (filterDoctor) {
            filter += `doctorId=${filterDoctor.value}&`;
        }

        if (filterHealthPlan) {
            filter += `healthPlanId=${filterHealthPlan.value}&`;
        }

        if (filterHospital) {
            filter += `hospitalId=${filterHospital.value}&`;
        }

        if (filterDateFrom && filterDateTo) {
            const filterDateToUTC = dayjs(filterDateTo).add(1, "day");
            filter += `startDate=${dayjs(filterDateFrom).toISOString()}&endDate=${dayjs(filterDateToUTC).toISOString()}&`;
          }

        try {
            await api.get(`/schedules?orderBy=pacient&${filter}`)
                .then(response => {
                    if (response.status === 200) {
                        const schedulesReturned = response.data.schedules.map((schedule) => {
                            return { ...schedule, status: getStatus(schedule.status), originalStatus: schedule.status }
                        });

                        setScheduleItems(schedulesReturned);
                    }
                })
        } catch (err) {
            if (err.status === 401) {
                history.push("/login");
            }
        }
    }

    function getStatus(status) {
        let statusColor = "warning";
        let statusLabel = "Aguardando Liberação";

        switch (status) {
            case "sch":
                statusColor = "info";
                statusLabel = "Agendamento Confirmado";
                break;
            case "can":
                statusColor = "danger";
                statusLabel = "Agendamento Cancelado";
                break;
            case "not":
                statusColor = "dark";
                statusLabel = "Procedimento Não Realizado";
                break;
            case "done":
                statusColor = "success";
                statusLabel = "Procedimento Realizado";
                break;
            default:
                statusColor = "warning";
                statusLabel = "Aguardando Liberação";
                break;
        }

        return {
            color: statusColor,
            label: statusLabel
        };
    }

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const doctorsReturned = response.data.doctors.map(doctorItem => {
                        return {
                            value: doctorItem._id,
                            label: doctorItem.name
                        }
                    });

                    setDoctorsList(doctorsReturned);
                }
            })
    }

    async function getHealthPlans() {
        await api.get("/health-plans?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const healthPlansReturned = response.data.healthPlans.map(healthPlanItem => {
                        return {
                            value: healthPlanItem._id,
                            label: healthPlanItem.name
                        }
                    });

                    setHealthPlansList(healthPlansReturned);
                }
            })
    }

    async function getHospitals() {
        await api.get("/hospitals?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const hospitalsReturned = response.data.hospitals.map(hospitalItem => {
                        return {
                            value: hospitalItem._id,
                            label: hospitalItem.name
                        }
                    });

                    setHospitalsList(hospitalsReturned);
                }
            })
    }

    function onCloseModal() {
        setScheduleItems([]);
        getSchedule();
    }

    function onCloseSelectPacient(pacientInfo) {
        if (pacientInfo) {
            childRef.current.openModal("new", pacientInfo);
        }
    }

    return (
        <>
            <Row hidden={hideFilter}>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Filtros</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="basic-form">
                                <div className="row" style={{ textAlign: "left" }}>
                                    <div className="form-group mb-3 col-md-12">
                                        <label for="status">Status</label>
                                        <Select
                                            value={filterStatus}
                                            closeMenuOnSelect={false}
                                            defaultValue={filterStatus}
                                            isMulti
                                            options={SCHEDULE_STATUS}
                                            placeholder="Selecione..."
                                            onChange={setFilterStatus}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Médico</label>
                                        <Select
                                            value={filterDoctor}
                                            defaultValue={filterDoctor}
                                            options={doctorsList}
                                            placeholder="Selecione..."
                                            onChange={setFilterDoctor}
                                            isClearable
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Convênio</label>
                                        <Select
                                            value={filterHealthPlan}
                                            defaultValue={filterHealthPlan}
                                            options={healthPlansList}
                                            placeholder="Selecione..."
                                            onChange={setFilterHealthPlan}
                                            isClearable
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-4">
                                        <label>Hospital</label>
                                        <Select
                                            value={filterHospital}
                                            defaultValue={filterHospital}
                                            options={hospitalsList}
                                            placeholder="Selecione..."
                                            onChange={setFilterHospital}
                                            isClearable
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    height: 45,
                                                    backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group mb-3 col-md-3">
                                        <label>Data Inicial</label>
                                        <br />
                                        <DatePicker
                                            selected={filterDateFrom}
                                            onChange={(date) => setFilterDateFrom(date)}
                                            locale="ptBR"
                                            dateFormat="P"
                                            className="form-control"
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-md-3">
                                        <label>Data Final</label>
                                        <br />
                                        <DatePicker
                                            selected={filterDateTo}
                                            onChange={(date) => setFilterDateTo(date)}
                                            locale="ptBR"
                                            dateFormat="P"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{ textAlign: "right" }}>
                                    <div className="form-group mb-12 col-md-12">
                                        <button className="btn btn-primary ms-1" onClick={getSchedule}>
                                            Filtrar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Pré-Agendamentos</Card.Title>
                            <div>
                                <button
                                    className="btn btn-primary ms-1"
                                    onClick={() => setHideFilter(!hideFilter)}
                                    title="Mostrar/esconder filtros"
                                >
                                    <i className="fas fa-filter"></i>
                                </button>
                                <button
                                    className="btn btn-primary ms-1"
                                    onClick={() => pacientSelectForm.current.openModal()}
                                    title="Incluir pré-agendamento"
                                    hidden={!hasAccess("preschedule", "create")}
                                >
                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                                        <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                                    </svg> Novo
                                </button>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Paciente</strong>
                                            </th>
                                            <th>
                                                <strong>Convênio</strong>
                                            </th>
                                            <th>
                                                <strong>Médico Responsável</strong>
                                            </th>
                                            <th>
                                                <strong>Encaminhador</strong>
                                            </th>
                                            <th>
                                                <strong>Situação</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            scheduleItems.map((schedule) => {
                                                return (
                                                    <tr
                                                        key={schedule._id}
                                                        onClick={() => childRef.current.openModal("update", null, schedule)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <td>
                                                            <div className="d-flex align-items-left">
                                                                <span className="w-space-no">{schedule.pacient_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-left">
                                                                <span className="w-space-no">{schedule.health_plan_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-left">
                                                                <span className="w-space-no">{schedule.main_doctor_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-left">
                                                                <span className="w-space-no">{schedule.forward_doctor_name}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-left">
                                                                <Badge bg="" className={`badge-${schedule.status.color}`} >{schedule.status.label}</Badge>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <PreScheduleForm ref={childRef} onClose={onCloseModal} />

            <PacientSelectForm ref={pacientSelectForm} onClose={onCloseSelectPacient} />

            <Terms ref={termsRef} />

            <Privacy ref={privacyRef} />
        </>
    )
}

export default PreSchedule;