const states = [{
    value: "AC",
    label: "Acre"
},
{
    value: "AL",
    label: "Alagoas"
},
{
    value: "AM",
    label: "Amazonas"
},
{
    value: "AP",
    label: "Amapá"
},
{
    value: "BA",
    label: "Bahia"
},
{
    value: "CE",
    label: "Ceará"
},
{
    value: "DF",
    label: "Distrito Federal"
},
{
    value: "ES",
    label: "Espírito Santo"
},
{
    value: "GO",
    label: "Goiás"
},
{
    value: "MA",
    label: "Maranhão"
},
{
    value: "MG",
    label: "Minas Gerais"
},
{
    value: "MS",
    label: "Mato Grosso do Sul"
},
{
    value: "MT",
    label: "Mato Grosso"
},
{
    value: "PA",
    label: "Pará"
},
{
    value: "PB",
    label: "Paraíba"
},
{
    value: "PE",
    label: "Pernambuco"
},
{
    value: "PI",
    label: "Piauí"
},
{
    value: "PR",
    label: "Paraná"
},
{
    value: "RJ",
    label: "Rio de Janeiro"
},
{
    value: "RN",
    label: "Rio Grande do Norte"
},
{
    value: "RO",
    label: "Rondônia"
},
{
    value: "RR",
    label: "Roraima"
},
{
    value: "RS",
    label: "Rio Grande do Sul"
},
{
    value: "SC",
    label: "Santa Catarina"
},
{
    value: "SE",
    label: "Sergipe"
},
{
    value: "SP",
    label: "São Paulo"
},
{
    value: "TO",
    label: "Tocantins"
}];

const healthPlanAccommodation = [
    {
        value: "ENF",
        label: "Enfermaria"
    },
    {
        value: "APT",
        label: "Apartamento"
    }
];

const complexity = [
    {
        value: "NOR",
        label: "Convencional"
    },
    {
        value: "COM",
        label: "Complexa"
    }
];

const symptoms = [
    {
        value: "SIN",
        label: "Síncope"
    },
    {
        value: "SEM",
        label: "Sem sintomas"
    },
    {
        value: "PAR",
        label: "Parada cardíaca"
    },
    {
        value: "PAL",
        label: "Palidez"
    },
    {
        value: "SUD",
        label: "Sudorese"
    },
    {
        value: "NAU",
        label: "Náuseas"
    },
    {
        value: "ESC",
        label: "Escurecimento da vista"
    },
    {
        value: "TON",
        label: "Tontura"
    },
    {
        value: "FAD",
        label: "Fadiga"
    },
    {
        value: "DIS",
        label: "Dispnéia"
    },
    {
        value: "DOR",
        label: "Dor toráxica"
    },
    {
        value: "PAP",
        label: "Palpitação no pescoço"
    },
    {
        value: "PALP",
        label: "Palpitação precordial"
    },
    {
        value: "PRE",
        label: "Pré-Síncope"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const cardioVersions = [
    {
        value: "QUI",
        label: "Química"
    },
    {
        value: "ELE",
        label: "Elétrica"
    },
    {
        value: "NAO",
        label: "Não"
    }
];

const historyTimes = [
    {
        value: "MAI",
        label: "Maior que 5 Anos"
    },
    {
        value: "DE1",
        label: "De 1 a 5 Anos"
    },
    {
        value: "DE6",
        label: "De 6 Meses a 1 Ano"
    },
    {
        value: "MEN",
        label: "Menor que 6 Meses"
    }
];

const heartDiseaseBase = [
    {
        value: "CCO",
        label: "Cardiopatia Congênita Operada"
    },
    {
        value: "CNO",
        label: "Coração Normal"
    },
    {
        value: "OUT",
        label: "Outros"
    },
    {
        value: "CIP",
        label: "Cardiopatia Isquêmica com IM Prévio"
    },
    {
        value: "CCN",
        label: "Cardiopatia Congênita Não Operada"
    },
    {
        value: "CIA",
        label: "Cardiopatia Isquêmica com Aneurisma"
    },
    {
        value: "CCC",
        label: "Cardiopatia Chagásica Crônica com Aneurisma"
    },
    {
        value: "MIO",
        label: "Miocardioesclerose"
    },
    {
        value: "MID",
        label: "Miocardopatia Dilatada"
    },
    {
        value: "MIH",
        label: "Miocardiopatia Hipertrófica"
    },
    {
        value: "PVM",
        label: "Prolapso da Válvula Mitral"
    },
    {
        value: "SQP",
        label: "Síndrome do QT Longo Primário"
    },
    {
        value: "SQS",
        label: "Síndrome do QT Longo Secundário"
    },
    {
        value: "DVD",
        label: "Displasia de VD"
    },
    {
        value: "VAO",
        label: "Valvopatia Operada"
    },
    {
        value: "VNO",
        label: "Valvopatia Não Operada"
    }
];

const arrhythmiaDiagnosisTypes = [
    {
        value: "EVS",
        label: "EV Simples"
    },
    {
        value: "EVC",
        label: "EV Complexo"
    },
    {
        value: "TVM",
        label: "TVNS Monomórfica"
    },
    {
        value: "TVP",
        label: "TVNS Polimórfica"
    },
    {
        value: "TRE",
        label: "TVS Monomórfica BRE"
    },
    {
        value: "TRD",
        label: "TVS Monomórifica BRD"
    },
    {
        value: "TRR",
        label: "TVS Ramo a Ramo"
    },
    {
        value: "TPM",
        label: "TVS Polimórfica"
    },
    {
        value: "TLM",
        label: "TVS Pleomórfica"
    },
    {
        value: "TDP",
        label: "Torsade de Pointes"
    },
    {
        value: "NOR",
        label: "Normal"
    },
    {
        value: "DNI",
        label: "Disfunção do Nó Sinusal Intrínseca"
    },
    {
        value: "DNE",
        label: "Disfunção do Nó Sinusal Extrínseca"
    },
    {
        value: "HSC",
        label: "Hipersensibilidade do Seio Carotídeo"
    },
    {
        value: "BBF",
        label: "Bloqueio Bifasccular"
    },
    {
        value: "BA1",
        label: "BAV 1o."
    },
    {
        value: "BA2",
        label: "BAV 2o. - I"
    },
    {
        value: "B2I",
        label: "BAV 2o. - II"
    },
    {
        value: "B21",
        label: "BAV 2:1"
    },
    {
        value: "BQE",
        label: "BAV com QRS Estreito"
    },
    {
        value: "BAL",
        label: "BAVT com QRS Largo"
    },
    {
        value: "BAC",
        label: "BAVT Congênito"
    },
    {
        value: "DMP",
        label: "Disfunção de MP"
    },
    {
        value: "TQL",
        label: "Taquicardia QRS Largo"
    },
    {
        value: "TQE",
        label: "Taquicardia QRS Estreito"
    },
    {
        value: "TSA",
        label: "Taquicardia Sino Atrial"
    },
    {
        value: "TAP",
        label: "Taquicardia Atrial Paroxística"
    },
    {
        value: "TAI",
        label: "Taquicardia Atrial Incessante"
    },
    {
        value: "FCH",
        label: "Flutter Comum - Horário"
    },
    {
        value: "FCA",
        label: "Flutter Comum - Anti-Horário"
    },
    {
        value: "FAT",
        label: "Flutter Atípico"
    },
    {
        value: "FAR",
        label: "Fibrilação Atrial - Resposta Normal"
    },
    {
        value: "FAA",
        label: "Fibrilação Atrial - Alta Resposta"
    },
    {
        value: "FAB",
        label: "Fibrilação Atrial - Baixa Resposta"
    },
    {
        value: "TJN",
        label: "Taquicardia Juncional Não Paroxística"
    },
    {
        value: "TRC",
        label: "Taquicardia por Reentrada Nodal - Comum"
    },
    {
        value: "TRI",
        label: "Taquicardia por Reentrada Nodal  Incomum"
    },
    {
        value: "WPC",
        label: "WPW Oculto"
    },
    {
        value: "WPM",
        label: "WPW Manifesto"
    },
    {
        value: "WPF",
        label: "WPW FA"
    },
    {
        value: "TAVP",
        label: "Taquicardia AV Permanente"
    },
    {
        value: "TMH",
        label: "Taquicardia por Mahaim"
    },
    {
        value: "TSI",
        label: "Taquicardia Sinusal Inapropriada"
    },
    {
        value: "RIP",
        label: "Ritmo Isioventrcular Rápido"
    },
    {
        value: "FLV",
        label: "Flutter Ventricular"
    },
    {
        value: "FBV",
        label: "Fibrilação Ventricular"
    },
    {
        value: "TVI",
        label: "TV Incessante"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const associatedDiseases = [
    {
        value: "NEU",
        label: "Neuropatia"
    },
    {
        value: "PNE",
        label: "Pneumopatia"
    },
    {
        value: "EMB",
        label: "Embolia Cerebral"
    },
    {
        value: "EAP",
        label: "Embolia Arterial Periférica"
    },
    {
        value: "OUT",
        label: "Outras"
    },
    {
        value: "DIS",
        label: "Dismetabolia"
    },
    {
        value: "NEF",
        label: "Nefro / Uropatia"
    },
    {
        value: "GAS",
        label: "Gastroenteropatia"
    },
    {
        value: "NEN",
        label: "Nenhuma"
    },
    {
        value: "HAS",
        label: "Hipertensão Arterial Sistêmica"
    },
    {
        value: "DML",
        label: "Diabetes Melitus"
    },
    {
        value: "AVC",
        label: "Acidente Vascular Cerebral Não Embólico"
    }
];

const medicationTypes = [
    {
        value: "VER",
        label: "Verapamil"
    },
    {
        value: "SOT",
        label: "Sotalol"
    },
    {
        value: "PRO",
        label: "Propafenona"
    },
    {
        value: "PRC",
        label: "Procainamida"
    },
    {
        value: "AZI",
        label: "Azimilide"
    },
    {
        value: "IBU",
        label: "Ibutilide"
    },
    {
        value: "DOF",
        label: "Dofetilide"
    },
    {
        value: "DIL",
        label: "Diltiazem"
    },
    {
        value: "DIG",
        label: "Digoxina"
    },
    {
        value: "BET",
        label: "Beta Bloqueador"
    },
    {
        value: "QUI",
        label: "Quinidina"
    },
    {
        value: "AMI",
        label: "Amiodarona"
    },
    {
        value: "SEM",
        label: "Sem Medicações"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const otherMedications = [
    {
        value: "HOR",
        label: "Hormônio tiroideano"
    },
    {
        value: "HIP",
        label: "Hipoglicemiantes orais"
    },
    {
        value: "INS",
        label: "Insulina"
    },
    {
        value: "NIT",
        label: "Nitratos"
    },
    {
        value: "OUT",
        label: "Outros"
    },
    {
        value: "ANT",
        label: "Antidepressivo"
    },
    {
        value: "SEM",
        label: "Sem medicação"
    },
    {
        value: "BET",
        label: "Beta-bloqueadores"
    },
    {
        value: "BLO",
        label: "Bloqueadores de cálcio"
    },
    {
        value: "BRO",
        label: "Broncodilatadores"
    },
    {
        value: "DIG",
        label: "Digoxina"
    },
    {
        value: "DIU",
        label: "Diurético"
    },
    {
        value: "ESP",
        label: "Espironolactona"
    },
    {
        value: "IEC",
        label: "IECA"
    }
];

const nonDrugTreatments = [
    {
        value: "VTC",
        label: "Ventriculectomia"
    },
    {
        value: "MAR",
        label: "Marcapasso"
    },
    {
        value: "CTP",
        label: "Cirurgia Troca/Plastia Valvar"
    },
    {
        value: "CRS",
        label: "Cirurgia RM sem Aneurismectomia"
    },
    {
        value: "CRA",
        label: "Cirurgia RM com Aneurismectomia"
    },
    {
        value: "OUT",
        label: "Outros"
    },
    {
        value: "CDI",
        label: "CDI"
    },
    {
        value: "ASC",
        label: "Ablação sem Sucesso"
    },
    {
        value: "ACS",
        label: "Ablação com Sucesso"
    },
    {
        value: "STR",
        label: "Sem Tratamento"
    },
    {
        value: "CIC",
        label: "Cirurgia Congênito"
    }
];

const clinicManifestations = [
    {
        value: "SEM-MANIFESTACAO",
        label: "Sem Manifestação"
    },
    {
        value: "SINCOPE",
        label: "Síncope"
    },
    {
        value: "PRE-SINCOPE",
        label: "Pré-Síncope"
    },
    {
        value: "TONTURAS",
        label: "Tonturas"
    },
    {
        value: "PALPITACOES",
        label: "Palpitações"
    },
    {
        value: "ANGOR",
        label: "Angor"
    },
    {
        value: "ICC",
        label: "ICC"
    },
    {
        value: "RESSUCITADOS",
        label: "Ressucitados"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const previousTreatments = [
    {
        value: "SEM-TRATAMENTO",
        label: "Sem Tratamento"
    },
    {
        value: "CLASSEIA",
        label: "Drogas de Classe IA"
    },
    {
        value: "CLASSEIB",
        label: "Drogas de Classe IB"
    },
    {
        value: "CLASSEIC",
        label: "Drogas de Classe IC"
    },
    {
        value: "CLASSEII",
        label: "Drogas de Classe II"
    },
    {
        value: "CLASSEIII",
        label: "Drogas de Classe III"
    },
    {
        value: "CLASSEIV",
        label: "Drogas de Classe IV"
    },
    {
        value: "AMIODARONA",
        label: "Amiodarona"
    },
    {
        value: "ABLACAO",
        label: "Ablação"
    },
    {
        value: "MARCAPASSO",
        label: "Marcapasso"
    },
    {
        value: "DESFIBRILADOR",
        label: "Desfibilador"
    },
    {
        value: "CIRURGIA-DIRIGIDA",
        label: "Cirurgia Dirigida"
    },
    {
        value: "CIRURGIA-NAO-DIRIGIDA",
        label: "Não Cirurgia Dirigida"
    },
    {
        value: "ASSOCIACOES",
        label: "Associações"
    },
    {
        value: "OUT",
        label: "Outros"
    }
];

const basalRhythms = [
    {
        value: "SINUSAL",
        label: "Sinusal"
    },
    {
        value: "TAQUICARDIA",
        label: "Taquicardia Atrial"
    },
    {
        value: "FLUTTER",
        label: "Flutter Atrial"
    },
    {
        value: "FIBRILACAO",
        label: "Fibrilação Atrial"
    },
    {
        value: "EA",
        label: "Sinusal com EA"
    },
    {
        value: "TANS",
        label: "Sinusal com TANS"
    },
    {
        value: "EV",
        label: "Sinusal com EV"
    },
    {
        value: "TVNS",
        label: "Sinusal com TVNS"
    },
    {
        value: "ECTOPICO",
        label: "Ectópico atrial"
    },
    {
        value: "ESCAPEATRIAL",
        label: "Escape Atrial"
    },
    {
        value: "ESCAPEVENTRICULAR",
        label: "Escape Ventricular"
    },
    {
        value: "BAV1GRAU",
        label: "Sinusal com BAV 1o grau"
    },
    {
        value: "BAV2GRAU",
        label: "Sinusal com BAV 2o grau Mobitz I"
    },
    {
        value: "BAV2GRAUMOBITZ",
        label: "Sinusal com BAV 2o grau Mobitz II"
    },
    {
        value: "BAV3GRAU",
        label: "Sinusal com BAV 3o grau (BAVT)"
    },
    {
        value: "FIBRILACAOBAV3GRAU",
        label: "Fibrilação Atrial com BAV 3o grau (BAVT)"
    },
    {
        value: "TAQATRIOVENTRICULAR",
        label: "Taquicardia Atrioventricular"
    },
    {
        value: "TAQTRN",
        label: "Taquicardia por Reentrada Nodal Comum"
    },
    {
        value: "TAQVIAACESSORIA",
        label: "Taquicardia por Reentrada por Via acessória"
    },
    {
        value: "TAQVENTRICULAR",
        label: "Taquicardia Ventricular"
    },
    {
        value: "RITMOIDIOVENTRICULAR",
        label: "Ritmo Idioventricular"
    },
    {
        value: "OUT",
        label: "Outro"
    }
]

const yesNoOptions = [
    {
        value: "YES",
        label: "Sim"
    },
    {
        value: "NO",
        label: "Não"
    }
];

const physicalActivityOptions = [
    {
        value: "NO",
        label: "Não"
    },
    {
        value: "REGULAR",
        label: "Sim - Regular"
    },
    {
        value: "IRREGULAR",
        label: "Sim - Irregular"
    },
];

const alcoholOptions = [
    {
        value: "NO",
        label: "Não"
    },
    {
        value: "SOCIAL",
        label: "Social"
    },
    {
        value: "HABIT",
        label: "Habitual"
    },
];

const smokeOptions = [
    {
        value: "OPT1",
        label: "Até 10"
    },
    {
        value: "OPT2",
        label: "De 10 a 30"
    },
    {
        value: "OPT3",
        label: "Mais de 30"
    }
];

export {
    states,
    healthPlanAccommodation,
    complexity,
    symptoms,
    cardioVersions,
    historyTimes,
    heartDiseaseBase,
    arrhythmiaDiagnosisTypes,
    associatedDiseases,
    medicationTypes,
    otherMedications,
    nonDrugTreatments,
    clinicManifestations,
    previousTreatments,
    basalRhythms,
    yesNoOptions,
    physicalActivityOptions,
    alcoholOptions,
    smokeOptions
};