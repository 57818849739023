import React, { useContext, useState, useEffect } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
import Main from './layouts/Main';

import Dashboard from "./pages/Dashboard/Dashboard";

// Cadastros
import User from "./pages/User/User";
import HealthPlan from "./pages/HealthPlan/HealthPlan";
import Doctor from "./pages/Doctor/Doctor";
import Hospital from "./pages/Hospital/Hospital";
import Pacient from "./pages/Pacient/Pacient";
import Supplier from "./pages/Supplier/Supplier";
import ProcedureGroup from "./pages/ProcedureGroup/ProcedureGroup";
import ProcedureType from "./pages/ProcedureType/ProcedureType";
import Procedure from "./pages/Procedure/Procedure";

// Agenda
import PreSchedule from "./pages/PreSchedule/PreSchedule";
import Schedule from "./pages/Schedule/Schedule";
import Scale from "./pages/Scale/Scale";
import DoctorAvailability from "./pages/DoctorAvailability/DoctorAvailability";

// Consultório
import Clinic from "./pages/Clinic/Clinic";

// Operacional
import Invoice from "./pages/Operational/Invoice";
import Receipt from "./pages/Operational/Receipt";
import Commissions from "./pages/Commissions/Commissions";

// Configurações
import Settings from "./pages/Settings/Settings";
import CancelReason from "./pages/CancelReason/CancelReason";

// Prontuário
import MedicalRecord from "./pages/MedicalRecord/MedicalRecord";
import AnamnesisForm from "./pages/Anamnesis/AnamnesisForm";
import ProcedureExecution from "./pages/ProcedureExecution/ProcedureExecution";
import ProtocolList from "./pages/Protocol/ProtocolList";

/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Banking from "./components/Dashboard/Banking";
import Ticketing from "./components/Dashboard/Ticketing";
import Crypto from "./components/Dashboard/Crypto";
import Contact from "./components/Dashboard/Contact";
import DashInvoice from "./components/Dashboard/DashInvoice";
import Kanban from "./components/Dashboard/Kanban";

/// File Manager
import FileManager from './components/FileManager/FileManager';
import HomeCalendar from './components/FileManager/HomeCalendar';
import Activity from './components/FileManager/Activity';
import ToDoList from './components/FileManager/ToDoList';
import FileChat from './components/FileManager/FileChat';

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import EditProfile from "./components/AppsMenu/AppProfile/EditProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
// import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";


/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CKEditor from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword";
import Error404 from "./pages/Error404";
import EventLogs from "./pages/EventLogs/EventLogs";


const Markup = () => {

  const routes = [
    { url: "login", component: Login },

    { url: "dashboard", component: Dashboard },

    { url: "user", component: User },
    { url: "health-plan", component: HealthPlan },
    { url: "doctor", component: Doctor },
    { url: "hospital", component: Hospital },
    { url: "pacient", component: Pacient },
    { url: "supplier", component: Supplier },
    { url: "procedure-group", component: ProcedureGroup },
    { url: "procedure-type", component: ProcedureType },
    { url: "procedure", component: Procedure },

    { url: "preschedule", component: PreSchedule },
    { url: "schedule", component: Schedule },
    { url: "scale", component: Scale },
    { url: "availability", component: DoctorAvailability },

    { url: "clinic", component: Clinic },
    { url: "medical-record/:id", component: MedicalRecord },
    { url: "anamnesis/new/:id", component: AnamnesisForm },
    { url: "anamnesis/edit/:id", component: AnamnesisForm },
    { url: "procedure-execution/new/:id", component: ProcedureExecution },
    { url: "procedure-execution/edit/:id", component: ProcedureExecution },
    { url: "protocol-form", component: ProtocolList },

    { url: "invoice", component: Invoice },
    { url: "receipt", component: Receipt },
    { url: "commissions", component: Commissions },

    { url: "settings", component: Settings },
    { url: "cancel-reasons", component: CancelReason },
    { url: "logs", component: EventLogs },

    { url: "banking", component: Banking },
    { url: "ticketing", component: Ticketing },
    { url: "crypto", component: Crypto },
    { url: "contact", component: Contact },
    // { url: "invoice", component: DashInvoice },
    { url: "kanban", component: Kanban },

    //File Manager
    { url: 'calendar', component: HomeCalendar },

    /// Apps
    { url: "app-profile", component: AppProfile },
    { url: "app-calender", component: Calendar },
    { url: "post-details", component: PostDetails },

    /// Shop
    { url: "ecom-product-grid", component: ProductGrid },
    { url: "ecom-product-list", component: ProductList },

    { url: "ecom-product-order", component: ProductOrder },
    { url: "ecom-checkout", component: Checkout },
    { url: "ecom-product-detail", component: ProductDetail },
    { url: "ecom-customers", component: Customers },

    /// Chart
    { url: "chart-sparkline", component: SparklineChart },
    { url: "chart-chartjs", component: ChartJs },
    { url: "chart-apexchart", component: ApexChart },
    { url: "chart-rechart", component: RechartJs },

    { url: "ui-typography", component: UiTypography },
    { url: "ui-grid", component: UiGrid },

    /// Plugin
    { url: "uc-select2", component: Select2 },

    { url: "uc-noui-slider", component: MainNouiSlider },
    { url: "map-jqvmap", component: JqvMap },


    /// Widget
    { url: "widget-basic", component: Widget },



    /// Form
    { url: "form-element", component: Element },
    { url: "form-validation", component: FormValidation },

    /// table
    { url: 'datatable-filtering', component: FilteringTable },
    { url: 'datatable-sorting', component: SortingTable },
    { url: "table-bootstrap-basic", component: BootstrapTable },

  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];


  let pagePath = path.split("-").includes("page");

  return (
    <>
      <Switch>
        {/* without layouts (nav, header, sidebar and footer) */}
        <Route path='/login' component={Login} />
        <Route path='/page-forgot-password' component={ForgotPassword} />
        <Route path='/page-error-404' component={Error404} />

        <Route path={['/', '/dashboard', '/user', '/dashboard-dark',
          '/header-theme', '/sidebar-compact',
          '/horizontal-sidebar', '/horizontal-theme',
          '/sidebar-mini'
        ]}
          exact
        >
          <div id="main-wrapper" className="active show">
            <Main>
              <Nav />
              <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                  <Switch>
                    <Route path='/' exact component={Schedule} />
                    <Route path='/dashboard' exact component={Dashboard} />

                    <Route path='/user' exact component={User} />
                    <Route path='/health-plan' exact component={HealthPlan} />
                    <Route path='/doctor' exact component={Doctor} />
                    <Route path='/hospital' exact component={Hospital} />
                    <Route path='/pacient' exact component={Pacient} />
                    <Route path='/supplier' exact component={Supplier} />
                    <Route path='/procedure-group' exact component={ProcedureGroup} />
                    <Route path='/procedure-type' exact component={ProcedureType} />
                    <Route path='/procedure' exact component={Procedure} />

                    <Route path='/preschedule' exact component={PreSchedule} />
                    <Route path='/schedule' exact component={Schedule} />
                    <Route path='/scale' exact component={Scale} />
                    <Route path='/availability' exact component={DoctorAvailability} />

                    <Route path='/clinic' exact component={Clinic} />
                    <Route path='/medical-record/:id' component={MedicalRecord} />
                    <Route path='/anamnesis/new/:id' component={AnamnesisForm} />
                    <Route path='/procedure-execution/new/:id' component={ProcedureExecution} />
                    <Route path='/protocol-form' component={ProtocolList} />

                    <Route path='/invoice' component={Invoice} />
                    <Route path='/receipt' component={Receipt} />
                    <Route path='/commissions' component={Commissions} />
                    
                    <Route path='/settings' exact component={Settings} />
                    <Route path='/cancel-reasons' exact component={CancelReason} />
                    <Route path='/logs' exact component={EventLogs} />
                  </Switch>
                  <Footer />
                </div>
              </div>
            </Main>
          </div>
        </Route>
        <Route path={['/container-boxed']} exact>
          <div id="main-wrapper" className=" show">
            <Main>
              <Nav />
              <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                  <Switch>
                    {/* <Route path='/container-boxed' exact component={Theme2} />                                                      */}
                  </Switch>
                </div>
              </div>
              <Footer changeFooter="out-footer" />
            </Main>
          </div>
        </Route>

        {/* footer change */}
        <Route path={['/form-ckeditor', '/form-pickers', '/form-wizard', '/uc-sweetalert',
          '/map-jqvmap', '/uc-lightgallery', '/edit-profile', '/ecom-product-detail',
          '/uc-toastr', '/ecom-invoice'
        ]} exact>
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                  <Switch>
                    <Route path='/form-ckeditor' exact component={CKEditor} />
                    <Route path='/form-pickers' exact component={Pickers} />
                    <Route path='/form-wizard' exact component={Wizard} />
                    <Route path='/map-jqvmap' exact component={JqvMap} />
                    <Route path='/uc-lightgallery' exact component={Lightgallery} />
                    <Route path='/edit-profile' exact component={EditProfile} />
                    <Route path='/ecom-invoice' exact component={Invoice} />
                    <Route path='/ecom-product-detail' exact component={ProductDetail} />
                    <Route path='/uc-sweetalert' exact component={MainSweetAlert} />
                    <Route path='/uc-toastr' exact component={Toastr} />
                  </Switch>
                </div>
              </div>
              <Footer changeFooter="out-footer" />
            </Main>
          </div>
        </Route>

        {/* without footer  */}
        <Route path={['/activity', '/to-do-list', '/ui-modal', '/ui-popover']}>
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body">
                <div className="container-fluid">
                  <Switch>
                    <Route path='/activity' exact component={Activity} />
                    <Route path='/to-do-list' exact component={ToDoList} />
                    <Route path='/ui-modal' exact component={UiModal} />
                    <Route path='/ui-popover' exact component={UiPopOver} />
                  </Switch>
                </div>
              </div>
            </Main>
          </div>
        </Route>
        {/* without footer and container */}
        <Route path={['/file-manager', '/chat', '/email-compose', '/email-inbox', '/email-read']} exact>
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body message-body mh-auto">
                <div className="container-fluid mh-auto p-0">
                  <Switch>
                    <Route path='/file-manager' exact component={FileManager} />
                    <Route path='/chat' exact component={FileChat} />
                    <Route path='/email-compose' exact component={Compose} />
                    <Route path='/email-inbox' exact component={Inbox} />
                    <Route path='/email-read' exact component={Read} />
                  </Switch>
                </div>
              </div>
            </Main>
          </div>
        </Route>
        {/* change footer  */}
        <Route path={['/ui-accordion', '/ui-alert', '/ui-badge',
          '/ui-button', '/ui-button-group', '/ui-list-group',
          '/ui-card', '/ui-carousel', '/ui-dropdown',
          '/ui-progressbar', '/ui-tab', '/ui-pagination']}
          exact
        >
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body">
                <div className="container-fluid">
                  <Switch>
                    <Route path='/ui-accordion' exact component={UiAccordion} />
                    <Route path='/ui-alert' exact component={UiAlert} />
                    <Route path='/ui-badge' exact component={UiBadge} />
                    <Route path='/ui-button' exact component={UiButton} />
                    <Route path='/ui-button-group' exact component={UiButtonGroup} />
                    <Route path='/ui-list-group' exact component={UiListGroup} />
                    <Route path='/ui-card' exact component={UiCards} />
                    <Route path='/ui-carousel' exact component={UiCarousel} />
                    <Route path='/ui-dropdown' exact component={UiDropDown} />
                    <Route path='/ui-progressbar' exact component={UiProgressBar} />
                    <Route path='/ui-tab' exact component={UiTab} />
                    <Route path='/ui-pagination' exact component={UiPagination} />
                  </Switch>
                </div>
              </div>
            </Main>
          </div>
        </Route>
        {/* default layout  */}
        <Route>
          <div id="main-wrapper" className="show">
            <Main>
              <Nav />
              <div className="content-body" style={{ minHeight: window.screen.height + 20 }}>
                <div className="container-fluid">
                  <Switch>
                    {routes.map((data, i) => (
                      <Route key={i} exact path={`/${data.url}`} component={data.component} />
                    ))}
                  </Switch>
                  <Footer />
                </div>
              </div>
            </Main>
          </div>
        </Route>
      </Switch>
      <ScrollToTop />
    </>
  );
};

export default Markup;
