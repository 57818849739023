import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import {
    catheterProblemsList,
    complexAblationList,
    complicationsDuringProcedureList,
    conventionalAblationList,
    cryoablationList,
    devicesProblemsList,
    femoralPuncturesList,
    implantList,
    materialList,
    preProcedureComplicationsList,
    proceduresList,
    punctureList,
    sheathProblemsList
} from "../../../enum/complicationEnums";
import { yesNoOptions } from "../../../enum/enums";

import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import api from "../../../services/api";

const ComplicationCard = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        complicationInfoParm,
        hide,
        ...attributes
    } = props;

    const [doctorsList, setDoctorsList] = useState([]);

    const [diagnosis, setDiagnosis] = useState("");
    const [operatorOne, setOperatorOne] = useState("");
    const [operatorTwo, setOperatorTwo] = useState("");
    const [procedures, setProcedures] = useState("");
    const [conventionalAblation, setConventionalAblation] = useState("");
    const [complexAblation, setComplexAblation] = useState("");
    const [complexAblationOthers, setComplexAblationOthers] = useState("");
    const [cryoablation, setCryoablation] = useState("");
    const [cryoablationOthers, setCryoablationOthers] = useState("");
    const [implant, setImplant] = useState("");
    const [electrodeExtraction, setElectrodeExtraction] = useState("");
    const [occlusion, setOcclusion] = useState("");
    const [material, setMaterial] = useState("");
    const [materialOthers, setMaterialOthers] = useState("");
    const [puncture, setPuncture] = useState("");
    const [femoralPunctures, setFemoralPunctures] = useState("");
    const [preProcedureComplications, setPreProcedureComplications] = useState(null);
    const [preProcedureComplicationsDesc, setPreProcedureComplicationsDesc] = useState("");
    const [complicationsDuringProcedure, setComplicationsDuringProcedure] = useState([]);
    const [complicationDesc, setComplicationDesc] = useState("");
    const [catheterProblems, setCatheterProblems] = useState(null);
    const [catheterProblemsDesc, setCatheterProblemsDesc] = useState("");
    const [sheathProblems, setSheathProblems] = useState(null);
    const [sheathProblemsDesc, setSheathProblemsDesc] = useState("");
    const [devicesProblems, setDevicesProblems] = useState([]);
    const [devicesProblemsDesc, setDevicesProblemsDesc] = useState("");
    const [interferenceSolved, setInterferenceSolved] = useState(null);
    const [interferenceDescription, setInterferenceDescription] = useState("");
    const [comments, setComments] = useState("");

    useEffect(() => {
        getDoctors();
    }, [])

    useEffect(() => {
        setDiagnosis(complicationInfoParm.diagnosis ?? "");
        setOperatorOne(complicationInfoParm.operatorOne ?? "");
        setOperatorTwo(complicationInfoParm.operatorTwo ?? "");
        setProcedures(complicationInfoParm.procedures ?? "");
        setConventionalAblation(complicationInfoParm.conventionalAblation ?? "");
        setComplexAblation(complicationInfoParm.complexAblation ?? "");
        setComplexAblationOthers(complicationInfoParm.complexAblationOthers ?? "");
        setCryoablation(complicationInfoParm.cryoablation ?? "");
        setCryoablationOthers(complicationInfoParm.cryoablationOthers ?? "");
        setImplant(complicationInfoParm.implant ?? "");
        setElectrodeExtraction(complicationInfoParm.electrodeExtraction ?? "");
        setOcclusion(complicationInfoParm.occlusion ?? "");
        setMaterial(complicationInfoParm.material ?? "");
        setMaterialOthers(complicationInfoParm.materialOthers ?? "");
        setPuncture(complicationInfoParm.puncture ?? "");
        setFemoralPunctures(complicationInfoParm.femoralPunctures ?? "");
        setPreProcedureComplications(complicationInfoParm.preProcedureComplications ?? null);
        setPreProcedureComplicationsDesc(complicationInfoParm.preProcedureComplicationsDesc ?? "");
        setComplicationsDuringProcedure(complicationInfoParm.complicationsDuringProcedure ?? []);
        setComplicationDesc(complicationInfoParm.complicationDesc ?? "");
        setCatheterProblems(complicationInfoParm.catheterProblems ?? null);
        setCatheterProblemsDesc(complicationInfoParm.catheterProblemsDesc ?? "");
        setSheathProblems(complicationInfoParm.sheathProblems ?? null);
        setSheathProblemsDesc(complicationInfoParm.sheathProblemsDesc ?? "");
        setDevicesProblems(complicationInfoParm.devicesProblems ?? []);
        setDevicesProblemsDesc(complicationInfoParm.devicesProblemsDesc ?? "");
        setInterferenceSolved(complicationInfoParm.interferenceSolved ?? null);
        setInterferenceDescription(complicationInfoParm.interferenceDescription ?? "");
    }, [complicationInfoParm]);

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200) {
                    const doctorsReturned = response.data.doctors.map(doctor => {
                        return {
                            value: doctor._id,
                            label: doctor.name
                        }
                    });
                    setDoctorsList(doctorsReturned);
                }
            })
    }

    return (
        <div hidden={hide}>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <DCard borderColor="light">
                        <DCardHeader color="light" textColor="dark">Informações Gerais</DCardHeader>
                        <DCardBody>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Diagnóstico</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={diagnosis}
                                        onChange={e => {
                                            setDiagnosis(e.target.value);
                                            onChangeInfo("diagnosis", e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Operador 1 (cateter)</label>
                                    <Select
                                        defaultValue={operatorOne}
                                        value={operatorOne}
                                        onChange={e => {
                                            setOperatorOne(e);
                                            onChangeInfo("operatorOne", e);
                                        }}
                                        options={doctorsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Operador 2 (polígrafo)</label>
                                    <Select
                                        defaultValue={operatorTwo}
                                        value={operatorTwo}
                                        onChange={e => {
                                            setOperatorTwo(e);
                                            onChangeInfo("operatorTwo", e);
                                        }}
                                        options={doctorsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                            </div>
                        </DCardBody>
                    </DCard>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <DCard borderColor="light">
                        <DCardHeader color="light" textColor="dark">Procedimento</DCardHeader>
                        <DCardBody>
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Procedimentos</label>
                                    <Select
                                        defaultValue={procedures}
                                        value={procedures}
                                        onChange={e => {
                                            setProcedures(e);
                                            onChangeInfo("procedures", e);
                                        }}
                                        options={proceduresList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Ablação Convencional</label>
                                    <Select
                                        defaultValue={conventionalAblation}
                                        value={conventionalAblation}
                                        onChange={e => {
                                            setConventionalAblation(e);
                                            onChangeInfo("convetionalAblation", e);
                                        }}
                                        options={conventionalAblationList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Ablação Complexa</label>
                                    <Select
                                        defaultValue={complexAblation}
                                        value={complexAblation}
                                        onChange={e => {
                                            setComplexAblation(e);
                                            onChangeInfo("complexAblation", e);
                                        }}
                                        options={complexAblationList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Ablação Complexa (Outros)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={complexAblationOthers}
                                        onChange={e => {
                                            setComplexAblationOthers(e.target.value);
                                            onChangeInfo("complexAblationOthers", e.target.value);
                                        }}
                                        disabled={complexAblation.value !== "OUT"}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Crioablação</label>
                                    <Select
                                        defaultValue={cryoablation}
                                        value={cryoablation}
                                        onChange={e => {
                                            setCryoablation(e);
                                            onChangeInfo("cryoablation", e);
                                        }}
                                        options={cryoablationList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Crioablação (Outras)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={cryoablationOthers}
                                        onChange={e => {
                                            setCryoablationOthers(e.target.value);
                                            onChangeInfo("cryoablationOthers", e.target.value);
                                        }}
                                        disabled={cryoablation.value !== "OUT"}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Implante de MP</label>
                                    <Select
                                        defaultValue={implant}
                                        value={implant}
                                        onChange={e => {
                                            setImplant(e);
                                            onChangeInfo("implant", e);
                                        }}
                                        options={implantList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Extração de eletrodo</label>
                                    <Select
                                        defaultValue={electrodeExtraction}
                                        value={electrodeExtraction}
                                        onChange={e => {
                                            setElectrodeExtraction(e);
                                            onChangeInfo("electrodeExtraction", e);
                                        }}
                                        options={yesNoOptions}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Oclusão de AAE</label>
                                    <Select
                                        defaultValue={occlusion}
                                        value={occlusion}
                                        onChange={e => {
                                            setOcclusion(e);
                                            onChangeInfo("occlusion", e);
                                        }}
                                        options={yesNoOptions}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Material</label>
                                    <Select
                                        defaultValue={material}
                                        value={material}
                                        onChange={e => {
                                            setMaterial(e);
                                            onChangeInfo("material", e);
                                        }}
                                        options={materialList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Material (Outros)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={materialOthers}
                                        onChange={e => {
                                            setMaterialOthers(e.target.value);
                                            onChangeInfo("materialOthers", e.target.value);
                                        }}
                                        disabled={material.value !== "OUT"}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Punções</label>
                                    <Select
                                        defaultValue={puncture}
                                        value={puncture}
                                        onChange={e => {
                                            setPuncture(e);
                                            onChangeInfo("puncture", e);
                                        }}
                                        options={punctureList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-3">
                                    <label>Punções Femorais</label>
                                    <Select
                                        defaultValue={femoralPunctures}
                                        value={femoralPunctures}
                                        onChange={e => {
                                            setFemoralPunctures(e);
                                            onChangeInfo("femoralPunctures", e);
                                        }}
                                        options={femoralPuncturesList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                            </div>
                        </DCardBody>
                    </DCard>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <DCard borderColor="light">
                        <DCardHeader color="light" textColor="dark">Complicações</DCardHeader>
                        <DCardBody>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Complicações pré-procedimento</label>
                                    <Select
                                        defaultValue={preProcedureComplications}
                                        value={preProcedureComplications}
                                        onChange={e => {
                                            setPreProcedureComplications(e);
                                            onChangeInfo("preProcedureComplications", e);
                                        }}
                                        options={preProcedureComplicationsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-8">
                                    <label>Descrição complicações pré-procedimento</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={preProcedureComplicationsDesc}
                                        onChange={e => {
                                            setPreProcedureComplicationsDesc(e.target.value);
                                            onChangeInfo("preProcedureComplicationsDesc", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Complicações durante o procedimento</label>
                                    <Select
                                        defaultValue={complicationsDuringProcedure}
                                        value={complicationsDuringProcedure}
                                        onChange={e => {
                                            setComplicationsDuringProcedure(e);
                                            onChangeInfo("complicationsDuringProcedure", e);
                                        }}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={complicationsDuringProcedureList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-6">
                                    <label>Descrição da complicação</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={complicationDesc}
                                        onChange={e => {
                                            setComplicationDesc(e.target.value);
                                            onChangeInfo("complicationDesc", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Problemas com Cateter</label>
                                    <Select
                                        defaultValue={catheterProblems}
                                        value={catheterProblems}
                                        onChange={e => {
                                            setCatheterProblems(e);
                                            onChangeInfo("catheterProblems", e);
                                        }}
                                        options={catheterProblemsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-8">
                                    <label>Descrição do problema com cateter</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={catheterProblemsDesc}
                                        onChange={e => {
                                            setCatheterProblemsDesc(e.target.value);
                                            onChangeInfo("catheterProblemsDesc", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Problemas com Bainha</label>
                                    <Select
                                        defaultValue={sheathProblems}
                                        value={sheathProblems}
                                        onChange={e => {
                                            setSheathProblems(e);
                                            onChangeInfo("sheathProblems", e);
                                        }}
                                        options={sheathProblemsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-8">
                                    <label>Descrição do problema com Bainha</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={sheathProblemsDesc}
                                        onChange={e => {
                                            setSheathProblemsDesc(e.target.value);
                                            onChangeInfo("sheathProblemsDesc", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Problemas com Aparelhos</label>
                                    <Select
                                        defaultValue={devicesProblems}
                                        value={devicesProblems}
                                        onChange={e => {
                                            setDevicesProblems(e);
                                            onChangeInfo("devicesProblems", e);
                                        }}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={devicesProblemsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-8">
                                    <label>Descrição do problema com Aparelhos</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={devicesProblemsDesc}
                                        onChange={e => {
                                            setDevicesProblemsDesc(e.target.value);
                                            onChangeInfo("devicesProblemsDesc", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Interferência durante o procedimento resolvida?</label>
                                    <Select
                                        defaultValue={interferenceSolved}
                                        value={interferenceSolved}
                                        onChange={e => {
                                            setInterferenceSolved(e);
                                            onChangeInfo("interferenceSolved", e);
                                        }}
                                        options={yesNoOptions}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-8">
                                    <label>Descrição da interferência</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={interferenceDescription}
                                        onChange={e => {
                                            setInterferenceDescription(e.target.value);
                                            onChangeInfo("interferenceDescription", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </DCardBody>
                    </DCard>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-12 col-lg-12">
                    <DCard borderColor="light">
                        <DCardHeader color="light" textColor="dark">Comentários</DCardHeader>
                        <DCardBody>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Comentários adicionais da complicação</label>
                                    <textarea
                                        type="text"
                                        rows={6}
                                        className="form-control"
                                        value={comments}
                                        onChange={e => {
                                            setComments(e.target.value);
                                            onChangeInfo("comments", e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </DCardBody>
                    </DCard>
                </div>
            </div>
        </div>
    )
}

ComplicationCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

ComplicationCard.defaultProps = {
    complicationInfoParm: {},
    hide: false
};

export default ComplicationCard;