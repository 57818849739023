import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";

const WeekSummary = props => {

    const {
        className,
        innerRef,
        //
        weekSummary,
        ...attributes
    } = props;

    const [options, setOptions] = useState({
        chart: {
            id: 'assetDistribution2',
            height: 300,
            type: 'area',
            group: 'social',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [3, 3, 3],
            colors: ['var(--bs-orange)', 'var(--primary)'],
            curve: 'straight'
        },
        legend: {
            show: false,
            tooltipHoverFormatter: function (val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
            },
            markers: {
                fillColors: ['var(--bs-orange)', 'var(--primary)'],
                width: 16,
                height: 16,
                strokeWidth: 0,
                radius: 16
            }
        },
        markers: {
            size: [8, 8],
            strokeWidth: [4, 4],
            strokeColors: ['var(--bs-orange)', 'var(--primary)'],
            border: 2,
            radius: 2,
            colors: ['#fff', '#fff', '#fff'],
            hover: {
                size: 10,
            }
        },
        xaxis: {
            categories: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
            labels: {
                style: {
                    colors: '#3E4954',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 100,

                },
            },
            axisBorder: {
                show: false,
            }
        },
        yaxis: {
            labels: {
                minWidth: 20,
                offsetX: -16,
                style: {
                    colors: '#3E4954',
                    fontSize: '14px',
                    fontFamily: 'Poppins',
                    fontWeight: 100,

                },
            },
        },
        fill: {
            colors: ['#fff', '#fff'],
            type: 'gradient',
            opacity: 1,
            gradient: {
                shade: 'light',
                shadeIntensity: 1,
                colorStops: [
                    [
                        {
                            offset: 0,
                            color: '#fff',
                            opacity: 0
                        },
                        {
                            offset: 0.6,
                            color: '#fff',
                            opacity: 0
                        },
                        {
                            offset: 100,
                            color: '#fff',
                            opacity: 0
                        }
                    ],
                    [
                        {
                            offset: 0,
                            color: '#fff',
                            opacity: .4
                        },
                        {
                            offset: 50,
                            color: '#fff',
                            opacity: 0.25
                        },
                        {
                            offset: 100,
                            color: '#fff',
                            opacity: 0
                        }
                    ]
                ]

            },
        },
        colors: ['#1EA7C5', 'var(--bs-orange)'],
        grid: {
            borderColor: '#f1f1f1',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: false
                }
            },
        },

        responsive: [{
            breakpoint: 1602,
            options: {
                markers: {
                    size: [6, 6, 4],
                    hover: {
                        size: 7,
                    }
                }, chart: {
                    height: 230,
                },
            },

        }]
    });
    const [series, setSeries] = useState([{
        name: "Agendados",
        data: [0, 0, 0, 0, 0, 0, 0]
    },
    {
        name: "Realizados",
        data: [0, 0, 0, 0, 0, 0, 0]
    }]);

    useEffect(() => {
        let newSeriesScheduled = { name: "Agendados", data: [0, 0, 0, 0, 0, 0, 0]};
        let newSeriesDone = { name: "Realizados", data: [0, 0, 0, 0, 0, 0, 0]};

        for (let indexSummary = 0; indexSummary < weekSummary.length; indexSummary++) {
            for (let indexCounter = 0; indexCounter < weekSummary[indexSummary].count.length; indexCounter++) {
                if (weekSummary[indexSummary].count[indexCounter].status === "done") {
                    newSeriesDone.data[weekSummary[indexSummary].weekday - 1] += weekSummary[indexSummary].count[indexCounter].count;
                }

                newSeriesScheduled.data[weekSummary[indexSummary].weekday - 1] += weekSummary[indexSummary].count[indexCounter].count;
            }
        }

        let newSeries = [ ...series ];
        newSeries[0] = newSeriesScheduled;
        newSeries[1] = newSeriesDone;
        setSeries(newSeries);
    }, [weekSummary])

    return (
        <div id="activity" >
            <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={300}
            />
        </div>
    )
}

WeekSummary.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    weekSummary: PropTypes.array
};

WeekSummary.defaultProps = {
    weekSummary: []
};

export default WeekSummary;