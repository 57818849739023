import api from "../../../services/api";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { convertStringToPrint } from "../../../services/utils";

const imgLogo = "../../../images/lec-logo-2.png";
var scheduleReport = undefined;
var linePosition = 3;

export async function generateProcedureDescriptionPdf(reportId) {
    scheduleReport = await getScheduleReport(reportId);

    let doc = new jsPDF({
        unit: "cm"
    });

    linePosition = 3;

    jsPDF.autoTableSetDefaults({
        headStyles: { fillColor: [55, 75, 69] },
        margin: { top: 3 }
    });

    if (scheduleReport) {
        const pacientHma = await getAnamnesis(scheduleReport.pacient_id);

        doc.addImage(imgLogo, "PNG", 5, 7, 9.7, 6.7);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(24);

        doc.text("DESCRIÇÃO DE PROCEDIMENTO", 10.55, 15, { align: "center" });

        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("HOSPITAL", 10.55, 17.5, { align: "center" });
        doc.text(scheduleReport.hospital_name, 10.55, 18.5, { align: "center" });

        doc.setFont("helvetica", "bold");
        doc.text("Paciente:", 5, 21, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(scheduleReport.pacient_name, 5.2, 21);
        
        doc.setFont("helvetica", "bold");
        doc.text("Data:", 5, 21.6, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(dayjs(scheduleReport.execution_date).format("DD/MM/YYYY"), 5.2, 21.6);

        doc.setFont("helvetica", "bold");
        doc.text("Procedimento:", 5, 22.2, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(scheduleReport.procedure_name, 5.2, 22.2);

        doc.setFont("helvetica", "bold");
        doc.text("Cirurgião:", 5, 22.8, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(scheduleReport.main_doctor_name, 5.2, 22.8);

        doc.setFont("helvetica", "bold");
        doc.text("Primeiro Auxiliar:", 5, 23.4, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(scheduleReport.first_assist_doctor_name, 5.2, 23.4);

        doc.setFont("helvetica", "bold");
        doc.text("Segundo Auxiliar:", 5, 24, { align: "right" });

        doc.setFont("helvetica", "normal");
        doc.text(scheduleReport.second_assist_doctor_name, 5.2, 24);

        doc.addPage();

        if (pacientHma.hma) {
            linePosition += 1.1;
            checkBreak(doc);

            const hmaToPrint = [{ hma: convertStringToPrint(pacientHma.hma) }];

            doc.autoTable({
                startY: linePosition,
                head: [{ hma: "Resumo sumário da História Clínica" }],
                body: hmaToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        if (scheduleReport.final_report) {
            linePosition += 1.1;
            checkBreak(doc);

            const descriptionToPrint = [{ procedure: convertStringToPrint(scheduleReport.final_report) }];
            doc.autoTable({
                startY: linePosition,
                head: [{ procedure: "Descrição Cirúrgica do Procedimento Realizado" }],
                body: descriptionToPrint,
            });

            linePosition = doc.lastAutoTable.finalY;
        }

        linePosition += 1.1;
        checkBreak(doc);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.text(`Curitiba, PR, ${dayjs(scheduleReport.execution_date).format("DD/MM/YYYY")}`, 1.5, linePosition);

        linePosition += 0.8;
        checkBreak(doc);
        doc.text(`${scheduleReport.main_doctor_name} - CRM: ${scheduleReport.main_doctor_crm}`, 19.4, linePosition, { align: "right" });

        if (scheduleReport.first_assist_doctor_id) {
            linePosition += 1.3;
            checkBreak(doc);
            doc.text(`${scheduleReport.first_assist_doctor_name} - CRM: ${scheduleReport.first_assist_doctor_crm}`, 19.4, linePosition, { align: "right" });
        }

        if (scheduleReport.second_assist_doctor_id) {
            linePosition += 1.3;
            checkBreak(doc);
            doc.text(`${scheduleReport.second_assist_doctor_name} - CRM: ${scheduleReport.second_assist_doctor_crm}`, 19.4, linePosition, { align: "right" });
        }

        doc.setFont("helvetica", "normal");
        doc.setFontSize(7);
        const pageCount = doc.internal.getNumberOfPages();
        for (let pageItem = 1; pageItem <= pageCount; pageItem++) {
            doc.setPage(pageItem);

            if (pageItem > 1) {
                printHeader(doc);
            }

            printFooter(doc);
            doc.text("Página " + pageItem + " de " + pageCount, 18, 28.8);
        }

        doc.save("descricao_procedimento.pdf");
    }
}

function checkBreak(doc) {
    if (linePosition > 26.5) {
        doc.addPage();
        linePosition = 3;
    }
}

function printHeader(doc) {
    doc.addImage(imgLogo, "PNG", 8, 0.8, 5.6, 2.6);
}

function printFooter(doc) {
    doc.setFont("helvetica", "bold");
    doc.setFontSize(7);
    doc.text("LEC - Laboratório de Eletrofisiologia de Curitiba", 10.9, 28.2, { align: "right" });

    doc.setFont("helvetica", "normal");
    doc.text(" - Fones (41) 3262-8693 ou (41) 99933-9934", 10.9, 28.2);
    doc.text("Cândido de Abreu, 70 - Sala 1105 - Centro Cívico - Curitiba - PR - 80.530-000 - Email: eletrolec@leccuritiba.com.br", 10.55, 28.6, { align: "center" });
}

async function getScheduleReport(id) {
    return new Promise(resolve => {
        api.get(`/schedules/${id}`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.reports && response.data.reports.length > 0) {
                        resolve(response.data.reports[0]);
                    }
                }

                resolve(undefined);
            })
    });
}

async function getAnamnesis(id) {
    return new Promise(resolve => {
        api.get(`/anamnesis?pacientId=${id}`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.total > 0) {
                        resolve(response.data.anamnesis[0]);
                    }
                }

                resolve(undefined);
            })
    })
}