import {
    formatError,
    login,
    saveTokenInLocalStorage,
    saveTokenInSessionStorage,
} from '../../services/AuthService';
import { createLog } from '../../services/logService';
import { hasAccess } from '../../services/utils';

export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function logout(history) {
    if (localStorage.getItem("xLcxRem")) {
        localStorage.removeItem("xLcxTk");
        localStorage.removeItem("xLcxTkr");
        localStorage.removeItem("xLcxUsr");
    } else {
        sessionStorage.removeItem("xLcxTk");
        sessionStorage.removeItem("xLcxTkr");
        sessionStorage.removeItem("xLcxUsr");
    }
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(username, password, rememberUser, history) {
    return (dispatch) => {
        login(username, password)
            .then((response) => {
                if (!rememberUser) {
                    saveTokenInSessionStorage(response.data);
                } else {
                    saveTokenInLocalStorage(response.data);
                }

                createLog({
                    routine: "login",
                    action: "login",
                    type: "success",
                    message: "Login realizado",
                    jsonOrigin: JSON.stringify({ username, password }),
                    jsonReturn: JSON.stringify(response.data)
                });

                dispatch(loginConfirmedAction(response.data));

                if (hasAccess("schedule", "read")) {
                    history.push("/schedule");
                } else if (hasAccess("clinic", "medical_record")) {
                    history.push("/medical-record");
                } else if (hasAccess("dashboard", "access")) {
                    history.push("/dashboard");
                } else if (hasAccess("protocol", "create")) {
                    history.push("/protocol-form");
                } else if (hasAccess("preschedule", "read")) {
                    history.push("/preschedule");
                } else if (hasAccess("operational", "invoice")) {
                    history.push("/invoice");
                } else if (hasAccess("operational", "receipt")) {
                    history.push("/receipt");
                } else if (hasAccess("operational", "commission")) {
                    history.push("/commission");
                }
            }).catch((error) => {
                const errorMessage = formatError(error.data);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
