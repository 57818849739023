import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
    Row,
    Col,
    Card,
    Table,
} from "react-bootstrap";
import Select from "react-select";
import { isAuthenticated } from "../../../services/utils";
import { getActionName, getRoutineName } from "../../../services/logService";
import { useHistory } from "react-router-dom";

import api from "../../../services/api";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import DPagination from "../../components/DPagination/DPagination";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import dayjs from "dayjs";
import { logRoutines } from "../../../enum/logEnums";
import EventLogForm from "./EventLogForm";

registerLocale("ptBR", ptBR);

const LOG_STATUS = [
    {
        value: "success",
        label: "Successo"
    },
    {
        value: "error",
        label: "Erro"
    }
];

const EventLogs = () => {
    const childRef = useRef();
    const [loading, setLoading] = useState(false);
    const [eventLogs, setEventLogs] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [filterDateFrom, setFilterDateFrom] = useState(dayjs().toDate());
    const [filterDateTo, setFilterDateTo] = useState(dayjs().toDate());
    const [filterRoutine, setFilterRoutine] = useState(null);
    const [filterStatus, setFilterStatus] = useState(null);
    const [filterMessage, setFilterMessage] = useState("");

    const history = useHistory();

    useEffect(() => {
        if (!isAuthenticated()) {
            history.push("/login");
        } else {
            getEventLogs();
        }
    }, [])

    async function getEventLogs(page = currentPage) {
        setLoading(true);

        let filter = "&";
        if (filterDateFrom && filterDateTo) {
            const filterDateToUTC = dayjs(filterDateTo).add(1, "day");
            filter += `date_from=${dayjs(filterDateFrom).toISOString()}&date_to=${dayjs(filterDateToUTC).toISOString()}&`;
        }

        if (filterRoutine) {
            filter += `routine=${filterRoutine.value}`;
        }

        if (filterStatus) {
            filter += `type=${filterStatus.value}`;
        }

        if (filterMessage) {
            filter += `qs=${filterMessage}`;
        }

        await api.get(`/logs?offset=${page}&limit=10${filter}`)
            .then(response => {
                if (response.status === 200) {
                    setEventLogs(response.data.eventsLogs);

                    let pages = Math.ceil(response.data.total / 10);
                    setTotalPages(Math.max(pages, 1));
                }
            })

        setLoading(false);
    }

    function setActivePage(page) {
        setCurrentPage(page);
        getEventLogs(page);
    }

    function handleFilter() {
        setCurrentPage(1);
        getEventLogs(1);
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Logs" motherMenu="Events" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Filtros</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="basic-form">
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Inicial</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateFrom}
                                                onChange={(date) => setFilterDateFrom(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Data Final</label>
                                            <br />
                                            <DatePicker
                                                selected={filterDateTo}
                                                onChange={(date) => setFilterDateTo(date)}
                                                locale="ptBR"
                                                dateFormat="P"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Rotina</label>
                                            <Select
                                                value={filterRoutine}
                                                defaultValue={filterRoutine}
                                                options={logRoutines}
                                                isClearable
                                                placeholder="Selecione..."
                                                onChange={setFilterRoutine}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-md-3">
                                            <label>Status</label>
                                            <Select
                                                value={filterStatus}
                                                defaultValue={filterStatus}
                                                options={LOG_STATUS}
                                                placeholder="Selecione..."
                                                onChange={setFilterStatus}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        height: 45,
                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                    }),
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group mb-3 col-md-12">
                                            <label>Pesquisar nas mensagens</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={filterMessage}
                                                onChange={e => setFilterMessage(e.target.value)}
                                                tabIndex="1"
                                            />
                                        </div>
                                    </div>

                                    <div className="row" style={{ textAlign: "right" }}>
                                        <div className="form-group mb-12 col-md-12">
                                            <button className="btn btn-primary ms-1" onClick={handleFilter}>
                                                Filtrar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Logs</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Status</strong>
                                            </th>
                                            <th>
                                                <strong>Data</strong>
                                            </th>
                                            <th>
                                                <strong>Rotina</strong>
                                            </th>
                                            <th>
                                                <strong>Ação</strong>
                                            </th>
                                            <th>
                                                <strong>Mensagem</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            eventLogs.map((eventLog) => {
                                                return (
                                                    <tr key={eventLog._id}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <i className={(eventLog.type === "success" ? "fa fa-circle text-success me-1" : "fa fa-circle text-danger me-1")}></i>{" "}
                                                                {(eventLog.type === "success" ? "Sucesso" : "Erro")}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{dayjs(eventLog.event_date).format("DD/MM/YYYY HH:mm:ss")}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{getRoutineName(eventLog.routine)}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{getActionName(eventLog.action)}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <span className="w-space-no">{eventLog.message}</span>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-primary shadow sharp ms-1"
                                                                    onClick={() => childRef.current.openModal(eventLog)}
                                                                    title="Detalhes"
                                                                >
                                                                    <i className="fas fa-eye"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>

                                <DPagination
                                    activePage={currentPage}
                                    pages={totalPages}
                                    onActivePageChange={(i) => setActivePage(i)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <EventLogForm ref={childRef} />

            <ModalLoading
                visible={loading}
                message="Carregando Logs..."
                onClose={setLoading}
            />
        </>
    );
};

export default EventLogs;