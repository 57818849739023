import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import {
    Button,
    Modal,
    Tab,
    Table,
    Tabs
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import ModalLoading from '../../components/ModalLoading/ModalLoading';

import { healthPlanAccommodation, complexity as complexityList } from "../../../enum/enums";
import api from '../../../services/api';
import dayjs from 'dayjs';

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import ReceiptForm from './ReceiptForm';
import { receiptType, accountType as accountTypesList } from '../../../enum/receiptEnums';
import { createLog } from '../../../services/logService';
registerLocale("ptBR", ptBR);

const ReceiptCard = forwardRef((props, ref) => {
    const { onClose } = props;
    const childRef = useRef();
    const [modalBox, setModalBox] = useState(false);
    const [loadingReceiptCard, setLoadingReceiptCard] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Gravando Informações...");

    const [operationalId, setOperationalId] = useState(undefined);
    const [operationalStatus, setOperationalStatus] = useState("");
    const [name, setName] = useState("");
    const [healthPlan, setHealthPlan] = useState("");
    const [accommodation, setAccommodation] = useState("");
    const [forwardDoctor, setForwardDoctor] = useState("");
    const [mainDoctor, setMainDoctor] = useState("");
    const [hospital, setHospital] = useState("");
    const [procedure, setprocedure] = useState("");
    const [complexity, setComplexity] = useState("");
    const [supplier, setSupplier] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [executionDate, setExecutionDate] = useState(dayjs().toDate());
    const [operationalValues, setOperationalValues] = useState(null);
    const [receiptsList, setReceiptsList] = useState([]);

    const [logs, setLogs] = useState([]);

    const userInfo = JSON.parse(localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxUsr") : sessionStorage.getItem("xLcxUsr"));

    useImperativeHandle(ref, () => ({
        async openModal(operationalInfo = {}) {
            setModalBox(true);

            resetFields();

            if (operationalInfo) {
                setOperationalId(operationalInfo._id);
                setOperationalStatus(operationalInfo.status);
                setName(operationalInfo.pacient_name);
                setHealthPlan(operationalInfo.health_plan_name);

                const accommodationItem = healthPlanAccommodation.find(x => x.value === operationalInfo.health_plan_accommodation);
                setAccommodation(accommodationItem?.label ?? "");

                setForwardDoctor(operationalInfo.forward_doctor_name);
                setMainDoctor(operationalInfo.main_doctor_name);
                setHospital(operationalInfo.hospital_name);
                setprocedure(operationalInfo.procedure_name);

                const complexityItem = complexityList.find(x => x.value === operationalInfo.complexity);
                setComplexity(complexityItem?.label ?? "");
                setSupplier(operationalInfo.supplier_name);
                // setDiagnosis(operationalInfo.diagnosis);
                setExecutionDate(dayjs(operationalInfo.execution_date).toDate());
                setOperationalValues(operationalInfo.commission);

                let receiptsReturned = [];
                for (let i = 0; i < operationalInfo.receipts.length; i++) {
                    let doctorInfo = null;
                    if (operationalInfo.receipts[i].doctor_id) {
                        doctorInfo = {
                            value: operationalInfo.receipts[i].doctor_id,
                            label: operationalInfo.receipts[i].doctor_name
                        };
                    }

                    receiptsReturned.push({
                        id: operationalInfo.receipts[i]._id,
                        innerId: (Math.floor(Math.random() * 65536)).toString(),
                        receiptDate: operationalInfo.receipts[i].receipt_date,
                        type: receiptType.find(x => x.value === operationalInfo.receipts[i].type_option),
                        value: operationalInfo.receipts[i].value,
                        accountType: accountTypesList.find(x => x.value === operationalInfo.receipts[i].account_option),
                        accountDoctor: doctorInfo,
                        isDeleted: false
                    });
                }
                setReceiptsList(receiptsReturned);

                let logsInfo = [];
                if (operationalInfo.logs) {
                    logsInfo = [ ...operationalInfo.logs ];
                }
                setLogs(logsInfo);
            }
        }
    }));

    async function handleSaveReceipt(e) {
        e.preventDefault();

        setLoadingMessage("Gravando Registro...")
        setLoadingReceiptCard(true);

        const receiptsActive = receiptsList.filter(x => !x.isDeleted);

        let operationalToUpdate = {
            receipts: receiptsActive.map(receiptItem => {
                return {
                    receipt_date: receiptItem.receiptDate,
                    type_option: receiptItem.type.value,
                    type_description: receiptItem.type.label,
                    value: receiptItem.value,
                    account_option: receiptItem.accountType.value,
                    account_description: receiptItem.accountType.label,
                    doctor_id: receiptItem.accountDoctor?.value ?? "",
                    doctor_name: receiptItem.accountDoctor?.label ?? ""
                }
            })
        };

        let newLogs = [ ...logs ];

        if (operationalStatus !== "rec") {
            if (receiptsActive.length > 0) {
                newLogs.push({
                    status: "rec",
                    comment: "Procedimento recebido",
                    user: userInfo.name,
                    date: dayjs().toDate()
                });

                operationalToUpdate.status = "rec";
                
            }
        } else {
            if (receiptsList.length === 0 || receiptsList.every(x => x.isDeleted)) {
                newLogs.push({
                    status: "can",
                    comment: "Recebimento cancelado",
                    user: userInfo.name,
                    date: dayjs().toDate()
                });

                operationalToUpdate.status = "inv";
            }
        }

        operationalToUpdate.logs = [ ...newLogs ];

        try {
            await api.put(`/operationals/${operationalId}`, operationalToUpdate)
                .then(response => {
                    if (response.status === 200) {
                        createLog({
                            routine: "receipt",
                            action: "update",
                            type: "success",
                            message: `Recebimento atualizado`,
                            jsonOrigin: JSON.stringify(operationalToUpdate),
                            jsonReturn: JSON.stringify(response)
                        });

                        setLoadingReceiptCard(false);
                        notifySuccess();
                        resetFields();
                        onClose();
                        setModalBox(false);
                    }
                })
        } catch (err) {
            createLog({
                routine: "receipt",
                action: "update",
                type: "error",
                message: `Recebimento não atualizado`,
                jsonOrigin: JSON.stringify(operationalToUpdate),
                jsonReturn: JSON.stringify(err)
            });

            notifyError();
        }

        setLoadingReceiptCard(false);
    }

    function onCloseReceipt(mode, receiptItem) {
        let newReceiptsList = [...receiptsList];

        if (mode === "update") {
            const indexOfItem = newReceiptsList.findIndex(x => x.innerId === receiptItem.innerId);
            newReceiptsList[indexOfItem] = receiptItem;
        } else {
            newReceiptsList.push(receiptItem);
        }

        setReceiptsList(newReceiptsList);
    }

    function handleDeleteReceipt(id) {
        const receiptIndex = receiptsList.findIndex(x => x.innerId === id);

        if (receiptIndex >= 0) {
            let newReceiptsList = [ ...receiptsList ];

            if (receiptsList[receiptIndex].id) {
                newReceiptsList[receiptIndex].isDeleted = true;
            } else {
                newReceiptsList = newReceiptsList.filter(x => x.innerId !== id);
            }
            
            setReceiptsList(newReceiptsList);
        }
    }

    function notifySuccess() {
        toast.success(`✔️ Registro alterado com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error("❌ Ocorreu um problema ao salvar o registro", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setOperationalId(undefined);
        setName("");
        setHealthPlan("");
        setAccommodation("");
        setForwardDoctor("");
        setMainDoctor("");
        setHospital("");
        setprocedure("");
        setComplexity("");
        setSupplier("");
        setDiagnosis("");
        setExecutionDate(dayjs().toDate());
        setReceiptsList([]);
    }

    return (
        <>
            <Modal size='xl' fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">Recebimentos</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <Tabs
                                        defaultActiveKey="general"
                                        id="fill-tab-example"
                                        className="mb-3 basic-list-group"
                                        fill
                                    >
                                        <Tab eventKey="general" title="Informações Gerais">
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Paciente</label>
                                                            <input
                                                                type="text"
                                                                value={name}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Convênio</label>
                                                            <input
                                                                type="text"
                                                                value={healthPlan}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Tipo de Acomodação</label>
                                                            <input
                                                                type="text"
                                                                value={accommodation}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Médico Encaminhador</label>
                                                            <input
                                                                type="text"
                                                                value={forwardDoctor}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Cirurgião Principal</label>
                                                            <input
                                                                type="text"
                                                                value={mainDoctor}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Hospital</label>
                                                            <input
                                                                type="text"
                                                                value={hospital}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Procedimento</label>
                                                            <input
                                                                type="text"
                                                                value={procedure}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Complexidade</label>
                                                            <input
                                                                type="text"
                                                                value={complexity}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Fornecedor</label>
                                                            <input
                                                                type="text"
                                                                value={supplier}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Data/Hora Execução</label>
                                                            <br />
                                                            <DatePicker
                                                                selected={executionDate}
                                                                onChange={(date) => setExecutionDate(date)}
                                                                locale="ptBR"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                dateFormat="Pp"
                                                                className="form-control"
                                                                timeCaption="Hora"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="operational" title="Operacional">
                                            <h4>VALORES PREVISTOS</h4>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-6">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Valor a faturar (+)</label>
                                                            <input
                                                                type="text"
                                                                value={operationalValues?.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Impostos (-)</label>
                                                            <input
                                                                type="text"
                                                                value={operationalValues?.tax.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Valor líquido (=)</label>
                                                            <input
                                                                type="text"
                                                                value={(operationalValues?.total - operationalValues?.tax).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Encaminhador</label>
                                                            <input
                                                                type="text"
                                                                value={operationalValues?.forwarder.doctor_name}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Comissão (-)</label>
                                                            <input
                                                                type="text"
                                                                value={operationalValues?.forwarder.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Saldo a distribuir (=)</label>
                                                            <input
                                                                type="text"
                                                                value={(operationalValues?.total - operationalValues?.tax - operationalValues?.forwarder.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 col-md-6">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-12">
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <strong>Médico</strong>
                                                                        </th>
                                                                        <th className="text-center">
                                                                            <strong>%</strong>
                                                                        </th>
                                                                        <th className="text-center">
                                                                            <strong>Valor Previsto</strong>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        operationalValues?.distribution.map((operationalItem) => {
                                                                            return (
                                                                                <tr key={operationalItem.doctor_id}>
                                                                                    <td>
                                                                                        <div className="text-start">
                                                                                            <span className="w-space-no">{operationalItem.doctor_name}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="text-end">
                                                                                            {operationalItem.percent.toLocaleString('pt-br')}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="text-end">
                                                                                            {operationalItem.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="receipts" title="Recebimentos">
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12 text-end">
                                                    <button
                                                        className="btn btn-primary ms-1"
                                                        title="Adicionar novo item"
                                                        onClick={() => childRef.current.openModal("new")}
                                                    >
                                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                                                            <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                                                        </svg> Adicionar
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <Table responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    <strong>Data</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Tipo</strong>
                                                                </th>
                                                                <th>
                                                                    <strong>Conta</strong>
                                                                </th>
                                                                <th className="text-center">
                                                                    <strong>Valor Recebido</strong>
                                                                </th>
                                                                <th />
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                receiptsList.map((receiptItem) => {
                                                                    return (
                                                                        <tr key={receiptItem.innerId} hidden={receiptItem.isDeleted}>
                                                                            <td>
                                                                                <div className="text-start">
                                                                                    <span className="w-space-no">{dayjs(receiptItem.receiptDate).format("DD/MM/YYYY")}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-start">
                                                                                    <span className="w-space-no">{receiptItem.type.label}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-start">
                                                                                    <span className="w-space-no">
                                                                                        {receiptItem.accountType.value === "DOC" ? receiptItem.accountDoctor.label : receiptItem.accountType.label}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="text-end">
                                                                                    {receiptItem.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="d-flex">
                                                                                    <button
                                                                                        className="btn btn-primary shadow sharp ms-1"
                                                                                        onClick={() => childRef.current.openModal("update", receiptItem)}
                                                                                    >
                                                                                        <i className="fas fa-pencil-alt"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-primary shadow sharp ms-1"
                                                                                        onClick={() => handleDeleteReceipt(receiptItem.innerId)}
                                                                                    >
                                                                                        <i className="fas fa-trash"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                        <Button variant="success btn-rounded" onClick={handleSaveReceipt}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ReceiptForm ref={childRef} onClose={onCloseReceipt} />

            <ToastContainer />

            <ModalLoading
                visible={loadingReceiptCard}
                message={loadingMessage}
                onClose={setLoadingReceiptCard}
            />
        </>
    )
})

export default ReceiptCard;