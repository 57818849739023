import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import {
    Button,
    Dropdown,
    Modal,
    Tab,
    Table,
    Tabs
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import swal from "sweetalert";
import dayjs from "dayjs";
import Select from "react-select";
import PerfectScrollbar from "react-perfect-scrollbar";

import imageLogo from "../../../images/lec-logo-3.png";

import { useFiles } from "../../../context/files";
import UploadFile from "../../components/UploadFile/UploadFile";
import DFormText from "../../components/DFormText/DFormText";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

import { healthPlanAccommodation, complexity as complexityList } from "../../../enum/enums";
import PreScheduleCancelModal from "./PreScheduleCancelModal";

import api from "../../../services/api";
import { hasAccess } from "../../../services/utils";
import { createLog } from "../../../services/logService";
import { updateSummaryCount } from "../../../services/summaryService";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
registerLocale("ptBR", ptBR);

const PreScheduleForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const cancelChildRef = useRef();
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [hiddenCancel, setHiddenCancel] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [loadingScheduleForm, setLoadingScheduleForm] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Gravando Informações...");

    const [name, setName] = useState("");
    const [healthPlan, setHealthPlan] = useState(undefined);
    const [healthPlansList, setHealthPlansList] = useState([]);
    const [healthPlansFullList, setHealthPlansFullList] = useState([]);
    const [accommodation, setAccommodation] = useState(undefined);
    const [mainDoctor, setMainDoctor] = useState(undefined);
    const [forwardDoctor, setForwardDoctor] = useState(undefined);
    const [doctorsList, setDoctorsList] = useState([]);
    const [doctorsFullList, setDoctorsFullList] = useState([]);
    const [lecDoctorsList, setLecDoctorsList] = useState([]);
    const [procedure, setProcedure] = useState(undefined);
    const [proceduresList, setProceduresList] = useState([]);
    const [complexity, setComplexity] = useState(undefined);
    const [hospital, setHospital] = useState(undefined);
    const [hospitalsList, setHospitalsList] = useState([]);
    const [hospitalsFullList, setHospitalsFullList] = useState([]);
    const [supplier, setSupplier] = useState(undefined);
    const [suppliersList, setSuppliersList] = useState([]);
    const [suppliersFullList, setSuppliersFullList] = useState([]);
    const [diagnosis, setDiagnosis] = useState("");
    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [cancelReason, setCancelReason] = useState("");
    const [cancelMessage, setCancelMessage] = useState("");

    const [operationalValue, setOperationalValue] = useState(0);
    const [operationalTaxes, setOperationalTaxes] = useState(0);
    const [operationalForwarder, setOperationalForwarder] = useState("");
    const [operationalForwarderCommission, setOperationalForwarderCommission] = useState(0);
    const [operationalDistribution, setOperationalDistribution] = useState([]);

    const [checklist0, setChecklist0] = useState(false);
    const [checklist1, setChecklist1] = useState(false);
    const [checklist2, setChecklist2] = useState(false);
    const [checklist3, setChecklist3] = useState(false);
    const [checklist4, setChecklist4] = useState(false);
    const [checklist5, setChecklist5] = useState(false);

    const [logs, setLogs] = useState([]);

    const { uploadedFiles, resetFiles, loadFiles } = useFiles();

    const [pacientId, setPacientId] = useState(undefined);
    const [scheduleId, setScheduleId] = useState(undefined);
    const [scheduleOriginalInfo, setScheduleOriginalInfo] = useState(undefined);

    const [invalidName, setInvalidName] = useState(false);
    const [invalidMainDoctor, setInvalidMainDoctor] = useState(false);
    const [invalidMainDoctorMessage, setInvalidMainDoctorMessage] = useState("Campo obrigatório");
    const [invalidForwardDoctor, setInvalidForwardDoctor] = useState(false);
    const [invalidProcedure, setInvalidProcedure] = useState(false);
    const [invalidHospital, setInvalidHospital] = useState(false);
    const [invalidHealthPlan, setInvalidHealthPlan] = useState(false);
    const [invalidAccommodation, setInvalidAccommodation] = useState(false);
    const [invalidComplexity, setInvalidComplexity] = useState(false);
    const [invalidScheduleDate, setInvalidScheduleDate] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o registro");

    const userInfo = JSON.parse(localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxUsr") : sessionStorage.getItem("xLcxUsr"));

    useImperativeHandle(ref, () => ({
        async openModal(mode, pacientInfo, scheduleInfo) {
            setModalBox(true);
            setIsUpdate(false);

            resetFields();

            getHealthPlans();
            getDoctors();
            getProcedures();
            getHospitals();
            getSuppliers();

            if (mode === "new") {
                if (pacientInfo) {
                    await loadPacient(pacientInfo);
                }
            } else {
                setIsUpdate(true);
                setScheduleOriginalInfo(scheduleInfo);
                resetFiles();
                if (["can", "done", "not"].includes(scheduleInfo.originalStatus) || !hasAccess("preschedule", "update")) {
                    setIsDisabled(true);

                    if (scheduleInfo.originalStatus === "can") {
                        getCancelReason(scheduleInfo.cancel_reason_id);
                        setCancelMessage(scheduleInfo.cancel_message);

                        setHiddenCancel(true);
                    }
                }
                await loadSchedule(scheduleInfo);
            }
        },
    }));

    async function loadPacient(pacientInfo) {
        setLoadingMessage("Recuperando informações...");
        setLoadingScheduleForm(true);

        setPacientId(pacientInfo._id);
        setName(pacientInfo.name);

        setHealthPlan(null);
        setAccommodation(null);

        if (pacientInfo.health_plan_id) {
            const healthPlanReturned = await getHealthPlanById(pacientInfo.health_plan_id);

            if (healthPlanReturned) {
                if (healthPlanReturned.status) {
                    setHealthPlan({
                        value: healthPlanReturned._id,
                        label: healthPlanReturned.name
                    });

                    if (pacientInfo.health_plan_accommodation) {
                        const accommodationItem = healthPlanAccommodation.find(x => x.value === pacientInfo.health_plan_accommodation);
                        setAccommodation(accommodationItem);
                    }
                } else {
                    swal("O convênio vinculado ao paciente está inativo, deverá ser selecionado outro no pré-agendamento!", { icon: "warning", });
                }
            }
        }

        setLoadingScheduleForm(false);
    }

    async function loadSchedule(scheduleInfo) {
        setLoadingMessage("Recuperando informações...");
        setLoadingScheduleForm(true);

        setScheduleId(scheduleInfo._id);

        setPacientId(scheduleInfo.pacient_id);
        setName(scheduleInfo.pacient_name);

        loadFiles(scheduleInfo.files);

        if (scheduleInfo.health_plan_id) {
            setHealthPlan({
                value: scheduleInfo.health_plan_id,
                label: scheduleInfo.health_plan_name
            });
        }

        if (scheduleInfo.health_plan_accommodation) {
            const accommodationItem = healthPlanAccommodation.find(x => x.value === scheduleInfo.health_plan_accommodation);
            setAccommodation(accommodationItem);
        }

        if (scheduleInfo.forward_doctor_id) {
            setForwardDoctor({
                value: scheduleInfo.forward_doctor_id,
                label: scheduleInfo.forward_doctor_name
            })
        }

        if (scheduleInfo.main_doctor_id) {
            setMainDoctor({
                value: scheduleInfo.main_doctor_id,
                label: scheduleInfo.main_doctor_name
            })
        }

        if (scheduleInfo.procedure_id) {
            setProcedure({
                value: scheduleInfo.procedure_id,
                label: scheduleInfo.procedure_name
            })
        }

        if (scheduleInfo.complexity) {
            const complexityItem = complexityList.find(x => x.value === scheduleInfo.complexity);
            setComplexity(complexityItem);
        }

        if (scheduleInfo.hospital_id) {
            setHospital({
                value: scheduleInfo.hospital_id,
                label: scheduleInfo.hospital_name
            })
        }

        if (scheduleInfo.supplier_id) {
            setSupplier({
                value: scheduleInfo.supplier_id,
                label: scheduleInfo.supplier_name
            })
        }

        setDiagnosis(scheduleInfo.diagnosis);

        if (scheduleInfo.schedule_date) {
            setScheduleDate(dayjs(scheduleInfo.schedule_date).toDate());
        }

        setChecklist0(scheduleInfo.checklist[0]);
        setChecklist1(scheduleInfo.checklist[1]);
        setChecklist2(scheduleInfo.checklist[2]);
        setChecklist3(scheduleInfo.checklist[3]);
        setChecklist4(scheduleInfo.checklist[4]);
        setChecklist5(scheduleInfo.checklist[5]);

        if (scheduleInfo.logs) {
            const logsInfo = scheduleInfo?.logs.map((log) => {
                return { ...log, badge: getLogBadge(log.status) }
            });
            setLogs(logsInfo.reverse());
        }

        if (scheduleInfo.health_plan_id &&
            scheduleInfo.health_plan_accommodation &&
            scheduleInfo.forward_doctor_id &&
            scheduleInfo.procedure_id &&
            scheduleInfo.complexity) {
            getCommission(
                scheduleInfo.forward_doctor_id,
                scheduleInfo.health_plan_id,
                scheduleInfo.health_plan_accommodation,
                scheduleInfo.procedure_id,
                scheduleInfo.complexity
            );
        }

        setLoadingScheduleForm(false);
    }

    async function getHealthPlans() {
        await api.get("/health-plans?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    setHealthPlansFullList(response.data.healthPlans);

                    let healthPlansReturned = [];
                    for (let i = 0; i < response.data.healthPlans.length; i++) {
                        healthPlansReturned.push({
                            value: response.data.healthPlans[i]._id,
                            label: response.data.healthPlans[i].name
                        })
                    }
                    setHealthPlansList(prevData => ([...prevData, ...healthPlansReturned]));
                }
            })
    }

    async function getHealthPlanById(id) {
        return new Promise(resolve => {
            api.get(`/health-plans/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function getDoctors() {
        await api.get("/doctors?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    setDoctorsFullList(response.data.doctors);

                    let doctorsReturned = [];
                    let lecDoctorsReturned = [];
                    for (let i = 0; i < response.data.doctors.length; i++) {
                        doctorsReturned.push({
                            value: response.data.doctors[i]._id,
                            label: response.data.doctors[i].name
                        });

                        if (response.data.doctors[i].lec_doctor) {
                            lecDoctorsReturned.push({
                                value: response.data.doctors[i]._id,
                                label: response.data.doctors[i].name
                            });
                        }
                    }
                    setDoctorsList(doctorsReturned);
                    setLecDoctorsList(lecDoctorsReturned);
                }
            })
    }

    async function getProcedures() {
        await api.get("/procedures?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    let proceduresReturned = [];
                    for (let i = 0; i < response.data.procedures.length; i++) {
                        proceduresReturned.push({
                            value: response.data.procedures[i]._id,
                            label: response.data.procedures[i].code + " - " + response.data.procedures[i].name
                        })
                    }
                    setProceduresList(proceduresReturned);
                }
            })
    }

    async function getHospitals() {
        await api.get("/hospitals?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    setHospitalsFullList(response.data.hospitals);

                    let hospitalsReturned = [];
                    for (let i = 0; i < response.data.hospitals.length; i++) {
                        hospitalsReturned.push({
                            value: response.data.hospitals[i]._id,
                            label: response.data.hospitals[i].name
                        })
                    }
                    setHospitalsList(hospitalsReturned);
                }
            })
    }

    async function getSuppliers() {
        await api.get("/suppliers?status=true&offset=1&limit=1000")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    setSuppliersFullList(response.data.suppliers);

                    let suppliersReturned = [];
                    for (let i = 0; i < response.data.suppliers.length; i++) {
                        suppliersReturned.push({
                            value: response.data.suppliers[i]._id,
                            label: response.data.suppliers[i].name
                        })
                    }
                    setSuppliersList(suppliersReturned);
                }
            })
    }

    async function getCancelReason(id) {
        await api.get(`/cancel-reasons/${id}`)
            .then(response => {
                if (response.status === 200) {
                    setCancelReason(`${response.data.code} - ${response.data.description}`);
                }
            })
    }

    async function getAvailabilty(doctorId, dateParm = scheduleDate) {
        return new Promise(resolve => {
            api.get(`/availabilities?doctorId=${doctorId}&startDate=${dayjs(dateParm).toISOString()}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function handleSaveSchedule(isScale = false) {
        setLoadingScheduleForm(true);

        setInvalidName(false);
        setInvalidHealthPlan(false);
        setInvalidMainDoctor(false);
        setInvalidMainDoctorMessage("Campo obrigatório");
        setInvalidForwardDoctor(false);
        setInvalidProcedure(false);
        setInvalidHospital(false);

        let hasError = false;

        if (name === "") {
            setInvalidName(true);
            hasError = true;
        }

        if (!healthPlan) {
            setInvalidHealthPlan(true);
            hasError = true;
        }

        if (!accommodation) {
            setInvalidAccommodation(true);
            hasError = true;
        }

        if (!mainDoctor) {
            setInvalidMainDoctor(true);
            hasError = true;
        }

        if (!forwardDoctor) {
            setInvalidForwardDoctor(true);
            hasError = true;
        }

        if (!procedure) {
            setInvalidProcedure(true);
            hasError = true;
        }

        if (!complexity) {
            setInvalidComplexity(true);
            hasError = true;
        }

        if (!hospital) {
            setInvalidHospital(true);
            hasError = true;
        }

        if (isScale) {
            if (!scheduleDate) {
                setInvalidScheduleDate(true);
                hasError = true;
            } else {
                const doctorAvailability = await getAvailabilty(mainDoctor.value, scheduleDate);
                if (doctorAvailability) {
                    if (doctorAvailability.total > 0) {
                        setInvalidMainDoctor(true);
                        setInvalidMainDoctorMessage("Médico não disponível na data informada");
                        hasError = true;
                    }
                }
            }
        }

        if (!hasError) {
            const forwardDoctorInfo = doctorsFullList.find(x => x._id === forwardDoctor?.value);
            const mainDoctorInfo = doctorsFullList.find(x => x._id === mainDoctor?.value);
            const healthPlanInfo = healthPlansFullList.find(x => x._id === healthPlan?.value);
            const hospitalInfo = hospitalsFullList.find(x => x._id === hospital?.value);
            const supplierInfo = suppliersFullList.find(x => x._id === supplier?.value);
            const isLecForwarder = (forwardDoctorInfo.lec_doctor || forwardDoctorInfo.lec_forwarder);

            let schedule = {
                status: (!isUpdate ? "pre" : scheduleOriginalInfo.originalStatus),
                pacient_id: pacientId,
                pacient_name: name,
                forward_doctor_id: forwardDoctorInfo._id ?? "",
                forward_doctor_name: forwardDoctorInfo.name ?? "",
                forward_doctor_short_name: forwardDoctorInfo.short_name ?? "",
                forward_doctor_lec: isLecForwarder ?? false,
                main_doctor_id: mainDoctorInfo._id,
                main_doctor_name: mainDoctorInfo.name,
                main_doctor_short_name: mainDoctorInfo.short_name ?? "",
                doctors: [
                    {
                        doctor_id: mainDoctorInfo._id,
                        doctor_name: mainDoctorInfo.name,
                        doctor_short_name: mainDoctorInfo.short_name ?? ""
                    }
                ],
                health_plan_id: healthPlanInfo._id,
                health_plan_name: healthPlanInfo.name,
                health_plan_short_name: healthPlanInfo.short_name ?? "",
                health_plan_accommodation: accommodation.value,
                procedure_id: procedure.value,
                procedure_name: procedure.label,
                complexity: complexity.value,
                hospital_id: hospitalInfo._id,
                hospital_name: hospitalInfo.name,
                hospital_short_name: hospitalInfo.short_name ?? "",
                supplier_id: supplierInfo._id ?? "",
                supplier_name: supplierInfo.name ?? "",
                supplier_short_name: supplierInfo.short_name ?? "",
                diagnosis,
                checklist: [checklist0, checklist1, checklist2, checklist3, checklist4, checklist5],
                files: [],
                logs: []
            };

            if (scheduleDate) {
                schedule.schedule_date = scheduleDate;
            }

            if (uploadedFiles) {
                for (let indexFiles = 0; indexFiles < uploadedFiles.length; indexFiles++) {
                    schedule.files.push({
                        filename: uploadedFiles[indexFiles].name,
                        original_name: uploadedFiles[indexFiles].originalName,
                        size: uploadedFiles[indexFiles].file.size
                    })
                }
            }

            if (!isUpdate) {
                try {
                    let initialLogs = [
                        {
                            status: "add",
                            comment: "Incluído pré-agendamento",
                            user: userInfo.name,
                            date: dayjs().toDate()
                        },
                        {
                            status: "pre",
                            comment: "Aguardando liberação",
                            user: userInfo.name,
                            date: dayjs().toDate()
                        }
                    ];

                    if (isScale) {
                        initialLogs.push({
                            status: "sch",
                            comment: "Agenda confirmada",
                            user: userInfo.name,
                            date: dayjs().toDate()
                        });

                        schedule.status = "sch";
                    }

                    schedule.logs = [...initialLogs];

                    await api.post("/schedules", schedule)
                        .then(response => {
                            if (response.status === 201) {
                                createLog({
                                    routine: "preschedule",
                                    action: "create",
                                    type: "success",
                                    message: `Pré-agedamento para ${schedule.pacient_name} incluído`,
                                    jsonOrigin: JSON.stringify(schedule),
                                    jsonReturn: JSON.stringify(response)
                                });

                                if (isScale) {
                                    updateSummaryCount(schedule.schedule_date, schedule.forward_doctor_id, "ADD", "forwarded");
                                }

                                setLoadingScheduleForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    createLog({
                        routine: "preschedule",
                        action: "create",
                        type: "error",
                        message: `Pré-agedamento para ${schedule.pacient_name} não incluído`,
                        jsonOrigin: JSON.stringify(schedule),
                        jsonReturn: JSON.stringify(err)
                    });

                    notifyError();
                }
            } else {
                const hasUpdate = (!dayjs(scheduleOriginalInfo.schedule_date).isSame(schedule.schedule_date) || scheduleOriginalInfo.health_plan_id !== schedule.health_plan_id ||
                    scheduleOriginalInfo.forward_doctor_id !== schedule.forward_doctor_id || scheduleOriginalInfo.main_doctor_id !== schedule.main_doctor_id ||
                    scheduleOriginalInfo.procedure_id !== schedule.procedure_id || scheduleOriginalInfo.hospital_id !== schedule.hospital_id ||
                    scheduleOriginalInfo.supplier_id !== schedule.supplier_id || scheduleOriginalInfo.diagnosis !== schedule.diagnosis ||
                    scheduleOriginalInfo.health_plan_accommodation !== schedule.health_plan_accommodation || scheduleOriginalInfo.complexity !== schedule.complexity ||
                    !scheduleOriginalInfo.checklist.every((element, index) => element === schedule.checklist[index]));

                if (hasUpdate || isScale) {
                    let newLog = [...scheduleOriginalInfo.logs];

                    if (hasUpdate) {
                        newLog.push({
                            status: "upd",
                            comment: "Agendamento alterado",
                            user: userInfo.name,
                            date: dayjs().toDate()
                        });
                    }

                    if (isScale) {
                        if (scheduleOriginalInfo.originalStatus !== "sch") {
                            newLog.push({
                                status: "sch",
                                comment: "Agendamento confirmado",
                                user: userInfo.name,
                                date: dayjs().toDate()
                            });

                            schedule.status = "sch";
                        }
                    }

                    if (scheduleOriginalInfo.originalStatus === "sch") {
                        updateSummaryCount(scheduleOriginalInfo.schedule_date, scheduleOriginalInfo.forward_doctor_id, "SUB", "forwarded");
                    }

                    schedule.logs = [...newLog];
                    schedule.doctors = [ ...scheduleOriginalInfo.doctors ];

                    try {
                        await api.put(`/schedules/${scheduleId}`, schedule)
                            .then(response => {
                                if (response.status === 200) {
                                    createLog({
                                        routine: "preschedule",
                                        action: "update",
                                        type: "success",
                                        message: `Pré-agedamento para ${schedule.pacient_name} alterado`,
                                        jsonOrigin: JSON.stringify(schedule),
                                        jsonReturn: JSON.stringify(response)
                                    });

                                    if (isScale || scheduleOriginalInfo.originalStatus === "sch") {
                                        updateSummaryCount(schedule.schedule_date, schedule.forward_doctor_id, "ADD", "forwarded");
                                    }

                                    setLoadingScheduleForm(false);
                                    notifySuccess();
                                    resetFields();
                                    onClose();
                                    setModalBox(false);
                                }
                            })
                    } catch (err) {
                        createLog({
                            routine: "preschedule",
                            action: "update",
                            type: "error",
                            message: `Pré-agedamento para ${schedule.pacient_name} não alterado`,
                            jsonOrigin: JSON.stringify(schedule),
                            jsonReturn: JSON.stringify(err)
                        });

                        notifyError();
                    }
                } else {
                    notifyNoUpdate();
                    resetFields();
                    setModalBox(false);
                }
            }
        }

        setLoadingScheduleForm(false);
    }

    async function handleCancelSchedule(cancelReason, cancelMessage) {
        setLoadingScheduleForm(true);

        let scheduleToUpdate = { ...scheduleOriginalInfo };

        let newLog = [];
        if (scheduleToUpdate.logs) {
            newLog = [...scheduleToUpdate.logs];
        }
        newLog.push({
            status: "can",
            comment: "Agendamento cancelado",
            user: userInfo.name,
            date: dayjs().toDate()
        });
        scheduleToUpdate.logs = [...newLog];
        scheduleToUpdate.status = "can";
        scheduleToUpdate.cancel_reason_id = cancelReason;
        scheduleToUpdate.cancel_message = cancelMessage;

        try {
            await api.put(`/schedules/${scheduleId}`, scheduleToUpdate)
                .then(async response => {
                    if (response.status === 200) {
                        createLog({
                            routine: "preschedule",
                            action: "cancel",
                            type: "success",
                            message: `Pré-agedamento para ${scheduleToUpdate.pacient_name} cancelado`,
                            jsonOrigin: JSON.stringify(scheduleToUpdate),
                            jsonReturn: JSON.stringify(response)
                        });

                        if (scheduleOriginalInfo.originalStatus === "sch") {
                            await updateSummaryCount(scheduleOriginalInfo.schedule_date, scheduleOriginalInfo.forward_doctor_id, "SUB", "forwarded")
                                .then(response => {
                                    for (let indexDoctors = 0; indexDoctors < scheduleOriginalInfo.doctors.length; indexDoctors++) {
                                        updateSummaryCount(scheduleOriginalInfo.schedule_date, scheduleOriginalInfo.doctors[indexDoctors].doctor_id, "SUB", "completed");
                                    }
                                })                            
                        }

                        setLoadingScheduleForm(false);
                        notifySuccess();
                        resetFields();
                        onClose();
                        setModalBox(false);
                    }
                })
        } catch (err) {
            createLog({
                routine: "preschedule",
                action: "cancel",
                type: "error",
                message: `Pré-agedamento para ${scheduleToUpdate.pacient_name} não cancelado`,
                jsonOrigin: JSON.stringify(scheduleToUpdate),
                jsonReturn: JSON.stringify(err)
            });

            notifyError();
        }

        setLoadingScheduleForm(false);
    }

    function handleGetCommission(doctorId = "") {
        if (doctorId) {
            const lecDoctorFound = lecDoctorsList.find(x => x.value === doctorId);
            if (lecDoctorFound) {
                setMainDoctor(lecDoctorFound);
            }
        }

        if (forwardDoctor && healthPlan && accommodation && procedure && complexity) {
            getCommission(forwardDoctor.value, healthPlan.value, accommodation.value, procedure.value, complexity.value);
        }
    }

    async function getCommission(forwardDoctorId, healthPlanId, healthPlanTypeId, procedureId, complexityId) {
        const commissionObj = {
            forward_doctor_id: forwardDoctorId,
            health_plan_id: healthPlanId,
            health_plan_accommodation: healthPlanTypeId,
            procedure_id: procedureId,
            complexity: complexityId
        };

        api.post("/operationals/commissions", commissionObj)
            .then(response => {
                if (response.status === 200) {
                    const commissionData = response.data;

                    if (commissionData) {
                        setOperationalValue(commissionData.total);
                        setOperationalTaxes(commissionData.tax);
                        setOperationalForwarder(commissionData.forwarder.doctor_name);
                        setOperationalForwarderCommission(commissionData.forwarder.value);
                        setOperationalDistribution(commissionData.distribution);
                    }
                }
            })
    }

    function notifySuccess() {
        toast.success(`✔️ Pré-Agendamento ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyNoUpdate() {
        toast.success(`✔️ Nenhuma informação foi alterada!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setScheduleId(undefined);
        setPacientId(null);
        setName("");
        setHealthPlan(null);
        setHealthPlansList([]);
        setAccommodation(null);
        setMainDoctor(null);
        setForwardDoctor(null);
        setProcedure(null);
        setComplexity(null);
        setHospital(null);
        setSupplier(null);
        setDiagnosis("");
        setScheduleDate("");
        setIsDisabled(false);
        setCancelReason("");
        setCancelMessage("");
        setHiddenCancel(false);

        setOperationalValue(0);
        setOperationalTaxes(0);
        setOperationalForwarder("");
        setOperationalForwarderCommission(0);
        setOperationalDistribution([]);

        setScheduleOriginalInfo(undefined);

        setInvalidName(false);
        setInvalidHealthPlan(false);
        setInvalidMainDoctor(false);
        setInvalidMainDoctorMessage("Campo obrigatório");
        setInvalidForwardDoctor(false);
        setInvalidProcedure(false);
        setInvalidHospital(false);
        setInvalidAccommodation(false);
        setInvalidComplexity(false);

        setLoadingMessage("Gravando Informações...");
        setErrorMessage("Ocorreu um problema ao salvar o registro");
    }

    function getLogBadge(status) {
        let badgeColor = "warning";

        switch (status) {
            case "sch":
                badgeColor = "info";
                break;
            case "not":
                badgeColor = "dark";
                break;
            case "can":
                badgeColor = "danger";
                break;
            case "done":
                badgeColor = "success";
                break;
            case "upd":
                badgeColor = "secondary";
                break;
            default:
                badgeColor = "warning";
                break;
        }

        return badgeColor;
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            Pré-Agendamento
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <Tabs
                                        defaultActiveKey="general"
                                        id="fill-tab-example"
                                        className="mb-3 basic-list-group"
                                        fill
                                    >
                                        <Tab eventKey="general" title="Informações Gerais">
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-9">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Paciente</label>
                                                            <input
                                                                type="text"
                                                                value={name}
                                                                className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                                                onChange={e => setName(e.target.value)}
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Convênio</label>
                                                            <Select
                                                                className={`${(invalidHealthPlan ? "form-control is-invalid" : "")}`}
                                                                defaultValue={healthPlan}
                                                                value={healthPlan}
                                                                onChange={(e) => setHealthPlan(e)}
                                                                options={healthPlansList}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                onBlur={handleGetCommission}
                                                                isDisabled={isDisabled}
                                                            />
                                                            <DFormText hidden={!invalidHealthPlan} color="danger">Campo obrigatório</DFormText>
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Tipo de Acomodação</label>
                                                            <Select
                                                                className={`${(invalidAccommodation ? "form-control is-invalid" : "")}`}
                                                                defaultValue={accommodation}
                                                                value={accommodation}
                                                                onChange={(e) => setAccommodation(e)}
                                                                options={healthPlanAccommodation}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                onBlur={handleGetCommission}
                                                                isDisabled={isDisabled}
                                                            />
                                                            <DFormText hidden={!invalidAccommodation} color="danger">Campo obrigatório</DFormText>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Médico Encaminhador</label>
                                                            <Select
                                                                className={`${(invalidForwardDoctor ? "form-control is-invalid" : "")}`}
                                                                defaultValue={forwardDoctor}
                                                                value={forwardDoctor}
                                                                onChange={(e) => setForwardDoctor(e)}
                                                                options={doctorsList}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                onBlur={() => handleGetCommission(forwardDoctor?.value)}
                                                                isDisabled={isDisabled}
                                                            />
                                                            <DFormText hidden={!invalidForwardDoctor} color="danger">Campo obrigatório</DFormText>
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Médico Responsável</label>
                                                            <Select
                                                                className={`${(invalidMainDoctor ? "form-control is-invalid" : "")}`}
                                                                defaultValue={mainDoctor}
                                                                value={mainDoctor}
                                                                onChange={(e) => setMainDoctor(e)}
                                                                options={lecDoctorsList}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                isDisabled={isDisabled}
                                                            />
                                                            <DFormText hidden={!invalidMainDoctor} color="danger">{invalidMainDoctorMessage}</DFormText>
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Hospital</label>
                                                            <Select
                                                                className={`${(invalidHospital ? "form-control is-invalid" : "")}`}
                                                                defaultValue={hospital}
                                                                value={hospital}
                                                                onChange={(e) => setHospital(e)}
                                                                options={hospitalsList}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                isDisabled={isDisabled}
                                                            />
                                                            <DFormText hidden={!invalidHospital} color="danger">Campo obrigatório</DFormText>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Procedimento</label>
                                                            <Select
                                                                className={`${(invalidProcedure ? "form-control is-invalid" : "")}`}
                                                                defaultValue={procedure}
                                                                value={procedure}
                                                                onChange={(e) => setProcedure(e)}
                                                                options={proceduresList}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                onBlur={handleGetCommission}
                                                                isDisabled={isDisabled}
                                                            />
                                                            <DFormText hidden={!invalidProcedure} color="danger">Campo obrigatório</DFormText>
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Complexidade</label>
                                                            <Select
                                                                className={`${(invalidComplexity ? "form-control is-invalid" : "")}`}
                                                                defaultValue={complexity}
                                                                value={complexity}
                                                                onChange={(e) => setComplexity(e)}
                                                                options={complexityList}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                onBlur={handleGetCommission}
                                                                isDisabled={isDisabled}
                                                            />
                                                            <DFormText hidden={!invalidComplexity} color="danger">Campo obrigatório</DFormText>
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Fornecedor</label>
                                                            <Select
                                                                defaultValue={supplier}
                                                                value={supplier}
                                                                onChange={(e) => setSupplier(e)}
                                                                options={suppliersList}
                                                                styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        height: 45,
                                                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                                                    }),
                                                                }}
                                                                placeholder="Selecione..."
                                                                isDisabled={isDisabled}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Diagnóstico</label>
                                                            <input
                                                                type="text"
                                                                value={diagnosis}
                                                                className="form-control"
                                                                onChange={e => setDiagnosis(e.target.value)}
                                                                disabled={isDisabled}
                                                                maxLength={10}
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Data/Hora</label>
                                                            <br />
                                                            <DatePicker
                                                                selected={scheduleDate}
                                                                onChange={(date) => setScheduleDate(date)}
                                                                locale="ptBR"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={15}
                                                                dateFormat="Pp"
                                                                className={`form-control ${(invalidScheduleDate ? " is-invalid" : "")}`}
                                                                timeCaption="Hora"
                                                                disabled={isDisabled}
                                                            />
                                                            <br />
                                                            <DFormText hidden={!invalidScheduleDate} color="danger">Campo obrigatório</DFormText>
                                                        </div>
                                                    </div>
                                                    <div className="row" hidden={cancelReason === ""}>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Motivo de Cancelamento</label>
                                                            <input
                                                                type="text"
                                                                value={cancelReason}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Observação do Cancelamento</label>
                                                            <input
                                                                type="text"
                                                                value={cancelMessage}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <div className="card">
                                                        <div className="card-header border-0 pb-0">
                                                            <img src={imageLogo} alt="Cardiosis" width="160" height="80" />
                                                        </div>
                                                        <div className="card-body">
                                                            <PerfectScrollbar
                                                                style={{ height: "370px" }}
                                                                id="DZ_W_TimeLine1"
                                                                className="widget-timeline dlab-scroll style-1 height370 ps ps--active-y"
                                                            >
                                                                <ul className="timeline">
                                                                    {
                                                                        logs.map((log) => {
                                                                            return (
                                                                                <li>

                                                                                    <div className={`timeline-badge ${log.badge}`}></div>
                                                                                    <div className="timeline-panel text-muted">
                                                                                        <span>{dayjs(log.date).format("DD/MM/YYYY HH:mm")}</span>
                                                                                        <p className="mb-0">
                                                                                            {log.comment}
                                                                                        </p>
                                                                                        <p className="mb-0">
                                                                                            Usuário: {log.user}
                                                                                        </p>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </PerfectScrollbar>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="operational" title="Operacional">
                                            <h4>VALORES PREVISTOS</h4>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-6">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Valor a faturar (+)</label>
                                                            <input
                                                                type="text"
                                                                value={operationalValue.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Impostos (-)</label>
                                                            <input
                                                                type="text"
                                                                value={operationalTaxes.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Valor líquido (=)</label>
                                                            <input
                                                                type="text"
                                                                value={(operationalValue - operationalTaxes).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div className="row">
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Encaminhador</label>
                                                            <input
                                                                type="text"
                                                                value={operationalForwarder}
                                                                className="form-control"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Comissão (-)</label>
                                                            <input
                                                                type="text"
                                                                value={operationalForwarderCommission.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                        <div className="form-group mb-3 col-md-4">
                                                            <label>Saldo a distribuir (=)</label>
                                                            <input
                                                                type="text"
                                                                value={(operationalValue - operationalTaxes - operationalForwarderCommission).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                className="form-control text-end"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 col-md-6">
                                                    <div className="row">
                                                        <h6>Distribuição Prevista</h6>

                                                        <div className="form-group mb-3 col-md-12">
                                                            <Table responsive>
                                                                <thead>
                                                                    <tr>
                                                                        <th>
                                                                            <strong>Médico</strong>
                                                                        </th>
                                                                        <th className="text-center">
                                                                            <strong>%</strong>
                                                                        </th>
                                                                        <th className="text-center">
                                                                            <strong>Valor Previsto</strong>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        operationalDistribution.map((operationalItem) => {
                                                                            return (
                                                                                <tr key={operationalItem.doctor_id}>
                                                                                    <td>
                                                                                        <div className="text-start">
                                                                                            <span className="w-space-no">{operationalItem.doctor_name}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="text-end">
                                                                                            {operationalItem.percent.toLocaleString('pt-br')}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="text-end">
                                                                                            {operationalItem.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="checklist" title="Check-List">
                                            <h4>INFORMAÇÕES PARA AGENDAMENTO DE PROCEDIMENTO</h4>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked={checklist0}
                                                            onChange={() => setChecklist0(!checklist0)}
                                                            disabled={isDisabled}
                                                        />
                                                        <label className="form-check-label">Liberação válida pelo convênio</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked={checklist1}
                                                            onChange={() => setChecklist1(!checklist1)}
                                                            disabled={isDisabled}
                                                        />
                                                        <label className="form-check-label">Agendar data com o hospital</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked={checklist2}
                                                            onChange={() => setChecklist2(!checklist2)}
                                                            disabled={isDisabled}
                                                        />
                                                        <label className="form-check-label">Realização de consulta pré-anestésica</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked={checklist3}
                                                            onChange={() => setChecklist3(!checklist3)}
                                                            disabled={isDisabled}
                                                        />
                                                        <label className="form-check-label">Realização dos exames pré-operatório</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked={checklist4}
                                                            onChange={() => setChecklist4(!checklist4)}
                                                            disabled={isDisabled}
                                                        />
                                                        <label className="form-check-label">Lista de medicamentos</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            defaultChecked={checklist5}
                                                            onChange={() => setChecklist5(!checklist5)}
                                                            disabled={isDisabled}
                                                        />
                                                        <label className="form-check-label">Termo de consentimento assinado</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="documents" title="Documentos">
                                            <UploadFile disabled={isDisabled} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sweetalert ms-3 mb-3 mt-5">
                        <Button
                            variant="danger btn-rounded"
                            hidden={!isUpdate || !hasAccess("preschedule", "delete") || hiddenCancel}
                            disabled={isDisabled}
                            onClick={() => cancelChildRef.current.openModal()}
                        >
                            Cancelar Pré-Agendamento
                        </Button>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={() => setModalBox(false)}
                        >
                            Fechar
                        </Button>
                        <Dropdown hidden={isDisabled}>
                            <Dropdown.Toggle className="ms-2" variant="success btn-rounded">
                                Salvar
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleSaveSchedule(false)}>Salvar</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleSaveSchedule(true)}>Salvar e enviar escala</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </Modal >

            <PreScheduleCancelModal ref={cancelChildRef} onClose={handleCancelSchedule} />

            <ToastContainer />

            <ModalLoading
                visible={loadingScheduleForm}
                message={loadingMessage}
                onClose={setLoadingScheduleForm}
            />
        </>
    );
});

export default PreScheduleForm;
