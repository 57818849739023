import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Modal,
  Button,
} from "react-bootstrap";
import DCard from '../../components/card/DCard';
import DCardHeader from '../../components/card/DCardHeader';
import DCardBody from '../../components/card/DCardBody';
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import DFormText from "../../components/DFormText/DFormText";

import ModalLoading from "../../components/ModalLoading/ModalLoading";

import api from "../../../services/api";
import { isAuthenticated, hasAccess } from "../../../services/utils";

import { yesNoOptions } from "../../../enum/enums";
import { createLog } from "../../../services/logService";

const ComplicationsForm = forwardRef((props, ref) => {
  const [modalBox, setModalBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("Salvando formulário...");
  const [loadingComplicatiomForm, setLoadingComplicatiomForm] = useState(false);
  const [complicationType, setComplicationType] = useState("");
  const [isInvalidComplicationType, setIsInvalidComplicationType] = useState(false);
  const [lateComplications, setLateComplications] = useState("");
  const [weekComplications, setWeekComplications] = useState("");
  const [diagnosis, setDiagnosis] = useState("")
  const [operatorOne, setOperatorOne] = useState("")
  const [operatorTwo, setOperatorTwo] = useState("")
  const [procedures, setProcedures] = useState("")
  const [conventionalAblation, setConventionalAblation] = useState("")
  const [complexAblation, setComplexAblation] = useState("")
  const [complexAblationOthers, setComplexAblationOthers] = useState("")
  const [cryoablation, setCryoablation] = useState("")
  const [cryoablationOthers, setCryoablationOthers] = useState("")
  const [implant, setImplant] = useState("")
  const [electrodeExtraction, setElectrodeExtraction] = useState("")
  const [occlusion, setOcclusion] = useState("")
  const [material, setMaterial] = useState("")
  const [materialOthers, setMaterialOthers] = useState("")
  const [puncture, setPuncture] = useState("")
  const [femoralPunctures, setFemoralPunctures] = useState("")
  const [preProcedureComplications, setPreProcedureComplications] = useState("")
  const [preProcedureComplicationsDesc, setPreProcedureComplicationsDesc] = useState("")
  const [complicationsDuringProcedure, setComplicationsDuringProcedure] = useState([])
  const [complicationDesc, setComplicationDesc] = useState("")
  const [catheterProblems, setCatheterProblems] = useState("")
  const [catheterProblemsDesc, setCatheterProblemsDesc] = useState("")
  const [sheathProblems, setSheathProblems] = useState("")
  const [sheathProblemsDesc, setSheathProblemsDesc] = useState("")
  const [devicesProblems, setDevicesProblems] = useState([])
  const [devicesProblemsDesc, setDevicesProblemsDesc] = useState("")
  const [interferenceSolved, setInterferenceSolved] = useState("")
  const [interferenceDescription, setInterferenceDescription] = useState("")
  const [comments, setComments] = useState("")
  const [examNumber, setExamNumber] = useState("")
  const [doctorList, setDoctorsList] = useState("")
  const [isUpdate, setIsUpdate] = useState(false)
  const [complicationId, setComplicationId] = useState(false)
  const [errorSaveForm, setErrorSaveForm] = useState(false)
  const [complicationForm, setComplicationForm] = useState({})

  const history = useHistory();

  useImperativeHandle(ref, () => ({
    async openModal(paramId, mode = {}) {
      setProcedureFields(paramId, mode);
    },
  }));

  useEffect(() => {
    if (!isAuthenticated()) {
      return history.push("/login");
    }

  }, [])

  async function setProcedureFields(paramId, mode) {
    setLoading(true)
    setLoadingMessage("Carregando Informações...")

    if (mode === "new") {
      setComplicationType("")
      setWeekComplications("")
      setLateComplications("")
      setDiagnosis("")
      setOperatorOne("")
      setOperatorTwo("")
      setProcedures("")
      setConventionalAblation("")
      setComplexAblation("")
      setComplexAblationOthers("")
      setCryoablation("")
      setCryoablationOthers("")
      setImplant("")
      setElectrodeExtraction("")
      setOcclusion("")
      setMaterial("")
      setMaterialOthers("")
      setPuncture("")
      setFemoralPunctures("")
      setPreProcedureComplications("")
      setPreProcedureComplicationsDesc("")
      setComplicationsDuringProcedure([])
      setComplicationDesc("")
      setCatheterProblems("")
      setCatheterProblemsDesc("")
      setSheathProblems("")
      setSheathProblemsDesc("")
      setDevicesProblems([])
      setDevicesProblemsDesc("")
      setInterferenceSolved("")
      setInterferenceDescription("")
      setComments("")

      await api.get(`/schedules/${paramId}`)
        .then(response => {
          if (response.status === 200) {
            const scheduleInfo = response.data.reports[0]
            const complicationInfo = {
              schedule_id: paramId,
              pacient_id: scheduleInfo.pacient_id,
              pacient_name: scheduleInfo.pacient_name,
              birth_date: scheduleInfo.pacient_birth_date,
              procedure_date: scheduleInfo.execution_date,
              hospital_name: scheduleInfo.hospital_name,
              gender: scheduleInfo.pacient_gender.substring(0, 1),
              doctor_id: scheduleInfo.main_doctor_id,
              doctor_name: scheduleInfo.main_doctor_name
            }

            setExamNumber(response.data.reports[0].eef_number)
            setComplicationForm(complicationInfo)
          }
        });

    } else {
      setIsUpdate(true)
      setComplicationId(paramId)
      await api.get(`/complications/${paramId}`)
        .then(response => {
          if (response.status === 200) {
            const formData = response.data
            let complicationsDuringProcedureArray = []
            let devicesProblemsArray = []

            formData.procedure_complications.forEach(element => {
              complicationsDuringProcedureArray.push({
                value: element.procedure_complications_id,
                label: element.procedure_complications_name
              })
            })

            formData.device_problems.forEach(element => {
              devicesProblemsArray.push({
                value: element.device_problem_id,
                label: element.device_problem_name
              })
            })
            const complicationInfo = {
              pacient_id: formData.pacient_id,
              pacient_name: formData.pacient_name,
              birth_date: formData.birth_date,
              procedure_date: formData.procedure_date,
              hospital_name: formData.hospital_name,
              gender: formData.gender,
              doctor_id: formData.doctor_id,
              doctor_name: formData.doctor_name
            }
            setComplicationForm(complicationInfo)
            setComplicationType({
              value: formData.type_id,
              label: formData.type_name
            })
            setExamNumber(formData.exam_number)

            if (formData.type_id === "1") {
              setWeekComplications(formData.first_complication)
              setLateComplications(formData.late_complications)
            } else {
              setDiagnosis(formData.final_diagnosis)
              setOperatorOne({
                value: formData.operator1_id,
                label: formData.operator1_name
              })
              setOperatorTwo({
                value: formData.operator2_id,
                label: formData.operator2_name
              })
              setProcedures({
                value: formData.procedure_id,
                label: formData.procedure_name
              })
              setConventionalAblation({
                value: formData.normal_ablation_id,
                label: formData.normal_ablation_name
              })
              setComplexAblation({
                value: formData.complex_ablation_id,
                label: formData.complex_ablation_name
              })
              setComplexAblation({
                value: formData.complex_ablation_id,
                label: formData.complex_ablation_name
              })
              setComplexAblationOthers(formData.complex_ablation_other)
              setCryoablation({
                value: formData.cryoablation_id,
                label: formData.cryoablation_name
              })
              setCryoablationOthers(formData.cryoablation_other)
              setImplant({
                value: formData.pacemaker_id,
                label: formData.pacemaker_name
              })
              setElectrodeExtraction({
                value: formData.eletrode_extraction_id,
                label: formData.eletrode_extraction_name
              })
              setOcclusion({
                value: formData.aae_occlusion_id,
                label: formData.aae_occlusion_name
              })
              setMaterial({
                value: formData.material_id,
                label: formData.material_name
              })
              setMaterialOthers(formData.material_other)
              setPuncture({
                value: formData.punches_id,
                label: formData.punches_name
              })
              setFemoralPunctures({
                value: formData.femoral_punches_id,
                label: formData.femoral_punches_name
              })
              setPreProcedureComplications({
                value: formData.preprocedure_complications_id,
                label: formData.preprocedure_complications_name
              })
              setPreProcedureComplicationsDesc(formData.preprocedure_complications_description)
              setComplicationsDuringProcedure(complicationsDuringProcedureArray)
              setComplicationDesc(formData.procedure_complications_description)
              setCatheterProblems({
                value: formData.catheter_problem_id,
                label: formData.catheter_problem_name
              })
              setCatheterProblemsDesc(formData.catheter_problem_description)
              setSheathProblems({
                value: formData.sheath_problem_id,
                label: formData.sheath_problem_name
              })
              setSheathProblemsDesc(formData.sheath_problem_description)
              setDevicesProblems(devicesProblemsArray)
              setDevicesProblemsDesc(formData.device_problems_description)
              setInterferenceSolved({
                value: formData.interference_solved_id,
                label: formData.interference_solved_name
              })
              setInterferenceDescription(formData.interference_description)
              setComments(formData.comments)
            };
          }
        })
    };

    await api.get("/doctors?status=true&lec=true")
      .then(response => {
        if (response.status === 200 || response.status === 304) {
          const doctorsReturned = response.data.doctors.map(doctor => {
            return {
              value: doctor._id,
              label: doctor.name
            }
          });
          setDoctorsList(doctorsReturned);
        }
      })

    setLoading(false);
    setModalBox(true);
  };

  function handleSaveForm() {

    if (!complicationType) {
      setErrorSaveForm(true)
      return
    };

    setLoadingMessage("Salvando formulário...")

    let newForm = { ...complicationForm }

    newForm.type_id = complicationType.value;
    newForm.type_name = complicationType.label;
    newForm.exam_number = examNumber;
    if (complicationType.value === "1") {
      newForm.first_complication = weekComplications
      newForm.late_complications = lateComplications
    } else {
      let complicationsDuringProcedureArray = []
      let devicesProblemsArray = []

      complicationsDuringProcedure.forEach(element =>
        complicationsDuringProcedureArray.push({
          "procedure_complications_id": element.value,
          "procedure_complications_name": element.label
        })
      );

      devicesProblems.forEach(element =>
        devicesProblemsArray.push({
          "device_problem_id": element.value,
          "device_problem_name": element.label
        })
      );

      newForm.final_diagnosis = diagnosis
      newForm.operator1_id = operatorOne.value
      newForm.operator1_name = operatorOne.label
      newForm.operator2_id = operatorTwo.value
      newForm.operator2_name = operatorTwo.label
      newForm.procedure_id = procedures.value
      newForm.procedure_name = procedures.label
      newForm.normal_ablation_id = conventionalAblation.value
      newForm.normal_ablation_name = conventionalAblation.label
      newForm.complex_ablation_id = complexAblation.value
      newForm.complex_ablation_name = complexAblation.label
      if (complexAblation.value === "OUT") {
        newForm.complex_ablation_other = complexAblationOthers
      } else {
        newForm.complex_ablation_other = ""
      }
      newForm.cryoablation_id = cryoablation.value
      newForm.cryoablation_name = cryoablation.label
      if (cryoablation.value === "OUT") {
        newForm.cryoablation_other = cryoablationOthers
      } else {
        newForm.cryoablation_other = ""
      }
      newForm.pacemaker_id = implant.value
      newForm.pacemaker_name = implant.label
      newForm.eletrode_extraction_id = electrodeExtraction.value
      newForm.eletrode_extraction_name = electrodeExtraction.label
      newForm.aae_occlusion_id = occlusion.value
      newForm.aae_occlusion_name = occlusion.label
      newForm.material_id = material.value
      newForm.material_name = material.label
      if (material.value === "OUT") {
        newForm.material_other = materialOthers
      } else {
        newForm.material_other = ""
      }
      newForm.punches_id = puncture.value
      newForm.punches_name = puncture.label
      newForm.femoral_punches_id = femoralPunctures.value
      newForm.femoral_punches_name = femoralPunctures.label
      newForm.preprocedure_complications_id = preProcedureComplications.value
      newForm.preprocedure_complications_name = preProcedureComplications.label
      newForm.preprocedure_complications_description = preProcedureComplicationsDesc
      newForm.procedure_complications = complicationsDuringProcedureArray
      newForm.procedure_complications_description = complicationDesc
      newForm.catheter_problem_id = catheterProblems.value
      newForm.catheter_problem_name = catheterProblems.label
      newForm.catheter_problem_description = catheterProblemsDesc
      newForm.sheath_problem_id = sheathProblems.value
      newForm.sheath_problem_name = sheathProblems.label
      newForm.sheath_problem_description = sheathProblemsDesc
      newForm.device_problems = devicesProblemsArray
      newForm.device_problems_description = devicesProblemsDesc
      newForm.interference_solved_id = interferenceSolved.value
      newForm.interference_solved_name = interferenceSolved.label
      newForm.interference_description = interferenceDescription
      newForm.comments = comments
    };

    if (!isUpdate) {
      try {
        api.post("/complications", newForm)
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              createLog({
                routine: "complication",
                action: "create",
                type: "success",
                message: `Complicação para ${newForm.pacient_name} incluído`,
                jsonOrigin: JSON.stringify(newForm),
                jsonReturn: JSON.stringify(response)
              });

              notifySuccess();
            }
          })
      } catch (err) {
        createLog({
          routine: "complication",
          action: "create",
          type: "error",
          message: `Complicação para ${newForm.pacient_name} não incluído`,
          jsonOrigin: JSON.stringify(newForm),
          jsonReturn: JSON.stringify(err)
        });

        notifyError();
      };
    } else {
      try {
        api.put(`/complications/${complicationId}`, newForm)
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              createLog({
                routine: "complication",
                action: "update",
                type: "success",
                message: `Complicação para ${newForm.pacient_name} alterada`,
                jsonOrigin: JSON.stringify(newForm),
                jsonReturn: JSON.stringify(response)
              });

              notifySuccess();
            }
          })
      } catch (err) {
        createLog({
          routine: "complication",
          action: "update",
          type: "error",
          message: `Complicação para ${newForm.pacient_name} não alterada`,
          jsonOrigin: JSON.stringify(newForm),
          jsonReturn: JSON.stringify(err)
        });

        notifyError();
      };
    };

    setModalBox(false)
  };

  function notifySuccess() {
    toast.success(`✔️ Complicação ${(isUpdate) ? "alterada" : "incluída"} com sucesso!`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  function notifyError() {
    toast.error(`❌ Ocorreu um problema ao ${(isUpdate) ? "alterar" : "salvar"} a complicação`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  return (
    <>
      <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
        <div className="modal-content">
          <div className="modal-header">
            <div>
              <h3
                className="modal-title"
                id="exampleModalLabel"
              >
                {`${(isUpdate ? "Editar" : "Incluir")} complicação`}
              </h3>
            </div>
          </div>
          <div className="modal-body">
            <div className="basic-form">
              <div className="row">
                <div className="form-group mb-3 col-md-3">
                  <label>Tipo de complicação</label>
                  <Select
                    className={`${(isInvalidComplicationType ? "form-control is-invalid" : "")}`}
                    defaultValue={complicationType}
                    value={complicationType}
                    onChange={e => setComplicationType(e)}
                    isDisabled={isUpdate}
                    options={[
                      { value: "1", label: "Complicações tardias ablação" },
                      { value: "2", label: "Complicações ablação" }
                    ]}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        height: 45,
                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                      }),
                    }}
                    placeholder="Selecione..."
                  />
                  <DFormText hidden={!errorSaveForm} color="danger">Campo obrigatório</DFormText>
                </div>
              </div>
              <br />
              <div className="row" hidden={complicationType.value !== "1"}>
                <div className="row">
                  <div className="form-group mb-3 col-md-2">
                    <label>Número do exame</label>
                    <input
                      autoFocus
                      type="text"
                      className="form-control"
                      value={examNumber}
                      onChange={e => setExamNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label>Complicações na primeira semana pós procedimento</label>
                    <textarea
                      type="text"
                      rows={6}
                      className="form-control"
                      value={weekComplications}
                      onChange={e => setWeekComplications(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label>Complicações tardias</label>
                    <textarea
                      type="text"
                      rows={6}
                      className="form-control"
                      value={lateComplications}
                      onChange={e => setLateComplications(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row" hidden={complicationType.value !== "2"}>
                <div className="col-xl-12 col-lg-12">
                  <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Gerais</DCardHeader>
                    <DCardBody>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Número do exame</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={examNumber}
                            onChange={e => setExamNumber(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Diagnóstico</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={diagnosis}
                            onChange={e => setDiagnosis(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Operador 1 (cateter)</label>
                          <Select
                            defaultValue={operatorOne}
                            value={operatorOne}
                            onChange={e => setOperatorOne(e)}
                            options={doctorList}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Operador 2 (polígrafo)</label>
                          <Select
                            defaultValue={operatorTwo}
                            value={operatorTwo}
                            onChange={e => setOperatorTwo(e)}
                            options={doctorList}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                      </div>
                    </DCardBody>
                  </DCard>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Procedimento</DCardHeader>
                    <DCardBody>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Procedimentos</label>
                          <Select
                            defaultValue={procedures}
                            value={procedures}
                            onChange={e => setProcedures(e)}
                            options={[
                              { value: "EEF", label: "Estudo eletrofisiológico" },
                              { value: "ABNOR", label: "Ablação convencional" },
                              { value: "ABCOM", label: "Ablação complexa" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Ablação Convencional</label>
                          <Select
                            defaultValue={conventionalAblation}
                            value={conventionalAblation}
                            onChange={e => setConventionalAblation(e)}
                            options={[
                              { value: "TRN", label: "TRN" },
                              { value: "WPW", label: "WPW" },
                              { value: "TAQATR", label: "Taquicardia atrial" },
                              { value: "FLUATR", label: "Flutter atrial" },
                              { value: "EXTVEN", label: "Extrassístole ventricular" },
                              { value: "TAQVEN", label: "Taquicardia ventricular" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Ablação Complexa</label>
                          <Select
                            defaultValue={complexAblation}
                            value={complexAblation}
                            onChange={e => setComplexAblation(e)}
                            options={[
                              { value: "FA", label: "FA" },
                              { value: "TV", label: "TV" },
                              { value: "FLUATI", label: "Flutter atípico" },
                              { value: "EXTVEN", label: "Extrassístole ventricular" },
                              { value: "TAQATR", label: "Taquicardia atrial" },
                              { value: "OUT", label: "Outros" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Ablação Complexa (Outros)</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={complexAblationOthers}
                            onChange={e => setComplexAblationOthers(e.target.value)}
                            disabled={complexAblation.value !== "OUT"}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Crioablação</label>
                          <Select
                            defaultValue={cryoablation}
                            value={cryoablation}
                            onChange={e => setCryoablation(e)}
                            options={[
                              { value: "FA", label: "FA" },
                              { value: "OUT", label: "Outros" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Crioablação (Outras)</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={cryoablationOthers}
                            onChange={e => setCryoablationOthers(e.target.value)}
                            disabled={cryoablation.value !== "OUT"}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Implante de MP</label>
                          <Select
                            defaultValue={implant}
                            value={implant}
                            onChange={e => setImplant(e)}
                            options={[
                              { value: "CAMUNI", label: "Câmara única" },
                              { value: "CAMDUP", label: "Dupla Câmara" },
                              { value: "HIS", label: "His" },
                              { value: "CDI", label: "CDI" },
                              { value: "RES", label: "Ressinc" },
                              { value: "CDIRES", label: "CDI + Ressinc" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Extração de eletrodo</label>
                          <Select
                            defaultValue={electrodeExtraction}
                            value={electrodeExtraction}
                            onChange={e => setElectrodeExtraction(e)}
                            options={yesNoOptions}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Oclusão de AAE</label>
                          <Select
                            defaultValue={occlusion}
                            value={occlusion}
                            onChange={e => setOcclusion(e)}
                            options={yesNoOptions}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Material</label>
                          <Select
                            defaultValue={material}
                            value={material}
                            onChange={e => setMaterial(e)}
                            options={[
                              { value: "ABB", label: "Abbot" },
                              { value: "MED", label: "Medtronic" },
                              { value: "JJ", label: "JJ" },
                              { value: "BIO", label: "Biotronik" },
                              { value: "OUT", label: "Outros" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Material (Outros)</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={materialOthers}
                            onChange={e => setMaterialOthers(e.target.value)}
                            disabled={material.value !== "OUT"}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Punções</label>
                          <Select
                            defaultValue={puncture}
                            value={puncture}
                            onChange={e => setPuncture(e)}
                            options={[
                              { value: "FEM", label: "Femoral" },
                              { value: "JUG", label: "Jugular" },
                              { value: "ART", label: "Arterial" },
                              { value: "EPI", label: "Epicárdica" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Punções Femorais</label>
                          <Select
                            defaultValue={femoralPunctures}
                            value={femoralPunctures}
                            onChange={e => setFemoralPunctures(e)}
                            options={[
                              { value: "0", label: "0" },
                              { value: "1", label: "1" },
                              { value: "2", label: "2" },
                              { value: "3", label: "3" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                      </div>
                    </DCardBody>
                  </DCard>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Complicações</DCardHeader>
                    <DCardBody>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Complicações pré-procedimento</label>
                          <Select
                            defaultValue={preProcedureComplications}
                            value={preProcedureComplications}
                            onChange={e => setPreProcedureComplications(e)}
                            options={[
                              { value: "ECO", label: "Eco TE" },
                              { value: "OUT", label: "Outras" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Descrição complicações pré-procedimento</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={preProcedureComplicationsDesc}
                            onChange={e => setPreProcedureComplicationsDesc(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-6">
                          <label>Complicações durante o procedimento</label>
                          <Select
                            defaultValue={complicationsDuringProcedure}
                            value={complicationsDuringProcedure}
                            onChange={e => setComplicationsDuringProcedure(e)}
                            isMulti
                            closeMenuOnSelect={false}
                            options={[
                              { value: "TAMP", label: "Tamponamento (punção/cirurgia)" },
                              { value: "EMBCER", label: "Embolia cerebral" },
                              { value: "EMBPER", label: "Embolia periférica" },
                              { value: "CHO", label: "Choque" },
                              { value: "LESVAS", label: "Lesão Vascular" },
                              { value: "COMANE", label: "Complicação anestésica" },
                              { value: "OBI", label: "Óbito" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Descrição da complicação</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={complicationDesc}
                            onChange={e => setComplicationDesc(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Problemas com Cateter</label>
                          <Select
                            defaultValue={catheterProblems}
                            value={catheterProblems}
                            onChange={e => setCatheterProblems(e)}
                            options={[
                              { value: "ABB", label: "Abbot" },
                              { value: "MED", label: "Medtronic" },
                              { value: "JJ", label: "JJ" },
                              { value: "BIO", label: "Biotronik" },
                              { value: "OUT", label: "Outros" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Descrição do problema com cateter</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={catheterProblemsDesc}
                            onChange={e => setCatheterProblemsDesc(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Problemas com Bainha</label>
                          <Select
                            defaultValue={sheathProblems}
                            value={sheathProblems}
                            onChange={e => setSheathProblems(e)}
                            options={[
                              { value: "ABB", label: "Abbot" },
                              { value: "MED", label: "Medtronic" },
                              { value: "JJ", label: "JJ" },
                              { value: "BIO", label: "Biotronik" },
                              { value: "OUT", label: "Outros" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Descrição do problema com Bainha</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={sheathProblemsDesc}
                            onChange={e => setSheathProblemsDesc(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Problemas com Aparelhos</label>
                          <Select
                            defaultValue={devicesProblems}
                            value={devicesProblems}
                            onChange={e => setDevicesProblems(e)}
                            isMulti
                            closeMenuOnSelect={false}
                            options={[
                              { value: "RF", label: "RF" },
                              { value: "POL", label: "Polígrafo" },
                              { value: "MAPELE", label: "Mapeamento eletroanatômico" }
                            ]}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Descrição do problema com Aparelhos</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={devicesProblemsDesc}
                            onChange={e => setDevicesProblemsDesc(e.target.value)}
                          />
                        </div>
                        <div className="form-group mb-3 col-md-3">
                          <label>Interferência durante o procedimento resolvida?</label>
                          <Select
                            defaultValue={interferenceSolved}
                            value={interferenceSolved}
                            onChange={e => setInterferenceSolved(e)}
                            options={yesNoOptions}
                            styles={{
                              control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                              }),
                            }}
                            placeholder="Selecione..."
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group mb-3 col-md-3">
                          <label>Descrição da interferência</label>
                          <input
                            autoFocus
                            type="text"
                            className="form-control"
                            value={interferenceDescription}
                            onChange={e => setInterferenceDescription(e.target.value)}
                          />
                        </div>
                      </div>
                    </DCardBody>
                  </DCard>
                </div>
                <div className="col-xl-12 col-lg-12">
                  <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Comentários</DCardHeader>
                    <DCardBody>
                      <div className="row">
                        <div className="form-group mb-3 col-md-12">
                          <label>Comentários adicionais</label>
                          <textarea
                            type="text"
                            rows={6}
                            className="form-control"
                            value={comments}
                            onChange={e => setComments(e.target.value)}
                          />
                        </div>
                      </div>
                    </DCardBody>
                  </DCard>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="modal-footer">
            <Button
              variant="outline-danger btn-rounded"
              onClick={() => setModalBox(false)}
            >
              Fechar
            </Button>
            <Button variant="success btn-rounded" onClick={handleSaveForm}>Salvar</Button>
          </div>
        </div>
      </Modal>

      <ToastContainer />

      <ModalLoading
        visible={loading}
        message={loadingMessage}
        onClose={setLoadingComplicatiomForm}
      />
    </>
  );
});

export default ComplicationsForm;