import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
  Row,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import CancelReasonForm from "./CancelReasonForm";

import api from "../../../services/api";
import DPagination from "../../components/DPagination/DPagination";
import ModalLoading from "../../components/ModalLoading/ModalLoading";
import { isAuthenticated } from "../../../services/utils";
import { useHistory } from "react-router-dom";

const CancelReason = () => {
  const childRef = useRef();
  const [loading, setLoading] = useState(false);
  const [cancelReasons, setCancelReasons] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [hideFilter, setHideFilter] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated()) {
      return history.push("/login");
    }

    getCancelReasons();
  }, [])

  async function getCancelReasons(page = currentPage) {
    setLoading(true);

    let filter = "&";
    if (filterName !== "") {
      filter += `qs=${filterName}&`;
    }
    if (filterStatus) {
      filter += "status=true";
    }

    await api.get(`/cancel-reasons?offset=${page}&limit=10${filter}`)
      .then(response => {
        if (response.status === 200) {
          setCancelReasons(response.data.cancelReasons);

          let pages = Math.ceil(response.data.total / 10);
          setTotalPages(Math.max(pages, 1));
        }
      })

    setLoading(false);
  }

  function setActivePage(page) {
    setCurrentPage(page);
    getCancelReasons(page);
  }

  function onCloseModal() {
    getCancelReasons();
  }

  function handleFilter() {
    setCurrentPage(1);
    getCancelReasons(1);
  }

  return (
    <>
      <Fragment>
        <PageTitle activeMenu="Procedure" motherMenu="Records" />
        <Row hidden={hideFilter}>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Filtros</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="basic-form">
                  <div className="row">
                    <div className="form-group mb-3 col-md-10">
                      <label>Informe parte da descrição ou do código para pesquisa</label>
                      <input
                        type="text"
                        className="form-control"
                        value={filterName}
                        onChange={e => setFilterName(e.target.value)}
                        tabIndex="1"
                      />
                    </div>
                    <div className="form-group mb-3 col-md-2">
                      <label>Somente Ativos?</label>
                      <div className="form-check">
                        <input className="form-check-input" value={filterStatus} onChange={(e) => setFilterStatus(e.target.checked)} type="checkbox" />
                        <label className="form-check-label">Sim</label>
                      </div>
                    </div>
                  </div>

                  <div className="row" style={{ textAlign: "right" }}>
                    <div className="form-group mb-12 col-md-12">
                      <button className="btn btn-primary ms-1" onClick={handleFilter}>
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Header>
                <Card.Title>Motivos de Cancelamento</Card.Title>
                <div>
                  <button
                    className="btn btn-primary ms-1"
                    onClick={() => setHideFilter(!hideFilter)}
                    title="Mostrar/esconder filtros"
                  >
                    <i className="fas fa-filter"></i>
                  </button>
                  <button className="btn btn-primary ms-1" onClick={() => childRef.current.openModal()}>
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 3C7.05 3 3 7.05 3 12C3 16.95 7.05 21 12 21C16.95 21 21 16.95 21 12C21 7.05 16.95 3 12 3ZM12 19.125C8.1 19.125 4.875 15.9 4.875 12C4.875 8.1 8.1 4.875 12 4.875C15.9 4.875 19.125 8.1 19.125 12C19.125 15.9 15.9 19.125 12 19.125Z" fill="#FCFCFC" />
                      <path d="M16.3498 11.0251H12.9748V7.65009C12.9748 7.12509 12.5248 6.67509 11.9998 6.67509C11.4748 6.67509 11.0248 7.12509 11.0248 7.65009V11.0251H7.6498C7.1248 11.0251 6.6748 11.4751 6.6748 12.0001C6.6748 12.5251 7.1248 12.9751 7.6498 12.9751H11.0248V16.3501C11.0248 16.8751 11.4748 17.3251 11.9998 17.3251C12.5248 17.3251 12.9748 16.8751 12.9748 16.3501V12.9751H16.3498C16.8748 12.9751 17.3248 12.5251 17.3248 12.0001C17.3248 11.4751 16.8748 11.0251 16.3498 11.0251Z" fill="#FCFCFC" />
                    </svg> Novo
                  </button>
                </div>
              </Card.Header>
              <Card.Body>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Situação</strong>
                      </th>
                      <th>
                        <strong>Código</strong>
                      </th>
                      <th>
                        <strong>Descrição</strong>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cancelReasons.map((cancelReason) => {
                        return (
                          <tr key={cancelReason._id}>
                            <td>
                              <div className="d-flex align-items-center">
                                <i className={(cancelReason.status ? "fa fa-circle text-success me-1" : "fa fa-circle text-danger me-1")}></i>{" "}
                                {(cancelReason.status ? "Ativo" : "Inativo")}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="w-space-no">{cancelReason.code}</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <span className="w-space-no">{cancelReason.description}</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex">
                                <button
                                  className="btn btn-primary shadow sharp ms-1"
                                  onClick={() => childRef.current.openModal("update", cancelReason)}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>

                <DPagination
                  activePage={currentPage}
                  pages={totalPages}
                  onActivePageChange={(i) => setActivePage(i)}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Fragment>

      <CancelReasonForm ref={childRef} onClose={onCloseModal} />

      <ModalLoading
        visible={loading}
        message="Carregando Registros..."
        onClose={setLoading}
      />
    </>
  );
};

export default CancelReason;