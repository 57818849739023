import React, { Fragment, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Badge,
    Dropdown,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import PageTitle from "../../layouts/PageTitle";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

import { ageInString, getScheduleStatus, hasAccess } from "../../../services/utils";
import { generateAnamnesisPdf } from "../Anamnesis/AnamnesisPdf";
import { generateProcedureExecutionPdf } from "../ProcedureExecution/ProcedureExecutionPdf";
import { generateProcedureDescriptionPdf } from "../ProcedureExecution/ProcedureDescriptionPdf";

import PreScheduleCancelModal from "../PreSchedule/PreScheduleCancelModal";
import ComplicationsList from "../Complications/ComplicationsList";
import ComplicationsForm from "../Complications/ComplicationsForm";
import ProtocolForm from "../Protocol/ProtocolForm";
import FollowUpForm from "../FollowUp/FollowUpForm";
import FollowUpList from "../FollowUp/FollowUpList";
import ProcedureExecution from "../ProcedureExecution/ProcedureExecution";

import api from "../../../services/api";
import { updateSummaryCount } from "../../../services/summaryService";

const MedicalRecord = ({ match }) => {
    const reportFormRef = useRef();
    const complicationsListRef = useRef();
    const complicationsFormRef = useRef();
    const cancelChildRef = useRef();
    const protocolFormRef = useRef();
    const followUpFormRef = useRef();
    const followUpsListRef = useRef();
    const [pacientId, setPacientId] = useState("");
    const [name, setName] = useState("");
    const [birhtDate, setBirthDate] = useState("");
    const [healthPlan, setHealthPlan] = useState("");
    const [gender, setGender] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [age, setAge] = useState("");
    const [procedures, setProcedures] = useState([]);

    const [hasAnamesis, setHasAnamnesis] = useState(false);
    const [hasOpenSchedule, setHasOpenSchedule] = useState(false);
    const [showComplicationsButton, setShowComplicationsButton] = useState(false);

    const [scheduleInfo, setScheduleInfo] = useState(undefined);

    const [isUpdated, setIsUpdated] = useState(true);

    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Carregando Informações...");

    const userInfo = JSON.parse(localStorage.getItem("xLcxRem") ? localStorage.getItem("xLcxUsr") : sessionStorage.getItem("xLcxUsr"));
    const history = useHistory();

    useEffect(() => {
        setLoadingMessage("Carregando Informações...");
        setLoading(true);

        setProcedures([]);

        getPacient(match.params.id);
        getProcedures(match.params.id);

        setLoading(false);
    }, []);

    async function getPacient(id = pacientId) {
        await api.get(`/pacients/${id}`)
            .then(response => {
                if (response.status === 200) {
                    setPacientId(response.data._id);
                    setName(response.data.name);
                    setBirthDate(dayjs(response.data.birth_date).format("DD/MM/YYYY"));
                    setHealthPlan(response.data.health_plan_name);
                    setGender(response.data.gender);

                    const phoneNumber = response.data.contacts.find(x => x.type === "personal");
                    if (phoneNumber) {
                        const cellPhone = phoneNumber.phones.find(x => x.type === "cell");
                        if (cellPhone) {
                            setPhoneNumber(maskPhoneField(cellPhone.phone));
                        }
                    }

                    const ageInStr = ageInString(dayjs(response.data.birth_date).toDate());
                    setAge(ageInStr);

                    if (!response.data.health_plan_id || response.data.health_plan_id === "") {
                        setIsUpdated(false);
                    }
                }
            })
    }

    function maskPhoneField(phoneField) {
        const actualText = phoneField;
        const isMobile = actualText.length === 11;
        let newText;

        if (isMobile) {
            newText = actualText.replace(/(\d{2})(\d{5})(\d{4})/,
                function (regex, arg1, arg2, arg3) {
                    return "(" + arg1 + ") " + arg2 + '-' + arg3;
                });
        } else {
            newText = actualText.replace(/(\d{2})(\d{4})(\d{4})/,
                function (regex, arg1, arg2, arg3) {
                    return "(" + arg1 + ") " + arg2 + '-' + arg3;
                });
        }
        return newText;
    }

    async function getProcedures(pacientId) {
        let newProcedures = [];

        await api.get(`/anamnesis?pacientId=${pacientId}`)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.total > 0) {
                        setHasAnamnesis(true);

                        newProcedures.push({
                            id: response.data.anamnesis[0]._id,
                            type: "anamnesis",
                            status: "done",
                            statusBadge: getScheduleStatus("done"),
                            name: "Anamnese",
                            date: dayjs(response.data.anamnesis[0].createdAt).format("DD/MM/YYYY"),
                            code: "Não existente",
                            procedureInfo: {},
                            isHiddenEdit: false,
                            isHiddenCancel: true,
                            isHiddenDownload: true,
                            isHiddenComplication: true,
                            isHiddenProtocol: true
                        });
                    }
                }
            })

        await api.get(`/schedules?pacientId=${pacientId}`)
            .then(response => {
                if (response.status === 200) {
                    const schedulesData = response.data.schedules;

                    for (let indexSchedule = 0; indexSchedule < schedulesData.length; indexSchedule++) {
                        let idInfo = schedulesData[indexSchedule]._id;
                        let type = "schedule";

                        let eefNumber = "Não existente";
                        if (schedulesData[indexSchedule].reports) {
                            if (schedulesData[indexSchedule].reports.length > 0) {
                                type = "procedure-execution";
                                eefNumber = schedulesData[indexSchedule].reports[0].eef_number;
                            }
                        }

                        let isHiddenEdit = false;
                        if (
                            ["can", "not", "pre", "sch"].includes(schedulesData[indexSchedule].status) ||
                            (schedulesData[indexSchedule].status === "done" && !hasAccess("report", "update"))
                        ) {
                            isHiddenEdit = true;
                        }

                        let isHiddenCancel = true;
                        if (schedulesData[indexSchedule].status === "sch") {
                            isHiddenCancel = false;
                        }

                        let isHiddenDownload = false;
                        if (["can", "not", "pre", "sch"].includes(schedulesData[indexSchedule].status)) {
                            isHiddenDownload = true;
                        }

                        let isHiddenComplication = true
                        if (schedulesData[indexSchedule].status === "done") {
                            isHiddenComplication = false;
                            setShowComplicationsButton(true)
                        };

                        let isHiddenProtocol = true;
                        if (schedulesData[indexSchedule].protocols) {
                            if (schedulesData[indexSchedule].protocols.length > 0) {
                                isHiddenProtocol = false;
                            }
                        }

                        newProcedures.push({
                            id: idInfo,
                            type: type,
                            status: schedulesData[indexSchedule].status,
                            statusBadge: getScheduleStatus(schedulesData[indexSchedule].status),
                            name: schedulesData[indexSchedule].procedure_name,
                            date: (schedulesData[indexSchedule].schedule_date ? dayjs(schedulesData[indexSchedule].schedule_date).format("DD/MM/YYYY") : ""),
                            code: eefNumber,
                            procedureInfo: schedulesData[indexSchedule],
                            isHiddenEdit,
                            isHiddenCancel,
                            isHiddenDownload,
                            isHiddenComplication,
                            isHiddenProtocol
                        });

                        if (schedulesData[indexSchedule].status === "sch") {
                            setHasOpenSchedule(true);
                        };
                    }
                }
            })

        setProcedures(newProcedures);
    }

    async function getScheduleById(id) {
        await api.get(`/schedules/${id}`)
            .then(response => {
                if (response.status === 200) {
                    setScheduleInfo(response.data);
                }
            })
    }

    function handleAnamnesis(mode, id = pacientId) {
        history.push(`/anamnesis/${mode}/${id}`);
    }

    function handleProcedureExecution(mode) {
        history.push(`/procedure-execution/${mode}/${pacientId}`);
    }

    function handleCancelSchedule(id) {
        getScheduleById(id);
        cancelChildRef.current.openModal();
    }

    function onCloseModal() {
        getPacient(pacientId);
        getProcedures(pacientId);
    }

    async function confirmCancelSchedule(cancelReason, cancelMessage) {
        setLoadingMessage("Cancelando Agendamento...");
        setLoading(true);

        let scheduleToUpdate = { ...scheduleInfo };

        let newLog = [];
        if (scheduleToUpdate.logs) {
            newLog = [...scheduleToUpdate.logs];
        }
        newLog.push({
            status: "can",
            comment: "Agendamento cancelado",
            user: userInfo.name,
            date: dayjs().toDate()
        });
        scheduleToUpdate.logs = [...newLog];
        scheduleToUpdate.status = "can";
        scheduleToUpdate.cancel_reason_id = cancelReason;
        scheduleToUpdate.cancel_message = cancelMessage;

        try {
            await api.put(`/schedules/${scheduleInfo._id}`, scheduleToUpdate)
                .then(async response => {
                    if (response.status === 200) {
                        if (scheduleInfo.status === "sch") {
                            await updateSummaryCount(scheduleInfo.schedule_date, scheduleInfo.forward_doctor_id, "SUB", "forwarded")
                                .then(response => {
                                    for (let indexDoctors = 0; indexDoctors < scheduleInfo.doctors.length; indexDoctors++) {
                                        updateSummaryCount(scheduleInfo.schedule_date, scheduleInfo.doctors[indexDoctors].doctor_id, "SUB", "completed");
                                    }
                                })                            
                        }

                        notifySuccess();

                        setLoadingMessage("Carregando Informações...");
                        setProcedures([]);

                        getProcedures(match.params.id);
                        setLoading(false);
                    }
                })
        } catch (err) {
            notifyError();
        }

        setLoading(false);
    }

    function handleGenerateAnamnesis(id) {
        generateAnamnesisPdf(id);
    }

    function handleGenerateReport(id, type) {
        if (type === 1) {
            generateProcedureExecutionPdf(id);
        } else if (type === 2) {
            generateProcedureDescriptionPdf(id);
        }
    }

    function notifySuccess() {
        toast.success(`✔️ Agendamento cancelado com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao cancelar o agendamento.`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    return (
        <>
            <Fragment>
                <PageTitle activeMenu="Clinic" motherMenu="Clinic" />
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Prontuário do Paciente</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="row">
                                    <Card>
                                        <Card.Header>
                                            <h4>Informações gerais do paciente</h4>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <strong>Nome do paciente:</strong>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    {name}
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <strong>Data de nascimento:</strong>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    {birhtDate}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <strong>Convênio:</strong>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    {healthPlan}
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <strong>Sexo:</strong>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    {gender}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <strong>Telefone:</strong>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    {phoneNumber}
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    <strong>Idade:</strong>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    {age}
                                                </div>
                                            </div>

                                            <br />
                                            <br />
                                            {/* <div className="row">
                                                <div className="form-group mb-3 col-md-3">
                                                    <strong>Código de acesso para laudos:</strong>
                                                </div>
                                                <div className="form-group mb-3 col-md-3">
                                                    ABCDEF
                                                </div>
                                            </div> */}

                                            <div className="row" hidden={isUpdated}>
                                                <div className="form-group mb-3 col-md-12">
                                                    <Button variant="danger">Dados do Paciente Desatualizados - Clique para atualizar</Button>
                                                </div>
                                            </div>

                                            <br />
                                            <div className="row">
                                                <div className="form-group mb-3 col-md-12">
                                                    <Button
                                                        variant="secondary"
                                                        onClick={() => handleAnamnesis("new")}
                                                        hidden={hasAnamesis}
                                                    >
                                                        Adicionar Resumo da História Clínica
                                                    </Button>{" "}
                                                    <Button
                                                        variant="warning"
                                                        onClick={() => reportFormRef.current.openModal("new", pacientId)}
                                                        hidden={!hasOpenSchedule || !hasAccess("report", "create")}
                                                    >
                                                        Executar Procedimento
                                                    </Button>{" "}
                                                    <Button
                                                        variant="light"
                                                        onClick={() => followUpsListRef.current.openModal(pacientId)}
                                                        hidden={!showComplicationsButton}
                                                    >
                                                        Seguimentos
                                                    </Button>{" "}
                                                    <Button
                                                        variant="info"
                                                        onClick={() => complicationsListRef.current.openModal(pacientId)}
                                                        hidden={!showComplicationsButton}
                                                    >
                                                        Complicações
                                                    </Button>{" "}
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>

                                <div className="row">
                                    <Card className="col-md-12">
                                        <Card.Header>
                                            <h4>Procedimentos - <small>Agendados e/ou executados</small></h4>
                                        </Card.Header>
                                        <Card.Body>
                                            <table className="table verticle-middle table-responsive-md">
                                                <thead style={{ backgroundColor: "#9a9a9a" }}>
                                                    <tr>
                                                        <th>
                                                            Status
                                                        </th>
                                                        <th>
                                                            Procedimento
                                                        </th>
                                                        <th>
                                                            Data
                                                        </th>
                                                        <th>
                                                            Número EEF
                                                        </th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        procedures.map((procedure) => {
                                                            return (
                                                                <tr key={procedure.id} style={{ borderBottom: "1px solid" }}>
                                                                    <td>
                                                                        <div className="d-flex align-items-left">
                                                                            <Badge bg="" className={`badge-${procedure.statusBadge.color}`} >{procedure.statusBadge.label}</Badge>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className="w-space-no">{procedure.name}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className="w-space-no">{procedure.date}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <span className="w-space-no">{procedure.code}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <button
                                                                                className="btn btn-warning shadow btn-sm me-1"
                                                                                onClick={() => reportFormRef.current.openModal("new", pacientId)}
                                                                                hidden={!procedure.isHiddenEdit || !hasOpenSchedule || !hasAccess("report", "create")}
                                                                            >
                                                                                <i className="fas fa-pen"></i>{" "}
                                                                                Executar Procedimento
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-danger shadow btn-sm me-1"
                                                                                onClick={() => handleCancelSchedule(procedure.id)}
                                                                                hidden={procedure.isHiddenCancel}
                                                                            >
                                                                                <i className="fas fa-ban"></i>{" "}
                                                                                Cancelar Procedimento
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary shadow btn-sm me-1"
                                                                                onClick={() => reportFormRef.current.openModal("edit", procedure.id)}
                                                                                hidden={procedure.isHiddenEdit || procedure.type === "anamnesis"}
                                                                            >
                                                                                <i className="fas fa-pen"></i>{" "}
                                                                                Editar Laudo
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-primary shadow btn-sm me-1"
                                                                                onClick={() => handleAnamnesis("edit", procedure.id)}
                                                                                hidden={procedure.isHiddenEdit || procedure.type !== "anamnesis"}
                                                                            >
                                                                                <i className="fas fa-pen"></i>{" "}
                                                                                Editar Anamnese
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-info shadow btn-sm me-1"
                                                                                hidden={procedure.type !== "anamnesis"}
                                                                                onClick={() => handleGenerateAnamnesis(procedure.id)}
                                                                                title="Baixar Anamnese"
                                                                            >
                                                                                <i className="fas fa-download"></i>{" "}
                                                                                Anamnese
                                                                            </button>

                                                                            <Dropdown hidden={procedure.isHiddenDownload}>
                                                                                <Dropdown.Toggle className="btn btn-info shadow btn-sm me-1">
                                                                                    Download
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => handleGenerateReport(procedure.id, 1)}
                                                                                    >
                                                                                        Laudo
                                                                                    </Dropdown.Item>
                                                                                    {/* <Dropdown.Item
                                                                                        onClick={() => handleGenerateReport(procedure.id, 2)}
                                                                                    >
                                                                                        Laudo com anexos
                                                                                    </Dropdown.Item> */}
                                                                                    <Dropdown.Item
                                                                                        onClick={() => handleGenerateReport(procedure.id, 2)}
                                                                                    >
                                                                                        Descrição de procedimento
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>

                                                                            <Dropdown hidden={(procedure.isHiddenProtocol && procedure.isHiddenComplication)}>
                                                                                <Dropdown.Toggle variant="dark" className="btn btn-info shadow btn-sm me-1">
                                                                                    Outras Ações
                                                                                </Dropdown.Toggle>
                                                                                <Dropdown.Menu>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => protocolFormRef.current.openModal(procedure.procedureInfo)}
                                                                                        hidden={procedure.isHiddenProtocol}
                                                                                    >
                                                                                        Protocolo
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => complicationsFormRef.current.openModal(procedure.id, "new")}
                                                                                        hidden={procedure.isHiddenComplication}
                                                                                    >
                                                                                        Incluir Complicação
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Item
                                                                                        onClick={() => followUpFormRef.current.openModal("new", procedure.procedureInfo)}
                                                                                        hidden={procedure.isHiddenEdit}
                                                                                    >
                                                                                        Incluir Seguimento
                                                                                    </Dropdown.Item>
                                                                                </Dropdown.Menu>
                                                                            </Dropdown>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>

            <ProcedureExecution ref={reportFormRef} onClose={onCloseModal} />

            <PreScheduleCancelModal ref={cancelChildRef} onClose={confirmCancelSchedule} />

            <ComplicationsList ref={complicationsListRef} onClose={onCloseModal} />

            <ComplicationsForm ref={complicationsFormRef} onClose={onCloseModal} />

            <ProtocolForm ref={protocolFormRef} onClose={onCloseModal} />

            <FollowUpList ref={followUpsListRef} onClose={onCloseModal} />

            <FollowUpForm ref={followUpFormRef} onClose={onCloseModal} />

            <ToastContainer />

            <ModalLoading
                visible={loading}
                message={loadingMessage}
                onClose={setLoading}
            />
        </>
    );
};

export default MedicalRecord;