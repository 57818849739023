import dayjs from "dayjs";
import api from "./api";

export function createLog({
    routine,
    action,
    type,
    message = "",
    jsonOrigin = "",
    jsonReturn = ""
}) {
    const userInfo = (localStorage.getItem("xLcxUsr") ? JSON.parse(localStorage.getItem("xLcxUsr")) : JSON.parse(sessionStorage.getItem("xLcxUsr")));

    const logToCreate = {
        routine,
        action,
        type,
        user_id: userInfo.user_id,
        username: userInfo.username,
        event_date: dayjs().toDate(),
        message,
        json_origin: jsonOrigin,
        json_return: jsonReturn
    };

    api.post("/logs", logToCreate);
}

export function getActionName(action) {
    let actionName = "";

    switch (action) {
        case "create":
            actionName = "Criação";
            break;
        case "update":
            actionName = "Atualização";
            break;
        case "pwd":
            actionName = "Alteração de senha";
            break;
        case "cancel":
            actionName = "Cancelamento";
            break;
        case "delete":
            actionName = "Exclusão";
            break;
        case "login":
            actionName = "Login";
            break;
        default:
            actionName = "";
            break;
    }

    return actionName;
}

export function getRoutineName(routine) {
    let routineName = "";

    switch (routine) {
        case "user":
            routineName = "Usuário";
            break;
        case "supplier":
            routineName = "Fornecedor";
            break;
        case "protocol":
            routineName = "Protocolo";
            break;
        case "procedureExecution":
            routineName = "Execução de Procedimento";
            break;
        case "complication":
            routineName = "Complicação";
            break;
        case "preschedule":
            routineName = "Pré-Agendamento";
            break;
        case "pacient":
            routineName = "Paciente";
            break;
        case "receipt":
            routineName = "Recebimento";
            break;
        case "invoice":
            routineName = "Faturamento";
            break;
        case "login":
            routineName = "Login";
            break;
        case "hospital":
            routineName = "Hospital";
            break;
        case "healthplan":
            routineName = "Convênio";
            break;
        case "followup":
            routineName = "Seguimento";
            break;
        case "doctor":
            routineName = "Médico";
            break;
        default:
            routineName = "";
            break;
    }

    return routineName;
}

export function getTypeName(type) {
    return (type === "success" ? "Sucesso" : "Erro");
}