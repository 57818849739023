const receiptType = [
    {
        value: "NOR",
        label: "Normal"
    },
    {
        value: "GLO",
        label: "Glosa"
    }
];

const accountType = [
    {
        value: "LEC",
        label: "LEC"
    },
    {
        value: "DOC",
        label: "Médico"
    }
];

export { receiptType, accountType };