import React from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-scroll";

const AlertList = () =>{
    return(
        <>
            <PerfectScrollbar className="demo-right-inner dlab-scroll " >
                <h4 className="title">Examples For Alert</h4>
                <ul className="navbar-nav" id="menu-bar">
                    <li><Link to="basic-alerts" className="scroll">Basic-Alerts</Link></li>
                    <li><Link to="color-alerts" className="scroll">Solid color alerts</Link></li>
                    <li><Link to="square-alerts" className="scroll">Square Alerts</Link></li>
                    <li><Link to="rounded-alerts" className="scroll">Rounded Alerts</Link></li>
                    <li><Link to="dismissable-alerts" className="scroll">Dismissable Alerts</Link></li>
                    <li><Link to="alerts-alt" className="scroll">Alerts Alt</Link></li>
                    <li><Link to="solid-alt" className="scroll">Solid Alt</Link></li>
                    <li><Link to="dismissable-solid" className="scroll">Dismissable with solid</Link></li>
                    <li><Link to="alert-link" className="scroll">Alert with Link</Link></li>
                    <li><Link to="alert-link-color" className="scroll">Alert with Link and Solid Color</Link></li>
                    <li><Link to="notifications" className="scroll">Inline Notifications</Link></li>
                    <li><Link to="alert-icon-left" className="scroll">Alert Icon Left</Link></li>
                    <li><Link to="alert-outline" className="scroll">Alert outline</Link></li>
                    <li><Link to="alert-social" className="scroll">Alert Social</Link></li>
                    <li><Link to="message-alert" className="scroll">Message Alert</Link></li>
                    <li><Link to="message-alert-color" className="scroll">Message Alert Color</Link></li>
                    <li><Link to="alert-icon-big" className="scroll">Alert left icon big </Link></li>
                </ul>	
            </PerfectScrollbar>
        </>
    )
}
export default AlertList;