import React from 'react';
import { useHistory } from 'react-router-dom';

function LogoutPage() {
    const history = useHistory();

    function onLogout() {
        if (localStorage.getItem("xLcxRem")) {
            localStorage.removeItem("xLcxRem");
            localStorage.removeItem("xLcxTk");
            localStorage.removeItem("xLcxTkr");
            localStorage.removeItem("xLcxUsr");
        } else {
            sessionStorage.removeItem("xLcxTk");
            sessionStorage.removeItem("xLcxTkr");
            sessionStorage.removeItem("xLcxUsr");
        }
        history.push('/login');
    }
    return (
        <div className="dropdown-item ai-icon" style={{ cursor: "pointer" }} onClick={onLogout}>
            <svg
                id="icon-logout" xmlns="http://www.w3.org/2000/svg"
                className="text-danger me-1" width={18} height={18} viewBox="0 0 24 24"
                fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" fillRule="round"
            >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                <polyline points="16 17 21 12 16 7" />
                <line x1={21} y1={12} x2={9} y2={12} />
            </svg>
            <span className="ms-2" >Sair </span>
        </div>
    )
}

export default LogoutPage;