import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import Select from "react-select";

import correios from "../../../services/correios";
import ModalLoading from "../ModalLoading/ModalLoading";
import { states } from "../../../enum/enums";

const DAddressForm = props => {
    const {
        children,
        className,
        //
        onChangeAddress,
        addressInfoParm,
        ...attributes
    } = props;

    const [addressInfo, setAddressInfo] = useState(addressInfoParm);
    const [zipCode, setZipCode] = useState("");
    const [actualZipCode, setActualZipCode] = useState("");
    const [address, setAddress] = useState("");
    const [addressNumber, setAddressNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [district, setDistrict] = useState("");
    const [city, setCity] = useState("");
    const [addressState, setAddressState] = useState(null);
    const [findAddress, setFindAddress] = useState(false);

    const [loadingAddress, setLoadingAddress] = useState(false);

    const [disableAddress, setDisableAddress] = useState(false);
    const [disableDistrict, setDisableDistrict] = useState(false);
    const [disableCity, setDisableCity] = useState(false);
    const [disableState, setDisableState] = useState(false);

    useEffect(() => {
        if (addressInfoParm) {
            setActualZipCode(addressInfoParm.zipCode ?? "");

            if (addressInfoParm.zipCode) {
                setDisableAddress(addressInfoParm.address ? true : false);
                setDisableDistrict(addressInfoParm.district ? true : false);
                setDisableCity(addressInfoParm.city ? true : false);
                setDisableState(addressInfoParm.addressState ? true : false);
            }
        }
    }, [])

    useEffect(() => {
        setAddressInfo(addressInfoParm);

        setZipCode(addressInfoParm.zipCode ?? "");
        setAddress(addressInfoParm.address ?? "");
        setAddressNumber(addressInfoParm.addressNumber ?? "");
        setComplement(addressInfoParm.complement ?? "");
        setDistrict(addressInfoParm.district ?? "");
        setCity(addressInfoParm.city ?? "");

        const stateInfo = states.find(x => x.value === addressInfoParm?.addressState);
        setAddressState(stateInfo);

        if (addressInfoParm.zipCode && findAddress) {
            setDisableAddress(addressInfoParm.address ? true : false);
            setDisableDistrict(addressInfoParm.district ? true : false);
            setDisableCity(addressInfoParm.city ? true : false);
            setDisableState(addressInfoParm.addressState ? true : false);
        }
    }, [addressInfoParm]);

    function handleChangeInfo(info, value) {
        let newaddressInfo = { ...addressInfo };

        if (info === "zipCode") {
            const zipCodeUnformatted = value.replace(".", "").replace("-", "");
            newaddressInfo.zipCode = zipCodeUnformatted;
            setZipCode(value);
        } else if (info === "address") {
            newaddressInfo.address = value;
            setAddress(value);
        } else if (info === "addressNumber") {
            newaddressInfo.addressNumber = value;
            setAddressNumber(value);
        } else if (info === "complement") {
            newaddressInfo.complement = value;
            setComplement(value);
        } else if (info === "district") {
            newaddressInfo.district = value;
            setDistrict(value);
        } else if (info === "city") {
            newaddressInfo.city = value;
            setCity(value);
        } else if (info === "addressState") {
            newaddressInfo.addressState = value.value;
            setAddressState(value);
        }

        onChangeAddress(newaddressInfo);
    }

    async function maskZipCodeField() {
        setLoadingAddress(true);

        const zipCodeUnformatted = zipCode.replace(".", "").replace("-", "");

        if (zipCodeUnformatted !== actualZipCode) {
            setActualZipCode(zipCodeUnformatted);
            setAddress("");
            setAddressNumber("");
            setComplement("");
            setDistrict("");
            setCity("");
            setAddressState("");

            setFindAddress(false);
            setDisableAddress(false);
            setDisableDistrict(false);
            setDisableCity(false);
            setDisableState(false);

            if (zipCodeUnformatted.trim().length === 8) {
                await correios.get(`/ws/${zipCodeUnformatted}/json`)
                    .then(response => {
                        if (response.status === 200) {
                            if (!response.data.erro) {
                                const cepResponse = response.data;
                                let nextField = undefined;
                                let newaddressInfo = { ...addressInfo };

                                if (cepResponse.logradouro) {
                                    setAddress(cepResponse.logradouro);
                                    setDisableAddress(true);
                                    setFindAddress(true);
                                    newaddressInfo.address = cepResponse.logradouro;
                                } else {
                                    nextField = "addressStreet";
                                }

                                if (cepResponse.bairro) {
                                    setDistrict(cepResponse.bairro);
                                    setDisableDistrict(true);
                                    newaddressInfo.district = cepResponse.bairro;
                                } else if (!nextField) {
                                    nextField = "addressDistrict";
                                }

                                if (cepResponse.localidade) {
                                    setCity(cepResponse.localidade);
                                    setDisableCity(true);
                                    newaddressInfo.city = cepResponse.localidade;
                                } else if (!nextField) {
                                    nextField = "addressCity";
                                }

                                if (cepResponse.uf) {
                                    const stateInfo = states.find(x => x.value === cepResponse.uf);
                                    setAddressState(stateInfo);
                                    setDisableState(true);
                                    newaddressInfo.addressState = cepResponse.uf;
                                } else if (!nextField) {
                                    nextField = "addressState";
                                }

                                nextField = nextField ? nextField : "addressNumberInput";

                                document.getElementById(nextField).focus();

                                setAddressInfo(newaddressInfo);
                            }
                        }
                    })
            }
        }

        setLoadingAddress(false);
    }

    return (
        <>
            <h5>Endereço</h5>
            <div className="row">
                <div className="form-group mb-3 col-md-2">
                    <label>CEP</label>
                    <InputMask
                        mask="99.999-999"
                        maskChar=" "
                        value={zipCode}
                        onChange={(event) => {
                            if (event.target.value.length !== 10) return false;
                            handleChangeInfo("zipCode", event?.target.value);
                        }}
                        onBlur={() => { maskZipCodeField() }}
                        alwaysShowMask={true}
                    >
                        {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                    </InputMask>
                </div>
                <div className="form-group mb-3 col-md-8">
                    <label>Logradouro</label>
                    <input
                        id="addressStreet"
                        type="text"
                        className="form-control"
                        value={address}
                        onChange={e => handleChangeInfo("address", e.target.value)}
                        disabled={disableAddress}
                    />
                </div>
                <div className="form-group mb-3 col-md-2">
                    <label>Número</label>
                    <input
                        id="addressNumberInput"
                        type="text"
                        className="form-control"
                        value={addressNumber}
                        onChange={e => handleChangeInfo("addressNumber", e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3 col-md-3">
                    <label>Complemento</label>
                    <input
                        id="addressComplement"
                        type="text"
                        className="form-control"
                        value={complement}
                        onChange={e => handleChangeInfo("complement", e.target.value)}
                    />
                </div>
                <div className="form-group mb-3 col-md-3">
                    <label>Bairro</label>
                    <input
                        id="addressDistrict"
                        type="text"
                        className="form-control"
                        value={district}
                        onChange={e => handleChangeInfo("district", e.target.value)}
                        disabled={disableDistrict}
                    />
                </div>
                <div className="form-group mb-3 col-md-3">
                    <label>Cidade</label>
                    <input
                        id="addressCity"
                        type="text"
                        className="form-control"
                        value={city}
                        onChange={e => handleChangeInfo("city", e.target.value)}
                        disabled={disableCity}
                    />
                </div>
                <div className="form-group mb-3 col-md-3">
                    <label>UF</label>
                    <Select
                        id="addressState"
                        defaultValue={addressState}
                        value={addressState}
                        onChange={e => handleChangeInfo("addressState", e)}
                        options={states}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                        isDisabled={disableState}
                    />
                </div>
            </div>

            <ModalLoading
                visible={loadingAddress}
                message="Verificando CEP..."
                onClose={setLoadingAddress}
            />
        </>
    )
}

DAddressForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

DAddressForm.defaultProps = {
    addressInfoParm: {}
};

export default DAddressForm;