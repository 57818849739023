import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import Select from "react-select";
import InputMask from "react-input-mask";
import { Table } from "react-bootstrap";

import { associatedDiseases } from "../../../enum/enums";

const AssociatedDiseaseCard = props => {
    const {
        children,
        className,
        //
        onRequestAdd,
        associatedDiseasesListParm,
        ...attributes
    } = props;

    const [associatedDiseasesList, setAssociatedDiseasesList] = useState(associatedDiseasesListParm);
    const [type, setType] = useState(null);
    const [month, setMonth] = useState("");
    const [year, setYear] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [idRowEditing, setIdRowEditing] = useState(undefined);

    useEffect(() => {
        setAssociatedDiseasesList(associatedDiseasesListParm);
    }, [associatedDiseasesListParm]);

    function handleAddAssociatedDisease() {
        if (!isEditing) {
            const newAssociatedDiseaseItem = {
                id: (Math.floor(Math.random() * 65536)).toString(),
                type,
                month,
                year
            };

            const newAssociatedDiseasesList = [...associatedDiseasesList, newAssociatedDiseaseItem];
            setAssociatedDiseasesList(newAssociatedDiseasesList);
            onRequestAdd(newAssociatedDiseasesList);
        } else {
            const newAssociatedDiseasesList = associatedDiseasesList.map(associatedDiseaseItem => {
                if (associatedDiseaseItem.id === idRowEditing) {
                    return {
                        id: associatedDiseaseItem.id,
                        type,
                        month,
                        year
                    }
                }

                return associatedDiseaseItem;
            })
            setAssociatedDiseasesList(newAssociatedDiseasesList);
            onRequestAdd(newAssociatedDiseasesList);
        }

        resetFields();
    }

    function handleEditAssociatedDisease(id) {
        const editRowInfo = associatedDiseasesList.find(x => x.id === id);
        if (editRowInfo) {
            setType(editRowInfo.type);
            setMonth(editRowInfo.month);
            setYear(editRowInfo.year);

            setIdRowEditing(id);
            setIsEditing(true);
        }
    }

    function handleDeleteAssociatedDisease(id) {
        const newAssociatedDiseasesList = associatedDiseasesList.filter(x => x.id !== id);
        setAssociatedDiseasesList(newAssociatedDiseasesList);
        onRequestAdd(newAssociatedDiseasesList);
    }

    function resetFields() {
        setType(null);
        setMonth("");
        setYear("");

        setIsEditing(false);
        setIdRowEditing(undefined);
    }

    return (
        <>
            <DCard borderColor="light">
                <DCardHeader color="light" textColor="dark">Doenças Associadas</DCardHeader>
                <DCardBody>
                    <div className="basic-form">
                        <div className="row">
                            <div className="form-group mb-3 col-md-6">
                                <label><small>Doenças Associadas</small></label>
                                <Select
                                    isClearable
                                    defaultValue={type}
                                    value={type}
                                    onChange={(e) => setType(e)}
                                    options={associatedDiseases}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            height: 45,
                                            backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                        }),
                                    }}
                                    placeholder="Selecione..."
                                />
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <label><small>Mês</small></label>
                                <InputMask
                                    mask="99"
                                    maskChar=" "
                                    value={month}
                                    onChange={(event) => {
                                        if (event.target.value.length !== 2) return false;
                                        setMonth(event?.target.value);
                                    }}
                                    alwaysShowMask={true}
                                >
                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <label><small>Ano</small></label>
                                <InputMask
                                    mask="9999"
                                    maskChar=" "
                                    value={year}
                                    onChange={(event) => {
                                        if (event.target.value.length !== 4) return false;
                                        setYear(event?.target.value);
                                    }}
                                    alwaysShowMask={true}
                                >
                                    {(inputProps) => <input {...inputProps} type="text" className="form-control" />}
                                </InputMask>
                            </div>
                            <div className="form-group mb-3 col-md-2">
                                <button className="btn btn-primary shadow mt-4 ms-1" onClick={handleAddAssociatedDisease}>
                                    <i className="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group mb-3 col-md-12" hidden={associatedDiseasesList.length === 0}>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Doença Associada</strong>
                                            </th>
                                            <th>
                                                <strong>Mês</strong>
                                            </th>
                                            <th>
                                                <strong>Ano</strong>
                                            </th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            associatedDiseasesList.map((associatedDiseaseItem) => {
                                                return (
                                                    <tr key={associatedDiseaseItem.id}>
                                                        <td>{associatedDiseaseItem.type.label}</td>
                                                        <td>{associatedDiseaseItem.month}</td>
                                                        <td>{associatedDiseaseItem.year}</td>
                                                        <td>
                                                            <div className="d-flex">
                                                                <button
                                                                    className="btn btn-info shadow sharp ms-1"
                                                                    onClick={() => handleEditAssociatedDisease(associatedDiseaseItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-pencil-alt"></i>
                                                                </button>
                                                                <button
                                                                    className="btn btn-danger shadow sharp ms-1"
                                                                    onClick={() => handleDeleteAssociatedDisease(associatedDiseaseItem.id)}
                                                                    disabled={isEditing}
                                                                >
                                                                    <i className="fas fa-trash"></i>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </DCardBody>
            </DCard>
        </>
    )
}

AssociatedDiseaseCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

AssociatedDiseaseCard.defaultProps = {
    associatedDiseasesListParm: []
};

export default AssociatedDiseaseCard;