import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';
import CurrencyInput, { formatValue } from "react-currency-input-field";

import ModalLoading from '../../components/ModalLoading/ModalLoading';

const PartitionForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [loadingPartitionForm, setLoadingPartitionForm] = useState(false);

    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [value, setValue] = useState("0");

    useImperativeHandle(ref, () => ({
        openModal(doctorInfo) {
            setModalBox(true);

            setId(doctorInfo._id);
            setName(doctorInfo.name);

            const valueFormatted = formatValue({
                value: doctorInfo.value.toString(),
                groupSeparator: ".",
                decimalSeparator: ","
            });
            setValue(valueFormatted);
        }
    }));

    async function handlePartitionValue(e) {
        e.preventDefault();

        const valueInNumber = +(value.replace(/[.]/g, "").replace(",", "."));
        onClose(id, valueInNumber);
        resetFields();
        setModalBox(false);

        setLoadingPartitionForm(false);
    }

    function resetFields() {
        setId("");
        setName("");
        setValue("0");
    }

    return (
        <>
            <Modal size='md' onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">{name}</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>% de Rateio</label>
                                    <CurrencyInput
                                        autoFocus
                                        className="form-control text-end"
                                        id="input-value"
                                        value={value}
                                        maxLength={6}
                                        decimalsLimit={2}
                                        onValueChange={(value) => setValue(value)}
                                        intlConfig={{ locale: "pt-BR" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                        <Button variant="success btn-rounded" onClick={handlePartitionValue}>Confirmar</Button>
                    </div>
                </div>
            </Modal>

            <ModalLoading
                visible={loadingPartitionForm}
                message="Gravando Informações..."
                onClose={setLoadingPartitionForm}
            />
        </>
    )
})

export default PartitionForm;