import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import Select from "react-select";

import {
    implantLimit,
    implantTypes,
    inductionPlaces,
    resultsList,
    suppliersList
} from "../../../enum/pacemakerEnums";

import MaskedInput from "react-maskedinput";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
registerLocale("ptBR", ptBR);

const PacemakerCard = props => {
    const {
        children,
        className,
        //
        pacemakerInfoParm,
        onChangeInfo,
        ...attributes
    } = props;

    const [pacemakerInfo, setPacemakerInfo] = useState(pacemakerInfoParm);
    const [implant, setImplant] = useState(null);
    const [implantDate, setImplantDate] = useState("");
    const [supplier, setSupplier] = useState(null);
    const [generator, setGenerator] = useState("");
    const [place, setPlace] = useState(null);
    const [defibrillation, setDefibrillation] = useState(null);
    const [result, setResult] = useState(null);
    const [complications, setComplications] = useState("");
    const [observations, setObservations] = useState("");

    useEffect(() => {
        setImplant(pacemakerInfoParm.implant ?? null);
        setImplantDate(pacemakerInfoParm.implantDate ?? "");
        setSupplier(pacemakerInfoParm.supplier ?? null);
        setGenerator(pacemakerInfoParm.generator ?? "");
        setPlace(pacemakerInfoParm.place ?? null);
        setDefibrillation(pacemakerInfoParm.defibrillation ?? null);
        setResult(pacemakerInfoParm.result ?? null);
        setComplications(pacemakerInfoParm.complications ?? "");
        setObservations(pacemakerInfoParm.observations ?? "");
    }, [pacemakerInfoParm]);

    function resetFields() {
        setImplant(undefined);
        setImplantDate("");
        setSupplier(undefined);
        setGenerator("");
        setPlace(undefined);
        setDefibrillation(undefined);
        setResult(undefined);
        setComplications("");
        setObservations("");
    }

    return (
        <>
            <DCard borderColor="light">
                <DCardHeader color="light" textColor="dark">Informações MP/CDI</DCardHeader>
                <DCardBody>
                    <div className="row">
                        <div className="form-group mb-3 col-md-4">
                            <label>Implante de MP/CDI?</label>
                            <Select
                                defaultValue={implant}
                                value={implant}
                                onChange={e => {
                                    setImplant(e);
                                    onChangeInfo("implant", e);
                                }}
                                options={implantTypes}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: 45,
                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                    }),
                                }}
                                placeholder="Selecione..."
                            />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                            <label>Fabricante</label>
                            <Select
                                defaultValue={supplier}
                                value={supplier}
                                onChange={e => {
                                    setSupplier(e);
                                    onChangeInfo("supplier", e);
                                }}
                                options={suppliersList}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: 45,
                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                    }),
                                }}
                                placeholder="Selecione..."
                            />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                            <label>Data Primeiro Implante MP/CDI</label>
                            <br />
                            <DatePicker
                                selected={implantDate}
                                onChange={(date) => {
                                    setImplantDate(date);
                                    onChangeInfo("implantDate", (date));
                                }}
                                locale="ptBR"
                                dateFormat="P"
                                className="form-control"
                                customInput={
                                    <MaskedInput mask="11/11/1111" placeholder="dd/mm/yyyy" />
                                }
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-md-12">
                            <label>Gerador</label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={generator}
                                onChange={e => {
                                    setGenerator(e.target.value);
                                    onChangeInfo("generator", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-md-4">
                            <label>Local</label>
                            <Select
                                defaultValue={place}
                                value={place}
                                onChange={e => {
                                    setPlace(e);
                                    onChangeInfo("place", e);
                                }}
                                options={inductionPlaces}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: 45,
                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                    }),
                                }}
                                placeholder="Selecione..."
                            />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                            <label>Limiar de Desfibrilação</label>
                            <Select
                                defaultValue={defibrillation}
                                value={defibrillation}
                                onChange={e => {
                                    setDefibrillation(e);
                                    onChangeInfo("defibrillation", e);
                                }}
                                options={implantLimit}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: 45,
                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                    }),
                                }}
                                placeholder="Selecione..."
                            />
                        </div>
                        <div className="form-group mb-3 col-md-4">
                            <label>Resultado do Implante de MP/CDI</label>
                            <Select
                                defaultValue={result}
                                value={result}
                                onChange={e => {
                                    setResult(e);
                                    onChangeInfo("result", e);
                                }}
                                options={resultsList}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: 45,
                                        backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                    }),
                                }}
                                placeholder="Selecione..."
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-md-12">
                            <label>Complicações do Implante de MP/CDI</label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={complications}
                                onChange={e => {
                                    setComplications(e.target.value);
                                    onChangeInfo("complications", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-md-12">
                            <label>Observação sobre o MP/CDI</label>
                            <textarea
                                type="text"
                                className="form-control"
                                value={observations}
                                onChange={e => {
                                    setObservations(e.target.value);
                                    onChangeInfo("observations", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </DCardBody>
            </DCard>
        </>
    )
}

PacemakerCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

PacemakerCard.defaultProps = {
    pacemakerInfoParm: {}
};

export default PacemakerCard;