import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { cdiPreventOptions, ecgOptions, holterOptions, implantAccessTypeOptions, implantMainSymptomOptions, implantMiocNonIsqOptions, implantReasonOptions, implantResultOptions, implantTypeOptions, implantVeElectrodeOptions, implantVeinOptions, protocolYesNoOptions } from "../../../enum/protocolEnums";
import { implantTypes } from "../../../enum/pacemakerEnums";

const ImplantForm = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        protocolInfoParm,
        hide,
        ...attributes
    } = props;

    const [mainSymptom, setMainSymptom] = useState(null);
    const [otherMainSymptom, setOtherMainSymptom] = useState("");
    const [startMonth, setStartMonth] = useState("");
    const [functionalClass, setFunctionalClass] = useState("");
    const [ventriArrhythmiaEcg, setVentriArrhythmiaEcg] = useState(null);
    const [otherVentriArrhythmiaEcg, setOtherVentriArrhythmiaEcg] = useState("");
    const [holter, setHolter] = useState(null);
    const [otherHolter, setOtherHolter] = useState("");
    const [ae, setAe] = useState("");
    const [aeMl, setAeMl] = useState("");
    const [ve, setVe] = useState("");
    const [septo, setSepto] = useState("");
    const [ppost, setPpost] = useState("");
    const [fe, setFe] = useState("");

    const [has, setHas] = useState(null);
    const [diabMelli, setDiabMelli] = useState(null);
    const [miocIsq, setMiocIsq] = useState(null);
    const [miocNonIsq, setMiocNonIsq] = useState(null);
    const [otherMiocNonIsq, setOtherMiocNonIsq] = useState("");
    const [implantReason, setImplantReason] = useState(null);
    const [otherImplantReason, setOtherImplantReason] = useState("");
    const [cdiPrevent, setCdiPrevent] = useState(null);
    const [observation, setObservation] = useState("");

    const [implantType, setImplantType] = useState(null);
    const [accessType, setAccessType] = useState(null);
    const [adLimit, setAdLimit] = useState("");
    const [vdLimit, setVdLimit] = useState("");
    const [veLimit, setVeLimit] = useState("");
    const [implantVein, setImplantVein] = useState(null);
    const [otherImplantVein, setOtherImplantVein] = useState("");
    const [veElectrode, setVeElectrode] = useState(null);
    const [otherVeElectrode, setOtherVeElectrode] = useState("");
    const [qlv, setQlv] = useState("");
    const [qrsBefore, setQrsBefore] = useState("");
    const [qrsAfter, setQrsAfter] = useState("");
    const [implantResult, setImplantResult] = useState(null);
    const [complications, setComplications] = useState("");
    const [implantObservation, setImplantObservation] = useState("");

    useEffect(() => {
        setMainSymptom(protocolInfoParm.mainSymptom ?? null);
        setOtherMainSymptom(protocolInfoParm.otherMainSymptom ?? "");
        setStartMonth(protocolInfoParm.startMonth ?? null);
        setFunctionalClass(protocolInfoParm.functionalClass ?? "");
        setVentriArrhythmiaEcg(protocolInfoParm.ventriArrhythmiaEcg ?? null);
        setOtherVentriArrhythmiaEcg(protocolInfoParm.otherVentriArrhythmiaEcg ?? "");
        setHolter(protocolInfoParm.holter ?? null);
        setOtherHolter(protocolInfoParm.otherHolter ?? "");
        setAe(protocolInfoParm.ae ?? "");
        setAeMl(protocolInfoParm.aeMl ?? "");
        setVe(protocolInfoParm.ve ?? "");
        setSepto(protocolInfoParm.septo ?? "");
        setPpost(protocolInfoParm.ppost ?? "");
        setFe(protocolInfoParm.fe ?? "");

        setHas(protocolInfoParm.has ?? null);
        setDiabMelli(protocolInfoParm.diabMelli ?? null);
        setMiocIsq(protocolInfoParm.miocIsq ?? null);
        setMiocNonIsq(protocolInfoParm.miocNonIsq ?? null);
        setOtherMiocNonIsq(protocolInfoParm.otherMiocNonIsq ?? null);
        setImplantReason(protocolInfoParm.implantReason ?? null);
        setOtherImplantReason(protocolInfoParm.otherImplantReason ?? "");
        setCdiPrevent(protocolInfoParm.cdiPrevent ?? null);
        setObservation(protocolInfoParm.observation ?? "");

        setImplantType(protocolInfoParm.implantType ?? null);
        setAccessType(protocolInfoParm.accessType ?? null);
        setAdLimit(protocolInfoParm.adLimit ?? "");
        setVdLimit(protocolInfoParm.vdLimit ?? "");
        setVeLimit(protocolInfoParm.veLimit ?? "");
        setImplantVein(protocolInfoParm.implantVein ?? null);
        setOtherImplantVein(protocolInfoParm.otherImplantVein ?? "");
        setVeElectrode(protocolInfoParm.veElectrode ?? null);
        setOtherVeElectrode(protocolInfoParm.otherVeElectrode ?? "");
        setQlv(protocolInfoParm.qlv ?? "");
        setQrsBefore(protocolInfoParm.qrsBefore ?? "");
        setQrsAfter(protocolInfoParm.qrsAfter ?? "");
        setImplantResult(protocolInfoParm.implantResult ?? null);
        setComplications(protocolInfoParm.complications ?? "");
        setImplantObservation(protocolInfoParm.implantObservation ?? "");
    }, [protocolInfoParm]);

    return (
        <div hidden={hide}>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal</label>
                    <Select
                        autoFocus
                        defaultValue={mainSymptom}
                        value={mainSymptom}
                        onChange={(e) => {
                            setMainSymptom(e);
                            onChangeInfo("mainSymptom", e);
                        }}
                        options={implantMainSymptomOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Queixa Principal (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMainSymptom}
                        onChange={e => {
                            setOtherMainSymptom(e.target.value);
                            onChangeInfo("otherMainSymptom", e.target.value);
                        }}
                        disabled={mainSymptom?.value !== 5}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Início (meses)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={startMonth}
                        onChange={e => {
                            setStartMonth(e.target.value);
                            onChangeInfo("startMonth", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Classe Funcional NYHA (número)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={functionalClass}
                        onChange={e => {
                            setFunctionalClass(e.target.value);
                            onChangeInfo("functionalClass", e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>ECG</label>
                    <Select
                        defaultValue={ventriArrhythmiaEcg}
                        value={ventriArrhythmiaEcg}
                        onChange={(e) => {
                            setVentriArrhythmiaEcg(e);
                            onChangeInfo("ventriArrhythmiaEcg", e);
                        }}
                        options={ecgOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>ECG (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherVentriArrhythmiaEcg}
                        onChange={e => {
                            setOtherVentriArrhythmiaEcg(e.target.value);
                            onChangeInfo("otherVentriArrhythmiaEcg", e.target.value);
                        }}
                        disabled={ventriArrhythmiaEcg?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Holter</label>
                    <Select
                        defaultValue={holter}
                        value={holter}
                        onChange={(e) => {
                            setHolter(e);
                            onChangeInfo("holter", e);
                        }}
                        options={holterOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Holter (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherHolter}
                        onChange={e => {
                            setOtherHolter(e.target.value);
                            onChangeInfo("otherHolter", e.target.value);
                        }}
                        disabled={holter?.value !== 6}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>AE (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ae}
                        onChange={e => {
                            setAe(e.target.value);
                            onChangeInfo("ae", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>AE (ml)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={aeMl}
                        onChange={e => {
                            setAeMl(e.target.value);
                            onChangeInfo("aeMl", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>VE (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ve}
                        onChange={e => {
                            setVe(e.target.value);
                            onChangeInfo("ve", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Septo (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={septo}
                        onChange={e => {
                            setSepto(e.target.value);
                            onChangeInfo("septo", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Ppost (mm)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={ppost}
                        onChange={e => {
                            setPpost(e.target.value);
                            onChangeInfo("ppost", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>FE (%)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={fe}
                        onChange={e => {
                            setFe(e.target.value);
                            onChangeInfo("fe", e.target.value);
                        }}
                    />
                </div>
            </div>

            <hr className="mt-4" />

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>HAS</label>
                    <Select
                        defaultValue={has}
                        value={has}
                        onChange={(e) => {
                            setHas(e);
                            onChangeInfo("has", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Diab Melli</label>
                    <Select
                        defaultValue={diabMelli}
                        value={diabMelli}
                        onChange={(e) => {
                            setDiabMelli(e);
                            onChangeInfo("diabMelli", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Mioc Isq</label>
                    <Select
                        defaultValue={miocIsq}
                        value={miocIsq}
                        onChange={(e) => {
                            setMiocIsq(e);
                            onChangeInfo("miocIsq", e);
                        }}
                        options={protocolYesNoOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Mioc Não Isq</label>
                    <Select
                        defaultValue={miocNonIsq}
                        value={miocNonIsq}
                        onChange={(e) => {
                            setMiocNonIsq(e);
                            onChangeInfo("miocNonIsq", e);
                        }}
                        options={implantMiocNonIsqOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-4">
                    <label>Mioc Não Isq (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherMiocNonIsq}
                        onChange={e => {
                            setOtherMiocNonIsq(e.target.value);
                            onChangeInfo("otherMiocNonIsq", e.target.value);
                        }}
                        disabled={miocNonIsq?.value !== 7}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-3">
                    <label>Motivo implante</label>
                    <Select
                        defaultValue={implantReason}
                        value={implantReason}
                        onChange={(e) => {
                            setImplantReason(e);
                            onChangeInfo("implantReason", e);
                        }}
                        options={implantReasonOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Motivo implante (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherImplantReason}
                        onChange={e => {
                            setOtherImplantReason(e.target.value);
                            onChangeInfo("otherImplantReason", e.target.value);
                        }}
                        disabled={implantReason?.value !== 6}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>CDI Prevenção</label>
                    <Select
                        defaultValue={cdiPrevent}
                        value={cdiPrevent}
                        onChange={(e) => {
                            setCdiPrevent(e);
                            onChangeInfo("cdiPrevent", e);
                        }}
                        options={cdiPreventOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Observações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={observation}
                        onChange={e => {
                            setObservation(e.target.value);
                            onChangeInfo("observation", e.target.value);
                        }}
                    />
                </div>
            </div>

            <hr className="mt-4" />

            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>Tipo Implante</label>
                    <Select
                        defaultValue={implantType}
                        value={implantType}
                        onChange={(e) => {
                            setImplantType(e);
                            onChangeInfo("implantType", e);
                        }}
                        options={implantTypes}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Acesso</label>
                    <Select
                        defaultValue={accessType}
                        value={accessType}
                        onChange={(e) => {
                            setAccessType(e);
                            onChangeInfo("accessType", e);
                        }}
                        options={implantAccessTypeOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Limiar AD (mV)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={adLimit}
                        onChange={e => {
                            setAdLimit(e.target.value);
                            onChangeInfo("adLimit", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Limiar VD (mV)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={vdLimit}
                        onChange={e => {
                            setVdLimit(e.target.value);
                            onChangeInfo("vdLimit", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Limiar VE (mV)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={veLimit}
                        onChange={e => {
                            setVeLimit(e.target.value);
                            onChangeInfo("veLimit", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>Veia Implante</label>
                    <Select
                        defaultValue={implantVein}
                        value={implantVein}
                        onChange={(e) => {
                            setImplantVein(e);
                            onChangeInfo("implantVein", e);
                        }}
                        options={implantVeinOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Veia Implante (Outra)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherImplantVein}
                        onChange={e => {
                            setOtherImplantVein(e.target.value);
                            onChangeInfo("otherImplantVein", e.target.value);
                        }}
                        disabled={implantVein?.value !== 6}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Eletrodo VE</label>
                    <Select
                        defaultValue={veElectrode}
                        value={veElectrode}
                        onChange={(e) => {
                            setVeElectrode(e);
                            onChangeInfo("veElectrode", e);
                        }}
                        options={implantVeElectrodeOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Eletrodo VE (Outro)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={otherVeElectrode}
                        onChange={e => {
                            setOtherVeElectrode(e.target.value);
                            onChangeInfo("otherVeElectrode", e.target.value);
                        }}
                        disabled={veElectrode?.value !== 4}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>QLV (ms)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={qlv}
                        onChange={e => {
                            setQlv(e.target.value);
                            onChangeInfo("qlv", e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="row mt-4">
                <div className="form-group mb-12 col-md-2">
                    <label>QRS antes (ms)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={qrsBefore}
                        onChange={e => {
                            setQrsBefore(e.target.value);
                            onChangeInfo("qrsBefore", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>QRS depois (ms)</label>
                    <input
                        type="text"
                        className="form-control"
                        value={qrsAfter}
                        onChange={e => {
                            setQrsAfter(e.target.value);
                            onChangeInfo("qrsAfter", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-2">
                    <label>Implante</label>
                    <Select
                        defaultValue={implantResult}
                        value={implantResult}
                        onChange={(e) => {
                            setImplantResult(e);
                            onChangeInfo("implantResult", e);
                        }}
                        options={implantResultOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: 45,
                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                            }),
                        }}
                        placeholder="Selecione..."
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Complicações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={complications}
                        onChange={e => {
                            setComplications(e.target.value);
                            onChangeInfo("complications", e.target.value);
                        }}
                    />
                </div>
                <div className="form-group mb-12 col-md-3">
                    <label>Observações</label>
                    <input
                        type="text"
                        className="form-control"
                        value={implantObservation}
                        onChange={e => {
                            setImplantObservation(e.target.value);
                            onChangeInfo("implantObservation", e.target.value);
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

ImplantForm.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

ImplantForm.defaultProps = {
    protocolInfoParm: {},
    hide: false
};

export default ImplantForm;