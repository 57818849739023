import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, FormCheck, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import api from "../../../services/api";
import DFormText from "../../components/DFormText/DFormText";
import ModalLoading from "../../components/ModalLoading/ModalLoading";

const ProcedureTypeForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingProcedureTypeForm, setLoadingProcedureTypeForm] = useState(false);

    const [name, setName] = useState("");
    const [procedureGroupsSelect, setProcedureGroupsSelect] = useState([]);
    const [procedureGroup, setProcedureGroup] = useState("");
    const [diagnosis, setDiagnosis] = useState("");
    const [defaultText, setDefaultText] = useState("");
    const [active, setActive] = useState(true);

    const [procedureTypeId, setProcedureTypeId] = useState(undefined);

    const [invalidName, setInvalidName] = useState(false);
    const [invalidProcedureGroup, setInvalidProcedureGroup] = useState(false);

    const [errorMessage, setErrorMessage] = useState("Ocorreu um problema ao salvar o tipo de procedimento");

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'align': ['', 'right', 'center', 'justify'] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'align', 'list', 'bullet', 'indent',
        'link', 'image'
    ];

    useImperativeHandle(ref, () => ({
        openModal(mode, procedureTypeInfo = {}) {
            setModalBox(true);
            setIsUpdate(false);

            getProcedureGroups();

            resetFields();

            if (mode === "update") {
                setIsUpdate(true);

                setProcedureTypeId(procedureTypeInfo._id);
                setName(procedureTypeInfo.name);
                setProcedureGroup({
                    value: procedureTypeInfo.procedure_group_id,
                    label: procedureTypeInfo.procedure_group_name
                });
                setDiagnosis(procedureTypeInfo.diagnosis);
                setDefaultText(procedureTypeInfo.default_text);
                setActive(procedureTypeInfo.status);
            }
        },
    }));

    async function getProcedureGroups() {
        await api.get("/procedure-groups?status=true")
            .then(response => {
                if (response.status === 200) {
                    setProcedureGroupsSelect(response.data.procedureGroups.map(procedureGroup => {
                        return {
                            value: procedureGroup._id,
                            label: procedureGroup.name
                        }
                    }))
                }
            })
    }

    async function handleSaveProcedureType(e) {
        e.preventDefault();

        setLoadingProcedureTypeForm(true);

        setInvalidName(false);
        setInvalidProcedureGroup(false);

        let hasError = false;

        if (name === "") {
            setInvalidName(true);
            hasError = true;
        }

        if (procedureGroup === "") {
            setInvalidProcedureGroup(true);
            hasError = true;
        }

        if (!hasError) {
            let procedureType = {
                name,
                procedure_group_id: procedureGroup.value,
                procedure_group_name: procedureGroup.label,
                diagnosis,
                default_text: defaultText,
                status: active
            };

            if (!isUpdate) {
                try {
                    await api.post("/procedure-types", procedureType)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingProcedureTypeForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    if (err.data?.error === "ALREADY_EXISTS") {
                        setErrorMessage("Tipo de procedimento já cadastrado");
                    }
                    notifyError();
                }
            } else {
                try {
                    await api.put(`/procedure-types/${procedureTypeId}`, procedureType)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingProcedureTypeForm(false);
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }
            }
        }

        setLoadingProcedureTypeForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Tipo de Procedimento ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function resetFields() {
        setProcedureTypeId(undefined);
        setName("");
        setProcedureGroup("");
        setDiagnosis("");
        setDefaultText("");
        setActive(true);

        setInvalidName(false);
        setInvalidProcedureGroup(false);
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">
                            {(!isUpdate ? "Novo " : "Alterar ")}Tipo de Procedimento
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row" hidden={!isUpdate}>
                                <div className="form-group mb-3 col-md-6">
                                    <FormCheck
                                        type="switch"
                                        id="active"
                                        label="Ativo"
                                        defaultChecked={active}
                                        onChange={() => setActive(!active)}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Nome</label>
                                    <input
                                        type="text"
                                        className={`form-control ${(invalidName ? "is-invalid" : "")}`}
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                        disabled={isUpdate}
                                    />
                                    <DFormText hidden={!invalidName} color="danger">Informe o tipo de procedimento</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Grupo de Procedimentos</label>
                                    <Select
                                        defaultValue={procedureGroup}
                                        onChange={(e) => setProcedureGroup(e)}
                                        options={procedureGroupsSelect}
                                        style={{
                                            lineHeight: "40px",
                                            color: "#7e7e7e",
                                            paddingLeft: " 15px",
                                        }}
                                        placeholder="Selecione..."
                                    />
                                    <DFormText hidden={!invalidProcedureGroup} color="danger">Informe o grupo de procedimento</DFormText>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Diagnóstico</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={diagnosis}
                                        onChange={setDiagnosis}
                                        modules={modules}
                                        formats={formats}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Texto Padrão</label>
                                    <ReactQuill
                                        theme="snow"
                                        value={defaultText}
                                        onChange={setDefaultText}
                                        modules={modules}
                                        formats={formats}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={() => setModalBox(false)}
                        >
                            Fechar
                        </Button>
                        <Button variant="success btn-rounded" onClick={handleSaveProcedureType}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingProcedureTypeForm}
                message="Gravando Informações..."
                onClose={setLoadingProcedureTypeForm}
            />
        </>
    );
});

export default ProcedureTypeForm;
