import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import dayjs from "dayjs";

import DFormText from "../../components/DFormText/DFormText";

import api from "../../../services/api";

import ModalLoading from "../../components/ModalLoading/ModalLoading";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import swal from "sweetalert";
registerLocale("ptBR", ptBR);

const DoctorAvailabilityForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingScheduleForm, setLoadingScheduleForm] = useState(false);

    const [scheduleId, setScheduleId] = useState("");

    const [doctorsList, setDoctorsList] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().toDate());
    const [endDate, setEndDate] = useState(dayjs().toDate());

    const [scheduleDate, setScheduleDate] = useState("");
    const [doctor, setDoctor] = useState(null);
    const [user, setUser] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [observations, setObservations] = useState("");
    const [status, setStatus] = useState("pending");

    const [invalidScheduleDate, setInvalidScheduleDate] = useState(false);
    const [invalidDoctor, setInvalidDoctor] = useState(false);
    const [invalidStartDate, setInvalidStartDate] = useState(false);
    const [invalidEndDate, setInvalidEndDate] = useState(false);
    const [invalidEndDateMessage, setInvalidEndDateMessage] = useState("Informe a data final");
    const [invalidUser, setInvalidUser] = useState(false);
    const [invalidCustomer, setInvalidCustomer] = useState(false);

    var successMessage = "Registro incluído com sucesso!";
    var errorMessage = "Ocorreu um problema ao salvar o registro";

    useImperativeHandle(ref, () => ({
        async openModal(mode, schedule_id = "") {
            setModalBox(true);
            setIsUpdate(false);

            getDoctors();

            if (mode === "update") {
                setIsUpdate(true);
                await loadSchedule(schedule_id);
            }
        },
    }));

    async function getDoctors() {
        await api.get("/doctors?status=true&lec=true&offset=1&limit=100")
            .then(response => {
                if (response.status === 200 || response.status === 304) {
                    const doctorsReturned = response.data.doctors.map(doctor => {
                        return {
                            value: doctor._id,
                            label: doctor.name,
                            color: doctor.color ?? ""
                        }
                    });
                    setDoctorsList(doctorsReturned);
                }
            })
    }

    async function loadSchedule(id) {
        await api.get(`/availabilities/${id}`)
            .then(response => {
                if (response.status === 200) {
                    setScheduleId(id);
                    setDoctor({
                        value: response.data.doctor_id,
                        label: response.data.doctor_name,
                        color: response.data.color,
                    });
                    setStartDate(dayjs(response.data.date_from).toDate());
                    setEndDate(dayjs(response.data.date_to).subtract(1, "day").toDate());
                }
            })
    }

    async function handleSaveSchedule(e) {
        e.preventDefault();

        setInvalidDoctor(false);
        setInvalidStartDate(false);
        setInvalidEndDate(false);

        let hasError = false;

        if (!doctor) {
            setInvalidDoctor(true);
            hasError = true;
        }

        if (!startDate) {
            setInvalidStartDate(true);
            hasError = true;
        }

        if (!endDate) {
            setInvalidEndDateMessage("Informe a data final");
            setInvalidEndDate(true);
            hasError = true;
        } else {
            if (startDate) {
                if (dayjs(startDate).isAfter(endDate)) {
                    setInvalidEndDateMessage("A data final deve ser maior que a inicial");
                    setInvalidEndDate(true);
                    hasError = true;
                }
            }
        }

        if (!hasError) {
            const startDateFormatted = dayjs(startDate).format("YYYY-MM-DD") + "T03:00:00.000Z";
            const endDateFormatted = dayjs(endDate).add(1, "day").format("YYYY-MM-DD") + "T03:00:00.000Z";

            if (!isUpdate) {
                const newAvailability = {
                    doctor_id: doctor.value,
                    doctor_name: doctor.label,
                    date_from: startDateFormatted,
                    date_to: endDateFormatted,
                    color: doctor.color
                };

                try {
                    api.post("/availabilities/", newAvailability)
                        .then(response => {
                            if (response.status === 201) {
                                setLoadingScheduleForm(false);
                                successMessage = "Registro incluído com sucesso!";
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        });
                } catch (err) {
                    errorMessage = "Ocorreu um problema ao salvar o registro";
                    notifyError();
                }
            } else {
                const updateAvailability = {
                    date_from: startDateFormatted,
                    date_to: endDateFormatted
                };

                try {
                    api.put(`/availabilities/${scheduleId}`, updateAvailability)
                        .then(response => {
                            if (response.status === 200) {
                                setLoadingScheduleForm(false);
                                successMessage = "Registro alterado com sucesso!";
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        });
                } catch (err) {
                    errorMessage = "Ocorreu um problema ao salvar o registro";
                    notifyError();
                }
            }
        }
    }

    function handleCancelSchedule() {
        resetFields();
        setModalBox(false);
    }

    function handleChangeStartDate(date) {
        setStartDate(date);
        setInvalidEndDate(false);

        if (dayjs(date).isAfter(endDate)) {
            setInvalidEndDateMessage("A data final deve ser maior que a inicial");
            setInvalidEndDate(true);
        }
    }

    function handleChangeEndDate(date) {
        setEndDate(date);
        setInvalidEndDate(false);

        if (dayjs(startDate).isAfter(date)) {
            setInvalidEndDateMessage("A data final deve ser maior que a inicial");
            setInvalidEndDate(true);
        }
    }

    function resetFields() {
        setScheduleId("");
        setStartDate(dayjs().toDate());
        setEndDate(dayjs().toDate());
        setDoctor(null);

        setInvalidDoctor(false);
        setInvalidStartDate(false);
        setInvalidEndDate(false);
        successMessage = "Registro incluído com sucesso!";
        errorMessage = "Ocorreu um problema ao salvar o registro";
    }

    function notifySuccess() {
        toast.success(`✔️ ${successMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ ${errorMessage}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleDeleteSchedule() {
        swal({
            title: "Confirma a exclusão?",
            icon: "warning",
            buttons: ["Cancelar", "Confirmar"],
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                setLoadingScheduleForm(true);

                try {
                    await api.delete(`/availabilities/${scheduleId}`)
                        .then(response => {
                            if (response.status === 200) {
                                successMessage = "Registro excluído com sucesso!";
                                notifySuccess();
                                resetFields();
                                onClose();
                                setModalBox(false);
                            }
                        })
                } catch (err) {
                    notifyError();
                }

                setLoadingScheduleForm(false);
            } else {
                return
            }
        })
    }

    return (
        <>
            <Modal size="xl" onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            {(!isUpdate ? "Nova " : "Alterar ")}Indisponibilidade
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-6">
                                    <label>Médico</label>
                                    <Select
                                        id="doctor"
                                        isClearable
                                        className={`${(invalidDoctor ? "form-control is-invalid" : "")}`}
                                        defaultValue={doctor}
                                        value={doctor}
                                        onChange={e => setDoctor(e)}
                                        options={doctorsList}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                height: 45,
                                                backgroundColor: state.isDisabled ? "#dde0e3" : ""
                                            }),
                                        }}
                                        placeholder="Selecione..."
                                        isDisabled={isUpdate}
                                    />
                                    <DFormText hidden={!invalidUser} color="danger">Informe o médico</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Data Inicial</label>
                                    <br />
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => handleChangeStartDate(date)}
                                        locale="ptBR"
                                        dateFormat="P"
                                        className={`form-control ${(invalidStartDate ? " is-invalid" : "")}`}
                                    />
                                    <br />
                                    <DFormText hidden={!invalidStartDate} color="danger">Informe a data inicial</DFormText>
                                </div>
                                <div className="form-group mb-3 col-md-3">
                                    <label>Data Final</label>
                                    <br />
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date) => handleChangeEndDate(date)}
                                        locale="ptBR"
                                        dateFormat="P"
                                        className={`form-control ${(invalidEndDate ? " is-invalid" : "")}`}
                                    />
                                    <br />
                                    <DFormText hidden={!invalidEndDate} color="danger">{invalidEndDateMessage}</DFormText>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <div className="align-start">
                            <Button
                                variant="danger btn-rounded"
                                onClick={handleDeleteSchedule}
                                hidden={!isUpdate || status !== "pending"}
                            >
                                Excluir
                            </Button>
                        </div>
                        <div className="align-end">
                            <Button
                                className="me-2"
                                variant="outline-danger btn-rounded"
                                onClick={handleCancelSchedule}
                            >
                                Fechar
                            </Button>
                            <Button
                                variant="success btn-rounded"
                                onClick={handleSaveSchedule}
                                hidden={status !== "pending"}
                            >
                                Salvar
                            </Button>
                        </div>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingScheduleForm}
                message="Gravando Agenda..."
                onClose={setLoadingScheduleForm}
            />
        </>
    );
});

export default DoctorAvailabilityForm;
