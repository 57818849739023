import React, { forwardRef, useImperativeHandle, useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';

import ModalLoading from '../../components/ModalLoading/ModalLoading';
import { getActionName, getRoutineName, getTypeName } from '../../../services/logService';
import dayjs from 'dayjs';


const EventLogForm = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [loadingEventLogForm, setLoadingEventLogForm] = useState(false);

    const [user, setUser] = useState("");
    const [routine, setRoutine] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [action, setAction] = useState("");
    const [type, setType] = useState("");
    const [message, setMessage] = useState("");
    const [jsonOrigin, setJsonOrigin] = useState("");
    const [jsonReturn, setJsonReturn] = useState("");

    useImperativeHandle(ref, () => ({
        openModal(eventLogInfo = {}) {
            setModalBox(true);
            resetFields();

            setUser(eventLogInfo.username);
            setRoutine(getRoutineName(eventLogInfo.routine));
            setEventDate(dayjs(eventLogInfo.event_date).format("DD/MM/YYYY HH:mm:ss"));
            setAction(getActionName(eventLogInfo.action));
            setType(getTypeName(eventLogInfo.type));
            setMessage(eventLogInfo.message);

            const jsonOriginFormatted = JSON.stringify(JSON.parse(eventLogInfo.json_origin), undefined, 4);
            setJsonOrigin(jsonOriginFormatted);

            const jsonReturnFormatted = JSON.stringify(JSON.parse(eventLogInfo.json_return), undefined, 4);
            setJsonReturn(jsonReturnFormatted);
        }
    }));

    function resetFields() {
        setUser("");
        setRoutine("");
        setEventDate("");
        setAction("");
        setType("");
        setMessage("");
        setJsonOrigin("");
        setJsonReturn("");
    }

    return (
        <>
            <Modal size='xl' onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="exampleModalLabel">Detalhes</h3>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Status</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        value={type}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Data</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        value={eventDate}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-4">
                                    <label>Usuário</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        value={user}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Rotina</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        value={routine}
                                    />
                                </div>
                                <div className="form-group mb-3 col-md-4">
                                    <label>Ação</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        value={action}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>Mensagem</label>
                                    <input
                                        disabled
                                        type="text"
                                        className="form-control"
                                        value={message}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>JSON Envio</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        value={jsonOrigin}
                                        disabled
                                        rows={15}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-md-12">
                                    <label>JSON Retorno</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        value={jsonReturn}
                                        disabled
                                        rows={15}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button variant="outline-danger btn-rounded" onClick={() => setModalBox(false)}>Fechar</Button>
                    </div>
                </div>
            </Modal>

            <ModalLoading
                visible={loadingEventLogForm}
                message="Carregando Informações..."
                onClose={setLoadingEventLogForm}
            />
        </>
    )
})

export default EventLogForm;