import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

import DCard from "../../components/card/DCard";
import DCardHeader from "../../components/card/DCardHeader";
import DCardBody from "../../components/card/DCardBody";
import DFormText from "../../components/DFormText/DFormText";

const PunctionCard = props => {
    const {
        children,
        className,
        //
        onChangeInfo,
        punctionInfoParm,
        invalidFieldsParm,
        hide,
        ...attributes
    } = props;

    const [punction1pfd, setPunction1pfd] = useState(false);
    const [punction2pfd, setPunction2pfd] = useState(false);
    const [punction3pfd, setPunction3pfd] = useState(false);
    const [punction1pfe, setPunction1pfe] = useState(false);
    const [punction1pj, setPunction1pj] = useState(false);
    const [punction1ps, setPunction1ps] = useState(false);
    const [punction1paf, setPunction1paf] = useState(false);
    const [punction1pe, setPunction1pe] = useState(false);
    const [punction1pt, setPunction1pt] = useState(false);
    const [punction2pt, setPunction2pt] = useState(false);
    const [punction1blc, setPunction1blc] = useState(false);
    const [punction2blc, setPunction2blc] = useState(false);
    const [punction1blev, setPunction1blev] = useState(false);
    const [punction1blea, setPunction1blea] = useState(false);
    const [punction7f, setPunction7f] = useState("0");
    const [punction8f, setPunction8f] = useState("0");

    const [invalidPunction7f, setInvalidPunction7f] = useState(false);
    const [invalidPunction8f, setInvalidPunction8f] = useState(false);

    useEffect(() => {
        setPunction1pfd(punctionInfoParm.punction1pfd ?? false);
        setPunction2pfd(punctionInfoParm.punction2pfd ?? false);
        setPunction3pfd(punctionInfoParm.punction3pfd ?? false);
        setPunction1pfe(punctionInfoParm.punction1pfe ?? false);
        setPunction1pj(punctionInfoParm.punction1pj ?? false);
        setPunction1ps(punctionInfoParm.punction1ps ?? false);
        setPunction1paf(punctionInfoParm.punction1paf ?? false);
        setPunction1pe(punctionInfoParm.punction1pe ?? false);
        setPunction1pt(punctionInfoParm.punction1pt ?? false);
        setPunction2pt(punctionInfoParm.punction2pt ?? false);
        setPunction1blc(punctionInfoParm.punction1blc ?? false);
        setPunction2blc(punctionInfoParm.punction2blc ?? false);
        setPunction1blev(punctionInfoParm.punction1blev ?? false);
        setPunction1blea(punctionInfoParm.punction1blea ?? false);
        setPunction7f(punctionInfoParm.punction7f ?? "0");
        setPunction8f(punctionInfoParm.punction8f ?? "0");
    }, [punctionInfoParm]);

    useEffect(() => {
        setInvalidPunction7f(false);
        setInvalidPunction8f(false);

        invalidFieldsParm.forEach(invalidField => {
            if (invalidField === "punction7f") {
                setInvalidPunction7f(true);
            }

            if (invalidField === "punction8f") {
                setInvalidPunction8f(true);
            }
        })
    }, [invalidFieldsParm]);

    return (
        <div hidden={hide}>
            <div className="form-group mb-3 col-md-12">
                <DCard borderColor="light">
                    <DCardHeader color="light" textColor="dark">Punções e bainhas curtas e longas usadas durante o procedimento</DCardHeader>
                    <DCardBody>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1pfd"
                                        checked={punction1pfd}
                                        onChange={e => {
                                            setPunction1pfd(!punction1pfd);
                                            onChangeInfo("punction1pfd", !punction1pfd);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1pfd">
                                        1 punção femoral direita
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1ps"
                                        checked={punction1ps}
                                        onChange={e => {
                                            setPunction1ps(!punction1ps);
                                            onChangeInfo("punction1ps", !punction1ps);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1ps">
                                        1 punção subclávia
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1blc"
                                        checked={punction1blc}
                                        onChange={e => {
                                            setPunction1blc(!punction1blc);
                                            onChangeInfo("punction1blc", !punction1blc);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1blc">
                                        Uso de 1 bainha longa convencional
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction2pfd"
                                        checked={punction2pfd}
                                        onChange={e => {
                                            setPunction2pfd(!punction2pfd);
                                            onChangeInfo("punction2pfd", !punction2pfd);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction2pfd">
                                        2 punções femorais direitas
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1paf"
                                        checked={punction1paf}
                                        onChange={e => {
                                            setPunction1paf(!punction1paf);
                                            onChangeInfo("punction1paf", !punction1paf);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1paf">
                                        1 punção de artéria femoral
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction2blc"
                                        checked={punction2blc}
                                        onChange={e => {
                                            setPunction2blc(!punction2blc);
                                            onChangeInfo("punction2blc", !punction2blc);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction2blc">
                                        Uso de 2 bainhas longas convencionais
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction3pfd"
                                        checked={punction3pfd}
                                        onChange={e => {
                                            setPunction3pfd(!punction3pfd);
                                            onChangeInfo("punction3pfd", !punction3pfd);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction3pfd">
                                        3 punções femorais direitas
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1pe"
                                        checked={punction1pe}
                                        onChange={e => {
                                            setPunction1pe(!punction1pe);
                                            onChangeInfo("punction1pe", !punction1pe);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1pe">
                                        1 punção epicárdica
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1blev"
                                        checked={punction1blev}
                                        onChange={e => {
                                            setPunction1blev(!punction1blev);
                                            onChangeInfo("punction1blev", !punction1blev);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1blev">
                                        Uso de 1 bainha longa especial VISIGO
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1pfe"
                                        checked={punction1pfe}
                                        onChange={e => {
                                            setPunction1pfe(!punction1pfe);
                                            onChangeInfo("punction1pfe", !punction1pfe);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1pfe">
                                        1 punção femoral esquerda
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1pt"
                                        checked={punction1pt}
                                        onChange={e => {
                                            setPunction1pt(!punction1pt);
                                            onChangeInfo("punction1pt", !punction1pt);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1pt">
                                        1 punção transeptal
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1blea"
                                        checked={punction1blea}
                                        onChange={e => {
                                            setPunction1blea(!punction1blea);
                                            onChangeInfo("punction1blea", !punction1blea);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1blea">
                                        Uso de 1 bainha longa especial AGILIS
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction1pj"
                                        checked={punction1pj}
                                        onChange={e => {
                                            setPunction1pj(!punction1pj);
                                            onChangeInfo("punction1pj", !punction1pj);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction1pj">
                                        1 punção jugular
                                    </label>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="mb-2">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="punction2pt"
                                        checked={punction2pt}
                                        onChange={e => {
                                            setPunction2pt(!punction2pt);
                                            onChangeInfo("punction2pt", !punction2pt);
                                        }}
                                    />
                                    <label className="form-check-label ms-3" htmlFor="punction2pt">
                                        2 punções transeptais
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-3 col-md-3" style={{ width: "20%" }}>
                                <label>Número de introdutores curtos 7F</label>
                            </div>
                            <div className="col-md-1">
                                <InputMask
                                    mask="999"
                                    maskChar=" "
                                    className={`form-control ${(invalidPunction7f ? "is-invalid" : "")}`}
                                    value={punction7f}
                                    onChange={(e) => {
                                        setPunction7f(e.target.value);
                                        onChangeInfo("punction7f", e.target.value);
                                    }}
                                    alwaysShowMask={true}
                                >
                                    {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidPunction7f ? "is-invalid" : "")}`} />}
                                </InputMask>
                                <DFormText hidden={!invalidPunction7f} color="danger">Campo obrigatório</DFormText>
                            </div>
                            <div className="mt-3 col-md-3" style={{ paddingLeft: "5.2rem" }}>
                                <label>Número de introdutores curtos 8F</label>
                            </div>
                            <div className="col-md-1">
                                <InputMask
                                    mask="999"
                                    maskChar=" "
                                    value={punction8f}
                                    onChange={(e) => {
                                        setPunction8f(e.target.value);
                                        onChangeInfo("punction8f", e.target.value);
                                    }}
                                    alwaysShowMask={true}
                                >
                                    {(inputProps) => <input {...inputProps} type="text" className={`form-control ${(invalidPunction8f ? "is-invalid" : "")}`} />}
                                </InputMask>
                                <DFormText hidden={!invalidPunction8f} color="danger">Campo obrigatório</DFormText>
                            </div>
                        </div>
                    </DCardBody>
                </DCard>
            </div>
        </div>
    )
}

PunctionCard.propTypes = {
    children: PropTypes.node,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
    //
    innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};

PunctionCard.defaultProps = {
    punctionInfoParm: {},
    invalidFieldsParm: [],
    hide: false
};

export default PunctionCard;