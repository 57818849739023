import React, { forwardRef, useImperativeHandle, useState } from "react";
import {
    Button,
    Modal,
    Tab,
    Tabs
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

import { useFiles } from "../../../context/files";
import UploadFile from "../../components/UploadFile/UploadFile";

import {
    avaliationOptions,
    evtvPlaceOptions,
    examsOptions,
    faPlaceOptions,
    holterOptions,
    implantEcgOptions,
    implantHolterOptions,
    postImplantOptions,
    ventriArrhythmiaOptions
} from "../../../enum/followUpEnum";
import {
    actualDaaOptions,
    anticoagOptions,
    ecgConsultOptions,
    implantMainSymptomOptions,
    mainSymptomOptions,
    protocolYesNoOptions
} from "../../../enum/protocolEnums";

import FollowFaForm from "./FollowFaForm";
import FollowEvTvForm from "./FollowEvTvForm";
import FollowImplantForm from "./FollowImplantForm";

import ModalLoading from "../../components/ModalLoading/ModalLoading";

import { createLog } from "../../../services/logService";
import api from "../../../services/api";

const FollowUpForm = forwardRef((props, ref) => {
    const { onClose } = props;
    const [modalBox, setModalBox] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [loadingProtocolForm, setLoadingFollowUpForm] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("Salvando Seguimento...");

    const [schedule, setSchedule] = useState({});

    const [procedureCode, setProcedureCode] = useState("");

    const [followInfo, setFollowInfo] = useState({});
    const [followUpId, setFollowUpId] = useState(undefined);

    const { uploadedFiles, resetFiles, loadFiles } = useFiles();

    useImperativeHandle(ref, () => ({
        async openModal(mode, infoParm) {
            setModalBox(true);
            setIsUpdate(false);

            if (mode === "new") {
                if (infoParm?.reports) {
                    if (infoParm.reports.length > 0) {
                        const procedureInfo = infoParm.reports[0];
                        setSchedule(procedureInfo);
                    }
                } else {
                    setSchedule(infoParm);
                }

                const procedureCode = infoParm.procedure_name.substr(0, infoParm.procedure_name.indexOf("-") - 1).trim();
                setProcedureCode(procedureCode);
            } else {
                setIsUpdate(true);
                loadFollowUp(infoParm);
            }

            resetFiles();
        },
    }));

    async function loadFollowUp(id) {
        const followUpInfo = await getFollowUp(id);

        if (followUpInfo) {
            setProcedureCode(followUpInfo.procedure_id);
            getScheduleById(followUpInfo.schedule_id);

            let followUpReturned = {};

            setFollowUpId(followUpInfo._id);

            if (followUpInfo?.avaliation >= 0) {
                const avaliationOption = avaliationOptions.find(x => x.value === followUpInfo.avaliation);
                followUpReturned.avaliation = avaliationOption;
            }

            if (followUpInfo?.other_avaliation) {
                followUpReturned.otherAvaliation = followUpInfo.other_avaliation;
            }

            if (followUpInfo?.place >= 0) {
                let placeOption = null;

                if (followUpInfo.procedure_id === "EV") {
                    placeOption = evtvPlaceOptions.find(x => x.value === followUpInfo.place);
                } else if (followUpInfo.procedure_id === "FA") {
                    placeOption = faPlaceOptions.find(x => x.value === followUpInfo.place);
                }

                followUpReturned.place = placeOption;
            }

            if (followUpInfo?.other_place) {
                followUpReturned.otherPlace = followUpInfo.other_place;
            }

            if (followUpInfo?.main_symptom >= 0) {
                let mainSymptomOption = null;

                if (followUpInfo.procedure_id === "MP" || followUpInfo.procedure_id === "CDI") {
                    mainSymptomOption = implantMainSymptomOptions.find(x => x.value === followUpInfo.main_symptom);
                } else {
                    mainSymptomOption = mainSymptomOptions.find(x => x.value === followUpInfo.main_symptom);
                }

                followUpReturned.mainSymptom = mainSymptomOption;
            }

            if (followUpInfo.other_main_symptom) {
                followUpReturned.otherMainSymptom = followUpInfo.other_main_symptom;
            }

            if (followUpInfo?.ecg >= 0) {
                let ecgOption = null;

                if (followUpInfo.procedure_id === "MP" || followUpInfo.procedure_id === "CDI") {
                    ecgOption = implantEcgOptions.find(x => x.value === followUpInfo.ecg);
                } else {
                    ecgOption = ecgConsultOptions.find(x => x.value === followUpInfo.ecg);
                }

                followUpReturned.ecg = ecgOption;
            }

            if (followUpInfo.other_ecg) {
                followUpReturned.otherEcg = followUpInfo.other_ecg;
            }

            if (followUpInfo?.anticoag >= 0) {
                const anticoagOption = anticoagOptions.find(x => x.value === followUpInfo.anticoag);
                followUpReturned.anticoag = anticoagOption;
            }

            if (followUpInfo.other_anticoag) {
                followUpReturned.otherAnticoag = followUpInfo.other_anticoag;
            }

            if (followUpInfo?.ventri_arrhythmia >= 0) {
                const ventriArrhythmiaOption = ventriArrhythmiaOptions.find(x => x.value === followUpInfo.ventri_arrhythmia);
                followUpReturned.ventriArrhythmia = ventriArrhythmiaOption;
            }

            if (followUpInfo.other_ventri_arrhythmia) {
                followUpReturned.otherVentriArrhythmia = followUpInfo.other_ventri_arrhythmia;
            }

            if (followUpInfo?.betablok >= 0) {
                const betablokOption = protocolYesNoOptions.find(x => x.value === followUpInfo.betablok);
                followUpReturned.betablok = betablokOption;
            }

            if (followUpInfo?.actual_daa >= 0) {
                const actualDaaOption = actualDaaOptions.find(x => x.value === followUpInfo.actual_daa);
                followUpReturned.actualDaa = actualDaaOption;
            }

            if (followUpInfo.other_actual_daa) {
                followUpReturned.otherActualDaa = followUpInfo.other_actual_daa;
            }

            if (followUpInfo?.holter >= 0) {
                let holterOption = null;

                if (followUpInfo.procedure_id === "MP" || followUpInfo.procedure_id === "CDI") {
                    holterOption = implantHolterOptions.find(x => x.value === followUpInfo.holter);
                } else {
                    holterOption = holterOptions.find(x => x.value === followUpInfo.holter);
                }

                followUpReturned.holter = holterOption;
            }

            if (followUpInfo.other_holter) {
                followUpReturned.otherHolter = followUpInfo.other_holter;
            }

            if (followUpInfo?.exams >= 0) {
                const examsOption = examsOptions.find(x => x.value === followUpInfo.exams);
                followUpReturned.exams = examsOption;
            }

            if (followUpInfo.other_exams) {
                followUpReturned.otherExams = followUpInfo.other_exams;
            }

            if (followUpInfo.observation) {
                followUpReturned.observation = followUpInfo.observation;
            }

            if (followUpInfo.holter_arrhytm) {
                followUpReturned.holterArrhytm = followUpInfo.holter_arrhytm;
            }

            if (followUpInfo?.post_implant >= 0) {
                const postImplantOption = postImplantOptions.find(x => x.value === followUpInfo.post_implant);
                followUpReturned.postImplant = postImplantOption;
            }

            if (followUpInfo.other_post_implant) {
                followUpReturned.otherPostImplant = followUpInfo.other_post_implant;
            }

            if (followUpInfo.ad_limit) {
                followUpReturned.adLimit = followUpInfo.ad_limit;
            }

            if (followUpInfo.vd_limit) {
                followUpReturned.vdLimit = followUpInfo.vd_limit;
            }

            if (followUpInfo.ve_limit) {
                followUpReturned.veLimit = followUpInfo.ve_limit;
            }

            if (followUpInfo.files) {
                loadFiles(followUpInfo.files);
            }

            setFollowInfo(followUpReturned);
        }
    }

    async function getFollowUp(id) {
        return new Promise(resolve => {
            api.get(`/follow-ups/${id}`)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                    }
                })
        });
    }

    async function getScheduleById(id) {
        await api.get(`/schedules/${id}`)
            .then(response => {
                if (response.status === 200) {
                    const scheduleInfo = response.data;

                    if (scheduleInfo.reports) {
                        if (scheduleInfo.reports.length > 0) {
                            const procedureInfo = scheduleInfo.reports[0];
                            setSchedule(procedureInfo);
                        }
                    } else {
                        setSchedule(scheduleInfo);
                    }
                }
            });
    }

    async function handleSaveFollowUp(e) {
        e.preventDefault();

        setLoadingFollowUpForm(true);

        let followUp = undefined;

        if (!isUpdate) {
            followUp = {
                pacient_id: schedule.pacient_id,
                pacient_name: schedule.pacient_name,
                schedule_id: schedule._id,
                procedure_id: procedureCode,
                procedure_date: schedule.execution_date,
                doctor_name: schedule.main_doctor_name,
                hospital_name: schedule.hospital_name,
                follow_date: dayjs().toDate()
            };
        }

        followUp = {
            ...followUp,
            avaliation: followInfo.avaliation?.value ?? null,
            other_avaliation: followInfo.otherAvaliation ?? "",
            place: followInfo.place?.value ?? null,
            other_place: followInfo.otherPlace ?? "",
            main_symptom: followInfo.mainSymptom?.value ?? null,
            other_main_symptom: followInfo.otherMainSymptom ?? "",
            ecg: followInfo.ecg?.value ?? null,
            other_ecg: followInfo.otherEcg ?? "",
            anticoag: followInfo.anticoag?.value ?? null,
            other_anticoag: followInfo.otherAnticoag ?? "",
            betablok: followInfo.betablok?.value ?? null,
            actual_daa: followInfo.actualDaa?.value ?? null,
            other_actual_daa: followInfo.otherActualDaa ?? "",
            holter: followInfo.holter?.value ?? null,
            other_holter: followInfo.otherHolter ?? "",
            exams: followInfo.exams?.value ?? null,
            other_exams: followInfo.otherExams ?? "",
            observation: followInfo.observation ?? "",
            ventri_arrhythmia: followInfo.ventriArrhythmia?.value ?? null,
            other_ventri_arrhythmia: followInfo.otherVentriArrhythmia ?? "",
            holter_arrhytm: followInfo.holterArrhytm ?? "",
            post_implant: followInfo.postImplant?.value ?? null,
            other_post_implant: followInfo.otherPostImplant ?? "",
            ad_limit: followInfo.adLimit ?? "",
            vd_limit: followInfo.vdLimit ?? "",
            ve_limit: followInfo.veLimit ?? "",
            files: []
        };

        if (uploadedFiles) {
            for (let i = 0; i < uploadedFiles.length; i++) {
                followUp.files.push({
                    filename: uploadedFiles[i].name,
                    original_name: uploadedFiles[i].originalName,
                    size: uploadedFiles[i].file.size
                })
            }
        }

        if (!isUpdate) {
            try {
                await api.post("/follow-ups", followUp)
                    .then(response => {
                        if (response.status === 201) {
                            createLog({
                                routine: "followup",
                                action: "create",
                                type: "success",
                                message: `Seguimento para ${schedule.pacient_name} incluído`,
                                jsonOrigin: JSON.stringify(followUp),
                                jsonReturn: JSON.stringify(response)
                            });

                            setLoadingFollowUpForm(false);
                            notifySuccess();
                            onClose();
                            setFollowInfo({});
                            setModalBox(false);
                        }
                    })
            } catch (err) {
                createLog({
                    routine: "followup",
                    action: "create",
                    type: "error",
                    message: `Seguimento para ${schedule.pacient_name} não incluído`,
                    jsonOrigin: JSON.stringify(followUp),
                    jsonReturn: JSON.stringify(err)
                });

                notifyError();
            }
        } else {
            try {
                await api.put(`/follow-ups/${followUpId}`, followUp)
                    .then(response => {
                        if (response.status === 200) {
                            createLog({
                                routine: "followup",
                                action: "update",
                                type: "success",
                                message: `Seguimento para ${schedule.pacient_name} alterado`,
                                jsonOrigin: JSON.stringify(followUp),
                                jsonReturn: JSON.stringify(response)
                            });

                            setLoadingFollowUpForm(false);
                            notifySuccess();
                            onClose();
                            setFollowInfo({});
                            setModalBox(false);
                        }
                    })
            } catch (err) {
                createLog({
                    routine: "followup",
                    action: "update",
                    type: "error",
                    message: `Seguimento para ${schedule.pacient_name} não alterado`,
                    jsonOrigin: JSON.stringify(followUp),
                    jsonReturn: JSON.stringify(err)
                });

                notifyError();
            }
        }

        setLoadingFollowUpForm(false);
    }

    function notifySuccess() {
        toast.success(`✔️ Protocolo ${(isUpdate ? "alterado" : "incluído")} com sucesso!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function notifyError() {
        toast.error(`❌ Ocorreu um problema ao salvar o registro`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    function handleChangeInfo(newInfo) {
        const newfollowInfo = { ...followInfo };

        for (let indexInfo = 0; indexInfo < newInfo.length; indexInfo++) {
            newfollowInfo[newInfo[indexInfo].field] = newInfo[indexInfo].value;
        }

        setFollowInfo({ ...newfollowInfo });
    }

    return (
        <>
            <Modal size="xl" fullscreen onHide={setModalBox} show={modalBox}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h3
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            Seguimento
                        </h3>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModalBox(false)}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="basic-form">
                            <div className="row">
                                <div className="form-group mb-12 col-md-12">
                                    <Tabs
                                        defaultActiveKey="baseForm"
                                        id="fill-tab-example"
                                        className="mb-3 basic-list-group"
                                        fill
                                    >
                                        <Tab eventKey="baseForm" title="Formulário">
                                            <FollowEvTvForm followInfoParm={followInfo}
                                                onChangeInfo={handleChangeInfo}
                                                hide={procedureCode !== "EV" && procedureCode !== "TVS"}
                                            />

                                            <FollowImplantForm followInfoParm={followInfo}
                                                onChangeInfo={handleChangeInfo}
                                                hide={procedureCode !== "MP" && procedureCode !== "CDI"}
                                            />

                                            <FollowFaForm followInfoParm={followInfo}
                                                onChangeInfo={handleChangeInfo}
                                                hide={procedureCode !== "FA"}
                                            />
                                        </Tab>

                                        <Tab eventKey="documents" title="Documentos">
                                            <UploadFile />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            variant="outline-danger btn-rounded"
                            onClick={() => {
                                setFollowInfo({});
                                setModalBox(false);
                            }}
                        >
                            Fechar
                        </Button>
                        <Button variant="success btn-rounded" onClick={handleSaveFollowUp}>Salvar</Button>
                    </div>
                </div>
            </Modal>

            <ToastContainer />

            <ModalLoading
                visible={loadingProtocolForm}
                message={loadingMessage}
                onClose={setLoadingFollowUpForm}
            />
        </>
    );
});

export default FollowUpForm;
